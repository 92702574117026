import t from './actionTypes'
import { idToInt } from 'helpers/sanitizers'

export function setMapView (payload) {
  return {
    type: t.TOGGLE_DELIVERIES_MAP_VIEW,
    payload
  }
}

export function setDepotFilter (payload) {
  return {
    type: t.SET_DELIVERIES_DEPOT_FILTER,
    payload
  }
}

export function setDeliveryType (payload) {
  return {
    type: t.SET_DELIVERY_TYPE,
    payload
  }
}

export function setFocusedDriverOrdersLoading (payload) {
  return {
    type: t.SET_FOCUSED_DELIVERIES_DRIVER_ORDERS_LOADING,
    payload
  }
}

export function setFocusedDriverInventoryLoading (payload) {
  return {
    type: t.SET_FOCUSED_DELIVERIES_DRIVER_INVENTORY_LOADING,
    payload
  }
}

export function setFocusedDeliveriesDriverId (driverId) {
  return {
    type: t.SET_FOCUSED_DELIVERIES_DRIVER_ID,
    payload: idToInt(driverId)
  }
}

export function saveMaps (payload) {
  return {
    type: t.SAVE_DELIVERIES_MAP,
    payload
  }
}

export function setDepotFilterString (payload) {
  return {
    type: t.SET_DELIVERIES_DEPOT_FILTER_STRING,
    payload
  }
}

export function setDriverDetailsContent (payload) {
  return {
    type: t.SET_DELIVERIES_DRIVER_DETAILS_CONTENT,
    payload
  }
}

export function toggleDepotOverview () {
  return {
    type: t.TOGGLE_DELIVERIES_DEPOT_OVERVIEW
  }
}

export function setMode (payload) {
  return {
    type: t.SET_DELIVERIES_MODE,
    payload
  }
}

export function clearDeliveriesStore () {
  return {
    type: t.CLEAR_DELIVERIES_STORE
  }
}

export function setDriverInventoryLoadingMap (payload) {
  return {
    type: t.SET_DELIVERIES_DRIVER_INVENTORY_LOADING_MAP,
    payload
  }
}

export function setDriverOrdersLoadingMap (payload) {
  return {
    type: t.SET_DELIVERIES_DRIVER_ORDERS_LOADING_MAP,
    payload
  }
}
