import t from './actionTypes'
import api from 'api'

import { pushNotification, SUCCESS } from 'src/redux/alerts/actions'

import {
  requestDeliveryFees,
  receiveDeliveryFees
} from 'src/redux/loading/actions'

export function fetchDeliveryFees (depotId) {
  return async function (dispatch) {
    dispatch(requestDeliveryFees())
    const { err, data } = await api.getDeliveryFees({ depotId })
    dispatch(receiveDeliveryFees())
    if (err) return

    dispatch(setDeliveryFees(data))
  }
}

export function saveDeliveryFees (deliveryFeesList, depotId) {
  return async function (dispatch) {
    const payload = {
      array: deliveryFeesList,
      depotId
    }
    const { err, data } = await api.saveDeliveryFees(payload)
    if (err) return

    dispatch(pushNotification('Peak hours successfully set', SUCCESS))
    dispatch(setDeliveryFees(data))
    return {}
  }
}

export const setDeliveryFees = (deliveryFees) => ({
  type: t.SET_DELIVERY_FEES,
  payload: deliveryFees
})
