import t from './actionTypes'

export function setBrandsMap (brandsMap) {
  return {
    type: t.SET_BRANDS_MAP,
    payload: brandsMap
  }
}

export function clearBrandsMap () {
  return {
    type: t.CLEAR_BRANDS_MAP
  }
}
