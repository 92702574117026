import React, { PureComponent } from 'react'
import { array, func, object } from 'prop-types'

import HorizontalProductCard from 'src/components/horizontal-product-card'

import { container, containerEmpty, emptyProductsGroup } from './style.js'

class SidebarMenuProducts extends PureComponent {
  static propTypes = {
    productIds: array,
    products: object,
    onAddProduct: func
  }

  render () {
    const {
      onAddProduct,
      productIds,
      products
    } = this.props

    const hasProducts = productIds.length

    return (
      <div className={hasProducts ? container : containerEmpty}>
        {!hasProducts &&
          <div className={emptyProductsGroup}>No products in this group.</div>
        }
        {productIds.map((id) => (
          <HorizontalProductCard
            key={`full-list-product-${id}`}
            product={products[id]}
            showAddIcon
            onAddProduct={onAddProduct}
          />
        ))}
      </div>
    )
  }
}

export default SidebarMenuProducts
