export const PEAK_DELIVERY_FEE_KEY = 'PEAK_DELIVERY_FEE_KEY'
export const DISCOUNTED_DELIVERY_FEE_KEY = 'DISCOUNTED_DELIVERY_FEE_KEY'
export const PEAK_DELIVERY_BYPASS_KEY = 'PEAK_DELIVERY_BYPASS_KEY'
export const DISCOUNTED_DELIVERY_BYPASS_KEY = 'DISCOUNTED_DELIVERY_BYPASS_KEY'

export const DELIVERY_FEE_MESSAGING = {
  [PEAK_DELIVERY_FEE_KEY]: 'Drivers are in high demand right now. The delivery fee has been raised temporarily.',
  [DISCOUNTED_DELIVERY_FEE_KEY]: 'It\'s happy hour! Order before it\'s too late for reduced delivery fees.',
  [PEAK_DELIVERY_BYPASS_KEY]: 'Drivers are in high demand right now. The minimum for free delivery is temporarily higher.',
  [DISCOUNTED_DELIVERY_BYPASS_KEY]: 'It\'s happy hour! We\'ve lowered the order minimum for free delivery.'
}
