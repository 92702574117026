import React from 'react'
import { string } from 'prop-types'

function DriversLicenseIcon ({ color, height, width }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 12'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>Drivers License</title>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Drivers-Lic' fill={color} fillRule='nonzero'>
          <path d='M14.6666,0 L1.3334,0 C0.6002,0 0,0.6002 0,1.3334 L0,10.6666 C0,11.3998 0.6002,12 1.3334,12 L14.6666,12 C15.3998,12 16,11.3998 16,10.6666 L16,1.3334 C16,0.6002 15.3998,0 14.6666,0 Z M4.6666,2.6666 C5.4038,2.6666 6,3.2636 6,4 C6,4.7364 5.4038,5.3334 4.6666,5.3334 C3.9296,5.3334 3.3334,4.7364 3.3334,4 C3.3334,3.2636 3.9296,2.6666 4.6666,2.6666 Z M7.3334,9.3334 L2,9.3334 L2,8.0006 C2,7.2642 2.5976,6.6674 3.3334,6.6674 L6,6.6666 C6.737,6.6666 7.3334,7.2636 7.3334,8 L7.3334,9.3334 Z M14,9.3334 L8.6666,9.3334 L8.6666,8 L14,8 L14,9.3334 Z M14,6.6666 L8.6666,6.6666 L8.6666,5.3334 L14,5.3334 L14,6.6666 Z M14,4 L7.3334,4 L7.3334,2.6666 L14,2.6666 L14,4 Z' id='Shape' />
        </g>
      </g>
    </svg>
  )
}

DriversLicenseIcon.propTypes = {
  color: string,
  height: string,
  width: string
}

DriversLicenseIcon.defaultProps = {
  color: '#948A91',
  height: '1.2rem',
  width: '1.6rem'
}

export default DriversLicenseIcon
