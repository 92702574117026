import t from './actionTypes'

const defaultState = {
  list: [],
  page: 0,
  total: 0,
  query: ''
}

const users = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_USERS:
      return Object.assign({}, state, {
        list: action.users
      })

    case t.SET_PAGE:
      return Object.assign({}, state, {
        page: action.page
      })

    case t.SET_TOTAL:
      return Object.assign({}, state, {
        total: action.total
      })

    case t.SET_QUERY:
      return Object.assign({}, state, {
        query: action.query
      })

    default:
      return state
  }
}

export default users
