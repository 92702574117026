import React, { PureComponent, Fragment } from 'react'
import styled from '@emotion/styled'
import { DELIVERY_TYPES } from 'helpers/drivers'
import DriverList from './driver-list'
import { accessory5 } from 'helpers/css-variables'
import { func, number, object, oneOfType, string } from 'prop-types'

export default class DepotBlock extends PureComponent {
  static propTypes = {
    drivers: object,
    expandDriverBubble: func,
    focusedDriverId: oneOfType([number, string]),
    setDepotZoom: func,
    setFocusedDriver: func
  }

  setDepotFilter = depotName => event => {
    const { setFocusedDriver, setDepotZoom } = this.props
    setFocusedDriver(undefined)

    // TODO: this setTimeout is janky
    // the reason for this is components/deliveries runs through willReceiveProps when filters or driverFocus changes
    // setTimeout ensures it runs after that so we unfocus a driver and then zoom to the
    setTimeout(() => setDepotZoom(depotName), 200)
  }

  render () {
    const {
      drivers,
      expandDriverBubble,
      focusedDriverId,
      setFocusedDriver
    } = this.props

    return (
      Object.entries(drivers).map(([depotName, value], index) => {
        const batchList = value[DELIVERY_TYPES.BATCH]
        const dynamicList = value[DELIVERY_TYPES.DYNAMIC]

        return (
          <Fragment key={index}>
            <DepotName onClick={this.setDepotFilter(depotName)}>
              {depotName}
            </DepotName>

            {Boolean(dynamicList.length) &&
              <DriverList
                driverList={dynamicList}
                expandDriverBubble={expandDriverBubble}
                focusedDriverId={focusedDriverId}
                listTitle='Dynamic'
                setFocusedDriver={setFocusedDriver}
              />}

            {Boolean(batchList.length) &&
              <DriverList
                driverList={batchList}
                expandDriverBubble={expandDriverBubble}
                focusedDriverId={focusedDriverId}
                listTitle='Batch'
                setFocusedDriver={setFocusedDriver}
              />}
          </Fragment>
        )
      })
    )
  }
}

const DepotName = styled.div`
  color: ${accessory5};
  font-size: 1.6rem;
  font-weight: bold;
  margin: 1.3rem 0;
  padding-left: 2.4rem;

  &:hover {
    cursor: pointer;
  }
`
