import React, { PureComponent } from 'react'
import { func, object, string } from 'prop-types'
import Autosuggest from 'react-autosuggest'
import debounce from 'debounce'

import {
  suggestionBody,
  suggestionIcon,
  suggestionRow,
  suggestionTitle
} from './style.js'

import * as allStyles from './style'

class PlaceSearch extends PureComponent {
  static propTypes = {
    theme: object,
    placeholder: string,
    initialSearch: string,
    activePlace: object,
    fetchPlace: func.isRequired,
    searchPlace: func.isRequired,
    onPlaceFetched: func,
    inputPropsOptions: object
  }

  static defaultProps = {
    theme: {},
    activePlace: {},
    placeholder: 'Type',
    initialSearch: ''
  }

  state = {
    search: '',
    searchResults: [],
    didPrefetchAddress: false
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps (nextProps) {
  /* eslint-enable camelcase */
    const { search, didPrefetchAddress } = this.state
    const { searchPlace } = this.props
    const {
      initialSearch,
      activePlace: nextActivePlace
    } = nextProps

    // only attempt to set an initial search if it exists and we haven't tried already
    const shouldFireInitialSearch = initialSearch && !didPrefetchAddress && !search && !nextActivePlace.id
    if (shouldFireInitialSearch) {
      // set an initial search if it exists
      const newState = {
        search: initialSearch,
        didPrefetchAddress: true
      }
      this.setState(newState, () => {
        searchPlace(initialSearch, this.setSearchResults)
      })
    }
  }

  onChange = (event, { newValue }) => {
    this.setState({ search: newValue })
  }

  onSelect = (event, { suggestion }) => {
    const { fetchPlace, onPlaceFetched } = this.props
    fetchPlace(suggestion.id, onPlaceFetched)
  }

  clearResults = () => {
    this.setState({ searchResults: [] })
  }

  debouncedSearch = debounce(({ value }) => {
    const { searchPlace } = this.props
    if (value) {
      searchPlace(value, this.setSearchResults)
    }
  }, 250)

  setSearchResults = (searchResults = []) => {
    this.setState({ searchResults }, this.setInitialPlace)
  }

  setInitialPlace = () => {
    const { searchResults } = this.state
    const {
      fetchPlace,
      activePlace,
      onPlaceFetched
    } = this.props

    if (!activePlace.id) {
      const firstResult = searchResults[0] || {}
      fetchPlace(firstResult.id, onPlaceFetched)
    }
  }

  renderSuggestion = (suggestion) => {
    return (
      <div className={suggestionRow}>
        <img src={suggestion.icon} className={suggestionIcon} />
        <div>
          <div className={suggestionTitle}>{suggestion.title}</div>
          <div className={suggestionBody}>{suggestion.body}</div>
        </div>
      </div>
    )
  }

  render () {
    const { theme, placeholder, inputPropsOptions } = this.props
    const { search, searchResults } = this.state

    const themeStyle = {
      ...allStyles,
      ...theme
    }

    const inputProps = {
      placeholder,
      value: search,
      onChange: this.onChange,
      type: 'search',
      ...inputPropsOptions
    }

    return (
      <Autosuggest
        suggestions={searchResults}
        inputProps={inputProps}
        renderSuggestion={this.renderSuggestion}
        getSuggestionValue={({ description }) => description}
        onSuggestionsFetchRequested={this.debouncedSearch}
        onSuggestionsClearRequested={this.clearResults}
        onSuggestionSelected={this.onSelect}
        theme={themeStyle}
      />
    )
  }
}

export default PlaceSearch
