import React from 'react'
import GoogleMapReact from 'google-map-react'
import isEmptyObject from 'is-empty-object'
import { array, bool, func, number, object, oneOfType, string } from 'prop-types'
import { isMobile } from 'helpers/constants'
import { idToInt } from 'helpers/sanitizers'
import errorHandler from 'helpers/error-handler'
import { GMAPS_KEY } from 'helpers/environment'

import CustomerMarker from './customer-marker'
import GoogleMapTheme from './map-theme'
import DriverMarker from './driver-marker'
import DepotMarker from 'microcomponents/icon-depot-marker'

const colors = [
  '#F44336',
  '#FF9800',
  '#FFEB3B',
  '#4CAF50',
  '#2196F3',
  '#E91E63',
  '#9C27B0',
  '#795548',
  '#68EFAD',
  '#18FFFF'
]

const defaultOpts = {
  zoom: 7,
  options: {
    styles: GoogleMapTheme,
    fullscreenControl: false
  }
}

const CA_DEFAULT_LAT = 36
const CA_DEFAULT_LNG = -119
const MI_DEFAULT_LAT = 42
const MI_DEFAULT_LNG = -83
export default class DeliveryOverview extends React.PureComponent {
  static propTypes = {
    depots: array,
    depotSpecificNav: string,
    driver: object,
    driverMarkers: array,
    driverOrders: array,
    expandDriverBubbleId: number,
    filterList: array,
    focusedDriverId: oneOfType([number, string]),
    isDriverFocused: bool,
    isOrdersLoading: bool,
    maps: object,
    saveMaps: func,
    setFocusedDriver: func
  }

  state = {
    mapLoaded: false,
    map: {},
    lines: []
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps (nextProps) {
  /* eslint-enable camelcase */
    const {
      depotSpecificNav,
      driverMarkers,
      driverOrders,
      filterList,
      focusedDriverId,
      isOrdersLoading
    } = this.props

    const depotNameChange = nextProps.depotSpecificNav !== depotSpecificNav
    const filtersChanged = nextProps.filterList.length !== filterList.length
    const driverMarkersChanged = driverMarkers.length !== nextProps.driverMarkers.length

    if (filtersChanged || driverMarkersChanged || depotNameChange) {
      this.findCenter(nextProps.driverMarkers)
    }

    if (focusedDriverId !== nextProps.focusedDriverId && this.state.lines.length) {
      this.cleanupLines()
    }

    if ((!isOrdersLoading && nextProps.isOrdersLoading) || (driverOrders.length !== nextProps.driverOrders.length)) {
      this.setDriver(nextProps.driver, nextProps.driverOrders)
    }
  }

  setDriver = (driver, driverOrders) => {
    this.cleanupLines(() => this.centerDriver(driver, driverOrders))
  }

  cleanupLines = (callback) => {
    this.state.lines.forEach(line => line.setMap(null))

    this.setState({ lines: [] }, callback)
  }

  findCenter = (driverMarkersFromNextProps) => {
    // this function calculates where the center of the map should be
    // we forEach over each driver on the map, grab their location
    // and the center the map accordingly
    const { driverMarkers: driverMarkersFromProps, maps } = this.props
    const driverMarkers = driverMarkersFromNextProps || driverMarkersFromProps
    const { map } = this.state

    if (isEmptyObject(maps) || isEmptyObject(map)) return

    const bounds = new maps.LatLngBounds()

    const coords = driverMarkers.map(({ location }) => ({
      lat: location.latitude,
      lng: location.longitude
    }))

    // grab the location of each driver, and extend it into bounds
    // that we'll use to center the map below
    coords.forEach(({ lat, lng }) => {
      const coordinates = this.getDefaultCoordinates()
      if (!lat || !lng) return bounds.extend(coordinates)

      return bounds.extend({ lat, lng })
    })

    if (map.fitBounds) {
      map.fitBounds(bounds)
    }
  }

  centerDriver = (nextPropsDriver, nextPropsDriverOrders) => {
    // this method is used for taking a drivers location, and
    // the location of the last order in their queue. We then
    // call fitBounds, so google maps will automagically center/zoom
    // the map to fit the coords
    const { map, directionService } = this.state
    const { driver: driverFromProps, maps } = this.props

    const driver = nextPropsDriver || driverFromProps

    if (isEmptyObject(maps)) return

    const bounds = new maps.LatLngBounds()

    const { latitude: lat, longitude: lng } = driver.location

    if (!lat || !lng) {
      // driver was probably deselected, fallback to finding the center of all driver markers
      return this.findCenter()
    }

    if (nextPropsDriverOrders && nextPropsDriverOrders.length > 0) {
      const deliveryCoords = []
      deliveryCoords.push({ lat, lng })

      nextPropsDriverOrders.forEach(({ delivery }, index) => {
        delivery.routes.forEach(r => {
          const to = {}
          to.lat = r.endLocation.latitude
          to.lng = r.endLocation.longitude

          deliveryCoords.push(to)
        })

        const dest = {
          lat: delivery.location.latitude,
          lng: delivery.location.longitude
        }

        let origin = { lat, lng }

        if (index > 0) {
          origin = {
            lat: nextPropsDriverOrders[index - 1].delivery.location.latitude,
            lng: nextPropsDriverOrders[index - 1].delivery.location.longitude
          }
        }

        if (this.state.lines.length === 0) {
          directionService.route({
            travelMode: maps.TravelMode.DRIVING,
            origin: new maps.LatLng(origin),
            destination: new maps.LatLng(dest)
          }, (result, status) => {
            if (!result) {
              errorHandler(new Error(status))
              return console.error('ERROR!!!', status)
            }

            const decodedPath = maps.geometry.encoding.decodePath(result.routes[0].overview_polyline)

            const line = new maps.Polyline({
              path: decodedPath,
              map: map,
              strokeColor: colors[index],
              strokeWeight: 3
            })

            this.setState({ lines: [...this.state.lines, line] })
          })
        }
      })

      deliveryCoords.forEach(({ lat, lng }) => bounds.extend({ lat, lng }))
      map.fitBounds(bounds)
    } else {
      bounds.extend({ lat, lng })
      map.fitBounds(bounds)
      map.setZoom(14)
    }
  }

  handleMarkerClick = (markerDriverId, markerData) => {
    // this is gross but it's currently the only way to detect a click on
    // a depot vs a driver. If no driver key exists in this object let's
    // return early so we don't instigate a toggle when we're clicking on a depot
    if (!markerData.driver) {
      return
    }
    const { focusedDriverId, setFocusedDriver } = this.props
    // using idToInt() here because markerDriverId is somehow changed
    // to a string. Not sure if that's happening by the google maps
    // lib or elsewhere. We do eventually want all IDs to be strings
    // but have some debt to pay down before we can enforce that properly
    const sanitizedMarkerDriverId = idToInt(markerDriverId)

    // logic to toggle driver off if that driver is currently on
    const isDriverFocused = focusedDriverId === sanitizedMarkerDriverId
    const driverId = isDriverFocused ? undefined : sanitizedMarkerDriverId

    setFocusedDriver(driverId)
  }

  drawDeliveries = (nextPropsDriver) => {
    const { driver: driverFromProps, driverOrders } = this.props

    const driver = nextPropsDriver || driverFromProps

    let deliveries = []

    const hasSelectedDriver = driver && driver.location && driverOrders

    if (hasSelectedDriver) {
      deliveries = driverOrders.map(({ delivery }, index) => {
        if (!delivery || isEmptyObject(delivery.location)) return null

        return (
          <CustomerMarker
            lat={delivery.location.latitude}
            lng={delivery.location.longitude}
            number={index + 1}
            last={index + 1 === driverOrders.length}
            key={index}
          />
        )
      })
    }

    return deliveries
  }

  getDefaultCoordinates = () => {
    const { selectedDepot = {} } = this.props
    const { depotAddress = {} } = selectedDepot
    const state = depotAddress.state
    switch (state) {
      case 'MI':
        return { lat: MI_DEFAULT_LAT, lng: MI_DEFAULT_LNG }
      default:
        return { lat: CA_DEFAULT_LAT, lng: CA_DEFAULT_LNG }
    }
  }

  render () {
    const {
      depots,
      driverMarkers,
      driver,
      expandDriverBubbleId,
      isDriverFocused
    } = this.props

    const { mapLoaded } = this.state

    if (driverMarkers.length === 0) return null

    const opt = defaultOpts

    if (!mapLoaded) {
      // if we haven't loaded google maps, set the default center
      // depot's state... this will change once onGoogleApiLoaded
      // calls this.findCenter()
      const coordinates = this.getDefaultCoordinates()
      opt.center = [coordinates.lat, coordinates.lng]
    }

    return (
      <GoogleMapReact
        bootstrapURLKeys={{
          language: 'en',
          key: GMAPS_KEY
        }}
        resetBoundsOnResize
        onChildClick={this.handleMarkerClick}
        onGoogleApiLoaded={({ map, maps }) => {
          this.setState((state, props) => {
            return {
              map: map,
              mapLoaded: true,
              directionService: new maps.DirectionsService()
            }
          }, () => {
            this.props.saveMaps(maps)
            this.findCenter()
          })
        }}
        {...opt}
      >
        {
          driverMarkers.map(driverMarker => {
            // if a driver is focused but the marker is not that driver, make the marker semi-transparent
            // if no driver is selected, none of the markers should be transparent
            const isMarkerTransparent = isDriverFocused && driverMarker.id !== driver.id
            return (
              <DriverMarker
                bubble={driverMarker.id === expandDriverBubbleId && !isMobile && !isDriverFocused}
                driver={driverMarker}
                key={driverMarker.id}
                lat={driverMarker.location.latitude}
                lng={driverMarker.location.longitude}
                opacity={driverMarker.id === (driver && driver.id) ? 1 : 0.4}
                status={driverMarker.driverModeDescription}
                variableOpacity={isMarkerTransparent}
              />
            )
          })
        }

        {driver ? this.drawDeliveries() : null}

        {depots.map(d => (<DepotMarker key={d.id} lat={d.latitude} lng={d.longitude} />))}
      </GoogleMapReact>
    )
  }
}
