import api from 'api'
import history from 'components/router/history'

import { requestCatalogMenus, receiveCatalogMenus } from '../loading/actions'
import { pushNotification, SUCCESS } from '../alerts/actions'
import { setCatalogGroups, setCatalogMenus } from '../catalog/actions'
import { dedupeArrayByObjectKey } from 'src/utils/helpers'
import t from './actionTypes'

export function fetchMenuById (id) {
  return async dispatch => {
    dispatch(requestCatalogMenus())

    const { err, data } = await api.getCatalogMenu({ id })
    dispatch(receiveCatalogMenus())

    if (err) return

    data.groups = dedupeArrayByObjectKey(data.groups, 'id')

    dispatch(setMenuItems(data.groups))
    dispatch(updateMenuObject(data))
  }
}

/**
 *
 * @param {object} items
 * @param {array} items.menuItems
 * @param {array} items.groups
 */
export function sortedItems (items) {
  return dispatch => {
    const menuItems = dedupeArrayByObjectKey(items.menuItems, 'id')
    const groups = dedupeArrayByObjectKey(items.groups, 'id')

    dispatch(setMenuItems(menuItems))
    dispatch(setCatalogGroups(groups))
  }
}

function setMenuItems (items) {
  return {
    type: t.SET_MENU_ITEMS,
    items
  }
}

export function updateMenuObject (obj) {
  return {
    type: t.UPDATE_MENU_OBJECT,
    obj
  }
}

export function resetMenuItem () {
  return {
    type: t.RESET_MENU_ITEM
  }
}

function pruneMenuItem (originalItem) {
  // create/update api doesn't accept additional properties that we're storing in state, so this function prunes the payload
  // first, clone item so we don't mutate UI
  const item = { ...originalItem }

  item.groups = item.items.map(({ id, name }) => ({ id, name }))
  item.depots_enabled = item.depotsEnabled.filter(({ enabled }) => enabled).map(({ id }) => id)

  delete item.depotsEnabled
  delete item.items
  delete item.showColorPicker
  delete item.menuColor
  delete item.tabs
  delete item.currentTab
  delete item.hasSetDepotsEnabled

  return item
}

export function createOrUpdateMenu () {
  return async (dispatch, getState) => {
    const state = getState()

    const item = pruneMenuItem(state.menuItem)

    dispatch(requestCatalogMenus())

    let apiMethod = null
    let createOrEditWord = ''
    if (item.id) {
      // Update!
      apiMethod = api.updateCatalogMenus
      createOrEditWord = 'edited'
    } else {
      // Create! We don't need item.id
      delete item.id

      apiMethod = api.createCatalogMenus
      createOrEditWord = 'created'
    }

    const { err } = await apiMethod(item)
    dispatch(receiveCatalogMenus())
    if (err) return

    // reset menus, we're refetching as soon as the next component mounts
    dispatch(setCatalogMenus([]))

    dispatch(pushNotification(`Successfully ${createOrEditWord} ${item.name} menu`, SUCCESS))

    return history.push('/catalog/menus')
  }
}
