// Material UI Theme: https://material-ui.com/customization/themes/
// ThemeProvider: https://material-ui.com/customization/themes/#theme-provider
import { createMuiTheme } from '@material-ui/core/styles'

import DEFAULT_PROPS from './defaultProps'
import OVERRIDES from './overrides'
import PALETTE from './palette'
import TYPOGRAPHY from './typography'

const MC3_THEME = createMuiTheme({
  // Apply CSS to all instances of a component
  overrides: OVERRIDES,

  // Define our custom palette for Material UI to use
  palette: PALETTE,

  // Apply props to all instances of a component
  props: DEFAULT_PROPS,

  // Apply typography styles throughout
  typography: TYPOGRAPHY
})

// Make the theme available in the console for easy exploration of theme object
if (window) window.theme = MC3_THEME

export default MC3_THEME
