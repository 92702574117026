import React from 'react'
import { object, string } from 'prop-types'
import { tagTypeColor, tagTypeStyle } from './helpers'

export default function ExpressTag ({ color = tagTypeColor, height = '16px', width = '12px', style = tagTypeStyle }) {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox='0 0 12 16'
      version='1.1'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
        <polygon
          stroke={tagTypeColor}
          strokeLinecap='round'
          strokeLinejoin='round'
          points='5.45169891 9.01587302 0.66666667 9.01587302 8.96875 0.888888889 6.2107589 6.91025699 10.8068576 6.91025699 2.66666667 15.1111111'
        />
        <path
          d='M1.756 8.571h3.696c.323 0 .538.335.404.63l-1.75 3.829L9.74 7.355H6.211a.444.444 0 0 1-.404-.63l1.76-3.841L1.755 8.57zm3.004.89H.667a.444.444 0 0 1-.311-.763L8.658.571c.352-.344.92.055.715.503l-2.47 5.392h3.904c.395 0 .594.477.315.757l-8.14 8.201c-.35.352-.926-.046-.72-.498L4.76 9.46z'
          fill={tagTypeColor}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}

ExpressTag.propTypes = {
  color: string,
  height: string,
  width: string,
  style: object
}
