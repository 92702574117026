import t from './actionTypes'

const defaultState = {}

const products = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_PRODUCTS:
      return {
        ...state,
        ...action.payload
      }

    case t.CLEAR_PRODUCTS:
      return defaultState

    default:
      return state
  }
}

export default products
