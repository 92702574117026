import React from 'react'
import InputLabel from 'src/microcomponents/input-label'
import { Row, Column } from 'src/microcomponents/flexbox-helpers'
import { bool, func, number, object, oneOfType, string } from 'prop-types'
import { selectContainer } from '../style.js'

const DetailsStandard = ({ promoOptions, setCreditType, creditType, creditAmount, setCreditAmount, lockCreditType }) => (
  <Row top={1} bottom={1}>
    <Column right={1} full>
      <InputLabel content='Credit Type*' />
      <div className={selectContainer}>
        <select onChange={setCreditType} value={creditType} disabled={lockCreditType || 0}>
          <option value={0} />
          {
            lockCreditType
              ? <option value={creditType}>{creditType}</option>
              : promoOptions.credit_types.map((opt, ind) => <option value={opt} key={ind}>{opt}</option>)
          }
        </select>
      </div>
    </Column>
    <Column full>
      <InputLabel content='Amount*' />
      <input
        onBlur={setCreditAmount}
        type='number'
        placeholder='Amount'
        defaultValue={creditAmount}
      />
    </Column>
  </Row>
)

DetailsStandard.propTypes = {
  promoOptions: object,
  setCreditType: func,
  creditType: string,
  creditAmount: oneOfType([number, string]),
  setCreditAmount: func,
  lockCreditType: bool
}

export default DetailsStandard
