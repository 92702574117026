import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { array, bool, func, number, object, string } from 'prop-types'
import Screen from 'src/components/screen/container'
import DashboardContainer from 'src/components/dashboard'

import { DASHBOARD_NAMES } from 'helpers/dashboard'
import { fetchAllDispensaries } from 'src/redux/dispensaries/actions'
import Cases from 'src/pages/dashboard/cases/container'
import Deliveries from 'src/pages/deliveries'
import Hub from 'src/pages/hub-and-spoke'
import Packing from 'src/pages/hub-and-spoke/pages/packer'
import Running from 'src/pages/hub-and-spoke/pages/runner'
import Canceled from 'src/pages/hub-and-spoke/pages/canceled'
import Inventory from 'src/pages/hub-and-spoke/pages/depot-inventory/container'
import Drivers from 'src/pages/drivers/container'
import Settings from 'src/pages/settings/container'
import { getHubAndSpokeDepotIds } from 'src/redux/dispensaries/selectors'
import { idToInt } from 'helpers/sanitizers'
import { getIsAdmin } from 'src/redux/permissions/selectors'

const DASHBOARD_CONTAINERS = {
  [DASHBOARD_NAMES.cases.name]: Cases,
  [DASHBOARD_NAMES.deliveries.name]: Deliveries,
  [DASHBOARD_NAMES.hub.name]: Hub,
  [DASHBOARD_NAMES.inventory.name]: Inventory,
  [DASHBOARD_NAMES.drivers.name]: Drivers,
  [DASHBOARD_NAMES.packer.name]: Packing,
  [DASHBOARD_NAMES.runner.name]: Running,
  [DASHBOARD_NAMES.canceled.name]: Canceled,
  [DASHBOARD_NAMES.settings.name]: Settings
}

const mapStateToProps = (state, props) => {
  const { tab = DASHBOARD_NAMES.cases.name, task = '', id = '' } = props.match.params
  const selectedDepot = state.depotsMap[id] || {}
  return {
    tab,
    task,
    selectedDepotId: idToInt(id),
    hubAndSpokeDepotIds: getHubAndSpokeDepotIds(state),
    isAdmin: getIsAdmin(state),
    selectedDepot
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchAllDispensaries: () => dispatch(fetchAllDispensaries())
  }
}

class Dashboard extends PureComponent {
  static propTypes = {
    fetchAllDispensaries: func,
    hubAndSpokeDepotIds: array,
    isAdmin: bool,
    selectedDepotId: number,
    tab: string,
    task: string,
    selectedDepot: object
  }

  getDepotRoute = (tab) => {
    if (tab === 'inventory') return `/dashboard/${this.props.selectedDepotId}/${tab}?page=1` // appends page # query for pagination
    return `/dashboard/${this.props.selectedDepotId}/${tab}`
  }

  componentDidMount () {
    const { fetchAllDispensaries } = this.props
    fetchAllDispensaries()
  }

  render () {
    const {
      hubAndSpokeDepotIds,
      isAdmin,
      selectedDepotId,
      tab,
      task,
      selectedDepot
    } = this.props
    const ContainerToShow = DASHBOARD_CONTAINERS[tab]

    return (
      <Screen>
        <DashboardContainer
          depotId={selectedDepotId}
          tab={tab}
          task={task}
          getDepotRoute={this.getDepotRoute}
          hubAndSpokeDepotIds={hubAndSpokeDepotIds}
          isAdmin={isAdmin}
          selectedDepot={selectedDepot}
        >
          <ContainerToShow {...this.props} />
        </DashboardContainer>
      </Screen>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
