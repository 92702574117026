import React from 'react'
import { string } from 'prop-types'

function DeleteIcon ({ color, height, width }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>Delete</title>
      <defs />
      <g
        id='BUILD-THIS'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='Menu-(desktop)'
          transform='translate(-323.000000, -556.000000)'
          fillRule='nonzero'
          fill={color}
        >
          <g id='Group-2' transform='translate(308.000000, 543.000000)'>
            <g id='trashcan' transform='translate(15.000000, 13.000000)'>
              <g id='Group'>
                <path d='M0.434782609,2.89361702 L2.10434783,2.89361702 L2.50434783,14.6382979 C2.52173913,15.3702128 3.14782609,15.9489362 3.89565217,15.9489362 L12.1043478,15.9489362 C12.8521739,15.9489362 13.4782609,15.3702128 13.4956522,14.6382979 L13.8956522,2.89361702 L15.5652174,2.89361702 C15.7565217,2.89361702 15.9130435,2.74042553 15.9130435,2.55319149 C15.9130435,2.36595745 15.7565217,2.21276596 15.5652174,2.21276596 L10.8347826,2.21276596 L10.8347826,1.05531915 C10.8347826,0.493617021 10.3652174,0.0340425532 9.79130435,0.0340425532 L6.20869565,0.0340425532 C5.63478261,0.0340425532 5.16521739,0.493617021 5.16521739,1.05531915 L5.16521739,2.21276596 L0.434782609,2.21276596 C0.243478261,2.21276596 0.0869565217,2.36595745 0.0869565217,2.55319149 C0.0869565217,2.74042553 0.243478261,2.89361702 0.434782609,2.89361702 Z M5.86086957,1.05531915 C5.86086957,0.868085106 6.0173913,0.714893617 6.20869565,0.714893617 L9.80869565,0.714893617 C10,0.714893617 10.1565217,0.868085106 10.1565217,1.05531915 L10.1565217,2.21276596 L5.86086957,2.21276596 L5.86086957,1.05531915 Z M13.2,2.89361702 L12.8,14.6212766 C12.7826087,14.9957447 12.4869565,15.2851064 12.1043478,15.2851064 L3.89565217,15.2851064 C3.51304348,15.2851064 3.2173913,14.9957447 3.2,14.6212766 L2.8,2.89361702 L13.2,2.89361702 Z' id='Shape' />
                <path d='M8,13.5319149 C8.19130435,13.5319149 8.34782609,13.3787234 8.34782609,13.1914894 L8.34782609,4.85106383 C8.34782609,4.66382979 8.19130435,4.5106383 8,4.5106383 C7.80869565,4.5106383 7.65217391,4.66382979 7.65217391,4.85106383 L7.65217391,13.1914894 C7.65217391,13.3787234 7.80869565,13.5319149 8,13.5319149 Z' id='Shape' />
                <path d='M10.6086957,13.5319149 C10.8,13.5319149 10.9565217,13.3787234 10.9565217,13.1914894 L10.9565217,4.85106383 C10.9565217,4.66382979 10.8,4.5106383 10.6086957,4.5106383 C10.4173913,4.5106383 10.2608696,4.66382979 10.2608696,4.85106383 L10.2608696,13.1914894 C10.2608696,13.3787234 10.4173913,13.5319149 10.6086957,13.5319149 Z' id='Shape' />
                <path d='M5.39130435,13.5319149 C5.5826087,13.5319149 5.73913043,13.3787234 5.73913043,13.1914894 L5.73913043,4.85106383 C5.73913043,4.66382979 5.5826087,4.5106383 5.39130435,4.5106383 C5.2,4.5106383 5.04347826,4.66382979 5.04347826,4.85106383 L5.04347826,13.1914894 C5.04347826,13.3787234 5.2,13.5319149 5.39130435,13.5319149 Z' id='Shape' />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

DeleteIcon.propTypes = {
  color: string,
  height: string,
  width: string
}

DeleteIcon.defaultProps = {
  color: '#EF5A4B',
  height: '1.6rem',
  width: '1.6rem'
}

export default DeleteIcon
