import { css } from 'emotion'
import { white } from 'helpers/css-variables'

export const container = css`
  background: ${white};
  border-radius: 0.34rem;
  width: 100%;

  @media (min-width: 559px) {
    max-width: 42rem;
  }
`

export const content = css`
  padding: 2rem; 
`

export const title = css`
  text-align: center;
`

export const orderData = css`
  margin: 4rem 0;
`

export const buttonRow = css`
  display: flex;
  justify-content: space-between;
`

export const button = css`
  width: 15rem;
`

export const dataItem = css`
  display: flex;
  justify-content: space-between;
`
