import t from './actionTypes'

export function openNavSlider () {
  return {
    type: t.OPEN_NAVIGATION_SLIDER
  }
}

export function closeNavSlider () {
  return {
    type: t.CLOSE_NAVIGATION_SLIDER
  }
}
