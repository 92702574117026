import React, { PureComponent } from 'react'

export default class TableCell extends PureComponent {
  render () {
    const { value, formatter, row } = this.props
    const fval = formatter
      ? formatter(value, row)
      : value

    return (<td>{fval}</td>)
  }
}
