import t from './actionTypes'

export const defaultState = {
  territoriesMap: {},
  isTerritoryFormOpen: false
}

const adultUseTerritories = (state = defaultState, { type, payload }) => {
  switch (type) {
    case t.SET_ADULT_USE_TERRITORIES_MAP:
      return {
        ...state,
        territoriesMap: payload
      }

    case t.SHOW_ADULT_USE_TERRITORIES_FORM:
      return {
        ...state,
        isTerritoryFormOpen: true
      }

    case t.HIDE_ADULT_USE_TERRITORIES_FORM:
      return {
        ...state,
        isTerritoryFormOpen: false
      }

    default:
      return state
  }
}

export default adultUseTerritories
