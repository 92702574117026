import { createSelector } from 'reselect'
import errorHandler from 'helpers/error-handler'

export const getPromosList = (state) => state.promos.list

export const getSearchQuery = (state) => state.promos.searchQuery

// Filters on all promos we have in memory (we fetch all promos available in
// the API in fetchPromos()) for the current search query, after downcasing both
// All values in promo objects are searched via serializing of promos
// This is a hold-over fix as part of PEP-2623
export const getFilteredPromosList = createSelector(
  [getPromosList, getSearchQuery],
  function (promosList, searchQuery) {
    let filteredPromosList = []
    try {
      filteredPromosList = promosList.filter(promoObj => {
        const normalizedPromo = JSON.stringify(Object.values(promoObj)).toLowerCase()
        const normalizedSearchQuery = searchQuery.toLowerCase()
        return normalizedPromo.includes(normalizedSearchQuery)
      })
    } catch (e) {
      console.error('An error occurred while filtering promos', e)
      errorHandler(e)
      console.log('Promos List:', promosList)
      console.log('Search Query:', searchQuery)
    }
    return filteredPromosList
  }
)
