const green = {
  10: '#ebfef5',
  20: '#bdffe2',
  30: '#96ffd1',
  40: '#54f7b0',
  50: '#34e096',
  60: '#14c479',
  70: '#12985f',
  80: '#166f49',
  90: '#1a5039'
}

export default green
