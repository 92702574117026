import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import SoldOut from './sold-out.js'

const fonts = '\'Proxima\', \'Open Sans\', \'Helvetica\';'

const Availability = styled.div`
  font-family: ${fonts}
  font-size: 1.4rem;
  padding: 1rem;
  padding-top: 0;
  margin-top: auto;
`

export default class AvailabilityComponent extends PureComponent {
  render () {
    return (
      <Availability>
        <SoldOut />
      </Availability>
    )
  }
}
