/**
 * Various functions and constants that are used for product representation
 * throughout the app
 */
import isEmptyObject from 'is-empty-object'

/**
 * WARNING: all of these IDs have come from a production or development database,
 * this means that any product additions or deletions, or any subtype/type additions
 * or deletions in a production or development database have a good chance of breaking
 * client side logic unless adding or removing these items are coordinated with the frontend
 * the real fix for this solution will be endpoints from the backend where we can get the
 * information we need to make these relationships.
 */

// product type IDs
export const FLOWER_TYPE_ID = 'bf43bb27-3c14-4b5b-b68f-fc5762f310de'
export const EDIBLE_TYPE_ID = '5cc9ff17-f300-490a-ac13-a750af7325ff'
export const CONCENTRATE_TYPE_ID = 'b1312580-63d6-40a7-b96f-ec12e07b2900'
export const ACCESSORY_TYPE_ID = '87704600-e71d-4cc3-bff9-267690619ea8'
export const PREROLL_TYPE_ID = 'd433fb62-850e-4e2b-bc91-1e1874ea407d'
export const VAPORIZER_TYPE_ID = '2c57d120-3904-45bb-a593-d1b487099720'
export const DROP_TYPE_ID = '68a708a3-7dda-44c1-833f-2fdbf6fd72b0'
export const TOPICAL_TYPE_ID = 'ab67c215-1eb2-4519-9057-5fd08b87d549'

// product subtype IDs
export const PAX_CARTRIDGE_SUBTYPE_ID = 'd0ec6125-5901-4229-b1bb-df7128f41222'
export const UNIVERSAL_CARTRIDGE_SUBTYPE_ID = '8932bedc-4207-4162-ab89-906cd583cecf'

// We need to make special cases for cartridges
const CARTRIDGE_SUBTYPE_IDS = [
  PAX_CARTRIDGE_SUBTYPE_ID,
  UNIVERSAL_CARTRIDGE_SUBTYPE_ID
]
export const isCartridge = (productSubtypeId) => CARTRIDGE_SUBTYPE_IDS.indexOf(productSubtypeId) > -1

export const catalogItemRoute = (item) => {
  const baseFullSlug = `${item.type.slug}/${item.subtype.slug}/${item.slug}`
  return item.brand && item.brand.slug
    ? `/browse/${item.brand.slug}/${baseFullSlug}`
    : `/browse/${baseFullSlug}`
}

export function transformCatalogItemToProduct (item) {
  if (!item || isEmptyObject(item)) return {}

  let effects
  if (item.effects && item.effects.length) {
    effects = item.effects
  } else if (item.strain && item.strain.effects && item.strain.effects.length) {
    effects = item.strain.effects
  } else if (item.species && item.species.effects && item.species.effects.length) {
    effects = item.species.effects
  } else {
    effects = []
  }

  // sometimes we don't have a species which will cause catalogSpeciesId below to fail when trying to access id
  item.species = item.species || {}

  return {
    name: item.title,
    brand: item.brand,
    description: item.description,
    photoUrl: item.images[0].downloadUrl,
    species: item.species,
    type: item.type,
    subtype: item.subtype,
    strain: item.strain,
    thc: item.thc_content,
    thcMeasure: item.type.id === EDIBLE_TYPE_ID ? 'mg' : '%',
    cbd: item.cbd_content,
    cbdMeasure: item.type.id === EDIBLE_TYPE_ID ? 'mg' : '%',
    price: null,
    prices: [],
    id: item.id,
    highCBD: item.high_cbd,
    seoRoute: catalogItemRoute(item),
    catalogItemId: item.catalogItemId,
    catalogSpeciesId: item.species.id,
    effects: effects,
    slug: item.slug
  }
}

export const PRODUCT_SLUG_TYPES = {
  FLOWERS: 'flowers',
  ACCESSORIES: 'accessories',
  VAPORIZERS: 'vaporizers',
  EDIBLES: 'edibles',
  PREROLLS: 'prerolls',
  CONCENTRATES: 'concentrates'
}
