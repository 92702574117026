import React from 'react'
import styled from '@emotion/styled'
import { blazeUpBlack, mcGreen, mcRed, mcYellow, white } from 'src/helpers/css-variables'
import { bool, number, object, string } from 'prop-types'

const statusColorMap = {
  DELIVERY: mcGreen,
  HOLD: mcRed,
  OFF: mcRed,
  ON: mcYellow
}

const DriverMarker = (props) => {
  const {
    $hover,
    bubble,
    driver,
    opacity,
    status,
    variableOpacity
  } = props

  const color = statusColorMap[status]
  const driverHasPendingOrders = Boolean(driver.pendingOrders)
  const showDriverInfo = (bubble || $hover)

  return (
    <div>
      <DriverDiv>
        <OnlineDriver
          borderColor={color}
          showDriverInfo={showDriverInfo}
        >
          {showDriverInfo && driver &&
            (
              <DriverBreakdown>
                <DriverName>{driver.displayName}</DriverName>
                {driverHasPendingOrders && <div>Orders: {driver.pendingOrders}</div>}
              </DriverBreakdown>
            )}
        </OnlineDriver>
      </DriverDiv>

      <Dot color={color} opacity={variableOpacity ? opacity : 1} />
    </div>
  )
}

DriverMarker.propTypes = {
  $hover: bool,
  bubble: bool,
  driver: object,
  opacity: number,
  status: string,
  variableOpacity: bool
}

export default DriverMarker

const DriverBreakdown = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-left: 0.1rem;
  width: 100%;
`

const DriverName = styled.div`
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
`

const DriverDiv = styled.div`
  align-items: center;
  color: ${white};
  display: flex;
  font-size: 5px;
  height: 2rem;
  justify-content: center;
  padding: 0.5rem;
  position: absolute;
  transform: scale(3);
  width: 2rem;
  z-index: ${({ showDriverInfo }) => showDriverInfo ? 3 : 1};
`

const OnlineDriver = styled.div`
  background-color: ${white};
  border: ${({ borderColor }) => borderColor ? `1px solid ${borderColor}` : 'none'};
  border-radius: 0.16rem;
  bottom: 0;
  color: ${blazeUpBlack};
  height: 1rem;
  left: 0;
  opacity: ${({ showDriverInfo }) => showDriverInfo ? 1 : 0};
  position: absolute;
  right: 0;
  top: 0;
  width: 5rem;
`

const Dot = styled.div`
  background-color: ${({ color }) => color};
  border-radius: 50%;
  border: 1px solid ${white};
  height: 1.5rem;
  opacity: ${({ opacity }) => opacity};
  width: 1.5rem;
`
