import { connect } from 'react-redux'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ROUTES from 'src/pages/catalog/routes'
import { getSortedCatalogProducts } from 'src/redux/catalog/selectors'
import { mapToCatalogFetch, mapToCatalogSearch } from 'src/redux/catalog/actions'
import { getPromoStateId } from 'src/redux/manage-promos/selectors'
import CatalogSearch from './'

// Props passed to the component
const mapStateToProps = (state, props) => {
  return {
    list: getSortedCatalogProducts(state, 'title'),
    search: state.catalog.search,
    stateId: getPromoStateId(state)
  }
}

// Methods passed to the components
const mapDispatchToProps = (dispatch, props) => {
  return {
    searchCatalogItems: (value, stateId) => dispatch(mapToCatalogSearch(value, ROUTES.products.name, stateId)),
    fetchBrands: () => dispatch(mapToCatalogFetch(ROUTES.brands.name)),
    fetchGroups: () => dispatch(mapToCatalogFetch(ROUTES.groups.name))
  }
}

class CatalogSearchContainer extends Component {
  propTypes = {
    fetchBrands: PropTypes.func,
    fetchGroups: PropTypes.func
  }

  componentDidMount () {
    this.props.fetchBrands()
    this.props.fetchGroups()
  }

  render () {
    return (
      <CatalogSearch {...this.props} />
    )
  }
}

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CatalogSearchContainer)

export default reduxContainer
