import { WEB_CLIENTS_BASE_URL } from 'helpers/environment'
// takes a catalog cart and formats a list of deliverable catalog items
export const formatCartProducts = function (catalogCart, products) {
  const catalogCartIds = Object.keys(catalogCart)
  return catalogCartIds.map((id) => formatCartItem(id, catalogCart, products))
}

// takes a catalogCart, catalogId and products store to format a cart object
export const formatCartItem = function (catalogId, catalogCart, products) {
  return {
    // The ID being queried and set as a value here is the depot specific
    // integer ID we need this for the quantity picker to work properly
    id: products[catalogId].id,
    quantity: catalogCart[catalogId]
  }
}

export function formatConciergeUrl (slug) {
  return `${WEB_CLIENTS_BASE_URL}/concierge/${slug}`
}

export function formatPhoneNumber (phoneNumber) {
  const areaCode = phoneNumber.substr(0, 3)
  const prefix = phoneNumber.substr(3, 3)
  const lineNumber = phoneNumber.substr(6, 4)

  return `(${areaCode}) ${prefix}-${lineNumber}`
}
