import api from 'api'

export function checkIfDepotHasMETRCRules (depotObject) {
  const { latitude, longitude } = depotObject
  return async () => {
    // https://github.com/eaze/eaze-api-client/blob/master/src/routes.js#L1768
    const { data = {} } = await api.rules({
      latitude: latitude,
      longitude: longitude,
      types: 'metrcDriverManifests'
    })
    const { metrcDriverManifests = [] } = data
    return Boolean(metrcDriverManifests.length)
  }
}
