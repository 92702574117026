import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

export class ItemsSearch extends PureComponent {
  state = {
    search: ''
  }

  handleSearchChange = ({ target: { value } }) => {
    const { resetAction, type } = this.props
    this.setState(
      { search: value },
      () => {
        // if the user clears out their search results, fetch the full list
        if (value === '') {
          resetAction(type)
        }
      }
    )
  }

  componentDidUpdate () {
    if (this.props.resetQuery === true) {
      this.setState({ search: '' })
    }
  }

  handleSearchSubmit = (event) => {
    event.preventDefault()

    const { search } = this.state
    const { searchAction, type } = this.props
    if (search !== '') {
      searchAction(search, type)
    }
  }

  render () {
    const { placeholder, formClassName } = this.props
    const { search } = this.state

    return (
      <form onSubmit={this.handleSearchSubmit} className={formClassName}>
        <input
          value={search}
          placeholder={placeholder}
          type='search'
          onChange={this.handleSearchChange}
        />
      </form>
    )
  }
}

export default ItemsSearch

ItemsSearch.propTypes = {
  searchAction: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  resetQuery: PropTypes.bool,
  resetAction: PropTypes.func.isRequired
}
