import React, { PureComponent } from 'react'
import { array, func, object, string } from 'prop-types'
import debounce from 'debounce'
import styled from '@emotion/styled'
import { css } from 'emotion'

import { Flex } from 'src/microcomponents/flexbox-helpers'
import Accordion from 'components/accordion'
import Button, { DANGER, PRIMARY } from 'components/button'
import SelectProductsList from './select-products-list'
import { PRODUCT_FILTER_TYPE, GROUP_FILTER_TYPE } from 'helpers/constants'

import {
  filterTypeForm,
  filterTypeGroup,
  filterTypeLabel,
  filterTypeRadio,
  groupsContent,
  productsFormButtons,
  productsHeader,
  productsWrapper,
  title
} from './style.js'

class SelectProducts extends PureComponent {
  static propTypes = {
    brandsMap: object,
    conflictsMap: object,
    filterType: string,
    groupIds: array,
    groupsMap: object,
    onCancelSelectProducts: func.isRequired,
    onSubmitSelectProducts: func.isRequired,
    openConflictModal: func,
    productsMap: object,
    selectedState: string,
    selectedProducts: object,
    setFilterString: func,
    setFilterType: func
  }

  static defaultProps = {
    conflictsMap: {},
    groupIds: [],
    groupsMap: {},
    productsMap: {}
  }

  state = {
    filterString: '',
    filterType: this.props.filterType || '',
    selectedProducts: this.props.selectedProducts || {}
  }

  componentWillUnmount () {
    this.resetFilterString()
  }

  resetFilterString = () => {
    const { setFilterString } = this.props
    setFilterString('')
    this.setState({ filterString: '' })
  }

  onSelectProduct = (id, isChecked) => {
    const { selectedProducts } = this.state
    const newSelectedProducts = { ...selectedProducts }

    if (isChecked) {
      newSelectedProducts[id] = true
    } else {
      delete newSelectedProducts[id]
    }

    this.setState({ selectedProducts: newSelectedProducts })
  }

  handleSubmitSelectProducts = () => {
    const { onSubmitSelectProducts } = this.props
    const { selectedProducts } = this.state

    onSubmitSelectProducts(selectedProducts)
  }

  handleFilterChange = debounce((value) => {
    const { setFilterString } = this.props

    setFilterString(value)
  }, 200)

  handleFilter = ({ target: { value } }) => {
    this.handleFilterChange(value)
    this.setState({ filterString: value })
  }

  handleFilterType = ({ target: { value } }) => {
    const { setFilterType } = this.props

    this.resetFilterString()
    setFilterType(value)
    this.setState({ filterType: value })
  }

  handleSelectAllProductsInGroup = (areAllItemsInGroupSelected, items) => {
    const { selectedProducts } = this.state
    const newSelectedProducts = { ...selectedProducts }

    items.forEach((item) => {
      if (areAllItemsInGroupSelected) {
        delete newSelectedProducts[item]
      } else {
        newSelectedProducts[item] = true
      }
    })

    this.setState({ selectedProducts: newSelectedProducts })
  }

  render () {
    const { selectedProducts, filterString, filterType } = this.state
    const {
      brandsMap,
      onCancelSelectProducts,
      productsMap,
      groupsMap,
      groupIds,
      conflictsMap,
      selectedState
    } = this.props
    const selectedProductsCount = Object.keys(selectedProducts).length
    const productTitle = `Select Products (${selectedProductsCount})`

    return (
      <div className={productsWrapper}>
        <div className={productsHeader}>
          <h1 className={title}>{productTitle}</h1>
          <input
            type='text'
            onChange={this.handleFilter}
            placeholder={`Search ${filterType}s`}
            value={filterString}
          />
          <form
            className={filterTypeForm}
            onChange={this.handleFilterType}
          >
            search by:
            <div className={filterTypeGroup}>
              <label
                className={filterTypeLabel}
                htmlFor='search-product'
              >
                <input
                  className={filterTypeRadio}
                  name='search'
                  type='radio'
                  id='search-product'
                  value={PRODUCT_FILTER_TYPE}
                  defaultChecked={filterType === PRODUCT_FILTER_TYPE}
                />
                product
              </label>
              <label
                className={filterTypeLabel}
                htmlFor='search-group'
              >
                <input
                  className={filterTypeRadio}
                  name='search'
                  type='radio'
                  id='search-group'
                  value={GROUP_FILTER_TYPE}
                  defaultChecked={filterType === GROUP_FILTER_TYPE}
                />
                group
              </label>
            </div>
          </form>
        </div>
        <div className={groupsContent}>
          <AccordionGroups
            brandsMap={brandsMap}
            conflictsMap={conflictsMap}
            groupIds={groupIds}
            groupsMap={groupsMap}
            handleSelectAllProductsInGroup={this.handleSelectAllProductsInGroup}
            onSelectProduct={this.onSelectProduct}
            openConflictModal={this.props.openConflictModal}
            productsMap={productsMap}
            selectedState={selectedState}
            selectedProducts={selectedProducts}
          />
        </div>
        <div className={productsFormButtons}>
          <Flex top={3}>
            <Button
              type={PRIMARY}
              onClick={this.handleSubmitSelectProducts}
            >
              Save Products
            </Button>
          </Flex>
          <Flex top={1}>
            <Button
              type={DANGER}
              onClick={onCancelSelectProducts}
            >
              Edit Promo Details
            </Button>
          </Flex>
        </div>
      </div>
    )
  }
}

class AccordionGroups extends PureComponent {
  static propTypes = {
    brandsMap: object,
    conflictsMap: object,
    groupIds: array,
    groupsMap: object,
    handleSelectAllProductsInGroup: func,
    onSelectProduct: func,
    openConflictModal: func,
    productsMap: object,
    selectedState: string,
    selectedProducts: object
  }

  render () {
    const {
      groupIds,
      groupsMap,
      handleSelectAllProductsInGroup,
      selectedState,
      selectedProducts
    } = this.props

    const filteredGroups = groupIds.filter(el => selectedState === groupsMap[el].state_id)

    return filteredGroups.map((id) => {
      const group = groupsMap[id] || { items: [] }
      const { items } = group

      if (!items.length) return null

      const areAllItemsInGroupSelected = !items.some((item) => !selectedProducts[item])

      const accordionButtonContent = (
        <AccordionButtonText htmlFor={group.id}>
          {group.name} - {items.length}
        </AccordionButtonText>
      )

      return (
        <AccordionWrap key={id}>
          <label>
            <StyledCheckbox
              checked={areAllItemsInGroupSelected}
              onChange={() => handleSelectAllProductsInGroup(areAllItemsInGroupSelected, items)}
              type='checkbox'
            />
          </label>
          <Accordion
            key={`group-accordion-element-${id}`}
            buttonClass={AccordionButtonStyle}
            buttonContent={accordionButtonContent}
            iconColor='#fff'
          >
            <SelectProductsList
              brandsMap={this.props.brandsMap}
              productsMap={this.props.productsMap}
              items={items}
              onSelectProduct={this.props.onSelectProduct}
              selectedProducts={this.props.selectedProducts}
              conflictsMap={this.props.conflictsMap}
              openConflictModal={this.props.openConflictModal}
            />
          </Accordion>
        </AccordionWrap>
      )
    })
  }
}

const AccordionButtonText = styled.label`
  margin-left: 1rem;
`

const AccordionWrap = styled.div`
  display: flex;
  padding: 0.5rem;
`

const AccordionButtonStyle = css`
  align-items: center;
  background-color: transparent;
  border-bottom: 2px solid var(--tertiaryDark);
  border: none;
  color: var(--white);
  cursor: pointer;
  font-size: 1.4rem;
  margin-left: 1rem;
  padding-bottom: 1rem;
  padding-top: 0.2rem;

  svg > g {
    stroke: var(--white);
  }
`

const StyledCheckbox = styled.input`
  width: auto;
`

export default SelectProducts
