import { connect } from 'react-redux'
import React from 'react'
import { func, string } from 'prop-types'

import { setUserToken } from 'src/redux/action-wrappers/user'
import McRouter from './'

// Props passed to the component
const mapStateToProps = (state, props) => {
  return {
    token: state.user.xAuthToken,
    store: props.store,
    firebase: props.firebase
  }
}

// Methods passed to the components
const mapDispatchToProps = (dispatch, props) => {
  return {
    setUserToken: (token) => dispatch(setUserToken(token))
  }
}

class McRouterContainer extends React.Component {
  static propTypes = {
    setUserToken: func,
    token: string
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillMount () {
  /* eslint-enable camelcase */
    this.props.setUserToken(this.props.token)
  }

  render () {
    return (
      <McRouter {...this.props} />
    )
  }
}

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(McRouterContainer)

export default reduxContainer
