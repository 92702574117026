import styled from '@emotion/styled'
import { midGray, primaryDark, secondaryDark, tertiaryDark, white } from 'helpers/css-variables'

const Container = styled.div`
  background-color: ${secondaryDark};
  display: flex;
  padding: 1.5rem;
  margin-bottom: 2rem;
  border-radius: 4px;

  > * {
    font-family: system-ui;
    color: ${white};
  }
`

const LeftSideContainer = styled.div`
  flex: 0 0 75%;
`

const RightSideContainer = styled.div`
  flex: 0 0 25%;
`

const ContentBlock = styled.div`
  margin-bottom: 1rem;
  font-size: 1.5rem;
`

const OrderHeader = styled(ContentBlock)`
  font-size: 2.2rem;
  display: flex;
  justify-content: space-between;
  font-weight: 800;
  position: relative;
`

const DeliveryText = styled.div`
  text-transform: uppercase;
`

const OrderLinkHeader = styled.a`
  color: ${white};
  text-decoration: none;
  margin-left: 0.7rem;
  font-size: 2.2rem;

  &:hover {
    text-decoration: underline;
  }
`

const OrderLink = styled(OrderLinkHeader)`
  font-size: 1.3rem;
`

const SummaryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const RightContent = styled.div`
  margin-left: 4rem;
`

const Title = styled.div`
  color: ${white};
  font-size: 1.5rem;
`

const Subtitle = styled.div`
  color: ${white};
  opacity: 0.5;
  font-size: 1.2rem;
`

const Table = styled.table`
  width: 100%;
  border-radius: 2px;
  border: 2px solid ${midGray};
`

// TODO: hover opacity needed to override our global table tr hover
const Row = styled.tr`
  background-color: ${tertiaryDark};
  height: 3rem;

  &:hover {
    opacity: 1;
  }

  &:nth-child(odd) {
    background-color: ${primaryDark};
  }

  td {
    font-size: 1.3rem;
    padding: 0;
    line-height: 3.5rem;
    color: ${white};
    padding-left: 1rem;
    padding-right: 1rem;
    opacity: 0.5;
  }
`

const Address = styled.span`
  color: ${white};
  opacity: 0.5;
  margin-left: 0.3rem;
`

export default {
  Address,
  Container,
  ContentBlock,
  DeliveryText,
  LeftSideContainer,
  OrderHeader,
  OrderLink,
  OrderLinkHeader,
  RightContent,
  RightSideContainer,
  Row,
  Subtitle,
  SummaryHeader,
  Table,
  Title
}
