import React from 'react'

export default function RestockIcon ({ color = '#00AAE7', height = '3em', width = '3em' }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 24 20'
      version='1.1'>
    >
      <title>noun_Stock_2014925</title>
      <desc>Created with Sketch.</desc>
      <g id='Page-1' stroke='none' strokeWidth='1' fill={color} fillRule='evenodd'>
        <g id='Depot-View---Drivers-' transform='translate(-44.000000, -575.000000)' fill={color} fillRule='nonzero'>
          <g id='TAB-NAV' transform='translate(19.000000, 77.000000)'>
            <g id='Group-6' transform='translate(9.000000, 498.000000)'>
              <g id='noun_Stock_2014925' transform='translate(16.000000, 0.000000)'>
                <g id='Group'>
                  <path d='M22.5,18 L1.5,18 L1.5,18.75 C1.5,19.1642135 1.16421355,19.5 0.75,19.5 C0.33578644,19.5 0,19.1642135 0,18.75 L0,0.75 C0,0.33578645 0.33578644,0 0.75,0 C1.16421355,0 1.5,0.33578645 1.5,0.75 L1.5,7 L22.5,7 L22.5,0.75 C22.5,0.33578645 22.8357865,0 23.25,0 C23.6642135,0 24,0.33578645 24,0.75 L24,18.75 C24,19.1642135 23.6642135,19.5 23.25,19.5 C22.8357865,19.5 22.5,19.1642135 22.5,18.75 L22.5,18 Z M22.5,16.5 L22.5,8.5 L1.5,8.5 L1.5,16.5 L22.5,16.5 Z M16.5,4.5 L20,4.5 L20,1.5 L16.5,1.5 L16.5,4.5 Z M21.5,0.5 L21.5,5.5 C21.5,5.77614235 21.2761424,6 21,6 L15.5,6 C15.2238576,6 15,5.77614235 15,5.5 L15,0.5 C15,0.22385765 15.2238576,0 15.5,0 L21,0 C21.2761424,0 21.5,0.22385765 21.5,0.5 Z M4,14 L7.5,14 L7.5,11 L4,11 L4,14 Z M9,10 L9,15 C9,15.2761424 8.77614235,15.5 8.5,15.5 L3,15.5 C2.72385765,15.5 2.5,15.2761424 2.5,15 L2.5,10 C2.5,9.72385765 2.72385765,9.5 3,9.5 L8.5,9.5 C8.77614235,9.5 9,9.72385765 9,10 Z M11.5,14 L15,14 L15,11 L11.5,11 L11.5,14 Z M16.5,10 L16.5,15 C16.5,15.2761424 16.2761424,15.5 16,15.5 L10.5,15.5 C10.2238576,15.5 10,15.2761424 10,15 L10,10 C10,9.72385765 10.2238576,9.5 10.5,9.5 L16,9.5 C16.2761424,9.5 16.5,9.72385765 16.5,10 Z' id='Shape' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
