import React from 'react'
import { bool, func } from 'prop-types'
import styled from '@emotion/styled'
import { css } from 'emotion'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Flex, Column } from 'src/microcomponents/flexbox-helpers'

import { dataPropTypeShape } from './types'
import { getIsValidCityAndState, getIsValidZipcodeAndCity } from 'src/redux/territory-management/helpers'
import Switch from 'src/microcomponents/switch'
import Button from 'components/button'
import { fontSizeLabel, letterSpacingLabel, mcGreen, mcRed, tertiaryDark, white } from 'src/helpers/css-variables'

const errorMessages = {
  city: 'A territory must have a city.',
  state: 'A territory must have a state.',
  zipcodeCity: 'A territory must have a City or Zip Code but NOT both.',
  cityState: 'A territory must have a State and City.',
  zipcodeState: 'A territory cannot have a Zip Code and a State.'
}

const validate = (values) => {
  const { city, state, zipcode } = values
  const errors = {}
  const isValidCityAndState = getIsValidCityAndState(zipcode, city, state)
  const isValidZipcodeAndCity = getIsValidZipcodeAndCity(zipcode, city)

  if (!isValidZipcodeAndCity) {
    errors.city = errorMessages.zipcodeCity
    errors.zipcode = errorMessages.zipcodeCity
  }

  if (!isValidCityAndState && city) {
    errors.city = errorMessages.city
    errors.state = errorMessages.state
  }

  if (!isValidCityAndState && zipcode) {
    errors.state = errorMessages.zipcodeState
    errors.zipcode = errorMessages.zipcodeState
  }
  return errors
}

const SUBMIT_MODE = {
  delete: 'DELETE',
  submit: 'SUBMIT'
}

const EditTerritoryForm = (props) => {
  const getSubmitMode = mode => values => {
    if (mode === SUBMIT_MODE.submit) {
      props.handleFormSubmit(values)
    } else {
      props.handleFormDelete(values)
    }
  }

  const onSubmit = getSubmitMode(SUBMIT_MODE.submit)
  const onDelete = getSubmitMode(SUBMIT_MODE.delete)

  const renderDeleteButton = () => (
    // Formik does not make it easy to have more than 1 submit type button in a form. Difficult to access the form values. Because the delete territory function doesn't need form values, I shortcut this by using the territory data passed from props.
    props.isEdit && (
      <Flex top={1}>
        <Button
          componentStyle={{ backgroundColor: mcRed }}
          onClick={(e) => {
            e.preventDefault()
            onDelete(props.territoryData)
          }}
          type='button'
        >
          Delete
        </Button>
      </Flex>
    )
  )

  const styleErrorInput = ({ errors, touched }, name) =>
    touched[name] && errors[name] ? RedBorderInput : null

  return (
    <Formik
      enableReinitialize
      initialValues={props.territoryData}
      onSubmit={onSubmit}
      validate={validate}
    >
      {(formik) => {
        const {
          errors,
          handleBlur,
          initialValues,
          isSubmitting,
          setFieldValue,
          touched,
          values
        } = formik
        return (
          <Form>
            <Flex full componentStyle={{ alignItems: 'center', margin: '1rem 0 1rem -2rem' }}>
              <SwitchWrapper>
                <span>Adult Use</span>
                <Switch
                  defaultChecked={initialValues.enabled}
                  enabled={values.enabled}
                  onChange={(checked) => {
                    setFieldValue('enabled', checked)
                  }}
                />
              </SwitchWrapper>
              <SwitchWrapper>
                <span>ID Scan</span>
                <Switch
                  defaultChecked={initialValues.identityScanRequired}
                  enabled={values.identityScanRequired}
                  onChange={(checked) => setFieldValue('identityScanRequired', checked)}
                />
              </SwitchWrapper>
              <SwitchWrapper>
                <span>Accepts USA IDs Only</span>
                <Switch
                  defaultChecked={initialValues.usaOnly}
                  enabled={values.usaOnly}
                  onChange={(checked) => setFieldValue('usaOnly', checked)}
                />
              </SwitchWrapper>
              <SwitchWrapper>
                <span>Selfie Required</span>
                <Switch
                  defaultChecked={initialValues.selfieRequired}
                  enabled={values.selfieRequired}
                  onChange={(checked) => setFieldValue('selfieRequired', checked)}
                />
              </SwitchWrapper>
            </Flex>

            <Column top={1}>
              <Label htmlFor='city'>City</Label>
              <Field
                type='text'
                name='city'
                id='city'
                onBlur={handleBlur}
                className={styleErrorInput({ errors, touched }, 'city')}
              />
              <RedErrorMessage name='city' component='div' />
            </Column>
            <Column top={1}>
              <Label htmlFor='state'>State</Label>
              <SelectWrapper>
                <Field
                  as='select'
                  name='state'
                  id='state'
                  onBlur={handleBlur}
                  className={styleErrorInput({ errors, touched }, 'state')}
                >
                  <option value='' />
                  <option value='CA'>CA</option>
                  <option value='MI'>MI</option>
                </Field>
              </SelectWrapper>
              <RedErrorMessage name='state' component='div' />
            </Column>
            <Column top={1}>
              <Label htmlFor='zipcode'>Zip Code</Label>
              <Field
                type='string'
                name='zipcode'
                id='zipcode'
                onBlur={handleBlur}
                className={styleErrorInput({ errors, touched }, 'zipcode')}
              />
              <RedErrorMessage name='zipcode' component='div' />
            </Column>
            <Column top={1}>
              <Label htmlFor='comments'>Comments</Label>
              <Field
                as='textarea'
                name='comments'
                id='comments'
                cols='2'
                rows='5'
              />
            </Column>
            <Flex top={3}>
              <Button
                componentStyle={{ backgroundColor: mcGreen }}
                disabled={Boolean(Object.values(errors).length) || isSubmitting}
                type='submit'
              >
                Submit
              </Button>
            </Flex>
            {renderDeleteButton()}
          </Form>
        )
      }}
    </Formik>
  )
}

EditTerritoryForm.propTypes = {
  handleFormDelete: func,
  handleFormSubmit: func,
  // eslint-disable-next-line react/no-unused-prop-types
  isEdit: bool, // this prop is used, linter...
  territoryData: dataPropTypeShape
}

const SelectWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  position: relative;

  & > select {
    width: 100%;
  }

  &:after {
    content: '▸';
    transform: rotate(90deg);
    position: absolute;
    bottom: 0;
    top: 0;
    z-index: 1000;
    right: 2rem;
  }

  select {
    -webkit-appearance: none;
    width: 100%;
    height: 4rem;
    padding-left: 1rem;
    font-size: 1.6rem;
    color: ${white};
    border: 0;
    background-color: ${tertiaryDark};
    cursor: pointer;
  }

  select:disabled {
    background: transparent;
    border: 1px solid ${tertiaryDark};
  }
`

const SwitchWrapper = styled.div`
  margin-left: 2rem;
  margin-bottom: 0.5rem;
  text-align: right;

  & span {
    margin-right: 0.5rem;
  }
`

const RedErrorMessage = styled(ErrorMessage)`
  padding-top: 0.3rem;
  color: ${mcRed};
`

const RedBorderInput = css`
  box-shadow: 0 0 0 0.1rem ${mcRed};
`

const Label = styled.label`
  margin-bottom: 0.5rem;
  font-size: ${fontSizeLabel};
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: ${letterSpacingLabel});
`

export default EditTerritoryForm
