import React from 'react'

export default function Plus () {
  return (
    <svg width='10' height='10' viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'>
      <g id='Page-1' fill='none' fillRule='evenodd'>
        <g id='Group-14' transform='translate(-1 -1)'>
          <g id='Group-12'>
            <rect id='Rectangle' fillOpacity='0' fill='#D8D8D8' fillRule='nonzero' width='12' height='12' />
            <path d='M4.5,4.5 L4.5,2.5 C4.5,1.67157288 5.17157288,1 6,1 C6.82842712,1 7.5,1.67157288 7.5,2.5 L7.5,4.5 L9.5,4.5 C10.3284271,4.5 11,5.17157288 11,6 C11,6.82842712 10.3284271,7.5 9.5,7.5 L7.5,7.5 L7.5,9.5 C7.5,10.3284271 6.82842712,11 6,11 C5.17157288,11 4.5,10.3284271 4.5,9.5 L4.5,7.5 L2.5,7.5 C1.67157288,7.5 1,6.82842712 1,6 C1,5.17157288 1.67157288,4.5 2.5,4.5 L4.5,4.5 Z' id='Combined-Shape' fill='#FFF' />
          </g>
        </g>
      </g>
    </svg>
  )
}
