import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { array } from 'prop-types'
import MCColors from 'src/helpers/css-variables'
const { mcGreen, mcRed, mcYellow } = MCColors

const alertStyle = {
  WARNING: mcYellow,
  SUCCESS: mcGreen,
  ERROR: mcRed
}

const Alert = ({ notifications }) => (
  <AlertContainer>
    <PositionRelativeDiv>
      {notifications.map(({ message, type, id }) => (
        <AlertContent color={alertStyle[type]} key={id}>
          {message}
        </AlertContent>
      ))}
    </PositionRelativeDiv>
  </AlertContainer>
)

Alert.propTypes = {
  notifications: array
}

export default Alert

const AlertContainer = styled.div`
  display: flex;
  height: 0;
  justify-content: center;
  position: fixed;
  top: 5rem;
  width: 100%;
  z-index: 1000;
`

const PositionRelativeDiv = styled.div`
  position: relative;
`

const flyIn = keyframes`
  from {
    transform: translateY(-10rem) scale(0.4);
    opacity: 0;
  }
  to {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
`

// height: 0 ensures alert doesn't block clicking on UI elements that the actual notification does not obscure
const AlertContent = styled.div`
  animation: ${flyIn} 0.2s ease-in-out;
  background-color: ${({ color }) => color};
  border-radius: 0.4rem;
  box-shadow: 0 0.5rem 5rem rgba(0,0,0,0.5);
  color: white;
  margin-top: 1rem;
  padding: 1rem;
  width: 30rem;
  word-wrap: break-word;
`
