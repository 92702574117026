/**
 * Setting up the catalog container
 */

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { bool, func, object, string } from 'prop-types'

import {
  fetchGroupAndApplyStateFilter,
  sortedItems,
  setGroupItemsByFilter,
  createOrUpdateGroup,
  resetGroupItem,
  updateGroupObject
} from 'src/redux/group-item/actions'
import { getBrandsMap } from 'src/redux/brands-map/selectors'
import { getSpeciesMap } from 'src/redux/species-map/selectors'
import { fetchAllDispensaries } from 'src/redux/dispensaries/actions'
import { getActiveDepotsGroupedByState } from 'src/redux/dispensaries/selectors'
import { uploadPublicFile } from 'src/redux/files/api'
import { areWeLoading } from 'src/redux/loading/selectors'
import ROUTES from 'src/pages/catalog/routes'
import { getCatalogStatesAvailable } from 'src/redux/catalog/selectors'
import { mapToCatalogFetch, mapToCatalogSearch, fetchLocationStates, fetchSpecies, setCatalogStateFilter } from 'src/redux/catalog/actions'
import { getProductsRelevantToGroup, getProductsInGroup, getGroupStateId } from 'src/redux/group-item/selectors'

import EditGroup from './'

// Props passed to the component
const mapStateToProps = (state, props) => {
  const id = props.match.params.id || '' // '' = false if we're coming into /create

  // TODO: These should be selectors not customized props that are then passed into a component
  return {
    activeDepotsByState: getActiveDepotsGroupedByState(state),
    brandsMap: getBrandsMap(state),
    loading: areWeLoading(state),
    groupId: id,
    groupItem: state.groupItem,
    hasBrands: !!state.catalog.brands.length,
    hasSpecies: !!state.catalog.species.length,
    items: {
      groupItems: getProductsInGroup(state),
      products: getProductsRelevantToGroup(state)
    },
    speciesMap: getSpeciesMap(state),
    statesAvailable: getCatalogStatesAvailable(state),
    stateId: getGroupStateId(state),
    brands: state.catalog.brands,
    types: state.catalog.types,
    subtypes: state.catalog.subtypes
  }
}

// Methods passed to the components
const mapDispatchToProps = dispatch => {
  return {
    createOrUpdateGroup: () => dispatch(createOrUpdateGroup()),
    fetchAllDispensaries: () => dispatch(fetchAllDispensaries()),
    fetchCatalog: () => dispatch(mapToCatalogFetch(ROUTES.products.name)),
    fetchCatalogBrands: () => dispatch(mapToCatalogFetch(ROUTES.brands.name)),
    fetchGroupAndApplyStateFilter: (id) => dispatch(fetchGroupAndApplyStateFilter(id)),
    fetchLocationStates: () => dispatch(fetchLocationStates()),
    fetchSpecies: () => dispatch(fetchSpecies()),
    resetGroupItem: () => dispatch(resetGroupItem()),
    searchCatalog: (value, state) => dispatch(mapToCatalogSearch(value, ROUTES.products.name, state)),
    setCatalogStateFilter: (state) => dispatch(setCatalogStateFilter(state)),
    sortedItems: items => dispatch(sortedItems(items)),
    updateGroupObject: v => dispatch(updateGroupObject(v)),
    uploadPublicFile: (formData) => dispatch(uploadPublicFile(formData)),
    fetchCatalogTypes: () => dispatch(mapToCatalogFetch(ROUTES.types.name)),
    fetchCatalogSubtypes: () => dispatch(mapToCatalogFetch(ROUTES.subtypes.name)),
    setGroupItemsByFilter: filters => dispatch(setGroupItemsByFilter(filters))
  }
}

class EditGroupContainer extends PureComponent {
  static propTypes = {
    activeDepotsByState: object,
    fetchAllDispensaries: func,
    fetchCatalog: func,
    fetchCatalogBrands: func,
    fetchGroupAndApplyStateFilter: func,
    fetchLocationStates: func,
    fetchSpecies: func,
    groupId: string,
    hasBrands: bool,
    hasSpecies: bool,
    fetchCatalogTypes: func,
    fetchCatalogSubtypes: func,
    setGroupItemsByFilter: func
  }

  componentDidMount () {
    const {
      activeDepotsByState,
      fetchAllDispensaries,
      fetchCatalog,
      fetchCatalogBrands,
      fetchGroupAndApplyStateFilter,
      fetchLocationStates,
      fetchSpecies,
      fetchCatalogTypes,
      fetchCatalogSubtypes,
      groupId,
      hasBrands,
      hasSpecies
    } = this.props
    const hasActiveDepots = Object.values(activeDepotsByState).flat().length !== 0

    if (groupId) {
      // this action also fetches the product catalog after setting the state filter
      fetchGroupAndApplyStateFilter(groupId)
    } else {
      fetchCatalog()
    }

    if (!hasBrands) {
      fetchCatalogBrands()
    }

    if (!hasSpecies) {
      fetchSpecies()
    }

    fetchLocationStates()

    if (!hasActiveDepots) {
      fetchAllDispensaries()
    }

    fetchCatalogSubtypes()

    fetchCatalogTypes()
  }

  // TODO: This passing all props that are not from the redux store directly but constructed in container is very difficult to work with
  render () {
    return (
      <EditGroup {...this.props} />
    )
  }
}

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditGroupContainer)

export default withRouter(reduxContainer)
