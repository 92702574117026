import React from 'react'
import { array, func, string } from 'prop-types'
import { Column } from 'src/microcomponents/flexbox-helpers'
import InputLabel from 'src/microcomponents/input-label'
import CatalogSearch from 'src/components/catalog-search/container'
import CatalogGroupSearch from './CatalogGroupSearch/container'
import { selectContainer } from '../style.js'

const CatalogSelector = ({ idType, idValue, catalogBrands, setPromoIdValue }) => {
  switch (idType) {
    case 'item':
      return (
        <div>
          <InputLabel content='Catalog Item' />
          <CatalogSearch setResult={setPromoIdValue} />
        </div>
      )
    case 'group':
      return (
        <Column>
          <InputLabel content='Catalog Group' />
          <CatalogGroupSearch setResult={setPromoIdValue} />
        </Column>
      )
    case 'brand':
      return (
        <Column>
          <InputLabel content='Catalog Brand' />
          <div className={selectContainer}>
            <select onChange={setPromoIdValue} value={idValue || 0}>
              <option value={0} disabled='disabled'>Select Brand</option>
              {catalogBrands.map((opt, ind) => <option key={ind} value={opt.id}>{opt.name}</option>)}
            </select>
          </div>
        </Column>
      )
    default:
      return null
  }
}

CatalogSelector.propTypes = {
  idType: string,
  idValue: string,
  catalogBrands: array,
  setPromoIdValue: func
}

export default CatalogSelector
