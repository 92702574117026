import { connect } from 'react-redux'

import { setUserToken } from 'src/redux/action-wrappers/user'
import { closeNavSlider } from 'src/redux/navigation/actions'
import { getXAuthToken } from 'src/redux/users/selectors'
import Screen from './'

// Props passed to the component
const mapStateToProps = (state, props) => {
  return {
    navIsOpen: state.navigation.slider,
    token: getXAuthToken(state)
  }
}

// Methods passed to the components
const mapDispatchToProps = (dispatch, props) => {
  return {
    closeNavSlider: () => dispatch(closeNavSlider()),
    setUserToken: (token) => dispatch(setUserToken(token))
  }
}

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Screen)

export default reduxContainer
