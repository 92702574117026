import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { array, bool, func, number, object, oneOfType, string } from 'prop-types'
import isEmptyObject from 'is-empty-object'
import window from 'global/window'
import LoadingSpinner from 'components/spinner'

import history from 'components/router/history'
import { DRIVER_ORDERS_KEY, DRIVER_INVENTORY_KEY } from 'helpers/deliveries'
import {
  CLOSE_DRIVER_DRAWER,
  DEFAULT_DRIVER_CONTENT_TAB,
  windowConfirmUnsavedChanges
} from 'helpers/drivers'
import DriverInfo from './driver-info'
import ContentNavigation from './content-navigation'
import DriverOrders from './driver-orders'
import DriverInventory from './driver-inventory'
import Print from 'microcomponents/print-button'
import Divider from 'src/microcomponents/divider'
import { ERROR } from 'src/redux/alerts/actions'

import { CHECKLIST_URL } from 'helpers/environment'
import { lightGray, primaryDark, white } from 'helpers/css-variables'
import { dateOnly, timeOnly } from 'helpers/date'

export default class Breakdown extends PureComponent {
  static propTypes = {
    changeDriverHistoryDate: func,
    changeDriverMode: func,
    didInventoryChange: bool,
    driverCurrentVehicle: object,
    driverDetailsContent: string,
    driverInventoryInfo: object,
    driverMETRCInfoDrawerOpen: bool,
    driverOrders: array,
    fetchFocusedDriverInventory: func,
    focusedDriver: object,
    focusedDriverDepotName: string,
    focusedDriverId: oneOfType([number, string]),
    isAdmin: bool,
    isDeliveriesRoute: bool,
    isDeliveriesTab: bool,
    isInventoryLoading: bool,
    isOrdersLoading: bool,
    orderHistoryDrawer: bool,
    pushNotification: func,
    setDriverDetailsContent: func,
    setDriverDeliveryType: func,
    setFocusedDriver: func,
    showDriverMETRCInfoDrawerToggle: bool,
    toggleDriverMETRCInfoDrawer: func,
    trackInventoryChanges: func,
    rerouteOrder: func,
    userEmail: string,
    updateDriverInventory: func
  }

  static defaultProps = {
    driverInventoryInfo: {
      inventory: [],
      inventoryChecksum: {}
    },
    showDriverMETRCInfoDrawerToggle: false
  }

  state = {
    downloadUrl: '',
    downloadFileName: '',
    printPending: false
  }

  handleCloseBreakdown = () => {
    const { didInventoryChange, trackInventoryChanges } = this.props
    history.replace({ search: '' })

    if (didInventoryChange) {
      if (windowConfirmUnsavedChanges()) {
        trackInventoryChanges([])
        this.resetTabAndCloseDrawer()
      }
    } else {
      this.resetTabAndCloseDrawer()
    }
  }

  resetTabAndCloseDrawer = () => {
    const { setDriverDetailsContent, setFocusedDriver } = this.props
    setDriverDetailsContent(DEFAULT_DRIVER_CONTENT_TAB)
    setFocusedDriver(CLOSE_DRIVER_DRAWER)
  }

  getCaseTotalValue = () => {
    const { inventory } = this.props.driverInventoryInfo

    // we cannot rely on the case total because total value needs to reflect what is currently in the driver's inventory throughout the day
    // so we will just add up their inventory...
    const filteredInventory = inventory.filter((item) => item.quantity > 0)
    const total = filteredInventory.reduce(
      (acc, product) => acc + product.price * product.quantity,
      0
    )

    return total || 0
  }

  handlePrintClickFn = async () => {
    const {
      driverInventoryInfo,
      focusedDriver,
      focusedDriverDepotName,
      pushNotification,
      userEmail
    } = this.props

    this.setState({ printPending: true })

    // return early if inventory is empty or hasn't loaded yet
    const inventory = driverInventoryInfo.inventory.filter((item) => item.quantity > 0)
    const driverName = `${focusedDriver.displayName ||
      focusedDriver.firstName + ' ' + focusedDriver.lastName}`
    if (inventory.length === 0) {
      this.setState({ printPending: false })
      pushNotification(`${driverName}'s inventory is empty`, ERROR)
      return
    }

    const caseTotal = this.getCaseTotalValue(inventory)

    // While checklists are usually printed by the depot staff, it is more accurate to reflect a timestamp of who generated the file.
    // timeZone is the local time zone of the user who is generating the checklist
    const payload = {
      name: userEmail,
      drivers: [
        {
          caseName: focusedDriver.caseTemplateName || 'N/A',
          caseTotal: caseTotal,
          id: focusedDriver.id,
          inventory: inventory,
          name: driverName
        }
      ],
      dispensary: focusedDriver.depotName || focusedDriverDepotName,
      timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    // post to the endpoint and process the response
    const response = await window.fetch(`${CHECKLIST_URL}/checklist`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })

    const blob = await response.blob()
    const downloadUrl = window.URL.createObjectURL(blob)
    const downloadFileName = `inventory-confirmation-${dateOnly()}-${timeOnly()}.pdf`

    // update state with generated file metadata
    this.setState({ downloadUrl })
    this.setState({ downloadFileName })

    this.triggerDownload()
    this.setState({ printPending: false })
  }

  triggerDownload = () => {
    // create download anchor tag
    const downloadLink = document.createElement('a')
    downloadLink.name = this.state.downloadFileName
    downloadLink.href = this.state.downloadUrl
    downloadLink.download = this.state.downloadFileName
    downloadLink.target = '_blank'
    downloadLink.style = 'display:none;'

    // append and initiate click
    document.body.appendChild(downloadLink)
    downloadLink.click()

    // cleanup
    setTimeout(() => {
      document.body.removeChild(downloadLink)
    }, 0)
  }

  handleCSVClickFn = () => {
    const {
      driverInventoryInfo: { inventory },
      focusedDriver: { caseTemplateName, depotId, displayName, firstName, id, lastName }
    } = this.props

    if (!inventory.length) {
      return
    }

    const dateString = dateOnly()
    const driverName = displayName || `${firstName} ${lastName}`
    const driverTotalValue = this.getCaseTotalValue(inventory)
    const csvContentPrefix = 'data:text/csv;charset=utf-8,'
    let csvContent = 'driver name,driver ID,end quantity,product name,brand name,type name,catalogItemId,depotId,caseName,--,total cannabis value for entire case\n'

    inventory.forEach((product, index) => {
      const { brand, catalogItemId, name, productType, quantity } = product
      let row = `${driverName},${id},${quantity},${name},${brand.name},${productType.name},${catalogItemId},${depotId},${caseTemplateName || 'N/A'},,`
      if (index === 0) {
        row += driverTotalValue
      }
      csvContent += row + '\n'
    })

    const encodedUri = csvContentPrefix + encodeURIComponent(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', `driver-inventory-${id}-${dateString}.csv`) // need download attr in order to customize file name
    document.body.appendChild(link) // Required for FF
    link.click()
    document.body.removeChild(link)
  }

  render () {
    const {
      changeDriverHistoryDate,
      changeDriverMode,
      didInventoryChange,
      driverCurrentVehicle,
      driverDetailsContent,
      driverInventoryInfo,
      driverMETRCInfoDrawerOpen,
      driverOrders,
      fetchFocusedDriverInventory,
      focusedDriver,
      focusedDriverDepotName,
      focusedDriverId,
      isAdmin,
      isDeliveriesRoute,
      isDeliveriesTab,
      isInventoryLoading,
      isOrdersLoading,
      orderHistoryDrawer,
      rerouteOrder,
      setDriverDeliveryType,
      setDriverDetailsContent,
      showDriverMETRCInfoDrawerToggle,
      toggleDriverMETRCInfoDrawer,
      trackInventoryChanges,
      updateDriverInventory
    } = this.props

    const {
      deliveryType,
      displayName, // drivers that are not delivering don't have this (BE)
      driverMode,
      email,
      firstName,
      id,
      lastName,
      mobilePhone,
      mobilePhoneDisplay, // drivers that are not delivering don't have this (BE)
      pictureUrl
    } = focusedDriver
    const showDriverLoadingState = focusedDriverId && isEmptyObject(focusedDriver)

    if (showDriverLoadingState) {
      return (
        <Container>
          <Loading>
            <LoadingSpinner />
          </Loading>
        </Container>
      )
    }

    return (
      <Container className='Breakdown' orderHistoryDrawer={orderHistoryDrawer}>
        <CloseButton onClick={this.handleCloseBreakdown}>&#10005;</CloseButton>
        <Header>
          <DriverInfoContainer>
            <DriverInfo
              changeDriverMode={changeDriverMode}
              deliveryType={deliveryType}
              driverCurrentVehicle={driverCurrentVehicle}
              driverMode={driverMode}
              driverMETRCInfoDrawerOpen={driverMETRCInfoDrawerOpen}
              depotDisplayName={focusedDriverDepotName}
              displayName={displayName || `${firstName} ${lastName}`}
              email={email}
              id={id}
              mobilePhoneDisplay={mobilePhoneDisplay || mobilePhone}
              pictureUrl={pictureUrl}
              setDriverDeliveryType={setDriverDeliveryType}
              showDriverMETRCInfoDrawerToggle={showDriverMETRCInfoDrawerToggle}
              toggleDriverMETRCInfoDrawer={toggleDriverMETRCInfoDrawer}
            />
          </DriverInfoContainer>
        </Header>
        <Divider />
        <ContentContainer>
          <ContentHeader>
            <ContentNavigation
              fetchFocusedDriverInventory={fetchFocusedDriverInventory}
              focusedDriverId={focusedDriverId}
              didInventoryChange={didInventoryChange}
              driverDetailsContent={driverDetailsContent}
              setDriverDetailsContent={setDriverDetailsContent}
              isInventoryLoading={isInventoryLoading}
              isOrdersLoading={isOrdersLoading}
              trackInventoryChanges={trackInventoryChanges}
            />
            <span>
              {driverDetailsContent === 'inventory' && (
                <span>
                  <InventoryActionContainer onClick={() => this.handleCSVClickFn()}>
                    <span>Download CSV</span>
                  </InventoryActionContainer>
                  <InventoryActionContainer onClick={() => this.handlePrintClickFn()}>
                    {/* FIXME: Firefox doesn't like rems so using em for short term. Need to think of a longer term solution/standardization to support cross-browser compatibility https://bugzilla.mozilla.org/show_bug.cgi?format=default&id=1231147 */}
                    <Print
                      color={lightGray}
                      size='1.4em'
                      right='1rem'
                      componentStyle={{
                        padding: '0',
                        verticalAlign: 'text-bottom'
                      }}
                    />
                    <span>Print</span>
                  </InventoryActionContainer>
                </span>
              )}
            </span>
          </ContentHeader>
          <Content>
            {
              {
                [DRIVER_ORDERS_KEY]: (
                  <DriverOrders
                    isLoading={isOrdersLoading}
                    focusedDriverId={focusedDriverId}
                    orders={driverOrders}
                    orderHistoryDrawer={orderHistoryDrawer}
                    changeDriverHistoryDate={changeDriverHistoryDate}
                    isAdmin={isAdmin}
                    rerouteOrder={rerouteOrder}
                  />
                ),
                [DRIVER_INVENTORY_KEY]: (
                  <DriverInventory
                    driverInventoryInfo={driverInventoryInfo}
                    focusedDriverId={focusedDriverId}
                    isDeliveriesRoute={isDeliveriesRoute}
                    isDeliveriesTab={isDeliveriesTab}
                    isInventoryLoading={isInventoryLoading}
                    isLoading={isInventoryLoading}
                    trackInventoryChanges={trackInventoryChanges}
                    updateDriverInventory={updateDriverInventory}
                  />
                )
              }[driverDetailsContent]
            }
          </Content>
        </ContentContainer>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: ${({ orderHistoryDrawer }) => (orderHistoryDrawer ? 'none' : '86.8rem')};
  background-color: ${primaryDark};
  padding: 2.4rem;
  overflow: auto;

  @media (max-width: 767px) {
    position: relative;
    max-height: 100%;
    overflow: auto;
    margin: 0 auto;
  }
`

const Loading = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  margin-top: 1.4rem;
`

const Header = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0 1.4rem;
`

const DriverInfoContainer = styled.div`
  width: 100%;
  margin-bottom: auto;
`

const CloseButton = styled.button`
  align-self: flex-end;
  height: 2.4rem;
  margin: -1.8rem -1.8rem 0 0;
  border: none;
  background-color: transparent;
  color: ${white};
  font-size: 1.7rem;
  cursor: pointer;
`

const Content = styled.div`
  padding-top: 1.8rem;
  margin-top: 2.4rem;
  min-height: 6rem;
`

const ContentHeader = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const InventoryActionContainer = styled.div`
  display: inline;
  width: 9rem;
  margin-left: 2rem;
  padding: 0.5rem 1rem;
  border: 1px solid ${lightGray};
  border-radius: 4rem;
  color: ${lightGray};
  font-size: 1.4rem;
  cursor: pointer;
`
