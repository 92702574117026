import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import Color from 'microcomponents/colors'
import isEmptyObject from 'is-empty-object'
import PropTypes from 'prop-types'

import PlusIcon from './plus-icon'

const QuickAddButton = styled.button`
  position: absolute;
  right: 0;
  padding: 1.5rem;
  z-index: 5;
  border: 0;
  background: transparent;
  outline: none;
  transition: all 0.2s linear;
  opacity: ${getQuickAddIndicatorOpacity};

  &:hover {
    transform: scale(1.25);
    cursor: pointer;
  }
`

const baseStyle = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
  height: 2rem;
  width: 2rem;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 0;
  font-weight: 900;
  border: 0;
  border-radius: 50%;
`

const PlusContainer = styled(baseStyle)`
  height: 1.5rem;
  width: 1.5rem;
  border: 0;
`

const Count = styled(baseStyle)`
  background-color: ${Color.danger[1]};
  color: white;
`

export default class QuickAddComponent extends PureComponent {
  static defaultProps = {
    isInCart: false,
    cartItem: {}
  }

  static propTypes = {
    cartItem: PropTypes.object.isRequired,
    isMobile: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    focused: PropTypes.bool
  }

  render () {
    const { cartItem, onClick, isMobile, focused } = this.props

    return (
      <QuickAddButton
        onClick={onClick}
        aria-label='Add item to cart'
        isMobile={isMobile}
        focused={focused}
        cartItem={cartItem}
      >
        {
          !isEmptyObject(cartItem)
            ? <Count>{cartItem.quantity}</Count>
            : <PlusContainer><PlusIcon fillColor={isMobile ? '#D7D7D7' : '#00AAE7'} /></PlusContainer>
        }
      </QuickAddButton>
    )
  }
}

function getQuickAddIndicatorOpacity ({ isMobile, cartItem, focused }) {
  if (!isEmptyObject(cartItem) && focused) {
    return 0
  }

  if (!isEmptyObject(cartItem) || isMobile) {
    return 1
  }

  return focused && !isMobile ? 1 : 0
}
