import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { bool, func, number, object, shape, string } from 'prop-types'

import PriceLineItem from './item'
import { HIGHLIGHT_TYPE, FADED_TYPE, RED_SUBTYPE, GREEN_SUBTYPE } from '../constants'
import {
  CUSTOMER_FIRST_ORDER_KEY,
  DELIVERY_FEE_KEY,
  MAX_VETERAN_DISCOUNT,
  MINIMUM_AMOUNT_BYPASS_KEY,
  NONE_KEY,
  REMAINING_AMOUNT_BYPASS_KEY,
  TOTAL_EXCEEDED_MINIMUM_KEY
} from './constants'
import {
  getIsPeakDelivery,
  getIsDiscountedDeliveryFee,
  getIsPeakDeliveryFee,
  getIsPeakDeliveryBypass,
  getIsDeliveryPastMinimum,
  getIsDeliveryFeeBypassed
} from '../delivery-helpers'
import {
  PEAK_DELIVERY_FEE_KEY,
  PEAK_DELIVERY_BYPASS_KEY,
  DELIVERY_FEE_MESSAGING
} from '../message-formatters'

export default class OrderPrices extends PureComponent {
  static propTypes = {
    deliveryFee: number,
    hideDeliveryFee: bool,
    inviteCredit: number,
    isShippingMenu: bool,
    isShippingOrder: bool,
    loading: bool.isRequired,
    promoCredit: number,
    subtotal: number,
    toggleTaxesAndFees: func,
    peakInfo: shape({
      current: object
    }),
    bypassDeliveryFeeReason: string,
    minimumAmountBypassDeliveryFee: number,
    isCurrentOrder: bool,
    difference: number,
    hideDeliveryFeeSubtext: bool,
    tax: number,
    veteranDiscount: number
  }

  static defaultProps = {
    loading: false,
    peakInfo: {
      current: {}
    },
    taxLineItems: []
  }

  getDeliveryValue = (key) => {
    const { peakInfo: { current = {} } } = this.props
    const currentValue = current[key]

    // if the value in current blob of peakInfo is a number, we want to
    // override the existing value with that one, otherwise we want to
    // return the original value
    return typeof currentValue === 'number'
      ? currentValue
      : this.props[key]
  }

  deliveryFeeBypassReasonMessage = (bypassReason, minimumAmount) => {
    const { isShippingMenu } = this.props
    const deliveryMethod = isShippingMenu ? 'shipping' : 'delivery'
    const bypassReasonMap = {
      [NONE_KEY]: null,
      [CUSTOMER_FIRST_ORDER_KEY]: `Free ${deliveryMethod} with your first order!`,
      [TOTAL_EXCEEDED_MINIMUM_KEY]: `Free delivery for $${minimumAmount}+ orders!`
    }

    return bypassReasonMap[bypassReason] || `Free ${deliveryMethod}!`
  }

  getSubtextType = () => {
    const { bypassDeliveryFeeReason } = this.props
    const deliveryFee = this.getDeliveryValue(DELIVERY_FEE_KEY)
    const isDeliveryFeeBypassed = getIsDeliveryFeeBypassed(deliveryFee, bypassDeliveryFeeReason)

    return isDeliveryFeeBypassed ? GREEN_SUBTYPE : RED_SUBTYPE
  }

  getLineThroughPrice = () => {
    const { bypassDeliveryFeeReason } = this.props
    const deliveryFee = this.getDeliveryValue(DELIVERY_FEE_KEY)
    const remainingAmountBypassDeliveryFee = this.getDeliveryValue(REMAINING_AMOUNT_BYPASS_KEY)

    return getIsDeliveryFeeBypassed(deliveryFee, bypassDeliveryFeeReason) || !remainingAmountBypassDeliveryFee
  }

  getDeliveryFeeText = () => {
    const deliveryFee = this.getDeliveryValue(DELIVERY_FEE_KEY)

    return deliveryFee || (deliveryFee === 0 ? 'FREE' : '-')
  }

  getDeliveryFeeTooltipText = () => {
    const {
      deliveryFee: originalDeliveryFee,
      minimumAmountBypassDeliveryFee: originalMinimumAmountBypassDeliveryFee,
      peakInfo: { current = {} }
    } = this.props
    let messageKey = ''
    const isPeakDeliveryFee = getIsPeakDeliveryFee(
      current.deliveryFee,
      originalDeliveryFee
    )
    const isPeakDeliveryBypass = getIsPeakDeliveryBypass(
      current.minimumAmountBypassDeliveryFee,
      originalMinimumAmountBypassDeliveryFee
    )

    if (isPeakDeliveryFee) {
      messageKey = PEAK_DELIVERY_FEE_KEY
    }

    if (isPeakDeliveryBypass) {
      messageKey = PEAK_DELIVERY_BYPASS_KEY
    }

    return DELIVERY_FEE_MESSAGING[messageKey]
  }

  deliveryFeeSubtext = () => {
    const {
      bypassDeliveryFeeReason,
      isCurrentOrder,
      isShippingMenu
    } = this.props
    let text = ''
    const deliveryMethod = isShippingMenu ? 'shipping' : 'delivery'
    const deliveryFee = this.getDeliveryValue(DELIVERY_FEE_KEY)
    const isDeliveryFeeBypassed = getIsDeliveryFeeBypassed(deliveryFee, bypassDeliveryFeeReason)
    const minimumAmountBypassDeliveryFee = this.getDeliveryValue(MINIMUM_AMOUNT_BYPASS_KEY)
    const remainingAmountBypassDeliveryFee = this.getDeliveryValue(REMAINING_AMOUNT_BYPASS_KEY)

    if (!deliveryFee || !bypassDeliveryFeeReason) return ''

    if (isDeliveryFeeBypassed) {
      text = this.deliveryFeeBypassReasonMessage(bypassDeliveryFeeReason, minimumAmountBypassDeliveryFee)
    } else if (!isCurrentOrder) {
      text = `Add $${remainingAmountBypassDeliveryFee} to get free ${deliveryMethod}!`
    }

    return {
      type: this.getSubtextType(),
      text
    }
  }

  getVeteranDiscountSubtext = () => {
    const { subtotal } = this.props
    const text = subtotal > MAX_VETERAN_DISCOUNT
      ? 'The 25% discount only applies to the first $500 of your order.'
      : ''

    return {
      text,
      type: GREEN_SUBTYPE,
      customStyle: veteranSubtypeStyle
    }
  }

  render () {
    const {
      bypassDeliveryFeeReason,
      deliveryFee: originalDeliveryFee,
      difference,
      hideDeliveryFee,
      hideDeliveryFeeSubtext,
      inviteCredit,
      isShippingMenu,
      isShippingOrder,
      loading,
      minimumAmountBypassDeliveryFee: originalMinimumAmountBypassDeliveryFee,
      peakInfo: { current = {} },
      promoCredit,
      subtotal,
      tax,
      toggleTaxesAndFees,
      veteranDiscount
    } = this.props

    const deliveryFee = this.getDeliveryValue(DELIVERY_FEE_KEY)

    // If deliveryFee is populated, show it. Otherwise, only show it if there is a subtotal, indicating that the data has loaded
    // hideDeliveryFee bool primarily used in cart-drawer for logged out users to not show potentially misleading "Free" delivery line
    const showDeliveryFee = (deliveryFee || subtotal) && !hideDeliveryFee
    const isPeakDelivery = getIsPeakDelivery(
      current.deliveryFee,
      originalDeliveryFee,
      current.minimumAmountBypassDeliveryFee,
      originalMinimumAmountBypassDeliveryFee
    )
    const isDiscountedDeliveryFee = getIsDiscountedDeliveryFee(
      current.deliveryFee,
      originalDeliveryFee
    )
    const isDeliveryFeeBypassed = getIsDeliveryFeeBypassed(deliveryFee, bypassDeliveryFeeReason)
    const isDeliveryPastMinimum = getIsDeliveryPastMinimum(bypassDeliveryFeeReason)

    const priceObjects = [
      {
        amount: subtotal,
        label: 'Subtotal',
        show: subtotal !== 0,
        type: FADED_TYPE
      },
      {
        amount: tax,
        label: isShippingMenu || isShippingOrder ? 'Taxes' : 'Taxes & Fees',
        show: tax,
        toggleTaxesAndFees: toggleTaxesAndFees,
        type: FADED_TYPE
      },
      {
        amount: this.getDeliveryFeeText(),
        isDeliveryFeeBypassed,
        isDeliveryPastMinimum,
        isDiscountedDeliveryFee,
        isPeakDelivery,
        label: isShippingMenu ? '4-6 Day Shipping' : 'Delivery',
        lineThroughPrice: this.getLineThroughPrice(),
        originalAmount: originalDeliveryFee,
        show: showDeliveryFee,
        subtext: hideDeliveryFeeSubtext ? '' : this.deliveryFeeSubtext(),
        type: FADED_TYPE
      },
      {
        amount: (promoCredit * -1),
        label: 'Promo',
        show: promoCredit,
        type: HIGHLIGHT_TYPE
      },
      {
        amount: -1 * veteranDiscount,
        label: 'Veterans Discount',
        show: veteranDiscount,
        subtext: this.getVeteranDiscountSubtext(),
        type: HIGHLIGHT_TYPE
      },
      {
        amount: (inviteCredit * -1),
        label: 'Credit',
        show: inviteCredit,
        type: HIGHLIGHT_TYPE
      }
    ]

    if (difference) {
      priceObjects.push(
        { label: 'Bonus Savings', amount: (difference * -1), type: HIGHLIGHT_TYPE, show: difference !== 0 }
      )
    }

    return (
      <Container>
        {
          priceObjects.map((price, index) => {
            return (
              <PriceLineItem
                amount={price.amount}
                defaultText={price.defaultText}
                deliveryFeeTooltipText={this.getDeliveryFeeTooltipText()}
                isDeliveryFeeBypassed={price.isDeliveryFeeBypassed}
                isDeliveryPastMinimum={price.isDeliveryPastMinimum}
                isDiscountedDeliveryFee={price.isDiscountedDeliveryFee}
                isPeakDelivery={price.isPeakDelivery}
                key={index}
                label={price.label}
                lineThroughPrice={price.lineThroughPrice}
                loading={loading}
                originalAmount={price.originalAmount}
                show={price.show}
                subtext={price.subtext}
                // tooltip={price.tooltip} // currently tooltip is not used in any of the above priceLineItems, should we delete?
                type={price.type}
                toggleTaxesAndFees={price.toggleTaxesAndFees}
              />
            )
          })
        }
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const veteranSubtypeStyle = css`
  font-weight: 200;
`
