import t from './actionTypes'

const defaultState = {}

const speciesMap = (state = defaultState, { type, payload }) => {
  switch (type) {
    case t.SET_SPECIES_MAP:
      return {
        ...state,
        ...payload
      }

    case t.CLEAR_SPECIES_MAP:
      return defaultState

    default:
      return state
  }
}

export default speciesMap
