import React from 'react'

export default function IconOrderPin ({ color = 'white', size = '4rem', componentStyle }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 109 142'
      version='1.1'
      xmlnsXlink='http://www.w3.org/1999/xlink'>
      <defs>
        <filter
          x='-48.4%'
          y='-115.4%'
          width='196.8%'
          height='330.8%'
          filterUnits='objectBoundingBox'
          id='a'
        >
          <feGaussianBlur
            stdDeviation={10}
            in='SourceGraphic'
          />
        </filter>
      </defs>
      <g
        transform='translate(19.000000, 0.000000)'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'>
        <ellipse
          fillOpacity='0.3'
          fill='#353034'
          filter='url(#a)'
          cx={31}
          cy={107}
          rx={31}
          ry={13}
        />
        <circle
          fill='#FFF'
          cx='45.5'
          cy='43.5'
          r='35.5'
        />
        <path
          d='M45.5 116.703C38 114 11.424 78.915 4.16 58.07a43.342 43.342 0 0 1-2.384-14.223C1.776 19.834 21.39.297 45.5.297c24.11 0 43.724 19.537 43.724 43.55 0 4.87-.803 9.656-2.383 14.22C79.574 78.917 53 114 45.5 116.703zm0-106.122c-18.33 0-33.243 14.853-33.243 33.11 0 18.256 14.913 33.106 33.243 33.106 18.33 0 33.242-14.85 33.242-33.108 0-18.257-14.913-33.108-33.242-33.108z'
          fill='#1B9EEC'
          fillRule='nonzero'
        />
        <g
          transform='translate(27.000000, 29.000000)'
          fill={color}
          fillRule='nonzero'>
          <path
            d='M22.279 25.344v2.358h5.34v-2.358h-5.34zM.146.935v2.358H4.38L9.365 22.82h21.703l5.785-17.002H10.11v2.358h23.43l-.857 2.523H13.482v2.357h18.399l-.861 2.526H16.566v2.357h13.652l-.858 2.523H11.216L6.232.935H.146zm12.17 24.41v2.357h5.34v-2.357h-5.34z'
          />
        </g>
      </g>
    </svg>
  )
}
