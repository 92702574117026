import history from 'components/router/history'
import {
  TOGGLE_ACTION_BAR,
  HIDE_ACTION_BAR
} from 'src/redux/catalog/actionTypes'

/*
  This is a file for common actions used across the catalog reducers in order to avoid circular dependencies
  i.e. so `catalog/item/actions` doesn't need to import from `catalog/actions` which imports `catalog/item/actions`
*/

// strip search params
export function clearFormQueryParams () {
  history.push({ search: '' })
}

export function toggleActionBar () {
  return {
    type: TOGGLE_ACTION_BAR
  }
}

export function hideActionBar () {
  return {
    type: HIDE_ACTION_BAR
  }
}
