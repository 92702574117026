import t from './actionTypes'

export const defaultState = {
  deliveryFeesList: []
}

const deliveryFees = (state = defaultState, { type, payload }) => {
  switch (type) {
    case t.SET_DELIVERY_FEES:
      return {
        ...state,
        deliveryFeesList: payload
      }

    default:
      return state
  }
}

export default deliveryFees
