import { SET_STRAIN } from './actionTypes'

const emptyEffects = [
  { name: '', amount: '' },
  { name: '', amount: '' },
  { name: '', amount: '' },
  { name: '', amount: '' },
  { name: '', amount: '' }
]

const defaultState = {
  id: null,
  name: '',
  species: '',
  description: '',

  thc: 0,
  cbd: 0,
  highCbd: false,

  effects: emptyEffects,
  images: []
}

const catalogStrain = (state = defaultState, action) => {
  switch (action.type) {
    case SET_STRAIN:
      return {
        ...state,
        ...action.strain
      }

    default:
      return state
  }
}

export default catalogStrain
