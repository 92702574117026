import { func, string } from 'prop-types'
import React from 'react'
import { css, cx } from 'emotion'
import { secondaryDark } from 'helpers/css-variables'

const search = css`
  max-width: 100%;
  width: 100%;
  margin: 1rem;
  padding: 1rem;
  border-radius: 0.2rem;
  border: 1px solid ${secondaryDark};
  background-color: ${secondaryDark};
  color: white;
  font-size: 1.44rem;
`

const form = css`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TablePagerSearch = ({ placeholderText, setText, submit }) => {
  return (
    <form onSubmit={submit} className={cx(form)}>
      <input type='text' className={cx(search)} placeholder={placeholderText || 'Search'} onChange={setText} />
    </form>
  )
}

TablePagerSearch.propTypes = {
  placeholderText: string,
  setText: func,
  submit: func
}

export default TablePagerSearch
