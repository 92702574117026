import t from './actionTypes'

export function setDriverMap (driverMap) {
  return {
    type: t.SET_DRIVER_MAP,
    payload: driverMap
  }
}

export function updateDriverMap (driverMap) {
  return {
    type: t.UPDATE_DRIVER_MAP,
    payload: driverMap
  }
}

export function clearDriverMap () {
  return {
    type: t.CLEAR_DRIVER_MAP
  }
}
