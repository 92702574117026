import React from 'react'
import { Link } from 'react-router-dom'
import { readDate } from 'helpers/date'
import Button, { TRANSPARENT } from 'components/button'
import { Column, Flex, Row } from 'src/microcomponents/flexbox-helpers'
import styled from '@emotion/styled'
import { accessory5, blazeUpBlack, mcBlue, midGray } from 'src/helpers/css-variables'
import CopyIcon from 'microcomponents/icons/copy'
import { array, func, object, string } from 'prop-types'

export default class GroupCard extends React.PureComponent {
  static propTypes = {
    createOrUpdateGroup: func,
    group: object,
    id: string,
    items: array,
    lastUpdated: string,
    name: string,
    slug: string,
    updateGroupObject: func
  }

  handleCopyGroup = (event) => {
    event.preventDefault()
    const {
      createOrUpdateGroup,
      group: {
        depots_enabled: depotsEnabled,
        description,
        desktop_image: desktopImage,
        desktop_inline_image: desktopInlineImage,
        items,
        auto_add: autoAdd,
        filters,
        manual_sort: manualSort,
        mobile_image: mobileImage,
        mobile_inline_image: mobileInlineImage,
        name,
        slug
      },
      updateGroupObject
    } = this.props

    if (window.confirm(`Do you want to make a copy of group "${name}?"`)) {
      const newGroupObject = {
        depots_enabled: depotsEnabled,
        description,
        desktop_image: desktopImage,
        desktop_inline_image: desktopInlineImage,
        items,
        auto_add: autoAdd,
        filters,
        manual_sort: manualSort,
        mobile_image: mobileImage,
        mobile_inline_image: mobileInlineImage,
        name: `Copy of ${name}`,
        slug: `${slug}-copy`
      }

      updateGroupObject(newGroupObject)
      createOrUpdateGroup()
    }
  }

  render () {
    const { group, id, items, lastUpdated, name, slug } = this.props

    return (
      <GroupCardContainerLink to={`/catalog/groups/edit/${id}`}>
        <GroupCardContent>
          <FullHeightRow align='center'>
            <ItemTotalContainer
              align='center'
              justify='center'
              left={2}
              right={2}
            >
              <BlueBox>
                <div>{items.length}</div>
                <Total>TOTAL</Total>
              </BlueBox>
            </ItemTotalContainer>
            <GroupInfoContainer>
              <GroupName>{name}</GroupName>
              <GroupInfo>Last updated: {readDate(lastUpdated)}</GroupInfo>
              <GroupSlug>{slug && `/${slug}`}</GroupSlug>
            </GroupInfoContainer>
            <StateCorner>{group.state_id}</StateCorner>
            <CardButtonsWrapper>
              <Button
                onClick={this.handleCopyGroup}
                type={TRANSPARENT}
                componentStyle={{ boxShadow: 'none', padding: '0.3rem' }}
              >
                <CopyIcon title='Make a copy of this group.' />
              </Button>
            </CardButtonsWrapper>
          </FullHeightRow>
        </GroupCardContent>
      </GroupCardContainerLink>
    )
  }
}

const GroupCardContainerLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  transition: transform 0.25s linear;
  width: 100%;

  &:hover {
    transform: translateY(-0.5rem);
  }
`

const CardButtonsWrapper = styled.div`
  position: absolute;
  right: 1rem;
  visibility: hidden;
`

const GroupCardContent = styled.div`
  background-color: ${accessory5};
  border-radius: 0.4rem;
  box-shadow: 0 -0.2rem 0.5rem rgba(0,0,0,0.1);
  height: 100%;
  overflow: hidden;
  position: relative;
  transform: translateZ(0.1rem);
  z-index: 2;

  &:hover {
    ${CardButtonsWrapper} {
      visibility: visible;
    }
  }
`

const FullHeightRow = styled(Row)`
  height: 100%;
`

const ItemTotalContainer = styled(Flex)`
  max-width: 4rem;
  height: 100%;
  font-weight: 600;
`

const BlueBox = styled(Column)`
  background-color: ${mcBlue};
  border-radius: 0.4rem;
  color: ${accessory5};
  letter-spacing: 0.03em;
  padding: 1rem;
  text-align: center;
`

const Total = styled.div`
  font-size: 1.2rem;
  font-weight: 200;
  letter-spacing: 0.03rem;
`

const GroupInfoContainer = styled(Column)`
  color: ${blazeUpBlack};
  letter-spacing: 0.03em;
  padding: 2rem 1rem;
  position: relative;
`

const GroupName = styled.div`
  color: ${mcBlue};
  font-weight: bold;
  text-transform: uppercase;
`

const GroupInfo = styled.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  font-size: 1.4rem;
  margin-top: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
`

const GroupSlug = styled.div`
  font-size: 1.2rem;
  margin-top: 0.5rem;
  opacity: 0.7;
  text-align: left;
`

const StateCorner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  font-size: 1.2rem;
  color: ${midGray};
`
