import { connect } from 'react-redux'
import Alert from './'

// Props passed to the component
const mapStateToProps = (state, props) => {
  return {
    notifications: state.alerts.notifications
  }
}

const reduxContainer = connect(
  mapStateToProps
)(Alert)

export default reduxContainer
