import React, { PureComponent } from 'react'
import Button, { HYBRID, DANGER } from 'components/button'
import ColorPicker from 'components/color-picker'
import styled from '@emotion/styled'
import Colors from 'microcomponents/colors'

import InfoBlock from 'src/components/info-block'
import McButton from 'microcomponents/mc-button'
import { func, number, string } from 'prop-types'

class CaseInfoHeader extends PureComponent {
  state = {
    showColorPicker: false,
    name: '',
    description: '',
    color: ''
  }

  static propTypes = {
    archiveCase: func,
    color: string,
    description: string,
    legalCaseTotal: number,
    name: string,
    numberOfItems: number,
    onColorChange: func,
    onNameChange: func,
    onDescriptionChange: func,
    saveCase: func
  }

  handleColorChange = (color) => {
    this.setState({ showColorPicker: false, color })
    this.props.onColorChange(color)
  }

  handleTextArea = key => event => {
    this.setState({ [key]: event.target.value }, () => {
      const value = this.state[key]
      key === 'name' ? this.props.onNameChange(value) : this.props.onDescriptionChange(value)
    })
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps ({ color, description, name }) {
  /* eslint-enable camelcase */
    if (name && !this.props.name) {
      this.setState({ color, description, name })
    }
  }

  render () {
    const {
      archiveCase,
      legalCaseTotal,
      numberOfItems,
      saveCase
    } = this.props

    const { showColorPicker, name, description } = this.state

    const displayTotal = Math.floor(legalCaseTotal)

    let { color } = this.state
    color = color || Colors.indica['1']

    return (
      <HeaderBox color={color}>
        <div style={{ marginBottom: '5rem', position: 'relative' }}>
          <ArchivedBtn
            onClick={archiveCase}
            type={DANGER}
            componentStyle={{ position: 'absolute' }}
          >
            Archive
          </ArchivedBtn>
          <Btn
            onClick={saveCase}
            type={HYBRID}
            componentStyle={{ position: 'absolute' }}
          >
            Save Case
          </Btn>

          <ButtonContainer>
            <McButton onClick={() => this.setState({ showColorPicker: true })}>
              <span>COLOR</span>
              <ColorPicker color={color} showColorPicker={showColorPicker} onColorChange={this.handleColorChange} />
            </McButton>
          </ButtonContainer>
        </div>

        <LowerContainer>
          <LeftSide>
            <CaseNameTextArea color={color} placeholder='Case Template Name' value={name} onChange={this.handleTextArea('name')} />
            <CaseDescription color={color} placeholder='Description' value={description} onChange={this.handleTextArea('description')} />
          </LeftSide>
          <RightSide>
            <InfoBlock
              subTitle='ITEMS'
              title={numberOfItems}
            />
            <InfoBlock
              color='white'
              subTitle='CANNABIS VALUE'
              title={`$${displayTotal}`}
            />

          </RightSide>
        </LowerContainer>
      </HeaderBox>
    )
  }
}

const HeaderBox = styled.div`
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  color: white;
  background-color: ${({ color }) => color};
  padding: 2rem;
`

const ButtonContainer = styled.div`
  position: absolute;
  right: 0;
  width: 10rem;
`

const LowerContainer = styled.div`
  position: relative;
`

const LeftSide = styled.div`
  display: inline-block;
  width: 40rem;
`

const RightSide = styled(LeftSide)`
  width: 35rem;
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: right;
`

const CaseNameTextArea = styled.textarea`
  background-color: ${({ color }) => color};
  height: 90%;
  font-size: 3rem;

  ::placeholder {
    color: white;
    font-size: 3rem;
  }

  &:hover {
    border: 2px dashed white;
  }
`

const CaseDescription = styled(CaseNameTextArea)`
  font-size: 1.7rem;

  ::placeholder {
    font-size: 1.7rem;
  }
`

const Btn = styled(Button)`
  font-family: inherit;
  position: absolute;
  width: 12rem;
  top: -8rem;
  right: -2rem;
`

const ArchivedBtn = styled(Btn)`
  right: 12rem;
`

export default CaseInfoHeader
