// this is used in places that we don't actually want to render a button .. ie a <label /> for an
// input[type=file]

import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { PRIMARY } from './types'
import {
  fetchBackgroundColor,
  fetchBorderColor
} from './style-helpers'
import Spinner from './spinner'

const Button = styled.div`
  box-sizing: border-box;
  background-color: ${fetchBackgroundColor};
  position: relative;
  padding: 1.4rem;
  border-radius: 0.2rem;
  color: #FFF;
  cursor: pointer;
  min-width: 5rem;
  font-size: 1.2rem;
  font-family: 'Open Sans';
  font-weight: 400;
  letter-spacing: 0.03em;
  outline: none;
  width: 100%;
  text-transform: uppercase;
  white-space: nowrap;
  border: 0.1rem solid ${fetchBorderColor};
  text-align: center;
  &:hover {
    box-shadow: 0 0.4rem 0.8rem rgba(0,0,0,.2);
    opacity: 0.9;
  }
`

export default class StyleOnlyComponent extends PureComponent {
  static defaultProps = {
    type: PRIMARY,
    loading: false
  }

  static propTypes = {
    loading: PropTypes.bool,
    type: PropTypes.string,
    children: PropTypes.node
  }

  render () {
    const { type, children, loading, ...otherProps } = this.props
    const content = loading ? <Spinner /> : children

    return (
      <Button type={type} {...otherProps}>{content}</Button>
    )
  }
}
