// Yep, promos are complicated 😬
// This file might help you understand them though.
// The biggest thing to infer from this is that we have two types of `standard` promos, and then...`bogo`.

const PROMOS_IANA_TIME_ZONE = 'America/Los_Angeles'

const PROMO_TYPES = {
  standard: 'standard',
  bogo: 'bogo'
}

const APPLIES_TO_TYPES = {
  id: 'id',
  order: 'order'
}

// This is an object we use to build a logical construct for UI purposes. The back-end has no formal notion of these types.
const promoDefinitions = {
  bogo: {
    id: 1,
    name: 'Buy One Get One',
    applies_to: APPLIES_TO_TYPES.order,
    promo_type: PROMO_TYPES.bogo
  },
  wholeOrder: {
    id: 2,
    name: 'Whole Order',
    applies_to: APPLIES_TO_TYPES.order,
    promo_type: PROMO_TYPES.standard
  },
  catalogCriteria: {
    id: 3,
    name: 'Catalog Criteria',
    applies_to: APPLIES_TO_TYPES.id,
    promo_type: PROMO_TYPES.standard
  }
}

// a list for convenience
const promoDefinitionsList = Object.values(promoDefinitions)

// returns an an object of promo definitions keyed by id
const promoDefinitionsIdMap = (function () {
  const idMap = {}
  Object.keys(promoDefinitions).map((key, index) => {
    idMap[promoDefinitions[key].id] = promoDefinitions[key]
  })
  return idMap
})()

// This helper determines a promo objects defintion types so you don't have to ;)
const getPromoDefinition = function getPromoDefinition (promoObject) {
  if (promoObject.rules.promo_type === PROMO_TYPES.bogo) {
    return promoDefinitions.bogo
  } else if (
    promoObject.rules.promo_type === PROMO_TYPES.standard &&
    promoObject.rules.applies_to === APPLIES_TO_TYPES.order
  ) {
    return promoDefinitions.wholeOrder
  } else if (
    promoObject.rules.promo_type === PROMO_TYPES.standard &&
    promoObject.rules.applies_to === APPLIES_TO_TYPES.id
  ) {
    return promoDefinitions.catalogCriteria
  } else {
    return null
  }
}

export {
  promoDefinitions,
  promoDefinitionsList,
  promoDefinitionsIdMap,
  PROMOS_IANA_TIME_ZONE,
  getPromoDefinition
}
