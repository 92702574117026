/**
 * this is a button component that handles image uploads.
 * currently it takes two props, children (components) and postUpload (fn)
 * postUpload is called after the upload is successful, with the response payload
 */

import React from 'react'
import PropTypes from 'prop-types'
import window from 'global/window'

import InputFile from 'microcomponents/input-file'
import LoadingSpinner from 'src/microcomponents/loading-spinner'

class UploadFile extends React.Component {
  constructor () {
    super()

    this.state = {loading: false}

    this.handleFileRead = this.handleFileRead.bind(this)
  }

  async handleFileRead (img) {
    const { onRequestUpload, postUpload, uploadFile } = this.props
    let formData = new window.FormData()
    let file = img.file
    formData.append('file', file)

    onRequestUpload && onRequestUpload()
    this.setState({loading: true})

    const {err, data} = await uploadFile(formData)
    if (err) throw err

    if (Array.isArray(data)) {
      postUpload(data[0])
      this.setState({loading: false})
    }
  }

  render () {
    const { children } = this.props

    const loadingClass = (children.props && children.props.className) || ''
    const loadingStyle = (children.props && children.props.style) || {}

    const fileHandler = this.props.handleFile || this.handleFileRead

    if (this.state.loading) {
      return (
        <div className={loadingClass} style={{...loadingStyle, height: '5rem', background: 'none', boxShadow: 'none'}}>
          <div style={{height: '5rem'}}>
            <LoadingSpinner show size={5} />
          </div>
        </div>
      )
    }

    return (
      <InputFile
        name={Math.random().toString()}
        onFileRead={fileHandler}
      >
        {children}
      </InputFile>
    )
  }
}

export default UploadFile

UploadFile.propTypes = {
  children: PropTypes.node,
  postUpload: PropTypes.func,
  onRequestUpload: PropTypes.func
}
