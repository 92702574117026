import React, { useEffect } from 'react'
import { Flex, Column } from 'src/microcomponents/flexbox-helpers'
import InputLabel from 'src/microcomponents/input-label'
import { selectContainer } from '../style.js'

import { promoDefinitionsList } from 'helpers/promos/definitions.js'
import { func, number, object, string } from 'prop-types'

/* Caveats!
  onSetPromoType doesn't work like other similar areas of the code here.
  Most places you pass just one action to set one thing. Setting promo_type is a little complicated, as we need to set multiple properties in relation to it.
  See promo-definitions.js to get the idea...
*/

const isAllStates = (stateId) => /all/i.test(stateId)

const PromoTypeFormSection = ({
  appliesTo,
  idType,
  onSetPromoType,
  promoOptions,
  selectedPromoDefinitionId,
  setPromoIdType,
  stateId
}) => {
  useEffect(() => {
    if (isAllStates(stateId)) {
      onSetPromoType({ target: { value: 2 } })
    }
  }, [stateId])

  return (
    <Flex top={1} bottom={1}>
      <Column full>
        <InputLabel content='Promotion Type*' />
        <div className={selectContainer}>
          {/*
            We need to also track what the promo applies_to here.
            When we change the promo_type, we need to change the appliesTo property as well.

            When "ALL" states is chosen, select "Whole Order" for the user
          */}
          <select onChange={onSetPromoType} value={selectedPromoDefinitionId || 0} disabled={isAllStates(stateId)}>
            <option disabled value={0} />
            {promoDefinitionsList.map((opt, ind) => <option value={opt.id} key={ind}>{opt.name}</option>)}
          </select>
        </div>
      </Column>

      {
        appliesTo === 'id'
          ? (
            <Column left={1} full>
              <InputLabel content='Catalog discount*' />
              <div className={selectContainer}>
                <select onChange={setPromoIdType} value={idType || 0}>
                  <option disabled='disabled' value={0} />
                  {promoOptions.id_types.map((opt, ind) => <option key={ind} value={opt}>{opt}</option>)}
                </select>
              </div>
            </Column>
            )
          : null
      }
    </Flex>
  )
}

PromoTypeFormSection.propTypes = {
  appliesTo: string,
  idType: string,
  onSetPromoType: func,
  promoOptions: object,
  selectedPromoDefinitionId: number,
  setPromoIdType: func,
  stateId: string
}

export default PromoTypeFormSection
