import t from './actionTypes'

export function setProducts (products) {
  return {
    type: t.SET_PRODUCTS,
    payload: products
  }
}

export function clearProducts () {
  return {
    type: t.CLEAR_PRODUCTS
  }
}

export function setProduct (product, productId) {
  return (dispatch, getState) => {
    const state = getState()
    // shallow copy of products in state
    const productsCopy = { ...state.products }
    productsCopy[productId] = product

    dispatch(setProducts(productsCopy))
  }
}

export function removeProduct (productId) {
  return (dispatch, getState) => {
    const state = getState()
    // shallow copy of products in state
    const productsCopy = { ...state.products }
    delete productsCopy[productId]

    dispatch(setProducts(productsCopy))
  }
}
