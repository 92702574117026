import t from './actionTypes'
import api from 'api'
import gatewayClient from 'src/gateway-client'

import { pushNotification, SUCCESS } from 'src/redux/alerts/actions'
import { setProducts, clearProducts } from 'src/redux/products/actions'
import { setBrandsMap, clearBrandsMap } from 'src/redux/brands-map/actions'
import { setSpeciesMap, clearSpeciesMap } from 'src/redux/species-map/actions'
import { setGroups, clearGroups } from 'src/redux/groups/actions'
import { arrayToObject } from 'src/redux/products/helpers'
import {
  requestCatalogGroup,
  receiveCatalogGroup,
  requestCatalogBrand,
  receiveCatalogBrand,
  requestSpecies,
  receiveSpecies,
  requestTags,
  receiveTags,
  requestTagTypes,
  receiveTagTypes
} from 'src/redux/loading/actions'
import { getTagById } from 'src/redux/tags/selectors'

export function fetchTagTypes () {
  return async function (dispatch) {
    dispatch(requestTagTypes())
    const { err, data } = await api.getTagTypes()
    dispatch(receiveTagTypes())
    if (err) return

    dispatch(setTagTypes(data))
  }
}

export const setTagTypes = (tagTypes) => ({
  type: t.SET_TAG_TYPES,
  payload: tagTypes
})

export function showTagForm () {
  return {
    type: t.SHOW_TAG_FORM
  }
}

export function hideTagForm () {
  return {
    type: t.HIDE_TAG_FORM
  }
}

export function setTagsGroups (groupIds) {
  return {
    type: t.SET_TAG_GROUPS,
    payload: groupIds
  }
}

export function setFilterString (filterString) {
  return {
    type: t.SET_TAG_FILTER_STRING,
    payload: filterString
  }
}

export function setFilterType (filterType) {
  return {
    type: t.SET_TAG_FILTER_TYPE,
    payload: filterType
  }
}

export function fetchSpecies () {
  return async function (dispatch) {
    dispatch(requestSpecies())

    const { err, data } = await api.getCatalogSpecies()
    dispatch(receiveSpecies())
    if (err) return

    const speciesMap = {}
    data.forEach(function (species) {
      speciesMap[species.id] = species
    })

    dispatch(clearSpeciesMap())
    dispatch(setSpeciesMap(speciesMap))
  }
}

export function fetchBrands () {
  return async function (dispatch) {
    dispatch(requestCatalogBrand())

    const { err, data } = await api.getCatalogBrands()
    dispatch(receiveCatalogBrand())
    if (err) return

    const brandsMap = {}
    data.forEach(function (brand) {
      brandsMap[brand.id] = brand
    })

    dispatch(clearBrandsMap())
    dispatch(setBrandsMap(brandsMap))
  }
}

export function fetchGroups () {
  return async function (dispatch) {
    dispatch(requestCatalogGroup())

    const { err, data } = await api.getCatalogGroups()
    dispatch(receiveCatalogGroup())
    if (err) return

    dispatch(clearGroups())
    dispatch(clearProducts())

    const groupsMap = {}
    let productsMap = {}
    data.forEach(function (group) {
      const { productsObject, productIds } = arrayToObject(group.items)
      productsMap = {
        ...productsMap,
        ...productsObject
      }
      groupsMap[group.id] = {
        ...group,
        items: productIds
      }
    })

    dispatch(setProducts(productsMap))
    dispatch(setGroups(groupsMap))
    dispatch(setTagsGroups(Object.keys(groupsMap)))
  }
}

export function fetchTagsGroups () {
  return async dispatch => {
    dispatch(requestCatalogGroup())

    try {
      const { data: { tagsGroups } } = await gatewayClient().getTagsGroups()

      const groupsMap = {}
      let productsMap = {}
      tagsGroups.forEach(function (group) {
        const { productsObject, productIds } = arrayToObject(group.items)
        productsMap = {
          ...productsMap,
          ...productsObject
        }

        groupsMap[group.id] = {
          ...group,
          items: productIds
        }
      })
      dispatch(clearGroups())
      dispatch(clearProducts())

      dispatch(setProducts(productsMap))
      dispatch(setGroups(groupsMap))
      dispatch(setTagsGroups(Object.keys(groupsMap)))
    } catch (error) {
      console.log(error)
    } finally {
      dispatch(receiveCatalogGroup())
    }
  }
}

export const setTagConflicts = (conflictData) => ({
  type: t.SET_TAG_CONFLICTS,
  payload: conflictData
})

export const setTagsMap = (tags) => ({
  type: t.SET_TAGS_MAP,
  payload: tags
})

export const updateTagsMap = (tags) => ({
  type: t.UPDATE_TAGS_MAP,
  payload: tags
})

export function createTag (tagObject = {}, callback) {
  return async function (dispatch) {
    const { err, data } = await api.createTag(tagObject)
    if (err) return { err }
    // backend doesn't return items: [] or areaIds: [] so we need to handle
    // the persistence of this data from the client for now, ultimately though
    // this should be the responsibility of the backend
    const { areaIds } = tagObject
    const id = data.id
    const tagCopy = {
      ...data,
      items: [],
      areaIds
    }

    dispatch(updateTagsMap(
      { [id]: tagCopy }
    ))
    callback(tagCopy)
    return {}
  }
}

export function addTagItems (tagItemsObject = {}) {
  return async function (dispatch) {
    const { err, data } = await api.addTagItems(tagItemsObject, (err) => err)
    
    if (err) return { err }
    
    const { tagId, itemIds } = data
    const { data: { tag } }  = await gatewayClient().getTag(tagId)
    const tagCopy = {
      ...tag,
      items: itemIds
    }

    dispatch(updateTagsMap(
      { [tagId]: tagCopy }
    ))

    return {
      data: tagCopy
    }
  }
}

export function updateTag (tagObject = {}, callback = () => {}) {
  return async function (dispatch, getState) {
    dispatch(requestTags())

    // PUT does not need items: []. Items bloats payload and causes "XHR Aborted" which won't allow tag to be edited.
    delete tagObject.items

    const { err, data } = await api.updateTag({ ...tagObject, tagId: tagObject.id })
    const { data: { tag } }  = await gatewayClient().getTag(tagObject.id)
    dispatch(receiveTags())
    if (err) return { err }

    // backend doesn't return items: [] or areaIds: [] so we need to handle
    // the persistence of this data from the client for now, ultimately though
    // this should be the responsibility of the backend
    const { areaIds } = tagObject
    const { tags: { tagsMap } } = getState()
    const { id } = data
    const { items } = tagsMap[id]
    const newTag = {
      ...tag,
      items,
      areaIds
    }

    dispatch(updateTagsMap(
      { [id]: newTag }
    ))
    callback(newTag)
  }
}

export function deleteTag (id) {
  return async function (dispatch, getState) {
    const { err } = await api.deleteTag({ id })
    if (err) return { err }

    const { tags: { tagsMap } } = getState()
    const tagsMapCopy = { ...tagsMap }
    delete tagsMapCopy[id]

    dispatch(pushNotification('Successfully deleted tag', SUCCESS))
    dispatch(setTagsMap(tagsMapCopy))
    return {}
  }
}

export function deleteTagItems (tagId, itemIds) {
  if (typeof itemIds === 'string') itemIds = [itemIds]

  return async function (dispatch, getState) {
    const { err } = await api.deleteTagItems({ tagId, itemIds })

    if (err) return { err }

    dispatch(pushNotification('Successfully deleted tag items', SUCCESS))

    // Looks weird, but if we wanna const {err, data} = await, we need a return value
    return {}
  }
}

export function getTags (params = {}) {
  return async function (dispatch) {
    dispatch(requestTags())

    const { data: { tags } } = await gatewayClient().getTags(params)

    dispatch(receiveTags())
    dispatch(setTagsMap(tags))
    return {}
  }
}

export function getTagWithItemsAndUpdateMap (tagId) {
  return async function (dispatch) {
    dispatch(requestTags())

    const { data: { tag } }  = await gatewayClient().getTag(tagId)

    dispatch(receiveTags())
    dispatch(updateTagsMap(
      { [tagId]: tag }
    ))

    return tag
  }
}

export function toggleTag (id, isEnabled) {
  const getToggleOrSetValue = (state) => {
    if (typeof isEnabled !== 'undefined') return isEnabled

    return !state.tags.tagsMap[id].active
  }
  return function (dispatch, getState) {
    const state = getState()
    const newTagState = {
      ...getTagById(state, id),
      active: getToggleOrSetValue(state)
    }
    dispatch(updateTag(newTagState))
  }
}
