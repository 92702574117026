import React, { PureComponent } from 'react'
import window from 'global/window'
import ProductImage, { SIZES } from 'microcomponents/product-image'
import { func, object, bool } from 'prop-types'
import isEmptyObject from 'is-empty-object'
import { getPrice } from 'helpers/price-helpers'
import styled from '@emotion/styled'

import { parseType } from './helpers'

import Availability from './availability'
import Card from './card'
import Content from './content'
import QuickAdd from './quick-add'
import QuantityPicker from './quantity-picker'
import ImageContainer from './image-container'

class ProductCard extends PureComponent {
  static defaultProps = {
    cart: {},
    cartItem: {},
    isMobile: false,
    loadImage: false,
    addItem: () => {},
    lazyloadImage: false,
    removeItem: () => {},
    productAdded: () => {},
    productRemoved: () => {},
    onProductCardClick: () => {},
    onOverMaxQuantityClick: () => {}
  }

  static propTypes = {
    addItem: func,
    removeItem: func,
    onProductCardClick: func,
    product: object.isRequired,
    cartItem: object.isRequired,
    isMobile: bool.isRequired,
    onOverMaxQuantityClick: func,
    lazyloadImage: bool,
    loadImage: bool,
    productViewed: func,
    productAdded: func,
    shouldRequestQuote: bool,
    productRemoved: func,
    tag: object,
    tagData: object
  }

  state = {
    focused: false,
    windowListener: false,
    showQuantityPicker: false
  }

  componentWillUnmount () {
    if (this.state.windowListener) {
      window.removeEventListener('touchstart', this.hideIndividualPicker)
    }
  }

  setFocus = value => {
    this.setState((state) => {
      return {
        focused: value
      }
    })
  }

  visitProductPage = id => {
    const { productViewed, onProductCardClick } = this.props

    if (productViewed) {
      productViewed(id)
    }

    this.setState((state) => {
      return {
        focused: false
      }
    })

    if (onProductCardClick) {
      onProductCardClick(id)
    }
  }

  applyListener = () => {
    if (this.state.windowListener) return

    window.addEventListener('touchstart', this.hideIndividualPicker)

    this.setState({ windowListener: true })
  }

  hideIndividualPicker = () => {
    window.removeEventListener('touchstart', this.hideIndividualPicker)

    this.setState({ windowListener: false, focused: false, showIndividualPicker: false })
  }

  showIndividualPicker = event => {
    event.stopPropagation()

    const { product, addItem, productAdded, cartItem, shouldRequestQuote } = this.props
    const { id, price, prices } = product

    this.applyListener()

    if (isEmptyObject(cartItem)) {
      const quantity = price ? 1 : prices[0].quantity

      productAdded({ product, quantity })
      addItem(id, quantity, price, shouldRequestQuote)
    }

    window.requestAnimationFrame(() => {
      this.setState({ showIndividualPicker: true })
    })
  }

  stopPropagation = event => event.stopPropagation()

  render () {
    const { focused, showIndividualPicker } = this.state
    const {
      product,
      addItem,
      removeItem,
      cartItem,
      productAdded,
      productRemoved,
      isMobile,
      onOverMaxQuantityClick,
      loadImage,
      lazyloadImage,
      tag,
      tagData
    } = this.props

    if (!product) return null

    const {
      name,
      photoUrl,
      species,
      prices,
      id,
      highCBD,
      isAccessory,
      isAvailable,
      brand
    } = product

    const isInCart = !isEmptyObject(cartItem)

    const parsedSpecies = parseType(species.name, highCBD, isAccessory)

    return (
      <Card
        onClick={() => this.visitProductPage(id)}
        onMouseEnter={() => this.setFocus(true)}
        onMouseLeave={() => this.setFocus(false)}
        focused={focused}
        isMobile={isMobile}
        label={`Open page for ${name} from ${brand.name}`}
      >
        <CardContainer>
          {
            isAvailable && (
              <QuickAdd
                focused={focused}
                isMobile={isMobile}
                count={cartItem.quantity || 0}
                cartItem={cartItem}
                onClick={this.showIndividualPicker}
              />
            )
          }
          <ImageContainer>
            <ProductImage
              size={SIZES.SMALL}
              imageUrl={photoUrl}
              color='#EBEBEB'
              load={loadImage}
              lazy={lazyloadImage}
            />

            {
              isInCart && (
                <SectionOverlay />
              )
            }

            <QuantityPicker
              focused={focused}
              isMobile={isMobile}
              showIndividualPicker={showIndividualPicker}
              product={product}
              cartItem={cartItem}
              addItem={addItem}
              removeItem={removeItem}
              productAdded={productAdded}
              productRemoved={productRemoved}
              isInCart={isInCart}
              onOverMaxQuantityClick={onOverMaxQuantityClick}
            />
          </ImageContainer>

          <Content
            parsedSpecies={parsedSpecies}
            isInCart={isInCart}
            name={name}
            brandName={brand.name}
            price={getPrice(product.price, prices)}
            tag={!isEmptyObject(product.tag) ? product.tag : null}
            tagType={(tagData && tagData.tagType) || (tag && tag.tagType)}
            isAvailable={isAvailable}
          />

          {
            !isAvailable && (
              <SectionOverlay />
            )
          }

        </CardContainer>

        {
          !isAvailable && (
            <Availability />
          )
        }

      </Card>
    )
  }
}

const CardContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
`

const SectionOverlay = styled.div`
  position: absolute;
  top: 0;
  background-color: rgba(255, 255, 255, 0.75);
  height: 100%;
  width: 100%;
`

export default ProductCard
