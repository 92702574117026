import React, { PureComponent } from 'react'
import { element, func, node, oneOfType, string } from 'prop-types'
import styled from '@emotion/styled'
import { mcGreen, white } from 'src/helpers/css-variables'

export default class McButton extends PureComponent {
  static propTypes = {
    children: oneOfType([
      string,
      node,
      element
    ]),
    onClick: func.isRequired
  }

  render () {
    const { onClick, children } = this.props
    return (
      <MCButton onClick={onClick}>
        {children}
      </MCButton>
    )
  }
}

const MCButton = styled.button`
  align-items: center;
  background: ${white};
  border-radius: 0.5rem;
  border: none;
  color: ${mcGreen};
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
`
