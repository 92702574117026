/* eslint-disable react/display-name */
import React from 'react'
import { array, bool, func, object, string } from 'prop-types'
import { withRouter } from 'react-router-dom'
import history from 'components/router/history'
import window from 'global/window'
import qs from 'query-string'
import styled from '@emotion/styled'

import PromoNav from '../promo-nav'
import Screen from 'src/components/screen/container'
import FabFan from 'src/components/fab-fan'
import Overlay from 'src/components/overlay'
import RightBar from 'src/components/right-bar'
import EditTag from 'src/pages/promos/tags/edit'
import Table from 'src/microcomponents/table'
import EazeSwitch from 'src/microcomponents/switch'
import Modal from './conflict-modal'
import { formatDateTimeWithZone, readFullDate } from 'src/helpers/date'
import { TAG_MAP } from 'src/helpers/constants'
import { PROMOS_IANA_TIME_ZONE } from 'src/helpers/promos/definitions'

import {
  container,
  promoNavContainer,
  sidebar,
  tableContainer,
  tableContainerHeader,
  tableTitle
} from './style.js'

const getTableConfig = ({ toggleTag }) => [
  {
    title: 'Status',
    key: 'active',
    formatter: (value, rowData) => (
      <div
        onClick={function (event) {
          event.stopPropagation()
          const { id, active } = rowData
          toggleTag(id, !active)
        }}
      >
        <EazeSwitch enabled={!!value} />
      </div>
    )
  },
  {
    title: 'Tag',
    key: 'text',
    formatter: (value, rowData) => {
      const TagIconComponent = TAG_MAP[rowData.tagType] || TAG_MAP.default
      return (
        <span>
          <TagIconComponent style={{ verticalAlign: 'text-bottom', marginRight: '1rem' }} />
          <span>{value}</span>
        </span>
      )
    }
  },
  {
    title: 'State',
    key: 'state_id'
  },
  {
    title: 'Created',
    key: 'createdAt',
    formatter: v => readFullDate(v)
  },
  {
    title: 'Description',
    key: 'description'
  },
  {
    title: 'Start Date',
    key: 'startAt',
    formatter: v => formatDateTimeWithZone(v, PROMOS_IANA_TIME_ZONE)
  },
  {
    title: 'End Date',
    key: 'expiresAt',
    formatter: v => formatDateTimeWithZone(v, PROMOS_IANA_TIME_ZONE)
  }
]

const ExpiredToggle = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;

  & > span {
    margin-right: 1rem;
  }
`

class Tags extends React.PureComponent {
  static propTypes = {
    addTagItems: func,
    brandsMap: object,
    conflictsMap: object,
    createTag: func,
    deleteTag: func,
    depots: array,
    depotsMap: object,
    filterType: string,
    getTagWithItemsAndUpdateMap: func,
    groupIds: array,
    groupsMap: object,
    hideTagForm: func,
    isTagFormOpen: bool,
    location: object,
    productsMap: object,
    pushNotification: func,
    setFilterString: func,
    setFilterType: func,
    setTagConflicts: func,
    showExpiredTags: bool,
    showTagForm: func,
    speciesMap: object,
    tags: array,
    tagsMap: object,
    tagTypes: array,
    toggleExpiredTags: func,
    // eslint-disable-next-line react/no-unused-prop-types
    toggleTag: func, // It's used in getTableConfig()
    updateTag: func
  }

  state = {
    selectedState: 'CA',
    activeConflictId: undefined,
    closeFormMessage: '',
    formTagId: null
  }

  onSetCloseFormMessage = (closeFormMessage) => {
    this.setState({ closeFormMessage })
  }

  // convenience to query/parse the location search string and return the object
  getQueryStringObject = (location) => {
    const { search } = location

    return qs.parse(search)
  }

  handleTableRowClick = async ({ id }) => {
    const { getTagWithItemsAndUpdateMap, location } = this.props
    const searchObject = this.getQueryStringObject(location)
    const newSearchObject = {
      tagId: id,
      ...searchObject
    }

    this.setState({ formTagId: id })
    await getTagWithItemsAndUpdateMap(id)

    history.push({ search: qs.stringify(newSearchObject) })
  }

  handleStateChange = (selectedState) => {
    this.setState({ selectedState })
  }

  onHideForm = () => {
    const { closeFormMessage } = this.state

    if (!closeFormMessage || window.confirm(closeFormMessage)) {
      this.hideForm()
    }
  }

  hideForm = () => {
    const { hideTagForm, location } = this.props
    const searchObject = this.getQueryStringObject(location)
    delete searchObject.tagId

    hideTagForm()
    history.push({ search: qs.stringify(searchObject) })
    this.setState({ closeFormMessage: '' })
  }

  openConflictModal = (conflict) => {
    this.setState({ activeConflictId: conflict.item_id })
  }

  closeConflictModal = () => {
    this.setState({ activeConflictId: undefined })
  }

  componentDidMount () {
    this.hideForm()
  }

  render () {
    const { closeFormMessage, formTagId, selectedState } = this.state
    const {
      addTagItems,
      brandsMap,
      conflictsMap,
      createTag,
      deleteTag,
      depots,
      depotsMap,
      filterType,
      groupIds,
      groupsMap,
      isTagFormOpen,
      productsMap,
      pushNotification,
      setFilterString,
      setFilterType,
      setTagConflicts,
      showExpiredTags,
      showTagForm,
      speciesMap,
      tags,
      tagsMap,
      tagTypes,
      toggleExpiredTags,
      updateTag
    } = this.props

    const { items: tagItems } = tagsMap[formTagId] || {}

    return (
      <Screen>
        <div className={container}>
          <div className={promoNavContainer}>
            <PromoNav />
          </div>
          <ExpiredToggle onClick={toggleExpiredTags}>
            <span>Show expired </span>
            <EazeSwitch enabled={showExpiredTags} />
          </ExpiredToggle>
          <div className={tableContainer}>
            <div className={tableContainerHeader}>
              <h2 className={tableTitle}>Tags</h2>
            </div>
            <Table
              data={tags}
              config={getTableConfig(this.props)}
              height='calc(100vh - 28rem)'
              rowClick={this.handleTableRowClick}
              rowStyle={() => {}}
              sortBy='createdAt'
            />
            <Modal
              conflictId={this.state.activeConflictId}
              onRequestClose={this.closeConflictModal}
              isOpen={this.state.activeConflictId}
              selectedState={selectedState}
            />
          </div>

          <FabFan options={[]} onClick={showTagForm} />

          <RightBar isOpen={isTagFormOpen}>
            <div className={sidebar}>
              <EditTag
                addTagItems={addTagItems}
                brandsMap={brandsMap}
                closeConflictModal={this.closeConflictModal}
                closeFormMessage={closeFormMessage}
                conflictsMap={conflictsMap}
                createTag={createTag}
                deleteTag={deleteTag}
                depots={depots}
                depotsMap={depotsMap}
                filterType={filterType}
                handleStateChange={this.handleStateChange}
                groupIds={groupIds}
                groupsMap={groupsMap}
                isTagFormOpen={isTagFormOpen}
                onHideForm={this.onHideForm}
                onSetCloseFormMessage={this.onSetCloseFormMessage}
                openConflictModal={this.openConflictModal}
                productsMap={productsMap}
                pushNotification={pushNotification}
                selectedState={selectedState}
                setFilterString={setFilterString}
                setFilterType={setFilterType}
                setTagConflicts={setTagConflicts}
                showTagForm={showTagForm}
                speciesMap={speciesMap}
                tagItems={tagItems}
                tagsMap={tagsMap}
                tagTypes={tagTypes}
                updateTag={updateTag}
              />
            </div>
          </RightBar>

          <Overlay isOpen={isTagFormOpen} onClick={this.onHideForm} />
        </div>
      </Screen>
    )
  }
}

export default withRouter(Tags)
