import React from 'react'

const InfoIcon = props => (
  <svg width={16} height={16} viewBox='0 0 16 16'>
    <defs>
      <circle id='a' cx={8} cy={8} r={8} />
      <mask id='b' x={0} y={0} width={16} height={16} fill='#fff'>
        <use xlinkHref='#a' />
      </mask>
    </defs>
    <g fill='none' fillRule='evenodd'>
      <use stroke='#373838' mask='url(#b)' xlinkHref='#a' />
      <path
        d='M7.004 12.088c-.024.144.06.264.18.264h.78c.12 0 .264-.12.276-.264l.696-5.016c.024-.132-.072-.264-.192-.264h-.78c-.12 0-.264.132-.276.264l-.684 5.016zM8.492 5.62a.953.953 0 00.936-.936c0-.384-.3-.684-.708-.684-.468 0-.924.42-.924.924 0 .396.288.696.696.696z'
        fill='#373838'
      />
    </g>
  </svg>
)

export default InfoIcon
