import { css } from 'emotion'
import { border, mcGreen, mcRed, secondaryDark, tertiaryDark } from 'helpers/css-variables'

export const fab = css`
  background-color: ${mcGreen};
  position: fixed;
  bottom: 2rem;
  border-radius: 50%;
  padding: 1rem;
  height: 6rem;
  width: 6rem;
  font-size: 3rem;
  border: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  box-shadow: 0 0.3rem 1rem rgba(0,0,0,0.2);
  transition: background-color 0.3s linear;

  div {
    height: 4rem;
  }

  &.close {
    background-color: ${mcRed};
  }
`

export const container = css`
  margin: 3rem;
  margin-bottom: 0;
  height: calc(100% - 3rem);
`

export const confirmation = css`
  font-size: 2rem;
  width: 100%;
  text-align: center;
`

export const thinker = css`
  font-size: 4rem;
`

export const manageContainer = css`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  background-color: ${secondaryDark};
  box-shadow: -5px 0 1rem rgba(0,0,0,0.2);
  padding: 1rem;
  overflow: auto;
  z-index: 100;
`

export const selectContainer = css`
  width: 100%;
  flex: 1;
  display: flex;
  position: relative;

  & > select {
    width: 100%;
  }

  &:after {
    content: '▸';
    transform: rotate(90deg);
    position: absolute;
    bottom: 0;
    top: 0;
    z-index: 1000;
    right: 2rem;
  }

  select {
    -webkit-appearance: none;
    width: 100%;
    height: 4rem;
    padding-left: 1rem;
    font-size: 1.6rem;
    color: white;
    border: 0;
    background-color: ${tertiaryDark};
    cursor: pointer;
  }

  select:disabled {
    background: transparent;
    border: 1px solid ${tertiaryDark};
  }
`

export const isSus = css`
  background-color: #613F48 !important;
`

export const promoNavContainer = css`
  margin-bottom: 3rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`

export const tableContainer = css`
  --border-radius: 4px;

  background-color: ${secondaryDark};
  border-top-left-radius: ${border}radius);
  border-top-right-radius: ${border}radius);
`

export const tableTitle = css`
  margin: 0;
`

export const tableContainerHeader = css`
  display: flex;
  padding: 1rem;
  justify-content: space-between;
`

export const tableWrapper = css`
  width: 100%;
  height: 100%;
  overflow: auto;
`

export const managePromosWrapper = css`
  z-index: 100;
  position: relative;
`

export const depotSelectionCount = css`
  margin-left: 1rem;
  font-size: 1.6rem;
`

export const depotList = css`
  list-style: none;
  margin: 0;
`

export const depotRow = css`
  align-items: top;
`

export const supplierSpecificity = css`
  padding-bottom: 1rem;

  & label {
    flex: 1;
    display: flex;
    align-items: center;
    user-select: none;
  }
  & input {
    width: auto;
    margin-right: 1rem;
    display: inline-block;
  }
`

export const disabled = css`
  opacity: 0.5;
`

export const copyPromoId = css`
  margin-left: 1rem;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
`
