import React, { PureComponent } from 'react'
import { array, bool, func, number, object } from 'prop-types'
import Drawer from '@eaze/react-fast-drawer'
import Lane from 'src/components/hub-and-spoke/lane'
import OrderCard from 'src/components/hub-and-spoke/order-card'
import OrderDetailModal from 'src/components/order-detail-map'
import { LANE_TYPES } from 'helpers/hub'
import { markOrderUnpacked, markOrderPacked } from 'src/redux/hub/actions'

import {
  closeButton,
  drawerContainer,
  hubContainer
} from '../style.js'

export default class Canceled extends PureComponent {
  static propTypes = {
    areCanceledOrdersMuted: bool,
    canceledOrdersNotRecovered: array,
    clearOrderFocus: func,
    clearUnpackedCanceledOrders: func,
    focusedOrder: object,
    recoveredOrders: array,
    refreshCanceledOrders: func,
    setOrderFocus: func,
    toggleCanceledOrderSounds: func,
    userId: number
  }

  render () {
    const {
      areCanceledOrdersMuted,
      canceledOrdersNotRecovered,
      clearOrderFocus,
      clearUnpackedCanceledOrders,
      focusedOrder,
      recoveredOrders,
      refreshCanceledOrders,
      setOrderFocus,
      toggleCanceledOrderSounds,
      userId
    } = this.props

    return (
      <div className={hubContainer}>
        <Lane
          headerText={`Canceled Orders (${canceledOrdersNotRecovered.length})`}
          onClickFn={refreshCanceledOrders}
          showRefresh
          showSoundIcon
          isSoundMuted={areCanceledOrdersMuted}
          onSoundIconClickFn={toggleCanceledOrderSounds}
        >
          {canceledOrdersNotRecovered.map((order) => {
            return (
              <OrderCard
                type={LANE_TYPES.NOT_RECOVERED}
                key={order.id}
                order={order}
                onClickFn={() => markOrderUnpacked(order.id, userId)}
                setOrderFocus={setOrderFocus}
              />
            )
          })}
        </Lane>
        <Lane
          headerText={`Unpacked & Reshelved (${recoveredOrders.length})`}
          onClickFn={() => clearUnpackedCanceledOrders(recoveredOrders)}
          showClearAll
        >
          {recoveredOrders.map((order) => {
            return (
              <OrderCard
                type={LANE_TYPES.RECOVERED}
                key={order.id}
                order={order}
                onClickFn={() => markOrderPacked(order.id)}
              />
            )
          })}
        </Lane>
        <Drawer
          open={focusedOrder.id || false}
          onRequestClose={clearOrderFocus}
          modalElementClass={drawerContainer}
          overlayOpacity={0.9}
          dontApplyListeners
        >
          {focusedOrder.id && <OrderDetailModal order={focusedOrder} />}
          <div className={closeButton} onClick={clearOrderFocus}>
            &#10005;
          </div>
        </Drawer>
      </div>
    )
  }
}
