import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { node } from 'prop-types'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media(min-width: 768px) {
    flex-direction: column;
  }
`

export default class ContainerComponent extends PureComponent {
  static propTypes = {
    children: node
  }

  render () {
    const { children } = this.props

    /* don't let onTouchStart bubble up, so we can use this on the menu! */
    return (
      <Container onClick={event => event.stopPropagation()}>{children}</Container>
    )
  }
}
