import { connect } from 'react-redux'

import { getCanceledOrdersNotRecovered, getRecoveredOrders } from 'src/redux/hub/selectors'
import { clearUnpackedCanceledOrders, refreshCanceledOrders } from 'src/redux/hub/actions'
import { clearOrderFocus, setOrderFocus } from 'src/redux/orders/actions'
import { getAreCanceledOrdersMuted } from 'src/redux/sound-alerts/selectors'
import { getFocusedOrder } from 'src/redux/orders/selectors'
import { toggleCanceledOrderSounds } from 'src/redux/sound-alerts/actions'

import Canceled from './component'

const mapStateToProps = (state, props) => {
  return {
    areCanceledOrdersMuted: getAreCanceledOrdersMuted(state),
    canceledOrdersNotRecovered: getCanceledOrdersNotRecovered(state),
    focusedOrder: getFocusedOrder(state),
    recoveredOrders: getRecoveredOrders(state)
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    clearUnpackedCanceledOrders: (orders) => dispatch(clearUnpackedCanceledOrders(orders)),
    clearOrderFocus: () => dispatch(clearOrderFocus()),
    refreshCanceledOrders: () => dispatch(refreshCanceledOrders()),
    setOrderFocus: (order) => dispatch(setOrderFocus(order)),
    toggleCanceledOrderSounds: () => dispatch(toggleCanceledOrderSounds())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Canceled)
