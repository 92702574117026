import { css } from 'emotion'
import { darkDivider, tertiaryDark, white } from 'helpers/css-variables'

export const container = css`
  border-left: 1px solid ${darkDivider};
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const setMenuSlugContainer = css`
  padding: 1rem;
`

export const selectContainer = css`
  width: 100%;
  flex: 1;
  display: flex;
  position: relative;

  & > select {
    width: 100%;
  }

  &:after {
    content: '▸';
    transform: rotate(90deg);
    color: white;
    position: absolute;
    bottom: 0;
    top: 0;
    background-color: ${tertiaryDark};
    z-index: 1000;
    right: 2rem;
  }

  select {
    -webkit-appearance: none;
    width: 100%;
    height: 4rem;
    padding-left: 1rem;
    font-size: 1.6rem;
    color: white;
    border: 0;
    background-color: ${tertiaryDark};
    cursor: pointer;
  }
`

export const filterInputContainer = css`
  padding: 1rem;
  flex: 0 0 auto;
`

export const filterInput = css`
  border-radius: 0.1rem;
  padding: 1.4rem;
  /* right padding to account for spyglass background image */
  padding-right: 3.5rem;
  position: relative;
  background-image: url('assets/spyglass.svg');
  background-repeat: no-repeat;
  background-size: 1.6rem;
  background-position: 96% center;
`

export const productGroups = css`
  flex: 1 1 auto;
  background-color: ${tertiaryDark};
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

export const accordionWrapper = css`
  padding: 1rem;
`

export const accordionButton = css`
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
  color: ${white};
  background-color: ${tertiaryDark};
  font-size: 1.4rem;
  cursor: pointer;
  padding: 2rem 1rem;
  text-align: left;

  svg > g {
    stroke: ${white};
  }
`

export const accordionButtonText = css`
  padding-left: 1rem;
`
