import { connect } from 'react-redux'
import qs from 'query-string'

import {
  getDepotInventory,
  saveBatchChanges
} from 'src/redux/depot-inventories/actions'
import DepotInventory from './'

function mapStateToProps (state, props) {
  const depot = props.selectedDepot
  const depotId = props.selectedDepotId

  const { page, query } = qs.parse(props.location.search)

  return {
    depot,
    depotId,
    email: state.user.email,
    page: Number(page),
    query,
    loading: state.depotInventories.loading
  }
}

function mapDispatchToProps (dispatch) {
  return {
    getDepotInventory: (id, limit, offset, query) => dispatch(getDepotInventory(id, limit, offset, query)),
    saveBatchChanges: (depotId, quantities) => dispatch(saveBatchChanges(depotId, quantities))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepotInventory)
