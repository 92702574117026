/*
* Form Field Validators
* each validator takes in an input and returns true
* if the for value is valid and false if it is invalid
*
**/

// tests if a basic text input is valid
export const isValidInput = (value) => {
  if (value) {
    return true
  }

  return false
}

// tests if an image input is valid
export const isValidImage = (value) => {
  if (!value.length) {
    return false
  }

  return true
}

// checks if a specific effect is valid, ensures
// there is either an amount and name or neither
export const isValidEffect = ({amount, name}) => {
  const emptyEffect = name === '' && amount === ''
  const fullEffect = name && amount
  // we only want to show an error on effects if the effect is partially full
  if (emptyEffect || fullEffect) {
    return true
  }

  return false
}
