import t from './actionTypes'

const defaultState = {}

const driverMap = (state = defaultState, { type, payload }) => {
  switch (type) {
    case t.SET_DRIVER_MAP:
      return payload

    case t.UPDATE_DRIVER_MAP:
      return {
        ...state,
        ...payload
      }

    case t.CLEAR_DRIVER_MAP:
      return defaultState

    default:
      return state
  }
}

export default driverMap
