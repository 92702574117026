import React from 'react'

import MCColors from 'src/helpers/css-variables'
const { midGray } = MCColors

export default function PhoneIcon ({ height = '0.75em', width = '0.75em', color = midGray }) {
  return (
    <svg
      height={height}
      width={width}
      viewBox='0 0 12 12'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Page-1' fill='none' fillRule='evenodd'>
        <g id='Depot-View---Deliveries-driver-selected' transform='translate(-683 -398)' fill={color}>
          <g id='Group-17' transform='translate(485 332)'>
            <g id='Group-19' transform='translate(34 24)'>
              <g id='Group-18'>
                <g id='Group-8' transform='translate(164 39)'>
                  <g id='Group-2'>
                    <path d='M3.08709688,3.93404044 L4.44404631,5.34526786 C4.82399215,5.67093572 4.82399215,6.32227145 4.44404631,6.64793931 L3.35848676,7.73349886 C4.11837845,9.36183819 5.69243979,10.9358995 7.26650114,11.6415132 L8.35206069,10.5559537 C8.73200653,10.1760078 9.32906428,10.1760078 9.70901012,10.5559537 L11.0659596,11.9129031 C11.4459054,12.292849 11.4459054,12.8899067 11.0659596,13.2698526 L9.70901012,14.626802 C7.32077911,17.015033 -1.96075502,7.67922089 0.373198005,5.34526786 L1.73014744,3.93404044 C2.11009328,3.60837258 2.70715103,3.60837258 3.08709688,3.93404044 Z' id='Phone-Icon' />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
