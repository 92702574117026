/**
 * Input component, consumed by higher level inputs components like ./input-with-label
 *
 * This component packages 2 pieces together - the input itself, and validation errors that
 * come with it.
 */

import React from 'react'
import { arrayOf, bool, func, node, string } from 'prop-types'
import omit from 'object.omit'
import { CHECKBOX } from './types'
import { css, cx } from 'emotion'
import {
  borderContainerLight,
  colorTextDark,
  defaultBorderRadius,
  fire,
  fontSizeParagraph,
  lightFire,
  marginFormElementsVertical,
  paddingContainer
} from 'helpers/css-variables'

const input = css`
  color: ${colorTextDark};
  padding: ${paddingContainer};
  border: 1px solid ${borderContainerLight};
  border-radius: ${defaultBorderRadius};
  font-size: 1.4rem;
  cursor: pointer;
  height: 4.5rem;
  box-sizing: border-box;
  font-weight: normal;
  margin: ${marginFormElementsVertical} 0;
  width: 100%;
  appearance: none;

  &::placeholder {
    color: white;
    font-size: ${fontSizeParagraph};
  }

  &.errorInput::placeholder {
    color: ${lightFire};
  }

  &:invalid {
    box-shadow: none;
  }
`

const hasValue = css`
  padding: calc(2.25 * ${paddingContainer}) ${paddingContainer} ${paddingContainer}
`

const errorInput = css`
  ${input};
  border: 1px solid ${fire} !important;
  background-color: ${lightFire};
`

const errorMessageCss = css`
  padding: 0;
`

const errors = css`
  color: ${fire};
  font-size: 1.2rem;
  list-style: none;
  padding: 0;
  margin: 0;
`

const inputContainer = css`
  position: relative;
`

const inputWrapper = css`
  position: relative;
`

class Input extends React.Component {
  constructor (props) {
    super(props)

    const {
      formatter,
      name,
      parser,
      type,
      eagerValidate,
      initialErrorState,
      initialValue,
      validators,
      inputIcon,
      ...inputProps
    } = props

    // use inputProps as a catch-all for other props that can go on default `input` html components,
    // like placeholder, autocomplete, etc.
    this.inputProps = inputProps

    this.name = name
    this.parser = parser
    this.type = type
    this.eagerValidate = eagerValidate
    this.initialValue = initialValue
    this.validators = validators
    this.inputIcon = inputIcon

    this.getErrors = this.getErrors.bind(this)
    this.isValid = this.isValid.bind(this)
    this.errorList = this.errorList.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)

    this.state = {
      // refers to whether the input should show errors.
      // For eager-validation inputs, this will flip onBlur.
      // For other inputs, this should be flipped on submit of the parent form.
      showErrors: initialErrorState,
      errors: [],
      value: formatter(this.props.initialValue)
    }
  }

  /**
   * Runs each validator function against the parsed value and adds resulting strings to array.
   * This is run on every change of the input, though errors are only displayed when state.showErrors is true
   * @return {[String]} Array of strings describing validation errors
   */
  getErrors (value) {
    const messages = []
    for (let i = 0; i < this.props.validators.length; i++) {
      const validator = this.props.validators[i]
      const message = validator(this.props.parser(value))
      if (message) messages.push(message)
    }

    return messages
  }

  /**
   * Checks if the input is valid by running the validators.
   * Valid inputs will have no validation messages
   * @return {Boolean} whether the input is valid or not
   */
  isValid () {
    return this.getErrors(this.parsedValue()).length === 0
  }

  /**
   * Used to set if the input has been blurred.
   */
  onBlur (e) {
    this.props.onBlur(e)

    // Show any validation errors if eagerValidate is set to true
    if (this.props.eagerValidate) {
      this.setState({
        showErrors: true,
        errors: this.getErrors(this.parsedValue())
      })
    }
  }

  /**
   * onChange updates the value and constantly runs validators
   */
  onChange (e) {
    /*
      Fixes android cursor position going out of place when formatting input value
      I tried:
      - using a different formatter.
      - updating it to our new form system.
      - setSelectionRange w/o the setTimeout
      - Queueing an additional setState

      I beg forgivess for this transgression about to occur.
    */
    const fValue = this.formattedValue()
    const cursorFix = this.props.cursorFix
      ? () => { setTimeout(() => { this.input.setSelectionRange(fValue.length, fValue.length) }, 0) }
      : () => {}

    this.setState(
      {
        value: this.formattedValue(),
        errors: this.getErrors(this.parsedValue())
      },
      cursorFix
    )

    this.props.onChange(e)
  }

  /**
   * Return value of input, which is used by #formattedValue and #parsedValue
   * Also used to check for presence of a value for `./input-with-label`
   * @return {String}
   */
  value () {
    if (!this.input) return ''

    let value

    switch (this.props.type) {
      case (CHECKBOX):
        value = this.input.checked
        break
      default:
        value = this.input.value
    }

    return value
  }

  /**
   * Formatted values should be displayed to the user.
   */
  formattedValue () {
    // always format the raw value of the input
    return this.props.formatter(this.parsedValue())
  }

  /**
   * Parsed values should be used for validation and for form submission
   */
  parsedValue () {
    return this.props.parser(this.value())
  }

  /**
   * Returns jsx for display of errors, when state.showErrors is true, used in #render
   */
  errorList () {
    return this.state.errors.map((errorMessage, index) => {
      return (
        <li key={index} className={cx(errorMessageCss)}>
          {errorMessage}
        </li>
      )
    })
  }

  render () {
    const shouldShowErrors = !this.isValid() && this.state.showErrors

    const inputStyles = cx({
      [errorInput]: shouldShowErrors,
      [input]: !shouldShowErrors,
      [hasValue]: Boolean(this.state.value)
    })

    // Filter invalid input attributes
    const inputProps = omit(this.inputProps, 'cursorFix')

    return (
      <div className={cx(inputContainer)}>
        <div className={cx(inputWrapper)}>
          <input
            {...inputProps}
            value={this.state.value}
            id={this.props.name}
            type={this.props.type}
            name={this.props.name}
            onBlur={this.onBlur}
            onChange={this.onChange}
            className={this.props.className ? this.props.className : inputStyles}
            ref={(input) => { this.input = input }}
          />
          {
            this.props.inputIcon ? this.props.inputIcon : null
          }
        </div>

        {
          shouldShowErrors
            ? <ul className={cx(errors)}>{this.errorList()}</ul>
            : null
        }
      </div>
    )
  }
}

Input.propTypes = {
  // ** Required propTypes ** //
  // Formatter takes the value and reformats it
  formatter: func.isRequired,
  // Name of input. Required for submitting values correctly
  name: string.isRequired,
  // Placeholder for input
  placeholder: string,
  // Parser takes the value and gets the data ready for form submission
  parser: func.isRequired,
  type: string.isRequired,

  // ** Optional propTypes ** //
  // specifying a className will OVERWRITE the default input types, useful for things
  // types with drastically different styles, like stylized checkboxes
  className: string,
  // Eager validating inputs will show errors onBlur instead of on form submit
  eagerValidate: bool,
  // Some cases require us to show any input an error has already. An example
  // is with the password input that allows the user to reveal what's been typed
  initialErrorState: bool,
  // Will be run through the formatter to get initial display value
  initialValue: string,
  inputIcon: node,
  // Passed onBlur handlers will be fired at the start of the onBlur function defined here
  onBlur: func,
  // Passed onChange handlers will be fired at the start of the onChange function defined here
  onChange: func,
  validators: arrayOf(func).isRequired,
  cursorFix: bool
}

Input.defaultProps = {
  eagerValidate: false,
  formatter: (value) => { return value },
  initialErrorState: false,
  initialValue: '',
  onBlur: () => {},
  onChange: () => {},
  parser: (value) => { return value },
  // Assume blank inputs have no validator functions
  validators: []
}

export default Input
