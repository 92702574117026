export default {
  SHOW_TAG_FORM: 'SHOW_TAG_FORM',
  HIDE_TAG_FORM: 'HIDE_TAG_FORM',

  SET_TAG_GROUPS: 'SET_TAG_GROUPS',

  SET_TAGS_MAP: 'SET_TAGS_MAP',
  UPDATE_TAGS_MAP: 'UPDATE_TAGS_MAP',

  SET_TAG_TYPES: 'SET_TAG_TYPES',

  SET_TAG_FILTER_TYPE: 'SET_TAG_FILTER_TYPE',
  SET_TAG_FILTER_STRING: 'SET_TAG_FILTER_STRING',

  SET_TAG_CONFLICTS: 'SET_TAG_CONFLICTS'
}
