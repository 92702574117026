import React, { PureComponent } from 'react'
import { array, func, object } from 'prop-types'

import {
  brandName as brandNameClass,
  conflictButton,
  productCheckbox,
  productsContainer,
  productId,
  productLabel,
  productTitle as productTitleClass
} from './style.js'

class SelectProductsList extends PureComponent {
  static propTypes = {
    brandsMap: object,
    items: array,
    productsMap: object,
    conflictsMap: object,
    selectedProducts: object,
    onSelectProduct: func.isRequired,
    openConflictModal: func.isRequired
  }

  static defaultProps = {
    productsMap: {},
    selectedProducts: {}
  }

  handleSelectProduct = ({ target: { value, checked } }) => {
    const { onSelectProduct } = this.props
    onSelectProduct(value, checked)
  }

  render () {
    const {
      brandsMap,
      conflictsMap,
      items,
      productsMap,
      selectedProducts
    } = this.props
    return (
      <div className={productsContainer}>
        {items.map((id, i) => {
          const product = productsMap[id]
          const productTitle = product.title
          const brandId = product.brand_id || ''

          const brand = brandsMap[brandId] || {}
          const brandName = brand.name || '* NO BRAND ID'
          const formattedId = `...${id.substr(id.length - 5)}`
          return (
            <label
              className={productLabel}
              htmlFor={`name_${id}`}
              key={`${id}-${i}`}
            >
              <input
                className={productCheckbox}
                onChange={this.handleSelectProduct}
                type='checkbox'
                // being explicit with false boolean so react
                // doesn't assume this is an uncontrolled component
                checked={selectedProducts[id] || false}
                id={`name_${id}`}
                value={id}
              />
              <span className={productTitleClass}>{productTitle}</span>
              <span className={brandNameClass}>{brandName}</span>
              <span className={productId}>ID: {formattedId}</span>
              {conflictsMap[id] &&
                <button className={conflictButton} onClick={() => this.props.openConflictModal(conflictsMap[id])}>⚠️</button>}
            </label>
          )
        })}
      </div>
    )
  }
}

export default SelectProductsList
