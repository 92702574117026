import React, { PureComponent } from 'react'
import {
  arrayOf,
  boolean,
  func,
  number,
  object,
  shape,
  string
} from 'prop-types'
import { DragDropContext } from 'react-beautiful-dnd'
import { Flex, Column, Row } from 'src/microcomponents/flexbox-helpers'
import styled from '@emotion/styled'
import { css, keyframes } from 'emotion'
import debounce from 'debounce'
import Button, { HYBRID } from 'components/button'

import Screen from 'components/screen/container'
import PhoneShell from 'assets/phone-shell.png'
import BackButton from 'components/back-button'
import List from 'components/draggable/list'
import ItemsSearch from 'microcomponents/items-search'
import TabBar from 'microcomponents/tab-bar'
import MenuSettingsTab from './tab-settings'
import MenuMainTab from './tab-main'

import {
  centerContainer,
  content,
  descriptionClass,
  groupList,
  groupOverflowContainer,
  inputDisabled,
  item,
  leftBar,
  main,
  menuSlugInput,
  mobileImg,
  overflowContainer,
  phoneContent,
  phoneDescription,
  reveal,
  rightBarForm,
  title,
  topInfo
} from '../edit-page-styles.js'

const TABS = {
  MENU: 'MENU',
  SETTINGS: 'SETTINGS'
}

export default class EditMenu extends PureComponent {
  static propTypes = {
    activeDepots: arrayOf(shape({
      id: number,
      name: string
    })),
    fetchGroups: func,
    items: object,
    searchCatalogGroups: func,
    sortedItems: func,
    uploadPublicFile: func,
    stateId: string,
    statesAvailable: arrayOf(shape({
      fenceId: number,
      name: string,
      orgins: arrayOf(object),
      state: string
    })),
    createOrUpdateMenu: func,
    menuItem: shape({
      color: string,
      depotsEnabled: arrayOf(shape({
        enabled: boolean,
        id: number,
        name: string
      })),
      description: string,
      id: string,
      name: string,
      slug: string,
      /* eslint-disable camelcase */
      desktop_image: string,
      icon_image: string,
      mobile_image: string,
      state_id: string
      /* eslint-enable camelcase */
    }),
    resetMenuItem: func,
    updateMenuObject: func

  }

  state = {
    color: 'transparent',
    currentTab: TABS.MENU,
    depotsEnabled: [],
    description: '',
    desktop_image: '',
    hasSetDepotsEnabled: false,
    icon_image: '',
    id: '',
    mobile_image: '',
    name: '',
    showColorPicker: false,
    slug: '',
    state_id: '',
    tabs: [TABS.MENU, TABS.SETTINGS]
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps (nextProps) {
    const {
      activeDepots,
      menuItem: {
        color,
        depots_enabled: depotsConfirmed,
        description,
        id,
        name,
        slug,
        /* eslint-disable camelcase */
        desktop_image,
        icon_image,
        mobile_image,
        state_id
        /* eslint-enable camelcase */
      }
    } = nextProps

    let hasSetDepotsEnabled = this.state.hasSetDepotsEnabled
    let depotsEnabled = [...this.state.depotsEnabled]
    if (!depotsEnabled.length && activeDepots.length) {
      depotsEnabled = activeDepots.map(depotObj => ({
        id: depotObj.id,
        name: depotObj.name,
        enabled: false
      }))
    }

    if (depotsConfirmed && activeDepots.length && !hasSetDepotsEnabled) {
      depotsConfirmed.forEach(function (id) {
        const depotObj = depotsEnabled.find((depotObj) => depotObj.id === id)
        if (depotObj) {
          depotObj.enabled = true
        }
      })

      hasSetDepotsEnabled = true
    }

    this.setState({
      color,
      depotsEnabled,
      description,
      desktop_image,
      hasSetDepotsEnabled,
      icon_image,
      id,
      mobile_image,
      name,
      slug,
      state_id
    })
  }

  componentWillUnmount () {
    this.props.resetMenuItem()
  }

  saveFormValue = (value, key) => {
    this.setState({ [key]: value })
    this.updateMenuStore()
  }

  updateMenuStore = debounce(() => {
    this.props.updateMenuObject(this.state)
  }, 200)

  handleCreateOrUpdateMenu = () => {
    this.props.updateMenuObject(this.state)
    this.props.createOrUpdateMenu()
  }

  handleOnDragEnd = (result) => {
    const { items, sortedItems } = this.props
    const { draggableId, source, destination } = result
    // item didn't move anywhere
    if (!destination) return

    // item dropped in same position as starting position
    if (destination.droppableId === source.droppableId && destination.index === source.index) return

    // move item from old index to new index
    const start = source.droppableId
    const finish = destination.droppableId

    let newState = {
      ...items
    }

    // reorder in 1 column
    if (start === finish) {
      const updatedItems = [...items[start]]
      const movedItem = updatedItems.find(item => item.id === draggableId)
      updatedItems.splice(source.index, 1)
      updatedItems.splice(destination.index, 0, movedItem)

      newState = {
        ...newState,
        [destination.droppableId]: updatedItems
      }
    } else {
      // move between lists
      const updatedStartItems = [...items[start]]
      const movedStartItem = updatedStartItems.find(item => item.id === draggableId)
      updatedStartItems.splice(source.index, 1)

      const updatedFinishItems = [...items[finish]]
      updatedFinishItems.splice(destination.index, 0, movedStartItem)

      newState = {
        [source.droppableId]: updatedStartItems,
        [destination.droppableId]: updatedFinishItems
      }
    }
    sortedItems(newState)
  }

  setDepotEnabled = (index) => {
    // allows us to curry an onClick function in MenuSettingsTab so we have access to the index/rule we are toggling without creating a ton of fn's
    return () => {
      const { depotsEnabled } = this.state
      const newDepotsEnabled = [...depotsEnabled]
      newDepotsEnabled[index].enabled = !newDepotsEnabled[index].enabled
      this.setState({ depotsEnabled: newDepotsEnabled }, this.updateMenuStore)
    }
  }

  setAllDepotsEnabled = (enabled) => {
    const { depotsEnabled } = this.state
    const newDepotsEnabled = [...depotsEnabled].map(function (depot) {
      depot.enabled = enabled
      return depot
    })
    this.setState({ depotsEnabled: newDepotsEnabled }, this.updateGroupStore)
  }

  render () {
    const {
      fetchGroups,
      items,
      searchCatalogGroups,
      stateId,
      statesAvailable,
      uploadPublicFile
    } = this.props

    const {
      color: menuColor,
      currentTab,
      depotsEnabled,
      description,
      desktop_image: desktopImage,
      icon_image: iconImage,
      id,
      mobile_image: mobileImage,
      name,
      slug,
      tabs
    } = this.state

    const isEdit = Boolean(id)

    const isOnSettingsTab = currentTab === TABS.SETTINGS
    const noOverflowOnSettingsTab = isOnSettingsTab ? '' : 'auto'

    return (
      <Screen>
        <Column className={main}>

          <Row full>
            <Column className={leftBar}>
              <BackButton path='/catalog/menus' />
            </Column>
            <DragDropContext onDragEnd={this.handleOnDragEnd}>

              <Row full className={reveal}>
                <Flex
                  full top={1}
                  componentStyle={{
                    height: '100vh',
                    overflow: noOverflowOnSettingsTab
                  }}
                >

                  <MobileImagePreviewClass mobileImage={mobileImage}>
                    <div className={phoneContent}>
                      <img src={mobileImage} className={mobileImg} />
                      <span className={phoneDescription}>{description}</span>
                    </div>
                  </MobileImagePreviewClass>

                  <Column
                    className={centerContainer}
                    componentStyle={{ margin: '0 auto' }}
                  >
                    <Row align='center' justify='space-between'>
                      <TabBar tabs={tabs} onClick={(tab) => this.saveFormValue(tab, 'currentTab')} currentTab={currentTab} />
                      <div>
                        <StyledButton onClick={this.handleCreateOrUpdateMenu} type={HYBRID}>Save Menu</StyledButton>
                      </div>

                    </Row>

                    <Row align='center' justify='space-between' className={topInfo} top={1}>
                      <input
                        className={title}
                        onChange={event => this.saveFormValue(event.target.value, 'name')}
                        value={name}
                        placeholder='Menu name'
                      />

                      <span className={menuSlugInput}>
                        <label htmlFor='slug'>www.eaze.com/.../</label>
                        <input
                          id='slug'
                          // disable changing slug if the user is editing
                          disabled={isEdit}
                          className={isEdit ? inputDisabled : descriptionClass}
                          onChange={event => this.saveFormValue(event.target.value, 'slug')}
                          value={slug}
                          style={{ paddingLeft: 0 }}
                          placeholder='menu-slug'
                        />
                      </span>
                    </Row>

                    {currentTab === TABS.MENU &&
                      (
                        <Column className={content} top={1} full>
                          <MenuMainTab
                            description={description}
                            menuColor={menuColor}
                            saveFormValue={this.saveFormValue}
                            desktopImage={desktopImage}
                            iconImage={iconImage}
                            uploadPublicFile={uploadPublicFile}
                          />
                          <div className={groupOverflowContainer}>
                            <List
                              className={GroupList}
                              emptyListText='Groups that are associated with MENU will show up here.'
                              itemClassName={item}
                              items={items.menuItems}
                              listName='menuItems'
                              narrow
                            />
                          </div>
                        </Column>
                      )}

                    {currentTab === TABS.SETTINGS &&
                      <MenuSettingsTab
                        depotsEnabled={depotsEnabled}
                        setAllDepotsEnabled={this.setAllDepotsEnabled}
                        setDepotEnabled={this.setDepotEnabled}
                        slug={slug}
                        stateId={stateId}
                        statesAvailable={statesAvailable}
                        saveFormValue={this.saveFormValue}
                      />}

                  </Column>
                </Flex>

                <Sidebar hide={isOnSettingsTab}>
                  <Column className={overflowContainer}>
                    <ItemsSearch
                      formClassName={rightBarForm}
                      placeholder='Search Groups'
                      resetAction={fetchGroups}
                      searchAction={searchCatalogGroups}
                    />
                    <List
                      className={groupList}
                      itemClassName={item}
                      items={items.groups}
                      listName='groups'
                    />
                  </Column>
                </Sidebar>

              </Row>
            </DragDropContext>
          </Row>
        </Column>

      </Screen>
    )
  }
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const Sidebar = styled.div`
  box-shadow: 0 0 3rem rgba(0,0,0,.5);
  flex-shrink: 0;
  height: 100vh;
  width: 35rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  visibility: ${({ hide }) => hide ? 'hidden' : ''};
`

const StyledButton = styled(Button)`
  font-family: inherit;
`

const MobileImagePreviewClass = styled.div`
  display: ${({ mobileImage }) => mobileImage ? '' : 'none'};
  background-image: url(${PhoneShell});
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  z-index: 10;
  width: 30rem;
  height: 30rem;
  bottom: 0;
  left: 5rem;
  animation: ${({ mobileImage }) => mobileImage ? `${fadeIn} 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;` : 'none'};
`

// magic number: 30px here is twice the padding height (top and bottom)
const GroupList = css`
  padding: 0 15px;
  height: calc(100% - 30px)
`
