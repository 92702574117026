import { connect } from 'react-redux'

import { pushNotification } from 'src/redux/alerts/actions'
import {
  clearCartQuote,
  clearCatalogCartEta,
  clearCatalogBuilderStore,
  clearUser,
  createCatalogCart,
  createConciergeCart,
  fetchCatalogCartEta,
  fetchMenu,
  fetchUser,
  onPlaceFetched,
  removeOutOfDepotItem,
  resetMenu,
  setCatalogBucket,
  setCatalogBuilderMenuSlug,
  setProductsFilter,
  setShowOrderDrawer,
  updateCart,
  updateCatalogCart,
  updateConciergeCart
} from 'src/redux/catalog-builder/actions'

import {
  fetchDepotInventory
} from 'src/redux/depot-inventories/actions'

import { clearMenus } from 'src/redux/menus/actions'
import { clearGroups } from 'src/redux/groups/actions'
import { clearProducts } from 'src/redux/products/actions'
import { fetchPlace, clearPlaceStore } from 'src/redux/place/actions'
import { getDepotsMap } from 'src/redux/depots-map/selectors'
import { fetchAllDispensaries } from 'src/redux/dispensaries/actions'
import { idToInt } from 'helpers/sanitizers'

import {
  createCatalogCase,
  deleteCase,
  getCaseById,
  saveCase,
  updateCase,
  updateCatalogCase
} from 'src/redux/cases/actions'

import {
  enableSendCart,
  filterGroupProducts,
  getCartId,
  getCartQuote,
  getCartTotal,
  getCaseColor,
  getCaseDescription,
  getCaseName,
  getCatalogCartEtaValue,
  getCatalogBucket,
  getCatalogBucketId,
  getCatalogErrors,
  getConciergeSlug,
  getCurrentMenu,
  getCaseDepotId,
  getHasCartQuoteTotal,
  getIsEtaLoading,
  getIsQuoteLoading,
  getMenuSlug,
  getOutOfDepotItems,
  getShowOrderDrawer,
  getUser
} from 'src/redux/catalog-builder/selectors'
import { getProductStore } from 'src/redux/products/selectors'
import { getPlaceStore } from 'src/redux/place/selectors'

import { getOrderBuilderPollInterval } from 'src/redux/firebase/selectors'

import CatalogBuilder from './'

const noop = function () {}

const noopIfCaseBuilder = function (isCaseBuilder) {
  return function (fnToDispatch) {
    return isCaseBuilder ? noop : fnToDispatch
  }
}

const switchFnIfCase = function (isCaseBuilder) {
  return function (fnIfCaseBuilder, fnIfCatalogBuilder) {
    return isCaseBuilder ? fnIfCaseBuilder : fnIfCatalogBuilder
  }
}

const mapStateToProps = function (state, props) {
  const { match } = props
  const depotId = idToInt(match.params.id || state.depots.id)
  const isCaseBuilder = props.preloadedProps.isCaseBuilder
  const depotsMap = getDepotsMap(state)
  const currentDepot = depotsMap[depotId]

  return {
    cartId: getCartId(state),
    cartQuote: getCartQuote(state),
    cartQuoteTotal: getCartTotal(state),
    caseColor: getCaseColor(state),
    caseDepotId: getCaseDepotId(state),
    caseDescription: getCaseDescription(state),
    caseName: getCaseName(state),
    catalogBucket: getCatalogBucket(state),
    catalogErrors: getCatalogErrors(state),
    catalogCartEtaValue: getCatalogCartEtaValue(state),
    catalogBucketId: isCaseBuilder ? match.params.caseId : getCatalogBucketId(state),
    conciergeSlug: getConciergeSlug(state),
    currentDepot,
    depotId,
    enableSendCart: enableSendCart(state),
    groups: filterGroupProducts(state),
    hasCartQuoteTotal: getHasCartQuoteTotal(state),
    isCaseBuilder,
    isEtaLoading: getIsEtaLoading(state),
    isQuoteLoading: getIsQuoteLoading(state),
    menu: getCurrentMenu(state),
    menuSlug: getMenuSlug(state),
    outOfDepotItems: getOutOfDepotItems(state),
    place: getPlaceStore(state),
    etaPollInterval: getOrderBuilderPollInterval(state),
    products: getProductStore(state),
    showOrderDrawer: getShowOrderDrawer(state),
    user: getUser(state),
    userId: isCaseBuilder ? null : match.params.id
  }
}

const mapDispatchToProps = function (dispatch, props) {
  const isCaseBuilder = props.preloadedProps.isCaseBuilder
  // this container is being used for both carts and cases, so to keep the component clean and avoid if/else clauses everywhere
  // anything that is shared has been renamed to `bucket`.  Any action that is separate is kept as cart/case
  // we want to keep all the same props passed in to it, but we want different actions/dispatches based on whether or not we are building a case or a cart
  // this could be separate files with different mapDispatch and mapState, but since the component requires
  // specific actions passed in (an api contract essentially) it's easier to have it in the same file so we can see what needs to be passed in
  const fnOrNoop = noopIfCaseBuilder(isCaseBuilder)
  const switchedFn = switchFnIfCase(isCaseBuilder)

  return {
    archiveCase: (id, depotId) => dispatch(deleteCase(id, depotId)),
    clearCartQuote: fnOrNoop(() => dispatch(clearCartQuote())),
    clearCatalogCartEta: fnOrNoop(() => dispatch(clearCatalogCartEta())),
    clearGroups: () => dispatch(clearGroups()),
    clearMenus: () => dispatch(clearMenus()),
    clearCatalogBuilderStore: () => dispatch(clearCatalogBuilderStore()),
    clearPlaceStore: () => dispatch(clearPlaceStore()),
    clearProducts: () => dispatch(clearProducts()),
    clearUser: fnOrNoop(() => dispatch(clearUser())),
    createCatalogBucket: switchedFn(() => dispatch(createCatalogCase()), (catalogCart) => dispatch(createCatalogCart(catalogCart))),
    createConciergeCart: fnOrNoop((payload) => dispatch(createConciergeCart(payload))),
    getCaseById: (id) => dispatch(getCaseById(id)),
    fetchCatalogCartEta: fnOrNoop((catalogCartId, placeId) => dispatch(fetchCatalogCartEta(catalogCartId, placeId))),
    fetchDepotInventory: (depotId) => dispatch(fetchDepotInventory(depotId, true)),
    fetchAllDispensaries: () => dispatch(fetchAllDispensaries()),
    fetchMenu: fnOrNoop((placeId, slug) => dispatch(fetchMenu(placeId, slug))),
    fetchPlace: fnOrNoop((placeId, callback) => dispatch(fetchPlace(placeId, callback))),
    fetchUser: fnOrNoop((id) => dispatch(fetchUser(id))),
    onPlaceFetched: fnOrNoop((err, place) => dispatch(onPlaceFetched(err, place))),
    pushNotification: (message, type) => dispatch(pushNotification(message, type)),
    removeOutOfDepotItem: (catalogId) => dispatch(removeOutOfDepotItem(catalogId)),
    resetMenu: fnOrNoop((placeId, slug) => dispatch(resetMenu(placeId, slug))),
    saveCase: (payload) => dispatch(saveCase(payload)),
    setCatalogBucket: (Bucket) => dispatch(setCatalogBucket(Bucket)),
    setCatalogBuilderMenuSlug: fnOrNoop((slug) => dispatch(setCatalogBuilderMenuSlug(slug))),
    setProductsFilter: (productsFilter) => dispatch(setProductsFilter(productsFilter)),
    setShowOrderDrawer: (showOrderDrawer) => dispatch(setShowOrderDrawer(showOrderDrawer)),
    updateBucket: switchedFn((payload) => dispatch(updateCase(payload)), (cartId, products, menuSlug) => dispatch(updateCart(cartId, products, menuSlug))),
    updateCatalogBucket: switchedFn((catalogCase, id) => dispatch(updateCatalogCase(catalogCase, id)), (catalogCart, id) => dispatch(updateCatalogCart(catalogCart, id))),
    updateConciergeCart: fnOrNoop((payload) => dispatch(updateConciergeCart(payload)))
  }
}

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CatalogBuilder)

export default reduxContainer
