import React from 'react'

export default function IconCases ({ color = 'white', size = '3em', componentStyle }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 22 23'
      version='1.1'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g
        transform='translate(-218.000000, -109.000000) translate(156.000000, 92.000000) translate(54.000000, 14.000000) translate(4.000000, 0.000000) translate(0.000000, 0.156334)'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <rect x={0} y={0} width={30} height={30} />
        <path
          d='M14.563 3.09a.877.877 0 0 1 .252.045l9.805 3.922c.272.11.462.396.46.69v13.235c0 .29-.19.573-.46.682l-9.805 3.921a.744.744 0 0 1-.551 0L4.46 21.664a.754.754 0 0 1-.46-.682V7.746a.754.754 0 0 1 .46-.689l9.804-3.922a.8.8 0 0 1 .299-.046zm-.023 1.524L6.727 7.739l2.91 1.172 7.82-3.133-2.917-1.164zm4.902 1.96L11.629 9.7l2.91 1.171 7.813-3.132-2.91-1.165zm4.167 2.26l-8.334 3.332v11.65l8.334-3.34V8.835zm-18.138 0v11.643l8.333 3.34V12.165l-3.431-1.371v2.834a.735.735 0 1 1-1.47 0v-3.424L5.47 8.834z'
          fill={color}
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
