import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { array, object, oneOfType, string } from 'prop-types'

export default class NavButtonGroup extends PureComponent {
  static propTypes = {
    children: oneOfType([array, object]),
    ComponentStyle: string
  }

  render () {
    const { children, ComponentStyle } = this.props
    return (
      <Navbar componentstyle={ComponentStyle}>
        {children}
      </Navbar>
    )
  }
}

const Navbar = styled.div`
  display: flex;

  ${({ componentstyle }) => componentstyle}
`
