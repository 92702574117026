import React, { PureComponent } from 'react'
import { bool, func, object, string } from 'prop-types'
import styled from '@emotion/styled'
import * as JsSearch from 'js-search'
import debounce from 'debounce'

import history from 'src/components/router/history'
import Screen from 'src/components/screen/container'
import FabFan from 'src/components/fab-fan'
import Overlay from 'src/components/overlay'
import RightBar from 'src/components/right-bar'
import Switch from 'src/microcomponents/switch'
import Table from 'src/microcomponents/table'
import SearchInput from 'src/components/mui/search-input'

import EditAdultUseTerritory from 'src/pages/territory-management/edit'

class TerritoryManagement extends PureComponent {
  static propTypes = {
    createTerritory: func,
    deleteTerritory: func,
    hideTerritoryForm: func,
    isTerritoryFormOpen: bool,
    showTerritoryForm: func,
    territoriesMap: object,
    territoryId: string,
    updateTerritory: func
  }

  state = {
    searchIndex: {},
    searchResults: [],
    searchText: ''
  }

  componentDidMount () {
    const { territoryId } = this.props
    this.handleFormOpen(territoryId)
    this.buildSearchIndex()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.territoriesMap !== this.props.territoriesMap) {
      this.buildSearchIndex()
    }

    if (prevState.searchIndex !== this.state.searchIndex) {
      this.handleSearch(this.state.searchText)
    }
  }

  buildSearchIndex = () => {
    const { territoriesMap } = this.props
    const territories = Object.values(territoriesMap)

    const dataToSearch = new JsSearch.Search('id')
    dataToSearch.tokenizer = new JsSearch.StopWordsTokenizer(new JsSearch.SimpleTokenizer())
    dataToSearch.addIndex('city')
    dataToSearch.addIndex('zipcode')
    dataToSearch.addIndex('comments')
    dataToSearch.addDocuments(territories)
    this.setState({ searchIndex: dataToSearch })
  }

  handleFormOpen = (territoryId) => {
    const { showTerritoryForm } = this.props
    if (territoryId) {
      showTerritoryForm()
    }
  }

  handleSearch = (query) => {
    const { searchIndex } = this.state
    const queryResult = searchIndex.search(query)
    this.setState({ searchResults: queryResult, searchText: query })
  }

  handleHideForm = () => {
    const { hideTerritoryForm } = this.props
    hideTerritoryForm()
    history.push({ search: '' })
  }

  handleTableRowClick = ({ id }) => {
    history.push({ search: `?territoryId=${id}` })
    this.handleFormOpen(id)
  }

  sanitizeTerritoryDataForFormInputs (territoryData = {}) {
    const {
      city,
      comments,
      enabled,
      id,
      identityScanRequired,
      selfieRequired,
      state,
      usaOnly,
      zipcode
    } = territoryData
    return {
      city: city || '',
      comments: comments || '',
      enabled: enabled || false,
      id: id || null,
      identityScanRequired: identityScanRequired || false,
      selfieRequired: selfieRequired || false,
      state: state || '',
      usaOnly: usaOnly || false,
      zipcode: zipcode || ''
    }
  }

  render () {
    const {
      createTerritory,
      deleteTerritory,
      isTerritoryFormOpen,
      showTerritoryForm,
      territoriesMap,
      territoryId,
      updateTerritory
    } = this.props

    const { searchResults, searchText } = this.state

    const displayedTerritories = searchText ? searchResults : Object.values(territoriesMap)

    const switchFormatter = (isEnabled) => <div style={{ textAlign: 'center' }}><Switch enabled={isEnabled} /></div>
    const thStyle = (title) => <div style={{ textAlign: 'center' }}>{title}</div>

    const tableConfig = [
      {
        title: 'City',
        key: 'city'
      },
      {
        title: 'State',
        key: 'state'
      },
      {
        title: 'Zip Code',
        key: 'zipcode'
      },
      {
        title: 'Comments',
        key: 'comments'
      },
      {
        title: thStyle('Adult Use Enabled'),
        key: 'enabled',
        formatter: switchFormatter
      },
      {
        title: thStyle('ID Scan'),
        key: 'identityScanRequired',
        formatter: switchFormatter
      },
      {
        title: thStyle('Accepts USA IDs Only'),
        key: 'usaOnly',
        formatter: switchFormatter
      },
      {
        title: thStyle('Selfie Required'),
        key: 'selfieRequired',
        formatter: switchFormatter
      }
    ]

    return (
      <Screen>
        <Container>
          <SearchInput
            margin='0 0 2rem'
            onChange={debounce(this.handleSearch, 350, true)}
            placeholder='Search by City, Zip Code, or Comments'
            value={searchText}
          />
          {`Total Results: ${displayedTerritories.length}`}
          <Table
            config={tableConfig}
            data={displayedTerritories}
            height='calc(100vh - 20rem)'
            rowClick={this.handleTableRowClick}
            rowStyle={() => {}}
            sortAscending
            sortBy='zipcode'
            noDataMsg='Not Found'
          />
          <FabFan
            onClick={showTerritoryForm}
            options={[]}
          />

          <RightBar isOpen={isTerritoryFormOpen}>
            <EditAdultUseTerritory
              createTerritory={createTerritory}
              deleteTerritory={deleteTerritory}
              isTerritoryFormOpen={isTerritoryFormOpen}
              territoryData={this.sanitizeTerritoryDataForFormInputs(territoriesMap[territoryId])}
              territoryId={territoryId}
              updateTerritory={updateTerritory}
            />
          </RightBar>

          <Overlay
            onClick={this.handleHideForm}
            isOpen={isTerritoryFormOpen}
          />
        </Container>
      </Screen>
    )
  }
}

const Container = styled.div`
  padding: 3rem;
`

export default TerritoryManagement
