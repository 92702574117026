import React from 'react'

import { tagTypeColor, tagTypeStyle } from './helpers'

export default function PriceTag ({ color = tagTypeColor, height = '16px', width = '16px', style = tagTypeStyle }) {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Price</title>
      <desc>Created with Sketch.</desc>
      <g id='MC' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Price' transform='translate(-4.000000, -4.000000)' fill={color} fillRule='nonzero'>
          <g transform='translate(4.000000, 4.000000)' id='Group'>
            <g>
              <path d='M10.9697911,9.57658594 L10.9697911,9.71673778 C10.9697911,10.9482744 9.96789612,11.9501693 8.73635952,11.9501693 L8.5868186,11.9501693 L8.5868186,13.2813673 L7.4131814,13.2813673 L7.4131814,11.9501693 L5.73145714,11.9501693 L5.73145714,10.7765321 L8.5868186,10.7765321 L8.73635952,10.7765321 C9.32073303,10.7765321 9.79615388,10.3011113 9.79615388,9.71673778 L9.79615388,9.57658594 C9.79615388,8.99221243 9.32073303,8.51679157 8.73635952,8.51679157 L7.26364048,8.51679157 C6.03220167,8.51679157 5.03020892,7.51479883 5.03020892,6.28326222 C5.03020892,5.05172562 6.03220167,4.04983068 7.26364048,4.04983068 L7.4131814,4.04983068 L7.4131814,2.71863271 L8.5868186,2.71863271 L8.5868186,4.04983068 L10.2685429,4.04983068 L10.2685429,5.22346786 L7.26364048,5.22346786 C6.67926697,5.22346786 6.20384612,5.69888871 6.20384612,6.28326222 C6.20384612,6.86763573 6.67926697,7.3431544 7.26364048,7.3431544 L8.73635952,7.3431544 C9.96789612,7.3431544 10.9697911,8.34504933 10.9697911,9.57658594 M8,14.8263628 C4.23594997,14.8263628 1.17363717,11.76405 1.17363717,8 C1.17363717,4.23594997 4.23594997,1.17363717 8,1.17363717 C11.7641478,1.17363717 14.8263628,4.23594997 14.8263628,8 C14.8263628,11.76405 11.7641478,14.8263628 8,14.8263628 M8,0 C3.58878688,0 0,3.58878688 0,8 C0,12.4112131 3.58878688,16 8,16 C12.4112131,16 16,12.4112131 16,8 C16,3.58878688 12.4112131,0 8,0' id='Shape' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
