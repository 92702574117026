import t from './action-types'
import { flatten, unflatten } from 'flat'
import { dateOnly } from 'src/helpers/date'
import { addDays } from 'date-fns'

// Safe: true = preserve arrays
const FLATTEN_OPTS = { safe: true }

// Conforms to https://eaze.github.io/pine/#!/promos/post_promos
// Separate from state so we can access an unflattened version for easy reference (See RESET_PROMO_BOGO)
const promoModel = {
  enabled: true,
  code: null,
  description: null,
  valid_at: `${dateOnly()}T12:00Z`, // 5am Pacfic
  expires_at: `${dateOnly(addDays(new Date(), 1).toISOString())}T06:00Z`, // 11pm pacific
  rules: {
    applies_to: null,
    promo_type: null,
    global_limit: 0,
    user_limit: 1,
    order_history: null,
    min_order_amount: null,
    state_id: '',
    user_cohort: {
      verification_status: null,
      total_orders: null,
      dispensary_id: null,
      depot_ids: null
    },
    operators: {
      min_order_amount: null, // DEPRECATE ME
      credit_amount: null,
      credit_type: null,
      credit_max: null,
      id_type: null,
      id_value: null,
      buy: {
        id_type: null,
        id_value: null,
        quantity: null
      },
      get: {
        id_type: null,
        id_value: null,
        quantity: null
      }
    }
  },
  meta: {
    marketing_channel: ''
  }
}

// NOTE! We flatten this so we can manage the reducer operations and validation easily, but we consume it in it's unflattened form at the component level.
const defaultState = flatten({
  // Read only
  id: null,
  // UI only properties.
  catalogName: '',
  catalogNameBuy: '',
  catalogNameGet: '',
  selectedPromoDefinition: 0,
  unFlat: function unFlat (promo) { return unflatten(promo || this) },
  ...promoModel
},
FLATTEN_OPTS)

const managePromos = (state = defaultState, action) => {
  switch (action.type) {
    case t.RESET_MANAGE_PROMO_STATE:
      return { ...defaultState }

    case t.SET_PROMO_TO_EDIT:
      return flatten({
        ...defaultState.unFlat(),
        ...action.payload
      }, FLATTEN_OPTS)

    case t.SET_SELECTED_PROMO_DEFINITION:
      return {
        ...state,
        selectedPromoDefinition: action.payload
      }

    case t.SET_PROMO_CODE:
      return {
        ...state,
        code: action.text
      }

    case t.SET_PROMO_DESC:
      return {
        ...state,
        description: action.desc
      }

    case t.SET_PROMO_TYPE:
      return {
        ...state,
        'rules.promo_type': action.payload
      }

    case t.SET_PROMO_START_DATE:
      return {
        ...state,
        valid_at: action.date
      }

    case t.SET_PROMO_END_DATE:
      return {
        ...state,
        expires_at: action.date
      }

    case t.SET_PROMO_APPLIES_TO:
      return {
        ...state,
        'rules.applies_to': action.payload
      }

    case t.SET_PROMO_ID_VALUE:
      return {
        ...state,
        'rules.operators.id_value': action.payload
      }

    case t.SET_PROMO_ID_TYPE:
      return {
        ...state,
        'rules.operators.id_type': action.payload
      }

    case t.SET_TOTAL_ORDER_MIN:
      return {
        ...state,
        'user_cohort.total_orders': action.value
      }

    case t.SET_PROMO_CREDIT_TYPE:
      return {
        ...state,
        'rules.operators.credit_type': action.promoType
      }

    case t.SET_PROMO_AMOUNT:
      return {
        ...state,
        'rules.operators.credit_amount': action.amount
      }

    case t.SET_PROMO_CREDIT_MAX:
      return {
        ...state,
        'rules.operators.credit_max': action.amount
      }

    case t.SET_PROMO_GLOBAL_USE:
      return {
        ...state,
        'rules.global_limit': action.globalUse
      }

    case t.SET_PROMO_USER_USE:
      return {
        ...state,
        'rules.user_limit': action.userUse
      }

    case t.SET_PROMO_STATE_ID:
      return {
        ...state,
        'rules.state_id': action.payload
      }

    case t.SET_PROMO_DEPOTS:
      return {
        ...state,
        'rules.user_cohort.depot_ids': action.payload
      }

    case t.SET_PROMO_DISPENSARY:
      return {
        ...state,
        'rules.user_cohort.dispensary_id': action.payload
      }

    case t.SET_PROMO_BUY_ONE_ID_TYPE:
      return {
        ...state,
        'rules.operators.buy.id_type': action.buyOneType
      }

    case t.SET_PROMO_BUY_ONE_ID_VALUE:
      return {
        ...state,
        'rules.operators.buy.id_value': action.buyOneValue
      }

    case t.SET_PROMO_BUY_ONE_AMOUNT:
      return {
        ...state,
        'rules.operators.buy.quantity': action.buyOneAmount
      }

    case t.SET_PROMO_GET_ONE_ID_TYPE:
      return {
        ...state,
        'rules.operators.get.id_type': action.getOneType
      }

    case t.SET_PROMO_GET_ONE_ID_VALUE:
      return {
        ...state,
        'rules.operators.get.id_value': action.getOneValue
      }

    case t.SET_PROMO_GET_ONE_AMOUNT:
      return {
        ...state,
        'rules.operators.get.quantity': action.getOneAmount
      }

    case t.SET_MIN_ORDER_VALUE:
      return {
        ...state,
        'rules.min_order_amount': action.value
      }

    case t.SET_CATALOG_NAME:
      if (action.meta && action.meta.nameType === 'buy') {
        return {
          ...state,
          catalogNameBuy: action.payload
        }
      } else if (action.meta && action.meta.nameType === 'get') {
        return {
          ...state,
          catalogNameGet: action.payload
        }
      } else {
        return {
          ...state,
          catalogName: action.payload
        }
      }

    case t.SET_PROMO_CHANNEL:
      return {
        ...state,
        'meta.marketing_channel': action.channel
      }

    case t.SET_ORDER_HISTORY:
      return {
        ...state,
        'rules.order_history': action.payload === 'null' ? null : action.payload
      }

    case t.SET_PROMO_USER_IDS:
      return {
        ...state,
        'rules.user_cohort.user_ids': action.payload
      }

    case t.RESET_PROMO_BOGO:
      return {
        ...state,
        ...flatten(promoModel.rules.operators.buy),
        ...flatten(promoModel.rules.operators.get)
      }

    default:
      return state
  }
}

export default managePromos
