import { css } from '@emotion/core'
import { darkDivider } from 'helpers/css-variables'

export const tabContainer = css`
  display: flex;
`

export const button = css`
  padding: 2rem;
  color: white;
  background: transparent;
  border: 1px solid rgba(255,255,255, 0.2);
  cursor: pointer;
  width: 15rem;
`

export const active = css`
  background: ${darkDivider};
  color: white;
`
