import styled from '@emotion/styled'

const DateInput = styled.input`
  margin: ${({ margin }) => margin || 0};
  &::-webkit-calendar-picker-indicator {
    opacity: 1;
  }
  &::-webkit-inner-spin-button {
    display: none;
  }
  width: 16rem;
`

export default DateInput
