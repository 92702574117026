import React from 'react'
import styled from '@emotion/styled'

import Screen from 'src/components/screen'
import { func } from 'prop-types'

const ErrorPage = ({ requestLogin }) => {
  return (
    <Screen>
      <Container>There was an issue with your permissions. Please contact an admin.</Container>
    </Screen>
  )
}

ErrorPage.propTypes = {
  requestLogin: func
}

export default ErrorPage

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
