import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
// https://material-ui.com/components/text-fields
// https://material-ui.com/api/text-field/
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import { eazeBlue } from 'src/helpers/css-variables'

const useStyles = makeStyles(theme => {
  const font = {
    color: 'white',
    fontSize: '1.4rem'
  }
  return {
    root: {
      margin: props => props.margin,
      width: props => props.width,
      borderTopLeftRadius: '0.4rem',
      borderTopRightRadius: '0.4rem'
    },
    input: {
      backgroundColor: '#373838', // TODOAH Move to ThemeProvider palette
      '&::after': {
        borderBottom: `2px solid ${eazeBlue}` // color the animated bottom border blue
      },
      ...font
    },
    inputLabel: {
      '&.Mui-focused': {
        color: eazeBlue // color the label blue when it moves into border
      },
      ...font
    },
    iconButton: font
  }
})

function SearchInput (props) {
  const {
    onChange,
    label,
    placeholder,
    value
  } = props
  const classes = useStyles(props)

  return (
    <TextField
      className={classes.root}
      variant='filled'
      label={label}
      placeholder={placeholder}
      onChange={evt => onChange(evt.target.value)}
      value={value}
      InputProps={{
        className: classes.input,
        endAdornment: value ? (
          <InputAdornment position='end'>
            <IconButton
              className={classes.iconButton}
              aria-label='Clear'
              onClick={() => onChange('')}
            >
              <Clear />
            </IconButton>
          </InputAdornment>
        ) : null
      }}
      InputLabelProps={{
        className: classes.inputLabel
      }}
    />
  )
}

SearchInput.propTypes = {
  label: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  margin: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  width: PropTypes.string
}

SearchInput.defaultProps = {
  label: 'Search',
  margin: '0 0 0 2.4rem',
  onChange: () => {},
  placeholder: '',
  value: '',
  width: '100%'
}

export default React.memo(SearchInput)
