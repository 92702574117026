import React from 'react'
import styled from '@emotion/styled'
import { indica1, mcGreen, mcRed } from 'src/helpers/css-variables'
import Table from 'src/microcomponents/table'
import { array, func, oneOfType, string } from 'prop-types'

const DepotOverview = ({ toggleDepotOverview, depotDriverOverview }) => {
  return (
    <div>
      <Table
        config={[
          {
            title: (<Title>Depot</Title>),
            key: 'name',
            formatter: v => <div>{v}</div>
          },
          {
            title: (<Title color={mcGreen}>Delivery</Title>),
            key: 'delivering'
          },
          {
            title: (<Title color={indica1}>Idle</Title>),
            key: 'idle'
          },
          {
            title: (<Title color={mcRed}>Hold</Title>),
            key: 'hold'
          },
          {
            title: (<Title color={mcRed}>Off</Title>),
            key: 'off'
          },
          {
            title: (<Title>Utilization</Title>),
            key: 'utilization',
            formatter: v => v + '%'
          }
        ]}
        data={depotDriverOverview}
        height='88vh'
        sortBy='utilization'
        sortAscending
        rowStyle={() => {}}
      />
    </div>
  )
}

DepotOverview.propTypes = {
  toggleDepotOverview: func,
  depotDriverOverview: oneOfType([array, string])
}

export default DepotOverview

const Title = styled.span`
  color: ${({ color }) => color};
  font-weight: 600;
  text-align: left;
  &::selection {
    background: transparent;
  }
`
