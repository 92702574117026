import { connect } from 'react-redux'
import React from 'react'
import { func, number, object, oneOfType, string } from 'prop-types'
import pole from 'pole'
import Visibility from 'document-visibility'
import window from 'global/window'
import qs from 'query-string'

import { idToInt } from 'helpers/sanitizers'
import { DASHBOARD_NAMES } from 'helpers/dashboard'
import Deliveries from 'components/deliveries'
import ROUTES from 'components/router/routes'

import {
  clearDeliveries,
  fetchFocusedDriverInventory,
  hydrateFocusedDeliveriesDriver,
  setDeliveryTypeFilter,
  setFocusedDeliveriesDriver,
  setModeFilter,
  toggleMapView
} from 'src/redux/deliveries/operations'
import {
  changeDriverMode,
  pruneStaleDrivers,
  setDriverDeliveryType
} from 'src/redux/driver-map/operations'
import { rerouteOrder } from 'src/redux/orders/actions'

import {
  toggleDepotOverview,
  saveMaps,
  setDepotFilter,
  setDepotFilterString,
  setDriverDetailsContent
} from 'src/redux/deliveries/actions'

import {
  getDeliveriesDepotOverview,
  getDeliveryType,
  getDeliveriesFilter,
  getDeliveriesMapView,
  getDeliveriesMaps,
  getDeliveriesMode,
  getDriverDetailsContent,
  getFocusedDriverId,
  getFocusedDriver,
  getFocusedDriverInventory,
  getFocusedDriverOrders,
  getIsDriverFocused,
  getDeliveryTypeCounts,
  getDriverStatusTotals,
  getDriverMapMarkers,
  getDriverUtilization,
  getDriverUtilizationOverview,
  getFilteredDepots,
  getFilteredDrivers,
  getIsFocusedDriverInventoryLoading,
  getIsFocusedDriverOrdersLoading
} from 'src/redux/deliveries/selectors'

import { getIsAdmin } from 'src/redux/permissions/selectors'
import {
  clearDispensariesStore,
  fetchDispensariesAndDepotDrivers
} from 'src/redux/dispensaries/actions'

import { getDeliveriesPollInterval } from 'src/redux/firebase/selectors'

import { getDriverCurrentVehicle } from 'src/redux/driver-vehicles/selectors'

const visibility = Visibility()

const mapStateToProps = (state, props) => {
  const params = qs.parse(window.location.search)
  const { path } = props.match
  const { id, tab } = props.match.params
  // add a boolean for conditionally rendering the <Screen /> component
  // we need to do this so the deliveries page can be rendered inside of
  // the dashboard view without having double navs and headers
  const isDeliveriesTab = tab === DASHBOARD_NAMES.deliveries.name
  const isDeliveriesRoute = path === ROUTES.DELIVERIES

  return {
    deliveryCounts: getDeliveryTypeCounts(state),
    deliveryType: getDeliveryType(state),
    depots: state.depots.list,
    driverCurrentVehicle: getDriverCurrentVehicle(state),
    driverDetailsContent: getDriverDetailsContent(state),
    driverPollInterval: getDeliveriesPollInterval(state),
    filteredDepots: getFilteredDepots(state),
    filteredDrivers: getFilteredDrivers(state),
    focusedDriverId: getFocusedDriverId(state),
    focusedDriverInventory: getFocusedDriverInventory(state),
    focusedDriverOrders: getFocusedDriverOrders(state),
    driverMarkers: getDriverMapMarkers(state),
    focusedDriver: getFocusedDriver(state),
    isAdmin: getIsAdmin(state),
    isDeliveriesRoute: isDeliveriesRoute,
    isDeliveriesTab: isDeliveriesTab,
    isDriverFocused: getIsDriverFocused(state),
    driverStatusTotals: getDriverStatusTotals(state),
    loadingDriver: state.loading.driver,
    loading: state.loading.drivers,
    isFocusedDriverInventoryLoading: getIsFocusedDriverInventoryLoading(state),
    isFocusedDriverOrdersLoading: getIsFocusedDriverOrdersLoading(state),
    filterList: getDeliveriesFilter(state),
    mapView: getDeliveriesMapView(state),
    driverInUrl: idToInt(params.driver),
    maps: getDeliveriesMaps(state),
    mode: getDeliveriesMode(state),
    utilization: getDriverUtilization(state),
    depotOverview: getDeliveriesDepotOverview(state),
    depotDriverOverview: getDriverUtilizationOverview(state),
    focusedDepotId: idToInt(id),
    userEmail: state.user.email // this is from shared-redux, at some point we need to create selectors for user
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    changeDriverMode: (value, driverId) =>
      dispatch(changeDriverMode(value, driverId)),
    clearDeliveries: () => dispatch(clearDeliveries()),
    clearDispensariesStore: () => dispatch(clearDispensariesStore()),
    fetchFocusedDriverInventory: driverId =>
      dispatch(fetchFocusedDriverInventory(driverId)),
    fetchDispensariesAndDepotDrivers: id =>
      dispatch(fetchDispensariesAndDepotDrivers(id)),
    hydrateFocusedDeliveriesDriver: focusedDriverId =>
      dispatch(hydrateFocusedDeliveriesDriver(focusedDriverId)),
    pruneStaleDrivers: focusedDriverId =>
      dispatch(pruneStaleDrivers(focusedDriverId)),
    rerouteOrder: (orderId, driverId) =>
      dispatch(rerouteOrder(orderId, driverId)),
    saveMaps: maps => dispatch(saveMaps(maps)),
    setDeliveryTypeFilter: deliveryType =>
      dispatch(setDeliveryTypeFilter(deliveryType)),
    setDepotFilter: filter => dispatch(setDepotFilter(filter)),
    setDepotFilterString: filterString =>
      dispatch(setDepotFilterString(filterString)),
    setDriverDeliveryType: (driverId, deliveryType) =>
      dispatch(setDriverDeliveryType(driverId, deliveryType)),
    setDriverDetailsContent: contentKey =>
      dispatch(setDriverDetailsContent(contentKey)),
    setFocusedDriver: driverId =>
      dispatch(setFocusedDeliveriesDriver(driverId)),
    setModeFilter: mode => dispatch(setModeFilter(mode)),
    toggleDepotOverview: () => dispatch(toggleDepotOverview()),
    toggleMapView: value => dispatch(toggleMapView(value))
  }
}

class DeliveryContainer extends React.PureComponent {
  static propTypes = {
    clearDeliveries: func,
    clearDispensariesStore: func,
    driverDetailsContent: string,
    driverInUrl: string,
    driverPollInterval: number,
    fetchDispensariesAndDepotDrivers: func,
    focusedDepotId: oneOfType([number, string]),
    focusedDriverId: oneOfType([number, string]),
    hydrateFocusedDeliveriesDriver: func,
    preloadedProps: object,
    pruneStaleDrivers: func,
    setFocusedDriver: func
  }

  constructor () {
    super()

    this.state = {
      driverPoll: null
    }

    // if the user is no longer focused on the eaze tab, let's cancel polling
    // until they return
    this.unlistenVisibility = visibility.onChange(visible => {
      console.info('Deliveries visibility change: ', visible)

      visible ? this.applyPoll() : this.revokePoll()
    })
  }

  applyPoll = () => {
    let { driverPoll } = this.state
    const {
      driverPollInterval,
      fetchDispensariesAndDepotDrivers,
      hydrateFocusedDeliveriesDriver,
      pruneStaleDrivers
    } = this.props

    // only continue if we have don't have any instances of driverPoll yet!!
    if (driverPoll !== null) return
    driverPoll = pole({ interval: driverPollInterval }, async callback => {
      // NOTE: pulling these values from props at this level so we always have the latest focused driver or depot ID
      const { focusedDepotId, focusedDriverId } = this.props

      await fetchDispensariesAndDepotDrivers(focusedDepotId)
      const prunedDriverDict = await pruneStaleDrivers(focusedDriverId)
      // if an active driver was pruned, un-focus that driver
      if (prunedDriverDict[focusedDriverId]) {
        this.resetFocusedDriver()
      } else if (focusedDriverId || focusedDriverId === 0) {
        // if we still have a focused driver after the prune, rehydrate orders/inventory
        // else needed here so we don't fetch inventory for a driver we just pruned
        hydrateFocusedDeliveriesDriver(focusedDriverId)
      }

      callback()
    })

    this.setState({ driverPoll })
  }

  updatePoll = interval => {
    // if we're already polling restart with a randomized delay
    console.log('UPDATING POLLING INTERVAL TO:', interval)
    const delay = Math.floor(Math.random() * interval)
    if (this.state.driverPoll) {
      setTimeout(() => {
        this.revokePoll()
        this.applyPoll()
      }, delay)
    }
  }

  revokePoll = () => {
    const { driverPoll } = this.state
    driverPoll && driverPoll.cancel()

    this.setState(() => {
      return {
        driverPoll: null
      }
    })
  }

  resetFocusedDriver () {
    const { setFocusedDriver } = this.props
    setFocusedDriver(undefined)
  }

  componentDidMount () {
    const { clearDispensariesStore, driverInUrl, setFocusedDriver } = this.props

    clearDispensariesStore()
    this.applyPoll()

    if (driverInUrl) {
      setFocusedDriver(driverInUrl)
    }
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps (nextProps) {
  /* eslint-enable camelcase */
    if (
      nextProps.driverInUrl &&
      nextProps.focusedDriverId !== this.props.focusedDriverId
    ) {
      this.props.setFocusedDriver(nextProps.focusedDriverId)
    }

    // trigger polling restart if new interval is different
    if (
      nextProps.driverPollInterval &&
      nextProps.driverPollInterval !== this.props.driverPollInterval
    ) {
      this.updatePoll(nextProps.driverPollInterval)
    }
  }

  componentWillUnmount () {
    const { clearDeliveries } = this.props

    clearDeliveries()
    this.revokePoll()
    this.unlistenVisibility()
    this.resetFocusedDriver()
  }

  render () {
    return <Deliveries {...this.props} />
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryContainer)
