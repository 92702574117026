import React, { PureComponent } from 'react'
import Button, { PRIMARY } from 'components/button'
import requestCallback from 'request-callback'
import { bool, func, string, object } from 'prop-types'

import Modifier from './modifier'
import Container from './container'
import Increment from './increment'
import Decrement from './decrement'
import QuantityDisplay from './quantity-display'
import PriceDisplay from './price-display'
import CartButtonContainer from './cart-button-container'
import QuantityContainer from './quantity-container'

export default class QuantityPicker extends PureComponent {
  static propTypes = {
    addItemToCart: func,
    allowAddToCart: bool,
    cartItem: object.isRequired,
    product: object.isRequired,
    modifyUserCart: bool,
    showCartButton: bool,
    hidePrice: bool,
    isSeo: bool,
    stripQty: bool,
    removeItemFromCart: func,
    setCurrentAction: func,
    shouldRequestQuote: bool,
    onAfterAdd: func,
    onAfterRemove: func,
    onAfterCommit: func,
    onCheckout: func,
    onOverMaxQuantityClick: func,
    ignoreMaxQuantity: bool,
    quantityFontSize: string
  }

  static defaultProps = {
    addItemToCart: () => {},
    modifyUserCart: true,
    showCartButton: false,
    allowAddToCart: true,
    hidePrice: false,
    stripQty: false,
    isSeo: false,
    removeItemFromCart: () => {},
    setCurrentAction: () => {},
    onAfterAdd: () => {},
    onAfterRemove: () => {},
    onAfterCommit: () => {},
    onOverMaxQuantityClick: () => {},
    ignoreMaxQuantity: false
  }

  lowestQuantity = 1
  initialQuantity = this.props.cartItem ? this.props.cartItem.quantity : this.lowestQuantity

  state = {
    quantity: this.initialQuantity,
    maxedOut: false,
    distanceFromZero: this.initialQuantity,
    isInCart: this.props.cartItem
  }

  UNSAFE_componentWillReceiveProps ({ cartItem, product }) {
    if (cartItem) {
      this.setState({ isInCart: cartItem, quantity: cartItem.quantity })
    } else {
      this.setState({ isInCart: false, quantity: this.lowestQuantity })
    }
  }

  findNextHigherQuantity () {
    const { quantity } = this.state
    return quantity + 1
  }

  findNextLowerQuantity () {
    const { quantity } = this.state
    if (quantity === 0) return 0

    return Math.max(1, quantity - 1)
  }

  increment = () => {
    if (this.props.isSeo && !this.props.allowAddToCart) return

    const { modifyUserCart, addItemToCart, ignoreMaxQuantity, product, onAfterAdd, shouldRequestQuote } = this.props
    const { id, price, maxQuantity } = product
    let { distanceFromZero, maxedOut, quantity } = this.state

    const atHighestQuantity = this.findNextHigherQuantity() === quantity

    if (atHighestQuantity || maxedOut || (quantity === maxQuantity && !ignoreMaxQuantity)) return

    distanceFromZero++

    const newQuantity = quantity === 0
      ? this.lowestQuantity
      : this.findNextHigherQuantity()

    this.setState({
      quantity: newQuantity,
      distanceFromZero
    })

    if (modifyUserCart) {
      // directly modify cart
      requestCallback(() => onAfterAdd({ product, quantity: newQuantity }))
      addItemToCart(id, newQuantity, price, shouldRequestQuote)
    }
  }

  decrement = () => {
    if (this.props.isSeo && !this.props.allowAddToCart) return

    const { modifyUserCart, removeItemFromCart, product, onAfterRemove, shouldRequestQuote } = this.props
    const { id, price } = product
    let { distanceFromZero, isInCart, quantity } = this.state

    distanceFromZero--
    const newQuantity = this.findNextLowerQuantity()

    // if we're at -1 and it's in our Cart. Let's set local state to 0 trigger a remove with commitChange
    // this will close the product drawer
    if ((distanceFromZero <= -1 || quantity === newQuantity) && isInCart) {
      return this.setState({
        distanceFromZero: 0,
        quantity: 0
      }, this.commitChange)
    }

    this.setState({
      quantity: newQuantity,
      maxedOut: false,
      // never let distanceFromZero go below -1
      distanceFromZero: distanceFromZero <= -1 ? 0 : distanceFromZero
    })

    if (modifyUserCart) {
      // directly modify the cart
      requestCallback(() => onAfterRemove({ product, quantity: newQuantity }))
      removeItemFromCart(id, newQuantity, price, shouldRequestQuote)
    }
  }

  shakeIt = () => {
    this.setState({
      maxedOut: true
    }, () => {
      // remove animation once it's completed so we can replay it if needed!
      setTimeout(() => this.setState({ maxedOut: false }), 500)
    })
  }

  // used to determine if the cart has divigered from our local state,
  // this will help us determind the checkout button text
  hasCartDiversion = () => {
    const { quantity } = this.state
    const { cartItem } = this.props

    return cartItem && cartItem.quantity !== quantity
  }

  commitChange = () => {
    const {
      allowAddToCart,
      product,
      addItemToCart,
      removeItemFromCart,
      onAfterAdd,
      onAfterRemove,
      shouldRequestQuote
    } = this.props

    const { id, price, prices } = product

    let { quantity } = this.state

    if (quantity === 0) {
      // actually remove from cart
      removeItemFromCart(id, 0, price, shouldRequestQuote)
      // get new min quantity
      const lowestQuantity = this.lowestQuantity
      // update local state

      requestCallback(() => onAfterRemove({ product, lowestQuantity }))
      this.setState({ isInCart: false, quantity: lowestQuantity })

      return this.props.onAfterCommit()
    }

    if (!allowAddToCart) return

    if (!quantity) {
      quantity = price ? 1 : prices[0].quantity
    }

    this.setState({ isInCart: true })

    requestCallback(() => onAfterAdd({ product, quantity }))

    addItemToCart(id, quantity, price)

    this.props.onAfterCommit()
  }

  getCartButtonTitle = () => {
    if (this.hasCartDiversion()) {
      return 'update cart'
    } else {
      return 'in cart'
    }
  }

  getCartButtonFn = () => {
    const { cartItem, onCheckout, allowAddToCart, setCurrentAction } = this.props

    if (!allowAddToCart && setCurrentAction) {
      return setCurrentAction('ADD_TO_CART')
    }

    if (cartItem && this.hasCartDiversion() === false) {
      return onCheckout()
    } else {
      return this.commitChange()
    }
  }

  getHasMaxQuantity = (quantity, maxQuantity) => {
    if (this.props.ignoreMaxQuantity) return false

    return (quantity === maxQuantity) || this.findNextHigherQuantity() === quantity
  }

  getHasMinQuantity = (quantity) => {
    return this.findNextLowerQuantity() === quantity
  }

  handleIncrement = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const { quantity } = this.state
    const { product, onOverMaxQuantityClick, ignoreMaxQuantity } = this.props
    const { maxQuantity } = product
    const hasMaxQuantity = this.getHasMaxQuantity(quantity, maxQuantity)

    if (hasMaxQuantity && !ignoreMaxQuantity) {
      this.shakeIt()
      requestCallback(() => onOverMaxQuantityClick({ product, quantity }))
    } else {
      this.increment()
    }
  }

  handleDecrement = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const { isInCart, quantity } = this.state
    const hasMinQuantity = this.getHasMinQuantity(quantity)

    // the minimum quantity that can be added to a cart
    // used to disable the '-' button, and shake the quantity
    // if '-' is clicked
    const notInCartLowestPosition = !isInCart && hasMinQuantity

    if (notInCartLowestPosition) {
      this.shakeIt()
    } else {
      this.decrement()
    }
  }

  render () {
    const { product, allowAddToCart, showCartButton, hidePrice, stripQty, quantityFontSize } = this.props

    const { price, maxQuantity } = product
    const { maxedOut, quantity, isInCart } = this.state

    const priceDisplay = quantity * price
    const quantityDisplay = stripQty ? quantity : `Qty: ${quantity}`

    const hasMaxQuantity = this.getHasMaxQuantity(quantity, maxQuantity)
    const hasMinQuantity = this.getHasMinQuantity(quantity)

    // the minimum quantity that can be added to a cart
    // used to disable the '-' button, and shake the quantity
    // if '-' is clicked
    const notInCartLowestPosition = !isInCart && hasMinQuantity

    return (
      <Container>
        <Modifier>
          <Decrement
            onClick={this.handleDecrement}
            disabled={quantity === 0 || notInCartLowestPosition || !allowAddToCart}
            trash={hasMinQuantity}
          />

          <QuantityContainer maxedOut={maxedOut}>
            {!hidePrice && <PriceDisplay display={priceDisplay} />}

            <QuantityDisplay display={quantityDisplay} quantityFontSize={quantityFontSize} />
          </QuantityContainer>

          <Increment
            onClick={this.handleIncrement}
            disabled={hasMaxQuantity || !allowAddToCart}
          />
        </Modifier>
        {
          showCartButton && (
            <CartButtonContainer>
              <Button
                type={PRIMARY}
                disabled={!allowAddToCart || !this.hasCartDiversion()}
                onClick={this.getCartButtonFn}
              >
                {this.getCartButtonTitle()}
              </Button>
            </CartButtonContainer>
          )
        }
      </Container>
    )
  }
}
