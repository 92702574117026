import React from 'react'

// will inherit size from parent container
// button dimensions defined in parent container
const PlusIcon = ({ width = '100%', height = '100%' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
  >
    <g
      transform='translate(1 1)'
      fillRule='nonzero'
      stroke='none'
      strokeWidth={1}
    >
      <circle
        stroke='#00AAE7'
        strokeWidth={2}
        fill='#FFF'
        cx={11}
        cy={11}
        r={11}
      />
      <path
        d='M12 12h4.1c.5 0 .9-.5.9-1s-.4-.9-.9-.9H12V5.9c0-.5-.5-.9-1-.9s-1 .4-1 .9v4.2H5.9c-.5 0-.9.4-.9.9s.4 1 .9 1H10v4.1c0 .5.4.9 1 .9s1-.4 1-.9V12z'
        fill='#00AAE7'
      />
    </g>
  </svg>
)

export default PlusIcon
