import React, { PureComponent } from 'react'
import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import { bool, node } from 'prop-types'

const shake = keyframes`
  from, to {
    transform: translateX(0);
  }
  20%, 60% {
    transform: translateX(0.5rem);
  }
  40%, 80% {
    transform: translateX(-0.5rem);
  }
`

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Shaker = styled(QuantityContainer)`
  animation: ${shake} 0.3s linear infinite;
`

export default class QuantityContainerComponent extends PureComponent {
  static propTypes = {
    children: node,
    maxedOut: bool
  }

  render () {
    const { children, maxedOut } = this.props

    return maxedOut
      ? <Shaker>{children}</Shaker>
      : <QuantityContainer>{children}</QuantityContainer>
  }
}
