const blue = {
  10: '#e8f8ff',
  20: '#d9f4ff',
  30: '#baeaff',
  40: '#5bc3f0',
  50: '#17aceb',
  60: '#008fcc',
  70: '#037aad',
  80: '#04658f',
  90: '#0c4f6b'
}

export default blue
