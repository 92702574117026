import React from 'react'
import { bool, func, string } from 'prop-types'
import styled from '@emotion/styled'
import { primaryDark } from 'helpers/css-variables'

export default class DepotOption extends React.PureComponent {
  static propTypes = {
    onClick: func,
    selected: bool,
    name: string
  }

  static defaultProps = {
    onClick: () => {},
    selected: false,
    name: ''
  }

  render () {
    const { onClick, selected, name } = this.props
    return (
      <DepotCell onClick={onClick}>
        <input type='checkbox' checked={selected} />
        <span>{name}</span>
      </DepotCell>
    )
  }
}

const DepotCell = styled.div`
  padding: .5rem;
  padding-left: 2.6rem;

  & input {
    display: inline;
    width: auto;
    margin-right: 1rem;
  }
  /* Zebra stripe the cells */
  &:nth-child(even){
    background: ${primaryDark};
  }
`
