import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { func, string } from 'prop-types'

export default class SoundIcon extends PureComponent {
  static propTypes = {
    fill: string,
    onClick: func,
    right: string,
    size: string
  }

  render () {
    const { fill, onClick, right, size } = this.props

    return (
      <Button right={right} onClick={onClick}>
        <svg height={size} width={size} viewBox='0 0 33 27'>
          <g
            transform='translate(-345.000000, -85.000000) translate(73.000000, 85.000000) translate(272.000000, 0.000000)'
            fill={fill || '#F05B4B'}
            stroke='none'
            strokeWidth={1}
            fillRule='evenodd'
          >
            <path
              d='M16.835.137a1.217 1.217 0 0 0-1.262.085L5.563 7.266H3.746A3.247 3.247 0 0 0 .5 10.514v5.968a3.247 3.247 0 0 0 3.246 3.247h1.817l10.018 7.048a1.217 1.217 0 0 0 1.919-.995V1.217a1.218 1.218 0 0 0-.665-1.08zm-1.777 23.295L6.91 17.699a1.217 1.217 0 0 0-.901-.405H3.746a.812.812 0 0 1-.812-.812v-5.968c0-.449.364-.812.812-.812H6.01c.344-.002.671-.15.9-.406l8.148-5.733v19.869z'
              fillRule='nonzero'
            />
            <path d='M20.84 21.646a1.118 1.118 0 0 0 1.633 0c1.938-2.032 3.027-4.787 3.027-7.66 0-2.874-1.089-5.63-3.027-7.66a1.118 1.118 0 0 0-1.605.029 1.254 1.254 0 0 0-.028 1.683c3.127 3.286 3.127 8.605 0 11.891-.218.227-.34.536-.34.858 0 .322.122.631.34.859z' />
            <path d='M25.861 1.34a1.23 1.23 0 0 0 0 1.717 14.857 14.857 0 0 1 4.249 10.421c0 3.909-1.528 7.657-4.249 10.42a1.24 1.24 0 0 0-.323 1.182c.108.424.43.758.846.875.415.118.86 0 1.168-.306 6.597-6.704 6.597-17.573 0-24.277a1.183 1.183 0 0 0-1.69-.032z' />
          </g>
        </svg>
      </Button>
    )
  }
}

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 1rem;
  margin-right:  ${({ right }) => right || '0'};
`
