import api from 'api'
import t from './actionTypes'

import { hydrateFocusedDriversDriver } from 'src/redux/drivers/operations.js'
import { pushNotification, SUCCESS, WARNING } from 'src/redux/alerts/actions'

export function requestUpdateDriverInventory (driverId) {
  return dispatch => {
    dispatch(pushNotification(`Updating inventory...`, WARNING))
    dispatch({
      type: t.REQUEST_UPDATE_DRIVER_INVENTORY,
      payload: driverId
    })
  }
}

export function receivedUpdateDriverInventory (driverId) {
  return {
    type: t.RECEIVED_UPDATE_DRIVER_INVENTORY,
    payload: driverId
  }
}

export function updateDriverInventory (driverId, inventory, inventoryChecksum) {
  return async (dispatch) => {
    dispatch(requestUpdateDriverInventory(driverId))
    const payload = {
      id: driverId,
      inventory,
      inventoryChecksum
    }
    const { err } = await api.updateDriverInventory(payload)

    if (err) {
      dispatch(hydrateFocusedDriversDriver(driverId))
      return
    }

    dispatch(receivedUpdateDriverInventory(driverId))
    dispatch(pushNotification(`Driver ${driverId} inventory updated`, SUCCESS))

    dispatch(hydrateFocusedDriversDriver(driverId))
  }
}
