import React, { PureComponent } from 'react'
import { array, func, number, string } from 'prop-types'
import List from './list-component'
import McCard from 'src/components/mc-card'
import { FORM_STRING_TYPES } from 'src/helpers/constants'
import formatQuery from 'src/helpers/query-parameter'
import { grid } from '../style.js'
import Create from './create'
import ROUTES from 'src/pages/catalog/routes'

const { SUBTYPE } = FORM_STRING_TYPES

export default class SubtypeList extends PureComponent {
  static propTypes = {
    create: func,
    fetchCatalogFn: func,
    haveFetchedTypes: number,
    pathname: string,
    subtypesSorted: array
  }

  componentDidMount () {
    // the create/edit subtype overlay needs the catalog types for dropdowns
    if (!this.props.haveFetchedTypes) {
      this.props.fetchCatalogFn(ROUTES.types.name)
    }
  }

  render () {
    const { create, pathname, subtypesSorted } = this.props

    return (
      <List listClass={grid}>
        <Create
          action={create}
          title='add new subtype'
        />
        {
          subtypesSorted.map((subtype) => {
            const { name = '', id } = subtype
            const search = formatQuery(SUBTYPE, id)

            return (
              <McCard
                key={subtype.id}
                url={`${pathname}?${search}`}
                description={name}
              />
            )
          })
        }
      </List>
    )
  }
}
