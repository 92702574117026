import React, { PureComponent } from 'react'
import { bool, func, object } from 'prop-types'
import isEmptyObject from 'is-empty-object'
import Button, { DANGER } from 'src/components/button'
import Tooltip from 'src/components/tooltip'
import styled from '@emotion/styled'

import QuantityPicker from 'src/components/quantity-picker'
import HorizontalProductCard from 'src/components/horizontal-product-card'
import { formatCartItem } from 'src/redux/catalog-builder/format'
import LoadingSpinner from 'src/microcomponents/loading-spinner'
import CasePopupMenu from 'src/components/case-popup-menu'
import sortOn from 'sort-on'

import { mcRed } from 'src/helpers/css-variables'

class CatalogBucket extends PureComponent {
  static propTypes = {
    catalogBucket: object.isRequired,
    catalogErrors: object,
    isCaseBuilder: bool,
    onAfterAdd: func.isRequired,
    onAfterRemove: func.isRequired,
    outOfDepotItems: object,
    products: object.isRequired,
    removeOutOfDepotItem: func.isRequired
  }

  removeInvalidCatalogItem = (catalogItemId) => {
    // there isn't a product to remove as its invalid, pass this to catalogBuilder with no quantity so its removed
    this.props.onAfterRemove({ product: { catalogItemId } })
    this.props.removeOutOfDepotItem(catalogItemId)
  }

  render () {
    const {
      catalogBucket,
      catalogErrors,
      isCaseBuilder,
      onAfterAdd,
      onAfterRemove,
      outOfDepotItems,
      products
    } = this.props
    const catalogBucketIds = Object.keys(catalogBucket)
    const hasProducts = !isEmptyObject(products)

    let validProducts = []
    if (hasProducts) {
      catalogBucketIds.forEach(id => {
        const product = products[id]
        if (catalogErrors[id]) return

        if (product) {
          validProducts.push(products[id])
        }
      })

      validProducts = sortOn(validProducts, ['type.name', 'brand.name', 'name', 'subtype.name'])
    }

    const errorCards = outOfDepotItems.map((catalogItem) => {
      const { id } = catalogItem

      return (
        <CartProductRow key={`cart-list-product-row-${id}`}>
          <ErrorProductCardContainer>
            <HorizontalProductCard product={catalogItem} invalid />
          </ErrorProductCardContainer>

          <ErrorActionContainer>
            <Tooltip
              position='bottom'
              contents={<CasePopupMenu catalogItem={catalogItem} removeAction={() => this.removeInvalidCatalogItem(id)} />}
              tooltipStyle={{ backgroundColor: 'white' }}
              containerStyle={{ width: '15rem', height: 'auto' }}
              hoverEvents={false}
              hideOnInnerClick
            >
              <StyledButton type={DANGER}>
                FIX ME
              </StyledButton>
            </Tooltip>

            <QuantityPickerContainer>
              <div>quantity: {catalogBucket[id]}</div>
              <ErrorDiv>error: {catalogErrors[id]}</ErrorDiv>
            </QuantityPickerContainer>
          </ErrorActionContainer>
        </CartProductRow>
      )
    })

    const productCards = validProducts.map((product) => {
      return (
        <CartProductRow key={`cart-list-product-row-${product.id}`}>
          <ProductCardContainer>
            <HorizontalProductCard product={product} />
          </ProductCardContainer>
          <QuantityPickerContainer>
            <QuantityPicker
              product={product}
              cartItem={formatCartItem(product.catalogItemId, catalogBucket, products)}
              cart={{}}
              allowAddToCart
              hidePrice
              stripQty
              addItemToCart={() => {}}
              removeItemFromCart={() => {}}
              onAfterAdd={onAfterAdd}
              onAfterRemove={onAfterRemove}
              ignoreMaxQuantity={isCaseBuilder}
              quantityFontSize='1.6rem'
            />
          </QuantityPickerContainer>
        </CartProductRow>
      )
    })

    return (
      <Container>
        {!catalogBucketIds.length && <EmptyCart>This cart is empty.</EmptyCart>}
        {hasProducts && errorCards}
        {hasProducts && productCards}
        {!hasProducts && <LoadingSpinner show size={4} />}
      </Container>
    )
  }
}

export default CatalogBucket

const Container = styled.div`
  height: 100%;
  padding: 0 2rem;

  @media (min-width: 1200px) {
    padding: 0 5rem;
  }
`

const EmptyCart = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`

const CartProductRow = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: center;
`

const ProductCardContainer = styled.div`
  flex: 1 1 auto;
`

const ErrorProductCardContainer = styled.div`
  flex: 1;
`

const ErrorActionContainer = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid ${mcRed};
  display: flex;
  flex-direction: row;
  height: 9rem;
  margin-left: 2rem;
  padding: 1rem;
  width: 60%;
`

const QuantityPickerContainer = styled.div`
  flex: 0 0 11rem;
  margin-left: 2rem;
`

const ErrorDiv = styled.div`
  color: ${mcRed};
  font-size: 14px;
`

const StyledButton = styled(Button)`
  font-family: inherit;
`
