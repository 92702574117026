import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import window from 'global/window'

import { openNavSlider } from 'src/redux/navigation/actions'
import { areWeLoading } from 'src/redux/loading/selectors'
import { isLoggedIn } from 'src/redux/authentication/selectors'

import Navbar from './'

// Props passed to the component
const mapStateToProps = (state, props) => {
  // find a better way to do this...
  const route = window.location.pathname
  const { id = '' } = props.match.params
  const selectedDepot = state.depotsMap[id] || {}

  return {
    email: state.user.email,
    loading: areWeLoading(state),
    title: route.split('/')[1],
    isLoggedIn: isLoggedIn(state),
    selectedDepot
  }
}

// Methods passed to the components
const mapDispatchToProps = (dispatch, props) => {
  return {
    openNavSlider: () => dispatch(openNavSlider())
  }
}

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar)

export default withRouter(reduxContainer)
