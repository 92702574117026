import React, { PureComponent } from 'react'
import { func, bool, object, array } from 'prop-types'
import { unflatten } from 'flat'
import { Row, Column, Flex } from 'src/microcomponents/flexbox-helpers'

import Button, { HYBRID, DANGER, TRANSPARENT } from 'components/button'
import Divider from 'microcomponents/divider'
import { formatDateTimeWithZone } from 'src/helpers/date'
import Label from 'microcomponents/label'
import cloneImg from 'src/assets/clone.svg'
import { getPromoDefinition } from 'helpers/promos/definitions.js'
import CopyText from 'src/microcomponents/icons/icon-copy-text'

import { ORDER_HISTORY, USER_SEGMENTS, getUserSegmentType } from 'helpers/promos/user-segmentation'
import { PROMOS_IANA_TIME_ZONE } from 'src/helpers/promos/definitions'

import {
  confirmation,
  copyPromoId,
  depotList,
  thinker
} from './style.js'

const USER_SEGMENT_LABELS = {
  [USER_SEGMENTS.allUsers]: 'All users',
  [USER_SEGMENTS.custom]: 'Custom',
  [USER_SEGMENTS.orderHistory]: {
    [ORDER_HISTORY.newUsers]: 'Users who haven\'t ordered',
    [ORDER_HISTORY.existingUsers]: 'Users with 1+ order'
  }
}

export class PromoReceipt extends PureComponent {
  static propTypes = {
    showPromoPreview: func,
    createPromo: func,
    editPromo: func,
    promoEditing: bool,
    promoValidity: object,
    cloneable: bool,
    clonePromo: func,
    managePromos: object,
    depots: array,
    dispensaries: array
  }

  getUserSegmentationText (promo) {
    const userSegmentType = getUserSegmentType(promo)
    const labels = USER_SEGMENT_LABELS
    let text = labels[USER_SEGMENTS.allUsers]

    if (labels[userSegmentType]) {
      text = labels[userSegmentType]
      if (userSegmentType === USER_SEGMENTS.custom) {
        text = text + this.getPromoUserIdsLengthText(promo)
      }
    }

    if (userSegmentType === USER_SEGMENTS.orderHistory) {
      const orderHistory = promo.rules.order_history
      text = labels[USER_SEGMENTS.orderHistory][orderHistory] || 'All users'
    }

    return text || null
  }

  getPromoUserIdsLengthText = (promo) => {
    // eslint-disable-next-line camelcase
    const { user_cohort = {} } = promo.rules
    const userIds = user_cohort.user_ids
    if (userIds && userIds.length) {
      return ` - ${userIds.length} users`
    } else {
      return '- 0 users'
    }
  }

  getAmountWithType (amount, type) {
    if (type === '%') {
      return `${amount}%`
    } else if (type === '$') {
      return `$${amount}`
    }
  }

  getPromoDefinitionName (managePromos) {
    const def = getPromoDefinition(managePromos)
    return def ? def.name : null
  }

  getDepotNamesFromIds (depotIds) {
    if (!depotIds || !depotIds.length) return
    const { depots } = this.props
    const depotNamesList = []
    depots.forEach((depot) => depotIds.indexOf(depot.id) > -1 && depotNamesList.push(depot.name))
    return (
      <ul className={depotList}>
        {depotNamesList.map((name) => (<li key={name}>{name}</li>))}
      </ul>
    )
  }

  getDispensaryName (dispensaryId) {
    if (!dispensaryId) return
    const { dispensaries } = this.props
    const dispName = dispensaries.filter(d => d.id === parseInt(dispensaryId))
    return dispName.length ? dispName[0].name : ''
  }

  getDepotOrDispensaryDisplay = (type, userCohort = {}) => {
    const hasDispensaryData = userCohort.dispensary_id
    const hasDepotData = userCohort.depot_ids && userCohort.depot_ids.length

    if (type === 'depot') {
      if (hasDispensaryData) return 'N/A'

      return hasDepotData
        ? this.getDepotNamesFromIds(userCohort.depot_ids)
        : 'Any'
    } else if (type === 'dispensary') {
      if (hasDepotData) return 'N/A'

      return hasDispensaryData
        ? this.getDispensaryName(userCohort.dispensary_id)
        : 'Any'
    }
  }

  handleCopyPromoIdToClipboard = (promoId) => {
    window.navigator && window.navigator.clipboard.writeText(promoId)
  }

  render () {
    const { showPromoPreview, createPromo, editPromo, promoEditing, promoValidity, cloneable, clonePromo } = this.props
    const managePromos = unflatten(this.props.managePromos)
    // eslint-disable-next-line camelcase
    const { user_cohort = {} } = managePromos.rules

    const cloneButton = (
      <Row align='center' bottom={3}>
        <Flex full><h1 style={{ textTransform: 'uppercase' }}>{managePromos.code}</h1></Flex>
        <div style={{ maxWidth: '10rem' }}>
          <Button onClick={clonePromo} type={TRANSPARENT}>
            <img src={cloneImg} />
          </Button>
        </div>
      </Row>
    )

    return (
      <Column top={2} left={1} right={1}>
        {
          cloneable === false || promoEditing
            ? (<Column className={confirmation} align='center'>
                <Flex>Does this look alright?</Flex>
                <Flex className={thinker}>🤔</Flex>
              </Column>)
            : (cloneButton)
        }

        {
          cloneable === false
            ? (
              <Column>
                <Row>
                  <Flex full><Label content='Code' /></Flex>
                  <div style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>{managePromos.code}</div>
                </Row>
                <Divider />
              </Column>
              )
            : null
        }
        {managePromos.id && (
          <div>
            <Row align='center'>
              <Flex full right={1}><Label content='ID' />
                <button className={copyPromoId} onClick={() => this.handleCopyPromoIdToClipboard(managePromos.id)}>
                  <CopyText title='Copy Id' size='1.2rem' />
                </button>
              </Flex>
              <div>{managePromos.id}</div>
            </Row>
            <Divider />
          </div>
        )}

        <Row align='center'>
          <Flex full right={1}><Label content='Description' /></Flex>
          <div>{managePromos.description}</div>
        </Row>
        <Divider />

        <Row align='center'>
          <Flex full><Label content='Start' /></Flex>
          <div>{formatDateTimeWithZone(managePromos.valid_at, PROMOS_IANA_TIME_ZONE)}</div>
        </Row>
        <Divider />

        <Row align='center'>
          <Flex full><Label content='End' /></Flex>
          <div>{formatDateTimeWithZone(managePromos.expires_at, PROMOS_IANA_TIME_ZONE)}</div>
        </Row>
        <Divider />

        <Row align='top'>
          <Flex full><Label content='State' /></Flex>
          <div>{managePromos.rules.state_id}</div>
        </Row>
        <Divider />

        <Row align='center'>
          <Flex full><Label content='Promotion type' /></Flex>
          <div>{this.getPromoDefinitionName(managePromos)}</div>
        </Row>
        <Divider />

        {
          managePromos.rules.promo_type === 'bogo'
            ? (
              <div>
                <Row align='center'>
                  <Flex full><Label content='Buy' /></Flex>
                  <div>{`${managePromos.catalogNameBuy} (${managePromos.rules.operators.buy.quantity})`}</div>
                </Row>
                <Divider />

                <Row align='center'>
                  <Flex full><Label content='Get' /></Flex>
                  <div>{`${managePromos.catalogNameGet} (${managePromos.rules.operators.get.quantity})`}</div>
                </Row>
                <Divider />
              </div>
              )
            : null
        }

        {
          managePromos.rules.applies_to === 'id'
            ? (
              <div>
                <Row align='center'>
                  <Flex full><Label content='Criteria' /></Flex>
                  <div>{managePromos.rules.operators.id_type}</div>
                </Row>
                <Divider />

                <Row align='center'>
                  <Flex full><Label content='Criteria Value' /></Flex>
                  <div>{managePromos.catalogName}</div>
                </Row>
                <Divider />
              </div>
              )
            : <div />
        }

        <Row align='center'>
          <Flex full><Label content='Amount' /></Flex>
          <div>
            {
              this.getAmountWithType(
                managePromos.rules.operators.credit_amount,
                managePromos.rules.operators.credit_type
              )
            }
          </div>
        </Row>
        <Divider />

        <Row align='center'>
          <Flex full><Label content='Minimum Order Value' /></Flex>
          <div>${managePromos.rules.min_order_amount || managePromos.rules.operators.min_order_amount || 0}</div>
        </Row>
        <Divider />

        <Row align='center'>
          <Flex full><Label content='Credit Max' /></Flex>
          <div>${managePromos.rules.operators.credit_max}</div>
        </Row>
        <Divider />

        <Row align='center'>
          <Flex full><Label content='Global use limit' /></Flex>
          <div>{managePromos.rules.global_limit === 0 ? 'Unlimited' : managePromos.rules.global_limit}</div>
        </Row>
        <Divider />

        <Row align='center'>
          <Flex full><Label content='User use limit' /></Flex>
          <div>{managePromos.rules.user_limit === 0 ? 'Unlimited' : managePromos.rules.user_limit}</div>
        </Row>
        <Divider />

        <Row align='center'>
          <Flex full><Label content='User Segmentation' /></Flex>
          <div>
            {this.getUserSegmentationText(managePromos)}
          </div>
        </Row>
        <Divider />

        <Row align='top'>
          <Flex full><Label content='Depots' /></Flex>
          <div>{this.getDepotOrDispensaryDisplay('depot', user_cohort)}</div>
        </Row>
        <Divider />

        <Row align='center'>
          <Flex full><Label content='Dispensary' /></Flex>
          <div>{this.getDepotOrDispensaryDisplay('dispensary', user_cohort)}</div>
        </Row>
        <Divider />

        <Row align='center'>
          <Flex full><Label content='Marketing channel' /></Flex>
          <div>{managePromos.meta.marketing_channel}</div>
        </Row>
        <Divider />

        {
          cloneable === false || promoEditing
            ? (
              <Row>
                <Flex right={1} full>
                  <Button onClick={() => showPromoPreview(false)} type={DANGER}>go back and edit</Button>
                </Flex>
                <Flex full>
                  <Button
                    disabled={!promoValidity.status}
                    onClick={createPromo}
                    type={HYBRID}
                  >
                    Save Promo
                  </Button>
                </Flex>
              </Row>
              )
            : (
              <Column>
                <Button type={DANGER} onClick={editPromo}>Edit Promo</Button>
              </Column>
              )
        }
      </Column>
    )
  }
}

export default PromoReceipt
