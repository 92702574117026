import api from 'api'
import { parse } from 'csv-string'
import t from './actionTypes.js'
import { fetchOutOfDepotCatalogItems, setCatalogBucket, setCatalogBucketId } from 'src/redux/catalog-builder/actions'
import { pushNotification, SUCCESS } from 'src/redux/alerts/actions'
import { fetchSpecificDepotDrivers } from 'src/redux/dispensaries/actions'

function transformCaseTemplateResponse (caseResponse) {
  const catalogCase = {}
  caseResponse.items.forEach(catalogItem => {
    catalogCase[catalogItem.id] = catalogItem.quantity
  })
  return catalogCase
}

export function createCatalogCase () {
  return (dispatch) => {
    dispatch(setCatalogBucketId('1'))
  }
}

export function getCasesByDepot (depotId) {
  return async (dispatch, getState) => {
    const {err, data} = await api.getCasesByDepot({ id: depotId })
    if (err) return
    dispatch(setCases(data))
  }
}

export function getArchivedCasesByDepot (depotId) {
  return async (dispatch, getState) => {
    const {err, data} = await api.getArchivedCasesByDepot({ id: depotId, deleted: true })
    if (err) return
    dispatch(setArchivedCases(data))
  }
}

export function setCatalogCaseInfo ({color, name, depot_id: depotId, description}) {
  // note: this affects the catalog-builder reducer, not the case
  return {
    type: t.SET_CATALOG_CASE_INFO,
    payload: {
      color,
      depotId,
      description,
      name
    }
  }
}

export function getCaseById (caseId) {
  return async (dispatch) => {
    const {err, data} = await api.getCaseById({ id: caseId })
    if (err) return

    dispatch(setCatalogBucketId(data.id))

    const catalogBucket = transformCaseTemplateResponse(data)

    dispatch(setCatalogBucket(catalogBucket))
    dispatch(setCatalogCaseInfo(data))

    if (data.errors) {
      dispatch(fetchOutOfDepotCatalogItems(data.errors))

      data.errors.forEach(({catalogItemId, status, productIdOptions}) => {
        // sometimes BE messes up and sends down productIdOptions as null instead of an empty array
        if (!productIdOptions) productIdOptions = []
        console.warn(catalogItemId, status, ...productIdOptions)
      })
    }
  }
}

export function casesAddedFromCsv (cases) {
  return (dispatch) => {
    dispatch({
      type: t.CASES_ADDED_FROM_CSV,
      payload: cases
    })

    dispatch(pushNotification('Successfully uploaded your csv', SUCCESS))

    if (cases.length) {
      dispatch(getCasesByDepot(cases[0].depot_id))
    }
  }
}

export function cloneCase (caseId) {
  return async (dispatch) => {
    const {err, data} = await api.cloneCase({ id: caseId })
    if (err) return
    dispatch(pushNotification('Successfully cloned case template', SUCCESS))
    dispatch({
      type: t.CASE_CLONED,
      payload: data
    })
  }
}

export function updateCatalogCase (caseTemplate) {
  return (dispatch) => {
    dispatch(setCatalogBucket(caseTemplate))
  }
}

export function updateCase (caseTemplate) {
  return async (dispatch) => {
    const {err, data} = await api.updateCase(caseTemplate)
    if (err) return
    const catalogBucket = transformCaseTemplateResponse(data)
    dispatch(setCatalogBucket(catalogBucket))
    dispatch(setCatalogCaseInfo(data))
    dispatch(pushNotification(`Successfully updated ${data.name}`, SUCCESS))
  }
}

export function deleteCase (caseId) {
  return async (dispatch) => {
    const {err} = await api.deleteCase({ id: caseId })
    if (err) return

    dispatch(pushNotification('Successfully archived case template', SUCCESS))
    dispatch({
      type: t.DELETE_CASE,
      payload: caseId
    })
  }
}

export function saveCase (caseTemplate) {
  return async (dispatch) => {
    const {err, data} = await api.createCase(caseTemplate)
    if (err) return

    dispatch(pushNotification(`Successfully created ${data.name}`, SUCCESS))
  }
}

export function assignCaseTemplateToDrivers (caseTemplateId, caseTemplateName, drivers, depotId) {
  return async (dispatch) => {
    if (caseTemplateId === null) return dispatch(pushNotification('Please select a case template'))
    if (drivers.length === 0) return dispatch(pushNotification('Please select drivers'))
    const {err} = await api.assignCaseTemplateToDrivers({ id: caseTemplateId, array: drivers })
    if (err) return
    dispatch(pushNotification(`Successfully assigned ${caseTemplateName} to ${drivers.length} drivers`, SUCCESS))
    dispatch(fetchSpecificDepotDrivers(depotId)) // call fetch drivers to update driver list and re-render component
  }
}

export function setCaseUnassigned (drivers, depotId) {
  return async (dispatch) => {
    const {err} = await api.clearDriverInventory({ userIds: String(drivers) }) // String(drivers) because drivers need to be a comma separated list
    if (err) return
    dispatch(pushNotification('Successfully cleared driver inventory', SUCCESS))
    dispatch(fetchSpecificDepotDrivers(depotId)) // call fetch drivers to update driver list and re-render component
  }
}

export function setCases (cases) {
  return {
    type: t.SET_CASES,
    cases
  }
}

export function setArchivedCases (archivedCases) {
  return {
    type: t.SET_ARCHIVED_CASES,
    archivedCases
  }
}

export function exportCSV (caseTemplateId, depotName = 'depot') {
  return async (dispatch) => {
    const {err, data} = await api.exportCSV({ caseTemplateId })
    if (err) return
    const arr = parse(data)
    const csvContentPrefix = 'data:text/csv;charset=utf-8,'
    let csvContent = ''
    arr.forEach(function (rowArray) {
      let row = rowArray.join(',')
      csvContent += row + '\n'
    })
    const encodedUri = csvContentPrefix + encodeURIComponent(csvContent)
    // need download attr in order to customize file name
    var link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', `${depotName}.csv`)
    document.body.appendChild(link) // Required for FF
    link.click()
    document.body.removeChild(link)
  }
}
