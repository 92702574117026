import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { bool, func, node, string } from 'prop-types'

const Card = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  background-color: white;
  outline: none;

  border: 0;
  padding: 0;
  text-align: left;

  @media(max-width: 767px) {
    flex-shrink: 0;
  }

  z-index: ${({ focused }) => focused ? 8 : 2};
  border-color: ${({ focused }) => focused ? 'white' : 'none'};
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 ${({ focused, isMobile }) => focused && !isMobile ? 4 : 0}rem;
  transition: box-shadow 0.25s linear;
`

export default class CardComponent extends PureComponent {
  static propTypes = {
    onClick: func,
    isMobile: bool,
    focused: bool,
    children: node,
    label: string,
    onMouseEnter: func,
    onMouseLeave: func
  }

  render () {
    const {
      onClick,
      isMobile,
      focused,
      children,
      label,
      onMouseEnter,
      onMouseLeave
    } = this.props

    return (
      <Card
        isMobile={isMobile}
        focused={focused}
        role='button'
        aria-label={label}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      >
        {children}
      </Card>
    )
  }
}
