import { css } from 'emotion'
import {
  blazeUpBlack,
  borderContainerLight,
  darkDivider,
  mcBlue,
  mcGreen,
  mcRed,
  mcYellow,
  white
} from 'helpers/css-variables'

export const main = css`
  flex: 1;
  height: 100%;
`

export const hide = css`
  visibility: hidden;
`

export const item = css`
  align-items: center;
  display: flex;
  padding: 1rem 2rem;
  background-color: #fff;
  user-select: none;
  color: #333;
  font-weight: 400;
  font-size: 1.6rem;
  margin: 1rem auto;
  border-radius: 0.4rem;
  cursor: grab;
  box-shadow: 0 0.2rem 1rem rgba(0,0,0,0.05);
  width: 100%;
`

export const leftBar = css`
  width: 10rem;
  padding: 1rem;
`

export const centerContainer = css`
  width: 75rem;
  margin: 0 auto;
  min-height: max-content;
`

export const content = css`
  width: 100%;
  background: #f5f4ef;
  position: relative;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  box-shadow: 0 0 3rem rgba(0,0,0,.5);
`

export const topInfo = css`
  color: white;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;

  input, textarea {
    border: 0.2rem solid transparent;
  }

  input:hover, textarea:hover {
    border: 0.2rem dashed ${white};
  }
`

export const title = css`
  font-weight: bold;
  font-size: 2.4rem;
  background-color: transparent;
  letter-spacing: 0.03em;
  flex: 0.75 1 0%;

  &::-webkit-input-placeholder {
    color: rgba(255,255,255,0.8);
  }
`

export const menuSlugInput = css`
  flex: 0.6 1 0%;
  display: flex;
  align-items: baseline;
  margin-left: 0.5rem;
`

export const inputDisabled = css`
  font-size: 1.6rem;
  background-color: transparent;
  letter-spacing: 0.03em;

  &::-webkit-input-placeholder {
    color: rgba(255,255,255,0.8);
  }

  &:hover {
    border: 0.2rem solid transparent;
  }
`

export const descriptionClass = css`
  font-size: 1.6rem;
  background-color: transparent;
  letter-spacing: 0.03em;

  &::-webkit-input-placeholder {
    color: rgba(255,255,255,0.8);
  }
`

export const topGroupInfo = css`
  ${topInfo};
  background-color: ${mcBlue};
`

export const topBrandInfo = css`
  ${topInfo};
  background-color: ${mcRed};
`

export const topMenuInfo = css`
  ${topInfo};
  background-color: ${mcYellow};
  min-height: 20rem;
`

export const overflowContainer = css`
  margin: 0 auto;
  width: 100%;
  height: 100%;
`

export const groupOverflowContainer = css`
  ${overflowContainer};
  padding-top: 1rem;
  padding-bottom: 1rem;
`

export const groupList = css`
  padding: 0;
  width: 33rem;
  height: 100%;
  margin: 0 auto;
  display: block;
  overflow: auto;
`

export const rightBarForm = css`
  padding: 0;
  width: 33rem;
  margin: 0 auto;
  display: block;
  padding-bottom: 1rem;
  border-bottom: 0.1rem solid ${darkDivider};
`

export const logo = css`
  height: 4rem;
  border-radius: 0.4rem;
  box-shadow: 0 0.5rem 3rem rgba(0,0,0,0.5);
`

export const infoContainer = css`
  position: relative;
  overflow: hidden;
`

export const desktopImageRow = css`
  display: flex;
`

export const iconImage = css`
  height: 5rem;
  width: 5rem;
  margin-left: 1rem;
  border-radius: .5rem;
`

export const headerImage = css`
  height: 100%;
  width: 100%;
  max-width: 100%;
  opacity: 0.25;
  position: absolute;
  z-index: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

export const reveal = css`
  height: 100%;
`

export const uploadBttn = css`
  border: 0.1rem solid #C3CDD6;
  border-radius: 0.4rem;
  min-width: 15rem;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 1rem;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1.4rem;
  letter-spacing: 0.06em;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`

export const phoneContent = css`
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 6.8rem;
  padding-left: 2.5rem;
  padding-right: 2.8rem;
  overflow: hidden;
`

export const phoneDescription = css`
  position: absolute;
  padding: inherit;
  top: 10rem;
  left: 0;
  right: 0;
  color: black;
`

export const mobileImg = css`
  max-width: 100%;
  height: 100%;
  z-index: 2;
`

export const id = css`
  margin-bottom: 1rem;
`

export const menuInfoSection = css`
  ${topInfo};
  background: #ECE9E3;
  height: 20rem;
  position: relative;
`

export const menuColorClass = css`
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 12.5rem;
  height: 5rem;
`

export const uploadButtons = css`
  position: absolute;
  right: 1rem;
  top: 1rem;
  display: flex;
`

export const uploadButton = css`
  width: 12rem;
  height: 5rem;
  background: white;
  color: ${mcGreen};
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 0.5rem
`

export const groupMenuColor = css`
  top: 1rem;
  left: 5rem;
  width: 15rem;
  height: 5rem;
  margin-left: 2rem
`

export const groupMenuColorFont = css`
  font-size: 1.6rem;
  font-family: 'Fira Sans';
  margin-left: 1rem;
`

export const uploadButtonMobile = css`
  ${uploadButton};
  margin-left: 1rem;
`

export const toggleContainer = css`
  padding: 2rem;
  color: black;
  overflow-y: auto;
`

export const depotsListHeader = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const depotsListButtonWrapper = css`
  display: flex;
`

export const enableAllDepotsButton = css`
  margin-right: 1rem;
`

export const disableAllDepotsButton = css`
  background-color: transparent;
`

export const itemToggle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-top: 1px solid ${borderContainerLight};
`

export const switchWrapper = css`
  display: inline-block;
`

export const switchText = css`
  display: inline-block;
`

export const groupSettingsContainer = css`
  padding: 2rem;
  color: black;
  overflow-y: auto;
`

export const groupSettingsImages = css`
  & > div {
    display: inline-block;
    margin-right: 2rem;
  }
`

export const inlineImageBlock = css`
  position: relative;
  display: inline-block;
  width: 14rem;
  height: auto;
`

export const cancelImageButton = css`
  position: absolute;
  cursor: pointer;
  border: none;
  padding: 0;
  top: -1rem;
  right: 1rem;
  height: 2.5rem;
  width: 2.5rem;
  font-size: 2rem;
  border-radius: 100%;
  background-color: ${blazeUpBlack};
  color: ${white};
  text-align: center;
`

export const mobilePreviewButton = css`
  width: 50%;

  label {
    margin-right: 1rem;
  }

  button {
    bottom: 2px;
  }
`

export const switchFormContainer = css`
  text-align: right;
  direction: rtl;

  button {
    bottom: 2px;
  }
`
