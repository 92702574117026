import React from 'react'
import { bool, func, object, string } from 'prop-types'
import styled from '@emotion/styled'

import {
  darkDivider,
  fontSizeParagraph,
  letterSpacingLabel,
  primaryDark,
  secondaryDark
} from 'src/helpers/css-variables'
import LoadingSpinner from 'microcomponents/loading-spinner/'

const NavBar = ({
  email,
  isLoggedIn,
  loading,
  match,
  openNavSlider,
  selectedDepot,
  title
}) => (
  <Nav>
    {isLoggedIn
      ? (<>
        <NavGroup>
          <Cheeseburger onClick={openNavSlider}>&#9776;</Cheeseburger>
          <CurrPage>
            {/* prevent flash of title when on depot pages - need to wait for selectedDepot, use tab bc more discernable than params.id */}
            {match.params && match.params.tab
              ? (selectedDepot.name && <DepotName>{selectedDepot.name}</DepotName>)
              : (<NavCrumb>
                {match.params && match.params.view
                  ? match.params.view
                  : title.replace(/-/, ' ')}
              </NavCrumb>)}
          </CurrPage>
        </NavGroup>
        {loading && (
          <SmallSpinner>
            <LoadingSpinner show />
          </SmallSpinner>
        )}
        <Email>{email}</Email>
      </>)
      : (<div />)}
  </Nav>
)

NavBar.propTypes = {
  email: string,
  isLoggedIn: bool,
  loading: bool,
  match: object,
  openNavSlider: func,
  selectedDepot: object,
  title: string
}

const Nav = styled.nav`
  max-width: 100%;
  height: 5rem;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  box-shadow: 0 4px 8px ${primaryDark};
  border-bottom: 1px solid ${darkDivider};
  background-color: ${secondaryDark};
  color: white;
`

const NavGroup = styled.div`
  display: flex;
`

// added transform bc weird top padding on cheeseburger entity that doesn't align nice with the nav crumb
const Cheeseburger = styled.div`
  z-index: 7;
  cursor: pointer;
  font-size: 2rem;
  transform: rotate(180deg);
`

const SmallSpinner = styled.div`
  height: 2rem;
  width: 2rem;
`

const CurrPage = styled.div`
  z-index: 6;
  width: 100%;
  padding-left: 1.5rem;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: ${fontSizeParagraph};
  font-weight: 800;
  letter-spacing: ${letterSpacingLabel};
`

const DepotName = styled.span`
  text-transform: capitalize;
`

const NavCrumb = styled.span`
  text-transform: uppercase;
`

const Email = styled.span`
  padding-left: 1.5rem;
  text-align: right;
`

export default NavBar
