import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { number, oneOfType, string } from 'prop-types'

const PriceDisplay = styled.div`
  font-weight: 600;
  text-align: center;
  font-size: 1.4rem;
`

export default class PriceDisplayComponent extends PureComponent {
  static propTypes = {
    display: oneOfType([number, string])
  }

  render () {
    const { display } = this.props

    return (
      <PriceDisplay>{'$' + display}</PriceDisplay>
    )
  }
}
