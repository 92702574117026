import { css } from 'emotion'
import { secondaryDark, white } from 'helpers/css-variables'

export const hubContainer = css`
  height: 100%;
  margin-top: 4rem;
  margin-left: 2rem;
  display: flex;
  justify-content: space-evenly;
`

export const driverHubContainer = css`
  ${hubContainer};
  display: inline-flex;
`

export const driverQueueContainer = css`
  overflow-x: scroll;
  width: 40rem;
`

export const box = css`
  border-radius: 0.5rem;
  padding: 20px;
  font-size: 150%;
`

export const gridContainer = css`
  margin-left: 30px;
  width: 600px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 500px);
  grid-template-rows: repeat(100, 150px);
  grid-auto-flow: row;
`

export const drawerContainer = css`
  height: 90%;
  width: 80rem;
  background-color: ${secondaryDark};
  overflow: auto;
`

export const closeButton = css`
  position: absolute;
  right: -2rem;
  top: -2rem;
  color: ${white};
  cursor: pointer;
`
