import React from 'react'
import { Column } from 'src/microcomponents/flexbox-helpers'
import InputLabel from 'src/microcomponents/input-label'
import { arrayOf, func, object, oneOfType, string } from 'prop-types'
import { selectContainer } from '../style.js'

const MarketingFormSection = ({
  promoOptions,
  marketingChannel,
  setMarketingChannel
}) => (
  <Column top={1} bottom={1}>
    <InputLabel content='Marketing Channel*' />
    <div className={selectContainer}>
      <select onChange={setMarketingChannel} value={marketingChannel || 0}>
        <option value={0} disabled />
        {promoOptions.channels.sort().map((opt, ind) => (
          <option key={ind} value={opt}>
            {opt}
          </option>
        ))}
      </select>
    </div>
  </Column>
)

MarketingFormSection.propTypes = {
  promoOptions: oneOfType([arrayOf(string), object]),
  marketingChannel: string,
  setMarketingChannel: func
}

export default MarketingFormSection
