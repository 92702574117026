import { connect } from 'react-redux'
import React from 'react'
import { func } from 'prop-types'

import {
  fetchAdultUseTerritories,
  showTerritoryForm,
  hideTerritoryForm,
  createTerritory,
  updateTerritory,
  deleteTerritory
} from 'src/redux/territory-management/actions'
import {
  getIsTerritoryFormOpen,
  getTerritoriesMap
} from 'src/redux/territory-management/selectors'
import qs from 'query-string'

import TerritoryManagement from './'

// Props passed to the component
const mapStateToProps = function (state, props) {
  const { territoryId } = qs.parse(props.location.search)

  return {
    territoryId,
    territoriesMap: getTerritoriesMap(state),
    isTerritoryFormOpen: getIsTerritoryFormOpen(state)
  }
}

// Methods passed to the components
const mapDispatchToProps = function (dispatch, props) {
  return {
    createTerritory: (formData) => dispatch(createTerritory(formData)),
    deleteTerritory: (id, label) => dispatch(deleteTerritory(id, label)),
    fetchAdultUseTerritories: () => dispatch(fetchAdultUseTerritories()),
    hideTerritoryForm: () => dispatch(hideTerritoryForm()),
    showTerritoryForm: () => dispatch(showTerritoryForm()),
    updateTerritory: (formData) => dispatch(updateTerritory(formData))
  }
}

class TerritoryManagementContainer extends React.Component {
  static propTypes = {
    fetchAdultUseTerritories: func
  }

  componentDidMount () {
    this.props.fetchAdultUseTerritories()
  }

  render () {
    return (
      <TerritoryManagement {...this.props} />
    )
  }
}

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TerritoryManagementContainer)

export default reduxContainer
