import React, { PureComponent } from 'react'
import GoogleMapReact from 'google-map-react'
import styled from '@emotion/styled'

import GoogleMapTheme from 'components/deliveries/maps/map-theme'
import Marker from 'microcomponents/icon-order-pin'
import OrderItem from 'components/breakdown/order-item'
import { mcGreen } from 'helpers/css-variables'
import { readTime } from 'helpers/date'
import { GMAPS_KEY } from 'helpers/environment'
import { object } from 'prop-types'

const defaultOpts = {
  zoom: 2,
  options: {
    styles: GoogleMapTheme,
    fullscreenControl: false,
    zoomControl: false
  }
}

export default class Modal extends PureComponent {
  static propTypes = {
    order: object
  }

  render () {
    const { order } = this.props

    const lat = order.delivery.location.latitude
    const lng = order.delivery.location.longitude

    const opts = { center: [lat, lng], ...defaultOpts }
    return (
      <div style={{ height: '50rem' }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            language: 'en',
            key: GMAPS_KEY
          }}
          onGoogleApiLoaded={({ map, maps }) => {
            const bounds = new maps.LatLngBounds()
            bounds.extend({ lat, lng })
            map.fitBounds(bounds)
            map.setZoom(15)
          }}
          {...opts}
        >
          <Marker
            color={mcGreen}
            lat={lat}
            lng={lng}
          />
        </GoogleMapReact>
        <CenteredDiv>Order Created: {readTime(order.createdAt)}</CenteredDiv>
        <OrderItem order={order} />
      </div>
    )
  }
}

const CenteredDiv = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
`
