import blue from './blue'
import green from './green'
import grey from './grey'
import indigo from './indigo'
import purple from './purple'
import red from './red'
import yellow from './yellow'

export default {
  blue,
  green,
  gray: grey,
  grey,
  indigo,
  purple,
  red,
  yellow
}
