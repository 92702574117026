import React, { PureComponent } from 'react'
import { arrayOf, func, number, object, oneOfType, string } from 'prop-types'
import styled from '@emotion/styled'
import Button, { DANGER } from 'components/button'
import { CarIcon, DeleteIcon } from 'src/microcomponents/icons'
import { eazeBlue, midGray } from 'src/helpers/css-variables'

class ManageVehicles extends PureComponent {
  static propTypes = {
    // will close the side drawer entirely
    closeDrawer: func.isRequired,
    // closeView will update DriverMETRCInfo's state to no longer show the form
    closeView: func.isRequired,
    // currentVehicleId: string,
    // DELETEs a vehicle
    deleteDriverVehicle: func.isRequired,
    driverId: oneOfType([number, string]).isRequired,
    onfleetWorkerId: string,
    // Opens form for creating a vehicle
    toggleVehicleForm: func.isRequired,
    updateDriverVehicle: func.isRequired,
    vehicles: arrayOf(object)
  }

  static defaultProps = {
    currentVehicleId: '',
    vehicles: []
  }

  state = { loading: false }

  closeDrawer = () => {
    const { closeDrawer, closeView } = this.props
    closeView()
    closeDrawer()
  }

  performApiRequest = async (action, vehicle, confirmText) => {
    const { id, isCurrent } = vehicle
    const { deleteDriverVehicle, driverId, updateDriverVehicle, onfleetWorkerId } = this.props
    const { loading } = this.state

    if (!loading && window.confirm(confirmText)) {
      this.setState({ loading: true })
      switch (action) {
        case 'delete':
          // don't send the onfleet id if the vehicle is not the current one (onfleet only cares about the current vehicle)
          (onfleetWorkerId && vehicle.isCurrent === true)
            ? await deleteDriverVehicle(driverId, id, onfleetWorkerId)
            : await deleteDriverVehicle(driverId, id)
          break
        case 'update':
          await updateDriverVehicle(driverId, id, { ...vehicle, isCurrent: !isCurrent }, onfleetWorkerId)
          break
      }
      this.setState({ loading: false })
    }
  }

  renderVehicles () {
    const { vehicles, toggleVehicleForm } = this.props
    return (
      <VehiclesList>
        {vehicles.map(
          (vehicle) => {
            const { id, vehicleMake, vehicleModel, licensePlateNumber, isCurrent } = vehicle
            return (
              <VehicleRow key={id} onClick={() => toggleVehicleForm(vehicle)}>
                <VehicleDetails>
                  <MakeCurrentVehicle
                    onClick={(e) => {
                      e.stopPropagation()
                      this.performApiRequest(
                        'update',
                        vehicle,
                        `Are you sure you want to ${isCurrent ? 'disable this' : 'make this the'} Driver's current vehicle?`
                      )
                    }}
                  >
                    <CarIcon
                      color={isCurrent ? 'white' : undefined}
                      height='2rem'
                      width='2rem'
                    />
                  </MakeCurrentVehicle>
                  <VehicleDescription>
                    {`${vehicleMake} ${vehicleModel}, ${licensePlateNumber}`}
                  </VehicleDescription>
                </VehicleDetails>
                <DeleteVehicle
                  disabled={vehicle.isCurrent}
                  onClick={(e) => {
                    e.stopPropagation()
                    if (isCurrent) return
                    this.performApiRequest(
                      'delete',
                      vehicle,
                      'Are you sure you want to delete this vehicle?'
                    )
                  }}
                >
                  <DeleteIcon
                    height='2rem'
                    width='2rem'
                    color={isCurrent ? 'gray' : undefined}
                  />
                </DeleteVehicle>
              </VehicleRow>
            )
          }
        )}
      </VehiclesList>
    )
  }

  render () {
    const { loading } = this.state
    const { closeView, toggleVehicleForm } = this.props

    return (
      <Container className='ManageVehicles'>
        <TitleRow>
          <Title>Manage Vehicles</Title>
          <CloseDrawerButton onClick={this.closeDrawer}>
            &#10005;
          </CloseDrawerButton>
        </TitleRow>

        {this.renderVehicles()}

        <AddAVehicle onClick={() => toggleVehicleForm()}>
          + Add A Vehicle
        </AddAVehicle>

        <BottomControls>
          <Button
            type={DANGER}
            disabled={loading}
            loading={loading}
            inverted
            onClick={closeView}
            componentStyle={{
              backgroundColor: 'rgba(255, 255, 255, 0)'
            }}
          >
            CANCEL
          </Button>
        </BottomControls>
      </Container>
    )
  }
}

const Container = styled.div`
  padding: 3.6rem 2.4rem;
  color: #fff;
  width: 58rem;
  height: 100%;
  overflow-y: auto;
`

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.6rem;
`

const Title = styled.h1`
  margin: 0;
  letter-spacing: 0.1rem;
`

const CloseDrawerButton = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  font-size: 1.7rem;
  line-height: 2.4rem;
  text-align: center;
  cursor: pointer;
`

const VehiclesList = styled.div`
  width: 100%;
  margin-bottom: 2.4rem;
`

const VehicleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5.2rem;
  width: 100%;
  cursor: pointer;
  border-bottom: 0.1rem solid ${midGray};
  padding: 0 0.5rem;
`

const VehicleDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const MakeCurrentVehicle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const VehicleDescription = styled.div`
  margin-left: 1.2rem;
`

const DeleteVehicle = styled.div`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed;' : 'pointer;')}
`

const AddAVehicle = styled.a`
  color: ${eazeBlue};
  font-size: 1.4rem;
  line-height: 2.4rem;
  letter-spacing: 0.05rem;
  margin-bottom: 1.2rem;
  cursor: pointer;
`

const BottomControls = styled.div`
  margin-top: 4.8rem;
`

export default ManageVehicles
