const grey = {
  10: '#fafafa',
  20: '#f3f2f3',
  30: '#eae7eb',
  40: '#d2cdd4',
  50: '#9f9ca0',
  60: '#7d7a7e',
  70: '#5d5a5e',
  80: '#3f3d40',
  90: '#2e2b2e'
}

export default grey
