import MCGatewayClient from '@eaze/mc-gateway-client'
import store from 'src/redux/store'
import { getXAuthToken } from 'src/redux/users/selectors'
import { GATEWAY_BASE_URL } from 'helpers/environment'

const v1 = '1.0.0'

const gatewayClient = () => {
  const state = store.getState()
  const token = getXAuthToken(state)
  return new MCGatewayClient({
    baseUrl: GATEWAY_BASE_URL,
    headers: {
      'accept-version': v1
    }
  }, token)
}

export default gatewayClient
