// Fork of https://blog.solutelabs.com/configuring-thunk-action-creators-and-redux-dev-tools-with-reacts-usereducer-hook-5a1608476812
// Adds `getState` support for backwards compatibility
// We can refine and publish this to org if it proves useful

import { useReducer, useMemo, useEffect } from 'react'

const stores = {}
const subscribers = {}
let counter = 0

const REDUX_DEVTOOL_SET_STATE = 'REDUX_DEVTOOL_SET_STATE'
const withDevTools = name => {
  return (
    name &&
    process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION__
  )
}

const devToolReducer = reducer => (state, action) => {
  if (action.type === REDUX_DEVTOOL_SET_STATE) {
    return action.state
  } else {
    return reducer(state, action)
  }
}

function useReducerWithThunk (reducer, initialState, name) {
  let memoizedReducer = reducer
  const shouldConfigDevTools = withDevTools(name)

  // Memoizing to prevent recreation of devtoolReducer on each render.
  if (shouldConfigDevTools) {
    memoizedReducer = useMemo(() => devToolReducer(reducer), [reducer])
  }

  const [state, dispatch] = useReducer(memoizedReducer, initialState)

  useEffect(() => {
    if (shouldConfigDevTools) {
      memoizedReducer.prototype.name = getReducerName(name)

      if (stores[memoizedReducer.prototype.name]) {
        console.error('More than one useReducerWithThunk have same name')
      }

      stores[memoizedReducer.prototype.name] = window.__REDUX_DEVTOOLS_EXTENSION__(reducer, initialState, {
        name: memoizedReducer.prototype.name
      })

      subscribers[memoizedReducer.prototype.name] = stores[memoizedReducer.prototype.name]
        .subscribe(() => {
          dispatch({
            type: REDUX_DEVTOOL_SET_STATE,
            state: stores[memoizedReducer.prototype.name].getState()
          })
        })
    }

    return () => {
      if (shouldConfigDevTools) {
        subscribers[memoizedReducer.prototype.name]()
        subscribers[memoizedReducer.prototype.name] = undefined
        stores[memoizedReducer.prototype.name] = undefined
      }
    }
  }, [])

  const getState = () => state

  const customDispatch = action => {
    if (typeof action === 'function') {
      return action(customDispatch, getState)
    } else {
      if (shouldConfigDevTools && stores[memoizedReducer.prototype.name]) {
        stores[memoizedReducer.prototype.name].dispatch(action)
      } else {
        dispatch(action)
      }
    }
  }

  return [state, customDispatch]
}

const getReducerName = name => {
  if (!name) {
    const newName = `${(document && document.title) || 'Untitled Reducer'}_${counter}`
    counter++
    return newName
  }
  return name
}
export default useReducerWithThunk
