import React, { PureComponent } from 'react'
import { DELIVERY_TYPES } from 'helpers/drivers'
import DepotBlock from './depot-block'
import isEmptyObject from 'is-empty-object'
import styled from '@emotion/styled'
import { keyframes } from 'emotion'
import { accessory5, mcGreen, mcRed, mcYellow, primaryDark } from 'helpers/css-variables'
import { func, number, object, oneOfType, string } from 'prop-types'

const driverModeDescMap = {
  delivering: 'Delivery',
  hold: 'Hold',
  idle: 'On',
  off: 'Off'
}
const statuses = ['delivering', 'idle', 'hold', 'off']

export default class Sidebar extends PureComponent {
  static propTypes = {
    deliveryCounts: object,
    deliveryType: string,
    drivers: object,
    driverStatusTotals: object,
    expandDriverBubble: func,
    focusedDriverId: oneOfType([number, string]),
    mode: string,
    setDeliveryTypeFilter: func,
    setDepotZoom: func,
    setFocusedDriver: func,
    setModeFilter: func,
    toggleDepotOverview: func,
    utilization: string
  }

  handleSetStatus = (status) => (event) => {
    const { mode, setModeFilter } = this.props

    // toggle to an empty mode if this mode is already set
    const modeStatus = mode === driverModeDescMap[status] ? '' : status
    setModeFilter(modeStatus)
  }

  handleSetDeliveryType = (thisDeliveryType) => (event) => {
    const { deliveryType, setDeliveryTypeFilter } = this.props

    // toggle to an empty deliveryType if this deliveryType is already set
    const newDeliveryType = thisDeliveryType === deliveryType ? '' : thisDeliveryType
    setDeliveryTypeFilter(newDeliveryType)
  }

  render () {
    const {
      deliveryCounts,
      drivers,
      driverStatusTotals,
      expandDriverBubble,
      focusedDriverId,
      mode,
      setDepotZoom,
      setFocusedDriver,
      toggleDepotOverview,
      utilization
    } = this.props

    return (
      <DriverSidebarContainer>
        <DriversStatusGrid>
          <DriversStatusButton onClick={toggleDepotOverview} opacity={mode ? 0.25 : 1}>
            <DriverStatusButtonValue>{utilization === 'NaN%' ? 0 : utilization}</DriverStatusButtonValue>
            <DriverStatusButtonTitle>Utilization</DriverStatusButtonTitle>
          </DriversStatusButton>

          <DriversStatusButton onClick={this.handleSetDeliveryType('')}>
            <DriverStatusButtonValue>{deliveryCounts.dynamic + deliveryCounts.batch}</DriverStatusButtonValue>
            <DriverStatusButtonTitle>Total</DriverStatusButtonTitle>
          </DriversStatusButton>

          <DriversStatusButton onClick={this.handleSetDeliveryType(DELIVERY_TYPES.DYNAMIC)}>
            <DriverStatusButtonValue>{deliveryCounts.dynamic}</DriverStatusButtonValue>
            <DriverStatusButtonTitle>Dynamic</DriverStatusButtonTitle>
          </DriversStatusButton>
          <DriversStatusButton onClick={this.handleSetDeliveryType(DELIVERY_TYPES.BATCH)}>
            <DriverStatusButtonValue>{deliveryCounts.batch}</DriverStatusButtonValue>
            <DriverStatusButtonTitle>Batch</DriverStatusButtonTitle>
          </DriversStatusButton>
          {
            statuses.map((status, index) => {
              if (status === 'off') return null

              const title = status === 'idle'
                ? 'on'
                : status

              return (
                <DriversStatusButton
                  key={index}
                  onClick={this.handleSetStatus(status)}
                  opacity={mode ? (mode === driverModeDescMap[status] ? 1 : 0.25) : 1}
                >
                  <DriverStatusButtonValue className={status}>{driverStatusTotals[status]}</DriverStatusButtonValue>
                  <DriverStatusButtonTitle>{title}</DriverStatusButtonTitle>
                </DriversStatusButton>
              )
            })
          }
        </DriversStatusGrid>

        <DriverListContainer>
          {isEmptyObject(drivers) && <NoDrivers>No Drivers &hellip;</NoDrivers>}
          <DepotBlock
            drivers={drivers}
            expandDriverBubble={expandDriverBubble}
            focusedDriverId={focusedDriverId}
            mode={mode}
            setDepotZoom={setDepotZoom}
            setFocusedDriver={setFocusedDriver}
          />
        </DriverListContainer>
      </DriverSidebarContainer>
    )
  }
}

const revealSidebarDesktop = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-40rem, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

const revealSidebarMobile = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 40rem, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

const DriverSidebarContainer = styled.div`
  background-color: ${primaryDark};
  box-shadow: 1rem 0px 2rem rgba(0, 0, 0, 0.2);
  color: ${accessory5};
  display: inline-flex;
  flex-direction: column;
  min-width: 40rem;
  opacity: 0;
  will-change: transform;
  z-index: 10;

  @media(min-width: 768px) {
    animation: ${revealSidebarDesktop} 0.4s ease-in both;
    box-shadow: 0rem -1rem 2rem rgba(0, 0, 0, 0.2);
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  @media(max-width: 767px) {
    animation: ${revealSidebarMobile} 0.4s ease-in both;
    min-width: initial;
  }
`

const DriversStatusGrid = styled.div`
  display: grid;
  flex-shrink: 0;
  grid-gap: 2.4rem;
  grid-template-columns: repeat(4, 1fr);
  padding: 2.4rem 2.4rem; 2rem;

  @media(max-width: 767px) {
    font-size: 1.4rem;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
`

const DriversStatusButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${accessory5};
  cursor: pointer;
  opacity: ${({ opacity }) => opacity || 1};
  padding: 0;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .delivering {
    color: ${mcGreen};
  }

  .idle {
    color: ${mcYellow};
  }

  .off, .hold {
    color: ${mcRed};
  }
`

const DriverStatusButtonValue = styled.div`
  font-size: 4rem;
  font-weight: 800;
  font-weight: bold;
  text-align: left;
`

const DriverStatusButtonTitle = styled.div`
  font-size: 1.2rem;
  text-align: left;
  text-transform: uppercase;
`

const DriverListContainer = styled.div`
  height: 100%;

  @media(max-width: 767px) {
    height: auto;
    overflow: visible;
  }
`

const NoDrivers = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`
