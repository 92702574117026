import { css } from 'emotion'
import {
  accessory2,
  darkDivider,
  defaultBorderRadius,
  eazeBlue,
  imageBorderRadius,
  mcGreen,
  mcRed,
  mcYellow,
  paddingContainer,
  primaryDark,
  secondaryDark
} from 'helpers/css-variables'

// :root {
//   --select-padding: 5rem;
//   --select-border-radius: 4rem;
// }

export const header = css`
  background: transparent;
  cursor: pointer;
  border: none;
  padding: 1rem 0;
  text-transform: uppercase;
  color: white;
  font-size: 1.4rem;
  width: 100%;
  height: 100%;
  text-align: left;
`

export const drivers = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto 2rem;
`

export const controls = css`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
`

export const dot = css`
  height: 1.2rem;
  width: 1.2rem;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 1rem;
`

export const greenDot = css`
  ${dot};
  background-color: ${mcGreen};
`

export const blueDot = css`
  ${dot};
  background-color: ${eazeBlue};
`

export const yellowDot = css`
  ${dot};
  background-color: ${mcYellow};
`

export const redDot = css`
  ${dot};
  background-color: ${mcRed};
`

export const customerTable = css`
  font-size: 12px;
  width: 100%;
  border-spacing: 0;
  background-color: ${darkDivider};

  tr {
    background-color: #313131;

    &:nth-of-type(odd) {
      background-color: ${secondaryDark};
    }

    &:hover {
      opacity: 0.7;
    }
  }

  th {
    background-color: ${primaryDark};
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 0.04em;
  }

  td, th {
    padding: 1rem;
    text-align: left;
    line-height: 2rem;

    & a {
      color: white;
      text-decoration: underline;
    }
  }
`

export const isSus = css`
  background-color: #613F48;
`

export const disabledRow = css`
  background-color: #333;
  color: #555;
`

export const noMatches = css`
  width: 100%;
  text-align: center;
  font-size: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10rem auto 0;
  max-width: 20rem;
`

export const bottomBar = css`
  width: 100%;
  height: 6rem;
  background-color: ${darkDivider};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  bottom: 0;
`

export const leftContent = css`
  ${bottomBar};
  justify-content: flex-start;
  margin-left: 0.5rem;
`

export const rightContent = css`
  ${bottomBar};
  width: 30%;
  justify-content: flex-end;
  margin-right: 4rem;
`

export const select = css`
  appearance: none;
  font-size: 1.4rem;
  height: 4rem;
  min-width: 20rem;
  background-color: #373838;
  border-width: 0rem;
  color: #FFF;
  border-radius: ${defaultBorderRadius};
  padding-left: 2rem;
  padding-right: ${paddingContainer};
`

export const selectWrapper = css`
  position: relative;
  &:after {
    height: 100%;
    width: ${paddingContainer};
    content: '';
    position: absolute;
    right: 0;
    border-top-right-radius: ${defaultBorderRadius};
    border-bottom-right-radius: ${defaultBorderRadius};
    background: url('../../assets/down-arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none; /* allow clicking into select from arrow svg */
  }
`

export const myCheckbox = css`
  input {
    display: none;
    position: relative;
  }

  span {
    width: 20px;
    height: 20px;
    display: block;
    background: url('../../assets/checkMarkOff.svg');
    background-repeat: no-repeat;
    cursor: pointer;
  }

  input:checked + span {
    background: url('../../assets/checkMarkOn.svg');
    background-repeat: no-repeat;
  }
`

export const clickableText = css`
  cursor: pointer;
  padding-left: 1rem;
  text-decoration: underline;
`

export const drawerContainer = css`
  width: 60%;
  height: 100%;
  top: 3rem;
  position: relative;
  border-top-left-radius: ${imageBorderRadius};
  border-top-right-radius: ${imageBorderRadius};
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: ${accessory2};

  @media (max-width: 767px) {
    font-size: 2.4rem;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  @media(min-width: 768px) {
    width: 80%;
  }
`

export const modalBody = css`
  @media (min-width: 768px) {
    border-top-left-radius: ${imageBorderRadius};
    border-top-right-radius: ${imageBorderRadius};
    height: 100%;
    overflow-y: auto;
  }
`
