import t from './actionTypes'

const defaultState = {
  deliveriesPollInterval: 60000,
  partnerDeliveriesAccess: true,
  orderBuilderPollInterval: 30000
}

const firebase = (state = defaultState, { type, payload }) => {
  switch (type) {
    case t.RESET_FIREBASE_VALUES:
      return defaultState

    case t.SET_DELIVERIES_POLL_INTERVAL:
      return {
        ...state,
        deliveriesPollInterval: payload
      }

    case t.SET_PARTNER_DELIVERIES_ACCESS:
      return {
        ...state,
        partnerDeliveriesAccess: payload
      }

    case t.SET_ORDER_BUILDER_POLL_INTERVAL:
      return {
        ...state,
        orderBuilderPollInterval: payload
      }

    default:
      return state
  }
}

export default firebase
