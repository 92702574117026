import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bool, func, object, shape, string } from 'prop-types'
import Drawer from '@eaze/react-fast-drawer'
import TagPreview from './edit/preview/details'
import {
  deleteTagItems,
  setTagConflicts
} from 'src/redux/tags/actions'
import { getTagsMap } from 'src/redux/tags/selectors'
import { getProductStore } from 'src/redux/products/selectors'
import Button, { PRIMARY, DANGER } from 'components/button'
import { getBrandsMap } from 'src/redux/brands-map/selectors'
import { getSpeciesMap } from 'src/redux/species-map/selectors'
import { getDepotsMap } from 'src/redux/depots-map/selectors'

import { conflictModalContentClass, conflictModalButtons } from './style.js'

const mapStateToProps = (state, props) => {
  const conflictId = props.conflictId
  const conflictObject = state.tags.tagConflicts[conflictId] || {}
  const tagId = conflictObject.tag_id
  const itemId = conflictObject.item_id

  const productsMap = getProductStore(state)

  const tagsMap = getTagsMap(state)
  const tag = tagsMap[tagId]
  const tagConflicts = state.tags.tagConflicts

  const speciesMap = getSpeciesMap(state)
  const brandsMap = getBrandsMap(state)
  const depotsMap = getDepotsMap(state)

  // TODO: The details view was written expecting to use only formData. It should accept a simple array of products/depots
  const conflictProducts = {}
  if (itemId) conflictProducts[itemId] = true

  const conflictDepots = {}
  if (tag) {
    tag.areaIds.forEach((id) => {
      conflictDepots[id] = true
    })
  }

  return {
    brandsMap,
    conflictObject,
    tag,
    tagsMap,
    tagConflicts,
    productsMap,
    conflictDepots,
    conflictProducts,
    speciesMap,
    depotsMap
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteTagItems: (tagId, itemIds) => dispatch(deleteTagItems(tagId, itemIds)),
    setTagConflicts: (conflictMap) => dispatch(setTagConflicts(conflictMap))
  }
}

class ConflictModal extends PureComponent {
  static propTypes = {
    // External
    // conflictId: string,
    onRequestClose: func,
    selectedState: string,
    isOpen: bool,

    // Internal
    brandsMap: object,
    depotsMap: object,
    productsMap: object,
    conflictDepots: object,
    conflictProducts: object,
    speciesMap: object,
    tag: object,
    conflictObject: shape({
      item_id: string,
      item_title: string,
      tag_id: string,
      tag_tag_type: string,
      tag_text: string
    }),

    deleteTagItems: func,
    setTagConflicts: func,
    tagConflicts: object
  }

  removeItemFromTag = async (tagId, itemId) => {
    const { err } = await this.props.deleteTagItems(tagId, itemId)
    if (!err) {
      this.props.onRequestClose()

      const conflictMapCopy = { ...this.props.tagConflicts }
      delete conflictMapCopy[itemId]

      this.props.setTagConflicts(conflictMapCopy)
    }
  }

  render () {
    const {
      brandsMap,
      conflictObject,
      depotsMap,
      isOpen,
      onRequestClose,
      productsMap,
      conflictDepots,
      conflictProducts,
      selectedState,
      speciesMap,
      tag
    } = this.props

    if (!isOpen) return null

    return (
      <Drawer
        open={!!isOpen}
        onRequestClose={onRequestClose}
        containerStyle={{ zIndex: '20' }}
        modalElementClass={conflictModalContentClass}
      >
        <div>
          <TagPreview
            headerText='This product already has an active tag'
            tagData={tag}
            brandsMap={brandsMap}
            productsMap={productsMap}
            selectedDepots={conflictDepots}
            selectedState={selectedState}
            selectedProducts={conflictProducts}
            speciesMap={speciesMap}
            depotsMap={depotsMap}
          />
          <div className={conflictModalButtons}>
            <Button onClick={() => this.removeItemFromTag(conflictObject.tag_id, conflictObject.item_id)} type={DANGER}>REMOVE PREVIOUS TAG</Button>
            <Button onClick={() => onRequestClose()} type={PRIMARY}>DO NOTHING</Button>
          </div>
        </div>
      </Drawer>
    )
  }
}

const ConnectedConflictModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConflictModal)

export default ConnectedConflictModal
