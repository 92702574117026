import { css, keyframes } from '@emotion/core'
import { darkDivider, lightGray, mcGreen, primaryDark, secondaryDark, tertiaryDark } from 'helpers/css-variables'

const rcSwitchOn = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1.1);
  }
`

const rcSwitchOff = keyframes`
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`

export const globalStyles = css`
  @import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@200;400;600&display=swap');

  html,
  body {
    font-size: 10px;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    display: block;
    font-family: 'Fira Sans', Helvetica;
    background-color: ${primaryDark};
    color: ${lightGray};
  }

  #app {
    width: 100%;
    height: 100%;
    font-size: 1.6rem;
  }

  * {
    box-sizing: border-box;
  }

  /* global table reset styles */
  table {
    font-size: 1.2rem;
    width: 100%;
    border-spacing: 0;
    background-color: ${darkDivider};
    color: #fff;
    letter-spacing: 0.03rem;

    tr {
      cursor: pointer;
      background-color: rgb(49, 49, 49);
      font-size: 1.44rem;

      &.isSus {
        background-color: #613f48 !important;
      }

      &:nth-of-type(odd) {
        background-color: ${secondaryDark};
      }
    }

    th {
      background-color: rgb(49, 49, 49);
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: 400;
    }

    td, th {
      padding: 1rem;
      text-align: left;
      line-height: 2rem;

      & a {
        color: white;
        text-decoration: underline;
      }
    }
  }

  table tbody tr:hover {
    opacity: 0.7;
  }

  /* this previously lived in pages/promos/style.css
  and managed to affect the entire app anyway so moving it here */
  input,
  textarea {
    border: 0;
    color: white;
    border-radius: 0.4rem;
    padding: 1rem;
    font-size: 1.6rem;
    width: 100%;
    background-color: ${tertiaryDark};
    font-family: 'Fira Sans';
    resize: none;
  }

  /* these styles are applied globally to apply to the 3rd-party rc-switch component */
  .rc-switch {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 44px;
    height: 22px;
    line-height: 20px;
    vertical-align: middle;
    border-radius: 20px 20px;
    border: 1px solid #ccc;
    background-color: #ccc;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  }

  .rc-switch-inner {
    color: #fff;
    font-size: 12px;
    position: absolute;
    left: 24px;
    /*
    Regarding top: 0, upgrading to rc-switch@1.8.0+ resulted in this element
    getting pushed down (positionally) by 9px (they switched the parent element
    from a span to a button: https://github.com/react-component/switch/pull/26
    */
    top: 0;
  }

  .rc-switch:after {
    position: absolute;
    width: 18px;
    height: 18px;
    left: 2px;
    top: 1px;
    border-radius: 50% 50%;
    background-color: #fff;
    content: " ";
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
    transform: scale(1);
    transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
    animation-duration: 0.3s;
    animation-name: ${rcSwitchOff};
  }

  .rc-switch:hover:after {
    transform: scale(1.1);
    animation-name: ${rcSwitchOn};
  }
  .rc-switch:focus {
    box-shadow: 0 0 0 2px #d5f1fd;
    outline: none;
  }
  .rc-switch-checked {
    border: 1px solid ${mcGreen};
    background-color: ${mcGreen};
  }
  .rc-switch-checked .rc-switch-inner {
    left: 6px;
  }
  .rc-switch-checked:after {
    left: 22px;
  }
  .rc-switch-disabled {
    cursor: no-drop;
    background: #ccc;
    border-color: #ccc;
  }
  .rc-switch-disabled:after {
    background: #9e9e9e;
    animation-name: none;
    cursor: no-drop;
  }
  .rc-switch-disabled:hover:after {
    transform: scale(1);
    animation-name: none;
  }
  .rc-switch-label {
    display: inline-block;
    line-height: 20px;
    font-size: 14px;
    padding-left: 10px;
    vertical-align: middle;
    white-space: normal;
    pointer-events: none;
    user-select: text;
  }
`
