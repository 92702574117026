import { connect } from 'react-redux'
import React from 'react'
import { bool, func, object } from 'prop-types'

import history from 'components/router/history'
import { pushNotification } from 'src/redux/alerts/actions'
import { getGroupStore } from 'src/redux/groups/selectors'
import { PRODUCT_FILTER_TYPE, GROUP_FILTER_TYPE } from 'helpers/constants'
import { fetchAllDispensaries } from 'src/redux/dispensaries/actions'
import {
  addTagItems,
  createTag,
  deleteTag,
  fetchBrands,
  fetchTagsGroups,
  fetchSpecies,
  fetchTagTypes,
  getTagWithItemsAndUpdateMap,
  getTags,
  hideTagForm,
  setFilterString,
  setFilterType,
  toggleTag,
  showTagForm,
  updateTag,
  setTagConflicts
} from 'src/redux/tags/actions'
import {
  filterGroupProducts,
  filterGroups,
  getFilterType,
  getTagsMap,
  getTagsAsArray,
  getTagTypes,
  getGroupIds,
  getIsTagFormOpen
} from 'src/redux/tags/selectors'
import { getProductStore } from 'src/redux/products/selectors'
import { getBrandsMap } from 'src/redux/brands-map/selectors'
import { getDepotsMap } from 'src/redux/depots-map/selectors'
import { getSpeciesMap } from 'src/redux/species-map/selectors'
import qs from 'query-string'

import Tags from './'

const getBoolean = (string) => {
  if (string === 'true') return true
  return false
}

const getGroupIdsByFilterType = (filterType, state) => (
  filterType === GROUP_FILTER_TYPE
    ? filterGroups(state)
    : getGroupIds(state)
)
const getGroupsMapByFilterType = (filterType, state) => (
  filterType === PRODUCT_FILTER_TYPE
    ? filterGroupProducts(state)
    : getGroupStore(state)
)

const mapStateToProps = function (state, props) {
  const filterType = getFilterType(state)
  const params = qs.parse(props.location.search)

  return {
    brandsMap: getBrandsMap(state),
    conflictsMap: state.tags.tagConflicts,
    depots: state.depots.list,
    depotsMap: getDepotsMap(state),
    filterType,
    groupIds: getGroupIdsByFilterType(filterType, state),
    groupsMap: getGroupsMapByFilterType(filterType, state),
    isTagFormOpen: getIsTagFormOpen(state),
    params: params,
    productsMap: getProductStore(state),
    showExpiredTags: getBoolean(params.showExpired),
    tags: getTagsAsArray(state),
    tagsMap: getTagsMap(state),
    speciesMap: getSpeciesMap(state),
    tagTypes: getTagTypes(state)
  }
}

const mapDispatchToProps = function (dispatch, props) {
  return {
    addTagItems: (tagItemsObject, callback) => dispatch(addTagItems(tagItemsObject, callback)),
    createTag: (tagObject, callback) => dispatch(createTag(tagObject, callback)),
    deleteTag: (id) => dispatch(deleteTag(id)),
    fetchAllDispensaries: () => dispatch(fetchAllDispensaries()),
    fetchBrands: () => dispatch(fetchBrands()),
    fetchTagsGroups: () => dispatch(fetchTagsGroups()),
    fetchSpecies: () => dispatch(fetchSpecies()),
    fetchTagTypes: () => dispatch(fetchTagTypes()),
    getTags: (queryObject) => dispatch(getTags(queryObject)),
    getTagWithItemsAndUpdateMap: (id) => dispatch(getTagWithItemsAndUpdateMap(id)),
    hideTagForm: () => dispatch(hideTagForm()),
    pushNotification: (message, type) => dispatch(pushNotification(message, type)),
    setFilterString: (filterString) => dispatch(setFilterString(filterString)),
    setFilterType: (filterType) => dispatch(setFilterType(filterType)),
    setTagConflicts: (conflictMap) => dispatch(setTagConflicts(conflictMap)),
    showTagForm: () => dispatch(showTagForm()),
    toggleTag: (id, isEnabled) => dispatch(toggleTag(id, isEnabled)),
    updateTag: (tagObject, callback) => dispatch(updateTag(tagObject, callback))
  }
}

class TagsContainer extends React.Component {
  static propTypes = {
    fetchTagsGroups: func,
    fetchAllDispensaries: func,
    fetchBrands: func,
    fetchSpecies: func,
    fetchTagTypes: func,
    getTags: func,
    params: object,
    showExpiredTags: bool
  }

  toggleExpiredTags = () => {
    const newSearch = qs.stringify({
      ...this.props.params,
      showExpired: !this.props.showExpiredTags
    })

    history.push({ search: newSearch })
  }

  componentDidMount () {
    const {
      fetchAllDispensaries,
      fetchBrands,
      fetchSpecies,
      fetchTagsGroups,
      fetchTagTypes,
      getTags,
      showExpiredTags
    } = this.props

    let getTagsParams = {}
    if (showExpiredTags) getTagsParams = { showExpired: true }

    getTags(getTagsParams)
    fetchTagsGroups()
    fetchAllDispensaries()
    fetchBrands()
    fetchSpecies()
    fetchTagTypes()
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps (nextProps) {
  /* eslint-enable camelcase */
    const { showExpiredTags: lastShowExpiredTags } = this.props
    const { showExpiredTags: nextShowExpiredTags } = nextProps
    let getTagsParams = {}

    if (nextShowExpiredTags) getTagsParams = { showExpired: true }

    if (lastShowExpiredTags !== nextShowExpiredTags) {
      this.props.getTags(getTagsParams)
    }
  }

  render () {
    return (
      <Tags {...this.props} toggleExpiredTags={() => this.toggleExpiredTags()} />
    )
  }
}

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TagsContainer)

export default reduxContainer
