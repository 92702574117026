import React from 'react'
import { func } from 'prop-types'
import styled from '@emotion/styled'
import onClickOutside from 'react-onclickoutside'
import { tertiaryDark } from 'src/helpers/css-variables'

class VehiclesMenu extends React.Component {
  static propTypes = {
    closeMenu: func.isRequired,
    toggleManageVehiclesView: func.isRequired,
    toggleVehicleForm: func.isRequired
  }

  handleClickOutside = evt => this.props.closeMenu()

  render () {
    return (
      <VehiclesMenuContainer>
        <MenuItem onClick={() => this.props.toggleVehicleForm()}>
          Add A Vehicle
        </MenuItem>
        <MenuItem onClick={this.props.toggleManageVehiclesView}>
          Manage Vehicles
        </MenuItem>
      </VehiclesMenuContainer>
    )
  }
}

const VehiclesMenuContainer = styled.div`
  position: absolute;
  top: 28rem;
  right: 3rem;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  border-radius: 0.4rem;
  width: 15rem;
  background-color: ${tertiaryDark};
  box-shadow: 0 0.8rem 1.6rem 0 rgba(0, 0, 0, 0.2);
  cursor: default;

  a:last-child {
    margin-bottom: 0;
  }
`

const MenuItem = styled.a`
  color: white;
  font-size: 1.4rem;
  line-height: 2.4rem;
  letter-spacing: 0.05rem;
  margin-bottom: 1.2rem;
  cursor: pointer;
`

export default onClickOutside(VehiclesMenu)
