import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import debounce from 'debounce'
import window from 'global/window'
import { array, bool, func, object } from 'prop-types'
import { Flex, Column, Row } from 'src/microcomponents/flexbox-helpers'

import Button, { HYBRID, DANGER } from 'components/button'
import InputLabel from 'src/microcomponents/input-label'
import LoadingSpinner from 'src/microcomponents/loading-spinner'
import Switch from 'src/microcomponents/switch'
import { isValidInput, isValidImage, isValidEffect } from 'src/helpers/form-validators'
import UploadFile from 'microcomponents/upload-file'
import EffectSlider from 'src/components/effect-slider'
import {
  imageFormThumbnail,
  imageFormThumbnailDeleteButton,
  imageFormThumbnailLoadingContainer,
  imageFormThumbnailSpinner,
  inputError,
  selectContainer
} from '../../style.js'

import { transformToCatalogStrain } from 'src/redux/catalog/strain/actions'

import uploadImageSvg from 'src/assets/upload-image.svg'

export class EditStrain extends PureComponent {
  state = {
    formSubmitAttempted: false,
    // using transformToCatalogStrain in order to make sure the local
    // catalogStrain has the same shape as the redux store's catalogStrain
    catalogStrain: transformToCatalogStrain({ effects: [] }),
    isEdit: false,
    imageIsLoading: false
  }

  static propTypes = {
    isStrainFormOpen: bool,
    cantCreateStrain: bool,
    catalogStrain: object,
    createCatalogStrain: func,
    deleteCatalogStrain: func,
    setCatalogStrain: func,
    species: array,
    updateCatalogStrain: func,
    uploadPublicFile: func
  }

  // destructuring isStrainFormOpen nextProps to compare to this.props.isStrainFormOpen
  // to determine whether the form was opened from a closed state
  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps ({ isStrainFormOpen, catalogStrain }) {
    /* eslint-enable camelcase */
    // if we are going to open the form, ensure we reset formSubmitAttempted
    if (this.props.isStrainFormOpen === false && isStrainFormOpen === true) {
      const { id } = catalogStrain
      const isEdit = Boolean(id)

      this.setState({
        formSubmitAttempted: false,
        catalogStrain,
        isEdit
      })
    }
  }

  deleteStrain = (event) => {
    event.preventDefault()
    const { catalogStrain: { name } } = this.state
    const confirmMessage = `Are you sure you want to delete the ${name} strain from the catalog?`

    if (window.confirm(confirmMessage)) {
      this.props.deleteCatalogStrain()
    }
  }

  submitForm = (event) => {
    event.preventDefault()
    const { isEdit } = this.state
    const { cantCreateStrain, createCatalogStrain, updateCatalogStrain } = this.props
    this.setState({ formSubmitAttempted: true })

    if (!cantCreateStrain) {
      if (isEdit) {
        updateCatalogStrain()
      } else {
        createCatalogStrain()
      }
    }
  }

  // debounce this callback so we don't spam redux updates on change of form fields
  updateStore = debounce(() => {
    const { setCatalogStrain } = this.props
    const { catalogStrain } = this.state
    setCatalogStrain(catalogStrain)
  }, 200)

  updateStrainState = (catalogStrain) => {
    this.setState({ catalogStrain }, this.updateStore)
  }

  setFormValue = ({ target: { value } }, formKey) => {
    const { catalogStrain } = this.state
    const newCatalogStrain = {
      ...catalogStrain,
      [formKey]: value
    }
    this.updateStrainState(newCatalogStrain)
  }

  setBoolean = (formKey) => {
    const { catalogStrain } = this.state
    const booleanValue = catalogStrain[formKey]
    const newCatalogStrain = {
      ...catalogStrain,
      [formKey]: !booleanValue
    }
    this.updateStrainState(newCatalogStrain)
  }

  setEffectName = ({ target: { value } }, itemReference) => {
    const { catalogStrain } = this.state
    const { effects } = catalogStrain
    effects[itemReference].name = value

    // if user types in an effect name and there is no value, auto-set to 0
    if (effects[itemReference].amount === '') {
      effects[itemReference].amount = '1'
    }
    // if a user clears the text field, set amount to empty string
    if (value === '') {
      effects[itemReference].amount = ''
    }

    const newCatalogStrain = {
      ...catalogStrain,
      effects
    }

    this.updateStrainState(newCatalogStrain)
  }

  setEffectAmount = (amount, itemReference) => {
    const { catalogStrain } = this.state
    const { effects } = catalogStrain
    effects[itemReference].amount = amount.toString()

    // we don't want effects with an amount of 0
    if (amount === 0) {
      effects[itemReference].amount = ''
    }

    const newCatalogStrain = {
      ...catalogStrain,
      effects
    }
    this.updateStrainState(newCatalogStrain)
  }

  setImageLoading = (image) => {
    this.setState({ imageIsLoading: true })
  }

  setImage = (image) => {
    this.setState({ imageIsLoading: false })
    const { catalogStrain } = this.state
    const { images } = catalogStrain

    const newCatalogStrain = {
      ...catalogStrain,
      images: [...images, image]
    }
    this.updateStrainState(newCatalogStrain)
  }

  removeImage = (event, index) => {
    event.preventDefault()
    const { catalogStrain } = this.state
    const { images } = catalogStrain
    images.splice(index, 1)

    const newCatalogStrain = {
      ...catalogStrain,
      images: [...images]
    }
    this.updateStrainState(newCatalogStrain)
  }

  render () {
    const { imageIsLoading, isEdit, formSubmitAttempted, catalogStrain } = this.state
    const { species: speciesList, uploadPublicFile } = this.props
    const {
      name,
      description,
      species,
      thc,
      cbd,
      highCbd,
      images = [],
      effects = []
    } = catalogStrain

    return (
      <div style={{ height: '100%', overflow: 'auto', padding: '1rem' }}>
        <Column>
          <h1>Add a Strain to Catalog</h1>
          <form onSubmit={this.submitForm}>
            <Column>
              <InputLabel content='* Name' />
              <input
                className={formSubmitAttempted && !isValidInput(name) ? inputError : ''}
                onChange={(event) => this.setFormValue(event, 'name')}
                value={name}
                placeholder=''
              />
            </Column>

            <Column top={1}>
              <InputLabel content='* Species' />
              <div className={selectContainer}>
                <select
                  className={formSubmitAttempted && !isValidInput(species) ? inputError : ''}
                  onChange={(event) => this.setFormValue(event, 'species')}
                  value={species}
                >
                  <option value=''>None</option>
                  {speciesList.map(t => <option key={t.id} value={t.id}>{t.name}</option>)}
                </select>
              </div>
            </Column>

            <Column top={1}>
              <InputLabel content='Description' />
              <textarea
                onChange={(event) => this.setFormValue(event, 'description')}
                value={description}
                cols='2'
                rows='5'
              />
            </Column>

            <Column top={1}>
              <Row>
                <Column right={1} full>
                  <InputLabel content='* THC' />
                  <input
                    className={formSubmitAttempted && !isValidInput(thc) ? inputError : ''}
                    type='number'
                    value={thc}
                    min={0}
                    onChange={(event) => this.setFormValue(event, 'thc')}
                  />
                </Column>

                <Column full>
                  <InputLabel content='* CBD' />
                  <input
                    className={formSubmitAttempted && !isValidInput(cbd) ? inputError : ''}
                    type='number'
                    value={cbd}
                    min={0}
                    onChange={(event) => this.setFormValue(event, 'cbd')}
                  />
                </Column>
              </Row>
            </Column>

            <Row align='center' top={1}>
              <Flex full />
              <Flex full>
                <div onClick={(event) => this.setBoolean('highCbd')}>
                  * High CBD? <Switch enabled={highCbd} />
                </div>
              </Flex>
            </Row>

            <Flex top={1}>
              {
                images.map(({ id, downloadUrl }, index) => (
                  <Link
                    key={id}
                    className={imageFormThumbnail}
                    style={{ backgroundImage: `url(${downloadUrl})` }}
                    to={{ pathname: downloadUrl }}
                    rel='noreferrer'
                    target='_blank'
                  >
                    <button
                      className={imageFormThumbnailDeleteButton}
                      onClick={(event) => this.removeImage(event, index)}
                    >×</button>
                  </Link>
                ))
              }
              {
                imageIsLoading
                  ? (<div className={imageFormThumbnailLoadingContainer}>
                      <div className={imageFormThumbnailSpinner}>
                        <LoadingSpinner show />
                      </div>
                    </div>)
                  : null
              }
              <div className={formSubmitAttempted && !isValidImage(images) ? inputError : ''}>
                <UploadFile
                  postUpload={this.setImage}
                  onRequestUpload={this.setImageLoading}
                  uploadFile={uploadPublicFile}
                >
                  <img src={uploadImageSvg} />
                </UploadFile>
              </div>
            </Flex>

            {
              effects.map(({ name, amount }, index) => (
                <Flex top={1} key={index}>
                  <EffectSlider
                    key={index}
                    itemReference={index}
                    inputLabel='Effect'
                    invalidNameClass={isValidEffect({ name, amount }) ? '' : inputError}
                    sliderValue={amount}
                    sliderName={name}
                    setEffectName={this.setEffectName}
                    updateSliderValue={this.setEffectAmount}
                  />
                </Flex>
              ))
            }

            <Flex top={3}>
              <Button type={HYBRID}>
                Submit
              </Button>
            </Flex>

            {
              isEdit && (
                <Flex top={1}>
                  <Button
                    type={DANGER}
                    onClick={this.deleteStrain}
                    disabled
                  >
                  Delete
                  </Button>
                </Flex>
              )
            }

          </form>
        </Column>
      </div>
    )
  }
}

export default EditStrain
