import React from 'react'
import { array, func } from 'prop-types'
import List from './list-component'
import GroupCard from 'src/components/group-card'
import Create from './create'
import { brandGrid } from '../style.js'

export default class GroupList extends React.PureComponent {
  static propTypes = {
    create: func,
    createOrUpdateGroup: func,
    groups: array,
    updateGroupObject: func
  }

  render () {
    const { create, createOrUpdateGroup, groups, updateGroupObject } = this.props

    return (
      <List listClass={brandGrid} items={groups}>
        <Create
          action={create}
          title='add new group'
        />
        {
          groups.slice(0, 100).map(g => (
            <GroupCard
              createOrUpdateGroup={createOrUpdateGroup}
              group={g}
              id={g.id}
              items={g.items}
              key={g.id}
              lastUpdated={g.updated_at}
              name={g.internal_name || g.name}
              slug={g.slug}
              updateGroupObject={updateGroupObject}
            />
          ))
        }
      </List>
    )
  }
}
