/**
 * Component representing prices in the order details page.
 *
 * @props {Number or String} amount - Amount in dollars
 * @props {String} label - String label to show what the amount is for
 * @props {String} type - should be one of the types from ./types.js
 */

import Color from 'components/color'
import { bool, func, number, object, oneOfType, string } from 'prop-types'
import React, { PureComponent } from 'react'
import Spinner from 'components/spinner'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Tooltip from 'components/tooltip'

import * as TYPES from '../constants'
import { displayPriceFormatter } from '../price-helper'

function getFontWeight ({ type }) {
  if (type === TYPES.BOLD_TYPE) {
    return 600
  } else if (type === TYPES.FADED_TYPE) {
    return 200
  }

  return 'normal'
}

function getFontColor (type) {
  switch (type) {
    case TYPES.BOLD_TYPE:
      return Color.accessory['1']
    case TYPES.HIGHLIGHT_TYPE:
      return Color.hybrid['1']
    case TYPES.FADED_TYPE:
      return Color.accessory['2']
    case TYPES.RED_SUBTYPE:
      return Color.danger['2']
    case TYPES.GREEN_SUBTYPE:
      return Color.hybrid['1']
    default:
      return null
  }
}

export default class PriceLineItem extends PureComponent {
  static propTypes = {
    amount: oneOfType([number, string]),
    defaultText: oneOfType([number, string]),
    deliveryFeeTooltipText: string,
    isDeliveryFeeBypassed: oneOfType([bool, number]),
    isDeliveryPastMinimum: bool,
    isDiscountedDeliveryFee: bool,
    isPeakDelivery: bool,
    label: string.isRequired,
    lineThroughPrice: bool,
    loading: bool.isRequired,
    originalAmount: oneOfType([number, string]),
    show: oneOfType([bool, number]),
    subtext: oneOfType([object, string]),
    toggleTaxesAndFees: func,
    type: string
  }

  static defaultProps = {
    defaultText: '--',
    type: TYPES.FADED,
    loading: false
  }

  render () {
    const {
      amount,
      defaultText,
      deliveryFeeTooltipText,
      isDeliveryFeeBypassed,
      isDeliveryPastMinimum,
      isDiscountedDeliveryFee,
      isPeakDelivery,
      label,
      lineThroughPrice,
      loading,
      originalAmount,
      show,
      subtext,
      toggleTaxesAndFees,
      // tooltip,
      type
    } = this.props

    if (!show) return null

    // let tooltipIcon = tooltip && (<Tooltip position='right'/>)
    let amountDisplay

    if (loading) {
      amountDisplay = (
        <Spinner size={1.5} />
      )
    } else if (typeof amount === 'number') {
      amountDisplay = displayPriceFormatter(amount)
    } else if (amount === 'FREE') {
      amountDisplay = amount
    } else {
      amountDisplay = defaultText
    }

    let originalAmountDisplay
    if (typeof originalAmount === 'number') {
      originalAmountDisplay = displayPriceFormatter(originalAmount)
    }
    // this boolean detects if the delivery fee has been bypassed
    // but not by the user hitting a miimum order amount
    const isPriceOverride = isDeliveryFeeBypassed && !isDeliveryPastMinimum
    const renderOriginalAmount = isDiscountedDeliveryFee && !isDeliveryFeeBypassed
    const renderPeakIcon = isPeakDelivery && !isPriceOverride

    return (
      <Container type={type}>
        <Row>
          <Title>
            {label}
            <TooltipMargin>
              {toggleTaxesAndFees &&
                <a onClick={toggleTaxesAndFees} style={{ verticalAlign: 'text-top' }}><InfoIcon /></a>}
            </TooltipMargin>
          </Title>
          {renderPeakIcon &&
            <PeakDeliveryFeeIconWrapper>
              <Tooltip
                position='left'
                containerClass={TooltipContainer}
                tooltipClass={TooltipContent}
                tooltipIconClass={TooltipIcon}
                contents={deliveryFeeTooltipText}
              >
                <PeakDeliveryFeeIcon />
              </Tooltip>
            </PeakDeliveryFeeIconWrapper>}
          {renderOriginalAmount &&
            <OriginalAmount>{originalAmountDisplay}</OriginalAmount>}
          <Amount lineThroughPrice={lineThroughPrice && !renderOriginalAmount}>{amountDisplay}</Amount>
        </Row>
        {subtext && <Subtitle type={subtext.type} customStyle={subtext.customStyle}>{subtext.text}</Subtitle>}
      </Container>
    )
  }
}

const Container = styled.div`
  color: ${({ type }) => getFontColor(type)};
  font-family: 'Proxima', 'Open sans', Helvetica;
  font-size: ${({ type }) => type === TYPES.BOLD_TYPE ? '1.4rem' : '1.2rem'};
  font-weight: ${getFontWeight};
  line-height: 2rem;
  padding: 1.5rem 0 0;
  width: 100%;
`

const TooltipMargin = styled.div`
  margin-left: 1rem;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Amount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: ${({ lineThroughPrice }) => lineThroughPrice ? 'line-through' : 'none'};
`

const OriginalAmount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: line-through;
  margin-left: auto;
  margin-right: 1rem;
`

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const Subtitle = styled.div`
  color: ${({ type }) => getFontColor(type)};
  font-size: 1rem;
  line-height: 1.3rem;
  margin-top: 0.5rem;
  ${({ customStyle }) => customStyle}
`

const PeakDeliveryFeeIconWrapper = styled.div`
  margin-left: auto;
  margin-right: 0.5rem;
  border-radius: 50%;
`

const TooltipContent = css`
  max-width: 16rem;
`

const TooltipContainer = css`
  height: 2rem;
  width: 2rem;
  padding: 0.3rem;
`

const TooltipIcon = css`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PeakDeliveryFeeIcon = ({ width = '100%', height = '100%' }) => (
  <svg width={width} height={height} viewBox='0 0 8 12' xmlns='http://www.w3.org/2000/svg'>
    <g id='BUILD-THIS' fill='none' fillRule='evenodd'>
      <g id='Menu-(iPhone-6)-Copy-3' transform='translate(-287 -441)' fill='#FFF' fillRule='nonzero'>
        <g id='Group' transform='translate(0 143)'>
          <g id='Group-2' transform='translate(12 235)'>
            <g id='Group-7' transform='translate(270 60)'>
              <polygon id='Shape' points='13 7.68123949 6.57614746 15 7.87800683 10.3187605 5 10.3187605 11.4238525 3 10.1219932 7.68123949' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

PeakDeliveryFeeIcon.propTypes = {
  width: string,
  height: string
}

const InfoIcon = () => (
  <svg
    width='16px'
    height='16px'
    viewBox='0 0 16 16'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    aria-labelledby='more-info-title'
    role='img'
  >
    <title id='more-info-title'>More Info</title>
    <g id='more-info' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='noun_Info_932469' fill='#948A91'>
        <path d='M7.40571429,6.53428571 L8.59142857,6.53428571 L8.59142857,11.6771429 L7.40571429,11.6771429 L7.40571429,6.53428571 Z M7.40571429,4.32285714 L8.59142857,4.32285714 L8.59142857,5.52 L7.40571429,5.52 L7.40571429,4.32285714 Z M8,16 C3.581722,16 1.77635684e-15,12.418278 1.77635684e-15,8 C7.61295788e-16,3.581722 3.581722,0 8,0 C12.418278,-1.01506105e-15 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 Z M8,1.18857143 C4.23815188,1.18857143 1.18857143,4.23815188 1.18857143,8 C1.18857143,11.7618481 4.23815188,14.8114286 8,14.8114286 C11.7618481,14.8114286 14.8114286,11.7618481 14.8114286,8 C14.8082777,4.23945778 11.7605422,1.19172228 8,1.18857143 Z' id='Combined-Shape' />
      </g>
    </g>
  </svg>
)
