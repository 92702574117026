import api from 'api'
import { searchCatalog } from 'src/redux/catalog/actions'

export function searchCatalogOrFetchRecents (value) {
  return async (dispatch, getState) => {
    if (value) {
      return dispatch(searchCatalog(value, 'products'))
    }
    // If we did not receive a value, we want to get the most recent catalog items.
    // We'll also run this on pageload
    api.getAllCatalogItems({ limit: 100 })
      .then(({ data }) => {
        dispatch({
          type: 'SET_PRODUCTS',
          payload: data.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}),
          // if we ever ran this once, disable the page load effect
          meta: {
            // indicate when we return recent results VS searched results
            showingRecentCatalogEdits: true
          }
        })
      })
      .catch(err => {
        dispatch({
          type: 'SET_PRODUCTS',
          error: true,
          meta: {
            catalogError: 'Error retrieving catalog products',
            error: err
          }
        })
      })
  }
}

async function fetchAllMatchingInventory (catalogId) {
  if (!catalogId) {
    return []
  }

  let page = 1
  const results = []
  const count = 100
  let hasfetchedAll = false

  while (!hasfetchedAll) {
    const { data, err } = await api.getInventoryItems({ query: catalogId, count, page })
    if (data) {
      results.push(...data.items)
      if (data.total > count) {
        page++
      } else {
        hasfetchedAll = true
      }
    } else {
      console.error(err || 'Failed to fetch inventory items')
    }
  }
  return results
}

function applyCatalogPropertiesToInventoryItem (options = {}, catalogItem = {}, invItem = {}) {
  const newInvItem = { ...invItem }
  let skipUpdate = true

  if (options.Image && newInvItem.photoId !== catalogItem.images[0].id) {
    skipUpdate = false
    newInvItem.photoId = catalogItem.images[0].id
  }
  if (options.Name && newInvItem.name !== catalogItem.title) {
    skipUpdate = false
    newInvItem.name = catalogItem.title
  }

  if (options['CBD:THC']) {
    if (newInvItem.cbd !== catalogItem.cbd_content || newInvItem.thc !== catalogItem.thc_content) {
      skipUpdate = false
    }
    newInvItem.cbd = catalogItem.cbd_content
    newInvItem.thc = catalogItem.thc_content
  }

  if (options.Price && newInvItem.price !== catalogItem.suggested_retail_price) {
    skipUpdate = false
    newInvItem.price = catalogItem.suggested_retail_price
  }

  // Allow for filtering out unneccesarry updates
  if (skipUpdate) {
    return null
  } else {
    return newInvItem
  }
}

function reportInventoryUpdateError (message = 'Unknown error', err = null) {
  return (dispatch, getState) => dispatch({
    type: 'SET_UPDATE_PROGRESS',
    error: true,
    meta: {
      inventoryError: message,
      error: err
    }
  })
}

export function updateInventoryItems (catalogItems = [], options = {}) {
  return async (dispatch, getState) => {
    if (!Object.values(options).includes(true)) {
      return dispatch(reportInventoryUpdateError('No options selected for inventory update'))
    }

    // We'll toss these in a hash by catalog ID so we can pair the `categoryItem`s with thier associated `inventoryItems`
    // This mostly just makes `applyCatalogPropertiesToInventoryItem` easier
    const batchCollection = catalogItems.reduce((acc, cat) => ({ ...acc, [cat.id]: { catalogItem: cat } }), {})

    // using an array so we can grab counts async and sum them when ready to update UI.
    const inventoryUpdateCounts = []

    // Reset update state
    dispatch({ type: 'SET_UPDATE_PROGRESS', payload: null })

    // First, we'll traverse the catalogItems we received, and fetch all matching inventory
    await Promise.all(
      Object.entries(batchCollection).map(async ([id, batchItem]) => {
        batchItem.inventoryItems = []
        return fetchAllMatchingInventory(id)
          .catch(err => {
            const message = `Error fetching matching inventory for - Name: ${batchItem.catalogItem.title} ID: ${id}`
            dispatch(reportInventoryUpdateError(message, err))
          }).then(inventoryItems => {
            inventoryUpdateCounts.push(inventoryItems.length)
            batchItem.inventoryItems.push(...inventoryItems)
          })
      })
    )

    // Collect the items we need to update
    const invUpdateBatch = []

    // We'll loop through again now that we have inv items attached, we'll sub-loop into those, apply catalog attributes to them
    await Object.values(batchCollection).map((batchItem) => batchItem.inventoryItems.map(async (invItem) => {
      const newInvItem = applyCatalogPropertiesToInventoryItem(options, batchItem.catalogItem, invItem)

      if (newInvItem) {
        invUpdateBatch.push(newInvItem)
      }
    }))

    dispatch({ type: 'SET_UPDATE_PROGRESS', payload: [0, invUpdateBatch.length] })

    // Since we're filtering out unneccesarry requests, handle the edge case of no updates.
    if (invUpdateBatch.length === 0) {
      return
    }

    // Last, we'll do our final network operation in a separate loop for error handling/UI updating purposes
    await Promise.all(
      invUpdateBatch.map(async (invItem) => {
        api.updateInventoryItem({ ...invItem })
          .then(() => {
            dispatch({ type: 'SET_UPDATE_PROGRESS', meta: { increment: true } })
          })
          .catch(err => {
            const message = `Error updating inventory item - Name: ${invItem.name} ID: ${invItem.id}`
            dispatch(reportInventoryUpdateError(message, err))
          })
      })
    )
  }
}
