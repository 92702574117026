import { createSelector } from 'reselect'
import { getSortedCatalogGroups } from 'src/redux/catalog/selectors'

const getMenuItemState = state => state.menuItem

export const getMenuGroups = createSelector(
  [getMenuItemState],
  (menuItem) => menuItem.items
)

export const getDeDupedGroups = createSelector(
  [getSortedCatalogGroups, getMenuGroups],
  (groups, menuGroups) => {
    const menuGroupsIds = menuGroups.map((menuGroup) => menuGroup.id)

    const deDupedProducts = [...groups].filter((group) => {
      if (!group) return false

      return menuGroupsIds.indexOf(group.id) === -1
    })

    return deDupedProducts
  }
)
