import api from 'api'

import { pushNotification, SUCCESS } from 'src/redux/alerts/actions'
import {
  SET_CATALOG_TYPES,
  SET_CATALOG_TYPE,
  SHOW_TYPE_FORM,
  HIDE_TYPE_FORM
} from 'src/redux/catalog/type/actionTypes'
import {
  hideActionBar,
  clearFormQueryParams
} from 'src/redux/catalog/actions.common'

export function createCatalogType () {
  return async (dispatch, getState) => {
    const state = getState()
    const { catalogType } = state

    const { err, data } = await api.createCatalogType(catalogType)
    if (err) return

    dispatch(pushNotification(`Successfully created type ${catalogType.name}`, SUCCESS))
    dispatch(hideTypeForm())
    dispatch(addCatalogType(transformToCatalogType(data)))
  }
}

export function updateCatalogType (routerLocation) {
  return async (dispatch, getState) => {
    const state = getState()
    const { catalogType } = state

    const { err, data } = await api.updateCatalogType(catalogType)
    if (err) return

    dispatch(pushNotification(`Successfully updated type ${catalogType.name}`, SUCCESS))
    dispatch(hideTypeForm(routerLocation))
    dispatch(replaceCatalogType(transformToCatalogType(data)))
  }
}

export function deleteCatalogType (routerLocation) {
  return async (dispatch, getState) => {
    const state = getState()
    const { catalogType } = state
    const { id } = catalogType
    const { err } = await api.deleteCatalogType({ id })
    if (err) return

    dispatch(pushNotification(`Successfully deleted type ${catalogType.name}`, SUCCESS))
    dispatch(removeCatalogType(catalogType))
    dispatch(hideTypeForm(routerLocation))
  }
}

export function addCatalogType (type) {
  // get types, add type, update full list
  return (dispatch, getState) => {
    const state = getState()
    const { catalog: { types } } = state

    dispatch(setCatalogTypes([type, ...types]))
  }
}

export function replaceCatalogType (type) {
  // get types, search for type, replace, update full list
  return (dispatch, getState) => {
    const state = getState()
    const { catalog: { types } } = state
    const newList = types.map((typeItem) => typeItem.id === type.id ? type : typeItem)

    dispatch(setCatalogTypes(newList))
  }
}

export function removeCatalogType (type) {
  // get list, search, remove, update full list
  return (dispatch, getState) => {
    const state = getState()
    const { catalog: { types } } = state
    const typesWithItemsRemoved = types.filter((typeItem) => typeItem.id !== type.id)

    dispatch(setCatalogTypes([...typesWithItemsRemoved]))
  }
}

// parser to ignore unused keys from the response
export function transformToCatalogType (type) {
  return {
    id: type.id || null,
    name: type.name || '',
    description: type.description || ''
  }
}

function setCatalogTypes (types) {
  return {
    type: SET_CATALOG_TYPES,
    types
  }
}

export function setCatalogType (catalogType) {
  return {
    type: SET_CATALOG_TYPE,
    catalogType
  }
}

export function showTypeForm (type) {
  return dispatch => {
    dispatch({ type: SHOW_TYPE_FORM })

    const typeObject = type || {}
    const transformedType = transformToCatalogType(typeObject)
    dispatch(setCatalogType(transformedType))
    dispatch(hideActionBar())
  }
}

export function hideTypeForm (routerLocation) {
  return dispatch => {
    if (routerLocation) {
      clearFormQueryParams(routerLocation)
    }
    dispatch({ type: HIDE_TYPE_FORM })
    // clear out form on hide
    dispatch(setCatalogType(transformToCatalogType({})))
  }
}
