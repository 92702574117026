import { setDay } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { dateOnly } from 'helpers/date'

/**
 * Tranform formData in to ISO format for OG. OG cares about the DAY and TIME. Even if you send a date string to OG with the appropriate date. OG will return a date with year 1899/1900 which will not refect modern time zones or daylight savings. This is why we adjust the date string to the current date.
 * @param {*} day 0-6
 * @param {string} hour in 23 hr format '00:00' - '23:59'
 * @param {*} depotTimeZone IANA designation like 'America/Los_Angeles'
 */
export function formatPeakHoursTime (day, hour, depotTimeZone) {
  const today = new Date()
  const currentYr = today.getFullYear()
  const currentMonth = today.getMonth()
  const currentDay = today.getDate()

  // make sure day of week is set correctly for the particular year and month
  const updatedDate = setDay(new Date(currentYr, currentMonth, currentDay), day)

  // use current date and append desired time to more accurately reflect if this is being set during DST
  const localDateTime = `${dateOnly(updatedDate.toISOString())}T${hour}`

  // convert to UTC ISO format for OG
  return zonedTimeToUtc(localDateTime, depotTimeZone).toISOString()
}
