import React, { PureComponent } from 'react'
import { func, string } from 'prop-types'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { Flex, Row } from 'src/microcomponents/flexbox-helpers'
import { darkDivider, mcBlue } from 'src/helpers/css-variables'
import ROUTES from 'src/pages/catalog/routes'

export default class NavigationBar extends PureComponent {
  state = {
    resetQuery: false
  }

  static propTypes = {
    currentRoute: string,
    fetchCatalogFn: func
  }

  componentDidUpdate (prevProps) {
    const { currentRoute, fetchCatalogFn } = this.props
    // if we're changing routes we want to re-initialize the search by querying and returning the full set
    if (prevProps.currentRoute !== currentRoute) {
      this.setState(
        { resetQuery: true },
        // flip resetQuery back to false so the child only gets a truthy value once
        () => {
          this.setState({ resetQuery: false })
          fetchCatalogFn(prevProps.currentRoute)
          fetchCatalogFn(currentRoute)
        }
      )
    }
  }

  render () {
    const { currentRoute } = this.props

    return (
      <Container className='CatalogNavigationBar'>
        <Row jusitfy='space-between'>
          <Flex full>
            <Row align='center' jusitfy='flex-start'>
              {
                Object.keys(ROUTES).map(key => {
                  const route = ROUTES[key]
                  const link = route.name

                  return (
                    <StyledLink
                      active={link === currentRoute ? 1 : 0}
                      key={link}
                      to={`/catalog/${link}`}
                    >
                      {route.label}
                    </StyledLink>
                  )
                })
              }
            </Row>
          </Flex>
        </Row>
      </Container>
    )
  }
}

const Container = styled.div`
  border-bottom: 1px solid ${darkDivider};
  max-width: 120rem;
  margin: 2rem auto;
`

const StyledLink = styled(Link)`
  background-color: transparent;
  border: none;
  border-bottom: 0.6rem solid ${({ active }) => active ? mcBlue : 'transparent'};
  color: white;
  cursor: pointer;
  font-size: 1.4rem;
  letter-spacing: 0.03em;
  margin-right: 2rem;
  padding-bottom: 2rem;
  text-decoration: none;

  &:hover {
    border-color: ${mcBlue};
    opacity: 0.8;
  }
`
