import React, { Component } from 'react'
import { bool, func, number, string } from 'prop-types'
import styled from '@emotion/styled'
import { css, keyframes } from 'emotion'
import debounce from 'debounce'
import * as JsSearch from 'js-search'
import shortid from 'shortid'

import Button, { HYBRID, DANGER } from 'components/button'
import LoadingSpinner from 'components/spinner'
import SearchInput from 'src/components/mui/search-input/'
import Table from 'src/microcomponents/table'
import TablePager from 'src/microcomponents/table-pager'
import QuantityInput from 'src/microcomponents/quantity-input'
import { generateImageUrl } from 'helpers/get-cloudflare-img-url.js'
import history from 'src/components/router/history'
import CSVHandler from 'src/components/hub-and-spoke/csv-handler'
import { track } from 'src/analytics'

const getTableConfig = (changes, onQuantityChange) => {
  const imgFormat = (url) => <img src={generateImageUrl(url)} alt='product-image' loading='lazy' height='34' width='34' style={{ borderRadius: '4px' }} />
  const priceFormat = (p) => `$${p}`

  const quantityFormat = (v, row) => {
    const { id: productId } = row
    const unsavedQtyValue = changes[productId]

    let value

    if (unsavedQtyValue || unsavedQtyValue === 0) {
      value = unsavedQtyValue
    } else {
      value = v
    }

    return (
      <QuantityInput
        allowNegativeValues
        dataTestId={`${row.id}`}
        key={row.id}
        maxQuantityAllowed={10000}
        updateQuantity={(newQuantity) => onQuantityChange(newQuantity, row)}
        value={value}
      />
    )
  }

  return [
    {
      key: 'photoUrl',
      disableSort: true,
      formatter: imgFormat
    },
    {
      title: 'Type',
      key: 'type.name'
    },
    {
      title: 'Brand',
      key: 'brand.name'
    },
    {
      title: 'ID',
      key: 'id'
    },
    {
      title: 'Product',
      key: 'name'
    },
    {
      title: 'Price',
      key: 'price',
      formatter: priceFormat
    },
    {
      title: 'Quantity',
      style: { textAlign: 'center' },
      key: 'maxQuantity',
      formatter: quantityFormat
    }
  ]
}

export default class DepotInventory extends Component {
  static propTypes = {
    depotId: number.isRequired,
    getDepotInventory: func,
    loading: bool,
    page: number,
    query: string,
    saveBatchChanges: func
  }

  static defaultProps = {
    depot: {},
    saveBatchChanges: () => {},
    getDepotInventory: () => {}
  }

  state = {
    totalResults: 0,
    totalProducts: 0,
    inventory: [],
    invMap: {},
    changes: {},
    products: {},
    searchIndex: {},
    highlight: false,
    saving: false,
    searching: false,
    queryChange: true,
    showChanges: false,
    trackEvents: [],
    searchStr: this.props.query
  }

  async componentDidMount () {
    if (!this.props.page) {
      this.updateURLparams(1)
    } else {
      this.getPaginatedDepotInventory()
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { page, query } = this.props
    const { inventory, saving } = this.state

    // search query has changed
    if (prevProps.query !== query) {
      this.setState({ queryChange: true })
    }

    // page or query has changed
    if (prevProps.page !== page || prevProps.query !== query) {
      this.getPaginatedDepotInventory()
    }

    // build frontend search index
    // when cached inventory has changed
    if ((prevState.inventory.length !== inventory.length) || prevState.saving !== saving) {
      this.buildSearchIndex()
    }

    if (!page) {
      this.updateURLparams(1)
    }
  }

  getDepotInventory = async () => {
    const { getDepotInventory, depotId } = this.props
    const { products } = await getDepotInventory(depotId, -1)
    this.setState({ inventory: products })
    const invMap = await this.getInventoryMap(products)
    this.setState({ invMap })
  }

  getPaginatedDepotInventory = async () => {
    const { page, query, depotId, getDepotInventory } = this.props
    const { inventory } = this.state
    const offset = page ? 100 * (page - 1) : 0

    // fetch inventory if there is
    // none saved on state or new query
    if (inventory.length === 0) {
      this.getDepotInventory() // fetch whole inventory on the background to cache it for later
      const { products, totalProducts, totalResults } = await getDepotInventory(depotId, 100, offset, query)
      this.setState({ products, totalProducts, totalResults, queryChange: false })
    } else {
      const { totalProducts } = this.state
      // use the cached inventory
      let results = inventory
      let total = totalProducts
      if (query) {
        // frontend search with cached inventory
        const { searchIndex } = this.state
        results = searchIndex.search(query)
        total = results.length
      }
      const isLastPage = offset > total
      const start = isLastPage ? -(total % 100) : offset
      const end = isLastPage ? null : 100 + offset
      const cachedInventory = results.slice(start, end)
      this.setState({ products: cachedInventory, totalResults: total, queryChange: false })
    }
  }

  buildSearchIndex = () => {
    const { inventory } = this.state
    const dataToSearch = new JsSearch.Search('catalogItemId')
    dataToSearch.tokenizer = new JsSearch.StopWordsTokenizer(new JsSearch.SimpleTokenizer())
    dataToSearch.addIndex('id')
    dataToSearch.addIndex('name')
    dataToSearch.addIndex(['brand', 'name'])
    dataToSearch.addIndex(['type', 'name'])

    dataToSearch.addDocuments(inventory)
    this.setState({ searchIndex: dataToSearch })
  }

  handleDiscardChanges = () => {
    const { query } = this.props
    this.setState({ changes: {}, searchStr: query, showChanges: false })
  }

  highlightRow = (rowData) => {
    const { changes, highlight } = this.state
    if (Object.keys(changes).includes(rowData.id.toString()) && highlight) return Highlight
  }

  onQuantityChange = (newQuantity, row) => {
    const { id: productId, maxQuantity } = row
    const newChanges = { ...this.state.changes }

    if (newQuantity === maxQuantity) {
      delete newChanges[productId]
    } else {
      newChanges[productId] = newQuantity
    }
    this.setState({ changes: newChanges, showChanges: true, highlight: true })
  }

  handleSaveChanges = async () => {
    this.setState({ saving: true })
    const { products } = await this.props.saveBatchChanges(this.props.depotId, this.transformedChanges())
    this.setState({ inventory: products, saving: false, highlight: false, showChanges: false, changes: {} })
    this.getPaginatedDepotInventory()
    const invMap = await this.getInventoryMap(products)
    this.setState({ invMap })

    // send track events to segment
    // in batches of 200 every 5 seconds
    // to avoid rate-limiting
    const { trackEvents } = this.state
    let counter = 0
    for (let i = 0; i < trackEvents.length; i++) {
      if (counter === 200) {
        counter = 0
        await this.sleep(5000)
      }
      track('DepotCase.ManualUpload', trackEvents[i])
      counter++
    }
    this.setState({ trackEvents: [] })
  }

  handleSearch = (query) => {
    this.setState({ searchStr: query, searching: true })
    this.handleSearchChange(query)
  }

  handleSearchChange = debounce(async (query) => {
    this.updateURLparams(1, query)
    this.setState({ searching: false })
  }, 400)

  transformedChanges = () => {
    const { depotId, email } = this.props
    const { invMap, trackEvents } = this.state
    const uploadId = shortid.generate()
    return Object.keys(this.state.changes).map((key) => {
      const item = invMap[key]
      // tracking information
      trackEvents.push({
        depotId,
        uploadId,
        name: item.name,
        username: email,
        productId: item.id,
        previousCount: item.maxQuantity,
        newCount: this.state.changes[key]
      })
      return {
        productId: key,
        quantity: this.state.changes[key]
      }
    })
  }

  onPaginationClick = (inc) => {
    const { searchStr } = this.state
    const page = inc ? this.props.page + 1 : this.props.page - 1
    this.setState({ paginationClick: true })
    this.updateURLparams(page, searchStr)
  }

  updateURLparams = (page, query) => {
    history.push({
      search: `?page=${page}${query ? `&query=${query}` : ''}`
    })
  }

  updateInventory = (inv) => {
    this.setState({ inventory: inv })
    this.getPaginatedDepotInventory()
  }
  
  sleep = (ms) => new Promise(res => setTimeout(res, ms))

  getInventoryMap = async (inventory) => {
    const inv = {}
    // generates inventory map by productId
    for (let i = 0, len = inventory.length; i < len; i++) {
      inv[inventory[i].id] = inventory[i]
    }
    return inv
  }

  render () {
    const { page, loading: inventoryLoading } = this.props
    const {
      changes,
      showChanges,
      searchStr,
      searching,
      saving,
      products,
      totalProducts,
      totalResults,
      queryChange,
      inventory
    } = this.state

    if (totalProducts === 0) {
      return (
        <Container>
          <LoadingSpinner />
        </Container>
      )
    }

    const clearSearch = ((totalProducts !== totalResults) && !searchStr)
    const loading = searching || clearSearch || inventoryLoading
    const notFoundPage = (page > Math.ceil(totalResults / 100))
    const hideLabel = searching || clearSearch || notFoundPage || (inventoryLoading && queryChange)
    const totalPages = Math.ceil((searchStr ? totalResults : totalProducts) / 100)
    const changeCount = Object.keys(changes).length || false

    return (
      <Container>
        <CSVHandler {...this.props}
          inventory={inventory}
          updateInventory={this.updateInventory}
          sleep={this.sleep}
          getInventoryMap={this.getInventoryMap}
        />
        <SearchInput
          margin='0 0 2rem'
          onChange={this.handleSearch}
          placeholder='Search Depot Case by Type, Brand, ID, or Name'
          value={searchStr}
          label={`Search ${totalProducts} products`}
        />
        <TablePager
          increment={() => this.onPaginationClick(true)}
          decrement={() => this.onPaginationClick()}
          total={totalPages}
          page={page}
          style={{
            maxWidth: '100%'
          }}
          hideLabel={hideLabel}
          loading={loading || notFoundPage}
        />
        { loading
          ? <LoadingSpinner />
          : <Table
            config={getTableConfig(changes, this.onQuantityChange)}
            data={products}
            height='calc(100vh - 25rem)'
            style={{
              paddingBottom: showChanges ? '40px' : '0'
            }}
            rowClick={() => {}}
            noDataMsg='Not Found'
            rowStyle={(data) => this.highlightRow(data)}
          />
        }
        <SaveBar
          style={{
            display: showChanges ? 'flex' : 'none'
          }}
        >
          <SaveBarWrapper>
            <span>
              Updating {changeCount} {changeCount > 1 ? 'Products' : 'Product'}
            </span>
            <BtnContainer>
              <SaveBarBtn
                componentStyle={{ marginRight: '2rem' }}
                disabled={loading}
                onClick={this.handleDiscardChanges}
                type={DANGER}
              >
                Discard Changes
              </SaveBarBtn>
              <SaveBarBtn
                disabled={loading && showChanges}
                loading={saving}
                onClick={this.handleSaveChanges}
                type={HYBRID}
              >
                Save Changes
              </SaveBarBtn>
            </BtnContainer>
          </SaveBarWrapper>
        </SaveBar>
      </Container>
    )
  }
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const Container = styled.div`
  padding: 3rem;
`

const Highlight = css`
  background-color: #00aae7 !important;
  color: #fff;

  &:hover {
    opacity: 1;
  }
`

const SaveBar = styled.div`
  position: fixed;
  width: calc(100% - 20rem);
  bottom: 0;
  height: 7rem;
  background-color: #404242;
  padding: 1rem;
  box-shadow: 0 -1rem 10rem rgba(0, 0, 0, 0.25);

  -webkit-animation: ${fadeIn} 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: ${fadeIn} 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
`

const SaveBarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
`

const BtnContainer = styled.div`
  display: flex;
  max-width: 32rem;
`

const SaveBarBtn = styled(Button)`
  font-family: inherit;
  padding: 1rem;
  min-width: 14rem;
`
