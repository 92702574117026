import { NONE_KEY, TOTAL_EXCEEDED_MINIMUM_KEY } from './order-price-summary/constants'

export function getIsPeakDelivery (currentDeliveryFee, deliveryFee, currentBypassFee, bypassFee) {
  return getIsPeakDeliveryFee(currentDeliveryFee, deliveryFee) ||
    getIsPeakDeliveryBypass(currentBypassFee, bypassFee)
}

export function getIsDiscountedDelivery (currentDeliveryFee, deliveryFee, currentBypassFee, bypassFee) {
  return getIsDiscountedDeliveryFee(currentDeliveryFee, deliveryFee) ||
    getIsDiscountedDeliveryBypass(currentBypassFee, bypassFee)
}

export function getIsPeakDeliveryFee (currentDeliveryFee, deliveryFee) {
  return Boolean(typeof currentDeliveryFee === 'number' && currentDeliveryFee > deliveryFee)
}

export function getIsDiscountedDeliveryFee (currentDeliveryFee, deliveryFee) {
  return Boolean(typeof currentDeliveryFee === 'number' && currentDeliveryFee < deliveryFee)
}

export function getIsPeakDeliveryBypass (currentBypassFee, bypassFee) {
  return Boolean(typeof currentBypassFee === 'number' && currentBypassFee > bypassFee)
}

export function getIsDiscountedDeliveryBypass (currentBypassFee, bypassFee) {
  return Boolean(typeof currentBypassFee === 'number' && currentBypassFee < bypassFee)
}

export function getIsDeliveryFeeBypassed (deliveryFee, bypassDeliveryFeeReason) {
  return deliveryFee && bypassDeliveryFeeReason !== NONE_KEY
}

export function getIsDeliveryPastMinimum (bypassDeliveryFeeReason) {
  return TOTAL_EXCEEDED_MINIMUM_KEY === bypassDeliveryFeeReason
}
