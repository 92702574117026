import React from 'react'
import { string } from 'prop-types'

export default function IconRemove ({ color = 'white', size = '36px' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 100 100' >
      <path
        d='M50.094 8c-3.703 0-6.426 1.46-8.281 2.875s-3.004 2.574-4.938 3.344A3.057 3.057 0 0 0 35 16H17v6h66v-6H65a3.046 3.046 0 0 0-1.875-1.781c-1.953-.778-3.168-1.945-5-3.344S53.65 8 50.095 8zm0 6c2.207.125 3.453.934 4.844 2H45c1.754-1.352 3.59-1.984 5.094-2zM20 24v56.031c0 3.492.91 6.64 3.125 8.844S28.512 92 32.031 92h36c3.485 0 6.645-.922 8.844-3.125S80 83.523 80 80.03V24H20zm6 6h48v50.031c0 2.488-.586 3.832-1.375 4.625S70.508 86 68.031 86h-36c-2.527 0-3.86-.582-4.656-1.375S26 82.519 26 80.03zm7 10v36h6V40zm14 0v36h6V40zm14 0v36h6V40z'
        fill={color}
      />
    </svg>
  )
}

IconRemove.propTypes = {
  color: string,
  size: string
}
