import React, { useState } from 'react'
import Form from 'microcomponents/input/form'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { makeStyles } from '@material-ui/core/styles'
import window from 'global/window'
import { number, array } from 'prop-types'
import { API_BASE_URL } from 'helpers/environment'
import Cookies from 'js-cookie'

/*
  This component is intended to work as an isolated module and has no dependency on app state/structure.
  It accepts 2 props:
  {activeDepots, selectedDepotId}
  and will source the `x-auth-token` from local storage

  Note a few exceptions:
    it does use `Form` to provide UI logic for submit button.
    requires presence of `API_BASE_URL`
*/

const useStyles = makeStyles((theme) => {
  const { pxToRem } = theme.typography
  return {
    formControl: {
      'margin-bottom': theme.spacing(1),
      minWidth: pxToRem(400)
    },
    formHelperText: {
      'margin-left': theme.spacing(1)
    }
  }
})

const cloneDepotProducts = async (sourceDepotId, targetDepotId) => {
  if (!sourceDepotId || !targetDepotId) {
    return { err: `missing required input sourceDepotId: ${sourceDepotId}, targetDepotId: ${targetDepotId}` }
  }
  const req = {
    headers: { 'X-Auth-Token': JSON.parse(window.localStorage.getItem('mc:user')).xAuthToken },
    body: {
      depotFrom: sourceDepotId,
      depotTo: targetDepotId
    }
  }

  try {
    // https://github.com/eaze/api/blob/latest/base-api-methods/inventory/inventoryClonePost.js
    const URL = API_BASE_URL + '/inventory/clone'
    const response = await window.fetch(URL, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        ...req.headers,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(req.body)
    })

    return { response, err: null }
  } catch (err) {
    console.error(err)
    return { err, response: null }
  }
}

const cloneCookieExists = (depotID) => {
  return !!Cookies.get(`clone_${depotID}`)
}

const createCloneCookie = (selectedDepotId) => {
  Cookies.set(`clone_${selectedDepotId}`, selectedDepotId, {
    path: '/',
    expires: (1 / 1440) * 5 // 5 minutes
  })
}

const CloneDepotProductsComponent = (props) => {
  const classes = useStyles()
  const { activeDepots, selectedDepotId } = props

  const DEPOT_TARGETS_DEFAULT_STATE = {
    destination: ''
  }
  const [depotTargets, setDepotTargets] = useState({ ...DEPOT_TARGETS_DEFAULT_STATE })

  const REQ_DEFAULT_STATE = {
    loading: false,
    success: false,
    error: ''
  }
  const [requestStatus, setRequestStatus] = useState({ ...REQ_DEFAULT_STATE })

  const handleSubmit = async () => {
    // This is a temporary fix to keep the end-user from making multiple requests
    setRequestStatus({ ...REQ_DEFAULT_STATE, success: true })
    createCloneCookie(depotTargets.destination)
    // Expected response from OG should be changed to 201, endpoint must change before proper response monitoring can occur.
    const { response, err } = await cloneDepotProducts(selectedDepotId, depotTargets.destination)
    if (err) {
      setRequestStatus({ ...REQ_DEFAULT_STATE, error: err })
    } else if (response.ok && response.status === 204) {
      setRequestStatus({ ...REQ_DEFAULT_STATE, success: true })
    } else if (!response.ok && response.body) {
      const text = await response.json()
      setRequestStatus({ ...REQ_DEFAULT_STATE, error: `${response.status} - ${text.message}` })
    } else {
      setRequestStatus({ ...REQ_DEFAULT_STATE, error: 'An unspecified error occurred...' })
    }
  }

  const getHelperText = () => {
    if (requestStatus.error) {
      if (requestStatus.error.message) return requestStatus.error.message
      return requestStatus.error
    } else if (requestStatus.success) {
      // edited for clarity to end-user
      return 'Depots products queued for cloning! Please wait 5 minutes to verify inventory clone was successful ✅'
    } else {
      return "Select a depot to clone this depot's products to"
    }
  }

  const isFormDisabled = requestStatus.loading || !depotTargets.destination || cloneCookieExists(depotTargets.destination) || requestStatus.disabled

  return (
    <Form
      onSubmit={handleSubmit}
      submitText='Clone Products'
      loading={requestStatus.loading}
      disabled={isFormDisabled}
    >
      <FormControl variant='outlined' className={classes.formControl}>
        <InputLabel id='depotClone'>Target depot:</InputLabel>
        <Select
          labelId='depotClone'
          value={depotTargets.destination}
          onChange={(e) => setDepotTargets({ destination: e.target.value })}
          autoWidth
          label='Target depot:'
        >
          {activeDepots.map((d) => (
            <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>
          ))}
        </Select>
        <FormHelperText className={classes.formHelperText} error={!!requestStatus.error}>
          {getHelperText(requestStatus)}
        </FormHelperText>
      </FormControl>
    </Form>
  )
}

CloneDepotProductsComponent.propTypes = {
  activeDepots: array,
  selectedDepotId: number
}

CloneDepotProductsComponent.displayName = 'CloneDepotProductsComponent'
export default CloneDepotProductsComponent
