import { func, string } from 'prop-types'
import React from 'react'
import { Flex, Column } from 'src/microcomponents/flexbox-helpers'
import InputLabel from 'src/microcomponents/input-label'

const Description = ({ description, setPromoDescription }) => (
  <Column bottom={1}>
    <InputLabel content='promo description*' />
    <Flex>
      <textarea
        onBlur={setPromoDescription}
        placeholder='Description'
        defaultValue={description}
      />
    </Flex>
  </Column>
)

Description.propTypes = {
  description: string,
  setPromoDescription: func
}

export default Description
