import { createSelector } from 'reselect'
import fuzzysearch from 'fuzzysearch'

import { getProductStore } from 'src/redux/products/selectors'
import { getGroupStore } from 'src/redux/groups/selectors'

const getTagsStore = state => state.tags

export const getTagsMap = createSelector(
  [getTagsStore],
  (store) => store.tagsMap
)

export const getTagTypes = createSelector(
  [getTagsStore],
  (store) => store.tagTypes
)

export const getTagById = (state, id) => {
  const tagsMap = getTagsMap(state)
  return tagsMap[id] || {}
}

export const getIsTagFormOpen = createSelector(
  [getTagsStore],
  function ({ isTagFormOpen }) {
    return isTagFormOpen
  }
)

export const getProductIds = createSelector(
  [getTagsStore],
  function ({ productIds }) {
    return productIds
  }
)

export const getGroupIds = createSelector(
  [getTagsStore],
  function ({ groupIds }) {
    return groupIds
  }
)

export const getFilterString = createSelector(
  [getTagsStore],
  function ({ filterString }) {
    return filterString
  }
)

export const getFilterType = createSelector(
  [getTagsStore],
  function ({ filterType }) {
    return filterType
  }
)

export const filterGroups = createSelector(
  [getFilterString, getFilterType, getGroupStore, getGroupIds],
  function (filterString, productStore, groupStore, groupIds) {
    return groupIds.filter(function (id) {
      const group = groupStore[id] || {}
      const { name = '' } = group
      const searchContentString = `${name} ${id}`

      return fuzzysearch(filterString.toLowerCase(), searchContentString.toLowerCase())
    })
  }
)

export const filterGroupProducts = createSelector(
  [getFilterString, getProductStore, getGroupStore, getGroupIds],
  function (filterString, productStore, groupStore, groupIds) {
    const groupsStoreCopy = {}

    groupIds.forEach(function (id) {
      const group = groupStore[id] || { items: [] }
      const items = group.items
      groupsStoreCopy[id] = {
        ...groupStore[id],
        items: filterString
          ? searchProductFilter(productStore, items, filterString)
          : items
      }
    })

    return groupsStoreCopy
  }
)

export function searchProductFilter (productStore, items, productsFilter) {
  const filteredProducts = items.filter(function (id) {
    const product = productStore[id] || {}
    const { title = '' } = product

    const searchContentString = `${title} ${id}`

    return fuzzysearch(productsFilter.toLowerCase(), searchContentString.toLowerCase())
  })
  return filteredProducts
}

export const getTagsAsArray = createSelector(
  [getTagsMap],
  (tagsMap) => Object.values(tagsMap)
)
