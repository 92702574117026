import { arrayOf, bool, func, number, shape, string } from 'prop-types'

const model = {
  // Read only
  id: string,
  // UI only properties.
  catalogName: string,
  catalogNameBuy: string,
  catalogNameGet: string,
  selectedPromoDefinition: number,
  unFlat: func,
  // Promo model
  enabled: bool,
  code: string,
  description: string,
  valid_at: string,
  expires_at: string,
  rules: shape({
    applies_to: string,
    promo_type: string,
    global_limit: number,
    user_limit: number,
    order_history: string,
    min_order_amount: number,
    state_id: string,
    user_cohort: shape({
      verification_status: number,
      total_orders: number,
      dispensary_id: string,
      depot_ids: arrayOf(number)
    }),
    operators: shape({
      min_order_amount: null, // DEPRECATE ME
      credit_amount: number,
      credit_type: string,
      credit_max: number,
      id_type: string,
      id_value: string,
      buy: shape({
        id_type: string,
        id_value: string,
        quantity: number
      }),
      get: shape({
        id_type: string,
        id_value: string,
        quantity: number
      })
    })
  }),
  'meta.marketing_channel': string
}

export default shape(model)
