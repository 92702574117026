import React, { useEffect } from 'react'
import Component from './component'
import useReducerWithThunk from 'src/utils/custom-hooks/use-thunk-reducer'
import { updateInventoryItems, searchCatalogOrFetchRecents } from './actions'
import { setOrUnsetItem, setUpdateProgress } from './helpers.js'

const defaultState = {
  batchProgress: null, // [0, 36] an array containing complete/total count, null = no job started
  searchResults: {},
  itemsToEdit: {}, // A hash by catalogId. Each object value may contain a {catalogItem} and [inventoryItems]
  runPageLoadEffect: true, // something we can flip after we've fetched recent catalog items, or when there's no query
  showingRecentCatalogEdits: true,
  inventoryError: '',
  catalogError: ''
}

const reducer = (state = defaultState, { type, payload, error = false, meta = {} }) => {
  switch (type) {
    case 'SET_PRODUCTS': // we catch this from `searchCatalog` and also trigger it manually
      return {
        ...state,
        searchResults: payload || defaultState.searchResults,
        // Once we load catalog products, we know we don't want the pageload effect to run again
        runPageLoadEffect: false,
        showingRecentCatalogEdits: meta.showingRecentCatalogEdits || false,
        catalogError: error ? meta.catalogError : ''
      }
    case 'SET_ITEM_TO_EDIT':
      return {
        ...state,
        itemsToEdit: setOrUnsetItem(state, payload)
      }
    case 'SET_ALL_ITEMS_TO_EDIT':
      return {
        ...state,
        itemsToEdit: { ...state.itemsToEdit, ...payload }
      }
    case 'UNSET_ALL_ITEMS_TO_EDIT':
      return {
        ...state,
        itemsToEdit: defaultState.itemsToEdit
      }
    case 'SET_UPDATE_PROGRESS':
      return setUpdateProgress({ state, payload, error, meta })

    default:
      return state
  }
}

const InventoryUpdateContainer = () => {
  const [store, dispatch] = useReducerWithThunk(reducer, defaultState, 'MC-Next InventoryUpdateContainer')

  const dispatchers = {
    updateInventoryItems: (catalogItems, options) => dispatch(updateInventoryItems(catalogItems, options)),
    catalogSearch: (value) => dispatch(searchCatalogOrFetchRecents(value)),
    setItemToEdit: (item) => dispatch({ type: 'SET_ITEM_TO_EDIT', payload: item }),
    setAllItemsToEdit: (catalogHash) => dispatch({ type: 'SET_ALL_ITEMS_TO_EDIT', payload: catalogHash }),
    unsetAllItemsToEdit: () => dispatch({ type: 'UNSET_ALL_ITEMS_TO_EDIT' })
  }

  // PageLoadEffect
  useEffect(() => {
    if (store.runPageLoadEffect) {
      dispatchers.catalogSearch()
    }
  })

  const derivedProps = {
    ...store,
    searchResults: Object.values(store.searchResults) || [],
    itemsToEdit: Object.values(store.itemsToEdit) || []
  }

  return (
    <Component {...derivedProps} {...dispatchers} />
  )
}

export default InventoryUpdateContainer
