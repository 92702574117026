import React from 'react'
import { bool, func } from 'prop-types'
import styled from '@emotion/styled'
import { secondaryDark, eazeBlue } from 'helpers/css-variables'
import { VERSION } from 'helpers/environment'
import EazeLogo from 'microcomponents/logo'
import MenuLinks from './menu-links'
import { keyframes } from 'emotion'

const Navigation = ({
  closeNavSlider,
  isAdmin,
  isDispManager,
  isOpen,
  logout
}) => (
  <Menu isOpen={isOpen}>
    <MenuContainer>
      <LogoContainer>
        <EazeLogo color={eazeBlue} />
        <CloseContainer onClick={closeNavSlider}>&times;</CloseContainer>
      </LogoContainer>
      <LinkContainer>
        <MenuLinks
          closeNavSlider={closeNavSlider}
          isAdmin={isAdmin}
          isDispManager={isDispManager}
        />
      </LinkContainer>
      <LogoutContainer>
        <Logout onClick={logout}>Log out</Logout>
        <Version>v{VERSION}</Version>
      </LogoutContainer>
    </MenuContainer>
  </Menu>
)

const slideInLeft = keyframes`
  0% {
    transform: translateX(-105%);
  }
  100% {
    transform: translateX(0);
  }
`

const Menu = styled.div`
  position: fixed;
  height: 100%;
  width: 300px;
  top: 0;
  bottom: 0;
  color: white;
  z-index: 100;
  background-color: ${secondaryDark};
  animation: ${({ isOpen }) => isOpen ? `${slideInLeft} 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both` : ''};
  transform: translateX(-105%);
`

const MenuContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100%; 
  flex-direction: column;
`

const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.75rem;
  align-items: center;
  margin-bottom: 1.5rem;
  svg {
    width: 7rem; 
    height: 3rem;
  }
`

const LogoutContainer = styled.div`
  width: 100%;
  padding: .75rem 2rem 1.5rem;
  background-color: ${secondaryDark};
`

const Logout = styled.button`
  width: 100%;
  text-transform: uppercase;
  color: #fff; 
  font-size: 1.2rem;
  border: 1px solid #fff;
  letter-spacing: 0.6px;
  display: flex; 
  height: 4rem;
  align-items: center; 
  justify-content: center;
  background-color: transparent;
  border-radius: 0.3rem;
  margin-bottom: 1.2rem;
  cursor: pointer;
`

const LinkContainer = styled.div`
  min-height: 0;
  padding: 0 2rem;
  overflow-y: auto;
  flex: 1 1 auto;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    width: 1rem;
  }

  ::-webkit-scrollbar-thumb {
    background: #666;
  }
`

const CloseContainer = styled.div`
  display: flex; 
  align-items: center;
  justify-content: center;
  border: none;
  color: #FFF;
  width: 2.4rem;
  height: 2.4rem;
  padding: 0;
  margin: 0;
  font-size: 2.6rem;
  cursor: pointer;
`

const Version = styled.div`
  font-size: 1rem; 
  color: #fff;
  text-align: center;
`

Navigation.propTypes = {
  closeNavSlider: func,
  isAdmin: bool,
  isDispManager: bool,
  isOpen: bool,
  logout: func
}

export default Navigation
