export const setOrUnsetItem = (state, payload) => {
  const newState = { ...state.itemsToEdit }
  if (newState[payload.id]) {
    delete newState[payload.id]
  } else {
    newState[payload.id] = payload
  }

  return newState
}

// Convenience to handle meta and error updates
export const setUpdateProgress = ({ state, payload, error, meta = {} }) => {
  if (error) {
    return {
      ...state,
      error: true,
      inventoryError: meta.inventoryError
    }
  } else if (meta.increment) {
    return {
      ...state,
      error: false,
      batchProgress: [state.batchProgress[0] + 1, state.batchProgress[1]],
      inventoryError: ''
    }
  } else if (payload || payload === null) {
    return {
      ...state,
      error: false,
      batchProgress: payload,
      inventoryError: ''
    }
  }
}
