import React, { PureComponent } from 'react'
import styled from '@emotion/styled'

import MinusIcon from './minus-icon'
import TrashIcon from './trash-icon'
import { bool, func } from 'prop-types'

const Decrement = styled.button`
  background-color: transparent;
  border: 0;
  outline: none;
  font-weight: 400;
  text-align: center;
  font-size: 3rem;
  height: 3rem;
  width: 3rem;
  color: transparent;
  cursor: ${({ isDisabled }) => isDisabled ? 'not-allowed' : 'pointer'};
  opacity: ${({ isDisabled }) => isDisabled ? 0.5 : 1};
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  @media(max-width: 767px) {
    font-size: 2rem;
    height: 2rem;
    width: 2rem;
    padding: 0;
  }
`

export default class DecrementComponent extends PureComponent {
  static propTypes = {
    disabled: bool,
    trash: bool,
    onClick: func
  }

  render () {
    const { disabled, trash } = this.props

    return (
      <Decrement
        aria-label='Decrement'
        onClick={this.props.onClick}
        disabled={disabled}
        trash={trash}
        // intentionally not named disabled because we don't want to actually block clicks
        isDisabled={disabled}
      >
        { trash ?
          <TrashIcon />
          : <MinusIcon />
        }
      </Decrement>
    )
  }
}
