import React, { PureComponent } from 'react'
import window from 'global/window'
import styled from '@emotion/styled'
import Colors from 'microcomponents/colors'

import InventoryIcon from 'microcomponents/icon-inventory'
import RemoveIcon from 'microcomponents/icon-remove'
import ROUTES from 'components/router/routes'
import { func, object } from 'prop-types'

export default class CasePopupMenu extends PureComponent {
  static propTypes = {
    catalogItem: object,
    removeAction: func
  }

  goToCatalogEdit = () => {
    window.open(`${ROUTES.CATALOG_PRODUCTS}?catalogFormType=product&catalogFormId=${this.props.catalogItem.id}`, '_blank')
  }

  render () {
    const { removeAction } = this.props

    return (
      <ul style={{ width: '20rem', background: 'white', marginBottom: 0 }}>
        <ListItem onClick={this.goToCatalogEdit}>
          <InventoryIcon size='2rem' color={Colors.primary[1]} />
          <ListItemText style={{ color: Colors.primary[1] }}>EDIT IN CATALOG</ListItemText>
        </ListItem>
        {
          /* <ListItem>
          <StyledLink
            to={`${ROUTES.CATALOG_PRODUCTS}?catalogFormType=product&catalogFormId=${catalogItem.id}`}
            target='_blank'
          >
          EDIT IN INVENTORY
          </StyledLink>
          </ListItem> */
        }
        <ListItem onClick={removeAction}>
          <RemoveIcon size='2rem' color='red' />
          <RemoveWarning>REMOVE</RemoveWarning>
        </ListItem>
      </ul>
    )
  }
}

const ListItem = styled.li`
  padding: 1rem;
  cursor: pointer;
  font-size: 1.3rem;

  display: flex;
  align-items: center;

  &:hover {
    background: ${Colors.primary[5]}
  }
`

const ListItemText = styled.div`
  display: inline-block;
  margin-left: 1rem;
`

const RemoveWarning = styled(ListItemText)`
  color: ${Colors.danger[1]};
`
