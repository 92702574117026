import { array, func, string } from 'prop-types'
import React from 'react'
import { Column } from 'src/microcomponents/flexbox-helpers'
import InputLabel from 'src/microcomponents/input-label'
import { selectContainer } from '../style.js'

const DispensarySelect = ({ setDispensary = () => {}, dispensaries = [], dispensaryId }) => {
  return (
    <Column top={1} bottom={1}>
      <InputLabel content='Dispensary' />
      <div className={selectContainer}>
        <select onChange={setDispensary} value={dispensaryId || 'null'}>
          <option disabled value={'null'}>Select one</option>
          {dispensaries.map((opt, ind) => <option key={ind} value={opt.id}>{opt.name}</option>)}
        </select>
      </div>
    </Column>
  )
}

DispensarySelect.propTypes = {
  setDispensary: func,
  dispensaries: array,
  dispensaryId: string
}

export default DispensarySelect
