import gatewayClient from 'src/gateway-client'
import { setDriverVehicles } from './actions'
import { ERROR, pushNotification, SUCCESS } from 'src/redux/alerts/actions'

export function fetchDriverVehicles (userId) {
  return async (dispatch) => {
    const data = await gatewayClient().getDriverVehicles(userId)
    dispatch(setDriverVehicles(data))
  }
}

export function createDriverVehicle (payload, onfleetWorkerId) {
  return async (dispatch) => {
    const { userId } = payload
    try {
      await gatewayClient().createDriverVehicle(userId, payload)
      if (onfleetWorkerId) {
        await gatewayClient().updateDriverVehicleOnfleet(onfleetWorkerId, payload)
      }

      dispatch(fetchDriverVehicles(userId))
      return dispatch(pushNotification(
        `New vehicle successfully created for driver ${userId}`,
        SUCCESS
      ))
    } catch {
      return dispatch(pushNotification(
        `Error creating a new vehicle for driver ${userId}`,
        ERROR
      ))
    }
  }
}

export function deleteDriverVehicle (userId, vehicleId, onfleetWorkerId) {
  return async (dispatch) => {
    try {
      await gatewayClient().deleteDriverVehicle(userId, vehicleId)
      if (onfleetWorkerId) {
        await gatewayClient().deleteDriverVehicleOnfleet(onfleetWorkerId)
      }
      dispatch(fetchDriverVehicles(userId))

      return dispatch(pushNotification(
        `Driver ${userId} vehicle deleted successfully`,
        SUCCESS
      ))
    } catch {
      return dispatch(pushNotification(
        `Error deleting driver ${userId}'s vehicle`,
        ERROR
      ))
    }
  }
}

export function updateDriverVehicle (userId, vehicleId, payload, onfleetWorkerId) {
  return async (dispatch) => {
    try {
      await gatewayClient().updateDriverVehicle(userId, vehicleId, payload)
      if (onfleetWorkerId) {
        await gatewayClient().updateDriverVehicleOnfleet(onfleetWorkerId, payload)
      }

      dispatch(fetchDriverVehicles(userId))

      return dispatch(pushNotification(
        `Driver ${userId} vehicle updated successfully`,
        SUCCESS
      ))
    } catch (e) {
      return dispatch(pushNotification(
        `Error updating driver ${userId}'s vehicle. Error: ${e}`,
        ERROR
      ))
    }
  }
}
