import { array, string } from 'prop-types'
import React, { PureComponent } from 'react'
import { css } from 'emotion'

const listContainer = css`
  max-width: 120rem;
  margin: auto;
`

export class List extends PureComponent {
  render () {
    const { listClass, children } = this.props

    return (
      <div className={listContainer}>
        <div className={listClass}>
          {children}
        </div>
      </div>
    )
  }
}

List.propTypes = {
  children: array,
  listClass: string
}

export default List
