import { createSelector } from 'reselect'

const getAdultUseTerritoriesStore = state => state.adultUseTerritories

export const getTerritoriesMap = createSelector(
  [getAdultUseTerritoriesStore],
  function ({ territoriesMap }) {
    return territoriesMap
  }
)

export const getIsTerritoryFormOpen = createSelector(
  [getAdultUseTerritoriesStore],
  function ({ isTerritoryFormOpen }) {
    return isTerritoryFormOpen
  }
)
