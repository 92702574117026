export default {
  CLEAR_UNPACKED_CANCELED_ORDERS: 'CLEAR_UNPACKED_CANCELED_ORDERS',
  CLEAR_STORE: 'CLEAR_STORE',
  ERROR: 'ERROR',
  ERROR_ORDER_ID: 'ERROR_ORDER_ID',
  FETCH_CANCELED_ORDERS: 'FETCH_CANCELED_ORDERS',
  MARK_MANIFEST_PRINTED: 'MARK_MANIFEST_PRINTED',
  MARK_ORDER_LOADING: 'MARK_ORDER_LOADING',
  MARK_ORDER_UNPACKED: 'MARK_ORDER_UNPACKED',
  MARK_ORDER_NOT_RECOVERED: 'MARK_ORDER_NOT_RECOVERED',
  MARK_ORDER_PACKED: 'MARK_ORDER_PACKED',
  MARK_ORDER_RECOVERED: 'MARK_ORDER_RECOVERED',
  MARK_ORDERS_PRINTED: 'MARK_ORDERS_PRINTED',
  NEW_CONNECTION: 'NEW_CONNECTION',
  ORDER_CANCELED: 'ORDER_CANCELED',
  ORDER_MODIFIED: 'ORDER_MODIFIED',
  RECEIVE_CANCELED_ORDERS: 'RECEIVE_CANCELED_ORDERS',
  RECEIVE_PENDING_ORDERS: 'RECEIVE_PENDING_ORDERS',
  SET_SELECTED_DEPOT_ID: 'SET_SELECTED_DEPOT_ID'
}
