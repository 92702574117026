import React from 'react'
import { css } from 'emotion'
import { darkDivider } from 'helpers/css-variables'

const divider = css`
  border: 1px solid ${darkDivider};
  width: 100%;
  margin: 1rem 0;
`

export const Divider = () => (
  <div className={divider} />
)

export default Divider
