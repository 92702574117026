import ROUTES from 'src/pages/catalog/routes'

const SORT_OPTIONS = {
  [ROUTES.products.name]: [
    {
      label: 'Most recently updated',
      value: '-updated_at'
    },
    {
      label: 'Name (alphabetical)',
      value: 'title'
    },
    {
      label: 'Name (reverse alphabetical)',
      value: '-title'
    },
    {
      label: 'SRP (Lowest - Highest)',
      value: 'suggested_retail_price'
    },
    {
      label: 'SRP (Highest - Lowest)',
      value: '-suggested_retail_price'
    }
  ],
  [ROUTES.brands.name]: [
    {
      label: 'Most recently updated',
      value: '-updated_at'
    },
    {
      label: 'Name (alphabetical)',
      value: 'name'
    },
    {
      label: 'Name (reverse alphabetical)',
      value: '-name'
    }
  ],
  [ROUTES.groups.name]: [
    {
      label: 'Most recently updated',
      value: '-updated_at'
    },
    {
      label: 'Name (alphabetical)',
      value: 'name'
    },
    {
      label: 'Name (reverse alphabetical)',
      value: '-name'
    }
  ],
  [ROUTES.menus.name]: [
    {
      label: 'Most recently updated',
      value: '-updated_at'
    },
    {
      label: 'Name (alphabetical)',
      value: 'name'
    },
    {
      label: 'Name (reverse alphabetical)',
      value: '-name'
    }
  ],
  [ROUTES.strains.name]: [
    {
      label: 'Most recently updated',
      value: '-updated_at'
    },
    {
      label: 'Name (alphabetical)',
      value: 'name'
    },
    {
      label: 'Name (reverse alphabetical)',
      value: '-name'
    }
  ],
  [ROUTES.types.name]: [
    {
      label: 'Most recently updated',
      value: '-updated_at'
    },
    {
      label: 'Name (alphabetical)',
      value: 'name'
    },
    {
      label: 'Name (reverse alphabetical)',
      value: '-name'
    }
  ],
  [ROUTES.subtypes.name]: [
    {
      label: 'Most recently updated',
      value: '-updated_at'
    },
    {
      label: 'Name (alphabetical)',
      value: 'name'
    },
    {
      label: 'Name (reverse alphabetical)',
      value: '-name'
    }
  ]
}

export default SORT_OPTIONS
