import api from 'api'

import { DELIVERY_TYPES } from 'helpers/drivers'

export function fetchDriverOrders (params) {
  return async () => {
    const { err, data } = await api.getDriverOrders(params)
    if (err) return { err, data }

    const filteredItems = data.items.filter(function (item) {
      const { notReAssignable, deliveryType } = item
      // if an order is deliveryType batch but notReAssignable is false, we want to filter it out
      const shouldFilterBatch = notReAssignable && deliveryType === DELIVERY_TYPES.BATCH
      // if an order is deliveryType dynamic we do not want to filter it out
      const isDynamic = deliveryType === DELIVERY_TYPES.DYNAMIC

      return shouldFilterBatch || isDynamic
    })

    data.items = filteredItems

    return { err, data }
  }
}

export function fetchDriverPendingOrders (driverId) {
  return async () => {
    return api.getDriverPendingOrders({ id: driverId })
  }
}
