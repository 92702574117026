import React, { PureComponent } from 'react'
import { array, bool, func, object, shape, string } from 'prop-types'
import { withRouter } from 'react-router-dom'
import window from 'global/window'
import qs from 'query-string'

import TagForm from './tag-form'
import SelectProducts from 'src/pages/promos/tags/edit/select-products'
import PreviewTagsDeals from 'src/pages/promos/tags/edit/preview'
import { AVAILABLE_STATES } from 'src/helpers/constants'

import { tagContainer } from './style.js'

const TAG_FORM = Symbol('tag form')
const PRODUCTS_FORM = Symbol('products form')
const PREVIEW_FORM = Symbol('review form')

const getTagItemsPayload = (formData, selectedProducts) => ({
  tagId: formData.id,
  itemIds: Object.keys(selectedProducts)
})

class EditTag extends PureComponent {
  static propTypes = {
    addTagItems: func,
    brandsMap: object,
    closeConflictModal: func.isRequired,
    conflictsMap: object,
    createTag: func,
    deleteTag: func,
    depots: array,
    depotsMap: object,
    filterType: string,
    handleStateChange: func,
    groupIds: array,
    groupsMap: object,
    isTagFormOpen: bool,
    location: shape({
      search: string
    }),
    onHideForm: func,
    onSetCloseFormMessage: func,
    openConflictModal: func.isRequired,
    productsMap: object,
    pushNotification: func,
    selectedState: string,
    setFilterString: func,
    setFilterType: func,
    setTagConflicts: func,
    showTagForm: func,
    speciesMap: object,
    tagItems: array,
    tagsMap: object,
    tagTypes: array,
    updateTag: func
  }

  state = {
    formData: undefined,
    formState: TAG_FORM,
    isEdit: false,
    selectedDepots: {},
    selectedProducts: {}
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps (nextProps) {
  /* eslint-enable camelcase */
    const {
      isTagFormOpen: wasTagFormOpen,
      location: prevLocation,
      tagItems: prevTagItems
    } = this.props
    const {
      isTagFormOpen,
      location: newLocation,
      tagItems: newTagItems
    } = nextProps
    const { tagId } = qs.parse(prevLocation.search)
    const { tagId: nextTagId } = qs.parse(newLocation.search)

    // Process new tag items (e.g. because we fetched for them on form opening)
    if (newTagItems !== prevTagItems && nextTagId) {
      this.handleFormEdit(nextTagId)
    }
    // if the tagId has chagned, and the tag form
    // isn't open query for tag data then open the form
    if (nextTagId && nextTagId !== tagId && !wasTagFormOpen) {
      this.handleFormEdit(nextTagId)
    }

    // if we close the form, ensure we reset it
    if (wasTagFormOpen && !isTagFormOpen) this.resetForm()
  }

  resetForm = () => {
    this.setState({
      formState: TAG_FORM,
      formData: undefined,
      isEdit: false,
      selectedProducts: {},
      selectedDepots: {}
    })
  }

  handleFormEdit = (tagId) => {
    const { showTagForm, tagsMap } = this.props
    const tag = Object.assign({ items: [] }, tagsMap[tagId] || {})
    const selectedProducts = {}
    const selectedDepots = {}

    tag.items.forEach(function ({ id }) {
      selectedProducts[id] = true
    })

    tag.areaIds.forEach(function (id) {
      selectedDepots[id] = true
    })

    const newState = {
      formData: tag,
      formState: PREVIEW_FORM,
      isEdit: true,
      selectedDepots,
      selectedProducts
    }
    this.setState(newState, showTagForm)
  }

  onSelectDepots = (selectedDepots) => {
    this.setState({ selectedDepots })
  }

  // Selected Products Form
  onSubmitSelectProducts = async (selectedProducts) => {
    const { addTagItems } = this.props
    const { formData } = this.state

    const tagItemsObject = getTagItemsPayload(formData, selectedProducts)

    const { err, data } = await addTagItems(tagItemsObject)
    this.setState({ selectedProducts })
    this.handleProductLinkResponse(err, data)
  }

  handleProductLinkResponse = (err, data) => {
    const { pushNotification } = this.props
    if (err && err.statusCode === 409) {
      this.receiveConflicts(JSON.parse(err.message))
      pushNotification('There was a tag conflict with some items')
    } else {
      this.setState({ formState: PREVIEW_FORM })
    }
  }

  receiveConflicts (conflictData) {
    const conflictsMappedByItemId = {}

    conflictData.forEach((item) => {
      conflictsMappedByItemId[item.item_id] = item
    })

    this.props.setTagConflicts(conflictsMappedByItemId)
  }

  onSubmitTagForm = (formData) => {
    const { createTag, onSetCloseFormMessage, updateTag } = this.props

    onSetCloseFormMessage('')

    if (formData.id) {
      updateTag(formData, this.afterSubmitTagForm)
    } else {
      createTag(formData, this.afterSubmitTagForm)
    }
  }

  afterSubmitTagForm = (formData) => {
    this.setState({ formData, formState: PRODUCTS_FORM })
  }

  onCancelSelectProducts = () => {
    this.setState({ formState: TAG_FORM })
  }

  onCancelPreview = () => {
    this.setState({ formState: TAG_FORM })
  }

  onDeleteTag = () => {
    const { formData: { id } } = this.state
    const { deleteTag, onHideForm } = this.props
    const confirmMessage = 'Are you sure you want to delete this tag?'

    if (window.confirm(confirmMessage)) {
      deleteTag(id)
      onHideForm()
    }
  }

  render () {
    const {
      brandsMap,
      depots,
      depotsMap,
      filterType,
      handleStateChange,
      groupIds,
      groupsMap,
      isTagFormOpen,
      onHideForm,
      onSetCloseFormMessage,
      productsMap,
      selectedState,
      setFilterString,
      setFilterType,
      speciesMap,
      tagItems,
      tagTypes,
      conflictsMap,
      tagsMap
    } = this.props

    const {
      formData,
      formState,
      isEdit,
      selectedDepots,
      selectedProducts
    } = this.state

    const stateId = (formData && formData.state_id) || selectedState

    return (
      <div className={tagContainer}>
        {isTagFormOpen && formState === TAG_FORM &&
          <TagForm
            depots={depots}
            formData={formData}
            isEdit={isEdit}
            isTagFormOpen={isTagFormOpen}
            onCancelTagForm={onHideForm}
            onSelectDepots={this.onSelectDepots}
            onSetCloseFormMessage={onSetCloseFormMessage}
            onSubmitForm={this.onSubmitTagForm}
            handleStateChange={handleStateChange}
            availableStates={AVAILABLE_STATES}
            selectedState={stateId}
            selectedDepots={selectedDepots}
            tagItems={tagItems}
            tagTypes={tagTypes}
          />}
        {isTagFormOpen && formState === PRODUCTS_FORM &&
          <SelectProducts
            brandsMap={brandsMap}
            filterType={filterType}
            groupsMap={groupsMap}
            groupIds={groupIds}
            onCancelSelectProducts={this.onCancelSelectProducts}
            onSubmitSelectProducts={this.onSubmitSelectProducts}
            productsMap={productsMap}
            setFilterString={setFilterString}
            setFilterType={setFilterType}
            selectedState={stateId}
            selectedProducts={selectedProducts}
            conflictsMap={conflictsMap}
            openConflictModal={this.props.openConflictModal}
            closeConflictModal={this.props.closeConflictModal}
          />}
        {isTagFormOpen && formState === PREVIEW_FORM &&
          <PreviewTagsDeals
            brandsMap={brandsMap}
            depotsMap={depotsMap}
            formData={formData}
            isEdit={isEdit}
            onDeleteTag={this.onDeleteTag}
            onCloseForm={onHideForm}
            onCancelPreview={this.onCancelPreview}
            productsMap={productsMap}
            selectedState={stateId}
            selectedDepots={selectedDepots}
            selectedProducts={selectedProducts}
            speciesMap={speciesMap}
            tagsMap={tagsMap}
          />}
      </div>
    )
  }
}

export default withRouter(EditTag)
