import { string } from 'prop-types'
import React from 'react'

export default function DownloadIcon ({ title = 'Download' }) {
  return (
    <svg
      width='36px'
      height='36px'
      viewBox='0 0 36 36'
      version='1.1'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      aria-labelledby='download-icon-title'
    >
      <title id='download-icon-title'>{title}</title>
      <g
        transform='translate(-354.000000, -804.000000) translate(354.000000, 804.000000)'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle fill='#00AAE7' cx={18} cy={18} r={18} />
        <path
          d='M17.98 19.795a.857.857 0 0 1-.012 0 .777.777 0 0 1-.558-.23L14.846 17a.774.774 0 0 1 0-1.103.774.774 0 0 1 1.103 0l1.256 1.257V8.769c0-.436.333-.769.77-.769.435 0 .769.333.769.77v8.384L20 15.897a.774.774 0 0 1 1.103 0 .774.774 0 0 1 0 1.103l-2.565 2.564a.777.777 0 0 1-.557.23zm5.969-7.462a8.913 8.913 0 0 1 3 6.693c0 4.948-4.026 8.974-8.975 8.974C13.026 28 9 23.974 9 19.026a8.913 8.913 0 0 1 3-6.693.8.8 0 0 1 .538-.23c.436 0 .77.333.77.769a.73.73 0 0 1-.282.59 7.493 7.493 0 0 0-2.488 5.564 7.441 7.441 0 0 0 7.436 7.436 7.441 7.441 0 0 0 7.436-7.436 7.402 7.402 0 0 0-2.487-5.564.73.73 0 0 1-.282-.59c0-.436.333-.77.77-.77.204 0 .41.077.538.231z'
          fill='#FFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}

DownloadIcon.propTypes = {
  title: string
}
