import { createSelector } from 'reselect'
import { isValidInput } from 'src/helpers/form-validators'

export const getCatalogType = (state) => state.catalogType

export const cantCreateType = createSelector(
  [getCatalogType],
  (catalogType) => {
    const keys = Object.keys(catalogType)

    return keys.some(k => {
      // block of keys that are not required
      if (k === 'id') return false
      if (k === 'description') return false

      const value = catalogType[k]

      return !isValidInput(value)
    })
  }
)
