import React from 'react'

export default function RefreshIcon ({ onClick, color = 'white', size = '3em', right = '' }) {
  const buttonStyle = {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    marginRight: right
  }

  return (
    <button style={buttonStyle} onClick={onClick}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 100 100'>
        <path d='M45.898 84.5c-19.199-2.602-32.598-20.301-30-39.5 2.602-19.199 20.398-32.602 39.5-29.898a34.81 34.81 0 0 1 17.301 7.5L58.898 34.5l37.898 9.2-1.8-40L82.197 14.6C75.398 8.5 66.8 4.301 57.1 3 31.3-.602 7.397 17.5 3.8 43.3.197 69.101 18.3 93.101 44.196 96.601 62.1 99.101 78.998 91.2 88.998 77.5L79.3 70c-7.3 10.398-20 16.398-33.402 14.5z'
          fill={color}
          fillRule='nonzero'
        />
      </svg>
    </button>
  )
}
