import React, { PureComponent } from 'react'
import {
  arrayOf,
  bool,
  func,
  number,
  object,
  oneOfType,
  string
} from 'prop-types'
import styled from '@emotion/styled'
import Button, { DANGER, PRIMARY } from 'components/button'
import { ArrowIcon, VerticalMenuIcon } from 'src/microcomponents/icons'
import InputLabel from 'src/microcomponents/input-label'
import { tertiaryDark } from 'src/helpers/css-variables'
import VehicleForm from './vehicle-form'
import ManageVehicles from './manage-vehicles'
import VehiclesMenu from './vehicles-menu'
import { DRIVER_MODE_OFF_KEY } from 'helpers/drivers'

class DriverMETRCInfo extends PureComponent {
  static propTypes = {
    closeDrawer: func,
    createDriverVehicle: func.isRequired,
    currentVehicle: object,
    deleteDriverVehicle: func.isRequired,
    depotId: number.isRequired,
    depots: object.isRequired,
    dispensaryId: number,
    driverId: oneOfType([number, string]),
    driverMode: number,
    onfleetWorkerId: string,
    showVehicleFunctionality: bool,
    updateDriverDepot: func.isRequired,
    updateDriverVehicle: func.isRequired,
    vehicles: arrayOf(object)
  }

  static defaultProps = {
    currentVehicle: {
      id: null
    },
    showVehicleFunctionality: false,
    vehicles: []
  }

  state = {
    showVehicleForm: false,
    updateVehicle: null,
    loading: false,
    managingVehicles: false,
    newDepotId: null,
    newVehicleId: null,
    vehiclesMenuOpen: false
  }

  closeDrawer = () => {
    const { closeDrawer } = this.props
    this.setState(
      {
        showVehicleForm: false,
        updateVehicle: {},
        loading: false,
        managingVehicles: false,
        newDepotId: null,
        newVehicleId: null
      },
      closeDrawer
    )
  }

  toggleBooleanState = stateAttr => () => {
    this.setState(state => ({ [stateAttr]: !state[stateAttr] }))
  }

  toggleVehicleForm = (vehicle) => {
    this.setState({
      showVehicleForm: !this.state.showVehicleForm,
      updateVehicle: vehicle || {}
    })
  }

  depotIdHasBeenChanged () {
    const { newDepotId } = this.state
    const { depotId } = this.props
    return newDepotId && newDepotId !== depotId
  }

  setNewDepotId = ({ target: { value: newDepotId } }) => {
    newDepotId = parseInt(newDepotId)
    this.setState({ newDepotId })
  }

  saveNewDepotId = async () => {
    const { dispensaryId, driverId, updateDriverDepot } = this.props
    const { newDepotId } = this.state
    return updateDriverDepot(driverId, newDepotId, dispensaryId)
  }

  renderDepotSelector () {
    const { depotId, depots, driverMode } = this.props

    const {
      state: { newDepotId },
      setNewDepotId
    } = this

    const selectedDepotId =
      newDepotId || newDepotId === 0 ? newDepotId : depotId

    // only allow depot switching if driver mode is OFF
    const shouldEnableSelector = Boolean(driverMode !== DRIVER_MODE_OFF_KEY)
    const DepotAssignmentHelpMessage =
      'Set driver status to OFF before changing depot assignment'

    return (
      <>
        <Selector
          className='DepotSelector'
          shouldEnableSelector={shouldEnableSelector}
        >
          <select
            value={selectedDepotId}
            onChange={setNewDepotId}
            disabled={shouldEnableSelector}
          >
            {Object.values(depots).map(({ id, name }) => {
              return (
                <option value={id} key={id}>
                  {name}
                </option>
              )
            })}
          </select>
          <SelectorArrowIconWrapper>
            <ArrowIcon height='1.4rem' width='1.4rem' color='white' />
          </SelectorArrowIconWrapper>
        </Selector>
        {shouldEnableSelector && (
          <DepotAssignmentHelpText>
            {DepotAssignmentHelpMessage}
          </DepotAssignmentHelpText>
        )}
      </>
    )
  }

  vehicleIdHasBeenChanged () {
    const { newVehicleId } = this.state
    const {
      currentVehicle: { id: currentVehicleId }
    } = this.props
    return newVehicleId && newVehicleId !== currentVehicleId
  }

  setNewVehicleId = ({ target: { value: newVehicleId } }) => {
    this.setState({ newVehicleId })
  }

  saveNewVehicleId = async () => {
    const { driverId, updateDriverVehicle, onfleetWorkerId, vehicles } = this.props
    const { newVehicleId } = this.state
    const selectedVehicle = vehicles.find(vehicle => vehicle.id === newVehicleId)
    return updateDriverVehicle(driverId, newVehicleId, { ...selectedVehicle, isCurrent: true }, onfleetWorkerId)
  }

  renderVehicleSelector () {
    const {
      currentVehicle: { id: currentVehicleId },
      vehicles = []
    } = this.props

    const {
      state: { newVehicleId },
      setNewVehicleId
    } = this

    const selectedVehicleId = newVehicleId || currentVehicleId
    const noVehicles = vehicles.length === 0
    if (vehicles.length === 1 && currentVehicleId == null) {
      this.setState({ newVehicleId: vehicles[0].vehicleId })
    }

    return (
      <Selector className='VehicleSelector'>
        <select value={selectedVehicleId || ''} onChange={setNewVehicleId} disabled={noVehicles}>
          {
            noVehicles
              ? (<option>No vehicles</option>)
              : vehicles.map(({ id, vehicleMake, vehicleModel, licensePlateNumber }) => {
                return (
                  <option value={id} key={id}>
                    {`${vehicleMake} ${vehicleModel}, ${licensePlateNumber}`}
                  </option>
                )
              })
          }
        </select>
        <SelectorArrowIconWrapper>
          <ArrowIcon height='1.4rem' width='1.4rem' color='white' />
        </SelectorArrowIconWrapper>
      </Selector>
    )
  }

  unsavedChangesExist = () => {
    return this.depotIdHasBeenChanged() || this.vehicleIdHasBeenChanged()
  }

  saveChanges = async () => {
    if (this.unsavedChangesExist) {
      this.setState({ loading: true })
      if (this.depotIdHasBeenChanged()) await this.saveNewDepotId()
      if (this.vehicleIdHasBeenChanged()) await this.saveNewVehicleId()
      this.setState({ loading: false })
    }
  }

  render () {
    const {
      createDriverVehicle,
      updateDriverVehicle,
      currentVehicle: { id: currentVehicleId },
      deleteDriverVehicle,
      driverId,
      onfleetWorkerId,
      showVehicleFunctionality,
      vehicles
    } = this.props
    const {
      loading,
      updateVehicle,
      managingVehicles,
      vehiclesMenuOpen,
      showVehicleForm
    } = this.state

    if (showVehicleForm) {
      return (
        <VehicleForm
          driverId={driverId}
          closeDrawer={this.closeDrawer}
          closeForm={this.toggleVehicleForm}
          createDriverVehicle={createDriverVehicle}
          onfleetWorkerId={onfleetWorkerId}
          updateDriverVehicle={updateDriverVehicle}
          vehicle={updateVehicle}
        />
      )
    }

    if (managingVehicles) {
      return (
        <ManageVehicles
          closeDrawer={this.closeDrawer}
          closeView={this.toggleBooleanState('managingVehicles')}
          currentVehicleId={currentVehicleId}
          updateDriverVehicle={updateDriverVehicle}
          deleteDriverVehicle={deleteDriverVehicle}
          driverId={driverId}
          onfleetWorkerId={onfleetWorkerId}
          vehicles={vehicles}
          toggleVehicleForm={this.toggleVehicleForm}
        />
      )
    }

    return (
      <Container className='DriverMETRCInfo'>
        <TitleRow className='TitleRow'>
          <Title className='Title'>Edit Profile</Title>
        </TitleRow>

        <SubtitleRow className='SubtitleRow'>
          <Subtitle className='Subtitle'>Depot Assignment</Subtitle>
        </SubtitleRow>
        <InputLabel content='Depot' />
        {this.renderDepotSelector()}

        {showVehicleFunctionality && (
          <>
            <SubtitleRow className='SubtitleRow'>
              <Subtitle className='Subtitle'>Vehicle Details</Subtitle>
              <SubtitleAction
                className='SubtitleAction'
                onClick={this.toggleBooleanState('vehiclesMenuOpen')}
              >
                <VerticalMenuIcon />
                {vehiclesMenuOpen && (
                  <VehiclesMenu
                    closeMenu={this.toggleBooleanState('vehiclesMenuOpen')}
                    toggleManageVehiclesView={this.toggleBooleanState(
                      'managingVehicles'
                    )}
                    toggleVehicleForm={this.toggleVehicleForm}
                  />
                )}
              </SubtitleAction>
            </SubtitleRow>
            <InputLabel content='Vehicle' />
            {this.renderVehicleSelector()}
          </>
        )}

        <BottomControls className='BottomControls'>
          <Button
            type={PRIMARY}
            disabled={loading || !this.unsavedChangesExist()}
            loading={loading}
            onClick={this.saveChanges}
          >
            UPDATE
          </Button>

          <Button
            type={DANGER}
            disabled={loading}
            loading={loading}
            inverted
            onClick={this.closeDrawer}
            componentStyle={{
              backgroundColor: 'rgba(255, 255, 255, 0)'
            }}
          >
            CANCEL
          </Button>
        </BottomControls>
      </Container>
    )
  }
}

const Container = styled.div`
  padding: 3.6rem 2.4rem;
  color: #fff;
  width: 58rem;
  height: 100%;
  overflow-y: auto;
`

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4.8rem;
`

const Title = styled.h1`
  margin: 0;
  letter-spacing: 0.1rem;
`

const SubtitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.4rem;
`

const Subtitle = styled.h2`
  letter-spacing: 0.04rem;
  margin: 0;
  line-height: 2.4rem;
`

const SubtitleAction = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`

const Selector = styled.div`
  width: 100%;
  margin-top: 0.4rem;
  margin-bottom: 3.6rem;
  position: relative;
  background-color: ${tertiaryDark};

  select {
    width: 100%;
    -webkit-appearance: none;
    height: 4.8rem;
    padding-left: 1.2rem;
    font-size: 1.6rem;
    color: white;
    border: 0;
    border-radius: 0.2rem;
    cursor: pointer;
    background-color: ${tertiaryDark};

    :disabled {
      cursor: not-allowed;
      opacity: 0.2;
    }
  }
`

const SelectorArrowIconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  right: 0;
  top: 0;
  padding-right: 1.2rem;
  pointer-events: none;
`

const DepotAssignmentHelpText = styled.p`
  margin-top: -2.4rem;
  margin-bottom: 2.4rem;
`

const BottomControls = styled.div`
  margin-top: 4.8rem;

  button:first-child {
    margin-bottom: 1.2rem;
  }
`

export default DriverMETRCInfo
