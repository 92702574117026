import t from './actionTypes'

export function setDepots (depots) {
  return {
    type: t.SET_DEPOTS,
    payload: depots
  }
}

export function setSelectedDepotId (id) {
  return {
    type: t.SET_SELECTED_DEPOT_ID,
    payload: id
  }
}
