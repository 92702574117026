import t from './actionTypes'

const defaultState = {
  notifications: []
}

const alerts = (state = defaultState, action) => {
  switch (action.type) {
    case t.PUSH_NOTIFICATION:
      return {
        notifications: [...state.notifications, action.notification]
      }

    case t.POP_NOTIFICATION:
      return {
        notifications: [
          ...state.notifications.slice(0, action.idx),
          ...state.notifications.slice(action.idx + 1)
        ]
      }

    default:
      return state
  }
}

export default alerts
