import { css } from 'emotion'
import { tertiaryDark } from 'helpers/css-variables'

export const container = css`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 1rem;
  background-color: ${tertiaryDark};
`

export const containerEmpty = css`
  ${container}
  height: 11rem;
`

export const emptyProductsGroup = css`
  height: 11rem;
  display: flex;  
  align-items: center;
  justify-content: center;
`

export const productCardContainer = css`
  display: block;
  border: none;
  background-color: transparent;
  width: 100%;
  padding: 0;
  cursor: pointer;

  margin: 1rem 0;
`
