import t from './actionTypes'

const defaultState = {}

const depotsMap = (state = defaultState, { type, payload }) => {
  switch (type) {
    case t.SET_DEPOTS_MAP:
      return {
        ...state,
        ...payload
      }

    case t.CLEAR_DEPOTS_MAP:
      return defaultState

    default:
      return state
  }
}

export default depotsMap
