import { connect } from 'react-redux'
import { getCanceledOrdersNotRecovered, getOrdersIncoming, getOrdersNeedManifestPrinted } from 'src/redux/hub/selectors'
import { getAreCanceledOrdersMuted, getAreIncomingOrdersMuted, getAreManifestsToPrintMuted } from 'src/redux/sound-alerts/selectors'
import SoundNotifier from './component'

const mapStateToProps = (state, props) => {
  return {
    canceledOrdersNotRecovered: getCanceledOrdersNotRecovered(state),
    areCanceledOrdersMuted: getAreCanceledOrdersMuted(state),
    areIncomingOrdersMuted: getAreIncomingOrdersMuted(state),
    areManifestsToPrintMuted: getAreManifestsToPrintMuted(state),
    manifestNeedsPrintedOrders: getOrdersNeedManifestPrinted(state),
    ordersIncoming: getOrdersIncoming(state)
  }
}

export default connect(mapStateToProps, null)(SoundNotifier)
