/**
 * this should be the only place we require '@eaze/eaze-api-client' and construct it.
 * This avoids a lot of duplicate code having the construct the API object in every file that uses
 * it. This should also make a it slightly easier to mock :)
 */

import client from '@eaze/eaze-api-client'
import store from 'src/redux/store'
import { pushNotification } from 'src/redux/alerts/actions'
import { ERROR_USER_ALREADY_DRIVER } from 'helpers/drivers'
import { API_BASE_URL } from 'helpers/environment'

const arrayOfErrorsToNotDisplay = [
  ERROR_USER_ALREADY_DRIVER
]

function errorHandler (err) {
  // existing API interface prevents us from controlling when errors
  // are pushed to the passed in errorHandler
  // mc3 was built around having all errors displayed to the user
  // however, now, we need to skip this functionality...for one error... :(
  // TODO: Remove this when the driver promotion and update endpoints are consolidated
  if (!arrayOfErrorsToNotDisplay.includes(err.message)) {
    store.dispatch(pushNotification(err.message || err))
  }
}

export default client(API_BASE_URL, errorHandler).v2
