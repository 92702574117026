import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { array, bool, func, object, string } from 'prop-types'
import { connect } from 'react-redux'
import sortOn from 'sort-on'

import { fetchAllDispensaries } from 'src/redux/dispensaries/actions'
import { getActiveDepots, getActiveDepotsGroupedByState } from 'src/redux/dispensaries/selectors'
import { getIsAdmin } from 'src/redux/permissions/selectors'
import { setSelectedDepotId } from 'src/redux/depots-shared-redux/actions'
import depotImg from 'src/assets/depot-icon.svg'
import List from 'src/pages/catalog/list/list-component'
import Screen from 'src/components/screen/container'
import McCard from 'src/components/mc-card'
import { Title } from 'components/typography'
import { letterSpacingLabel, lightGray, secondaryDark, white } from 'src/helpers/css-variables'
import { brandGrid, overflowContainer } from 'src/pages/catalog/style.js'

const mapStateToProps = (state, props) => {
  return {
    depots: getActiveDepots(state),
    depotsByState: getActiveDepotsGroupedByState(state),
    isAdmin: getIsAdmin(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllDispensaries: () => dispatch(fetchAllDispensaries()),
    setSelectedDepotId: (id) => dispatch(setSelectedDepotId(id))
  }
}

class SelectContainer extends PureComponent {
  static propTypes = {
    depots: array,
    depotsByState: object,
    fetchAllDispensaries: func,
    isAdmin: bool
  }

  componentDidMount () {
    this.props.fetchAllDispensaries()
  }

  renderAdminView = (sortedStates) => {
    const { depotsByState } = this.props

    return sortedStates.map((state, idx) =>
      <DepotsByState
        depots={depotsByState[state]}
        key={idx}
        state={state}
      />
    )
  }

  render () {
    const { depots, depotsByState, isAdmin } = this.props
    const sortedDepots = sortOn(depots, 'name')
    const sortedStates = Object.keys(depotsByState).sort()
    return (
      <Screen>
        <Title
          color={white}
          componentStyle={{
            maxWidth: '120rem',
            margin: 'auto',
            marginTop: '3rem'
          }}
        >
          Depots
        </Title>
        {isAdmin && <SubNav stateList={sortedStates} />}
        <div className={overflowContainer} style={{ marginTop: '3rem' }}>
          {
            isAdmin
              ? (this.renderAdminView(sortedStates))
              : (<List listClass={brandGrid}>
                {sortedDepots.map(depot => {
                  return (
                    <McCard
                      color='blue'
                      description={depot.depotAddress.city}
                      id={depot.id}
                      key={depot.id}
                      name={depot.name}
                      svg={depotImg}
                      url={`/dashboard/${depot.id}/deliveries`}
                    />
                  )
                })}
              </List>)
          }
        </div>
      </Screen>
    )
  }
}

const SubNav = ({ stateList }) => (
  <Section>
    <StateOL>
      {stateList.map((state, idx) => (
        <StateLI key={state}>
          <StateLink
            href={`/dashboard#${state}`}
            key={idx}
            onClick={(e) => { e.stopPropagation() }}
          >
            {state}
          </StateLink>
        </StateLI>
      ))}
    </StateOL>
  </Section>
)

SubNav.propTypes = {
  stateList: array
}

const DepotsByState = ({ state, depots }) => (
  <Section>
    <SectionTitle id={state}>{state}</SectionTitle>
    <List listClass={brandGrid}>
      {sortOn(depots, 'name').map((depot) => (
        <McCard
          color='blue'
          description={depot.depotAddress.city}
          id={depot.id}
          key={depot.id}
          name={depot.name}
          svg={depotImg}
          url={`/dashboard/${depot.id}/deliveries`}
        />
      ))}
    </List>
  </Section>
)

DepotsByState.propTypes = {
  depots: array,
  state: string
}

const Section = styled.section`
  max-width: 120rem;
  margin: auto;
`

// can't pass id attribute to components/typography SectionTitle so need to style own
const SectionTitle = styled.h2`
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 800;
  font-family: 'Open Sans', Helvetica;
  letter-spacing: ${letterSpacingLabel};
  color: ${white};
`

const StateOL = styled.ol`
  padding: 1rem 2rem;
  list-style-type: none;
  background: ${secondaryDark};
`

const StateLI = styled.li`
  display: inline-block;
  padding-right: 2rem;
`

const StateLink = styled.a`
  text-decoration: none;
  color: ${lightGray};
  text-transform: uppercase;

  :hover {
    text-decoration: underline;
  }
`

export default connect(mapStateToProps, mapDispatchToProps)(SelectContainer)
