import { createSelector } from 'reselect'
import { getSortedCatalogProducts } from 'src/redux/catalog/selectors'

const getGroupItemState = state => state.groupItem

export const getProductsInGroup = createSelector(
  [getGroupItemState],
  (groupItem) => groupItem.items
)

export const getGroupStateId = createSelector(
  [getGroupItemState],
  (groupItem) => groupItem.state_id
)

// This method will return the list of relevant products available to be placed in the group.
// Namely, those not already in the group
export const getProductsRelevantToGroup = createSelector(
  [getSortedCatalogProducts, getProductsInGroup],
  (products, groupProducts) => {
    const groupProductIds = groupProducts.map((groupProduct) => groupProduct.id)
    // iterate over shallow copy of products so we don't mutate the store
    const deDupedProducts = [...products].filter((product) => {
      // its possible to have a product be undefined for some reason, so lets guard against it
      if (!product) return false

      return groupProductIds.indexOf(product.id) === -1
    })

    return deDupedProducts
  }
)
