import t from './actionTypes'
import driverTypes from 'src/redux/drivers/actionTypes'

const defaultState = {
  list: [],
  map: {}
}

const dispensaries = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_DISPENSARIES:
      return {
        ...state,
        list: action.dispensaries
      }

    case t.SET_DISPENSARIES_MAP:
      return {
        ...state,
        map: action.dispensariesMap
      }

    case driverTypes.CLEAR_DISPENSARIES_STORE:
      return defaultState

    default:
      return state
  }
}

export default dispensaries
