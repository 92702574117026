import { SET_CATALOG_SUBTYPE } from './actionTypes'

const defaultState = {
  id: null,
  name: '',
  description: '',
  parentType: '',
  slug: ''
}

const catalogSubtype = (state = defaultState, action) => {
  switch (action.type) {
    case SET_CATALOG_SUBTYPE:
      return {
        ...state,
        ...action.subtype
      }

    default:
      return state
  }
}

export default catalogSubtype
