import t from './actionTypes'
import api from 'api'

import history from 'components/router/history'
import { pushNotification, SUCCESS } from 'src/redux/alerts/actions'
import { requestAdultUseTerritories, receiveAdultUseTerritories } from 'src/redux/loading/actions'
import { formatTerritoriesMap } from 'src/redux/territory-management/helpers'
import { getTerritoriesMap } from 'src/redux/territory-management/selectors'

export function fetchAdultUseTerritories () {
  return async function (dispatch) {
    dispatch(requestAdultUseTerritories())

    const { err, data } = await api.getAdultUseTerritories({})
    dispatch(receiveAdultUseTerritories())
    if (err) return

    const territoriesMap = formatTerritoriesMap(data)

    dispatch(setTerritoriesMap(territoriesMap))
  }
}

export function setTerritoriesMap (territoriesMap) {
  return {
    type: t.SET_ADULT_USE_TERRITORIES_MAP,
    payload: territoriesMap
  }
}

export function showTerritoryForm () {
  return {
    type: t.SHOW_ADULT_USE_TERRITORIES_FORM
  }
}

export function hideTerritoryForm () {
  return function (dispatch) {
    dispatch({ type: t.HIDE_ADULT_USE_TERRITORIES_FORM })
  }
}

export function createTerritory (formData) {
  return async function (dispatch) {
    const body = transformFromTerritory(formData)

    const { err, data } = await api.createAdultUseTerritory(body)
    if (err) return

    const { city, zipcode } = formData
    const territoryLabel = city || zipcode
    dispatch(pushNotification(`Successfully created territory for ${territoryLabel}`, SUCCESS))
    dispatch(hideTerritoryForm())
    dispatch(addTerritory(data))
  }
}

export function updateTerritory (formData) {
  return async function (dispatch) {
    const body = transformFromTerritory(formData)

    const { err, data } = await api.updateAdultUseTerritory(body)
    if (err) return

    const { city, zipcode } = formData
    const territoryLabel = city || zipcode
    dispatch(pushNotification(`Successfully updated territory for ${territoryLabel}`, SUCCESS))
    dispatch(hideTerritoryForm())
    history.push({ search: '' })
    dispatch(replaceTerritory(data))
  }
}

export function deleteTerritory (id, label) {
  return async function (dispatch) {
    const { err } = await api.deleteAdultUseTerritory({ id })
    if (err) return

    dispatch(pushNotification(`Successfully deleted territory for ${label}`, SUCCESS))
    dispatch(hideTerritoryForm())
    history.push({ search: '' })
    dispatch(removeTerritory(id))
  }
}

export function addTerritory (territory) {
  return function (dispatch, getState) {
    const state = getState()
    const territoriesMap = getTerritoriesMap(state)
    const territoriesMapCopy = { ...territoriesMap }

    territoriesMapCopy[territory.id] = territory

    dispatch(setTerritoriesMap(territoriesMapCopy))
  }
}

export function replaceTerritory (territory) {
  return function (dispatch, getState) {
    const state = getState()
    const territoriesMap = getTerritoriesMap(state)
    const territoriesMapCopy = { ...territoriesMap }

    territoriesMapCopy[territory.id] = territory

    dispatch(setTerritoriesMap(territoriesMapCopy))
  }
}

export function removeTerritory (id) {
  return function (dispatch, getState) {
    const state = getState()
    const territoriesMap = getTerritoriesMap(state)
    const territoriesMapCopy = { ...territoriesMap }

    delete territoriesMapCopy[id]

    dispatch(setTerritoriesMap(territoriesMapCopy))
  }
}

// parser to format keys from the redux store to match what the response wants
export function transformFromTerritory ({ city, zipcode, id, comments, enabled, identityScanRequired, state, selfieRequired, usaOnly }) {
  const territoryData = {
    id,
    city,
    comments,
    enabled,
    zipcode,
    identityScanRequired,
    selfieRequired,
    usaOnly
  }

  // only add state if territory has a city and no zipcode
  if (city && !zipcode) {
    territoryData.state = state
  }

  return territoryData
}
