import { connect } from 'react-redux'
import { search, setQuery, setUsers } from 'src/redux/users/actions'

import Component from './'

const mapStateToProps = (state, props) => {
  const { users: { list, query } } = state

  return {
    list,
    query
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    search: () => dispatch(search()),
    setQuery: query => dispatch(setQuery(query)),
    setUsers: (users) => dispatch(setUsers(users))
  }
}

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component)

export default reduxContainer
