import t from './actionTypes'

const defaultState = {
  list: [],
  focus: {},
  hub: {}
}

const orders = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_ORDER_LIST:
      return Object.assign({}, state, {
        list: action.orders
      })

    case t.SET_ORDER_FOCUS:
      return Object.assign({}, state, {
        focus: action.order
      })

    case t.CLEAR_ORDER_FOCUS:
      return {
        ...state,
        focus: {}
      }

    default:
      return state
  }
}

export default orders
