import React from 'react'
import styled from '@emotion/styled'

import Alert from '../alert/container'
import Navbar from '../navbar/container'
import NavigationContainer from '../navigation/container'
import Overlay from 'src/components/overlay'
import { array, bool, func, object, oneOfType } from 'prop-types'

const Screen = ({ navIsOpen, closeNavSlider, children }) => {
  const closeSidebar = () => navIsOpen && closeNavSlider()

  return (
    <FullScreen>
      <NavigationContainer />
      <Alert />

      <Overlay
        isOpen={navIsOpen}
        onClick={closeSidebar}
        overlayStyle={{ height: '100%' }}
      />

      <Navbar />

      <MainContent>
        {children}
      </MainContent>
    </FullScreen>
  )
}

Screen.propTypes = {
  navIsOpen: bool,
  children: oneOfType([array, object]),
  closeNavSlider: func
}

export default Screen

const FullScreen = styled.div`
  height: 100%;
  overflow: hidden;
  width: 100%;
`

const MainContent = styled.div`
  -webkit-overflow-scrolling: touch;
  display: block;
  height: calc(100% - 5rem);
  overflow: auto;
  pointer-events: ${({ navIsOpen }) => navIsOpen ? 'none' : 'auto'};
`
