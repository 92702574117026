/**
 * Returns the received string with the first char capitalized; if input is not
 * a string, it will simply be returned as it was received
 * @param {string} word - Word to capitalize; use titleize for multiple words
 * @returns {string} word is returned capitalized; e.g. "test" -> "Test"
 */
function capitalize (word) {
  const isString = typeof word === 'string'
  if (isString) {
    return word.charAt(0).toUpperCase() + word.slice(1)
  } else console.warn('capitalize() received an unexpected input type', word)
  return word
}

export default capitalize
