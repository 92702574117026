// Overrides: https://material-ui.com/customization/globals/
import COLORS from './colors'

const OVERRIDES = {
  // Name of the component to apply CSS to
  MuiButton: {
    // The base class for the component (will impact all variants)
    // NOTE: If a variant has the same property as that which is being defined
    // here, the variant's property value will be applied due to specificity
    // Example: defining color on .root will not impact .contained because
    // .contained defines its own `color`
    root: {
      // Pseudo-class styling example
      // '&:hover': {
      //   color: COLORS.blue
      // },
    },
    // The variant-specific override styles for the component
    // e.g. <Button variant='contained' />
    text: {
      // custom styles go here
    },
    contained: {
      // custom styles go here
      '&$disabled': {
        backgroundColor: COLORS.gray[80]
      }
    }
  },
  MuiFormHelperText: {
    root: {
      color: COLORS.gray[10],
      '&$error': {
        color: COLORS.red[50]
      }
    }
  },
  MuiFormLabel: {
    root: {
      '&$error': {
        color: COLORS.red[50]
      }
    }
  },
  MuiPaper: {
    root: {
      backgroundColor: COLORS.gray[90]
    }
  },
  MuiTextField: {
  },
  MuiInputBase: {
    root: {
      backgroundColor: COLORS.gray[80],
      color: COLORS.gray[10],
      '&:hover': {
        backgroundColor: COLORS.gray[80]
      },
      '&$focused': {
        backgroundColor: COLORS.gray[80]
      }
    }
  },
  MuiFilledInput: {
    underline: {
      '&:after': {
        borderBottomColor: COLORS.blue[50]
      },
      '&$error:after': {
        borderBottomColor: COLORS.red[50]
      },
      '&:hover:before': {
        borderBottom: `2px solid ${COLORS.gray[50]}`
      },
      '&:before': {
        borderBottom: `2px solid ${COLORS.gray[50]}`
      }
    }
  },
  MuiInputLabel: {
    root: {
      color: COLORS.gray[30],
      '&$error': {
        color: COLORS.red[50]
      },
      '&$focused': {
        color: COLORS.blue[50]
      }
    }
  },
  MuiMenuItem: {
    root: {
      color: COLORS.gray[30]
    }
  },
  MuiSelect: {
    icon: {
      color: COLORS.gray[30]
    }
  },
  MuiTypography: {
    root: {
      color: COLORS.gray[10]
    },
    h4: {
      fontWeight: 600
    }
  }
}

export default OVERRIDES
