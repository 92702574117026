import React, { PureComponent } from 'react'
import { func, number } from 'prop-types'
import window from 'global/window'

import ArrowIcon from 'src/microcomponents/icon-arrow'

import {
  DRIVER_MODES_MAP,
  DRIVER_MODE_KEYS_LIST,
  DRIVER_MODE_COLORS_MAP,
  DRIVER_MODE_OFF_KEY
} from 'helpers/drivers'

import styled from '@emotion/styled'

import MCColors from 'src/helpers/css-variables'
const { white, tertiaryDark } = MCColors

export default class DriverStatus extends PureComponent {
  static propTypes = {
    changeDriverMode: func.isRequired,
    driverId: number,
    driverMode: number
  }

  static defaultProps = {
    changeDriverMode: function () {
      console.warn('changeDriverMode() not imlimented in <DriverStatus />')
    }
  }

  handleChangeDriverMode = event => {
    const {
      target: { value }
    } = event
    const { changeDriverMode, driverId } = this.props

    const modeName = DRIVER_MODES_MAP[value]
    const confirmMessage = `Are you sure you want to change the drivers mode to ${modeName.toUpperCase()}?`

    if (window.confirm(confirmMessage)) {
      changeDriverMode(value, driverId)
    }
  }

  render () {
    const { driverMode } = this.props
    const shouldDisable = driverMode === DRIVER_MODE_OFF_KEY

    return (
      <Container>
        <ModeIcon statusColor={DRIVER_MODE_COLORS_MAP[driverMode]} />
        <DriverModeSelect
          onChange={this.handleChangeDriverMode}
          value={driverMode}
          disabled={shouldDisable}
        >
          {DRIVER_MODE_KEYS_LIST.map((modeKey, index) => {
            return (
              <option value={modeKey} key={index}>
                {DRIVER_MODES_MAP[modeKey]}
              </option>
            )
          })}
        </DriverModeSelect>
        {!shouldDisable && (
          <ArrowIconWrapper>
            <ArrowIcon height='1.4rem' width='1.4rem' />
          </ArrowIconWrapper>
        )}
      </Container>
    )
  }
}

const Container = styled.div`
  background-color: ${tertiaryDark};
  border-radius: 4rem;
  display: flex;
  align-items: center;
  position: relative;
  max-width: 12rem;
`

const ModeIcon = styled.div`
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
  position: absolute;
  margin-left: 0.9rem;
  background-color: red;
  pointer-events: none;
  background-color: ${({ statusColor }) => statusColor};
`

const DriverModeSelect = styled.select`
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  color: ${white};
  padding: 0.9rem;
  padding-right: 4rem;
  padding-left: 2.6rem;
  cursor: pointer;
  width: 100%;
  text-overflow: ellipsis;

  :disabled {
    cursor: not-allowed;
  }
`

const ArrowIconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  right: 0;
  top: 0;
  padding-right: 0.9rem;
  pointer-events: none;
`
