import React, { PureComponent } from 'react'
import { array, func, object } from 'prop-types'
import sortOn from 'sort-on'
import { Column, Flex } from 'src/microcomponents/flexbox-helpers'
import Button, { HYBRID } from 'components/button'

import Divider from 'microcomponents/divider'
import InputLabel from 'microcomponents/input-label'

import DatesFormSection from './form-sections/dates'
import DescriptionFormSection from './form-sections/description'
import BogoDetailsFormSection from './form-sections/details-bogo'
import StandardDetailsFormSection from './form-sections/details-standard'
import DispensaryFormSection from './form-sections/dispensary-select'
import DepotFormSection from './form-sections/depot-select'
import LimitsFormSection from './form-sections/limits.js'
import MarketingFormSection from './form-sections/marketing-channel'
import PromoCodeFormSection from './form-sections/promo-code'
import PromoTypeFormSection from './form-sections/promo-type'
import StateFormSection from './form-sections/state-select'
import UserSegmentFormSection from './form-sections/user-segmentation'
import ValuesFormSection from './form-sections/values'
import CatalogSelector from './form-sections/catalog-selector'

import {
  getPromoDefinition,
  promoDefinitionsIdMap
} from 'helpers/promos/definitions'

import { AVAILABLE_STATES } from 'helpers/constants'

import managePromosPropType from 'src/redux/manage-promos/prop-types'

import {
  disabled,
  supplierSpecificity
} from './style.js'

const PROMO_RESTRICTIONS = {
  depot: 'DEPOT',
  dispensary: 'DISPENSARY'
}

const PROMO_OPTIONS = {
  id_types: ['item', 'group', 'brand'],
  credit_types: ['$', '%'],
  channels: [
    'Juice',
    'Ambassador',
    'Promotion',
    'Event',
    'Social',
    'Partner',
    'Employee',
    'Loyalty',
    'Digital Ads',
    'Print Ads',
    'Direct Mail',
    'College',
    'Survey',
    'Contest / Giveaway',
    'In-Home',
    'Influencer',
    'Concierge',
    'Growth',
    'Podcast',
    'Affiliate',
    'Billboard',
    'Bus Shelter',
    'Support'
  ]
}

export class PromoForm extends PureComponent {
  static propTypes = {
    catalog: object,
    // clonePromo: func,
    // createPromo: func,
    // depots: array,
    depotsByState: object,
    dispensaries: array,
    getCatalogBrand: func,
    getCatalogGroup: func,
    getCatalogItem: func,
    managePromos: managePromosPropType,
    promoValidity: object,
    promos: array,
    setCatalogName: func,
    setMinOrderValue: func,
    // setOrderHistory: func,
    setPromoActions: object,
    setPromoStateId: func,
    setSelectedPromoDefinition: func,
    showPromoPreview: func,
    sortedCatalogBrands: array,
    sortedCatalogGroups: array
    // sortedCatalogItems: array
  }

  state = {
    restrictTo: null // 'depot' || 'dispensary'
  }

  setPromoCode = ({ target: { value } }) => {
    const { setPromoCode } = this.props.setPromoActions
    setPromoCode(value)
  }

  setPromoDescription = ({ target: { value } }) => {
    const { setPromoDescription } = this.props.setPromoActions
    setPromoDescription(value)
  }

  handleOnSetPromoType = ({ target: { value } }) => {
    const {
      setPromoType,
      setPromoAppliesTo,
      setSelectedPromoDefinition,
      setPromoCreditType
    } = this.props.setPromoActions
    const pDef = promoDefinitionsIdMap[value]
    // value will be an id that maps to promoDefinitionsIdMap in promo-definitions.js
    setPromoType(pDef.promo_type)
    setPromoAppliesTo(pDef.applies_to)
    setSelectedPromoDefinition(pDef.id)
    if (pDef.promo_type === 'bogo') {
      setPromoCreditType('%')
    }
  }

  setPromoAppliesTo = ({ target: { value } }) => {
    const { setPromoAppliesTo } = this.props.setPromoActions
    setPromoAppliesTo(value)
  }

  setPromoIdType = ({ target: { value } }) => {
    const { setPromoIdType } = this.props.setPromoActions
    setPromoIdType(value)
  }

  setCatalogName = (name, nameType) => {
    this.props.setCatalogName(name, nameType)
  }

  setPromoStateId = ({ target: { value } }) => {
    const { setPromoStateId } = this.props.setPromoActions
    setPromoStateId(value)
    if (value === 'ALL') {
      this.setState({ restrictTo: null })
    }
  }

  setPromoIdValue = (value, buyOrGet = null) => {
    const {
      setPromoBuyOneIdValue,
      setPromoGetOneIdValue,
      setPromoIdValue
    } = this.props.setPromoActions
    // Catalog search doesn't send an event back, but everything else does.
    const val = value.target ? value.target.value : value
    const type = this.props.managePromos[`rules.operators.${buyOrGet}.id_type`]

    switch (buyOrGet) {
      case 'buy':
        setPromoBuyOneIdValue(val)
        break
      case 'get':
        setPromoGetOneIdValue(val)
        break
      default:
        setPromoIdValue(val)
        break
    }

    switch (type) {
      case 'item':
        this.props.getCatalogItem(val, buyOrGet)
        break
      case 'brand':
        this.props.getCatalogBrand(val, buyOrGet)
        break
      case 'group':
        this.props.getCatalogGroup(val, buyOrGet)
        break
      default:
        return null
    }
  }

  setBuyOneIdValue = id => {
    this.setPromoIdValue(id, 'buy')
  }

  setGetOneIdValue = id => {
    this.setPromoIdValue(id, 'get')
  }

  // TODO: Consolidate all functions using `setPromoActions`
  setCreditType = ({ target: { value } }) => {
    const { setPromoCreditType } = this.props.setPromoActions
    setPromoCreditType(value)
  }

  handleSetGlobalLimit = ({ target: { value } }) => {
    const { setPromoGlobalUse } = this.props.setPromoActions
    setPromoGlobalUse(parseInt(value))
  }

  handleSetUserLimit = ({ target: { value } }) => {
    const { setPromoUserUse } = this.props.setPromoActions
    setPromoUserUse(parseInt(value))
  }

  setTargetUser = ({ target: { value } }) => {
    const { setPromoTargetUser } = this.props.setPromoActions
    setPromoTargetUser(value)
  }

  handleSetDepots = depotIds => {
    const { setPromoDepots } = this.props.setPromoActions
    setPromoDepots(depotIds)
  }

  setDispensary = ({ target: { value } }) => {
    const { setPromoDispensary } = this.props.setPromoActions
    setPromoDispensary(value)
  }

  setMarketingChannel = ({ target: { value } }) => {
    const { setPromoChannel } = this.props.setPromoActions
    setPromoChannel(value)
  }

  setOrderHistory = ({ target: { value } }) => {
    const { setOrderHistoryLimit } = this.props.setPromoActions
    setOrderHistoryLimit(value)
  }

  setMinOrderValue = ({ target: { value } }) => {
    const { setMinOrderValue } = this.props.setPromoActions
    setMinOrderValue(parseFloat(value))
  }

  setCreditAmount = ({ target: { value } }) => {
    const { setPromoAmount } = this.props.setPromoActions
    setPromoAmount(parseFloat(value))
  }

  setPromoCreditMax = ({ target: { value } }) => {
    const { setPromoCreditMax } = this.props.setPromoActions
    setPromoCreditMax(parseFloat(value))
  }

  setBuyOneIdType = ({ target: { value } }) => {
    const { setPromoBuyOneIdType } = this.props.setPromoActions
    setPromoBuyOneIdType(value)
  }

  setBuyOneAmount = ({ target: { value } }) => {
    const { setPromoBuyOneAmount } = this.props.setPromoActions
    setPromoBuyOneAmount(parseInt(value))
  }

  setGetOneIdType = ({ target: { value } }) => {
    const { setPromoGetOneIdType } = this.props.setPromoActions
    setPromoGetOneIdType(value)
  }

  setGetOneAmount = ({ target: { value } }) => {
    const { setPromoGetOneAmount } = this.props.setPromoActions
    setPromoGetOneAmount(parseInt(value))
  }
  // END  TODO: Consolidate all functions using `setPromoActions`

  // You might notice this used inconsistently. I don't know why react is retaining some input values when the properties are set to null in state -Chris
  normalize (value) {
    if (value === 0 || value === '0') {
      return 0
    } else if (!value) {
      return ''
    } else {
      return value
    }
  }

  filterDispensariesByState = (dispensaries, stateId) => {
    return dispensaries.filter((dispensary) =>
      dispensary.depots.length && dispensary.depots[0].depotAddress && dispensary.depots[0].depotAddress.state === stateId)
  }

  handleLocationRestrictionSelect = restrictTo => {
    /* eslint-disable camelcase */
    const { rules: { state_id } } = this.props.managePromos
    if (state_id === 'ALL' || restrictTo === null || !state_id) return
    this.setState({ restrictTo }, () => {
      this.setDispensary({ target: { value: null } })
      this.handleSetDepots(null)
    })
  }

  renderBogoFormSections = (managePromos, promoOptions, catalog) => (
    <div>
      <BogoDetailsFormSection
        promoOptions={promoOptions}
        catalog={catalog}
        managePromos={managePromos}
        setBuyOneValue={this.setBuyOneIdValue}
        setBuyOneType={this.setBuyOneIdType}
        setBuyOneAmount={this.setBuyOneAmount}
        setGetOneValue={this.setGetOneIdValue}
        setGetOneType={this.setGetOneIdType}
        setGetOneAmount={this.setGetOneAmount}
      />
      <br />
      <StandardDetailsFormSection
        promoOptions={promoOptions}
        setCreditType={this.setCreditType}
        creditAmount={this.normalize(
          managePromos.rules.operators.credit_amount
        )}
        setCreditAmount={this.setCreditAmount}
        lockCreditType
        creditType={managePromos.rules.operators.credit_type}
      />
    </div>
  )

  renderStandardFormSections = (promoOptions, creditType, creditAmount) => (
    <StandardDetailsFormSection
      promoOptions={promoOptions}
      setCreditType={this.setCreditType}
      creditType={creditType}
      creditAmount={this.normalize(creditAmount)}
      setCreditAmount={this.setCreditAmount}
    />
  )

  componentDidMount () {
    const { managePromos } = this.props
    const { user_cohort = {} } = managePromos.rules
    const hasBeenAssignedDepots = user_cohort.depot_ids
    const hasBeenAssignedDispensary = user_cohort.dispensary_id

    let promoRestrictTo = null
    if (hasBeenAssignedDepots) {
      promoRestrictTo = PROMO_RESTRICTIONS.depot
    } else if (hasBeenAssignedDispensary) {
      promoRestrictTo = PROMO_RESTRICTIONS.dispensary
    }

    this.setState({ restrictTo: promoRestrictTo })
  }

  render () {
    const {
      depotsByState,
      dispensaries,
      showPromoPreview,
      promoValidity,
      catalog,
      promos,
      managePromos
    } = this.props
    const { rules } = managePromos
    const { user_cohort = {} } = rules
    const { operators } = rules
    const promoOptions = { ...PROMO_OPTIONS }
    const promoDefinition = getPromoDefinition(managePromos)
    const {
      setPromoStartDate,
      setPromoEndDate,
      setPromoUserIds
    } = this.props.setPromoActions

    const isAllStates = /all/i.test(rules.state_id)
    return (
      <Column>
        <Flex top={1} bottom={1}>
          <PromoCodeFormSection
            code={this.normalize(managePromos.code)}
            setPromoCode={this.setPromoCode}
            promoCodes={promos}
            editPromoId={managePromos.id}
          />
        </Flex>

        <DescriptionFormSection
          description={this.normalize(managePromos.description)}
          setPromoDescription={this.setPromoDescription}
        />
        <Divider />

        <DatesFormSection
          promoValidAt={managePromos.valid_at}
          promoExpiresAt={managePromos.expires_at}
          setPromoValidAt={setPromoStartDate}
          setPromoExpiresAt={setPromoEndDate}
        />
        <Divider />

        <StateFormSection availableStates={AVAILABLE_STATES} selectedState={managePromos.rules.state_id} setPromoStateId={this.setPromoStateId} />
        <Divider />

        <PromoTypeFormSection
          promoOptions={promoOptions}
          selectedPromoDefinitionId={promoDefinition && promoDefinition.id}
          onSetPromoType={this.handleOnSetPromoType}
          idType={operators.id_type}
          setPromoIdType={this.setPromoIdType}
          appliesTo={rules.applies_to}
          setPromoAppliesTo={this.setPromoAppliesTo}
          stateId={rules.state_id}
        />
        {operators.id_type && rules.applies_to === 'id'
          ? (<CatalogSelector
            managePromos={managePromos}
            catalogBrands={this.props.sortedCatalogBrands}
            catalogGroups={this.props.sortedCatalogGroups}
            idType={operators.id_type}
            idValue={operators.id_value}
            setPromoIdValue={this.setPromoIdValue}
          />)
          : null}

        {rules.promo_type === 'bogo'
          ? this.renderBogoFormSections(managePromos, promoOptions, catalog)
          : this.renderStandardFormSections(
            promoOptions,
            operators.credit_type,
            operators.credit_amount
          )}
        <Divider />

        <ValuesFormSection
          minOrderValue={this.normalize(
            rules.min_order_amount || operators.min_order_amount
          )}
          creditMax={this.normalize(operators.credit_max)}
          setMinOrderValue={this.setMinOrderValue}
          setCreditMax={this.setPromoCreditMax}
        />
        <Divider />

        <LimitsFormSection
          globalLimit={rules.global_limit}
          userLimit={rules.user_limit}
          onGlobalLimitChange={this.handleSetGlobalLimit}
          onUserLimitChange={this.handleSetUserLimit}
        />
        <Divider />

        <UserSegmentFormSection
          setOrderHistoryLimit={this.setOrderHistory}
          setPromoUserIds={setPromoUserIds}
          managePromos={managePromos}
        />
        <Divider />

        <div className={supplierSpecificity}>
          <InputLabel content='Accept at:' />
          <Flex justify='space-around'>
            <label
              htmlFor='none'
              onClick={() => this.handleLocationRestrictionSelect(null)}
              className={isAllStates ? '' : disabled}
            >
              <input
                checked={this.state.restrictTo === null && isAllStates}
                disabled={!isAllStates}
                id='none'
                name='supplierSpecificity'
                type='radio'
              />
              All locations
            </label>
            <label
              htmlFor='depot'
              className={isAllStates || !rules.state_id ? disabled : ''}
              onClick={() =>
                this.handleLocationRestrictionSelect(PROMO_RESTRICTIONS.depot)}
            >
              <input
                checked={this.state.restrictTo === PROMO_RESTRICTIONS.depot}
                disabled={isAllStates}
                id='depot'
                name='supplierSpecificity'
                type='radio'
              />
              <span>Depot</span>
            </label>
            <label
              htmlFor='dispensary'
              className={isAllStates || !rules.state_id ? disabled : ''}
              onClick={() =>
                this.handleLocationRestrictionSelect(
                  PROMO_RESTRICTIONS.dispensary
                )}
            >
              <input
                type='radio'
                id='dispensary'
                name='supplierSpecificity'
                disabled={isAllStates}
                checked={
                  this.state.restrictTo === PROMO_RESTRICTIONS.dispensary
                }
              />
              <span>Dispensary</span>
            </label>
          </Flex>
        </div>

        {this.state.restrictTo === PROMO_RESTRICTIONS.dispensary && !isAllStates && (
          <DispensaryFormSection
            setDispensary={this.setDispensary}
            dispensaries={sortOn(this.filterDispensariesByState(dispensaries, rules.state_id), 'name')}
            dispensaryId={user_cohort.dispensary_id}
          />
        )}
        {this.state.restrictTo === PROMO_RESTRICTIONS.depot && !isAllStates && (
          <DepotFormSection
            onDepotSelect={this.handleSetDepots}
            depots={sortOn(depotsByState[managePromos.rules.state_id], 'name')}
            selectedDepotIds={user_cohort.depot_ids || []}
          />
        )}
        <Divider />

        <MarketingFormSection
          promoOptions={promoOptions}
          marketingChannel={managePromos.meta.marketing_channel}
          setMarketingChannel={this.setMarketingChannel}
        />
        <br />
        <small style={{ color: '#b63353' }}>{promoValidity.message}</small>
        <Flex top={1}>
          <Button
            type={HYBRID}
            onClick={() => showPromoPreview(true)}
            disabled={!promoValidity.status}
          >
            Next
          </Button>
        </Flex>
      </Column>
    )
  }
}

export default PromoForm
