import t from './actionTypes'

const defaultState = {
  list: [],
  id: 0
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_DEPOTS:
      return {
        ...state,
        list: action.payload
      }

    case t.SET_SELECTED_DEPOT_ID:
      return {
        ...state,
        id: action.payload
      }

    default:
      return state
  }
}
