import React, { PureComponent } from 'react'
import { bool, func, number, object, oneOfType, string } from 'prop-types'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import parseISO from 'date-fns/parseISO'

import Button, { DANGER } from 'components/button'
import { readTime } from 'src/helpers/date'
import { getOrderStatusText, orderIsPending, ORDER_STATUS_TEXTS } from 'src/helpers/orders'
import { mcGreen, mcRed, white } from 'helpers/css-variables'
import InfoBlock from './order-info-block'
import El from './order-item-elements'
import { getItemQuantity } from 'helpers/deliveries'
import { PAYMENT_METHOD_TEXT_MAPPING } from 'helpers/constants'
import { MC_URL } from 'helpers/environment'

function addressChop (address) {
  if (!address) return
  // filter out hacks appended to address from backend
  const colMatch = address.indexOf('Collect') || address.indexOf('COLLECT')
  // moaaar hax
  const cardMatch = address.indexOf('Paid by card')

  if (colMatch > -1) {
    address = address.substring(0, colMatch)
  } else if (cardMatch > -1) {
    address = address.substring(0, cardMatch)
  }

  return address
}

export default class OrderItem extends PureComponent {
  static propTypes = {
    focusedDriverId: oneOfType([number, string]),
    isAdmin: bool,
    order: object,
    rerouteOrder: func
  }

  static defaultProps = {
    order: {},
    rerouteOrder: () => {},
    isAdmin: false
  }

  state = {
    showReassignButton: false
  }

  showReassignButton = showButton => event => {
    this.setState({ showReassignButton: showButton })
  }

  reassignOrder = (orderId, driverId) => event => {
    const confirmed = window.confirm("Are you sure you want to reassign this order? It will remove it from this driver's queue")

    if (confirmed) {
      this.props.rerouteOrder(orderId, driverId)
    }
  }

  render () {
    const { order: o, isAdmin, focusedDriverId } = this.props
    const { showReassignButton } = this.state

    if (!o.delivery) return null

    const customer = o.createdBy

    const {
      // newDeliveryDateTime,
      originalDeliveryDateTime,
      estimatedDeliveryDateTime,
      estimatedDeliveryTimeDisplay
    } = o.delivery

    const address = addressChop(o.delivery.fullAddress)

    const ogDelivery = readTime(originalDeliveryDateTime)
    const currDelivery = readTime(estimatedDeliveryDateTime)
    const diff = differenceInMinutes(parseISO(estimatedDeliveryDateTime), parseISO(originalDeliveryDateTime))
    const statusText = getOrderStatusText(o) // Canceled, Delivered, or ''. If empty we will show the ogDiff
    const bigDiff = diff > 0 // ETA grew

    const isReassignable = orderIsPending(o)
    const reassignButton = isReassignable && showReassignButton && isAdmin

    return (
      <El.Container>
        <El.LeftSideContainer>
          <El.OrderHeader onMouseEnter={this.showReassignButton(true)} onMouseLeave={this.showReassignButton(false)}>
            <div>
              Order #
              <El.OrderLinkHeader href={`${MC_URL}/orders?id=${o.id}`} target='_blank'>
                {o.id}
              </El.OrderLinkHeader>
              {reassignButton &&
                <Button
                  componentStyle={{
                    maxWidth: '12rem',
                    padding: '0.5rem',
                    marginLeft: '1rem',
                    lineHeight: 'inherit',
                    position: 'absolute'
                  }}
                  type={DANGER}
                  onClick={this.reassignOrder(o.id, focusedDriverId)}
                >
                  Reassign
                </Button>}
            </div>
            <El.DeliveryText>{o.deliveryType}</El.DeliveryText>
          </El.OrderHeader>
          <El.ContentBlock>
            {customer.firstName}
            <El.OrderLink
              href={`${MC_URL}/profile/${customer.id}`}
              target='_blank'
            >
              (#{customer.id})
            </El.OrderLink>
            <El.Address>{address}</El.Address>
          </El.ContentBlock>
          <El.SummaryHeader>
            <div>{getItemQuantity(o.products)} items</div>
            <div>${o.chargeAmount} {PAYMENT_METHOD_TEXT_MAPPING[o.paymentMethod]}</div>
          </El.SummaryHeader>
          <El.Table>
            <tbody>
              {o.products.map((product, index) => {
                return (
                  <El.Row key={index}>
                    <td>
                      {product.quantity}x&nbsp;
                      {product.type.name}&nbsp;–&nbsp;
                      {product.brand.name}&nbsp;–&nbsp;
                      {product.name}
                    </td>
                  </El.Row>
                )
              })}
            </tbody>
          </El.Table>
        </El.LeftSideContainer>
        <El.RightSideContainer>
          <El.RightContent>
            <InfoBlock
              title={statusText || estimatedDeliveryTimeDisplay}
              subtitle={statusText ? '' : 'REMAINING'}
              titleStyle={{ fontSize: '2.2rem', fontWeight: 800, color: statusText === ORDER_STATUS_TEXTS.CANCELED ? mcRed : white }}
            />
            <InfoBlock
              title={`${diff} min`}
              subtitle={bigDiff ? 'OVER' : 'UNDER'}
              titleStyle={{ color: bigDiff ? mcRed : mcGreen }}
            />
            <InfoBlock
              title={currDelivery}
              subtitle='NEW'
            />
            <InfoBlock
              title={ogDelivery}
              subtitle='ORIGINAL'
            />
          </El.RightContent>
        </El.RightSideContainer>
      </El.Container>
    )
  }
}
