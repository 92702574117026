export const HYBRID = 'HYBRID'
export const DANGER = 'DANGER'
export const PRIMARY = 'PRIMARY'
export const CBD = 'CBD'
export const SATIVA = 'SATIVA'
export const INDICA = 'INDICA'
export const TEXT = 'TEXT'
export const TRANSPARENT = 'TRANSPARENT'

export default {
  HYBRID,
  DANGER,
  PRIMARY,
  CBD,
  SATIVA,
  INDICA,
  TEXT,
  TRANSPARENT
}
