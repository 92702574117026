import t from './actionTypes'
import { requestUsers, receiveUsers } from '../loading/actions'
import api from 'api'

export function fetchUsers (params = {page: 1, query: ''}) {
  return async dispatch => {
    dispatch(requestUsers())

    params.count = 20

    // we should rename this to getUsers at some point.
    const {err, data} = await api.getPatients(params)
    dispatch(receiveUsers())
    if (err) return

    dispatch(setUsers(data.items))
    dispatch(setPage(data.page))
    dispatch(setTotal(data.total, data.count))
  }
}

export function fetchUserForPromotion (params = {query: ''}) {
  return async dispatch => {
    dispatch(requestUsers())
    params.count = 1
    // we should rename this to getUsers at some point.
    const {err, data} = await api.searchUsersForPromotion(params)
    dispatch(receiveUsers())

    if (err) return null

    if (data.items && data.items.length) {
      return data.items[0]
    } else {
      return null
    }
  }
}

export function setUsers (users) {
  return {
    type: t.SET_USERS,
    users
  }
}

export function search () {
  return (dispatch, getState) => {
    const state = getState()
    const users = state.users

    dispatch(fetchUsers({
      query: users.query,
      page: 1
    }))
  }
}

function setPage (page) {
  return {
    type: t.SET_PAGE,
    page
  }
}

function setTotal (total, count) {
  total = Math.max(0, Math.ceil(total / count))

  return {
    type: t.SET_TOTAL,
    total
  }
}

export function setQuery (query) {
  return {
    type: t.SET_QUERY,
    query
  }
}

export function nextCustomerPage () {
  return (dispatch, getState) => {
    const state = getState()
    const nextPage = state.users.page + 1
    const query = state.users.query

    dispatch(fetchUsers({page: nextPage, query}))
  }
}

export function prevCustomerPage () {
  return (dispatch, getState) => {
    const state = getState()
    const nextPage = state.users.page - 1
    const query = state.users.query

    dispatch(fetchUsers({page: nextPage, query}))
  }
}
