import t from './actionTypes'
import { DRIVER_ORDERS_KEY } from 'helpers/deliveries'

const defaultState = {
  focusedDriverId: undefined,
  focusedDriverDepotHasMETRCRules: undefined,
  driverDetailsContent: DRIVER_ORDERS_KEY,
  driverOrdersLoadingMap: {},
  driverInventoryLoadingMap: {},
  driverMETRCInfoDrawerOpen: false
}

const drivers = (state = defaultState, { type, payload }) => {
  switch (type) {
    case t.SET_FOCUSED_DRIVERS_DRIVER_ID:
      return {
        ...state,
        focusedDriverId: payload
      }

    case t.SET_FOCUSED_DRIVER_DEPOT_HAS_METRC_RULES:
      return {
        ...state,
        focusedDriverDepotHasMETRCRules: payload
      }

    case t.SET_DRIVERS_DRIVER_ORDERS_LOADING_MAP:
      return {
        ...state,
        driverOrdersLoadingMap: payload
      }

    case t.SET_DRIVERS_DRIVER_INVENTORY_LOADING_MAP:
      return {
        ...state,
        driverInventoryLoadingMap: payload
      }

    case t.SET_DRIVERS_DRIVER_DETAILS_CONTENT:
      return {
        ...state,
        driverDetailsContent: payload
      }

    case t.TOGGLE_DRIVER_METRC_INFO_DRAWER:
      return {
        ...state,
        driverMETRCInfoDrawerOpen: !state.driverMETRCInfoDrawerOpen
      }

    case t.CLEAR_DRIVERS:
      return defaultState

    default:
      return state
  }
}

export default drivers
