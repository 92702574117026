/**
*  functions for formatting google maps search results
*
*/
import parseGoogleMaps from '@eaze/parse-google-autocomplete'

import pin from 'assets/place/pin.svg'
import globe from 'assets/place/global-pin.svg'
import office from 'assets/place/office.svg'

export const formatSearchResults = (places) => {
  const parsedPlaces = parseGoogleMaps(places)
  const formattedPlaces = parsedPlaces.map(formatPlace)

  return formattedPlaces
}

function formatPlace (place) {
  return {
    ...place,
    description: formatDescription(place.description),
    title: formatTitle(place),
    body: formatBody(place),
    icon: formatIcon(place)
  }
}

// we want to strip the country out of the description
function formatDescription (description) {
  return description.split(', ').slice(0, -1).join(', ')
}

function formatTitle (place) {
  return place.business
    ? place.title
    : place.street || place.zip || place.city
}

const formatBody = (place) => {
  const body = (place.street
    ? [place.business ? place.street : null, place.city, place.state]
    : [place.zip ? place.city : null, place.state])
    .filter(Boolean)
    .join(', ')

  return body
}

const formatIcon = (place) => {
  let icon = place.icon
  if (!icon) {
    icon = place.business ? office : place.street ? pin : globe
  }
  return icon
}
