// redux enhancers
import window from 'global/window'
import { applyMiddleware, compose } from 'redux'
import { autoRehydrate } from 'redux-persist'
import thunk from 'redux-thunk'
import createActionBuffer from 'redux-action-buffer'
import { REHYDRATE } from 'redux-persist/constants'

import { isLikeProduction } from 'helpers/constants'

export const getEnhancers = isLikeProduction ? getProdEnhancers : getDevEnhancers

function getDevEnhancers () {
  // Order of middleware is important! Don't rearrange without knowing what you're doing!
  return window.__REDUX_DEVTOOLS_EXTENSION__
    ? compose(
      applyMiddleware(
        createActionBuffer(REHYDRATE)
      ),
      applyMiddleware(thunk),
      autoRehydrate(),
      window.__REDUX_DEVTOOLS_EXTENSION__()
    )
    : compose(
      applyMiddleware(
        createActionBuffer(REHYDRATE)
      ),
      applyMiddleware(thunk),
      autoRehydrate()
    )
}

function getProdEnhancers () {
  // Order of middleware is important! Don't rearrange without knowing what you're doing!
  return compose(
    applyMiddleware(
      createActionBuffer(REHYDRATE)
    ),
    applyMiddleware(thunk),
    autoRehydrate()
  )
}
