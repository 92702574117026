import { createSelector } from 'reselect'

export const getUserStore = (state) => state.user
export const getUsersStore = (state) => state.users

export const getXAuthToken = createSelector(
  [getUserStore],
  function (userStore) {
    return userStore.xAuthToken
  }
)

// Added for incident response so we can lockout non employees.
export const getIsEazeEmployee = createSelector(
  [getUserStore],
  function (userStore) {
    return userStore.email &&
      (
        (userStore.email.indexOf('@eaze.com') > -1) ||
        (userStore.email.indexOf('@eazeup.com') > -1)
      )
  }
)

export const getUsersList = createSelector(
  [getUsersStore],
  (usersStore) => usersStore.list
)

export const getUsersCurrentPage = createSelector(
  [getUsersStore],
  (usersStore) => usersStore.page
)

export const getUsersTotal = createSelector(
  [getUsersStore],
  (usersStore) => usersStore.total
)
