import React, { Fragment, PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { bool, func, node, number, object, string } from 'prop-types'

import { Column, Flex, Row } from 'src/microcomponents/flexbox-helpers'
import Button, { TRANSPARENT } from 'components/button'
import Colors from 'microcomponents/colors'
import { ArchiveIcon, CopyIcon, DownloadIcon } from 'microcomponents/icons'
import { generateImageUrl } from 'helpers/get-cloudflare-img-url.js'

import {
  about,
  abovetheblur,
  box,
  cardContainer,
  content,
  contentCard,
  fillerClose,
  fillerFar,
  itemTotalContainer,
  name as nameClass,
  productImage,
  slug as slugClass,
  stateCorner
} from './style.js'

const colorMap = {
  yellow: Colors.sativa['1'],
  blue: Colors.primary['1'],
  red: Colors.danger['1']
}

export default class McCard extends PureComponent {
  static propTypes = {
    children: node,
    cloneCase: func,
    color: string,
    count: number,
    deleteCase: func,
    description: string,
    enabled: bool,
    exportCSV: func,
    headerImage: string,
    image: string,
    name: string,
    price: number,
    product: object,
    slug: string,
    stateId: string,
    svg: string,
    type: string,
    updatedAt: string,
    url: string
  }

  buttonClick = action => event => {
    event.preventDefault()
    action()
  }

  render () {
    const {
      children,
      cloneCase,
      color,
      count,
      deleteCase,
      description,
      enabled,
      exportCSV,
      headerImage,
      image,
      name,
      price,
      product,
      slug,
      stateId,
      svg,
      type,
      updatedAt,
      url
    } = this.props

    const cardColor = color && color.startsWith('#') ? color : (colorMap[color] || 'black')
    const opacity = enabled === false ? 0.5 : 1
    const countDefined = count != null // count can be 0 and is valid and should still show a 0 total so check if count isnt null or undefined with !=
    const IS_CASE = type === 'case'
    const showStateAbbreviation = Boolean(product || stateId)

    return (
      <Link to={url} className={cardContainer} style={{ opacity }}>
        {countDefined &&
          <Fragment>
            <div className={fillerFar} />
            <div className={fillerClose} />
          </Fragment>}

        <div className={contentCard}>

          {headerImage && <div style={{ backgroundImage: `url(${headerImage})` }} />}

          <Row className={abovetheblur} align='center'>
            {image || svg
              ? <Flex right={1} left={1}>
                <img
                  className={productImage}
                  loading='lazy'
                  src={svg || generateImageUrl(image)}
                />
              </Flex>
              : countDefined &&
                <Flex
                  className={itemTotalContainer}
                  justify='center' align='center'
                  left={2} right={2}
                >
                  <Column className={box} componentStyle={{ backgroundColor: cardColor }}>
                    <div style={{ fontWeight: 'bold' }}>{count}</div>
                    <div style={{ fontSize: '1.2rem', letterSpacing: '0.03rem' }}>
                      {IS_CASE ? 'PRODUCTS' : 'TOTAL'}
                    </div>
                  </Column>
                </Flex>}

            <Column className={content}>
              {price && <div>${price}</div>}
              <div className={nameClass} style={{ color: cardColor }}>{name}</div>
              <div className={about}>{description}</div>
              {updatedAt && <div className={about}>Updated: {updatedAt}</div>}
              <div className={slugClass}>
                {IS_CASE ? slug : slug && `/${slug}`}
              </div>
            </Column>

            {showStateAbbreviation && <div className={stateCorner}>{stateId || product.state_id || ''}</div>}

            {IS_CASE
              ? <Column className="button-content">
                <Button
                  onClick={this.buttonClick(deleteCase)}
                  type={TRANSPARENT}
                  componentStyle={{ boxShadow: 'none', padding: '0.3rem' }}
                >
                  <ArchiveIcon title='Archive case template' />
                </Button>
                <Button
                  onClick={this.buttonClick(cloneCase)}
                  type={TRANSPARENT}
                  componentStyle={{ boxShadow: 'none', padding: '0.3rem' }}
                >
                  <CopyIcon title='Copy case template' />
                </Button>
                <Button
                  onClick={this.buttonClick(exportCSV)}
                  type={TRANSPARENT}
                  componentStyle={{ boxShadow: 'none', padding: '0.3rem' }}
                >
                  <DownloadIcon title='Download case template CSV' />
                </Button>
              </Column>
              : null}
            {children}
          </Row>

        </div>

      </Link>
    )
  }
}
