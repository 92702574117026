import React from 'react'
import { array, bool, func, object, oneOfType, string } from 'prop-types'
import window from 'global/window'

import t from './types'

import errorHandler from 'helpers/error-handler'
import { css, cx } from 'emotion'

const fileInput = css`
  display: none;
`

const label = css`
  display: flex;
  cursor: pointer;
  width: 100%;
  height: 100%;
`

class InputFile extends React.Component {
  static propTypes = {
    name: string.isRequired,
    // Callback invoked once the file is read
    onFileRead: func.isRequired,
    filetype: string.isRequired,
    readAsText: bool,
    onRequestUpload: func,
    inputProps: object,
    labelAttributes: object,
    children: oneOfType([array, object, string])
  }

  readFile = (file) => {
    const { onFileRead, filetype, readAsText } = this.props
    // Read the file and invoke the callback once done
    const reader = new window.FileReader()
    const options = { file }

    if (filetype === t.IMAGE) {
      options.image = reader.result
    }

    reader.onloadend = () => {
      onFileRead(options, reader.result)
    }

    reader.onerror = () => {
      errorHandler('There was an error reading the file!')
      console.error('There was an error reading the file!')
    }
    readAsText ? reader.readAsText(file) : reader.readAsDataURL(file)
  }

  handleInputChange = (event) => {
    const { onRequestUpload } = this.props
    const file = event.target.files[0]

    if (file) {
      if (onRequestUpload) onRequestUpload()
      this.readFile(file)
    }

    // allows HTML input to select the same file
    event.target.value = null
  }

  render () {
    const { name, filetype, inputProps, labelAttributes, children } = this.props
    const accept = filetype === t.IMAGE ? 'image/*' : '.csv'

    return (
      <div>
        <input
          className={cx(fileInput)}
          type='file'
          accept={accept}
          name={name}
          id={name}
          onChange={this.handleInputChange}
          {...inputProps}
        />
        <label
          htmlFor={name}
          className={cx(label)}
          {...labelAttributes}
        >
          {children}
        </label>
      </div>
    )
  }
}

InputFile.defaultProps = {
  filetype: t.IMAGE
}

export default InputFile
