import React, { PureComponent } from 'react'
import { array, bool, object, func } from 'prop-types'
import { Link } from 'react-router-dom'
import debounce from 'debounce'
import sortOn from 'sort-on'
import window from 'global/window'
import { Flex, Row, Column } from 'src/microcomponents/flexbox-helpers'
import styled from '@emotion/styled'

import InputLabel from 'src/microcomponents/input-label'
import CopyText from 'src/microcomponents/icons/icon-copy-text'
import Switch from 'src/microcomponents/switch'
import LoadingSpinner from 'src/microcomponents/loading-spinner'
import EffectSlider from 'src/components/effect-slider'
import UploadFile from 'microcomponents/upload-file'
import Button, { HYBRID, DANGER, PRIMARY } from 'components/button'
import ProductCard from 'src/components/product-card'

import { transformCatalogItemToProduct } from 'helpers/product-helpers'
import {
  isValidInput,
  isValidImage,
  isValidEffect
} from 'src/helpers/form-validators'

import { transformToCatalogItem } from 'src/redux/catalog/item/actions'
import { STATUSES } from 'src/redux/catalog/item/constants'

import uploadImageSvg from 'src/assets/upload-image.svg'
import {
  imageFormThumbnail,
  imageFormThumbnailDeleteButton,
  imageFormThumbnailLoadingContainer,
  imageFormThumbnailSpinner,
  inputError,
  selectContainer,
  switchFormContainer,
  switchFormLabel
} from '../../style.js'

export class EditProduct extends PureComponent {
  static propTypes = {
    brands: array,
    cantCreateProduct: bool,
    catalogItem: object,
    deleteCatalogItem: func,
    isProductFormOpen: bool,
    setCatalogItem: func,
    setProductConfirmDrawerReasons: func,
    showProductConfirmDrawer: func,
    species: array,
    statesAvailable: array,
    strains: array,
    submitCatalogItem: func,
    subtypes: array,
    types: array,
    uploadPublicFile: func
  }

  state = {
    // using transformToCatalogItem in order to make sure the local
    // catalogItem has the same shape as the redux store's catalogItem
    catalogItem: transformToCatalogItem({ effects: [] }),
    cogsWarning: 'The COGS value for this product is set to $0.00',
    formSubmitAttempted: false,
    imageIsLoading: false,
    isEdit: false,
    preview: false,
    weightWarning: 'The Net Weight value for this product is set to '
  }

  // destructuring isProductFormOpen nextProps to compare to this.props.isProductFormOpen
  // to determine whether the form was opened from a closed state
  // also grabbing the catalogItem nextProps for loading the local catalogItem state
  componentDidUpdate (prevProps) {
    // if we are going to open the form, ensure we reset formSubmitAttempted
    const { catalogItem, isProductFormOpen } = this.props
    const { isProductFormOpen: prevIsProductFormOpen } = prevProps

    if (isProductFormOpen && prevIsProductFormOpen === false) {
      const { id } = catalogItem
      const isEdit = Boolean(id)

      this.setState({
        catalogItem,
        formSubmitAttempted: false,
        isEdit
      })
    }
  }

  deleteItem = () => {
    const {
      catalogItem: { name }
    } = this.state
    const { deleteCatalogItem } = this.props
    const confirmMessage = `Are you sure you want to delete the ${name} product from the catalog?`
    if (window.confirm(confirmMessage)) {
      deleteCatalogItem()
    }
  }

  submitForm = () => {
    const {
      catalogItem: { cogs, weight },
      cogsWarning,
      weightWarning
    } = this.state

    const {
      cantCreateProduct,
      setProductConfirmDrawerReasons,
      showProductConfirmDrawer,
      submitCatalogItem
    } = this.props

    this.setState({ formSubmitAttempted: true })

    if (!cantCreateProduct) {
      const isCogsZero = cogs === 0
      const weightShouldThrowWarning = weight === 0 || weight > 28

      // we want to pop up a warning modal if the user sets certain values to 0
      if (isCogsZero || weightShouldThrowWarning) {
        const warningArray = []

        if (isCogsZero) warningArray.push(cogsWarning)
        if (weightShouldThrowWarning) warningArray.push(weightWarning + weight)

        setProductConfirmDrawerReasons(warningArray)
        showProductConfirmDrawer()
      } else {
        submitCatalogItem()
      }
    }
  }

  // debounce this callback so we don't spam redux updates on change of form fields
  updateStore = debounce(() => {
    const { setCatalogItem } = this.props
    const { catalogItem } = this.state

    setCatalogItem(catalogItem)
  }, 200)

  updateItemState = catalogItem => {
    this.setState({ catalogItem }, this.updateStore)
  }

  setFormValue = ({ target: { value, type } }, formKey) => {
    const { catalogItem } = this.state

    const newCatalogItem = {
      ...catalogItem,
      // if the input type is number, ensure we preserve that type in the redux store
      [formKey]: type === 'number' ? Number(value) : value
    }
    this.updateItemState(newCatalogItem)
  }

  setBoolean = formKey => {
    const { catalogItem } = this.state
    const booleanValue = catalogItem[formKey]
    const newCatalogItem = {
      ...catalogItem,
      [formKey]: !booleanValue
    }
    this.updateItemState(newCatalogItem)
  }

  setEffectName = ({ target: { value } }, itemReference) => {
    const { catalogItem } = this.state
    const { effects } = catalogItem
    effects[itemReference].name = value

    // if user types in an effect name and there is no value, auto-set to 0
    if (effects[itemReference].amount === '') {
      effects[itemReference].amount = '1'
    }
    // if a user clears the text field, set amount to empty string
    if (value === '') {
      effects[itemReference].amount = ''
    }

    const newCatalogItem = {
      ...catalogItem,
      effects
    }
    this.updateItemState(newCatalogItem)
  }

  setEffectAmount = (amount, itemReference) => {
    const { catalogItem } = this.state
    const { effects } = catalogItem
    effects[itemReference].amount = amount.toString()

    // we don't want effects with an amount of 0
    if (amount === 0) {
      effects[itemReference].amount = ''
    }

    const newCatalogItem = {
      ...catalogItem,
      effects
    }

    this.updateItemState(newCatalogItem)
  }

  setImageLoading = image => {
    this.setState({ imageIsLoading: true })
  }

  setImage = image => {
    this.setState({ imageIsLoading: false })
    const { catalogItem } = this.state
    const { images } = catalogItem

    const newCatalogItem = {
      ...catalogItem,
      images: [...images, image]
    }
    this.updateItemState(newCatalogItem)
  }

  removeImage = (event, index) => {
    event.preventDefault()
    const { catalogItem } = this.state
    const { images } = catalogItem
    images.splice(index, 1)

    const newCatalogItem = {
      ...catalogItem,
      images: [...images]
    }
    this.updateItemState(newCatalogItem)
  }

  setPreview = preview => {
    this.setState(() => {
      return {
        preview
      }
    })
  }

  handleCopyCatalogId = (catalogItemId) => {
    window.navigator && window.navigator.clipboard.writeText(catalogItemId)
  }

  render () {
    const {
      imageIsLoading,
      isEdit,
      formSubmitAttempted,
      catalogItem
    } = this.state
    const {
      brands,
      species: speciesList,
      statesAvailable,
      strains,
      subtypes,
      types,
      uploadPublicFile
    } = this.props

    const {
      brand,
      cbd,
      cogs = 0,
      description,
      dietary,
      effects = [],
      enabled,
      excise_tax_exempt: exciseTaxExempt,
      highCbd,
      id,
      images = [],
      ingredients,
      name,
      price,
      slug,
      species,
      stateId,
      status,
      strain,
      subtype,
      thc,
      type,
      volume,
      weight
    } = catalogItem

    // make a shallow copy so the catalogItem being used for the form doesn't get mutated
    const catalogItemCopy = { ...catalogItem }
    const productCardProduct = catalogItemCopy.images.length && {
      ...transformCatalogItemToProduct(catalogItemCopy),
      ...catalogItemCopy,
      price: price,
      species: speciesList.find(s => s.id === catalogItemCopy.species) || {}
    }

    const { preview } = this.state

    return (
      <Container>
        <Column componentStyle={{ display: preview ? 'none' : 'flex' }}>
          <Flex
            full
            componentStyle={{
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <StyledH1>
              {isEdit ? name : 'Add a Product to Catalog'}
            </StyledH1>
            {isEdit
              ? (
              <div onClick={event => this.setBoolean('enabled')}>
                Enabled <Switch enabled={enabled} />
              </div>
                )
              : null}
          </Flex>

          <Row componentStyle={{ alignItems: 'center', marginBottom: '1rem' }}>
            <DivMarginRight>{id}</DivMarginRight>
            <StyledButton onClick={() => this.handleCopyCatalogId(id)}>
              <CopyText title='Copy Id' />
            </StyledButton>
          </Row>

          <Column>
            <Row>
              <Column right={1} full>
                <InputLabel content='name *' />
                <input
                  className={formSubmitAttempted && !isValidInput(slug) ? inputError : ''}
                  onChange={event => this.setFormValue(event, 'name')}
                  value={name}
                  placeholder=''
                />
              </Column>

              <Column full>
                <InputLabel content='Status' />
                <div className={selectContainer}>
                  <select
                    className={formSubmitAttempted && !isValidInput(slug) ? inputError : ''}
                    onChange={event => this.setFormValue(event, 'status')}
                    value={status}
                  >
                    {Object.keys(STATUSES).map((status, index) => (
                      <option value={status} key={status}>
                        {STATUSES[status]}
                      </option>
                    ))}
                  </select>
                </div>
              </Column>
            </Row>
          </Column>

          {isEdit
            ? (<Column top={1}>
              <InputLabel content='Slug *' />
              <input
                className={formSubmitAttempted && !isValidInput(slug) ? inputError : ''}
                onChange={event => this.setFormValue(event, 'slug')}
                value={slug}
                placeholder=''
              />
            </Column>)
            : null}

          <Column top={1}>
            <Row>
              <Column full>
                <InputLabel content='Brand *' />
                <div className={selectContainer}>
                  <select
                    className={formSubmitAttempted && !isValidInput(slug) ? inputError : ''}
                    onChange={event => this.setFormValue(event, 'brand')}
                    value={brand}
                  >
                    <option disabled value=''>
                      None
                    </option>
                    {sortOn(brands, 'name').map(b => (
                      <option key={b.id} value={b.id}>
                        {b.name}
                      </option>
                    ))}
                  </select>
                </div>
              </Column>
            </Row>
          </Column>

          <Column top={1}>
            <Row>
              <Column right={1} full>
                <InputLabel content='SRP ($) *' />
                <input
                  className={formSubmitAttempted && !isValidInput(slug) ? inputError : ''}
                  onChange={event => this.setFormValue(event, 'price')}
                  value={price}
                  min={0}
                  placeholder='$'
                  type='number'
                />
              </Column>

              <Column full>
                <InputLabel content='COGS' />
                <input
                  type='number'
                  value={cogs}
                  step={0.01}
                  min={0}
                  onChange={event => this.setFormValue(event, 'cogs')}
                />
              </Column>
            </Row>
          </Column>

          <Column top={1}>
            <Row>
              <Column right={1} full>
                <InputLabel content='Type *' />
                <div className={selectContainer}>
                  <select
                    className={formSubmitAttempted && !isValidInput(slug) ? inputError : ''}
                    onChange={event => this.setFormValue(event, 'type')}
                    value={type}
                  >
                    <option disabled value=''>
                      None
                    </option>
                    {sortOn(types, 'name').map(t => (
                      <option key={t.id} value={t.id}>
                        {t.name}
                      </option>
                    ))}
                  </select>
                </div>
              </Column>

              <Column full>
                <InputLabel content='Subtype *' />
                <div className={selectContainer}>
                  <select
                    className={formSubmitAttempted && !isValidInput(slug) ? inputError : ''}
                    onChange={event => this.setFormValue(event, 'subtype')}
                    value={subtype}
                  >
                    <option disabled value=''>
                      None
                    </option>
                    {sortOn(subtypes, 'name').map(s => (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </select>
                </div>
              </Column>
            </Row>
          </Column>

          <Column top={1}>
            <Row>
              <Column right={1} full>
                <InputLabel content='Strain *' />
                <div className={selectContainer}>
                  <select
                    className={formSubmitAttempted && !isValidInput(slug) ? inputError : ''}
                    onChange={event => this.setFormValue(event, 'strain')}
                    value={strain}
                  >
                    <option disabled value=''>
                      None
                    </option>
                    {sortOn(strains, 'name').map(s => (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </select>
                </div>
              </Column>

              <Column full>
                <InputLabel content='Species *' />
                <div className={selectContainer}>
                  <select
                    className={formSubmitAttempted && !isValidInput(slug) ? inputError : ''}
                    onChange={event => this.setFormValue(event, 'species')}
                    value={species}
                  >
                    <option disabled value=''>
                      None
                    </option>
                    {sortOn(speciesList, 'name').map(s => (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </select>
                </div>
              </Column>
            </Row>
          </Column>

          <Column top={1}>
            <Row>
              <Column right={1} full>
                <InputLabel content='THC' />
                <input
                  type='number'
                  value={thc}
                  min={0}
                  onChange={event => this.setFormValue(event, 'thc')}
                />
              </Column>

              <Column full>
                <InputLabel content='CBD' />
                <input
                  type='number'
                  value={cbd}
                  min={0}
                  onChange={event => this.setFormValue(event, 'cbd')}
                />
              </Column>
            </Row>
          </Column>

          <Column top={1}>
            <Row>
              <Column right={1} full>
                <InputLabel content='Net Weight (g)' />
                <input
                  type='number'
                  value={weight}
                  step={0.01}
                  min={0}
                  onChange={event => this.setFormValue(event, 'weight')}
                />
              </Column>
              <Flex full>
                <Column full>
                  <InputLabel content='Volume (mL)' />
                  <input
                    type='number'
                    value={volume}
                    step={0.01}
                    min={0}
                    onChange={event => this.setFormValue(event, 'volume')}
                  />
                </Column>
              </Flex>
            </Row>
          </Column>
          <Column top={1}>
            <Row>
              <Flex full>
                <Column full>
                  <InputLabel content='Available In *' />
                  <div className={selectContainer}>
                    <select
                      className={formSubmitAttempted && !isValidInput(slug) ? inputError : ''}
                      onChange={event => this.setFormValue(event, 'stateId')}
                      value={stateId}
                    >
                      <option disabled value=''>
                        Add a state
                      </option>
                      {sortOn(statesAvailable, 'name').map((s, index) => (
                        <option key={s.state + index} value={s.state}>
                          {s.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </Column>
              </Flex>
            </Row>
          </Column>

          <Column top={1}>
            <Row>
              <Column full right={1}>
                {/* leaving input label with a blank space to keep consistent form alignment */}
                <InputLabel content='&nbsp;' />
                <div
                  className={switchFormContainer}
                  onClick={event => this.setBoolean('excise_tax_exempt')}
                >
                  <span className={switchFormLabel}>
                    Excise Tax Exempt
                  </span>
                  <Switch enabled={exciseTaxExempt} />
                </div>
              </Column>

              <Column full>
                {/* leaving input label with a blank space to keep consistent form alignment */}
                <InputLabel content='&nbsp;' />
                <div
                  className={switchFormContainer}
                  onClick={event => this.setBoolean('highCbd')}
                >
                  <span className={switchFormLabel}>High CBD?</span>
                  <Switch enabled={highCbd} />
                </div>
              </Column>
            </Row>
          </Column>

          <Column top={2}>
            <InputLabel content='Description' />
            <textarea
              className={formSubmitAttempted && !isValidInput(description) ? inputError : ''}
              onChange={event => this.setFormValue(event, 'description')}
              value={description}
              cols='2'
              rows='5'
            />
          </Column>

          <Column top={1}>
            <InputLabel content='Dietary Information' />
            <textarea
              value={dietary}
              onChange={event => this.setFormValue(event, 'dietary')}
              cols='2'
              rows='3'
            />
          </Column>

          <Column top={1}>
            <InputLabel content='Ingredients' />
            <textarea
              value={ingredients}
              onChange={event => this.setFormValue(event, 'ingredients')}
              cols='2'
              rows='3'
            />
          </Column>

          <Flex top={1}>
            {images.map(({ id, downloadUrl }, index) => (
              <Link
                key={id}
                className={imageFormThumbnail}
                style={{ backgroundImage: `url(${downloadUrl})` }}
                to={{ pathname: downloadUrl }}
                rel='noreferrer'
                target='_blank'
              >
                <button
                  className={imageFormThumbnailDeleteButton}
                  onClick={event => this.removeImage(event, index)}
                >
                  ×
                </button>
              </Link>
            ))}
            {imageIsLoading
              ? (<div className={imageFormThumbnailLoadingContainer}>
                <div className={imageFormThumbnailSpinner}>
                  <LoadingSpinner show />
                </div>
              </div>)
              : null}
            <div
              className={
                formSubmitAttempted && !isValidImage(images)
                  ? inputError
                  : ''
              }
            >
              <UploadFile
                postUpload={this.setImage}
                onRequestUpload={this.setImageLoading}
                uploadFile={uploadPublicFile}
              >
                <img src={uploadImageSvg} />
              </UploadFile>
            </div>
          </Flex>

          {effects.map(({ name, amount }, index) => (
            <Flex top={1} key={index}>
              <EffectSlider
                key={index}
                itemReference={index}
                inputLabel='Effect'
                invalidNameClass={
                  isValidEffect({ name, amount }) ? '' : inputError
                }
                sliderValue={amount}
                sliderName={name}
                setEffectName={this.setEffectName}
                updateSliderValue={this.setEffectAmount}
              />
            </Flex>
          ))}

          <Flex top={3}>
            <Flex full right={1}>
              <StyledEazeButton
                disabled={!catalogItem.images.length}
                type={PRIMARY}
                onClick={() => this.setPreview(true)}
              >
                Preview
              </StyledEazeButton>
            </Flex>

            <Flex full>
              <StyledEazeButton type={HYBRID} onClick={this.submitForm}>
                Submit
              </StyledEazeButton>
            </Flex>
          </Flex>

          {isEdit
            ? (<Flex top={1}>
              <StyledEazeButton type={DANGER} onClick={this.deleteItem} disabled>
                Delete
              </StyledEazeButton>
            </Flex>)
            : null}
        </Column>

        <Column
          componentStyle={{ display: preview ? 'flex' : 'none' }}
          align='center'
          justify='center'
          top={10}
        >
          {productCardProduct && productCardProduct.name && (
            <ProductContainer>
              <ProductCard
                product={productCardProduct}
                cart={{}}
                cartItem={{}}
              />
            </ProductContainer>
          )}

          <Flex full top={5} componentStyle={{ width: '100%' }}>
            <StyledEazeButton type={PRIMARY} onClick={() => this.setPreview(false)}>
              Back
            </StyledEazeButton>
          </Flex>
        </Column>
      </Container>
    )
  }
}

const Container = styled.div`
  height: 100%;
  overflow: auto;
  padding: 1rem;
`

const StyledH1 = styled.h1`
  margin: 0.5rem 0;
`

const StyledEazeButton = styled(Button)`
  font-family: inherit;
`

const DivMarginRight = styled.div`
  margin-right: 1rem;
`

const ProductContainer = styled.div`
  width: 16rem;
`

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &:hover {
    opacity: 0.75;
  }
`

export default EditProduct
