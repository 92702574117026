import t from './actionTypes'

const defaultState = {
  active: {},
  history: []
}

const place = (state = defaultState, { type, payload }) => {
  switch (type) {
    case t.CLEAR_PLACE_STORE:
      return defaultState

    case t.SET_ACTIVE_PLACE:
      return {
        ...state,
        active: payload
      }

    case t.CLEAR_ACTIVE_PLACE:
      return {
        ...state,
        active: {}
      }

    case t.SET_PLACE_HISTORY:
      return {
        ...state,
        history: payload
      }

    case t.CLEAR_PLACE_HISTORY:
      return {
        ...state,
        history: []
      }

    default:
      return state
  }
}

export default place
