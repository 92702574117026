import React from 'react'
import { string } from 'prop-types'
export default function ArchiveIcon ({ size = '36px', title = 'Archive' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 36 36'
      version='1.1'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      aria-labelledby='archive-icon-title'
    >
      <title id='archive-icon-title'>{title}</title>
      <g
        transform='translate(-811.000000, -352.000000) translate(508.000000, 325.000000) translate(238.000000, 27.000000) translate(65.000000, 0.000000)'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <circle fill='#F05B4B' cx={18} cy={18} r={18} />
        <g transform='translate(8.000000, 9.000000)' stroke='#FFF'>
          <rect x='1.5' y='7.5' width={17} height={10} rx={1} />
          <path d='M1 3.5L3.1.7a.5.5 0 0 1 .4-.2h13a.5.5 0 0 1 .4.2L19 3.5H1z' />
          <rect x='7.5' y='10.5' width={5} height={2} rx={1} />
          <rect
            transform='translate(10.000000, 5.500000) scale(1, -1) translate(-10.000000, -5.500000) '
            x='0.5'
            y='3.5'
            width={19}
            height={4}
            rx={1}
          />
        </g>
      </g>
    </svg>
  )
}

ArchiveIcon.propTypes = {
  size: string,
  title: string
}
