import React, { PureComponent } from 'react'
import { array, bool, func, number, object } from 'prop-types'
import styled from '@emotion/styled'
import { css, keyframes } from 'emotion'
import isEmptyObject from 'is-empty-object'
import debounce from 'debounce'
import * as JsSearch from 'js-search'

import Button, { HYBRID, DANGER } from 'components/button'
import Table from 'microcomponents/table'
import QuantityInput from 'microcomponents/quantity-input'
import TableSearch from 'microcomponents/table-pager/search'
import ShowAllItemsSwitch from './show-all-items-switch'
import { generateImageUrl } from 'helpers/get-cloudflare-img-url.js'

const getTableConfig = (isDriversTab, changes, onQuantityChange) => {
  const quantityFormat = (v, row) => {
    const unsavedQtyValue = changes[row.productId] >= 0 ? changes[row.productId] : null

    let value

    if (unsavedQtyValue || unsavedQtyValue === 0) {
      value = unsavedQtyValue
    } else {
      value = v
    }
    return (
      <QuantityInput
        dataTestId={`${row.productId}`}
        key={row.productId}
        updateQuantity={(newQuantity) => onQuantityChange(newQuantity, row)}
        value={value}
      />
    )
  }

  return [
    {
      key: 'photoUrl',
      disableSort: true,
      formatter: imgFormat
    },
    {
      title: 'Type',
      key: 'productType.name'
    },
    {
      title: 'Brand',
      key: 'brand.name'
    },
    {
      title: 'ID',
      key: 'productId'
    },
    {
      title: 'Product',
      key: 'name'
    },
    {
      title: 'Price',
      key: 'price',
      formatter: priceFormat
    },
    {
      title: 'Quantity',
      style: isDriversTab ? { textAlign: 'center' } : null,
      key: 'quantity',
      formatter: isDriversTab ? quantityFormat : null
    }
  ]
}

const imgFormat = (url) => <img src={generateImageUrl(url)} alt='product-image' loading='lazy' height='34' width='34' style={{ borderRadius: '4px' }} />
const priceFormat = (p) => `$${p}`

export default class DriverInventoryTable extends PureComponent {
  static propTypes = {
    focusedDriverId: number,
    inventory: array,
    inventoryChecksum: object,
    isDriversTab: bool,
    trackInventoryChanges: func,
    updateDriverInventory: func
  }

  static defaultProps = {
    inventory: [],
    inventoryChecksum: {}
  }

  state = {
    changes: {},
    disableSave: false,
    searchIndex: {},
    searchResults: [],
    searchStr: '',
    shouldShowAll: this.props.inventory.every((item) => item.quantity === 0)
  }

  componentDidMount () {
    this.buildSearchIndex()
  }

  componentDidUpdate (prevProps, prevState) {
    const { searchIndex, searchStr } = this.state
    if (prevProps.inventory !== this.props.inventory) {
      this.buildSearchIndex()
    }
    if (prevState.searchIndex !== searchIndex) {
      const queryResult = searchIndex.search(searchStr)
      this.setState({ searchResults: queryResult })
    }
  }

  handleDiscardChanges = () => {
    const { trackInventoryChanges } = this.props
    this.setState({ changes: {} }, () => trackInventoryChanges([]))
  }

  formatChanges = () => {
    const { changes } = this.state
    const { inventory } = this.props

    return inventory.map((product) => {
      const { productId, quantity } = product
      let data = {}
      // eslint-disable-next-line no-prototype-builtins
      if (changes.hasOwnProperty(productId)) {
        data.productId = productId
        data.quantity = changes[productId]
      } else {
        data = { productId, quantity }
      }
      return data
    })
  }

  highlightRow = (changes, rowData) => {
    // eslint-disable-next-line no-prototype-builtins
    if (changes.hasOwnProperty(rowData.productId)) return Highlight
  }

  onQuantityChange = (newQuantity, row) => {
    const { productId } = row
    const { inventoryChecksum, trackInventoryChanges } = this.props
    const { changes } = this.state

    const newChanges = { ...changes }
    newChanges[productId] = newQuantity

    if (inventoryChecksum[productId] === newQuantity || isNaN(newQuantity)) {
      delete newChanges[productId]
    }

    this.setState({ changes: newChanges }, () =>
      trackInventoryChanges(Object.keys(this.state.changes))
    )
  }

  handleSaveChanges = async () => {
    const {
      focusedDriverId,
      trackInventoryChanges,
      updateDriverInventory,
      inventoryChecksum
    } = this.props

    this.setState({ disableSave: true })
    await updateDriverInventory(focusedDriverId, this.formatChanges(), inventoryChecksum)

    // state needs to be reset and driversComponent needs to know changes were cleared
    this.setState({ changes: {}, disableSave: false }, () => trackInventoryChanges([]))
  }

  buildSearchIndex = () => {
    const { inventory } = this.props
    const dataToSearch = new JsSearch.Search('catalogItemId')
    dataToSearch.tokenizer = new JsSearch.StopWordsTokenizer(new JsSearch.SimpleTokenizer())
    dataToSearch.addIndex('productId')
    dataToSearch.addIndex('name')
    dataToSearch.addIndex(['brand', 'name'])
    dataToSearch.addIndex(['productType', 'name'])

    dataToSearch.addDocuments(inventory)
    this.setState({ searchIndex: dataToSearch })
  }

  toggleShowAll = () => {
    this.setState({ shouldShowAll: !this.state.shouldShowAll })
  }

  handleSearch = (event) => {
    event.preventDefault()
    const { searchIndex } = this.state
    const query = event.target.value

    const queryResult = searchIndex.search(query)
    this.setState({ searchResults: queryResult, searchStr: query })
  }

  render () {
    const { changes, disableSave, shouldShowAll, searchResults, searchStr } = this.state
    const { inventory, isDriversTab } = this.props

    const stockedInventory = inventory.filter((item) => item.quantity > 0)
    const zeroInventory = inventory.filter((item) => item.quantity === 0)

    let displayedInventory

    if (searchStr) {
      displayedInventory = searchResults
    } else if (shouldShowAll) {
      displayedInventory = stockedInventory.concat(zeroInventory)
    } else {
      displayedInventory = stockedInventory
    }

    const hasUnsavedChanges = !isEmptyObject(changes)
    const unsavedChanges = Object.keys(changes).length

    return (
      <>
        <SearchWrapper>
          <TableSearch
            submit={this.handleSearch}
            setText={debounce(this.handleSearch, 200, true)}
          />
          {isDriversTab && (
            <ShowAllItemsSwitch enabled={shouldShowAll} toggleShowAll={this.toggleShowAll} />
          )}
        </SearchWrapper>
        <Table
          config={getTableConfig(isDriversTab, changes, this.onQuantityChange)}
          data={displayedInventory}
          noDataMsg='Not Found'
          rowStyle={(data) => this.highlightRow(changes, data)}
          style={tableStyle}
        />
        <SaveBar hasUnsavedChanges={hasUnsavedChanges}>
          <SaveBarWrapper>
            <span>
              Updating {unsavedChanges} {unsavedChanges > 1 ? 'Products' : 'Product'}
            </span>
            <BtnContainer>
              <SaveBarBtn
                componentStyle={{ marginRight: '2rem' }}
                onClick={this.handleDiscardChanges}
                type={DANGER}
              >
                Discard Changes
              </SaveBarBtn>
              <SaveBarBtn
                componentStyle={{ padding: '1rem' }}
                onClick={this.handleSaveChanges}
                type={HYBRID}
                disabled={disableSave}
              >
                Save Changes
              </SaveBarBtn>
            </BtnContainer>
          </SaveBarWrapper>
        </SaveBar>
      </>
    )
  }
}

const tableStyle = { marginBottom: '3rem' }

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const SaveBarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
`

const BtnContainer = styled.div`
  display: flex;
  max-width: 32rem;
`

const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

const SaveBar = styled.div`
  display: ${({ hasUnsavedChanges }) => hasUnsavedChanges ? 'flex' : 'none'};
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 6rem;
  padding: 1rem;
  box-shadow: 0 -1rem 10rem rgba(0, 0, 0, 0.25);
  background-color: #404242;
  animation: ${({ hasUnsavedChanges }) => hasUnsavedChanges ? `${fadeIn} 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both` : ''}
`

const SaveBarBtn = styled(Button)`
  font-family: inherit;
  min-width: 14rem;
  padding: 1rem;
`

const Highlight = css`
  background-color: #00aae7 !important;
  color: #fff;

  &:hover {
    opacity: 1;
  }
`
