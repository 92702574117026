import api from 'api'
import t from './actionTypes'
import isEmptyObject from 'is-empty-object'
import { pushNotification } from 'src/redux/alerts/actions'
import { CREDIT_CARD_PAYMENT_METHOD } from 'src/helpers/constants'
import {
  requestUser,
  receiveUser,
  requestMenu,
  receiveMenu,
  requestCatalogBuilderEta,
  receiveCatalogBuilderEta,
  requestCatalogBuilderQuote,
  receiveCatalogBuilderQuote
} from 'src/redux/loading/actions'
import { formatCartProducts, formatConciergeUrl, formatPhoneNumber } from './format'
import { setMenu, clearMenus } from 'src/redux/menus/actions'
import { formatMenu } from 'src/redux/menus/helpers'
import { setGroups, clearGroups } from 'src/redux/groups/actions'
import { setProducts, clearProducts } from 'src/redux/products/actions'

export function fetchUser (id) {
  return async (dispatch) => {
    dispatch(requestUser())
    const {err, data} = await api.getUserProfile({id})
    dispatch(receiveUser())
    if (err) return

    dispatch(setUser(data))
  }
}

export function setUser (user) {
  // set a formatted phone number which isn't provided by getUserProfile
  const { basic } = user
  basic.mobilePhoneDisplay = formatPhoneNumber(basic.mobilePhone)

  return {
    type: t.SET_ORDER_BUILDER_USER,
    payload: user
  }
}

export function clearUser () {
  return {
    type: t.CLEAR_ORDER_BUILDER_USER
  }
}

export function fetchMenu (placeId, slug) {
  return async (dispatch) => {
    dispatch(requestMenu())

    const {err, data} = await api.getProducts({ place_id: placeId, slug })
    dispatch(receiveMenu())
    if (err) return

    const { menuMap, groupsMap, productsMap } = formatMenu(data)

    // clear out products, groups, menus stores so they only reflects what's being used
    dispatch(clearMenus())
    dispatch(clearGroups())
    dispatch(clearProducts())

    dispatch(setCatalogBuilderMenuSlug(slug))
    dispatch(setMenu(menuMap, slug))
    dispatch(setGroups(groupsMap))
    dispatch(setProducts(productsMap))
  }
}

export function fetchOutOfDepotCatalogItems (catalogItemErrors) {
  return (dispatch) => {
    const callList = catalogItemErrors.map(error => api.getCatalogItem({ id: error.catalogItemId }))

    Promise.all(callList).then(res => {
      const catalogItems = res.filter(item => item.data).map(item => item.data)
      let errorItemsToShow = [...catalogItemErrors]
      let errorIdsToRemove = []

      // look through this function's original error items and see if they are included in the response
      // if there is an item NOT in the response, it was not found so we should filter it out
      errorItemsToShow = catalogItemErrors.filter(error => {
        const errorId = error.catalogItemId
        let shouldRemove = true

        catalogItems.forEach(catItem => {
          if (catItem.id === errorId) {
            shouldRemove = false
          }
        })

        if (shouldRemove) {
          console.info(`${errorId} is being removed from the case because it was not found in catalog`)
          dispatch(pushNotification('There is an issue with one ore more products. SAVE the template to remove this product.'))
          errorIdsToRemove.push(errorId)
          return false
        } else {
          return true
        }
      })

      dispatch(setOutOfDepotCatalogItems(catalogItems, errorItemsToShow, errorIdsToRemove))
    })
  }
}

export function removeOutOfDepotItem (catalogItemId) {
  return (dispatch, getState) => {
    const { catalogBuilder: { catalogBucket, outOfDepotItems } } = getState()
    const newCatalogBucket = {...catalogBucket}
    delete newCatalogBucket[catalogItemId]
    const newOutOfDepotItems = outOfDepotItems.filter(item => item.id !== catalogItemId)

    dispatch({
      type: t.REMOVE_OUT_OF_DEPOT_CATALOG_ITEM,
      payload: {
        catalogBucket: newCatalogBucket,
        outOfDepotItems: newOutOfDepotItems
      }
    })
  }
}

// this function is called when we have fetched a case that has errors in it and those erroneous
// items have been searched for in the catalog.  If we found a corresponding catalogItem we will
// set it in the bucket as an error, but if we didn't find it in the catalog, then that
// means we should modify the current case to remove not found items
function setOutOfDepotCatalogItems (catalogItems, errorsToShow, errorsToRemove) {
  return (dispatch, getState) => {
    const newCatalogBucket = {...getState().catalogBuilder.catalogBucket}
    let errorIdMap = {}

    errorsToShow.forEach(catalogItem => {
      errorIdMap[catalogItem.catalogItemId] = catalogItem.status
    })

    errorsToRemove.forEach(errorId => {
      delete newCatalogBucket[errorId]
    })

    dispatch({
      type: t.SET_OUT_OF_DEPOT_CATALOG_ITEMS,
      payload: {
        catalogItems,
        errorIdMap,
        catalogBucket: newCatalogBucket
      }
    })
  }
}

export function setCatalogBuilderMenuSlug (menuSlug) {
  return {
    type: t.SET_ORDER_BUILDER_MENU_SLUG,
    payload: menuSlug
  }
}

export function clearCatalogBuilderMenuSlug () {
  return {
    type: t.CLEAR_ORDER_BUILDER_MENU_SLUG
  }
}

export function setDepotId (depotId) {
  return {
    type: t.SET_ORDER_BUILDER_DEPOT_ID,
    payload: depotId
  }
}

export function clearDepotId () {
  return {
    type: t.CLEAR_ORDER_BUILDER_DEPOT_ID
  }
}

export function setCartId (cartId) {
  return {
    type: t.SET_ORDER_BUILDER_CART_ID,
    payload: cartId
  }
}

export function setCatalogBucketId (catalogBucketId) {
  return {
    type: t.SET_ORDER_BUILDER_CATALOG_BUCKET_ID,
    payload: catalogBucketId
  }
}

function handleCartErrors (err) {
  return function (dispatch, getState) {
    const { catalogBuilder, products, place: { active } } = getState()
    const { message } = err
    const shouldCreateNewCart = message.match(/expired|deleted|ordered/)
    const shouldIgnoreError = message.match(/modified|promo/)

    // with certain errors we want to create a new cart and suppress errors
    if (shouldCreateNewCart) {
      // create new cart
      const cartBody = createCreateCartBody(catalogBuilder, products, active)
      dispatch(createCart(cartBody))
    } else if (!shouldIgnoreError) {
      dispatch(pushNotification(err.message || err))
    }
  }
}

export function createCart (cartBody) {
  return async function (dispatch) {
    if (!cartBody.userId) return

    const {err, data} = await api.createNewCart(cartBody)
    if (err) return dispatch(handleCartErrors(err))

    const { id } = data
    dispatch(setCartId(id))
    // only fetch quote on create if we have products in cartBody
    if (cartBody.products.length) {
      dispatch(fetchCartQuote(id))
    }
  }
}

export function updateCart (id, products, menuSlug) {
  return async function (dispatch) {
    const payload = {
      id,
      products,
      menu: menuSlug
    }
    const {err} = await api.updateCart(payload)
    if (err) return dispatch(handleCartErrors(err))

    dispatch(fetchCartQuote(id))
  }
}

export function createCatalogCart (cart) {
  return async function (dispatch) {
    const body = { quantities: cart }

    const {err, data} = await api.createCatalogCart(body)
    if (err) return

    const { id } = data
    dispatch(setCatalogBucketId(id))
  }
}

export function updateCatalogCart (cart, id) {
  return async function (dispatch) {
    const body = { quantities: cart }
    await api.updateCatalogCart({...body, id})
  }
}

function handleCreateConciergeCartErrors (err) {
  return function (dispatch) {
    const { message } = err
    const slugNotUnique = message.match(/Slug/)

    if (slugNotUnique) {
      // create new cart
      dispatch(pushNotification('There was an error sending this cart, please click SEND CART again.'))
    } else {
      dispatch(pushNotification(err.message || err))
    }
  }
}

export function createConciergeCart (payload) {
  return async function (dispatch) {
    const {err, data} = await api.createConciergeCart(payload)
    if (err) return dispatch(handleCreateConciergeCartErrors(err))

    const { slug } = data
    dispatch(setConciergeSlug(slug))
    dispatch(handleConciergeCart(data))
  }
}

export function updateConciergeCart (payload) {
  return async function (dispatch) {
    const {err, data} = await api.updateConciergeCart(payload)
    if (err) return

    const { slug } = data
    dispatch(setConciergeSlug(slug))
    dispatch(handleConciergeCart(data))
  }
}

function handleConciergeCart (res) {
  return function (dispatch) {
    const { slug, userId } = res
    const smsPayload = {
      userId,
      url: formatConciergeUrl(slug)
    }
    dispatch(sendConciergeSms(smsPayload))
  }
}

export function sendConciergeSms (payload) {
  return async function (dispatch) {
    const {err} = await api.sendConciergeSms(payload)
    if (err) return

    dispatch(setShowOrderDrawer(true))
  }
}

export function fetchConciergeCart (payload) {
  return async function (dispatch) {
    const {err, data} = await api.fetchConciergeCart(payload)
    if (err) return

    const { slug } = data
    dispatch(setConciergeSlug(slug))
  }
}

export function setConciergeSlug (slug) {
  return {
    type: t.SET_ORDER_BUILDER_CONCIERGE_SLUG,
    payload: slug
  }
}

export function clearConciergeSlug (slug) {
  return {
    type: t.CLEAR_ORDER_BUILDER_CONCIERGE_SLUG
  }
}

export function onPlaceFetched (err, place) {
  return function (dispatch) {
    if (err) return

    dispatch(handleUpdateCart(place))
    dispatch(handleUpdateDepot(place))
    dispatch(handleUpdateEta(place))
  }
}

// when we fetch a new place, kick off an initial ETA request to keep the time current
function handleUpdateEta (place) {
  return function (dispatch, getState) {
    const { catalogBuilder } = getState()
    const { catalogBucketId } = catalogBuilder

    // only fire an ETA request if the location is exact and has a depot
    if (catalogBucketId && place.exact && place.depots.length) {
      dispatch(fetchCatalogCartEta(catalogBucketId, place.id))
    }
  }
}

function createCreateCartBody (catalogBuilder, products, place) {
  const {
    catalogBucketId,
    user: {
      basic: { userId }
    },
    catalogBucket,
    menuSlug
  } = catalogBuilder

  const cartProducts = formatCartProducts(catalogBucket, products)
  const cartBody = {
    catalogBucketId,
    userId,
    products: cartProducts,
    location: place.id,
    menu: menuSlug
  }

  return cartBody
}

// when we fetch a new place we want to create a new catalog cart with
// that location if it's exact. If not, we want to clear the cart ID
function handleUpdateCart (place) {
  return function (dispatch, getState) {
    if (place.exact) {
      const { catalogBuilder, products } = getState()
      const cartBody = createCreateCartBody(catalogBuilder, products, place)

      dispatch(createCart(cartBody))
    } else {
      dispatch(clearCartId())
      dispatch(clearCatalogCartEta())
      dispatch(clearCartQuote())
    }
  }
}

function handleUpdateDepot (place) {
  return function (dispatch, getState) {
    const { catalogBuilder } = getState()
    const { depotId, menuSlug } = catalogBuilder
    const { id: nextDepotId = null } = (place.depots && place.depots[0]) || {}

    if (nextDepotId && nextDepotId !== depotId) {
      dispatch(resetMenu())
      dispatch(fetchMenu(place.id, menuSlug))
    }

    if (!nextDepotId) {
      // if there is no depot ID, clear our menu/cart
      dispatch(clearCartId())
      dispatch(clearCatalogCart())
      dispatch(clearCatalogBucketId())
      dispatch(clearCatalogCartEta())
      dispatch(clearCartQuote())
    }

    dispatch(setDepotId(nextDepotId))
  }
}

export function resetMenu () {
  return function (dispatch, getState) {
    const { place: { active }, catalogBuilder, products } = getState()

    dispatch(clearConciergeSlug())
    dispatch(clearCartId())
    dispatch(clearCatalogCart())
    dispatch(clearCatalogBucketId())
    dispatch(createCatalogCart({}))
    dispatch(clearCatalogCartEta())
    dispatch(clearCartQuote())

    // if the place is exact, ensure we create a deliverable cart
    if (active.exact) {
      const cartBody = createCreateCartBody(catalogBuilder, products, active)

      dispatch(createCart(cartBody))
    }
  }
}

function handleQuoteErrors (err) {
  return function (dispatch) {
    const { message } = err
    const shouldClearQuote = message.match(/minimum/)
    const shouldIgnoreError = message.match(/promo|minimum/)

    if (!shouldIgnoreError) {
      dispatch(pushNotification(err.message || err))
    }

    if (shouldClearQuote) {
      dispatch(clearCartQuote())
    }
  }
}

export function fetchCartQuote (cartId) {
  return async function (dispatch, getState) {
    const { catalogBuilder: { catalogCart } } = getState()
    const params = {
      cartId,
      paymentMethod: CREDIT_CARD_PAYMENT_METHOD
    }
    dispatch(requestCatalogBuilderQuote())

    const {err, data} = await api.requestQuote(params)
    dispatch(receiveCatalogBuilderQuote())
    if (err) return dispatch(handleQuoteErrors(err))

    const { priceInfo } = data

    if (!isEmptyObject(catalogCart)) {
      const price = {
        difference: priceInfo.keepTheChange || 0,
        inviteCredit: priceInfo.inviteCredit || 0,
        promoCredit: priceInfo.promoCredit || 0,
        subtotal: priceInfo.subtotal || 0,
        tax: priceInfo.totalTax || 0,
        total: priceInfo.chargeAmount
      }

      dispatch(setCartQuote(price))
    }
  }
}

export function fetchCatalogCartEta (catalogCartId, placeId) {
  return async function (dispatch, getState) {
    const { catalogBuilder: { catalogCart } } = getState()
    const params = {
      id: catalogCartId,
      place_id: placeId
    }
    dispatch(requestCatalogBuilderEta())

    const {err, data} = await api.getCatalogCartDelivery(params)
    dispatch(receiveCatalogBuilderEta())
    if (err) return

    const { deliveryInfo = {} } = data

    if (!isEmptyObject(catalogCart)) {
      dispatch(setCatalogCartEta(deliveryInfo))
    }
  }
}

export function setCartQuote (cartQuote) {
  return {
    type: t.SET_ORDER_BUILDER_CART_QUOTE,
    payload: cartQuote
  }
}

export function clearCartQuote () {
  return {
    type: t.CLEAR_ORDER_BUILDER_CART_QUOTE
  }
}

export function setCatalogCartEta (catalogCartEta) {
  return {
    type: t.SET_ORDER_BUILDER_CATALOG_CART_ETA,
    payload: catalogCartEta
  }
}

export function clearCatalogCartEta () {
  return {
    type: t.CLEAR_ORDER_BUILDER_CATALOG_CART_ETA
  }
}

export function clearCartId () {
  return {
    type: t.CLEAR_ORDER_BUILDER_CART_ID
  }
}

export function clearCatalogBucketId () {
  return {
    type: t.CLEAR_ORDER_BUILDER_CATALOG_BUCKET_ID
  }
}

export function setCatalogBucket (catalogBucket) {
  return {
    type: t.SET_ORDER_BUILDER_CATALOG_BUCKET,
    payload: catalogBucket
  }
}

export function clearCatalogCart () {
  return {
    type: t.CLEAR_ORDER_BUILDER_CATALOG_CART
  }
}

export function setProductsFilter (productsFilter) {
  return {
    type: t.SET_ORDER_BUILDER_PRODUCTS_FILTER,
    payload: productsFilter
  }
}

export function clearProductsFilter () {
  return {
    type: t.CLEAR_ORDER_BUILDER_PRODUCTS_FILTER
  }
}

export function clearCatalogBuilderStore () {
  return {
    type: t.CLEAR_ORDER_BUILDER_STORE
  }
}

export function setShowOrderDrawer (showOrderDrawer) {
  return {
    type: t.SET_ORDER_BUILDER_SHOW_ORDER_DRAWER,
    payload: showOrderDrawer
  }
}
