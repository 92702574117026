import React from 'react'

import { tagTypeColor, tagTypeStyle } from './helpers'

export default function StopwatchTag ({ color = tagTypeColor, height = '16px', width = '16px', style = tagTypeStyle }) {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox='0 0 14 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Stopwatch</title>
      <desc>Created with Sketch.</desc>
      <g id='MC' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Stopwatch' transform='translate(-5.000000, -4.000000)' fill={color}>
          <g transform='translate(5.000000, 4.000000)' id='noun_timer_1515733'>
            <g>
              <path d='M13.8307714,3.51227854 C13.5720518,3.25509379 13.15421,3.25509379 12.8954904,3.51227854 L11.9369933,4.46745911 C10.8761482,3.46088111 9.51144871,2.83451903 8.0565722,2.68644534 L8.0565722,1.32663968 L9.38321188,1.32663968 C9.74955331,1.32663968 10.0465317,1.02966127 10.0465317,0.663319838 C10.0465317,0.296978407 9.74955331,0 9.38321188,0 L5.40329285,0 C5.03695142,0 4.73997301,0.296978407 4.73997301,0.663319838 C4.73997301,1.02966127 5.03695142,1.32663968 5.40329285,1.32663968 L6.72993252,1.32663968 L6.72993252,2.68644534 C5.27505601,2.83451903 3.91035652,3.46088111 2.84951147,4.46745911 L1.8910143,3.51227854 C1.62783534,3.28689785 1.23552551,3.30205108 0.990515689,3.5470609 C0.745505872,3.79207072 0.730352644,4.18438055 0.955733333,4.44755951 L1.97724588,5.47238866 C0.0296927487,8.22667932 0.459438976,12.0040038 2.97592267,14.250421 C5.49240636,16.4968381 9.29409836,16.4968381 11.8105821,14.250421 C14.3270657,12.0040038 14.756812,8.22667932 12.8092588,5.47238866 L13.8307714,4.44755951 C14.0879561,4.18883993 14.0879561,3.77099812 13.8307714,3.51227854 Z M7.39325236,14.5930364 C4.46252092,14.5930364 2.08669366,12.2172092 2.08669366,9.28647773 C2.08669366,6.35574629 4.46252092,3.97991903 7.39325236,3.97991903 C10.3239838,3.97991903 12.6998111,6.35574629 12.6998111,9.28647773 C12.6998111,10.6938646 12.1407288,12.0436086 11.145556,13.0387814 C10.1503832,14.0339542 8.80063923,14.5930364 7.39325236,14.5930364 Z' id='Shape' fillRule='nonzero' />
              <path d='M10.0465317,8.62315789 L8.0565722,8.62315789 L8.0565722,5.96987854 C8.0565722,5.60353711 7.75959379,5.3065587 7.39325236,5.3065587 C7.02691093,5.3065587 6.72993252,5.60353711 6.72993252,5.96987854 L6.72993252,9.28647773 C6.72993252,9.65281916 7.02691093,9.94979757 7.39325236,9.94979757 L10.0465317,9.94979757 C10.4128731,9.94979757 10.7098516,9.65281916 10.7098516,9.28647773 C10.7098516,8.9201363 10.4128731,8.62315789 10.0465317,8.62315789 Z' id='Path' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
