import React, { PureComponent } from 'react'
import { bool, func, number, oneOfType, string } from 'prop-types'
import styled from '@emotion/styled'
import { css } from 'emotion'
import debounce from 'debounce'

import { DRIVER_ORDERS_KEY, DRIVER_INVENTORY_KEY } from 'helpers/deliveries'
import { windowConfirmUnsavedChanges } from 'helpers/drivers'

import LoadingNavigation from 'components/loading-navigation'

import { mcBlue, midGray, lightGray } from 'src/helpers/css-variables'

export default class ContentNavigation extends PureComponent {
  static propTypes = {
    didInventoryChange: bool,
    driverDetailsContent: string,
    fetchFocusedDriverInventory: func,
    focusedDriverId: oneOfType([number, string]).isRequired,
    isInventoryLoading: bool.isRequired,
    isOrdersLoading: bool.isRequired,
    setDriverDetailsContent: func.isRequired,
    trackInventoryChanges: func
  }

  static defaultProps = {
    driverDetailsContent: '',
    isInventoryLoading: false,
    isOrdersLoading: false,
    setDriverDetailsContent: function () {
      console.warn(
        'setDriverDetailsContent() is not implimented in <ContentNavigation />'
      )
    }
  }

  state = {
    hasDriverIdChanged: false
  }

  componentDidUpdate (prevProps, prevState) {
    const { focusedDriverId } = this.props
    const hasDriverIdChanged = focusedDriverId !== prevProps.focusedDriverId

    this.setState({ hasDriverIdChanged })
  }

  handleNavigationClick = contentKey => event => {
    const {
      didInventoryChange,
      driverDetailsContent,
      setDriverDetailsContent,
      trackInventoryChanges,
      focusedDriverId
    } = this.props

    if (contentKey === DRIVER_INVENTORY_KEY) {
      // When driver profile opens on deliveries page, only call for driver inventory when we click on inventory tab
      this.debounceFetchDriverInventory(focusedDriverId)
    }

    if (driverDetailsContent === DRIVER_INVENTORY_KEY && didInventoryChange) {
      if (windowConfirmUnsavedChanges()) {
        trackInventoryChanges([])
        setDriverDetailsContent(contentKey)
      }
    } else {
      setDriverDetailsContent(contentKey)
    }
  }

  debounceFetchDriverInventory = debounce(
    focusedDriverId => {
      const { fetchFocusedDriverInventory } = this.props

      // This disables prefetching of driver inventory in Deliveries page because fetchFocusedDriverInventory is only passed into Deliveries page right now.
      fetchFocusedDriverInventory &&
        fetchFocusedDriverInventory(focusedDriverId)
    },
    60000,
    true
  )

  render () {
    const { hasDriverIdChanged } = this.state
    const {
      driverDetailsContent,
      isInventoryLoading,
      isOrdersLoading
    } = this.props

    const ordersActiveClass =
      driverDetailsContent === DRIVER_ORDERS_KEY ? NavigationLinkActive : ''
    const inventoryActiveClass =
      driverDetailsContent === DRIVER_INVENTORY_KEY ? NavigationLinkActive : ''

    const ordersLoadingClass = isOrdersLoading ? NavigationLoading : ''
    const inventoryLoadingClass = isInventoryLoading ? NavigationLoading : ''

    return (
      <NavigationContainer>
        <LoadingNavigation
          customClass={`${NavigationLink} ${ordersActiveClass} ${ordersLoadingClass}`}
          handleClick={this.handleNavigationClick(DRIVER_ORDERS_KEY)}
          isLoading={isOrdersLoading}
          pollFrequency={500}
          shouldResetLoading={hasDriverIdChanged}
          widthStepDivisor={10}
        >
          Orders
        </LoadingNavigation>
        <LoadingNavigation
          customClass={`${NavigationLink} ${inventoryActiveClass} ${inventoryLoadingClass}`}
          handleClick={this.handleNavigationClick(DRIVER_INVENTORY_KEY)}
          isLoading={isInventoryLoading}
          pollFrequency={500}
          shouldResetLoading={hasDriverIdChanged}
          widthStepDivisor={10}
        >
          Inventory
        </LoadingNavigation>
      </NavigationContainer>
    )
  }
}

const BORDER_BOTTOM_HEIGHT = '0.3rem'

const NavigationContainer = styled.nav`
  display: flex;
  justify-content: center;
`

const NavigationLinkActive = css`
  box-shadow: inset 0 -${BORDER_BOTTOM_HEIGHT} 0 0 ${mcBlue};
  color: ${lightGray};
`

const NavigationLink = css`
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1.4rem;
  padding-bottom: 0.6rem;
  letter-spacing: 0.03rem;
  font-weight: bold;
  color: ${midGray};

  &:not(:last-child) {
    margin-right: 2.4rem;
  }

  &:hover {
    color: ${lightGray};

    &::after {
      border-bottom-style: solid;
    }
  }

  &::after {
    border-bottom-width: ${BORDER_BOTTOM_HEIGHT};
    border-bottom-style: none;
  }
`

const NavigationLoading = css`
  box-shadow: none;

  &::after {
    border-bottom-style: solid;
  }
`
