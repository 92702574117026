// This is purely a UI concern for showing different user segment controls
export const USER_SEGMENTS = {
  allUsers: 'All users',
  orderHistory: 'Order history',
  custom: 'Custom'
}

export const getUserSegmentType = (promo) => {
  const { rules } = promo
  const { user_cohort = {} } = rules
  const hasUserIds = user_cohort.user_ids
  const hasOrderHistory = rules.order_history
  const isAllUserSegment = (!hasUserIds && !hasOrderHistory)

  if (isAllUserSegment) return USER_SEGMENTS.allUsers
  else if (hasOrderHistory) return USER_SEGMENTS.orderHistory
  else if (hasUserIds) return USER_SEGMENTS.custom
}

// This represents the values used for promo.rules.order_history in a less confusing way
export const ORDER_HISTORY = {
  existingUsers: 'some',
  newUsers: 'none',
  // String value b/c HTML can't accept null, and an empty string is less explicit/obvious (we need null to strip out properties on payload generation).
  allUsers: 'null'
}
