import t from './actionTypes'
import { DRIVER_ORDERS_KEY } from 'helpers/deliveries'

const defaultState = {
  mapView: false,
  depotOverview: false,
  filter: [],
  mode: '',
  depotFilterString: '',
  maps: {},
  deliveryType: '',
  directionService: {},
  driverDetailsContent: DRIVER_ORDERS_KEY,
  focusedDriverId: undefined,
  driverOrdersLoadingMap: {},
  driverInventoryLoadingMap: {}
}

const Deliveries = (state = defaultState, { type, payload }) => {
  switch (type) {
    case t.SET_DELIVERY_TYPE:
      return {
        ...state,
        deliveryType: payload
      }

    case t.TOGGLE_DELIVERIES_MAP_VIEW:
      return {
        ...state,
        mapView: payload
      }

    case t.SET_DELIVERIES_DEPOT_FILTER_STRING:
      return {
        ...state,
        depotFilterString: payload
      }

    case t.SET_DELIVERIES_MODE:
      return {
        ...state,
        mode: payload
      }

    case t.SET_FOCUSED_DELIVERIES_DRIVER_ID:
      return {
        ...state,
        focusedDriverId: payload
      }

    case t.SAVE_DELIVERIES_MAP:
      return {
        ...state,
        maps: payload
      }

    case t.TOGGLE_DELIVERIES_DEPOT_OVERVIEW:
      return {
        ...state,
        depotOverview: !state.depotOverview
      }

    case t.SET_DELIVERIES_DRIVER_DETAILS_CONTENT:
      return {
        ...state,
        driverDetailsContent: payload
      }

    case t.SET_DELIVERIES_DRIVER_ORDERS_LOADING_MAP:
      return {
        ...state,
        driverOrdersLoadingMap: payload
      }

    case t.SET_DELIVERIES_DRIVER_INVENTORY_LOADING_MAP:
      return {
        ...state,
        driverInventoryLoadingMap: payload
      }

    case t.SET_DELIVERIES_DEPOT_FILTER:
      return {
        ...state,
        filter: [...payload]
      }

    case t.CLEAR_DELIVERIES_STORE:
      return defaultState

    default:
      return state
  }
}

export default Deliveries
