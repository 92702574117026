import { css } from 'emotion'
import { borderContainerLight, primaryDark, white } from 'helpers/css-variables'

export const container = css`
  &:before {
    content: '';
    position: absolute;
    background: center/60% no-repeat url('assets/place/pin.svg');
    bottom: 0;
    top: 0;
    left: 0.7rem;
    padding: 2rem 1rem;

    @media (max-width: 767px) {
      background-size: 50%;
    }
  }
`

export const input = css`
  font-size: 1.6rem;
  border: 1px solid ${primaryDark};
  color: ${primaryDark};
  padding-left: 3rem;

  &::placeholder {
    font-size: 1.6rem;
    color: ${primaryDark};
  }
`

export const suggestionRow = css`
  display: flex;
  align-items: center;
  padding: 0.667rem;
`

export const suggestionIcon = css`
  width: 1.4rem;
  height: 2.1rem;
  margin-left: 0.4rem;
  margin-right: 1.4rem;
  display: flex;
  flex-shrink: 0;

  @media (min-width: 375px) and (max-width: 767px) {
    width: 1.667rem;
    margin-left: 0.333rem;
    margin-right: 0.667rem;
  }
`

export const suggestionTitle = css`
  font-size: 1.4rem;

  @media (min-width: 768px) {
    margin-bottom: 0.4rem;
  }
`

export const suggestionBody = css`
  font-size: 1.4rem;
  opacity: 0.5;
`

export const suggestionsContainer = css`
  width: 100%;
  background-color: ${white};
  color: ${primaryDark};
`

export const suggestionsContainerOpen = css`
  border: 1px solid ${borderContainerLight}
`

export const suggestionsList = css`
  list-style: none;
  padding: 0;
  margin: 0;

  &::after {
    content: ''; 
    width: 100%;
    height: 50px;
    display: block;
    background-image: url('assets/place/google.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 12rem;
  }
`

export const suggestion = css`
  cursor: pointer;
  border-bottom: 1px solid ${borderContainerLight};

  @media (min-width: 375px) and (max-width: 767px) {
    font-size: 1.167rem;
  }
`
