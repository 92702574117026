import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Colors from 'components/color'

const Badge = styled.div`
  position: absolute;
  top: -0.7rem;
  right: -0.7rem;
  background-color: ${Colors.danger[1]};
  color: white;
  border-radius: 1.5rem;
  padding: 0.3rem;
  font-size: 1rem;
  box-sizing: content-box;
  height: 1.6rem;
  width: 1.6rem;
  line-height: 1.6rem;
`

export default class BadgeComponent extends PureComponent {
  static defaultProps = {
    count: 0
  }

  static propTypes = {
    count: PropTypes.number
  }

  render () {
    const { count } = this.props

    return (
      <Badge>{count}</Badge>
    )
  }
}
