import React from 'react'

import MCColors from 'src/helpers/css-variables'
const { midGray } = MCColors

export default function PhoneIcon ({ height = '0.75em', width = '0.75em', color = midGray }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 8'
    >
      <path
        d='M.837 0a.776.776 0 0 0-.34.077L5.73 4.995c.128.12.412.12.54 0L11.503.077a.776.776 0 0 0-.34-.077H.837zM.092.505A1.002 1.002 0 0 0 0 .923v6.154C0 7.588.373 8 .837 8h10.326c.464 0 .837-.412.837-.923V.923c0-.15-.034-.293-.092-.418L6.632 5.462a.911.911 0 0 1-1.264 0L.092.505z'
        fill={color}
        fillRule='nonzero'
      />
    </svg>
  )
}
