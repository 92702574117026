import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Colors from 'components/color'
import {
  getTextColor,
  fetchBackgroundColor,
  fetchDisabledBackgroundColor,
  fetchBorderColor,
  getCustomPadding
} from './style-helpers'

import { PRIMARY, TEXT } from './types'
import Badge from './badge'
import Spinner from './spinner'

const Button = styled.button`
  line-height: ${({ pill }) => pill ? '0.2rem 0.5rem' : '1.4rem'};
  padding: ${getCustomPadding};
  font-size: ${({ supersize }) => supersize ? '1.6rem' : '1.2rem'};
  font-weight: ${({ supersize }) => supersize ? '900' : '400'};
  width: ${({ pill }) => pill ? '' : '100%'};
  text-transform: ${({ pill }) => pill ? '' : 'uppercase'};
  background-color: ${fetchBackgroundColor};
  color: ${getTextColor};
  border: 0.1rem solid ${fetchBorderColor};
  border-radius: 0.2rem;
  position: relative;
  cursor: pointer;
  min-width: 5rem;
  font-family: 'Open Sans';
  letter-spacing: 0.03em;
  outline: none;
  white-space: nowrap;
  text-align: center;
  &:hover {
    box-shadow: ${({ type }) => type === TEXT ? 'none' : '0 0.4rem 0.8rem rgba(0,0,0,.2)'};
    opacity: 0.9;
  }
  &[disabled],
  &[disabled]:hover {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: none;
    background-color: ${fetchDisabledBackgroundColor};
    color: ${Colors.accessory['2']};
    border: 1px solid ${Colors.accessory['3']};
  }
`

export default class ButtonComponent extends PureComponent {
  static propTypes = {
    type: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    inverted: PropTypes.bool,
    supersize: PropTypes.bool,
    pill: PropTypes.bool,
    active: PropTypes.bool,
    children: PropTypes.node,
    componentStyle: PropTypes.object,
    notification: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }

  static defaultProps = {
    type: PRIMARY,
    onClick: () => {},
    disabled: false,
    loading: false,
    inverted: false,
    supersize: false,
    pill: false,
    active: false
  }

  render () {
    const { children, componentStyle, loading, notification } = this.props

    return (
      <Button {...this.props} loading='' style={{ ...componentStyle }}>
        {notification && <Badge count={notification} />}
        {loading ? <Spinner /> : children}
      </Button>
    )
  }
}
