// https://firebase.google.com/docs/web/modular-upgrade
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'

import setLocalFirebaseValues from 'src/redux/firebase/actions'

import errorHandler from 'helpers/error-handler'
import { FIREBASE_API_KEY, FIREBASE_DATABASE_URL } from 'helpers/environment'

const config = {
  apiKey: FIREBASE_API_KEY,
  databaseURL: FIREBASE_DATABASE_URL
}

let firebaseCount = 0

export default class Firebase {
  constructor (store) {
    // check that we haven't already created a firebase instance
    if (firebaseCount === 0) {
      firebase.initializeApp(config)
      this.databaseRef = firebase.database().ref('settings/missionControl')
      firebaseCount++

      // listen to firebase and trigger redux updates on value changes
      try {
        this.databaseRef.on('value', (snapshot) => {
          updateRedux(snapshot, store)
        })
      } catch (error) {
        errorHandler(error)
      }
    }
  }
}

const updateRedux = (snapshot, store) => {
  const data = snapshot.val()
  store.dispatch(setLocalFirebaseValues(data))
}
