import React, { PureComponent } from 'react'
import { array, func, object, string } from 'prop-types'
import { format } from 'date-fns-tz'
import styled from '@emotion/styled'
import window from 'global/window'
import shortid from 'shortid'

import { track } from 'src/analytics'

import Button from 'components/button'
import MCColors from 'src/helpers/css-variables'
import types from 'src/microcomponents/input-file/types'
import InputFile from 'src/microcomponents/input-file'
import LoadingSpinner from 'components/spinner'

export default class DepotInventoryCSV extends PureComponent {
  static propTypes = {
    inventory: array,
    depot: object.isRequired,
    email: string.isRequired,
    sleep: func.isRequired,
    getInventoryMap: func.isRequired,
    getDepotInventory: func.isRequired,
    saveBatchChanges: func.isRequired,
    updateInventory: func.isRequired
  }

  state = {
    errors: [],
    inventory: [],
    invMap: {},
    uploading: false,
    downloading: false
  }

  getDepotInventory = async () => {
    const { getDepotInventory, depot, inventory } = this.props
    if (inventory.length !== 0) return inventory
    const { products } = await getDepotInventory(depot.id, -1)
    return products
  }

  toCamelCase = (str) => str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())

  inventoryToCSV = (inventory) => {
    const { depot } = this.props
    const headers = ['Depot Id', 'Product Id', 'Type', 'Brand', 'Product Name', 'Current Count', 'New Count']
    const keys = ['depotId', 'id', 'type', 'brand', 'name', 'maxQuantity', 'newCount']
    const csv = [
      headers.join(','), // headers row first
      ...inventory.map(row => keys.map(fieldName => {
        let value
        switch (fieldName) {
          case 'depotId':
            value = JSON.stringify(depot.id)
            break
          case 'type':
          case 'brand':
            value = JSON.stringify(row[fieldName].name)
            break
          case 'name':
            value = JSON.stringify(row[fieldName].replace(/,|"/g, ''))
            break
          case 'newCount':
            value = ''
            break
          default:
            value = JSON.stringify(row[fieldName])
            break
        }
        return value
      }
      ).join(','))
    ].join('\r\n')

    // handles file naming
    const date = format(new Date(), 'MMddyy')
    const depotCase = depot.name.toLowerCase().replace(' - ', ' ').replace(/\s/g, '_')
    const fileName = `${depotCase}_${depot.id}_${date}.csv`

    // prepares downloadable file
    const url = window.URL.createObjectURL(new Blob([csv]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    this.setState({ downloading: false })
  }

  handleCsvDownload = async (e) => {
    e.preventDefault()
    const { getInventoryMap } = this.props
    this.setState({ downloading: true })
    const inventory = await this.getDepotInventory()
    this.inventoryToCSV(inventory)
    const invMap = await getInventoryMap(inventory)
    this.setState({ inventory, invMap })
  }

  handleCsvUpload = async (file, data) => {
    this.setState({ uploading: true })
    const { depot, saveBatchChanges, getInventoryMap, updateInventory, sleep } = this.props

    const errors = []
    const depotId = depot.id
    const fileName = file.file.name
    const fileDepotId = fileName.split('_').includes(depotId.toString())

    // cancels upload if depotId mismatch
    if (!fileDepotId) {
      errors.push(`"Depot Id" on ${fileName} does not match current depot`)
      this.setState({ errors, uploading: false })
      return
    }

    // no saved inventory on memory
    // call the API to get it again
    let { inventory, invMap } = this.state
    if (inventory.length === 0) {
      inventory = await this.getDepotInventory()
      invMap = await getInventoryMap(inventory)
      this.setState({ inventory, invMap })
    }

    const result = []
    const trackEvents = []
    const uploadId = shortid.generate()
    const lines = data.replace('\r', '').split('\n')
    const headers = lines[0].split(',')
    lines.splice(0, 1) // deletes headers from data

    // csv to object
    for (const [i, line] of lines.entries()) {
      const obj = {}
      const currentLine = line.split(',')
      for (const [j, header] of headers.entries()) {
        const h = this.toCamelCase(header)
        const value = ['depotId', 'productId', 'currentCount'].includes(h) ? Number(currentLine[j]) : currentLine[j]
        obj[h] = value
      }

      // ignores empty rows
      if (
        obj.depotId === 0 &&
        obj.productId === 0 &&
        obj.brand === '' &&
        obj.productName === '' &&
        obj.currentCount === 0 &&
        obj.newCount.trim() === ''
      ) continue

      // ignores rows where newCount is empty/null
      if (obj.newCount.trim() === '') continue

      // item has a depotId mismatch
      if (obj.depotId !== depotId) {
        errors.push(`Row #${i + 2} - ${obj.productName} - "Depot ID" does not match current depot`)
        continue
      }

      // ignores rows where there is a currentCount mismatch
      if (invMap[obj.productId].maxQuantity !== obj.currentCount) {
        errors.push(`Row #${i + 2} - ${obj.productName} - "Current Count" in CSV does not match depot case count`)
        continue
      }

      // ignores rows when newCount is not a number
      if (isNaN(Number(obj.newCount))) {
        errors.push(`Row #${i + 2} - ${obj.productName} - "New Count" in CSV is invalid`)
        continue
      }

      // formats result
      result.push({
        productId: obj.productId,
        quantity: Number(obj.newCount)
      })

      // tracking information
      trackEvents.push({
        depotId,
        uploadId,
        name: obj.productName,
        username: this.props.email,
        productId: obj.productId,
        previousCount: Number(obj.currentCount),
        newCount: Number(obj.newCount)
      })
    }

    // save changes if no errors
    // and actual results
    if (errors.length === 0 && result.length !== 0) {
      const { products } = await saveBatchChanges(depotId, result)
      updateInventory(products)
      // send track events to segment
      // in batches of 200 every 5 seconds
      // to avoid rate-limiting
      let counter = 0
      for (let i = 0; i < trackEvents.length; i++) {
        if (counter === 200) {
          counter = 0
          await sleep(5000)
        }
        track('DepotCase.CSVUpload', trackEvents[i])
        counter++
      }
    } else if (errors.length !== 0) {
      errors.unshift('Please check the following errors:')
    } else {
      errors.push('No products were updated, check your CSV.')
    }

    this.setState({
      errors,
      uploading: false
    })
  }

  render () {
    const { errors, downloading, uploading } = this.state
    return (
      <React.Fragment>
        <CSVButtons>
          <Button
            onClick={this.handleCsvDownload}
            loading={downloading}
          >
            DOWNLOAD CSV
          </Button>
          <InputFile
            name={'upload csv'}
            filetype={types.CSV}
            onFileRead={this.handleCsvUpload}
            readAsText
          >
            { uploading ? <LoadingSpinner /> : 'UPLOAD CSV' }
          </InputFile>
          <Tooltip>?
            <span>
              <strong>Bulk Update Depot Case </strong>
              <p>Press “Download CSV” to download the products and the current quantities of this depot case into a CSV file.</p>
              <p>In the CSV, you can enter a new quantity for each product in the depot case.</p>
              <p>Save the CSV, and then press the “Upload CSV” button to update many products at once.</p>
            </span>
          </Tooltip>
        </CSVButtons>
        <Errors show={errors.length !== 0}>
          <CloseButton
            onClick={() => this.setState({ errors: [] })}
          >
            ×
          </CloseButton>
          { errors.map((el, i) => <li key={i}>{el}</li>) }
        </Errors>
      </React.Fragment>
    )
  }
}

const Tooltip = styled.span`
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: ${MCColors.eazeBlue};
  padding: 13px;
  border-radius: 20px;
  line-height: 0;
  top: 8px;
  padding-left: 9px;
  border: 2px solid white;
  right: -2px;
  color: white;
  font-size: 18px;
  &:hover {
    & span {
      visibility: visible;
    }
  }
  & span {
    visibility: hidden;
    color: #fff;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 999;
    width: 400px;
    line-height: 2.5rem;
    text-align: left;
    padding: 30px;
    background-color: #5C515A;
    top: 35px;
  }
`

const Errors = styled.div`
  display: ${({ show }) => (show ? 'block' : 'none')};
  background-color: ${MCColors.secondaryDark};
  margin: 0 auto 20px auto;
  padding: 30px;
  & li {
    &:first-of-type {
      margin-bottom: 15px;
    }
    list-style-type: none;
  }
`

const CloseButton = styled.button`
  position: relative;
  cursor: pointer;
  border: none;
  padding: 0;
  float: right;
  top: -25px;
  right: -20px;
  height: 2.5rem;
  width: 2.5rem;
  font-size: 2rem;
  background-color: ${MCColors.secondaryDark};
  color: white;
  font-size: 30px;
  text-align: center;
`
const CSVButtons = styled.div`
  display: inline-flex;
  height: 54px;
  width: 350px;
  margin-bottom: 15px;
  & button {
    margin: 0 20px 10px 0;
    font-family: 'Open Sans', 'Fira Sans';
    & + div {
      & label {
        display: block;
        width: 140px;
        height: 44px;
        position: relative;
        top: -15px;
        left: -15px;
        line-height: 370%;
        font-family: 'Open Sans', 'Fira Sans';
        & > div {
          margin: 12px auto !important;
          line-height: 0;
        }
      }
    }
  }

  & > div {
    line-height: 1.4rem;
    padding: 1.4rem;
    font-size: 1.2rem;
    font-weight: 400;
    width: 100%;
    text-transform: uppercase;
    background-color: ${MCColors.eazeBlue};
    color: white;
    border: 0.1rem solid transparent;
    border-radius: 0.2rem;
    position: relative;
    cursor: pointer;
    min-width: 5rem;
    font-family: 'Open Sans';
    -webkit-letter-spacing: 0.03em;
    -moz-letter-spacing: 0.03em;
    -ms-letter-spacing: 0.03em;
    letter-spacing: 0.03em;
    outline: none;
    white-space: nowrap;
    text-align: center;
    margin: 0 20px 10px 0;
  }
`
