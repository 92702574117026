import React from 'react'
import { bool, func, number, string } from 'prop-types'
import { css } from 'emotion'
import Label from 'microcomponents/label'
import Button, { TRANSPARENT } from 'components/button'

const pager = css`
  width: 100%;
  max-width: 250px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const label = css`
  text-align: center;
`

const TablePager = ({ decrement, hideLabel, increment, loading, page, style, total }) => {
  return (
    <div className={pager} style={style}>
      <div style={{ margin: '1rem' }}>
        <Button
          type={TRANSPARENT}
          onClick={decrement}
          disabled={loading || page === 1}
        >
          &#8249;
        </Button>
      </div>
      <Label content={hideLabel ? '' : `${total > 0 ? page : 0} / ${total}`} className={label} />
      <div style={{ margin: '1rem' }}>
        <Button
          type={TRANSPARENT}
          onClick={increment}
          disabled={loading || page === total || total === 0}
        >
          &#8250;
        </Button>
      </div>
    </div>
  )
}

TablePager.propTypes = {
  decrement: func,
  hideLabel: bool,
  increment: func,
  loading: bool,
  page: number,
  style: string,
  total: number
}

export default TablePager
