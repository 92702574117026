/**
 * User reducer, this is separated from auth because we want one central store that always contains these values and nothing else
 */

import tokenStore from '@eaze/eaze-api-client/es/token-store'
import t from './actionTypes.js'

const defaultState = {
  userId: '',
  email: '',
  firstName: '',
  lastName: '',
  xAuthToken: '',
  zipcode: '', // zip code user signed up with, required for berbix
  mobilePhone: '' // required for berbix
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      if (!action.payload || !action.payload.user) return state

      // eslint-disable-next-line
       var userPayload = action.payload.user
      // since we rehydrate multiple stores, let's make sure we're rehydrating the right one
      if (userPayload && userPayload.xAuthToken) {
        // let's set the api client's xAuth value
        tokenStore.set(userPayload.xAuthToken)
      }
      return {
        ...state,
        ...userPayload
      }

    case t.SET_USER_BASIC:
      // this can be triggered in a few places, coming from either (actions/profile) fetchUserProfile, or coming
      // from (actions/auth) receiveLoginSuccess
      return {
        ...state,
        userId: action.user.userId,
        email: action.user.email,
        firstName: action.user.firstName,
        lastName: action.user.lastName,
        mobilePhone: action.user.mobilePhone,
        zipcode: action.user.zipcode
      }

    case t.SET_USER_TOKEN:
      // this comes from (actions/auth) receiveLoginSuccess
      return {
        ...state,
        xAuthToken: action.token
      }

    case t.REMOVE_USER:
      // called when (actions/auth) logout is called
      return defaultState

    case 'LOGOUT':
      // TODO: we have 2 tokenStores (one here in shared-redux and one in .com :sadface:)
      // this has always been the case, but we never realized it cuz we never did xauth actions in shared.
      // when we logout, we invalidate the tokenStore in .com but we also need to do this here
      // the better way to do this will be to have eaze-api-client be constructed one time
      // here in shared-redux and exported so that consumers can have one source of api truth
      tokenStore.set('')
      return state

    default:
      return state
  }
}
