import React, { PureComponent } from 'react'
import { array, func, string } from 'prop-types'
import sortOn from 'sort-on'
import Switch from 'microcomponents/switch'
import Button, { PRIMARY } from 'components/button'

import uploadImageSvg from 'src/assets/upload-image.svg'
import UploadFile from 'microcomponents/upload-file'
import {
  cancelImageButton,
  depotsListButtonWrapper,
  depotsListHeader,
  disableAllDepotsButton,
  enableAllDepotsButton,
  groupSettingsContainer,
  groupSettingsImages,
  inlineImageBlock,
  itemToggle,
  switchText,
  switchWrapper,
  toggleContainer,
  uploadButton
} from '../edit-page-styles.js'

export default class GroupSettingsTab extends PureComponent {
  static propTypes = {
    depotsEnabled: array,
    desktopInlineImage: string.isRequired,
    // disableAllDepots: func,
    mobileInlineImage: string.isRequired,
    saveFormValue: func.isRequired,
    statesAvailable: array,
    setAllDepotsEnabled: func,
    toggleDepotEnabled: func,
    stateId: string,
    uploadPublicFile: func
  }

  thumbNailStyle = {
    height: 'auto',
    width: '12rem'
  }

  enableAllDepots = () => {
    const { setAllDepotsEnabled } = this.props
    setAllDepotsEnabled(true)
  }

  disableAllDepots = () => {
    const { setAllDepotsEnabled } = this.props
    setAllDepotsEnabled(false)
  }

  render () {
    const {
      depotsEnabled,
      desktopInlineImage,
      mobileInlineImage,
      saveFormValue,
      toggleDepotEnabled,
      uploadPublicFile,
      statesAvailable,
      stateId
    } = this.props

    // A "Test Depot" is a depot whose name contains the word "Test" (but not as part of another word, e.g. "Greatest")
    const isTestDepot = (depot) => depot.name.toLowerCase().replaceAll('-', ' ').split(' ').includes('test')
    const testDepots = depotsEnabled.filter(isTestDepot)
    const nonTestDepots = depotsEnabled.filter((depot) => !isTestDepot(depot))

    const depotView = (depotObj) => {
      const depotText = `Depot #${depotObj.id} - ${depotObj.name}`
      const onClick = toggleDepotEnabled(depotObj.id) // creates a curried fn to call later w id
      return (
        <div className={itemToggle} key={depotObj.id}>
          <div className={switchText}>{depotText}</div>
          <div className={switchWrapper} onClick={onClick}><Switch enabled={depotObj.enabled} /></div>
        </div>
      )
    }

    return (
      <div className={groupSettingsContainer}>
        <h3>Menu inline background images</h3>

        <div className={groupSettingsImages}>
          <UploadFile
            postUpload={({ downloadUrl }) => saveFormValue(downloadUrl, 'desktop_inline_image')}
            uploadFile={uploadPublicFile}
          >
            <div className={uploadButton}>
              <img style={{ height: '3rem', width: '3rem' }} src={uploadImageSvg} />
              DESKTOP
            </div>
          </UploadFile>

          <UploadFile
            postUpload={({ downloadUrl }) => saveFormValue(downloadUrl, 'mobile_inline_image')}
            uploadFile={uploadPublicFile}
          >
            <div className={uploadButton}>
              <img style={{ height: '3rem', width: '3rem' }} src={uploadImageSvg} />
              MOBILE
            </div>
          </UploadFile>
        </div>

        <div style={{ marginTop: '2rem' }}>
          <div className={inlineImageBlock}>
            <img style={this.thumbNailStyle} src={desktopInlineImage} />
            {desktopInlineImage && <button
              className={cancelImageButton}
              onClick={() => saveFormValue('', 'desktop_inline_image')}
            >×</button>}
          </div>

          <div className={inlineImageBlock}>
            <img style={this.thumbNailStyle} src={mobileInlineImage} />
            {mobileInlineImage &&
              <button
                className={cancelImageButton}
                onClick={() => saveFormValue('', 'mobile_inline_image')}
              >×</button>
            }
          </div>
        </div>
        <div>
          <select
            onChange={(event) => saveFormValue(event.target.value, 'state_id')}
            value={stateId}
          >
            <option defaultValue disabled value=''>Add a state</option>
            {statesAvailable.map(s => <option key={s.state} value={s.state}>{s.name}</option>)}
          </select>
        </div>

        <div className={toggleContainer}>
          <div className={depotsListHeader}>
            <h3>Depots</h3>
            <div className={depotsListButtonWrapper}>
              <Button
                className={enableAllDepotsButton}
                onClick={this.enableAllDepots}
                type={PRIMARY}
              >
                Enable All
              </Button>
              <Button
                className={disableAllDepotsButton}
                onClick={this.disableAllDepots}
                type={PRIMARY}
                inverted
              >
                Disable All
              </Button>
            </div>
          </div>
          {
            sortOn(nonTestDepots, 'name').map(depotView)
          }

          { testDepots.length > 0 &&
            <div className={depotsListHeader}>
              <h3>Test Depots</h3>
            </div>
          }
          {
            sortOn(testDepots, 'name').map(depotView)
          }
          </div>
        </div>
    )
  }
}
