import { connect } from 'react-redux'
import React from 'react'
import { func } from 'prop-types'
import ROUTES from 'src/pages/catalog/routes'
import * as promoActions from 'src/redux/promos/actions'
import { getFilteredPromosList, getSearchQuery } from 'src/redux/promos/selectors'
import { getPromoValidity } from 'src/redux/manage-promos/selectors'
import * as managePromoActions from 'src/redux/manage-promos/actions'
import { setPromoDepotsAndClearDispensary, setPromoDispensaryAndClearDepots, setPromoStateIdAndClearRelated } from 'src/redux/manage-promos/operations'
import * as catalogActions from 'src/redux/catalog/actions'
import { getSortedCatalogBrands, getSortedCatalogGroups } from 'src/redux/catalog/selectors'
import { fetchAllDispensaries } from 'src/redux/dispensaries/actions'
import { getActiveDepotsGroupedByState } from 'src/redux/dispensaries/selectors'
import Promos from './'
import { unflatten } from 'flat'

const mapStateToProps = (state, props) => {
  return {
    promoValidity: getPromoValidity(state),
    depots: state.depots.list,
    depotsByState: getActiveDepotsGroupedByState(state),
    dispensaries: state.dispensaries.list,
    promos: getFilteredPromosList(state),
    searchQuery: getSearchQuery(state),
    // total: state.customers.total,
    // page: state.customers.page,
    loading: state.loading.promos,
    manageBar: state.promos.manageBar,
    promoPreview: state.promos.promoPreview,
    managePromos: unflatten(state.managePromos),
    catalog: state.catalog,
    sortedCatalogBrands: getSortedCatalogBrands(state, 'name'),
    sortedCatalogGroups: getSortedCatalogGroups(state, 'name'),
    cloneable: state.promos.cloneable,
    promoEditing: state.promos.editPromo
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchPromos: () => dispatch(promoActions.fetchPromos()),
    fetchAllDispensaries: () => dispatch(fetchAllDispensaries()),
    toggleManageBar: () => dispatch(promoActions.composePromo()),
    showPromoPreview: (value) => dispatch(promoActions.showPromoPreview(value)),
    createPromo: () => dispatch(promoActions.createOrUpdatePromo()),
    selectRow: (r) => dispatch(promoActions.selectRow(r)),
    togglePromo: (row) => dispatch(promoActions.togglePromo(row)),
    setPromos: (promos) => dispatch(promoActions.setPromos(promos)),
    clonePromo: () => dispatch(promoActions.clonePromo()),
    editPromo: () => dispatch(promoActions.editPromo()),
    setSearchQuery: (searchQuery) => dispatch(promoActions.setSearchQuery(searchQuery)),
    setPromoActions: {
      // TODO: Standardize the payload across these so we can collapse them into a single method.
      // For now we'll fold them into an object to ease maintenence of props at lower levels.
      getCatalogBrand: (id, nameType) => dispatch(managePromoActions.getCatalogBrand(id, nameType)),
      getCatalogGroup: (id, nameType) => dispatch(managePromoActions.getCatalogGroup(id, nameType)),
      getCatalogItem: (id, nameType) => dispatch(managePromoActions.getCatalogItem(id, nameType)),
      setCatalogName: (name) => dispatch(managePromoActions.setCatalogName(name)),
      setMinOrderValue: (value) => dispatch(managePromoActions.setMinOrderValue(value)),
      setOrderHistoryLimit: (value) => dispatch(managePromoActions.setOrderHistory(value)),
      setPromoAmount: (amount) => dispatch(managePromoActions.setPromoAmount(amount)),
      setPromoAppliesTo: (criteria) => dispatch(managePromoActions.setPromoAppliesTo(criteria)),
      setPromoBuyOneAmount: (value) => dispatch(managePromoActions.setPromoBuyOneAmount(value)),
      setPromoBuyOneIdType: (value) => dispatch(managePromoActions.setPromoBuyOneIdType(value)),
      setPromoBuyOneIdValue: (value) => dispatch(managePromoActions.setPromoBuyOneIdValue(value)),
      setPromoChannel: (channel) => dispatch(managePromoActions.setPromoChannel(channel)),
      setPromoCode: (code) => dispatch(managePromoActions.setPromoCode(code)),
      setPromoCreditMax: (max) => dispatch(managePromoActions.setPromoCreditMax(max)),
      setPromoCreditType: (type) => dispatch(managePromoActions.setPromoCreditType(type)),
      setPromoDescription: (desc) => dispatch(managePromoActions.setPromoDescription(desc)),
      setPromoDepots: (depotIds) => dispatch(setPromoDepotsAndClearDispensary(depotIds)),
      setPromoDispensary: (dispensaryId) => dispatch(setPromoDispensaryAndClearDepots(dispensaryId)),
      setPromoEndDate: (date) => dispatch(managePromoActions.setPromoEndDate(date)),
      setPromoGetOneAmount: (value) => dispatch(managePromoActions.setPromoGetOneAmount(value)),
      setPromoGetOneIdType: (value) => dispatch(managePromoActions.setPromoGetOneIdType(value)),
      setPromoGetOneIdValue: (value) => dispatch(managePromoActions.setPromoGetOneIdValue(value)),
      setPromoGlobalUse: (globalUse) => dispatch(managePromoActions.setPromoGlobalUse(globalUse)),
      setPromoIdType: (id) => dispatch(managePromoActions.setPromoIdType(id)),
      setPromoIdValue: (value) => dispatch(managePromoActions.setPromoIdValue(value)),
      setPromoStartDate: (date) => dispatch(managePromoActions.setPromoStartDate(date)),
      setPromoStateId: (stateId) => dispatch(setPromoStateIdAndClearRelated(stateId)),
      setPromoTargetUser: (targetUser) => dispatch(managePromoActions.setPromoTargetUser(targetUser)),
      setPromoType: (promoType) => dispatch(managePromoActions.setPromoType(promoType)),
      setPromoUserUse: (userUse) => dispatch(managePromoActions.setPromoUserUse(userUse)),
      setPromoUserIds: (userIds) => dispatch(managePromoActions.setPromoUserIds(userIds)),
      setSelectedPromoDefinition: (id) => dispatch(managePromoActions.setSelectedPromoDefinition(id))
    },
    fetchBrands: () => dispatch(catalogActions.mapToCatalogFetch(ROUTES.brands.name))
    // increment: () => dispatch(promoActions.nextCustomerPage()),
    // decrement: () => dispatch(promoActions.prevCustomerPage()),
    // setQuery: (query) => dispatch(promoActions.setQuery(query)),
    // search: () => dispatch(promoActions.search())
  }
}

class PromoContainer extends React.Component {
  static propTypes = {
    fetchAllDispensaries: func,
    fetchPromos: func,
    fetchBrands: func,
    setPromos: func,
    setSearchQuery: func
  }

  componentDidMount () {
    const {
      fetchAllDispensaries,
      fetchPromos,
      fetchBrands
    } = this.props
    // TODO: see if this can be replaced with fetchDispensaries.
    fetchAllDispensaries()
    fetchPromos()
    fetchBrands()
  }

  componentWillUnmount () {
    const { setPromos, setSearchQuery } = this.props
    setPromos([])
    setSearchQuery('')
  }

  render () {
    return (
      <Promos {...this.props} />
    )
  }
}

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PromoContainer)

export default reduxContainer
