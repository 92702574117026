import t from './actionTypes'
import { PRODUCT_FILTER_TYPE } from 'helpers/constants'

export const defaultState = {
  filterString: '',
  filterType: PRODUCT_FILTER_TYPE,
  groupIds: [],
  isTagFormOpen: false,
  tagsMap: {},
  tagTypes: [],
  tagConflicts: {}
}

const tags = (state = defaultState, { type, payload }) => {
  switch (type) {
    case t.SHOW_TAG_FORM:
      return {
        ...state,
        isTagFormOpen: true,
        tagConflicts: defaultState.tagConflicts
      }

    case t.HIDE_TAG_FORM:
      return {
        ...state,
        isTagFormOpen: defaultState.isTagFormOpen,
        tagConflicts: defaultState.tagConflicts
      }

    case t.SET_TAG_GROUPS:
      return {
        ...state,
        groupIds: payload
      }

    case t.SET_TAG_FILTER_TYPE:
      return {
        ...state,
        filterType: payload
      }

    case t.SET_TAG_FILTER_STRING:
      return {
        ...state,
        filterString: payload
      }

    case t.SET_TAGS_MAP:
      return {
        ...state,
        tagsMap: payload
      }

    case t.SET_TAG_TYPES:
      return {
        ...state,
        tagTypes: payload
      }

    case t.UPDATE_TAGS_MAP:
      return {
        ...state,
        tagsMap: {
          ...state.tagsMap,
          ...payload
        }
      }

    case t.SET_TAG_CONFLICTS:
      return {
        ...state,
        tagConflicts: {
          ...payload
        }
      }

    default:
      return state
  }
}

export default tags
