import {
  HIDE_PRODUCT_CONFIRM_DRAWER,
  SET_CATALOG_BRANDS,
  SET_CATALOG_GROUPS,
  SET_CATALOG_MENUS,
  SET_CATALOG_PRODUCTS,
  SET_CATALOG_SORT,
  SET_CATALOG_SPECIES,
  SET_CATALOG_STATE_FILTER,
  SET_CATALOG_STRAINS,
  SET_CATALOG_SUBTYPES,
  SET_CATALOG_TYPES,
  SET_LOCATION_STATES,
  SET_PRODUCT_CONFIRM_DRAWER_REASONS,
  SHOW_PRODUCT_CONFIRM_DRAWER,
  TOGGLE_ACTION_BAR,
  HIDE_ACTION_BAR
} from 'src/redux/catalog/actionTypes'
import {
  SHOW_TYPE_FORM,
  HIDE_TYPE_FORM
} from 'src/redux/catalog/type/actionTypes'
import {
  SHOW_PRODUCT_FORM,
  HIDE_PRODUCT_FORM,
  SET_SKIPPED_DEPOTS
} from 'src/redux/catalog/item/actionTypes'
import {
  SHOW_SUBTYPE_FORM,
  HIDE_SUBTYPE_FORM
} from 'src/redux/catalog/subtype/actionTypes'
import {
  SHOW_STRAIN_FORM,
  HIDE_STRAIN_FORM
} from 'src/redux/catalog/strain/actionTypes'

export const defaultState = {
  products: [],
  groups: [],
  brands: [],
  types: [],
  subtypes: [],
  statesAvailable: [],
  strains: [],
  species: [],
  menus: [],
  skippedDepots: [],

  isActionBarOpen: false,
  isProductFormOpen: false,
  isTypeFormOpen: false,
  isSubtypeFormOpen: false,
  isStrainFormOpen: false,
  isProductConfirmDrawerOpen: false,

  productConfirmDrawerReasons: [],

  sort: {
    products: '-updated_at',
    brands: '-updated_at',
    groups: '-updated_at',
    menus: '-updated_at',
    strains: '-updated_at',
    types: '-updated_at',
    subtypes: '-updated_at'
  },

  catalogStateFilter: '' // state shorthands: 'CA', 'OR', 'WA', etc.
}

const catalog = (state = defaultState, action) => {
  switch (action.type) {
    case SET_CATALOG_PRODUCTS:
      return {
        ...state,
        products: action.payload
      }

    case SET_CATALOG_BRANDS:
      return {
        ...state,
        brands: action.brands
      }

    case SET_CATALOG_MENUS:
      return Object.assign({}, state, {
        menus: action.menus
      })

    case SET_CATALOG_GROUPS:
      return {
        ...state,
        groups: action.payload
      }

    case SET_CATALOG_TYPES:
      return {
        ...state,
        types: action.types
      }

    case SET_CATALOG_SUBTYPES:
      return {
        ...state,
        subtypes: action.types
      }

    case SET_CATALOG_STRAINS:
      return {
        ...state,
        strains: action.strains
      }

    case SET_CATALOG_SPECIES:
      return {
        ...state,
        species: action.species
      }

    case SET_LOCATION_STATES:
      return {
        ...state,
        statesAvailable: action.statesAvailable
      }

    case SET_CATALOG_SORT:
      return {
        ...state,
        sort: {
          ...state.sort,
          [action.typeToSort]: action.sort
        }
      }

    case SET_SKIPPED_DEPOTS:
      return {
        ...state,
        skippedDepots: action.skippedDepots
      }

    case SHOW_TYPE_FORM:
      return {
        ...state,
        isTypeFormOpen: true
      }

    case HIDE_TYPE_FORM:
      return {
        ...state,
        isTypeFormOpen: false
      }

    case SHOW_PRODUCT_FORM:
      return {
        ...state,
        isProductFormOpen: true
      }

    case HIDE_PRODUCT_FORM:
      return {
        ...state,
        isProductFormOpen: false
      }

    case SHOW_SUBTYPE_FORM:
      return {
        ...state,
        isSubtypeFormOpen: true
      }

    case HIDE_SUBTYPE_FORM:
      return {
        ...state,
        isSubtypeFormOpen: false
      }

    case SHOW_STRAIN_FORM:
      return {
        ...state,
        isStrainFormOpen: true
      }

    case HIDE_STRAIN_FORM:
      return {
        ...state,
        isStrainFormOpen: false
      }

    case SHOW_PRODUCT_CONFIRM_DRAWER:
      return {
        ...state,
        isProductConfirmDrawerOpen: true
      }

    case HIDE_PRODUCT_CONFIRM_DRAWER:
      return {
        ...state,
        isProductConfirmDrawerOpen: false
      }

    case SET_PRODUCT_CONFIRM_DRAWER_REASONS:
      return {
        ...state,
        productConfirmDrawerReasons: action.reasons
      }

    case TOGGLE_ACTION_BAR:
      return {
        ...state,
        isActionBarOpen: !state.isActionBarOpen
      }

    case HIDE_ACTION_BAR:
      return {
        ...state,
        isActionBarOpen: false
      }

    case SET_CATALOG_STATE_FILTER:
      return {
        ...state,
        catalogStateFilter: action.catalogStateFilter
      }

    default:
      return state
  }
}

export default catalog
