import React, { PureComponent } from 'react'
import { number, oneOfType, string } from 'prop-types'
import El from './order-summary-elements'

export default class OrderInfoBox extends PureComponent {
  static propTypes = {
    title: oneOfType([number, string]),
    subtitle: string.isRequired,
    color: string.isRequired
  }

  render () {
    const { title, subtitle, color } = this.props

    return (
      <El.ContentBlock color={color}>
        <El.Title>{title}</El.Title>
        <El.Subtitle>{subtitle}</El.Subtitle>
      </El.ContentBlock>
    )
  }
}
