import Colors from 'components/color'
import { TRANSPARENT, TEXT } from './types'

const colorMap = {
  CBD: Colors.cbd[1],
  HYBRID: Colors.hybrid[1],
  SATIVA: Colors.sativa[1],
  INDICA: Colors.indica[1],
  DANGER: Colors.danger[1],
  ACCESSORY: Colors.accessory[1],
  PRIMARY: Colors.primary[1],
  TRANSPARENT: 'transparent',
  TEXT: 'transparent'
}

const CUSTOM_PADDING = {
  pill: '0.2rem 0.5rem',
  supersize: '1.055rem 1.4rem',
  loading: '1.05rem 1.4rem',
  default: '1.4rem'
}

export function getTextColor ({ type, inverted, pill, active }) {
  if (type === TEXT) {
    return Colors.primary[1]
  }

  if (inverted || (pill && !active)) {
    return colorMap[type]
  }

  return 'white'
}

export function fetchBackgroundColor ({ type, inverted, pill, active }) {
  return (inverted || (pill && !active))
    ? 'white'
    : colorMap[type]
}

export function fetchDisabledBackgroundColor ({ type }) {
  return (type === TRANSPARENT)
    ? colorMap.TRANSPARENT
    : Colors.accessory['3']
}

export function fetchBorderColor ({ type, inverted, pill }) {
  if (type === TRANSPARENT) return 'white'

  return (inverted || pill)
    ? colorMap[type]
    : 'transparent'
}

export function getCustomPadding ({ pill, supersize, loading }) {
  if (pill) return CUSTOM_PADDING.pill
  if (supersize && loading) return CUSTOM_PADDING.supersize
  if (loading) return CUSTOM_PADDING.loading
  return CUSTOM_PADDING.default
}
