import { createSelector } from 'reselect'
import isEmptyObject from 'is-empty-object'
import { transformToUTCISOString } from 'helpers/date'
import { getMenuStore } from 'src/redux/menus/selectors'
import { getProductStore } from 'src/redux/products/selectors'
import { getGroupStore } from 'src/redux/groups/selectors'
import { getLoading } from 'src/redux/loading/selectors'

import fuzzysearch from 'fuzzysearch'

const ONE_SECOND_IN_MILLISECONDS = 1000

export const getCaseDepotId = (state) => state.catalogBuilder.depotId
export const getColor = (state) => state.catalogBuilder.color
export const getDescription = (state) => state.catalogBuilder.description
export const getName = (state) => state.catalogBuilder.name
export const getProductsFilter = (state) => state.catalogBuilder.productsFilter
export const getCartId = (state) => state.catalogBuilder.cartId
export const getCatalogBucket = (state) => state.catalogBuilder.catalogBucket
export const getCatalogCartEta = (state) => state.catalogBuilder.catalogCartEta
export const getCartQuote = (state) => state.catalogBuilder.cartQuote
export const getMenuSlug = (state) => state.catalogBuilder.menuSlug
export const getConciergeSlug = (state) => state.catalogBuilder.conciergeSlug
export const getUser = (state) => state.catalogBuilder.user
export const getCatalogBucketId = (state) => state.catalogBuilder.catalogBucketId
export const getShowOrderDrawer = (state) => state.catalogBuilder.showOrderDrawer
export const getOutOfDepotItems = (state) => state.catalogBuilder.outOfDepotItems
export const getCatalogErrors = (state) => state.catalogBuilder.catalogErrorIdMap

export const getCurrentMenu = createSelector(
  [getMenuStore, getMenuSlug],
  function (menuStore, menuSlug) {
    return menuStore[menuSlug] || { groups: [] }
  }
)

export const filterGroupProducts = createSelector(
  [getProductsFilter, getCatalogBucket, getProductStore, getGroupStore],
  function (productsFilter, catalogCart, productStore, groupStore) {
    const groupsStoreCopy = {}
    const cartProducts = Object.keys(catalogCart)

    Object.keys(groupStore).forEach(function (id) {
      // ensure we're filtering out cart products before we potentially apply search logic
      const products = filterOutCart(groupStore[id].products, cartProducts)
      groupsStoreCopy[id] = {
        ...groupStore[id],
        products: productsFilter
          ? searchProductFilter(productStore, products, productsFilter)
          : products
      }
    })

    return groupsStoreCopy
  }
)

export function filterOutCart (products, cartProducts) {
  return products.filter((product) => cartProducts.indexOf(product) === -1)
}

export function searchProductFilter (productStore, products, productsFilter) {
  // hack to allow users to search for the product id within the productStore which is keyed by catalogItemId
  if (productsFilter.indexOf('pid=') !== -1) {
    const pid = productsFilter.split('=')[1]
    if (pid) {
      const productArray = Object.entries(productStore).find(([key, product]) => product.id === Number(pid))
      if (productArray && productArray.length) {
        return [productArray[0]]
      }
    }
  }

  const filteredProducts = products.filter(function (id) {
    const product = productStore[id] || {}
    const { name = '', brand = {}, species = {} } = product
    const brandName = brand.name || ''
    const speciesName = species.name || ''
    const catalogId = product.catalogItemId || ''

    const searchContentString = `${name} ${brandName} ${speciesName} ${catalogId}`
    return fuzzysearch(productsFilter.toLowerCase(), searchContentString.toLowerCase())
  })

  return filteredProducts
}

export const getCatalogCartEtaValue = createSelector(
  [getCatalogCartEta],
  function (catalogCartEta) {
    const { estimatedDeliveryDateTime } = catalogCartEta
    if (estimatedDeliveryDateTime) {
      const timeStamp = transformToUTCISOString(catalogCartEta.estimatedDeliveryDateTime)
      return formattedTime(timeStamp)
    }
    return '--'
  }
)

export const getIsEtaLoading = createSelector(
  [getLoading],
  function (loading) {
    return loading.catalogBuilderEta
  }
)

export const getIsQuoteLoading = createSelector(
  [getLoading],
  function (loading) {
    return loading.catalogBuilderQuote
  }
)

export function formattedTime (timeStamp) {
  const etaInMinutes = (Date.parse(timeStamp) - Date.now()) / ONE_SECOND_IN_MILLISECONDS
  return `${Math.max(1, Math.round(etaInMinutes / 60))} min`
}

export const getHasCartQuoteTotal = createSelector(
  [getCartQuote],
  function (cartQuote) {
    return Boolean(cartQuote.total)
  }
)

// ensure we have a deliverable address and a quote
export const enableSendCart = createSelector(
  [getCatalogBucket, getHasCartQuoteTotal],
  function (catalogCart, hasCartQuoteTotal) {
    return !isEmptyObject(catalogCart) && hasCartQuoteTotal
  }
)

export const getCartTotal = createSelector(
  [getCartQuote, getHasCartQuoteTotal],
  function (cartQuote, hasCartQuoteTotal) {
    /**
      * since this quote is generated based on the logged in user, we want to
      * calculate total manually since `cartQuote.total` will take into account
      * the logged in user's credits and yield an incorrect total for this form
    */
    const { subtotal, tax } = cartQuote
    const total = hasCartQuoteTotal ? (subtotal + tax) : 0

    return total.toFixed(2)
  }
)

export const getCaseColor = createSelector(
  [getColor],
  function (color) {
    return color
  }
)

export const getCaseDescription = createSelector(
  [getDescription],
  function (description) {
    return description
  }
)

export const getCaseName = createSelector(
  [getName],
  function (name) {
    return name
  }
)
