import { func, number, oneOfType, string } from 'prop-types'
import React from 'react'
import { Row, Column } from 'src/microcomponents/flexbox-helpers'
import InputLabel from 'src/microcomponents/input-label'

const Limits = ({ globalLimit, userLimit, onGlobalLimitChange, onUserLimitChange }) => (
  <Row top={1} bottom={1}>
    <Column right={1} full>
      <InputLabel content='Global uses (0 is unlimited)*' />
      <input
        onBlur={onGlobalLimitChange}
        type='number'
        placeholder='Total number of uses (global)'
        defaultValue={globalLimit}
      />
    </Column>
    <Column full>
      <InputLabel content='Per user use (0 is unlimited)*' />
      <input
        onBlur={onUserLimitChange}
        type='number'
        placeholder='Uses per user'
        defaultValue={userLimit}
      />
    </Column>
  </Row>
)

Limits.propTypes = {
  globalLimit: oneOfType([number, string]),
  userLimit: oneOfType([number, string]),
  onGlobalLimitChange: func,
  onUserLimitChange: func
}

export default Limits
