import { SplitSdk } from '@splitsoftware/splitio-react'
import { SPLIT_BROWSER_KEY } from './environment'

// Create the config for the SDK factory.
const splitConfig = {
  core: {
    authorizationKey: SPLIT_BROWSER_KEY,
    key: 'id'
  },
  debug: false
}

export const factory = SplitSdk(splitConfig)

export async function getDispTreatment (dispensaryId, splitName) {
  let treatment = 'control'
  try {
    const client = factory.client(dispensaryId)
    await client.ready()
    treatment = client.getTreatments([splitName])
  } catch (err) {
    console.log(err)
  }
  return treatment
}
