import { css } from 'emotion'
import { blazeUpBlack, defaultBorderRadius, secondaryDark, tertiaryDark } from 'helpers/css-variables'

export const container = css`
  display: flex;
  flex-direction: column;
  margin: 3rem;
  margin-bottom: 0;

  input,
  textarea {
    border: 0;
    color: white;
    border-radius: 0.4rem;
    padding: 1rem;
    font-size: 1.6rem;
    background-color: ${tertiaryDark};
    font-family: 'Fira Sans';
    resize: none;
  }
`

export const promoNavContainer = css`
  margin-bottom: 3rem;
`

export const sidebar = css`
  padding: 1rem;
  height: 100%;
  max-width: 50rem;
`

export const tableContainer = css`
  --border-radius: 4px;

  background-color: ${secondaryDark};
  border-top-left-radius: ${defaultBorderRadius};
  border-top-right-radius: ${defaultBorderRadius};
`

export const tableTitle = css`
  margin: 0;
`

export const tableContainerHeader = css`
  padding: 1rem;
`

export const conflictModalContentClass = css`
  background-color: ${blazeUpBlack};
  max-width: 60rem;
  padding: 1rem;
  max-height: 100%;
  overflow: auto;
`

export const conflictModalButtons = css`
  display: flex;
`
