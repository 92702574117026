import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { node } from 'prop-types'

const CartButtonContainer = styled.div`
  @media(min-width: 768px) {
    width: 100%;
    padding-top: 1rem;
  }
  @media(max-width: 767px) {
    width: 12rem;
    margin-left: 1rem;
  }
`

export default class CartButtonContainerComponent extends PureComponent {
  static propTypes = {
    children: node
  }

  render () {
    const { children } = this.props

    return (
      <CartButtonContainer>{children}</CartButtonContainer>
    )
  }
}
