import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { bool, node } from 'prop-types'

const Price = styled.div`
  position: relative;
  line-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: ${({ showQuantityPicker }) => showQuantityPicker ? '12rem' : '5rem'};
`

export default class PriceComponent extends PureComponent {
  static propTypes = {
    children: node,
    showQuantityPicker: bool
  }

  render () {
    return (
      <Price showQuantityPicker={this.props.showQuantityPicker}>{this.props.children}</Price>
    )
  }
}
