import React, { PureComponent } from 'react'

import RightBar from 'src/components/right-bar'
import PromoForm from './promo-form'
import PromoReceipt from './promo-receipt'
import { Column } from 'src/microcomponents/flexbox-helpers'

import { func, object, array, bool } from 'prop-types'

import { manageContainer } from './style.js'

export class ManagePromo extends PureComponent {
  static propTypes = {
    catalog: object,
    cloneable: bool,
    clonePromo: func,
    createPromo: func,
    depots: array,
    depotsByState: object,
    dispensaries: array,
    editPromo: func,
    getCatalogBrand: func,
    getCatalogGroup: func,
    getCatalogItem: func,
    managePromos: object,
    open: bool,
    promoEditing: bool,
    promoValidity: object,
    promoPreview: bool,
    promos: array,
    setCatalogName: func,
    setMinOrderValue: func,
    setPromoActions: object,
    setSelectedPromoDefinition: func,
    showPromoPreview: func,
    sortedCatalogBrands: array,
    sortedCatalogGroups: array,
    sortedCatalogItems: array
  }

  render () {
    const {
      catalog,
      cloneable,
      clonePromo,
      createPromo,
      depots,
      depotsByState,
      dispensaries,
      editPromo,
      getCatalogBrand,
      getCatalogGroup,
      getCatalogItem,
      managePromos,
      open,
      promoEditing,
      promoValidity,
      promoPreview,
      promos,
      setCatalogName,
      setMinOrderValue,
      setPromoActions,
      setSelectedPromoDefinition,
      showPromoPreview,
      sortedCatalogBrands,
      sortedCatalogGroups,
      sortedCatalogItems
    } = this.props
    return (
      <RightBar isOpen={open}>
        <div className={manageContainer}>
          <Column>
            {
              promoPreview && open && <PromoReceipt
                showPromoPreview={showPromoPreview}
                createPromo={createPromo}
                editPromo={editPromo}
                promoEditing={promoEditing}
                promoValidity={promoValidity}
                cloneable={cloneable}
                clonePromo={clonePromo}
                managePromos={managePromos}
                depots={depots}
                dispensaries={dispensaries}
                                      />
            }
            {
              !promoPreview && open && <PromoForm
                promoValidity={promoValidity}
                // STATE
                depots={depots}
                depotsByState={depotsByState}
                dispensaries={dispensaries}
                promos={promos}
                managePromos={managePromos}
                catalog={catalog}
                sortedCatalogItems={sortedCatalogItems}
                sortedCatalogBrands={sortedCatalogBrands}
                sortedCatalogGroups={sortedCatalogGroups}
                // DISPATCH
                showPromoPreview={showPromoPreview}
                createPromo={createPromo}
                clonePromo={clonePromo}
                setSelectedPromoDefinition={setSelectedPromoDefinition}
                getCatalogItem={getCatalogItem}
                getCatalogGroup={getCatalogGroup}
                getCatalogBrand={getCatalogBrand}
                // set form values
                setPromoActions={setPromoActions}
                setMinOrderValue={setMinOrderValue}
                setCatalogName={setCatalogName}
              />
            }
          </Column>

        </div>
      </RightBar>
    )
  }
}

export default ManagePromo
