import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const Label = styled.label`
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: uppercase;
  font-family: 'Proxima', 'Open sans', Helvetica;
  letter-spacing: 0.03em;
`

export default class LabelComponent extends PureComponent {
  static defaultProps = {
    className: '',
    content: ''
  }

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    className: PropTypes.string,
    content: PropTypes.string
  }

  render () {
    const { content, children, className, ...otherProps } = this.props

    return (
      <Label className={className} {...otherProps}>
        {children}

        {content}
      </Label>
    )
  }
}
