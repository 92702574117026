import React from 'react'
import { array, func } from 'prop-types'
import Table from 'microcomponents/table'
import { formatDateTimeWithZone, readDate } from 'src/helpers/date'
import Switch from 'src/microcomponents/switch'
import { PROMOS_IANA_TIME_ZONE } from 'src/helpers/promos/definitions'
import { isSus } from './style.js'

export default class PromoTable extends React.Component {
  static propTypes = {
    promos: array,
    selectRow: func,
    togglePromo: func
  }

  static defaultProps = {
    promos: [],
    selectRow: () => {},
    togglePromo: () => {}
  }

  shouldComponentUpdate (nextProps) {
    const { promos: nextPromos } = nextProps
    const { promos: prevPromos } = this.props

    // Check if the promos collection changed
    // Add any other boolean checks to this array
    return [
      // This is a very crude check but it should suffice since this page has no
      // realtime data needs, and the majority of actions are add/delete a promo
      nextPromos !== prevPromos
    ].some(Boolean)
  }

  fetchPromoTableConfig = (togglePromo) => {
    return [
      {
        title: (<div style={{ textAlign: 'left' }}>Code</div>),
        key: 'code',
        formatter: v => <b title={v}>{v.toUpperCase()}</b>
      },
      {
        title: 'State',
        key: 'rules.state_id'
      },
      {
        title: 'Created',
        key: 'created_at',
        formatter: v => <div title={v}>{readDate(v)}</div>
      },
      {
        title: 'Description',
        key: 'description',
        formatter: v => <div style={{ maxWidth: '40rem' }}>{v}</div>
      },
      {
        title: 'Valid At',
        key: 'valid_at',
        formatter: v => formatDateTimeWithZone(v, PROMOS_IANA_TIME_ZONE)
      },
      {
        title: 'Expires At',
        key: 'expires_at',
        formatter: v => formatDateTimeWithZone(v, PROMOS_IANA_TIME_ZONE)
      },
      {
        title: 'Type',
        key: 'rules.operators.credit_type'
      },
      {
        title: 'Amount',
        key: 'rules.operators.credit_amount'
      },
      {
        title: 'Max',
        key: 'rules.operators.credit_max'
      },
      {
        title: 'Marketing Channel',
        key: 'meta.marketing_channel',
        formatter: v => <div title={v}>{v || 'Not Assigned'}</div>
      },
      {
        title: 'Global Limit',
        key: 'rules.global_limit',
        formatter: v => <div title={v}>{v || 'Unlimited'}</div>
      },
      {
        title: 'User Limit',
        key: 'rules.user_limit',
        formatter: v => <div title={v}>{v || 'Unlimited'}</div>
      },
      {
        title: 'Enabled',
        key: 'enabled',
        formatter: (v, row) => {
          return (
            <div
              title={v}
              onClick={event => {
                event.stopPropagation()
                togglePromo(row)
              }}
            >
              <Switch enabled={v} />
            </div>
          )
        }
      }
    ]
  }

  render () {
    const { promos, selectRow, togglePromo } = this.props
    // We only want to ever render 100 promos at a time
    // For now, the search bar will help users find what they need (PEP-2623)
    // Later, we will move to a virtualized table that supports sorting/filtering
    // on columns (PEP-2529); BEnd to add additional query param support as well
    const first100Promos = promos.slice(0, 100)
    return (
      <Table
        data={first100Promos}
        // Todo: make this accept react-emotion styles so we can get away from needing a style file
        rowStyle={r => r.enabled ? null : isSus}
        rowClick={r => { selectRow(r) }}
        config={this.fetchPromoTableConfig(togglePromo)}
        height='calc(100vh - 25rem)'
        sortBy='-created_at'
        style={{ minWidth: '160rem' }}
        sortAscending
      />
    )
  }
}
