// we would rather follow best practices and keep all of our IDs as strings
// but that will mean sanitizing everything as it goes into our reducer
// instead we are going to modify query string params to be ints, as those
// are the only places we have type string IDs currently
export function idToInt (id) {
  // using parseInt() because it has more consistent behavior than Number()
  const parsedId = parseInt(id)

  return isNaN(parsedId) ? '' : parsedId
}
