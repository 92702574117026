/**
 * Setting up the catalog container
 */

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import React, { PureComponent } from 'react'

import {
  fetchBrandById,
  createOrUpdateBrand,
  resetBrandItem,
  updateBrandObject
} from 'src/redux/brand-item/actions'
import { uploadPublicFile } from 'src/redux/files/api'

import EditBrand from './'

// Props passed to the component
const mapStateToProps = (state, props) => {
  const id = props.match.params.id || false // false if we're coming into /create

  return {
    brandId: id,
    brandItem: state.brandItem,
    brandLoading: state.loading.brand,
    items: {
      brandItems: state.brandItem.items,
      products: state.catalog.products
    }
  }
}

// Methods passed to the components
const mapDispatchToProps = dispatch => {
  return {
    createOrUpdateBrand: () => dispatch(createOrUpdateBrand()),
    fetchBrandById: (id) => dispatch(fetchBrandById(id)),
    resetBrandItem: () => dispatch(resetBrandItem()),
    updateBrandObject: value => dispatch(updateBrandObject(value)),
    uploadPublicFile: (formData) => dispatch(uploadPublicFile(formData))
  }
}

class EditBrandContainer extends PureComponent {
  componentDidMount () {
    const { fetchBrandById, brandId } = this.props

    if (brandId) {
      fetchBrandById(brandId)
    }
  }

  render () {
    return (
      <EditBrand {...this.props} />
    )
  }
}

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBrandContainer)

export default withRouter(reduxContainer)
