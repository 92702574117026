import { connect } from 'react-redux'

import { getActiveDepots } from 'src/redux/dispensaries/selectors'
import { getAllDriverOrders } from 'src/redux/hub/selectors'
import { getAreManifestsToPrintMuted } from 'src/redux/sound-alerts/selectors'
import { toggleManifestToPrintSounds } from 'src/redux/sound-alerts/actions'

import Runner from './component'

const mapStateToProps = (state, props) => {
  return {
    areManifestsToPrintMuted: getAreManifestsToPrintMuted(state),
    depots: getActiveDepots(state),
    driverOrders: getAllDriverOrders(state)
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    toggleManifestToPrintSounds: () => dispatch(toggleManifestToPrintSounds())
  }
}

const reduxContainer = connect(mapStateToProps, mapDispatchToProps)(Runner)

export default reduxContainer
