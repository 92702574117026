import { createSelector } from 'reselect'

export const getDispensaries = (state) => state.dispensaries.list
export const getActiveDispensariesMap = (state) => state.dispensaries.map
export const getActiveDepots = (state) => state.depots.list

// to avoid confusion between menu and group settings modifing an `enabled`
// key (to mean it's enabled for a group/menu) vs the `isEnabled` key that
// exists from the backend call to get active depots (which means the depot itself is enabled/active)
// we are taking this list and slimming it to just depot name and ID
export const getActiveDepotsSlimmed = createSelector(
  [getActiveDepots],
  (activeDepots) => {
    return activeDepots.map(depot => ({ id: depot.id, name: depot.name }))
  }
)

export const getHubAndSpokeDepotIds = createSelector(
  [getActiveDepots],
  (depots) => {
    return depots.filter(depot => {
      return depot.deliveryType === 'Hybrid' || depot.deliveryType === 'Batch'
    }).map(depot => depot.id)
  }
)

export const getActiveDispensaries = createSelector(
  [getDispensaries],
  (dispensaries) => {
    // NOTE: we need to filter out enabled dispensaries that have no depots listed
    // because inventory creation breaks if we send an empty array of depot IDs
    return dispensaries.filter((dispensary) => dispensary.isEnabled && dispensary.depots.length)
  }
)

export const getActiveDepotsGroupedByState = createSelector(
  [getActiveDepots],
  (activeDepots) => {
    return activeDepots.reduce((acc, depot) => {
      const stateAbbreviation = depot.depotAddress && depot.depotAddress.state
      if (stateAbbreviation && acc[stateAbbreviation]) {
        acc[stateAbbreviation].push(depot)
      } else if (stateAbbreviation) {
        acc[stateAbbreviation] = [depot]
      }
      return acc
    }, {})
  }
)
