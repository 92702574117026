import window from 'global/window'

import MCColors from 'src/helpers/css-variables'
const { mcGreen, mcRed, mcYellow } = MCColors

export const DRIVER_MODE_OFF_KEY = 0
export const DRIVER_MODE_ON_KEY = 1
export const DRIVER_MODE_DELIVERING_KEY = 2
export const DRIVER_MODE_HOLD_KEY = 3

export const DRIVER_MODES_MAP = {
  [DRIVER_MODE_DELIVERING_KEY]: 'Delivering',
  [DRIVER_MODE_ON_KEY]: 'On',
  [DRIVER_MODE_OFF_KEY]: 'Off',
  [DRIVER_MODE_HOLD_KEY]: 'Hold'
}

export const DRIVER_MODE_KEYS_LIST = [
  DRIVER_MODE_DELIVERING_KEY,
  DRIVER_MODE_ON_KEY,
  DRIVER_MODE_OFF_KEY,
  DRIVER_MODE_HOLD_KEY
]

export const DRIVER_MODE_COLORS_MAP = {
  [DRIVER_MODE_DELIVERING_KEY]: mcGreen,
  [DRIVER_MODE_ON_KEY]: mcYellow,
  [DRIVER_MODE_OFF_KEY]: mcRed,
  [DRIVER_MODE_HOLD_KEY]: mcRed
}

export const DRIVER_ENUMS = {
  [DRIVER_MODE_DELIVERING_KEY]: 'Delivering',
  [DRIVER_MODE_OFF_KEY]: 'Off',
  [DRIVER_MODE_ON_KEY]: 'On',
  [DRIVER_MODE_HOLD_KEY]: 'Hold'
}

export const DELIVERY_TYPES = {
  DYNAMIC: 'Dynamic',
  BATCH: 'Batch'
}

export function getDefaultDriverObjectWithLocation (driver = {}) {
  if (!driver.location) {
    driver.location = {}
  }
  return driver
}

// new driver order api looks for an array of string values for different order types
export const ORDER_STATUSES = {
  PENDING: ['1', '2', '3'],
  CANCELED: ['4', '5', '6', '7', '8', '9']
}

export const DEFAULT_DRIVER_CONTENT_TAB = `orders`
export const CLOSE_DRIVER_DRAWER = undefined
export const ERROR_USER_ALREADY_DRIVER = 'User is driver already'

export function windowConfirmUnsavedChanges () {
  return window.confirm('Are you sure you want to leave this page? The edits you made will not be saved.')
}
