import React, { PureComponent } from 'react'
import { bool, func, number, string } from 'prop-types'
import styled from '@emotion/styled'

import Plus from '../icon-plus'
import Minus from '../icon-minus'

export default class QuantityInput extends PureComponent {
  static propTypes = {
    allowNegativeValues: bool,
    dataTestId: string,
    maxQuantityAllowed: number,
    updateQuantity: func.isRequired,
    value: number
  }

  static defaultProps = {
    allowNegativeValues: false,
    maxQuantityAllowed: 999
  }

  onInputChange = (e) => {
    const { value } = e.target

    if (value === '') {
      this.updateQuantity(0)
      return
    }
    const qty = parseInt(value)
    this.isQtyInputValid(qty) && this.updateQuantity(qty)
  }

  isQtyInputValid = (value) => {
    const { allowNegativeValues, maxQuantityAllowed } = this.props
    if (isNaN(value)) return false

    return (value >= 0 && value <= maxQuantityAllowed) || (allowNegativeValues && value < 0)
  }

  changeQuantity = (direction) => () => {
    const { allowNegativeValues, maxQuantityAllowed, value } = this.props
    let newQty

    if (allowNegativeValues) {
      newQty = direction === '-' ? value - 1 : value + 1
    } else {
      newQty = direction === '-' ? Math.max(value - 1, 0) : value + 1
    }

    // Limiting input to specific quantity upper limit
    if (newQty > maxQuantityAllowed) return
    this.updateQuantity(newQty)
  }

  updateQuantity = (quantity) => {
    this.props.updateQuantity(quantity)
  }

  render () {
    const { dataTestId, maxQuantityAllowed, value } = this.props
    const testId = dataTestId ? `qt-${dataTestId}` : undefined
    return (
      <Container data-testid={testId}>
        <QuantityBtn
          aria-label='decrease-quantity-button'
          onClick={this.changeQuantity('-')}>
          <Minus />
        </QuantityBtn>
        <Input
          maxLength={maxQuantityAllowed.toString().length}
          minLength='1'
          onChange={this.onInputChange}
          pattern='\d'
          type='text'
          value={value}
        />
        <QuantityBtn
          aria-label='increase-quantity-button'
          onClick={this.changeQuantity('+')}>
          <Plus />
        </QuantityBtn>
      </Container>
    )
  }
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const QuantityBtn = styled.button`
  margin: 0 0.5rem;
  padding: 0;
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
  outline: none;
  border: 0;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Input = styled.input`
  width: 8.4rem;
  height: 3rem;
  border-radius: 1.5rem;
  background: rgba(255, 255, 255, 0.1);
  text-align: center;
  font-size: 1.44rem;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
