import t from './actionTypes'

const defaultState = {}

const menus = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_MENUS:
      return {
        ...state,
        ...action.menus
      }

    case t.CLEAR_MENUS:
      return defaultState

    default:
      return state
  }
}

export default menus
