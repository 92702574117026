import t from './actionTypes'

export function setSpeciesMap (speciesMap) {
  return {
    type: t.SET_SPECIES_MAP,
    payload: speciesMap
  }
}

export function clearSpeciesMap () {
  return {
    type: t.CLEAR_SPECIES_MAP
  }
}
