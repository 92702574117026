import api from 'api'
import times from 'map-times'
import gatewayClient from 'src/gateway-client'

import {
  SET_ITEM,
  SET_CATALOG_PRODUCTS,
  SHOW_PRODUCT_FORM,
  HIDE_PRODUCT_FORM,
  SET_SKIPPED_DEPOTS
} from 'src/redux/catalog/item/actionTypes'
import {
  PREROLLS_TYPE_ID,
  EDIBLES_TYPE_ID,
  CONCENTRATES_TYPE_ID,
  ACCESSORIES_TYPE_ID
} from 'src/helpers/constants'
import {
  hideActionBar,
  clearFormQueryParams
} from 'src/redux/catalog/actions.common'
import { DEFAULT_STATUS } from './constants'
import { pushNotification, ERROR, SUCCESS } from 'src/redux/alerts/actions'
import { setProduct, removeProduct } from 'src/redux/products/actions'
import { getProductStore } from 'src/redux/products/selectors'

export function submitCatalogItem (routerLocation) {
  return function (dispatch, getState) {
    const state = getState()
    const {
      catalogItem: { id }
    } = state
    const isEdit = Boolean(id)

    if (isEdit) {
      dispatch(updateCatalogItem(routerLocation))
    } else {
      dispatch(createCatalogItem(routerLocation))
    }
  }
}

export function createCatalogItem () {
  return async (dispatch, getState) => {
    const state = getState()
    const { catalogItem } = state
    const body = transformFromCatalogItem(catalogItem)

    const { err, data } = await api.createCatalogItem(body)
    if (err) return

    dispatch(
      pushNotification(
        `Successfully created ${catalogItem.name} product`,
        SUCCESS
      )
    )

    dispatch(hideProductForm())
    dispatch(addCatalogProduct(data))
  }
}

export function createInventoryItems (catalogItemId, dispensaries) {
  return async (dispatch, getState) => {
    const state = getState()
    const productsMap = getProductStore(state)
    const catalogItem = productsMap[catalogItemId] || {}
    const { title, type } = catalogItem
    const { legacy_id: productTypeId } = type
    // early return to bail if no legacy_id exists, ensure we handle the case of an ID int of 0
    if (!productTypeId && productTypeId !== 0) {
      return dispatch(
        pushNotification(
          `Error: ${title} does not have a valid type, please change the type and try again.`,
          ERROR
        )
      )
    }

    const inventoryArray = formatInventoryArray(catalogItem, dispensaries)
    const body = {
      productTypeId,
      inventoryArray,
      catalogId: catalogItemId
    }

    const { err, data } = await gatewayClient().createInventoryItems(body)

    if (err) {
      return dispatch(
        pushNotification(
          `Error: creating Inventory items for ${title}. Please try again.`,
          ERROR
        )
      )
    }

    // some depots were skipped
    if (data.skippedDepots.length !== 0) {
      dispatch(setSkippedDepots(data.skippedDepots))
    }

    dispatch(
      pushNotification(`Success: created Inventory items for ${title}`, SUCCESS)
    )
  }
}

export function formatInventoryArray (catalogItem, dispensaries) {
  return dispensaries.map(function (dispensary) {
    return catalogToInventory(catalogItem, dispensary)
  })
}

// takes a catalog object and formats it to be in the shape inventory wants
export function catalogToInventory (catalogItem, dispensary) {
  // object to conditionally add keys based on data
  const augmentedPayload = {}
  // NOTE: cannot destructure these because type, subtype and species
  // can all come back as null which will not trigger a fallback
  const productTypeId = catalogItem.type && catalogItem.type.legacy_id
  const productSubtypeId = catalogItem.subtype && catalogItem.subtype.legacy_id
  const speciesId = catalogItem.species && catalogItem.species.legacy_id
  const speciesName = catalogItem.species && catalogItem.species.name
  const price = catalogItem.suggested_retail_price
  const brandName = catalogItem.brand && catalogItem.brand.name
  const isByUnit =
    [
      PREROLLS_TYPE_ID,
      EDIBLES_TYPE_ID,
      CONCENTRATES_TYPE_ID,
      ACCESSORIES_TYPE_ID
    ].indexOf(productTypeId) > -1
  const priceType = isByUnit ? 2 : 1
  const cbdMeasure = productTypeId === PREROLLS_TYPE_ID ? 'MG' : '%'
  const thcMeasure = productTypeId === PREROLLS_TYPE_ID ? 'MG' : '%'
  const name = `${catalogItem.title} - ${brandName}`
  if (productTypeId === CONCENTRATES_TYPE_ID) {
    augmentedPayload.concentrateTypeId = productSubtypeId
  }
  /**
   * The backend expects type string for effects and images.
   * When adding images or effects to a catalog item, the legacy api/products/:id
   * endpoint does not persist those and appears to always pass them as empty
   * strings. The new api/products/:id endpoint is still not persisting those fields
   * but instead passes them on as empty arrays. The endpoint used to save an inventory
   * item however requires these to be empty strings and we (Brain and Amrit) felt that
   * hard coding effects and images to be empty strings in this submit method was the
   * least difficult way to handle this issue.
   */
  const effects = ''
  const images = ''

  const basePayload = {
    catalogItemId: catalogItem.id,
    categoryId: speciesId || 0,
    category: {
      id: speciesId,
      name: speciesName,
      parent: {}
    },
    cbd: catalogItem.cbd_content,
    cbdMeasure,
    depots: dispensary.depots,
    description: catalogItem.description,
    effects,
    images,
    highCbd: catalogItem.high_cbd,
    // NOTE: we always want isEnabled true for bulk creation of inventory
    isEnabled: true,
    name,
    photoId: parseInt(catalogItem.images[0].id.toString()),
    photoUrl: catalogItem.images[0].downloadUrl,
    price,
    priceType,
    // Automatically Enabled
    productStatus: 0,
    productSubtypeId,
    productTypeId,
    thc: catalogItem.thc_content,
    thcMeasure
  }

  return {
    ...basePayload,
    ...augmentedPayload
  }
}

export function updateCatalogItem (routerLocation) {
  return async (dispatch, getState) => {
    const state = getState()
    const { catalogItem } = state
    const body = transformFromCatalogItem(catalogItem)

    const { err, data } = await api.updateCatalogItem(body)
    if (err) return

    dispatch(
      pushNotification(
        `Successfully edited ${catalogItem.name} product`,
        SUCCESS
      )
    )

    dispatch(hideProductForm(routerLocation))
    dispatch(updateCatalogProduct(data))
  }
}

export function deleteCatalogItem (routerLocation) {
  return async (dispatch, getState) => {
    const state = getState()
    const { catalogItem } = state
    const { id } = catalogItem

    const { err } = await api.deleteCatalogItem({ id })
    if (err) return
    dispatch(
      pushNotification(
        `Successfully deleted ${catalogItem.name} product`,
        SUCCESS
      )
    )

    dispatch(removeCatalogProduct(catalogItem))
    dispatch(hideProductForm(routerLocation))
  }
}

// parser to format redux catalogItem keys into keys the backend is expecting
export function transformFromCatalogItem (item) {
  return {
    brand_id: item.brand,
    cbd_content: item.cbd,
    cogs: item.cogs,
    description: item.description,
    dietary: item.dietary,
    effects: item.effects,
    enabled: item.enabled,
    excise_tax_exempt: item.excise_tax_exempt,
    high_cbd: item.highCbd,
    id: item.id,
    images: item.images,
    ingredients: item.ingredients,
    slug: item.slug,
    species_id: item.species,
    state_id: item.stateId,
    status: item.status,
    strain_id: item.strain,
    subtype_id: item.subtype,
    suggested_retail_price: item.price,
    thc_content: item.thc,
    title: item.name,
    type_id: item.type,
    volume: item.volume,
    weight: item.weight
  }
}

// item is a catalog item that just came from the server,
// we'll need to change it's shape for our redux store
export function transformToCatalogItem (item) {
  // fallbacks for situations where data coming back from the server is not complete
  const {
    brand = {},
    type = {},
    subtype = {},
    strain = {},
    species = {},
    effects = []
  } = item
  return {
    brand: brand.id || '',
    cbd: item.cbd_content || 0,
    cogs: item.cogs || 0,
    description: item.description || '',
    dietary: item.dietary || '',
    effects: times(5, index => effects[index] || { name: '', amount: '' }),
    enabled: item.enabled || false,
    excise_tax_exempt: item.excise_tax_exempt || false,
    highCbd: item.high_cbd || false,
    id: item.id || null,
    images: item.images || [],
    ingredients: item.ingredients || '',
    name: item.title || '',
    price: item.suggested_retail_price || '',
    slug: item.slug || '',
    species: species.id || '',
    stateId: item.state_id || '',
    status: item.status || DEFAULT_STATUS,
    strain: strain.id || '',
    subtype: subtype.id || '',
    thc: item.thc_content || 0,
    type: type.id || '',
    volume: item.volume || 0,
    weight: item.weight || 0
  }
}

export function setCatalogItem (item) {
  return {
    type: SET_ITEM,
    item
  }
}

export function setSkippedDepots (skippedDepots) {
  return dispatch => {
    dispatch({
      type: SET_SKIPPED_DEPOTS,
      skippedDepots
    })
  }
}

export function resetSkippedDepots () {
  return dispatch => {
    dispatch({
      type: SET_SKIPPED_DEPOTS,
      skippedDepots: []
    })
  }
}

export function setCatalogProducts (products) {
  return {
    type: SET_CATALOG_PRODUCTS,
    payload: products
  }
}

export function addCatalogProduct (product) {
  // get list, add, update full list
  return (dispatch, getState) => {
    const state = getState()
    const { catalog: { products } } = state

    dispatch(setProduct(product, product.id))
    dispatch(setCatalogProducts([product.id, ...products]))
  }
}

export function updateCatalogProduct (product) {
  return setProduct(product, product.id)
}

export function removeCatalogProduct (product) {
  // get products, search, remove, update full list
  return (dispatch, getState) => {
    const state = getState()
    const { catalog: { products } } = state
    const productsWithRemovedItem = products.filter((productId) => productId !== product.id)

    dispatch(removeProduct(product.id))
    dispatch(setCatalogProducts([...productsWithRemovedItem]))
  }
}

export function showProductForm (product) {
  return dispatch => {
    dispatch({ type: SHOW_PRODUCT_FORM })

    const productObject = product || {}
    const transformedProduct = transformToCatalogItem(productObject)

    dispatch(setCatalogItem(transformedProduct))
    dispatch(hideActionBar())
  }
}

export function hideProductForm (routerLocation) {
  return dispatch => {
    if (routerLocation) {
      clearFormQueryParams(routerLocation)
    }
    const transformedItem = transformToCatalogItem({ effects: [] })
    dispatch({ type: HIDE_PRODUCT_FORM })
    // clear out form on hide
    dispatch(setCatalogItem(transformedItem))
  }
}
