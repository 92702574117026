/**
 * React Router boilerplate used in ~all~ environments
 */

import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'

import history from 'components/router/history'
import Promos from 'src/pages/promos/container'
import Tags from 'src/pages/promos/tags/container'
import Deliveries from 'src/pages/deliveries'
import Users from 'src/pages/users/container'
import Catalog from 'src/pages/catalog/container'
import Login from 'src/pages/login/container'
import Error from 'src/pages/error'
import Dashboard from 'src/pages/dashboard/container'
import SelectDepot from 'src/pages/dashboard/select'

import OrderBuilder from 'src/pages/catalog-builder'
import CatalogBuilder from 'src/pages/catalog-builder/edit/container'
import OrderBuilderSearchUser from 'src/pages/catalog-builder/search/container'

import EditCatalogGroup from 'src/pages/catalog/edit/group/container'
import EditCatalogMenu from 'src/pages/catalog/edit/menu/container'
import EditCatalogBrand from 'src/pages/catalog/edit/brand/container'
import TerritoryManagement from 'src/pages/territory-management/container'
import DepotInventory from 'src/pages/hub-and-spoke/pages/depot-inventory/container'
import InventoryUpdate from 'src/pages/inventory-update'

import { getIsAdmin, getIsDispManager } from 'src/redux/permissions/selectors'
import { getXAuthToken, getIsEazeEmployee } from 'src/redux/users/selectors'
import { getPartnerDeliveriesAccess } from 'src/redux/firebase/selectors'

import ROUTES from './routes'

export default class McRouter extends React.Component {
  WrappedRoute = ({ component: Component, exact = true, ...preloadedProps }) => {
    return (
      <Route
        exact={exact}
        path={preloadedProps.path}
        render={props => {
          let showComponent = true
          if (preloadedProps.withOnEnter) {
            showComponent = preloadedProps.withOnEnter(preloadedProps)
          }

          if (showComponent) {
            return (<Component preloadedProps={preloadedProps} {...props} />)
          } else {
            return null
          }
        }}
      />
    )
  }

  render () {
    return (
      <Router history={history}>
        <div style={{ height: '100%', width: '100%', display: 'block' }}>
          <Switch>
            <Route exact path={ROUTES.LOGIN} component={Login} />
            <Route exact path={ROUTES.ERROR} component={Error} />

            <this.WrappedRoute
              path={ROUTES.PROMOS_TAGS}
              withOnEnter={verifyAdmin}
              component={Tags}
              {...this.props}
            />
            <this.WrappedRoute
              path={ROUTES.PROMOS}
              withOnEnter={verifyAdmin}
              component={Promos}
              {...this.props}
            />
            <this.WrappedRoute
              path={ROUTES.USERS}
              withOnEnter={verifyAdmin}
              component={Users}
              {...this.props}
            />
            <this.WrappedRoute
              path={ROUTES.DEPOT_INVENTORY}
              withOnEnter={verifyAuthToken}
              component={DepotInventory}
              {...this.props}
            />
            <this.WrappedRoute
              path={ROUTES.EDIT_CATALOG_GROUP}
              withOnEnter={verifyAdmin}
              component={EditCatalogGroup}
              {...this.props}
            />
            <this.WrappedRoute
              path={ROUTES.EDIT_CATALOG_BRAND}
              withOnEnter={verifyAdmin}
              component={EditCatalogBrand}
              {...this.props}
            />
            <this.WrappedRoute
              path={ROUTES.EDIT_CATALOG_MENU}
              withOnEnter={verifyAdmin}
              component={EditCatalogMenu}
              {...this.props}
            />
            <this.WrappedRoute
              path={ROUTES.ADD_CATALOG_MENU}
              withOnEnter={verifyAdmin}
              component={EditCatalogMenu}
              {...this.props}
            />
            <this.WrappedRoute
              path={ROUTES.ADD_CATALOG_GROUP}
              withOnEnter={verifyAdmin}
              component={EditCatalogGroup}
              {...this.props}
            />
            <this.WrappedRoute
              path={ROUTES.ADD_CATALOG_BRAND}
              withOnEnter={verifyAdmin}
              component={EditCatalogBrand}
              {...this.props}
            />
            <this.WrappedRoute
              path={ROUTES.CATALOG}
              withOnEnter={verifyAdmin}
              component={Catalog}
              {...this.props}
            />
            <this.WrappedRoute
              path={ROUTES.ORDER_BUILDER_SEARCH_USER}
              withOnEnter={verifyAdmin}
              component={OrderBuilderSearchUser}
              {...this.props}
            />
            <this.WrappedRoute
              path={ROUTES.CASE_BUILDER_ID}
              component={CatalogBuilder}
              withOnEnter={verifyAuthToken}
              isCaseBuilder
              {...this.props}
            />
            <this.WrappedRoute
              path={ROUTES.ORDER_BUILDER_USER}
              withOnEnter={verifyAdmin}
              component={CatalogBuilder}
              {...this.props}
            />
            <this.WrappedRoute
              path={ROUTES.ORDER_BUILDER}
              withOnEnter={verifyAdmin}
              component={OrderBuilder}
              {...this.props}
            />
            <this.WrappedRoute
              path={ROUTES.DELIVERIES}
              withOnEnter={verifyDeliveriesAccess}
              component={Deliveries}
              {...this.props}
            />
            <this.WrappedRoute
              path={ROUTES.ADULT_USE_TERRITORIES}
              withOnEnter={verifyAuthToken}
              component={TerritoryManagement}
              {...this.props}
            />
            <this.WrappedRoute
              path={ROUTES.DEPOT_DASHBOARD}
              component={Dashboard}
              exact
              withOnEnter={verifyAuthToken}
              {...this.props}
            />
            <this.WrappedRoute
              path={ROUTES.DEPOT_DASHBOARD_TASKS}
              component={Dashboard}
              exact={false}
              withOnEnter={verifyAuthToken}
              {...this.props}
            />
            <this.WrappedRoute
              path={ROUTES.DASHBOARD}
              withOnEnter={verifyAuthToken}
              component={SelectDepot}
              {...this.props}
            />
            <this.WrappedRoute
              path={ROUTES.INVENTORY_UPDATE}
              withOnEnter={verifyAdmin}
              component={InventoryUpdate}
              {...this.props}
            />

            <Route path='*' component={Login} />
          </Switch>
        </div>
      </Router>
    )
  }
}

function verifyAuthToken ({ store }) {
  const state = store.getState()

  const xAuthToken = getXAuthToken(state)
  const isAdmin = getIsAdmin(state)
  const isDispensaryManager = getIsDispManager(state)

  const canLogIn = isAdmin || isDispensaryManager

  if (!xAuthToken) {
    history.replace({ pathname: ROUTES.LOGIN })

    return false
  }

  if (!canLogIn) {
    history.replace({ pathname: ROUTES.ERROR })

    return false
  }

  // auth verified, tell the router to proceed
  return true
}

// Added for selectively preventing use of certain pages (deliveries) as part of incident response.
function verifyEazeEmployeeOnly ({ store }) {
  const state = store.getState()

  if (!verifyAuthToken({ store })) { return false }

  if (!getIsEazeEmployee(state)) {
    history.replace({ pathname: ROUTES.ERROR })
    return false
  }

  // auth verified, tell the router to proceed
  return true
}

function verifyAdmin ({ store }) {
  // re-use our basic auth check to get the redirect to login for admin routes
  verifyAuthToken({ store })

  const state = store.getState()
  const isAdmin = getIsAdmin(state)
  const isDispensaryManager = getIsDispManager(state)

  if (!isAdmin && !isDispensaryManager) {
    history.replace({ pathname: ROUTES.ERROR })

    return false
  }

  // also handles dispensary managers and auto-routes them to the dashboard
  if (isDispensaryManager) {
    history.replace({ pathname: ROUTES.DASHBOARD })
  }

  // permissions verified, tell the router to proceed
  return true
}

// start listening to firebase for changes to access controls on the deliveries page
// if the returned value is false, we implement a check to determine if the user is an Eaze employee
function verifyDeliveriesAccess (store) {
  const state = store.store.getState()
  const partnerDeliveriesAccess = getPartnerDeliveriesAccess(state)

  // we default to assuming all users can access deliveries, we only disable in emergencies
  if (partnerDeliveriesAccess) {
    return verifyAuthToken(store)
  } else {
    return verifyAuthToken(store) && verifyEazeEmployeeOnly(store)
  }
}
