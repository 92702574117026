import { connect } from 'react-redux'
import Login from './'

import { isLoggedIn } from 'src/redux/authentication/selectors'
import { requestLogin } from 'src/redux/authentication/actions'

const mapStateToProps = (state) => {
  return {
    isLoggedIn: isLoggedIn(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    requestLogin: (form) => dispatch(requestLogin(form))
  }
}

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)

export default reduxContainer
