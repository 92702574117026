import t from './actionTypes'

const defaultState = {}

const groups = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_GROUPS:
      return {
        ...state,
        ...action.groups
      }

    case t.CLEAR_GROUPS:
      return defaultState

    default:
      return state
  }
}

export default groups
