const packageJson = require('../../package.json')

module.exports = {
  API_BASE_URL: process.env.API_BASE_URL,
  CHECKLIST_URL: process.env.CHECKLIST_URL,
  EAZE_ENVIRONMENT: process.env.EAZE_ENVIRONMENT,
  FIREBASE_API_KEY: process.env.FIREBASE_API_KEY,
  FIREBASE_DATABASE_URL: process.env.FIREBASE_DATABASE_URL,
  GATEWAY_BASE_URL: process.env.GATEWAY_BASE_URL,
  GMAPS_KEY: process.env.GMAPS_KEY,
  IMAGE_BASE: process.env.IMAGE_BASE,
  MC_URL: process.env.MC_URL,
  SEGMENT_KEY: process.env.SEGMENT_KEY,
  SENTRY: process.env.SENTRY_URL,
  SOCKET_URL: process.env.SOCKET_URL,
  SOCKET_AUTH_KEY: process.env.SOCKET_AUTH_KEY,
  VERSION: packageJson.version,
  WEB_CLIENTS_BASE_URL: process.env.WEB_CLIENTS_BASE_URL,
  SPLIT_BROWSER_KEY: process.env.SPLIT_BROWSER_KEY
}
