export default {
  SET_PROMO_TO_EDIT: 'SET_PROMO_TO_EDIT',
  SET_SELECTED_PROMO_DEFINITION: 'SET_SELECTED_PROMO_DEFINITION',
  SET_PROMO_CODE: 'SET_PROMO_CODE',
  SET_PROMO_DESC: 'SET_PROMO_DESC',

  SET_PROMO_TYPE: 'SET_PROMO_TYPE',

  SET_PROMO_START_DATE: 'SET_PROMO_START_DATE',
  SET_PROMO_END_DATE: 'SET_PROMO_END_DATE',

  SET_PROMO_APPLIES_TO: 'SET_PROMO_APPLIES_TO',
  SET_PROMO_ID_TYPE: 'SET_PROMO_ID_TYPE',
  SET_PROMO_ID_VALUE: 'SET_PROMO_ID_VALUE',

  SET_PROMO_CREDIT_TYPE: 'SET_PROMO_CREDIT_TYPE',
  SET_PROMO_CREDIT_MAX: 'SET_PROMO_CREDIT_MAX',
  SET_PROMO_AMOUNT: 'SET_PROMO_AMOUNT',
  SET_PROMO_GLOBAL_USE: 'SET_PROMO_GLOBAL_USE',
  SET_PROMO_USER_USE: 'SET_PROMO_USER_USE',

  SET_PROMO_BUY_ONE_ID_TYPE: 'SET_PROMO_BUY_ONE_TYPE',
  SET_PROMO_BUY_ONE_ID_VALUE: 'SET_PROMO_BUY_ONE_VALUE',
  SET_PROMO_BUY_ONE_AMOUNT: 'SET_PROMO_BUY_ONE_AMOUNT',
  SET_PROMO_GET_ONE_ID_TYPE: 'SET_PROMO_GET_ONE_TYPE',
  SET_PROMO_GET_ONE_ID_VALUE: 'SET_PROMO_GET_ONE_VALUE',
  SET_PROMO_GET_ONE_AMOUNT: 'SET_PROMO_GET_ONE_AMOUNT',
  RESET_PROMO_BOGO: 'RESET_PROMO_BOGO',

  SET_PROMO_CHANNEL: 'SET_PROMO_CHANNEL',
  SET_PROMO_CRITERIA_ID: 'SET_PROMO_CRITERIA_ID',
  SET_PROMO_CRITERIA_VALUE: 'SET_PROMO_CRITERIA_VALUE',
  RESET_MANGE_PROMO_STATE: 'RESET_MANGE_PROMO_STATE',
  SET_ORDER_HISTORY: 'SET_ORDER_HISTORY',
  SET_PROMO_USER_IDS: 'SET_PROMO_USER_IDS',
  SET_PROMO_DEPOTS: 'SET_PROMO_DEPOTS',
  SET_PROMO_DISPENSARY: 'SET_PROMO_DISPENSARY',
  SET_TOTAL_ORDER_MIN: 'SET_TOTAL_ORDER_MIN',
  SET_CATALOG_NAME: 'SET_CATALOG_NAME',
  SET_MIN_ORDER_VALUE: 'SET_MIN_ORDER_VALUE',
  SET_PROMO_ID: 'SET_PROMO_ID',
  RESET_MANAGE_PROMO_STATE: 'RESET_MANAGE_PROMO_STATE',
  SET_PROMO_STATE_ID: 'SET_PROMO_STATE_ID'
}
