import history from 'components/router/history'
import ROUTES from 'components/router/routes'

export const DASHBOARD_NAMES = {
  cases: {
    name: 'cases',
    label: 'Cases'
  },
  deliveries: {
    name: 'deliveries',
    label: 'Deliveries'
  },
  hub: {
    name: 'hub-and-spoke',
    label: 'Hub & Spoke'
  },
  inventory: {
    name: 'inventory',
    label: 'Depot Case'
  },
  dispensary: {
    name: 'inventory',
    label: 'Dispensary Case'
  },
  drivers: {
    name: 'drivers',
    label: 'Drivers'
  },
  packer: {
    name: 'hub-and-spoke/packer',
    label: 'Packer'
  },
  runner: {
    name: 'hub-and-spoke/runner',
    label: 'Runner'
  },
  canceled: {
    name: 'hub-and-spoke/canceled',
    label: 'Restock'
  },
  settings: {
    name: 'settings',
    label: 'Settings'
  }
}

export function goToCaseDashboard (depotId) {
  if (!depotId) return history.push(`${ROUTES.DASHBOARD}`)

  return history.push(`${ROUTES.DASHBOARD}/${depotId}/cases`)
}
