import { isValidInput } from 'src/helpers/form-validators'

export const formatTerritoriesMap = function (territories) {
  const territoriesMap = {}

  territories.forEach(function (territory) {
    territoriesMap[territory.id] = territory
  })

  return territoriesMap
}

// we only want zipcode OR city and state to be submitted to the backend
export const getIsValidZipcodeAndCity = (zipcode, city) => {
  const hasZipcode = isValidInput(zipcode)
  const hasCity = isValidInput(city)

  if (hasCity && !hasZipcode) {
    return true
  }

  if (!hasCity && hasZipcode) {
    return true
  }

  return false
}

// we want to allow the form to submit if a city and a state exist
// or if only a zip code exists
export const getIsValidCityAndState = (zipcode, city, state) => {
  const hasZipcode = isValidInput(zipcode)
  const hasCity = isValidInput(city)
  const hasState = isValidInput(state)

  if (hasZipcode && !hasState) {
    return true
  }

  if (hasCity && hasState) {
    return true
  }

  return false
}
