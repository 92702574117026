import React from 'react'
import { string } from 'prop-types'

function CarIcon ({ color, height, width }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 11'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>Car</title>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Car' fill={color} fillRule='nonzero'>
          <path d='M11.0526316,3.78947368 L10.9894737,3.78947368 L10.8154737,1.17915789 C10.7712632,0.515684211 10.2202105,0 9.55515789,0 L2.44484211,0 C1.77978947,0 1.22873684,0.515684211 1.18452632,1.17915789 L1.01052632,3.78947368 L0.947368421,3.78947368 C0.424105263,3.78947368 0,4.21357895 0,4.73684211 L0,7.89473684 C0,8.24368421 0.282631579,8.52631579 0.631578947,8.52631579 L0.947368421,8.52631579 L0.947368421,10.1052632 C0.947368421,10.4542105 1.23,10.7368421 1.57894737,10.7368421 L2.21052632,10.7368421 C2.55947368,10.7368421 2.84210526,10.4542105 2.84210526,10.1052632 L2.84210526,8.52631579 L9.15789474,8.52631579 L9.15789474,10.1052632 C9.15789474,10.4542105 9.44052632,10.7368421 9.78947368,10.7368421 L10.4210526,10.7368421 C10.77,10.7368421 11.0526316,10.4542105 11.0526316,10.1052632 L11.0526316,8.52631579 L11.3684211,8.52631579 C11.7173684,8.52631579 12,8.24368421 12,7.89473684 L12,4.73684211 C12,4.21357895 11.5758947,3.78947368 11.0526316,3.78947368 Z M2.12968421,1.242 C2.14073684,1.07684211 2.27936842,0.947368421 2.44484211,0.947368421 L9.55515789,0.947368421 C9.72094737,0.947368421 9.85926316,1.07684211 9.87031579,1.242 L10.0398947,3.78947368 L1.96010526,3.78947368 L2.12968421,1.242 Z M9.94736842,6.63157895 C9.51126316,6.63157895 9.15789474,6.27821053 9.15789474,5.84210526 C9.15789474,5.406 9.51126316,5.05263158 9.94736842,5.05263158 C10.3834737,5.05263158 10.7368421,5.406 10.7368421,5.84210526 C10.7368421,6.27821053 10.3834737,6.63157895 9.94736842,6.63157895 Z M2.05263158,6.63157895 C1.61652632,6.63157895 1.26315789,6.27821053 1.26315789,5.84210526 C1.26315789,5.406 1.61652632,5.05263158 2.05263158,5.05263158 C2.48873684,5.05263158 2.84210526,5.406 2.84210526,5.84210526 C2.84210526,6.27821053 2.48873684,6.63157895 2.05263158,6.63157895 Z' id='Shape' />
        </g>
      </g>
    </svg>
  )
}

CarIcon.propTypes = {
  color: string,
  height: string,
  width: string
}

CarIcon.defaultProps = {
  color: '#948A91',
  height: '1.1rem',
  width: '1.2rem'
}

export default CarIcon
