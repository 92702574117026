import { connect } from 'react-redux'
import React, { Component } from 'react'
import { func, array } from 'prop-types'

import ROUTES from 'src/pages/catalog/routes'
import { getSortedCatalogGroups } from 'src/redux/catalog/selectors'
import { getPromoStateId } from 'src/redux/manage-promos/selectors'
import { mapToCatalogSearch } from 'src/redux/catalog/actions'
import CatalogGroupSearch from './'

// Props passed to the component
const mapStateToProps = (state, props) => {
  return {
    list: getSortedCatalogGroups(state, 'name'),
    stateId: getPromoStateId(state)
  }
}

// Methods passed to the components
const mapDispatchToProps = (dispatch, props) => {
  return {
    searchCatalogGroups: (value, state) => dispatch(mapToCatalogSearch(value, ROUTES.groups.name, state))
  }
}

class CatalogGroupSearchContainer extends Component {
  static propTypes = {
    list: array,
    searchCatalogGroups: func
  }

  render () {
    return (
      <CatalogGroupSearch {...this.props} />
    )
  }
}

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CatalogGroupSearchContainer)

export default reduxContainer
