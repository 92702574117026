import { createSelector } from 'reselect'
import {
  isValidInput,
  isValidImage,
  isValidEffect
} from 'src/helpers/form-validators'

const getState = (state) => state

export const getCatalogItem = createSelector(
  [getState], (state) =>
    state.catalogItem
)

export const getSkippedDepots = createSelector(
  [getState], (state) =>
    state.catalog.skippedDepots
)

export const cantCreateProduct = createSelector(
  [getCatalogItem],
  (catalogItem) => {
    const keys = Object.keys(catalogItem)

    const { id } = catalogItem
    const isEdit = Boolean(id)

    return keys.some(k => {
      // block of keys that are not required
      if (k === 'id') return false
      if (k === 'itemSpecies') return false
      if (k === 'weight') return false
      if (k === 'volume') return false
      if (k === 'status') return false
      if (k === 'excise_tax_exempt') return false
      if (k === 'dietary') return false
      if (k === 'ingredients') return false
      if (k === 'thc') return false
      if (k === 'cbd') return false
      if (k === 'highCbd') return false
      if (k === 'enabled') return false
      // only make slug field required on edit
      if (!isEdit && k === 'slug') return false

      const value = catalogItem[k]

      if (k === 'images') {
        return !isValidImage(value)
      }

      if (k === 'effects') {
        return value.some((effect, index) => !isValidEffect(effect, index))
      }

      // we don't want to allow falsey values but we do want to allow 0
      if (k === 'cogs') {
        return !isValidInput(value) && value !== 0
      }

      return !isValidInput(value)
    })
  }
)
