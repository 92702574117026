import React, { Component } from 'react'
import { string, bool } from 'prop-types'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { generateImageUrl } from 'helpers/get-cloudflare-img-url.js'

import Color from 'microcomponents/colors'

export const SIZES = {
  SMALL: 'SMALL',
  LARGE: 'LARGE'
}

export default class ProductImage extends Component {
  static defaultProps = {
    imageUrl: '',
    load: false,
    lazy: false,
    skipAnimation: false,
    size: SIZES.SMALL,
    color: Color.accessory[5]
  }

  static propTypes = {
    imageUrl: string,
    lazy: bool,
    load: bool,
    size: string,
    color: string,
    skipAnimation: bool
  }

  state = {
    loaded: false
  }

  componentDidUpdate () {
    this.shouldWeLoad()
  }

  componentDidMount () {
    this.shouldWeLoad()
  }

  shouldWeLoad = () => {
    const { lazy, load } = this.props
    if (this.state.loaded) return

    if (lazy && load) {
      this.preload()
    } else if (!lazy) {
      this.preload()
    }
  }

  preload = () => {
    const { imageUrl } = this.props
    const img = new window.Image()

    img.src = generateImageUrl(imageUrl)

    img.onload = () => {
      this.setState({ loaded: true })
    }

    img.onerror = () => {
      this.setState({ loaded: true })
    }
  }

  render () {
    const { imageUrl, size, color, skipAnimation } = this.props

    const src = generateImageUrl(imageUrl)

    return (
      <Container
        loaded={this.state.loaded}
        color={color}
        size={size}
      >
        <ImageComponent
          src={
            skipAnimation
              ? src
              : this.state.loaded
                ? src
                : null
          }
          loaded={this.state.loaded}
          alt='product image'
          skipAnimation={skipAnimation}
        />
      </Container>
    )
  }
}

const Container = styled.div`
  width: 100%;
  height: ${({ size }) => size === SIZES.LARGE ? '26rem' : '16rem'};
  background-color: ${({ color, loaded }) => loaded ? 'white' : color};
  object-fit: contain;
  position: relative;
`

const fadeIn = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`

const ImageComponent = styled.img`
  opacity: 0;
  opacity: ${({ loaded, skipAnimation }) => skipAnimation ? 1 : loaded ? 1 : 0};
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  animation: ${({ loaded, skipAnimation }) => loaded && !skipAnimation ? fadeIn : null} 0.2s linear forwards;
`
