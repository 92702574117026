import t from './actionTypes'

const defaultState = {
  list: [],
  page: 0,
  total: 0,
  query: '',
  manageBar: false,
  promoPreview: false,
  editPromo: false,
  cloneable: false,
  searchQuery: ''
}

const promos = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_PROMOS:
      return Object.assign({}, state, {
        list: action.promos
      })

    case t.TOGGLE_MANAGE_BAR:
      return Object.assign({}, state, {
        manageBar: !state.manageBar
      })

    case t.TOGGLE_PROMO_PREVIEW:
      return Object.assign({}, state, {
        promoPreview: action.value
      })

    case t.SET_PROMO_CLONEABLE:
      return Object.assign({}, state, {
        cloneable: action.value
      })

    case t.SET_EDIT_PROMO:
      return {
        ...state,
        editPromo: action.payload
      }

    case t.SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.value
      }

    // case t.SET_PAGE:
    //   return Object.assign({}, state, {
    //     page: action.page
    //   })

    // case t.SET_TOTAL:
    //   return Object.assign({}, state, {
    //     total: action.total
    //   })

    // case t.SET_QUERY:
    //   return Object.assign({}, state, {
    //     query: action.query
    //   })

    default:
      return state
  }
}

export default promos
