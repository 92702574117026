import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { Flex } from 'src/microcomponents/flexbox-helpers'
import styled from '@emotion/styled'
import Item from './item'
import { primaryDark } from 'src/helpers/css-variables'
import { array, bool, object, string } from 'prop-types'

const SortableList = (props) => {
  const {
    brandsMap,
    className,
    emptyListText,
    itemClassName,
    items,
    shouldLinkOutItems,
    speciesMap,
    narrow = false,
    listName
  } = props

  return (
    <Droppable droppableId={listName}>
      {(provided) => (
        <ul
          className={className}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {
            (!items.length && emptyListText) &&
              (
                <EmptyListText
                  align='center'
                  justify='center'
                >
                  {emptyListText}
                </EmptyListText>
              )
          }
          {/* We fall back to name as that what catalog groups use */}
          {items && items.map((item, index) => {
            const {
              brand = {},
              brand_id: brandId,
              id,
              items,
              name,
              slug,
              species = {},
              species_id: speciesId,
              state_id: stateCode,
              suggested_retail_price: srp,
              title
            } = item

            return (
              <Item
                className={itemClassName}
                count={srp ? `$${srp}` : items.length}
                id={id}
                index={index}
                key={`item-${id}`}
                shouldLinkOutItems={shouldLinkOutItems}
                species={species.name || (speciesMap && speciesMap[speciesId] && speciesMap[speciesId].name)}
                slug={slug}
                narrow={narrow}
                stateCode={stateCode}
                subvalue={brand.name || (brandsMap && brandsMap[brandId] && brandsMap[brandId].name)}
                value={title || name}
              />
            )
          })}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  )
}

SortableList.propTypes = {
  brandsMap: object,
  className: string,
  emptyListText: string,
  itemClassName: string,
  items: array,
  shouldLinkOutItems: bool,
  speciesMap: object,
  narrow: bool,
  listName: string
}

const EmptyListText = styled(Flex)`
  color: ${primaryDark};
  height: 100%;
  text-align: center;
`

export default SortableList
