import { createSelector } from 'reselect'

const getSoundAlerts = (state) => state.soundAlerts

export const getAreCanceledOrdersMuted = createSelector(
  [getSoundAlerts],
  soundAlerts => soundAlerts.areCanceledOrdersMuted
)

export const getAreIncomingOrdersMuted = createSelector(
  [getSoundAlerts],
  soundAlerts => soundAlerts.areIncomingOrdersMuted
)

export const getAreManifestsToPrintMuted = createSelector(
  [getSoundAlerts],
  soundAlerts => soundAlerts.areManifestsToPrintMuted
)
