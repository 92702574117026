import React, { PureComponent } from 'react'
import { number, shape, string } from 'prop-types'

import El from './order-summary-elements'

export default class SpecialInfoBox extends PureComponent {
  static propTypes = {
    color: string,
    orderTotals: shape({
      cash: shape({
        subtitle: string,
        total: number
      }),
      cc: shape({
        subtitle: string,
        total: number
      }),
      ccInPerson: shape({
        subtitle: string,
        total: number
      })
    })
  }

  render () {
    const { color, orderTotals } = this.props
    const orderTotalsArray = Object.entries(orderTotals)

    return (
      <El.SpecialContentBlock color={color}>
        {orderTotalsArray.map(([paymentType, paymentTypeInfo], index) => {
          const { subtitle, total } = paymentTypeInfo
          return (
            <El.OperatorRow key={index}>
              <div>
                <El.TitleSmall>{`$${total.toFixed(2)}`}</El.TitleSmall>
                <El.Subtitle>{subtitle}</El.Subtitle>
              </div>
              {index < orderTotalsArray.length - 1 &&
                <El.Operator>{index === orderTotalsArray.length - 2 ? '=' : '+'}</El.Operator>
              }
            </El.OperatorRow>
          )
        })
        }
      </El.SpecialContentBlock>
    )
  }
}
