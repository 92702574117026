import React, { PureComponent } from 'react'
import debounce from 'debounce'
import window from 'global/window'
import { bool, func, object } from 'prop-types'
import { Flex, Column } from 'src/microcomponents/flexbox-helpers'

import Button, { HYBRID, DANGER } from 'components/button'
import InputLabel from 'src/microcomponents/input-label'
import { isValidInput } from 'src/helpers/form-validators'

import { transformToCatalogType } from 'src/redux/catalog/type/actions'

import { inputError } from '../../style.js'

export class EditType extends PureComponent {
  state = {
    formSubmitAttempted: false,
    // using transformToCatalogType in order to make sure the local
    // catalogType has the same shape as the redux store's catalogType
    catalogType: transformToCatalogType({}),
    isEdit: false
  }

  static propTypes = {
    cantCreateType: bool,
    catalogType: object,
    createCatalogType: func,
    deleteCatalogType: func,
    isTypeFormOpen: bool,
    setCatalogType: func,
    updateCatalogType: func
  }

  // destructuring isTypeFormOpen prevProps to compare to this.props.isTypeFormOpen
  // to determine whether the form was opened from a closed state
  componentDidUpdate ({ isTypeFormOpen }) {
    // if we are going to open the form, ensure we reset formSubmitAttempted
    const { catalogType } = this.props
    if (isTypeFormOpen === false && this.props.isTypeFormOpen === true) {
      const { id } = catalogType
      const isEdit = Boolean(id)
      this.setState({
        formSubmitAttempted: false,
        catalogType,
        isEdit
      })
    }
  }

  deleteType = (event) => {
    event.preventDefault()
    const { catalogType: { name } } = this.state
    const confirmMessage = `Are you sure you want to delete the ${name} type from the catalog?`
    if (window.confirm(confirmMessage)) {
      this.props.deleteCatalogType()
    }
  }

  submitForm = (event) => {
    event.preventDefault()
    const { isEdit } = this.state
    const { cantCreateType, createCatalogType, updateCatalogType } = this.props
    this.setState({ formSubmitAttempted: true })

    if (!cantCreateType) {
      if (isEdit) {
        updateCatalogType()
      } else {
        createCatalogType()
      }
    }
  }

  // debounce this callback so we don't spam redux updates on change of form fields
  updateStore = debounce(() => {
    const { setCatalogType } = this.props
    const { catalogType } = this.state
    setCatalogType(catalogType)
  }, 200)

  updateTypeState = (catalogType) => {
    this.setState({ catalogType }, this.updateStore)
  }

  setFormValue = ({ target: { value } }, formKey) => {
    const { catalogType } = this.state
    const newCatalogType = {
      ...catalogType,
      [formKey]: value
    }
    this.updateTypeState(newCatalogType)
  }

  render () {
    const { isEdit, formSubmitAttempted, catalogType } = this.state
    const { name, description } = catalogType

    return (
      <Column componentStyle={{ padding: '1rem' }}>
        <h1>Add a Type to Catalog</h1>
        <form onSubmit={this.submitForm}>
          <Column>
            <InputLabel content='* name' />
            <input
              className={formSubmitAttempted && !isValidInput(name) ? inputError : ''}
              onChange={(event) => this.setFormValue(event, 'name')}
              value={name}
              placeholder=''
            />
          </Column>

          <Column top={1}>
            <InputLabel content='Description' />
            <textarea
              onChange={(event) => this.setFormValue(event, 'description')}
              value={description}
              cols='2'
              rows='5'
            />
          </Column>

          <Flex top={3}>
            <Button type={HYBRID}>
              Submit
            </Button>
          </Flex>

          {
            isEdit && (
              <Flex top={1}>
                <Button
                  type={DANGER}
                  onClick={this.deleteType}
                  disabled
                >
                  Delete
                </Button>
              </Flex>
            )
          }

        </form>
      </Column>
    )
  }
}

export default EditType
