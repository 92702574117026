import api from 'api'

export function uploadPublicFile (formData) {
  return async () => {
    const {err, data} = await api.uploadPublic(formData)
    return {err, data}
  }
}

export function uploadPrivateFile (formData) {
  return async () => {
    const {err, data} = await api.upload(formData)
    return {err, data}
  }
}
