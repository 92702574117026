import { css } from 'emotion'
import { accessory2, softBlack } from 'helpers/css-variables'

export const container = css`
  text-align: left;
  font-size: 1.4rem;
  font-family: system-ui;
`

export const price = css`
  color: ${softBlack};
`

export const name = css`
  word-wrap: break-word;
`

export const brand = css`
  color: ${accessory2};
`
