import { func, object, string } from 'prop-types'
import React from 'react'
import CatalogSearch from 'src/components/catalog-search/container'
import CatalogGroupSearch from './form-sections/CatalogGroupSearch/container'
import {
  selectContainer
} from './style.js'

/*
Inputs:
  bogoOperators: rules.operators[buy||get] from managePromos reducer
  catalog: reducer
  setIdValue: action
*/

const InputGroupBogo = ({ bogoIdType, bogoIdValue, catalog, setIdValue, catalogName }) => {
  const renderBogoInputs = () => {
    switch (bogoIdType) {
      case 'item':
        return (
          <CatalogSearch
            setResult={setIdValue}
            defaultValue={catalogName || ''}
          />
        )
      case 'group':
        return (
          <CatalogGroupSearch setResult={setIdValue} />
        )
      case 'brand':
        return (
          <div className={selectContainer}>
            <select onChange={setIdValue} value={bogoIdValue || 0}>
              <option disabled='disabled' value={0}>Select {bogoIdType}</option>
              {catalog.brands.map((opt, ind) => <option key={ind} value={opt.id}>{opt.name}</option>)}
            </select>
          </div>
        )
      default:
        return (
          <div className={selectContainer}>
            <select>
              <option type='text' disabled>Select a type first</option>
            </select>
          </div>
        )
    }
  }
  return (
    <div>
      {renderBogoInputs(bogoIdType, bogoIdValue, catalog, setIdValue)}
    </div>
  )
}

InputGroupBogo.propTypes = {
  bogoIdType: string,
  bogoIdValue: string,
  catalog: object,
  setIdValue: func,
  catalogName: string
}

export default InputGroupBogo
