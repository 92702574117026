import React, { PureComponent } from 'react'
import { func, oneOfType, string, number, object } from 'prop-types'
import styled from '@emotion/styled'
import Button, { DANGER } from 'components/button'
import InputLabel from 'src/microcomponents/input-label'
import { Flex, Column } from 'src/microcomponents/flexbox-helpers'
import { tertiaryDark } from 'src/helpers/css-variables'
import Colors from 'microcomponents/colors'

class VehicleForm extends PureComponent {
  static propTypes = {
    vehicle: object,
    closeDrawer: func.isRequired,
    closeForm: func.isRequired,
    createDriverVehicle: func,
    updateDriverVehicle: func,
    driverId: oneOfType([number, string]),
    onfleetWorkerId: string
  }

  state = (() => {
    const {
      driverOccupationalLicenseNumber = '',
      driversLicenseNumber = '',
      licensePlateNumber = '',
      vehicleModel = '',
      vehicleColor = '',
      vehicleMake = '',
      vehicleYear = '',
      vehicleVin = '',
      isCurrent = true
    } = this.props.vehicle
    return {
      loading: false,
      isCurrent,
      driverOccupationalLicenseNumber,
      driversLicenseNumber,
      licensePlateNumber,
      vehicleModel,
      vehicleColor,
      vehicleMake,
      vehicleYear,
      vehicleVin
    }
  })()

  closeDrawer = () => {
    const { closeDrawer, closeForm } = this.props
    closeForm()
    closeDrawer()
  }

  updateField = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  formIsComplete = () => {
    const {
      driversLicenseNumber,
      licensePlateNumber,
      vehicleModel,
      vehicleColor,
      vehicleMake,
      vehicleYear,
      vehicleVin
    } = this.state
    return [
      driversLicenseNumber,
      licensePlateNumber,
      vehicleModel,
      vehicleColor,
      vehicleMake,
      vehicleYear,
      vehicleVin
    ].every(input => typeof input === 'string' && input.trim() !== '')
  }

  modifyVehicle = async (e, isUpdate) => {
    e.preventDefault()
    const { closeForm, createDriverVehicle, updateDriverVehicle, driverId, vehicle, onfleetWorkerId } = this.props
    const {
      driverOccupationalLicenseNumber,
      driversLicenseNumber,
      licensePlateNumber,
      vehicleModel,
      vehicleColor,
      vehicleMake,
      vehicleYear,
      vehicleVin,
      isCurrent
    } = this.state
    this.setState({ loading: true })

    if (isUpdate) {
      // only send update to OG/Onfleet if its the users current vehicle
      const onfleetId = isCurrent ? onfleetWorkerId : null
      await updateDriverVehicle(driverId, vehicle.id, {
        userId: `${driverId}`,
        driverOccupationalLicenseNumber,
        driversLicenseNumber,
        licensePlateNumber,
        vehicleModel,
        vehicleColor,
        vehicleMake,
        vehicleYear,
        vehicleVin,
        isCurrent
      }, onfleetId)
    } else {
      await createDriverVehicle({
        userId: `${driverId}`,
        driverOccupationalLicenseNumber,
        driversLicenseNumber,
        licensePlateNumber,
        vehicleModel,
        vehicleColor,
        vehicleMake,
        vehicleYear,
        vehicleVin,
        isCurrent
      }, onfleetWorkerId)
    }
    this.setState({ loading: false })
    closeForm()
  }

  render () {
    const { closeForm, vehicle, driverId } = this.props
    const {
      loading,
      driversLicenseNumber,
      licensePlateNumber,
      vehicleModel,
      vehicleColor,
      vehicleMake,
      vehicleYear,
      vehicleVin
    } = this.state

    const isUpdate = Object.keys(vehicle).length !== 0

    return (
      <Container className='VehicleForm'>
        <form onSubmit={(e) => this.modifyVehicle(e, isUpdate)}>
          <TitleRow className='TitleRow'>
            <Title className='Title'>Vehicle & License</Title>
          </TitleRow>

          <Flex full>
            <Column full>
              <Subtitle className='Subtitle'>Vehicle Details</Subtitle>
            </Column>
          </Flex>

          <InputLabel content='Make' htmlFor='vehicleMake' />
          <Input
            id='vehicleMake'
            name='vehicleMake'
            value={vehicleMake}
            onChange={this.updateField}
          />

          <InputLabel content='Model' htmlFor='vehicleModel' />
          <Input
            id='vehicleModel'
            name='vehicleModel'
            value={vehicleModel}
            onChange={this.updateField}
          />

          <InputLabel content='Vin' htmlFor='vehicleVin' />
          <Input
            id='vehicleVin'
            name='vehicleVin'
            value={vehicleVin}
            type='text'
            pattern='.{17,17}'
            maxLength='17'
            onInput={(e) => e.target.setCustomValidity('')}
            onInvalid={(e) => e.target.setCustomValidity('Should be 17 characters')}
            onChange={this.updateField}
          />

          <Flex full>
            <Column componentStyle={{ marginRight: '10px' }} full>
              <InputLabel content='Year' htmlFor='vehicleYear' />
              <Input
                id='vehicleYear'
                name='vehicleYear'
                value={vehicleYear}
                type='text'
                pattern='[0-9]{4,4}'
                maxLength='4'
                onInput={(e) => e.target.setCustomValidity('')}
                onInvalid={(e) => e.target.setCustomValidity('Should be 4 numbers')}
                onChange={this.updateField}
              />
            </Column>
            <Column full>
              <InputLabel content='Color' htmlFor='vehicleColor' />
              <Input
                id='vehicleColor'
                name='vehicleColor'
                value={vehicleColor}
                onChange={this.updateField}
              />
            </Column>
          </Flex>

          <InputLabel content='Plate' htmlFor='licensePlateNumber' />
          <Input
            id='licensePlateNumber'
            name='licensePlateNumber'
            value={licensePlateNumber}
            maxLength='8'
            onChange={this.updateField}
          />

          <VerticalSpacer />

          <Subtitle className='Subtitle'>License</Subtitle>

          <InputLabel content="Driver's License Number" htmlFor='driversLicenseNumber' />
          <Input
            id='driversLicenseNumber'
            name='driversLicenseNumber'
            value={driversLicenseNumber}
            onChange={this.updateField}
          />

          <InputLabel content="Metrc License Number (Driver's User Id)" htmlFor='driverOccupationalLicenseNumber' />
          <Input
            id='driverOccupationalLicenseNumber'
            name='driverOccupationalLicenseNumber'
            defaultValue={driverId}
            onChange={this.updateField}
          />

          <BottomControls className='BottomControls'>
            <Button
              type='submit'
              disabled={loading || !this.formIsComplete()}
              loading={loading}
            >
              {isUpdate ? 'Update Vehicle' : 'Add Vehicle'}
            </Button>

            <Button
              type={DANGER}
              disabled={loading}
              loading={loading}
              inverted
              onClick={closeForm}
              componentStyle={{
                backgroundColor: 'rgba(255, 255, 255, 0)'
              }}
            >
              CANCEL
            </Button>
          </BottomControls>
        </form>
      </Container>
    )
  }
}

const Container = styled.div`
  padding: 3.6rem 2.4rem;
  color: #fff;
  width: 58rem;
  height: 100%;
  overflow-y: auto;
`
const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4.8rem;
`

const Title = styled.h1`
  margin: 0;
  letter-spacing: 0.1rem;
`

const Subtitle = styled.h2`
  letter-spacing: 0.04rem;
  margin: 0;
  margin-bottom: 2.4rem;
  line-height: 2.4rem;
`

const Input = styled.input`
  width: 100%;
  height: 4.8rem;
  padding-left: 1.2rem;
  margin-top: 0.4rem;
  margin-bottom: 1.2rem;
  background-color: ${tertiaryDark};
  border-radius: 0.2rem;
  font-size: 1.6rem;
  color: white;

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    border: 0;
    border-radius: 0.2rem;
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0 100rem ${tertiaryDark} inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`

const BottomControls = styled.div`
  margin-top: 4.8rem;

  button:first-child {
    background-color: ${Colors.primary[1]};
    margin-bottom: 1.2rem;
  }
`

const VerticalSpacer = styled.div`
  height: 2.4rem;
`

export default VehicleForm
