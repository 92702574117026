import { bool, number, oneOfType, shape, string } from 'prop-types'

export const dataPropTypeShape = shape({
  id: oneOfType([string, number]), // can use number for nullable type
  city: string,
  comments: string,
  zipcode: string,
  enabled: bool,
  state: string,
  identityScanRequired: bool,
  selfieRequired: bool,
  usaOnly: bool
})
