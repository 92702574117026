import api from 'api'

import { pushNotification, SUCCESS } from 'src/redux/alerts/actions'
import {
  SET_CATALOG_SUBTYPE,
  SHOW_SUBTYPE_FORM,
  HIDE_SUBTYPE_FORM,
  SET_CATALOG_SUBTYPES
} from 'src/redux/catalog/subtype/actionTypes'
import {
  hideActionBar,
  clearFormQueryParams
} from 'src/redux/catalog/actions.common'

export function createCatalogSubtype () {
  return async (dispatch, getState) => {
    const state = getState()
    const { catalogSubtype } = state
    const body = transformFromCatalogSubtype(catalogSubtype)

    const { err, data } = await api.createCatalogSubtype(body)
    if (err) return

    dispatch(pushNotification(`Successfully created subtype ${catalogSubtype.name}`, SUCCESS))
    dispatch(hideSubtypeForm())
    dispatch(addCatalogSubtype(data))
  }
}

export function updateCatalogSubtype (routerLocation) {
  return async (dispatch, getState) => {
    const state = getState()
    const { catalogSubtype } = state
    const body = transformFromCatalogSubtype(catalogSubtype)

    const { err, data } = await api.updateCatalogSubtype(body)
    if (err) return

    dispatch(pushNotification(`Successfully updated subtype ${catalogSubtype.name}`, SUCCESS))
    dispatch(hideSubtypeForm(routerLocation))
    dispatch(replaceCatalogSubtype(data))
  }
}

export function deleteCatalogSubtype (routerLocation) {
  return async (dispatch, getState) => {
    const state = getState()
    const { catalogSubtype } = state
    const { id } = catalogSubtype

    const { err } = await api.deleteCatalogSubtype({ id })
    if (err) return

    dispatch(pushNotification(`Successfully deleted subtype ${catalogSubtype.name}`, SUCCESS))
    dispatch(removeCatalogSubtype(catalogSubtype))
    dispatch(hideSubtypeForm(routerLocation))
  }
}

// parser to ignore unused keys from the response
export function transformToCatalogSubtype (subtype) {
  const { type = {} } = subtype

  return {
    id: subtype.id || null,
    name: subtype.name || '',
    description: subtype.description || '',
    parentType: type.id || '',
    slug: subtype.slug || ''
  }
}

// parser to format keys from the redux store to match what the response wants
export function transformFromCatalogSubtype ({ name, id, description, parentType, slug }) {
  return {
    id,
    name,
    description,
    slug,
    type_id: parentType
  }
}

export function setCatalogSubtype (subtype) {
  return {
    type: SET_CATALOG_SUBTYPE,
    subtype
  }
}

export function addCatalogSubtype (subtype) {
  // get subtypes, add subtype, update full list
  return (dispatch, getState) => {
    const state = getState()
    const { catalog: { subtypes } } = state

    dispatch(setCatalogSubtypes([subtype, ...subtypes]))
  }
}

export function removeCatalogSubtype (subtype) {
  // get list, search, remove, update full list
  return (dispatch, getState) => {
    const state = getState()
    const { catalog: { subtypes } } = state
    const subtypesWithItemsRemoved = subtypes.filter((subtypeItem) => subtypeItem.id !== subtype.id)

    dispatch(setCatalogSubtypes([...subtypesWithItemsRemoved]))
  }
}

export function replaceCatalogSubtype (subtype) {
  // get subtypes, search for subtype, replace, update full list
  return (dispatch, getState) => {
    const state = getState()
    const { catalog: { subtypes } } = state
    const newList = subtypes.map((subtypeItem) => subtypeItem.id === subtype.id ? subtype : subtypeItem)

    dispatch(setCatalogSubtypes(newList))
  }
}

export function showSubtypeForm (subtype) {
  return dispatch => {
    dispatch({ type: SHOW_SUBTYPE_FORM })

    const subtypeObject = subtype || {}
    const transformedSubtype = transformToCatalogSubtype(subtypeObject)
    dispatch(setCatalogSubtype(transformedSubtype))
    dispatch(hideActionBar())
  }
}

export function hideSubtypeForm (routerLocation) {
  return dispatch => {
    if (routerLocation) {
      clearFormQueryParams(routerLocation)
    }
    dispatch({ type: HIDE_SUBTYPE_FORM })
    // clear out form on hide
    const transformedSubtype = transformToCatalogSubtype({})
    dispatch(setCatalogSubtype(transformedSubtype))
  }
}

function setCatalogSubtypes (types) {
  return {
    type: SET_CATALOG_SUBTYPES,
    types
  }
}
