// https://github.com/bendrucker/complacent/blob/master/index.js
'use strict'

import Lazy from '@eaze/lazy-async'
import ap from 'ap'
import Google from '@eaze/load-google-maps'
import gpb from '@eaze/google-places-browser'

const partial = ap.partial
const Autocomplete = gpb.Autocomplete
const Places = gpb.Places

module.exports = Complacent

const methods = [
  'autocomplete.place',
  'autocomplete.query',
  'places.details',
  'places.nearby',
  'places.radar',
  'places.text'
]

function Complacent (options) {
  return Lazy(methods, partial(load, options))
}

function load (options, callback) {
  Google(options, function (err, google) {
    if (err) return callback(err)
    callback(null, {
      autocomplete: Autocomplete(google),
      places: Places(google)
    })
  })
}
