import t from './actionTypes'

export function setGroups (groups) {
  return {
    type: t.SET_GROUPS,
    groups
  }
}

export function clearGroups () {
  return {
    type: t.CLEAR_GROUPS
  }
}
