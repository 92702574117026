import t from './actionTypes'
import api from 'api'

import { fetchFocusedDriverOrders, fetchFocusedDriverInventory } from 'src/redux/deliveries/operations'

export function setOrderList (orders) {
  return {
    type: t.SET_ORDER_LIST,
    orders
  }
}

export function setOrderFocus (order) {
  return {
    type: t.SET_ORDER_FOCUS,
    order
  }
}

export function clearOrderFocus (order) {
  return {
    type: t.CLEAR_ORDER_FOCUS
  }
}

export function rerouteOrder (orderId, driverId) {
  return async dispatch => {
    const {err} = await api.rerouteOrder({ id: orderId })
    if (err) return

    dispatch(fetchFocusedDriverOrders(driverId))
    dispatch(fetchFocusedDriverInventory(driverId))
  }
}
