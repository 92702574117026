import React from 'react'
import { func, array, bool, object, string } from 'prop-types'

import PromoNav from './promo-nav'
import Screen from 'src/components/screen/container'
import Overlay from 'src/components/overlay'
import ManagePromo from './manage-promo'
import PromoTable from './promo-table'
import managePromosPropType from 'src/redux/manage-promos/prop-types'
import SearchInput from 'src/components/mui/search-input/'
import FabFan from 'src/components/fab-fan'

import {
  container,
  managePromosWrapper,
  promoNavContainer,
  tableContainer,
  tableContainerHeader,
  tableTitle,
  tableWrapper
} from './style.js'
export default class PromoPage extends React.PureComponent {
  static propTypes = {
    manageBar: bool,
    toggleManageBar: func,
    selectRow: func,
    togglePromo: func,
    catalog: object,
    cloneable: bool,
    clonePromo: func,
    createPromo: func,
    depots: array,
    depotsByState: object,
    dispensaries: array,
    editPromo: func,
    getCatalogBrand: func,
    getCatalogGroup: func,
    getCatalogItem: func,
    managePromos: managePromosPropType,
    promoEditing: bool,
    promoValidity: object,
    promoPreview: bool,
    promos: array,
    searchQuery: string,
    setCatalogName: func,
    setMinOrderValue: func,
    setPromoActions: object,
    setSearchQuery: func,
    setSelectedPromoDefinition: func,
    showPromoPreview: func,
    sortedCatalogBrands: array,
    sortedCatalogGroups: array,
    sortedCatalogItems: array
  }

  render () {
    const {
      toggleManageBar,
      selectRow,
      togglePromo,
      catalog,
      cloneable,
      clonePromo,
      createPromo,
      depots,
      depotsByState,
      dispensaries,
      editPromo,
      getCatalogBrand,
      getCatalogGroup,
      getCatalogItem,
      managePromos,
      manageBar,
      promoEditing,
      promoValidity,
      promoPreview,
      promos,
      searchQuery,
      setCatalogName,
      setMinOrderValue,
      setPromoActions,
      setSearchQuery,
      setSelectedPromoDefinition,
      showPromoPreview,
      sortedCatalogBrands,
      sortedCatalogGroups,
      sortedCatalogItems
    } = this.props

    return (
      <Screen>
        <div className={container}>
          <div className={promoNavContainer}>
            <PromoNav />
            <SearchInput
              placeholder='Search by Code, Product, Brand, etc...'
              onChange={setSearchQuery}
              value={searchQuery}
            />
          </div>
          <div className={tableContainer}>
            <div className={tableContainerHeader}>
              <h2 className={tableTitle}>Codes</h2>
            </div>
            <div className={tableWrapper}>
              <PromoTable
                promos={promos}
                selectRow={selectRow}
                togglePromo={togglePromo}
              />
              <Overlay
                isOpen={manageBar}
                onClick={toggleManageBar}
              />
            </div>
          </div>

          <div className={managePromosWrapper}>
            <FabFan options={[]} onClick={toggleManageBar} />

            <ManagePromo
              catalog={catalog}
              cloneable={cloneable}
              clonePromo={clonePromo}
              createPromo={createPromo}
              depots={depots}
              depotsByState={depotsByState}
              dispensaries={dispensaries}
              editPromo={editPromo}
              getCatalogBrand={getCatalogBrand}
              getCatalogGroup={getCatalogGroup}
              getCatalogItem={getCatalogItem}
              managePromos={managePromos}
              open={manageBar}
              promoEditing={promoEditing}
              promoValidity={promoValidity}
              promoPreview={promoPreview}
              promos={promos}
              setCatalogName={setCatalogName}
              setMinOrderValue={setMinOrderValue}
              setPromoActions={setPromoActions}
              setSelectedPromoDefinition={setSelectedPromoDefinition}
              showPromoPreview={showPromoPreview}
              sortedCatalogBrands={sortedCatalogBrands}
              sortedCatalogGroups={sortedCatalogGroups}
              sortedCatalogItems={sortedCatalogItems}
            />
          </div>
        </div>
      </Screen>
    )
  }
}
