import React, { PureComponent } from 'react'
import { bool, func, object } from 'prop-types'

import Lane from 'src/components/hub-and-spoke/lane'
import OrderCard from 'src/components/hub-and-spoke/order-card'
import { LANE_TYPES, printAllOrders } from 'helpers/hub'

import { hubContainer } from '../style.js'

// substitute this for driverOrders if you want to test
// const testDriverOrders = {
//   '123': [{products: [], delivery: {fullAddress: '123'}, createdBy: {lastName: 'managerman', firstName: 'nick'}, id: 5678, acceptedBy: {id: 2020, manifestPrinted: false, mobilePhoneDisplay: '(555)-555-5555', firstName: 'brendan'}}],
//   '456': [{products: [], delivery: {fullAddress: '123'}, createdBy: {lastName: 'money', firstName: 'eddie'}, id: 5678, acceptedBy: {id: 2021, manifestPrinted: false, mobilePhoneDisplay: '(555)-555-5555', firstName: 'jack'}}],
//   '789': [{products: [], delivery: {fullAddress: '123'}, createdBy: {lastName: 'breezy', firstName: 'chris'}, id: 5678, acceptedBy: {id: 2022, manifestPrinted: true, mobilePhoneDisplay: '(555)-555-5555', firstName: 'rebecca'}}]
// }

const needsManifestPrinted = function (driverOrders) {
  if (!driverOrders.length) return false

  const firstOrder = driverOrders[0]
  return !firstOrder.acceptedBy.hasPrintedManifest && !firstOrder.outForDelivery
}

const hasManifestPrinted = function (driverOrders) {
  if (!driverOrders.length) return false

  const firstOrder = driverOrders[0]
  return firstOrder.acceptedBy.hasPrintedManifest && !firstOrder.outForDelivery
}

const isOutForDelivery = function (driverOrders) {
  if (!driverOrders.length) return false

  const firstOrder = driverOrders[0]
  return firstOrder.outForDelivery
}

export default class Runner extends PureComponent {
  static propTypes = {
    areManifestsToPrintMuted: bool,
    driverOrders: object,
    toggleManifestToPrintSounds: func
  }

  render () {
    const {
      areManifestsToPrintMuted,
      driverOrders,
      toggleManifestToPrintSounds
    } = this.props

    const driverOrdersWithoutManifest = Object.values(driverOrders).filter(needsManifestPrinted)
    const driverOrdersWithManifest = Object.values(driverOrders).filter(hasManifestPrinted)
    const driverOrdersOutForDelivery = Object.values(driverOrders).filter(isOutForDelivery)

    return (
      <div className={hubContainer}>
        <Lane
          headerText={`Manifests To Print (${driverOrdersWithoutManifest.length})`}
          showSoundIcon
          isSoundMuted={areManifestsToPrintMuted}
          onSoundIconClickFn={toggleManifestToPrintSounds}
        >
          {
            driverOrdersWithoutManifest.map((orderQueue, i) => {
              return (
                <OrderCard
                  type={LANE_TYPES.AWAITING_DRIVER}
                  key={i}
                  orders={orderQueue}
                  onClickFn={() => printAllOrders(orderQueue, true)}
                />
              )
            })
          }
        </Lane>
        <Lane
          headerText={`With Runner (${driverOrdersWithManifest.length})`}
        >
          {
            driverOrdersWithManifest.map((orderQueue, i) => {
              return (
                <OrderCard
                  type={LANE_TYPES.AWAITING_DRIVER}
                  key={i}
                  orders={orderQueue}
                  onClickFn={() => printAllOrders(orderQueue, true)}
                />
              )
            })
          }
        </Lane>
        <Lane
          headerText={`Out for delivery (${driverOrdersOutForDelivery.length})`}
        >
          {
            driverOrdersOutForDelivery.map((orderQueue, i) => {
              return (
                <OrderCard
                  type={LANE_TYPES.AWAITING_DRIVER}
                  key={i}
                  orders={orderQueue}
                  onClickFn={() => printAllOrders(orderQueue, true)}
                />
              )
            })
          }
        </Lane>
      </div>
    )
  }
}
