import { createSelector } from 'reselect'

export const getFirebaseStore = (state) => state.firebase

export const getPartnerDeliveriesAccess = createSelector(
  [getFirebaseStore],
  function (firebaseStore) {
    return firebaseStore.partnerDeliveriesAccess
  }
)

export const getDeliveriesPollInterval = createSelector(
  [getFirebaseStore],
  function (firebaseStore) {
    return firebaseStore.deliveriesPollInterval
  }
)

export const getOrderBuilderPollInterval = createSelector(
  [getFirebaseStore],
  function (firebaseStore) {
    return firebaseStore.orderBuilderPollInterval
  }
)
