export default {
  CLEAR_DELIVERIES_STORE: 'CLEAR_DELIVERIES_STORE',
  TOGGLE_DELIVERIES_MAP_VIEW: 'TOGGLE_DELIVERIES_MAP_VIEW',
  SAVE_DELIVERIES_MAP: 'SAVE_DELIVERIES_MAP',
  SET_DELIVERIES_MODE: 'SET_DELIVERIES_MODE',
  SET_DELIVERY_TYPE: 'SET_DELIVERY_TYPE',
  TOGGLE_DELIVERIES_DEPOT_OVERVIEW: 'TOGGLE_DELIVERIES_DEPOT_OVERVIEW',
  SET_DELIVERIES_DRIVER_DETAILS_CONTENT: 'SET_DELIVERIES_DRIVER_DETAILS_CONTENT',
  SET_DELIVERIES_DEPOT_FILTER: 'SET_DELIVERIES_DEPOT_FILTER',
  SET_DELIVERIES_DEPOT_FILTER_STRING: 'SET_DELIVERIES_DEPOT_FILTER_STRING',
  SET_FOCUSED_DELIVERIES_DRIVER_ID: 'SET_FOCUSED_DELIVERIES_DRIVER_ID',
  SET_DELIVERIES_DRIVER_ORDERS_LOADING_MAP: 'SET_DELIVERIES_DRIVER_ORDERS_LOADING_MAP',
  SET_DELIVERIES_DRIVER_INVENTORY_LOADING_MAP: 'SET_DELIVERIES_DRIVER_INVENTORY_LOADING_MAP'
}
