import { connect } from 'react-redux'
import React from 'react'

import { closeNavSlider } from 'src/redux/navigation/actions'
import { logout } from 'src/redux/authentication/actions'
import { getIsAdmin, getIsDispManager } from 'src/redux/permissions/selectors'
import NavigationSlider from './'

// Props passed to the component
const mapStateToProps = (state, props) => {
  return {
    isOpen: state.navigation.slider,
    isAdmin: getIsAdmin(state),
    isDispManager: getIsDispManager(state)
  }
}

// Methods passed to the components
const mapDispatchToProps = (dispatch, props) => {
  return {
    closeNavSlider: () => dispatch(closeNavSlider()),
    logout: () => dispatch(logout())
  }
}

class NavigationSliderContainer extends React.PureComponent {
  render () {
    return (
      <NavigationSlider {...this.props} />
    )
  }
}

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationSliderContainer)

export default reduxContainer
