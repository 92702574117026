/**
 * Returns an array with the duplicate objects removed
 * @param {array} array - Array of objects to dedupe
 * @param {string} key - Object key to dedupe on
 * @returns {array} array is returned with duplicate objects removed
 */
function dedupeArrayByObjectKey (array, key) {
  const isArray = Array.isArray(array)
  const hasLengthAndKey = key && array.length && array[0][key]

  if (isArray && hasLengthAndKey) {
    return [...new Map(array.map((item, index) => [item[key], item])).values()]
  }
  return array
}

export default dedupeArrayByObjectKey
