import color from 'microcomponents/colors'

export const defaultColor = color.danger[1]
export const defaultSize = 1

export const getColorFromProps = ({ color }) => color || defaultColor

export const getTextToIconRatio = (newIconSize, iconSize = 1.4, fontSize = 1) => {
  // Let's base the icon size off the font size
  if (!newIconSize) return defaultSize

  return newIconSize * (iconSize / fontSize)
}
