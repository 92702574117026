import React, { PureComponent } from 'react'
import { array, func, string } from 'prop-types'
import { css } from 'emotion'
import styled from '@emotion/styled'

import List from './list-component'
import DepotSelectDrawer from './depot-select-drawer'
import McCard from 'src/components/mc-card'
import Overlay from 'src/components/overlay'
import Button, { PRIMARY, TRANSPARENT } from 'components/button'
import { ERROR } from 'src/redux/alerts/actions'
import CopyIcon from 'microcomponents/icons/copy'
import Create from './create'
import { FORM_STRING_TYPES } from 'src/helpers/constants'
import formatQuery from 'src/helpers/query-parameter'

import Drawer from '@eaze/react-fast-drawer'
import { secondaryDark, lightGray } from 'helpers/css-variables'

import {
  grid
} from '../style.js'

const { PRODUCT } = FORM_STRING_TYPES

export default class ProductList extends PureComponent {
  static propTypes = {
    activeDispensaries: array,
    create: func,
    createInventoryItems: func,
    pathname: string,
    products: array,
    pushNotification: func,
    skippedDepots: array,
    resetSkippedDepots: func
  }

  state = {
    isBulkCreateActive: false,
    bulkCreateId: '',
    bulkCreateState: '',
    selectedDepots: []
  }

  handleCreateInventory = (id, title, state) => (event) => {
    event.preventDefault()
    const {
      activeDispensaries,
      pushNotification
    } = this.props

    // bail early if dispensaries haven't loaded
    const hasActiveDispensaries = activeDispensaries.length
    if (!hasActiveDispensaries) {
      return pushNotification('Dispensaries have not loaded yet, please wait and try again.', ERROR)
    }

    this.setState({
      bulkCreateId: id,
      bulkCreateTitle: title,
      bulkCreateState: state,
      isBulkCreateActive: !this.state.toggleBulkCreateDrawer
    })
  }

  closeDepotDrawer = () => {
    this.setState({ isBulkCreateActive: false })
  }

  render () {
    const {
      activeDispensaries,
      createInventoryItems,
      create,
      pathname,
      products,
      pushNotification,
      skippedDepots,
      resetSkippedDepots
    } = this.props

    const {
      isBulkCreateActive,
      bulkCreateId,
      bulkCreateState,
      bulkCreateTitle
    } = this.state

    const hasActiveDispensaries = !!activeDispensaries.length

    return (
      <div>
        <List listClass={grid} items={products}>
          <Create
            action={create}
            title='add new product'
          />
          {
            products.map(product => {
              const {
                title = '',
                brand = {},
                images = [{}],
                suggested_retail_price: price,
                slug,
                id
              } = product
              const search = formatQuery(PRODUCT, id)
              const url = `${pathname}?${search}`

              return (
                <CardWrapper key={product.id}>
                  <McCard
                    key={product.id}
                    product={product}
                    url={url}
                    slug={slug}
                    name={title}
                    description={brand.name}
                    price={price}
                    image={images[0] && images[0].downloadUrl}
                    enabled={product.enabled}
                  >
                    <CardButtonsWrapper>
                      <Button
                        onClick={this.handleCreateInventory(id, title, product.state_id)}
                        type={TRANSPARENT}
                        data-testid='smart-bulk'
                        componentStyle={{ boxShadow: 'none', padding: '0.3rem' }}
                      >
                        <CopyIcon title='Click to select depots to create this product at.' />
                      </Button>
                    </CardButtonsWrapper>
                  </McCard>
                </CardWrapper>
              )
            })
          }
        </List>
        {
          hasActiveDispensaries &&
            <div style={{ position: 'relative', zIndex: '16' }}>
              <Overlay
                isOpen={isBulkCreateActive}
                onClick={this.closeDepotDrawer}
              />
              <DepotSelectDrawer
                activeDispensaries={activeDispensaries}
                isBulkCreateActive={isBulkCreateActive}
                bulkCreateId={bulkCreateId}
                bulkCreateState={bulkCreateState}
                title={bulkCreateTitle}
                createInventoryItems={createInventoryItems}
                pushNotification={pushNotification}
                closeDepotDrawer={this.closeDepotDrawer}
              />
              <Drawer
                open={skippedDepots.length !== 0}
                onRequestClose={resetSkippedDepots}
                modalElementClass={DrawerModalElementClass}
              >
                Product was not created in the following depots because it already exists:
                <ul>
                  {skippedDepots.map((el, index) => (
                    <li key={index}>
                      <strong>{el.name}</strong> | Depot #{el.id}
                    </li>
                  ))}
                </ul>
                <Button onClick={() => resetSkippedDepots()} type={PRIMARY}>CLOSE</Button>
              </Drawer>
            </div>
        }
      </div>
    )
  }
}

const DrawerModalElementClass = css`
  background-color: ${secondaryDark};
  color: ${lightGray};
  max-width: 45rem;
  padding: 30px;
`

const CardButtonsWrapper = styled.div`
  visibility: hidden;
`

const CardWrapper = styled.div`
  width: 100%;

  &:hover {
    ${CardButtonsWrapper} {
      visibility: visible;
    }
  }
`
