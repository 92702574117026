const yellow = {
  10: '#fff5e8',
  20: '#ffebcf',
  30: '#ffdeaf',
  40: '#ffc26a',
  50: '#ffab30',
  60: '#e38b0c',
  70: '#c37709',
  80: '#92621c',
  90: '#705023'
}

export default yellow
