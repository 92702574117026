import t from './action-types'
import api from 'api'
import { requestCatalogItem, receiveCatalogItem } from '../loading/actions'

export function setPromoToEdit (promo) {
  return {
    type: t.SET_PROMO_TO_EDIT,
    payload: promo
  }
}

export function setPromoCode (text) {
  return {
    type: t.SET_PROMO_CODE,
    text
  }
}

export function setPromoDescription (desc) {
  return {
    type: t.SET_PROMO_DESC,
    desc
  }
}

export function setPromoStartDate (date) {
  return {
    type: t.SET_PROMO_START_DATE,
    date
  }
}

export function setPromoEndDate (date) {
  return {
    type: t.SET_PROMO_END_DATE,
    date
  }
}

export function setSelectedPromoDefinition (definitionId) {
  return {
    type: t.SET_SELECTED_PROMO_DEFINITION,
    payload: definitionId
  }
}

// TODO: Move this to ./operations.js
export function setPromoAppliesTo (criteria) {
  return (dispatch) => {
    // ensure BOGO form fields are clean when we switch discount type
    dispatch(resetPromoBuyOneGetOne())

    if (criteria === 'order') {
      // reset criteria state because we may be switching to whole order discounts!
      dispatch({ type: t.SET_PROMO_ID_TYPE, payload: null })
      dispatch({ type: t.SET_PROMO_ID_VALUE, payload: null })
    }

    dispatch({ type: t.SET_PROMO_APPLIES_TO, payload: criteria })
  }
}

export function setPromoIdValue (value) {
  return (dispatch, getState) => {
    const state = getState()
    const catalogItemType = state.managePromos['rules.operators.id_type']

    // catalog 😭
    if (catalogItemType === 'item') {
      dispatch(getCatalogItem(value))
    }

    // catalog 😭
    if (catalogItemType === 'brand') {
      dispatch(getCatalogBrand(value))
    }

    // catalog 😭
    if (catalogItemType === 'group') {
      dispatch(getCatalogGroup(value))
    }

    dispatch({ type: t.SET_PROMO_ID_VALUE, payload: value })
  }
}

// TODO: Move this to ./operations.js
export function setPromoIdType (id) {
  return (dispatch, getState) => {
    // Reset values before setting id type.
    dispatch({ type: t.SET_PROMO_ID_VALUE, payload: null })
    dispatch({ type: t.SET_CATALOG_NAME, name: '' })

    dispatch({ type: t.SET_PROMO_ID_TYPE, payload: id })
  }
}

export function setPromoType (promoType) {
  return {
    type: t.SET_PROMO_TYPE,
    payload: promoType
  }
}

export function setPromoCreditType (promoType) {
  return {
    type: t.SET_PROMO_CREDIT_TYPE,
    promoType
  }
}

export function setPromoAmount (amount) {
  return {
    type: t.SET_PROMO_AMOUNT,
    amount
  }
}

export function setPromoCreditMax (amount) {
  return {
    type: t.SET_PROMO_CREDIT_MAX,
    amount
  }
}

export function setPromoGlobalUse (globalUse) {
  return {
    type: t.SET_PROMO_GLOBAL_USE,
    globalUse
  }
}

export function setPromoUserUse (userUse) {
  return {
    type: t.SET_PROMO_USER_USE,
    userUse
  }
}

export function setPromoStateId (stateId) {
  return dispatch => {
    dispatch({
      type: t.SET_PROMO_STATE_ID,
      payload: stateId
    })
  }
}

export function setPromoDepots (depotIds) {
  if (typeof depotIds === 'undefined') return
  return {
    type: t.SET_PROMO_DEPOTS,
    // Set null so we don't whitelist the key/property onto the API payload
    // 0 length = 'All depots', the default if we omit this property.
    payload: (depotIds && depotIds.length) ? depotIds : null
  }
}

export function setPromoDispensary (dispensaryId) {
  return {
    type: t.SET_PROMO_DISPENSARY,
    // Odd, yes, but we wanted to make it explicit at the form level when we want to set null values that cause the value to be removed.
    payload: dispensaryId === 'null' ? null : dispensaryId
  }
}

export function setPromoChannel (channel) {
  return {
    type: t.SET_PROMO_CHANNEL,
    channel
  }
}

export function resetManagePromoState () {
  return {
    type: t.RESET_MANAGE_PROMO_STATE
  }
}

export function setOrderHistory (payload) {
  return {
    type: t.SET_ORDER_HISTORY,
    payload
  }
}

// This is most likely to be used as a clearing function. I advise using the more granular set fn's so your action history is accurate
export function setPromoUserIds (payload) {
  return {
    type: t.SET_PROMO_USER_IDS,
    payload
  }
}

export function setMinOrderValue (value) {
  return {
    type: t.SET_MIN_ORDER_VALUE,
    value
  }
}

export function setCatalogName (name, nameType) {
  return {
    type: t.SET_CATALOG_NAME,
    payload: name,
    meta: { nameType: nameType || null }
  }
}

// TODO: Move this to ./operations.js
export function setPromoBuyOneIdType (buyOneType) {
  return dispatch => {
    // reset value on changing of type
    dispatch(setPromoBuyOneIdValue(null))
    dispatch(setCatalogName('', 'buy'))
    dispatch({ type: t.SET_PROMO_BUY_ONE_ID_TYPE, buyOneType })
  }
}

export function setPromoBuyOneIdValue (buyOneValue) {
  return {
    type: t.SET_PROMO_BUY_ONE_ID_VALUE,
    buyOneValue
  }
}

export function setPromoBuyOneAmount (buyOneAmount) {
  return {
    type: t.SET_PROMO_BUY_ONE_AMOUNT,
    buyOneAmount
  }
}

// TODO: Move this to ./operations.js
export function setPromoGetOneIdType (getOneType) {
  return dispatch => {
    // reset value on changing of type
    dispatch(setPromoGetOneIdValue(null))
    dispatch(setCatalogName('', 'get'))
    dispatch({ type: t.SET_PROMO_GET_ONE_ID_TYPE, getOneType })
  }
}

export function setPromoGetOneIdValue (getOneValue) {
  return {
    type: t.SET_PROMO_GET_ONE_ID_VALUE,
    getOneValue
  }
}

export function setPromoGetOneAmount (getOneAmount) {
  return {
    type: t.SET_PROMO_GET_ONE_AMOUNT,
    getOneAmount
  }
}

export function resetPromoBuyOneGetOne () {
  return {
    type: t.RESET_PROMO_BOGO
  }
}

// TODO: Move this to ./operations.js
// this should belong in the catalog store at some point
export function getCatalogItem (id, nameType) {
  return async dispatch => {
    dispatch(requestCatalogItem())
    const { err, data } = await api.getCatalogItem({ id })
    dispatch(receiveCatalogItem())
    if (err) return

    dispatch(setCatalogName(data.title, nameType))
  }
}

// TODO: Move this to ./operations.js
// this should belong in the catalog store at some point
export function getCatalogBrand (id, nameType) {
  return async dispatch => {
    dispatch(requestCatalogItem())
    const { err, data } = await api.getCatalogBrandById({ id })
    dispatch(receiveCatalogItem())
    if (err) return

    dispatch(setCatalogName(data.name, nameType))
  }
}

// TODO: Move this to ./operations.js
// this should belong in the catalog store at some point
export function getCatalogGroup (id, nameType) {
  return async dispatch => {
    dispatch(requestCatalogItem())
    const { err, data } = await api.getCatalogGroup({ id })
    dispatch(receiveCatalogItem())
    if (err) return

    dispatch(setCatalogName(data.name, nameType))
  }
}
