import React, { PureComponent } from 'react'
import { bool, number, shape, string } from 'prop-types'
import { numberToPrice } from 'helpers/price-helpers'
import { parseType, strainMap } from 'src/components/product-card'

import {
  brand as brandClass,
  container,
  name as nameClass,
  price as priceClass
} from './style.js'

export class ProductBlock extends PureComponent {
  static propTypes = {
    product: shape({
      name: string,
      brand: shape({
        name: string
      }),
      price: number,
      species: shape({
        name: string
      }),
      highCBD: bool,
      isAccessory: bool
    })
  }

  static defaultProps = {
    product: {
      name: '',
      brand: {},
      price: 0,
      species: {}
    }
  }

  render () {
    const {
      name,
      brand = {},
      price,
      species = {},
      highCBD,
      isAccessory
    } = this.props.product

    const parsedSpecies = parseType(species.name, highCBD, isAccessory)
    const nameColor = strainMap[parsedSpecies]
    const nameStyle = { color: nameColor }
    const productPrice = numberToPrice(price)

    return (
      <div className={container}>
        <div className={priceClass}>{productPrice}</div>
        <div
          className={nameClass}
          style={nameStyle}
        >
          {name}
        </div>
        {brand.name && <div className={brandClass}>by {brand.name}</div>}
      </div>
    )
  }
}

export default ProductBlock
