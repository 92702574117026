import React, { PureComponent } from 'react'
import { array, bool, func, number, object, oneOfType, string } from 'prop-types'
import isEmptyObject from 'is-empty-object'
import pole from 'pole'
import debounce from 'debounce'
import shortid from 'shortid'
import history from 'components/router/history'

import { ERROR } from 'src/redux/alerts/actions'
import ROUTES from 'src/components/router/routes'
import Screen from 'src/components/screen/container'
import UserInfoHeader from 'src/components/user-info-header'
import CaseInfoHeader from 'src/components/case-info-header'
import SidebarMenu from 'src/components/sidebar-menu'
import OrderSentDrawer from './order-sent-drawer'
import { formatCartProducts } from 'src/redux/catalog-builder/format'
import CatalogCart from 'src/components/catalog-cart'
import Footer from './footer'
import { goToCaseDashboard } from 'helpers/dashboard'

import {
  backButton,
  backIcon,
  backText,
  centerColumn,
  container,
  content,
  form,
  leftColumn,
  rightColumn
} from './style.js'

export default class CatalogBuilder extends PureComponent {
  static propTypes = {
    archiveCase: func,
    cartId: oneOfType([number, string]),
    cartQuote: object,
    cartQuoteTotal: string,
    caseColor: string,
    caseDepotId: number,
    caseDescription: string,
    caseName: string,
    catalogBucket: object,
    catalogBucketId: string,
    catalogCartEtaValue: string,
    catalogErrors: object,
    clearCartQuote: func,
    clearCatalogBuilderStore: func,
    clearCatalogCartEta: func,
    clearGroups: func,
    clearMenus: func,
    clearPlaceStore: func,
    clearProducts: func,
    conciergeSlug: string,
    createCatalogBucket: func,
    createConciergeCart: func,
    // currentDepot: object,
    depotId: number,
    enableSendCart: bool,
    etaPollInterval: number,
    fetchAllDispensaries: func,
    fetchCatalogCartEta: func,
    fetchDepotInventory: func,
    fetchMenu: func,
    fetchPlace: func,
    fetchUser: func,
    getCaseById: func,
    groups: object,
    hasCartQuoteTotal: bool,
    isCaseBuilder: bool,
    isEtaLoading: bool,
    isQuoteLoading: bool,
    menu: object,
    menuSlug: string,
    onPlaceFetched: func,
    outOfDepotItems: array,
    place: object,
    products: object,
    pushNotification: func,
    removeOutOfDepotItem: func,
    resetMenu: func,
    saveCase: func,
    setCatalogBucket: func,
    setCatalogBuilderMenuSlug: func,
    setProductsFilter: func,
    setShowOrderDrawer: func,
    showOrderDrawer: bool,
    updateBucket: func,
    updateCatalogBucket: func,
    updateConciergeCart: func,
    user: object,
    userId: string
  }

  static defaultProps = {
    currentDepot: {}
  }

  state = {
    etaPoll: null,
    name: '',
    description: '',
    color: '',
    legalCaseTotal: 0,
    numberOfItems: 0
  }

  componentDidMount () {
    const { fetchAllDispensaries } = this.props

    fetchAllDispensaries()
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillMount () {
  /* eslint-enable camelcase */
    const {
      catalogBucketId,
      createCatalogBucket,
      depotId,
      fetchDepotInventory,
      fetchUser,
      getCaseById,
      isCaseBuilder,
      userId
    } = this.props

    if (isCaseBuilder) {
      fetchDepotInventory(depotId)
      // if we already have a case, fetch it and dont create a new catalog cart
      if (!this.isNewCatalogCase()) return getCaseById(catalogBucketId)
    } else {
      // create empty catalog cart in order to have a catalog cart id
      // once the user of this form begins building an order
      fetchUser(userId)
      this.initEtaPolling()
    }

    createCatalogBucket({})
  }

  componentWillUnmount () {
    const {
      clearCatalogBuilderStore,
      clearPlaceStore,
      clearMenus,
      clearGroups,
      clearProducts
    } = this.props

    this.clearEtaPolling()
    clearCatalogBuilderStore()
    clearPlaceStore()
    clearMenus()
    clearGroups()
    clearProducts()
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps (nextProps) {
  /* eslint-enable camelcase */
    const {
      caseColor,
      caseDepotId,
      caseDescription,
      caseName,
      depotId,
      etaPollInterval,
      isCaseBuilder,
      pushNotification
    } = nextProps
    if (isCaseBuilder && caseDepotId && caseDepotId !== depotId) {
      pushNotification(`Case ${caseName} is not associated with depotId ${depotId}.`, ERROR)
      return history.push({ pathname: `${ROUTES.DASHBOARD}/${depotId}/cases` })
    }

    // cases must have a name, so if a nextProps.name comes in, the case template has been set
    const { name: oldName } = this.state

    if (caseName && !oldName) {
      this.calculateCaseTotal()
      this.setState({ color: caseColor, description: caseDescription, name: caseName })
    }

    if (etaPollInterval && etaPollInterval !== this.props.etaPollInterval) {
      this.updateEtaPolling(etaPollInterval)
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.isCaseBuilder && isEmptyObject(prevProps.products) && !isEmptyObject(this.props.products)) {
      this.calculateCaseTotal()
    }

    if (!prevProps.outOfDepotItems.length && this.props.outOfDepotItems.length) {
      this.calculateCaseTotal()
    }
  }

  initEtaPolling = () => {
    console.log('initEtaPolling', this.props.etaPollInterval)
    if (!this.state.etaPoll) {
      const etaPoll = pole({ interval: this.props.etaPollInterval }, (callback) => {
        const {
          catalogBucketId,
          fetchCatalogCartEta,
          place: { active }
        } = this.props

        // only fire an ETA request if the location is exact and has a depot
        if (catalogBucketId && active.exact && active.depots.length) {
          fetchCatalogCartEta(catalogBucketId, active.id)
        }
        callback()
      })
      this.setState({ etaPoll })
    }
  }

  updateEtaPolling = (interval) => {
    // spread out restart of polling to prevent simultaneous hits from all users
    const delay = Math.floor(Math.random() * interval)
    setTimeout(() => {
      this.clearEtaPolling()
      this.initEtaPolling()
    }, delay)
  }

  clearEtaPolling = () => {
    const { etaPoll } = this.state
    if (etaPoll) {
      etaPoll.cancel()
    }

    this.setState({ etaPoll: null })
  }

  updateStore = (catalogBucket) => {
    const { setCatalogBucket } = this.props
    setCatalogBucket(catalogBucket)
    this.saveBucket(catalogBucket)
  }

  saveBucket = debounce((catalogBucket) => {
    const {
      updateBucket,
      updateCatalogBucket,
      cartId,
      catalogBucketId,
      menuSlug,
      place: { active = {} },
      products: productsStore,
      fetchCatalogCartEta
    } = this.props

    if (catalogBucketId) {
      updateCatalogBucket(catalogBucket, catalogBucketId)
      this.calculateCaseTotal()
    }

    if (catalogBucketId && cartId) {
      const products = formatCartProducts(catalogBucket, productsStore)

      updateBucket(cartId, products, menuSlug)
      fetchCatalogCartEta(catalogBucketId, active.id)
    }
  }, 200)

  onProductsFilterChange = debounce((value) => {
    const { setProductsFilter } = this.props
    setProductsFilter(value)
  }, 200)

  removeFromCart = (catalogId) => {
    const { catalogBucket, clearCartQuote, clearCatalogCartEta } = this.props
    const tmpCatalogBucket = { ...catalogBucket }

    delete tmpCatalogBucket[catalogId]

    if (isEmptyObject(tmpCatalogBucket)) {
      clearCartQuote()
      clearCatalogCartEta()
    }

    this.updateStore(tmpCatalogBucket)
  }

  setQuantity = (catalogId, quantity) => {
    const { catalogBucket } = this.props

    const tmpCatalogBucket = { ...catalogBucket }
    tmpCatalogBucket[catalogId] = quantity
    this.updateStore(tmpCatalogBucket)
  }

  onMenuSlugChange = (value) => {
    const {
      place: { active = {} },
      setCatalogBuilderMenuSlug,
      resetMenu,
      fetchMenu
    } = this.props

    setCatalogBuilderMenuSlug(value)
    resetMenu()
    fetchMenu(active.id, value)
  }

  onAddProduct = (id, quantity = 1) => {
    this.setQuantity(id, quantity)
  }

  onAfterAdd = ({ product, quantity }) => {
    const { catalogItemId } = product
    this.setQuantity(catalogItemId, quantity)
  }

  onAfterRemove = ({ product, quantity }) => {
    const { catalogItemId } = product
    const { catalogBucket } = this.props
    const previousQuantity = catalogBucket[catalogItemId]

    /**
     * the quantity picker never reaches 0, but we know that if the
     * previousQuantity was 1 and the current quantity is 1 the user
     * clicked on delete
    */
    // TODO: I don't believe the above comment is true anymore, its possible quantity is undefined
    if ((quantity === 1 && previousQuantity === 1) || !quantity) {
      this.removeFromCart(catalogItemId)
    } else {
      this.setQuantity(catalogItemId, quantity)
    }
  }

  handleSendCart = () => {
    const {
      userId,
      place: { active = {} },
      catalogBucketId,
      menuSlug,
      createConciergeCart,
      updateConciergeCart,
      conciergeSlug,
      enableSendCart
    } = this.props

    if (enableSendCart) {
      const slug = shortid.generate()
      const payload = {
        userId,
        placeId: active.id,
        slug,
        id: catalogBucketId,
        menuSlug
      }

      if (conciergeSlug) {
        const updatePayload = {
          ...payload,
          slug: conciergeSlug
        }
        updateConciergeCart(updatePayload)
      } else {
        createConciergeCart(payload)
      }
    }
  }

  // CASE SPECIFIC FUNCTIONS
  isNewCatalogCase = () => {
    // we default catalogBucketId from the url to '1' for new cases, anytime after that its a UUID
    return this.props.catalogBucketId === '1'
  }

  archiveCase = () => {
    if (this.isNewCatalogCase()) return

    this.props.archiveCase(this.props.catalogBucketId, this.props.depotId)
  }

  saveCase = async () => {
    const { catalogBucket, catalogBucketId, depotId, updateBucket, saveCase } = this.props
    const items = Object.entries(catalogBucket).map(([catalogId, quantity]) => ({ id: catalogId, quantity }))

    const payload = {
      id: catalogBucketId,
      depot_id: depotId,
      items,
      ...this.state
    }
    // state has etaPoll, which we dont care about
    delete payload.etaPoll

    const actionFn = this.isNewCatalogCase() ? saveCase : updateBucket

    await actionFn(payload)
    goToCaseDashboard(depotId)
  }

  onColorChange = (color) => {
    this.setState({ color })
  }

  onNameChange = (name) => {
    this.setState({ name })
  }

  onDescriptionChange = (description) => {
    this.setState({ description })
  }

  calculateCaseTotal = () => {
    const { catalogBucket, products } = this.props
    if (!this.props.isCaseBuilder || isEmptyObject(products)) return

    let legalCaseTotal = 0
    let numberOfItems = 0
    Object.entries(catalogBucket).forEach(([catalogId, quantity]) => {
      const product = products[catalogId]
      if (!product || !product.price) return

      const { excise_tax_exempt: taxExempt, price } = product

      const itemTotal = price * quantity
      if (itemTotal === 999) return // any modal hack product should NOT count towards total or count

      numberOfItems += quantity

      if (!taxExempt) {
        legalCaseTotal += quantity * price
      }
    })
    this.addOutOfDepotItemsToCaseTotal(legalCaseTotal, numberOfItems)
  }

  addOutOfDepotItemsToCaseTotal = (legalCaseTotal, numberOfItems) => {
    const { catalogBucket, outOfDepotItems } = this.props

    outOfDepotItems.forEach((product) => {
      const { id, price } = product
      const quantity = catalogBucket[id]
      legalCaseTotal += quantity * price
      numberOfItems += quantity
    })

    this.setState({ legalCaseTotal, numberOfItems })
  }

  confirmGoBack = () => {
    const { depotId, isCaseBuilder } = this.props
    const confirmed = window.confirm('Are you sure you want to go back? You will lose any unsaved changes.')

    if (confirmed) {
      const route = isCaseBuilder ? `${ROUTES.DASHBOARD}/${depotId}/cases` : ROUTES.ORDER_BUILDER_SEARCH_USER
      history.push({ pathname: route })
    }
  }

  render () {
    const {
      cartQuote,
      cartQuoteTotal,
      catalogBucket,
      catalogCartEtaValue,
      catalogErrors,
      conciergeSlug,
      enableSendCart,
      fetchPlace,
      groups,
      hasCartQuoteTotal,
      isEtaLoading,
      isCaseBuilder,
      isQuoteLoading,
      menu,
      menuSlug,
      onPlaceFetched,
      outOfDepotItems,
      place,
      products,
      removeOutOfDepotItem,
      setShowOrderDrawer,
      showOrderDrawer,
      user
    } = this.props

    const { color, description, legalCaseTotal, name, numberOfItems } = this.state

    const { basic: { displayName, mobilePhoneDisplay } } = user

    return (
      <Screen>
        <div className={container}>
          <div className={content}>
            <div className={leftColumn}>
              <div className={backButton} onClick={this.confirmGoBack}>
                <span className={backIcon}>←</span>
                <span className={backText}>Go Back</span>
              </div>
            </div>
            <div className={centerColumn}>
              {
                isCaseBuilder
                  ? <CaseInfoHeader
                    archiveCase={this.archiveCase}
                    color={color}
                    description={description}
                    legalCaseTotal={legalCaseTotal}
                    name={name}
                    numberOfItems={numberOfItems}
                    onColorChange={this.onColorChange}
                    onDescriptionChange={this.onDescriptionChange}
                    onNameChange={this.onNameChange}
                    saveCase={this.saveCase}
                  />
                  : <UserInfoHeader
                    fetchPlace={fetchPlace}
                    onPlaceFetched={onPlaceFetched}
                    place={place}
                    user={user}
                  />
              }

              <div
                className={form}
                ref={(formEl) => { this.formEl = formEl }}
              >
                <CatalogCart
                  isCaseBuilder={isCaseBuilder}
                  products={products}
                  catalogBucket={catalogBucket}
                  onAfterRemove={this.onAfterRemove}
                  onAfterAdd={this.onAfterAdd}
                  outOfDepotItems={outOfDepotItems}
                  catalogErrors={catalogErrors}
                  removeOutOfDepotItem={removeOutOfDepotItem}
                />
              </div>

              {!isCaseBuilder &&
                <Footer
                  cartQuote={cartQuote}
                  cartQuoteTotalDisplay={hasCartQuoteTotal ? `$${cartQuoteTotal}` : '--'}
                  catalogCartEtaValue={catalogCartEtaValue}
                  enableSendCart={enableSendCart}
                  hasCartQuoteTotal={hasCartQuoteTotal}
                  isEtaLoading={isEtaLoading}
                  isQuoteLoading={isQuoteLoading}
                  handleSendCart={this.handleSendCart}
                />
              }

            </div>
          </div>
          <div className={rightColumn}>
            <SidebarMenu
              menu={menu}
              products={products}
              groups={groups}
              menuSlug={menuSlug}
              onProductsFilterChange={this.onProductsFilterChange}
              onMenuSlugChange={this.onMenuSlugChange}
              onAddProduct={this.onAddProduct}
              isCaseBuilder={isCaseBuilder}
            />
          </div>
        </div>
        <OrderSentDrawer
          setShowOrderDrawer={setShowOrderDrawer}
          showOrderDrawer={showOrderDrawer}
          conciergeSlug={conciergeSlug}
          displayName={displayName}
          mobilePhoneDisplay={mobilePhoneDisplay}
        />
      </Screen>
    )
  }
}
