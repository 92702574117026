import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { css } from 'emotion'
import Colors from 'microcomponents/colors'
import MCColors from 'src/helpers/css-variables'
import { bool, func, string } from 'prop-types'

export default class NavButton extends PureComponent {
  static propTypes = {
    icon: func,
    isActive: bool,
    label: string,
    LinkActiveStyle: string,
    LinkStyle: string,
    route: string
  }

  render () {
    const {
      icon: Icon,
      isActive,
      label,
      LinkActiveStyle = LinkActive,
      LinkStyle,
      route
    } = this.props

    return (
      <StyledLink
        activestyle={isActive ? LinkActiveStyle : ''}
        linkstyle={LinkStyle}
        to={route}
      >
        <IconWrapper>
          <Icon color={isActive ? MCColors.white : Colors.primary[1]} />
        </IconWrapper>
        <span>{label}</span>
      </StyledLink>
    )
  }
}

const borderRadius = '4px'

const StyledLink = styled(Link)`
  font-size: 1.2rem;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  min-width: 12rem;
  text-transform: uppercase;
  background-color: ${Colors.accessory[1]};
  color: ${Colors.primary[1]};

  ${({ linkstyle }) => linkstyle}
  ${({ activestyle }) => activestyle}
`

const LinkActive = css`
  transform: scale(1.1);
  border-radius: ${borderRadius};
  background-color: ${Colors.primary[1]};
  color: ${MCColors.white};
`

const IconWrapper = styled.div`
  margin-bottom: 1rem;
`
