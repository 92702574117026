/**
 * Password input
 * The validation is based on https://github.com/sindresorhus/email-regex
 */

import React from 'react'
import { func, string } from 'prop-types'

import InputWithLabel from '../input-with-label'
import validators from './validators'

class Password extends React.Component {
  constructor () {
    super()

    this.onChange = this.onChange.bind(this)
    this.validators = validators(this)
  }

  onChange (e) {
    this.context.onChange(this.props.name, e.target.value, this.inputContainer.input.isValid())
  }

  componentDidMount () {
    this.context.register(this.props.name)
  }

  render () {
    return (
      <InputWithLabel
        {...this.props}
        name='password'
        type='password'
        validators={this.validators}
        onChange={this.onChange}
        ref={(inputContainer) => { this.inputContainer = inputContainer }}
        eagerValidate
      />
    )
  }
}

Password.contextTypes = {
  onChange: func,
  register: func
}

Password.propTypes = {
  name: string
}

export default Password
