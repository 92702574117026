import React, { PureComponent } from 'react'
import { bool, func, number, object, oneOfType, string } from 'prop-types'
import styled from '@emotion/styled'
import LoadingSpinner from 'components/spinner'

import DriverInventoryTable from './driver-inventory-table/'

export default class DriverInventory extends PureComponent {
  static propTypes = {
    driverInventoryInfo: object,
    focusedDriverId: oneOfType([number, string]),
    isDeliveriesRoute: bool,
    isDeliveriesTab: bool,
    isLoading: bool,
    trackInventoryChanges: func,
    updateDriverInventory: func
  }

  static defaultProps = {
    driverInventoryInfo: { inventory: [], inventoryChecksum: {} }
  }

  render () {
    const {
      focusedDriverId,
      driverInventoryInfo,
      isDeliveriesRoute,
      isDeliveriesTab,
      isLoading,
      trackInventoryChanges,
      updateDriverInventory
    } = this.props

    const { inventory, inventoryChecksum } = driverInventoryInfo

    const isDriversTab = !isDeliveriesRoute && !isDeliveriesTab

    const trimInventory = isDriversTab ? inventory : inventory.filter(item => item.quantity > 0)

    // Don't show inventory in Deliveries page if all items have 0 qty
    const hasInventory = !!(trimInventory && trimInventory.length)

    const showLoading = isLoading && !hasInventory
    const showEmpty = !isLoading && !hasInventory
    const showOpacity = isLoading && !showLoading

    return (
      <Container>
        {showEmpty && 'No Inventory'}
        {showLoading && <LoadingSpinner />}
        {hasInventory &&
          <Content showOpacity={showOpacity}>
            <DriverInventoryTable
              focusedDriverId={focusedDriverId}
              inventory={trimInventory}
              inventoryChecksum={inventoryChecksum}
              isDriversTab={isDriversTab}
              trackInventoryChanges={trackInventoryChanges}
              updateDriverInventory={updateDriverInventory}
            />
          </Content>}
      </Container>
    )
  }
}

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Content = styled.div`
  width: 100%;
  opacity: ${({ showOpacity }) => showOpacity ? 0.5 : 1};
`
