import React, { PureComponent } from 'react'
import { array, func, number, string } from 'prop-types'
import List from './list-component'
import McCard from 'src/components/mc-card'
import Create from './create'
import formatQuery from 'src/helpers/query-parameter'
import { FORM_STRING_TYPES, SPECIES_CATALOG_TYPE } from 'src/helpers/constants'
import { grid } from '../style.js'
const { STRAIN } = FORM_STRING_TYPES

export default class StrainList extends PureComponent {
  static propTypes = {
    create: func,
    fetchCatalogFn: func,
    haveFetchedSpecies: number,
    pathname: string,
    strainsSorted: array
  }

  componentDidMount () {
    // the create/edit strain overlay needs the catalog species for dropdowns
    if (!this.props.haveFetchedSpecies) {
      this.props.fetchCatalogFn(SPECIES_CATALOG_TYPE)
    }
  }

  render () {
    const { create, pathname, strainsSorted } = this.props
    return (
      <List listClass={grid}>
        <Create
          action={create}
          title='add new strain'
        />
        {
          strainsSorted.slice(0, 100).map((strain) => {
            const {
              name = '',
              images = [{}],
              id
            } = strain
            const search = formatQuery(STRAIN, id)
            return (
              <McCard
                url={`${pathname}?${search}`}
                key={strain.id}
                strain={strain}
                description={name}
                image={images[0] && images[0].downloadUrl}
              />
            )
          })
        }
      </List>
    )
  }
}
