import sortOn from 'sort-on'

import { clearDriverMap } from 'src/redux/driver-map/actions'
import {
  clearDriverInventoryMap,
  updateDriverInventoryMap
} from 'src/redux/driver-inventory-map/actions'
import { fetchDriverInventory } from 'src/redux/driver-inventory-map/operations'
import {
  clearDriverOrdersMap,
  updateDriverOrdersMap
} from 'src/redux/driver-orders-map/actions'
import { fetchDriverPendingOrders } from 'src/redux/driver-orders-map/operations'
import {
  clearDeliveriesStore,
  setMapView,
  setFocusedDeliveriesDriverId,
  setDriverInventoryLoadingMap,
  setDriverOrdersLoadingMap,
  setDeliveryType,
  setMode
} from 'src/redux/deliveries/actions'
import { clearDispensariesStore } from 'src/redux/dispensaries/actions'
import {
  getDriverDetailsContent,
  getDriverInventoryLoadingMap,
  getDriverOrdersLoadingMap
} from 'src/redux/deliveries/selectors'
import { fetchDriverVehicles } from '../driver-vehicles/operations'

import { DRIVER_INVENTORY_KEY } from 'helpers/deliveries'

const modeMap = {
  delivering: 'Delivery',
  hold: 'Hold',
  idle: 'On',
  off: 'Off'
}

export function setModeFilter (mode) {
  return (dispatch, getState) => {
    const state = getState()
    const currFilter = state.drivers.mode

    if (currFilter === modeMap[mode]) {
      dispatch(setMode(''))
    } else {
      dispatch(setMode(modeMap[mode]))
    }
  }
}

export function clearDeliveries () {
  return dispatch => {
    dispatch(clearDriverMap())
    dispatch(clearDriverInventoryMap())
    dispatch(clearDriverOrdersMap())
    dispatch(clearDeliveriesStore())
    dispatch(clearDispensariesStore())
  }
}

export function setDeliveryTypeFilter (deliveryType) {
  return (dispatch, getState) => {
    const currentDeliveryType = getState().drivers.deliveryType

    const payload = currentDeliveryType === deliveryType ? '' : deliveryType
    dispatch(setDeliveryType(payload))
  }
}

export function toggleMapView () {
  return (dispatch, getState) => {
    const state = getState()
    const isMapView = state.deliveries.mapView

    dispatch(setMapView(!isMapView))
  }
}

export function setFocusedDeliveriesDriver (driverId) {
  return dispatch => {
    dispatch(setFocusedDeliveriesDriverId(driverId))

    // since these driver IDs are ints, we need to ensure we honor an ID of 0
    if (driverId || driverId === 0) {
      dispatch(fetchDriverVehicles(driverId))
      dispatch(hydrateFocusedDeliveriesDriver(driverId))
    }
  }
}

export function hydrateFocusedDeliveriesDriver (driverId) {
  return (dispatch, getState) => {
    // be more discerning about which endpoint we poll based on tab selection
    const state = getState()
    const currentDriverDetailsContent = getDriverDetailsContent(state)

    if (currentDriverDetailsContent === DRIVER_INVENTORY_KEY) {
      dispatch(fetchFocusedDriverInventory(driverId))
    } else {
      dispatch(fetchFocusedDriverOrders(driverId))
    }
  }
}

export function fetchFocusedDriverOrders (driverId) {
  return async (dispatch, getState) => {
    dispatch(updateDriverOrdersLoadingMap(driverId, true))
    const { err, data } = await dispatch(fetchDriverPendingOrders(driverId))
    dispatch(updateDriverOrdersLoadingMap(driverId, false))

    if (err) return

    dispatch(updateDriverOrdersMap({ [driverId]: data }))
  }
}

export function updateDriverOrdersLoadingMap (driverId, isLoading) {
  return (dispatch, getState) => {
    const state = getState()
    const isDriverOrdersLoadingMap = getDriverOrdersLoadingMap(state)
    const newDriverOrdersLoadingMap = { ...isDriverOrdersLoadingMap }
    newDriverOrdersLoadingMap[driverId] = isLoading

    dispatch(setDriverOrdersLoadingMap(newDriverOrdersLoadingMap))
  }
}

export function fetchFocusedDriverInventory (driverId) {
  return async (dispatch, getState) => {
    dispatch(updateDriverInventoryLoadingMap(driverId, true))
    const { err, data } = await dispatch(fetchDriverInventory(driverId))
    dispatch(updateDriverInventoryLoadingMap(driverId, false))

    if (err) return

    const { inventory, inventoryChecksum } = data
    const sortedInventory = sortOn(inventory, 'quantity').reverse()

    dispatch(
      updateDriverInventoryMap({
        [driverId]: {
          inventory: sortedInventory,
          inventoryChecksum
        }
      })
    )
  }
}

export function updateDriverInventoryLoadingMap (driverId, isLoading) {
  return (dispatch, getState) => {
    const state = getState()
    const driverInventoryLoadingMap = getDriverInventoryLoadingMap(state)
    const newDriverInventoryLoadingMap = { ...driverInventoryLoadingMap }
    newDriverInventoryLoadingMap[driverId] = isLoading

    dispatch(setDriverInventoryLoadingMap(newDriverInventoryLoadingMap))
  }
}
