import t from './actionTypes'
import random from 'random-number'

const numberGen = () => {
  return random({min: 1, max: 10 * 100 * 100 * 1000, integer: true})
}

export const ERROR = 'ERROR'
export const SUCCESS = 'SUCCESS'
export const WARNING = 'WARNING'

// message is what shows up in the alert, type is what color the notification is
export function pushNotification (message = 'Looks like something went wrong...', type = ERROR) {
  return (dispatch, getState) => {
    const id = numberGen()
    const notification = { message, id, type }

    dispatch({ type: t.PUSH_NOTIFICATION, notification })

    setTimeout(() => {
      const { alerts } = getState()
      const idx = alerts.notifications.findIndex(n => n.id === id)

      dispatch({ type: t.POP_NOTIFICATION, idx })
    }, 5000)
  }
}
