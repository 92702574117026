import React, { PureComponent } from 'react'
import { func, bool } from 'prop-types'
import styled from '@emotion/styled'
import { css } from 'emotion'

import Switch from 'microcomponents/switch'

import MCColors from 'src/helpers/css-variables'
const { lightGray, primaryDark, mcGreen } = MCColors

export default class ShowAllItemsSwitch extends PureComponent {
  static propTypes = {
    enabled: bool,
    toggleShowAll: func
  }

  static defaultProps = {
    toggleShowAll: () => {}
  }

  render () {
    const { enabled } = this.props
    return (
      <Container>
        <LeftLabel className={enabled && LabelActive}>Show 0 Quantity Items</LeftLabel>
        <Switch
          enabled={enabled}
          className={SwitchClass}
          onChange={this.props.toggleShowAll}
          checkedChildren=''
          unCheckedChildren=''
        />
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 30%;
`

const LabelActive = css`
  opacity: 1;
`

const LeftLabel = styled.span`
  margin-right: 0.6rem;
  opacity: 0.3;
  font-size: 1.4rem;
  letter-spacing: 0.003rem;
`

const SwitchClass = css`
  background-color: ${primaryDark};

  &.rc-switch {
    border: 1px solid ${lightGray};

    :after {
      background-color: ${lightGray};
    }
  }

  &.rc-switch-checked {
    border: 1px solid ${mcGreen};

    :after {
      background-color: ${mcGreen};
    }
  }
`
