import { formatISO, parseISO, startOfDay, endOfDay } from 'date-fns'
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { PROMOS_IANA_TIME_ZONE } from 'src/helpers/promos/definitions'

/**
 * @param {string} dateString
 * @returns {string} formated date and time like 4/20/2020 4:20 PM
 */
export function readFullDate (date) {
  if (!date) return ''

  return format(new Date(date), 'M/dd/yyyy h:mm a')
}

/**
 * Given a timestamp return formated MMM DD, YYYY
 * @param {string} dateString
 * @returns {string} formated date like Apr 20, 2020
 */
export function readDate (date) {
  if (!date) return ''

  return format(new Date(date), 'MMM dd, yyyy')
}

// NB(chrisdickinson): Dates from OG are received without a timezone offset (or trailing `Z`)
// Appending a `Z` puts them at UTC ISO format before the date is parsed by `new Date()`.
export function transformToUTCISOString (dateString) {
  if (dateString[dateString.length - 1] === 'Z') return dateString
  return `${dateString}Z`
}

export function readTime (date) {
  if (!date) return ''

  const d = transformToUTCISOString(date)

  return format(new Date(d), 'h:mm a')
}

/**
 * USED ONLY IN DRIVER ORDERS. Given a date string, determine the start or end of the day for that date in a particular time zone.
 *
 * Why? The start of the day and end of day changes based on where you are on Earth, as well as what time of year it is (because of daylight savings.)
 * @param {string} dateString ISO Date e.g. 'YYYY-MM-DD'
 * @param {string} depotTimeZone depot's IANA time zone e.g. 'America/Los_Angeles'
 * @param {boolean} isStartOfDay
 * @returns {string} UTC ISO format time stamp e.g. '2020-04-20T04:20:00Z'
 */
export function determineTimeStampForDay (dateString, depotTimeZone = 'America/Los_Angeles', isStartOfDay) {
  const currentTime = timeOnly()
  const date = zonedTimeToUtc(`${dateString} ${currentTime}`, depotTimeZone)

  const timeStamp = isStartOfDay ? startOfDay(date) : endOfDay(date)
  return timeStamp.toISOString()
}

export function dateOnly (string) {
  const d = string || new Date().toISOString()
  return formatISO(parseISO(d), { representation: 'date' })
}

export function timeOnly (string) {
  const d = string || new Date().toISOString()
  return format(parseISO(d), 'HH:mm')
}

/**
 * Given a time formatted in 24hr, return 12hr format
 * @param {string} time
 * @returns {string} formated time like 3:00 PM
 */
export function convertTo12HourFormat (time) {
  let [hours, minutes] = time.split(':')
  hours = parseInt(hours, 10)
  const ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12 || 12
  return `${hours}:${minutes} ${ampm}`
}

// PROMOS AND TAGS SPECIFIC DATE HELPERS

/**
 * @return {string} 04/20/2020, 4:20 PM PDT
 */
export function formatDateTimeWithZone (string, timeZone) {
  const str = string || (new Date().toISOString())
  const d = utcToZonedTime(str, timeZone)
  return format(d, 'Pp zzz', { timeZone })
}

export function formatAsPacificTime (string, type) {
  let result = string
  if (type === 'date') {
    result = dateOnly(utcToZonedTime(string, PROMOS_IANA_TIME_ZONE).toISOString())
  } else if (type === 'time') {
    result = timeOnly(utcToZonedTime(string, PROMOS_IANA_TIME_ZONE).toISOString())
  }
  return result
}

/**
 * Concatenate values from date and time pickers to return ISO DateString.
 * @param {string} date ISO Date: 04-20-2020
 * @param {string} time 23 HR: 16:20
 */
export function formatDate (date, time) {
  return zonedTimeToUtc(`${date} ${time}`, PROMOS_IANA_TIME_ZONE).toISOString()
}
