import React from 'react'

// FIXME: Firefox doesn't like rems so using em for short term. Need to think of a longer term solution/standardization to support cross-browser compatibility https://bugzilla.mozilla.org/show_bug.cgi?format=default&id=1231147
const IconLaunchLink = ({ color = '#17ACEB', size = '1.5em', title = 'Open Product In New Tab' }) => (
  <svg
    aria-labelledby='icon-launch-link'
    height={size}
    viewBox='0 0 18 18'
    width={size}
  >
    <title id='icon-launch-link'>{title}</title>
    <path
      d='M14.667 14.667H1.833V1.833H8.25V0H1.833A1.833 1.833 0 000 1.833v12.834C0 15.675.816 16.5 1.833 16.5h12.834a1.839 1.839 0 001.833-1.833V8.25h-1.833v6.417zM10.083 0v1.833h3.291l-9.01 9.011 1.292 1.293 9.01-9.011v3.29H16.5V0h-6.417z'
      transform='translate(-2 -2) translate(2.75 2.75)'
      fill={color}
      stroke='none'
      strokeWidth={1}
      fillRule='evenodd'
    />
  </svg>
)

export default IconLaunchLink
