import Segment from '@eaze/load-segment'
import { isLocal } from 'helpers/constants'
import { SEGMENT_KEY } from 'helpers/environment'

let analytics = {}

if (!isLocal) {
  analytics = Segment({ key: SEGMENT_KEY })
}

export function track (eventName, properties = {}, integrations = {}, callback) {
  analytics.track(eventName, properties, integrations, callback)
}

export function identify (userId, email) {
  analytics.identify(userId, { email })
}

export function page () {
  analytics.page()
}
