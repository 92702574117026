import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { number, oneOfType, string } from 'prop-types'

const QuantityDisplay = styled.div`
  text-align: center;
  font-weight: 200;
  font-size: ${({ quantityFontSize }) => quantityFontSize || '1rem'};
`

export default class QuantityDisplayComponent extends PureComponent {
  static propTypes = {
    display: oneOfType([number, string]),
    quantityFontSize: number
  }

  render () {
    const { display, quantityFontSize } = this.props

    return (
      <QuantityDisplay quantityFontSize={quantityFontSize}>{display}</QuantityDisplay>
    )
  }
}
