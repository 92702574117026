import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import { cache } from 'emotion'
import { CacheProvider, Global } from '@emotion/core'
import { globalStyles } from './index.styles'
// import error-handler to trigger its initialization
import 'helpers/error-handler'

ReactDOM.render(
  <CacheProvider value={cache}>
    <Global styles={globalStyles} />
    <App />
  </CacheProvider>,
  document.getElementById('app')
)
