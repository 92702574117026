import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import warning from 'warning'
import styled from '@emotion/styled'

export default class TableHead extends PureComponent {
  static propTypes = {
    config: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.array,
    onClick: PropTypes.func,
    sortAscending: PropTypes.bool,
    sortBy: PropTypes.string
  }

  static defaultProps = {
    config: [],
    data: [],
    onClick: () => warning(false, 'onClick not configured for <TableHead />')
  }

  constructor (props) {
    super(props)

    this.sortIndicator = this.sortIndicator.bind(this)
  }

  sortIndicator (sortKey) {
    let indicator = ''
    if (this.props.sortBy === sortKey) {
      this.props.sortAscending ? (indicator = '▼') : (indicator = '▲')
    }
    return <span style={{ color: 'white' }}> {indicator}</span>
  }

  render () {
    const { data, config, sortBy, onClick } = this.props

    if ((!config || !config.length) && (!data || !data.length)) return null

    let headCells = []
    if (sortBy) {
      headCells = config.map((c, i) => (
        <StyledTh
          key={i}
          style={c.style}
          onClick={() => onClick(c.key, c.disableSort)}
        >
          <span>
            {c.title}
            {this.sortIndicator(c.key)}
          </span>
        </StyledTh>
      ))
    } else {
      headCells = config.map((c, i) => (
        <StyledTh
          key={i}
          onClick={() => onClick(c.key, c.disableSort)}
          style={c.style}
        >
          {c.title}
        </StyledTh>
      ))
    }

    // TODO: This can/should support Children in case someone want to easily opt out of config based table building.
    // Might want to support header grouping as well...
    return (
      <thead>
        <tr>{headCells}</tr>
      </thead>
    )
  }
}

const StyledTh = styled.th`
  top: 0;
  // Keep headers in view
  position: sticky;
  font-weight: 600;
  // RightBar Overlay is 10
  z-index: 9;
`
