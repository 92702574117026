import React from 'react'
import { Flex, Column } from 'src/microcomponents/flexbox-helpers'
import InputLabel from 'src/microcomponents/input-label'
import { arrayOf, func, string } from 'prop-types'
import { AVAILABLE_STATES } from 'helpers/constants'
import { selectContainer } from '../style.js'

const StateFormSection = ({
  selectedState,
  setPromoStateId,
  availableStates = { AVAILABLE_STATES }
}) => (
  <Flex top={1} bottom={1}>
    <Column full>
      <InputLabel content='State*' />
      <div className={selectContainer}>
        <select onChange={setPromoStateId} value={selectedState || 0}>
          <option value={0} disabled />
          {['ALL', ...availableStates].map((opt) => <option value={opt} key={`state-${opt}`}>{opt}</option>
          )}
        </select>
      </div>
    </Column>
  </Flex>
)

StateFormSection.propTypes = {
  availableStates: arrayOf(string),
  selectedState: string,
  setPromoStateId: func
}

export default StateFormSection
