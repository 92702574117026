import t from './actionTypes'

const defaultState = {
  list: [],
  archivedCases: []
}

const cases = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_CASES:
      return {
        ...state,
        list: action.cases
      }

    case t.CASE_CLONED:
      let newList = [...state.list]
      newList.push(action.payload)
      return {
        ...state,
        list: newList
      }
    case t.DELETE_CASE:
      newList = [...state.list]
      let newArchivedList = [...state.archivedCases]
      let idx = newList.findIndex(caseTemplate => caseTemplate.id === action.payload)
      newArchivedList.push(newList[idx])
      newList.splice(idx, 1)

      return {
        ...state,
        list: newList,
        archivedCases: newArchivedList
      }
    case t.CASES_ADDED_FROM_CSV:
      newList = [...state.list]
      newList.push(...action.payload)
      return {
        ...state,
        list: newList
      }
    case t.SET_ARCHIVED_CASES:
      return {
        ...state,
        archivedCases: action.archivedCases
      }
    default:
      return state
  }
}

export default cases
