import React, { PureComponent } from 'react'
import { SectionTitle } from 'components/typography'
import debounce from 'debounce'
import styled from '@emotion/styled'
import { keyframes } from 'emotion'
import MCColors from 'helpers/css-variables'
import { func, object } from 'prop-types'
const { accessory5, primaryDark } = MCColors

export default class DepotSidebar extends PureComponent {
  state = {
    filterString: '',
    selectedDepots: {},
    areAllDepotsSelected: false
  }

  static propTypes = {
    depots: object,
    setDepotFilter: func,
    setDepotFilterString: func,
    setFocusedDriver: func
  }

  handleSelectAllDepots = (event) => {
    event.stopPropagation()
    const { target: { checked } } = event
    const { depots } = this.props
    const depotsList = Object.keys(depots)

    const selectedDepots = {}
    if (checked) {
      depotsList.forEach(function (depotName) {
        selectedDepots[depotName] = true
      })
    }

    const newState = {
      areAllDepotsSelected: checked,
      selectedDepots
    }

    this.setState(newState, this.afterSelectDepots)
  }

  handleSelectDepot = ({ target: { value, checked } }) => {
    const { depots } = this.props
    const { selectedDepots } = this.state
    const newSelectedDepots = { ...selectedDepots }

    if (checked) {
      newSelectedDepots[value] = true
    } else {
      delete newSelectedDepots[value]
    }

    // if user has selected all depots by hand, flip the select all depots boolean
    const allDepotsSelected = Object.keys(depots).length === Object.keys(newSelectedDepots).length
    const newState = {
      selectedDepots: newSelectedDepots,
      areAllDepotsSelected: allDepotsSelected
    }

    this.setState(newState, this.afterSelectDepots)
  }

  afterSelectDepots = () => {
    const { setFocusedDriver, setDepotFilter } = this.props
    const { selectedDepots } = this.state
    const depotsList = Object.keys(selectedDepots)
    const depotsFilterList = depotsList.map((depotName) => ({ key: 'depotName', value: depotName }))

    setFocusedDriver(undefined)
    setDepotFilter(depotsFilterList)
  }

  applyFilter = (key) => {
    const { setFocusedDriver, setDepotFilter } = this.props

    setFocusedDriver(undefined)
    setDepotFilter({ key: 'depotName', value: key })
  }

  handleFilter = ({ target: { value } }) => {
    this.handleFilterChange(value)
    this.setState({ filterString: value })
  }

  handleFilterChange = debounce((value) => {
    const { setDepotFilterString } = this.props

    setDepotFilterString(value)
  }, 200)

  render () {
    const { selectedDepots, areAllDepotsSelected } = this.state
    const { depots } = this.props

    return (
      <SidebarDepotsList>
        <SidebarDepotTitle>
          <SectionTitle color={accessory5}>Depots</SectionTitle>
        </SidebarDepotTitle>

        <input
          type='text'
          placeholder='Search Depots...'
          onChange={this.handleFilter}
        />

        <SidebarContainer>
          <DepotSelectCheckboxLabel>
            <DepotSelectCheckbox
              checked={areAllDepotsSelected}
              onChange={this.handleSelectAllDepots}
              type='checkbox'
            />
            <Title>Select All</Title>
          </DepotSelectCheckboxLabel>
          {
            Object.entries(depots).map(([key, value]) => {
              return (
                <DepotSelectCheckboxLabel key={key}>
                  <DepotSelectCheckbox
                    // being explicit with false boolean so react
                    // doesn't assume this is an uncontrolled component
                    checked={selectedDepots[key] || false}
                    id={key}
                    onChange={this.handleSelectDepot}
                    type='checkbox'
                    value={key}
                  />
                  <Title>{key}</Title>
                </DepotSelectCheckboxLabel>
              )
            })
          }
        </SidebarContainer>
      </SidebarDepotsList>
    )
  }
}

const SidebarDepotTitle = styled.div`
  padding: 1rem 1rem 1rem 0;
`

const revealSidebarDesktop = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-40rem, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

const revealSidebarMobile = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 40rem, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

const SidebarDepotsList = styled.div`
  background-color: ${primaryDark};
  box-shadow: 1rem 0px 2rem rgba(0, 0, 0, 0.2);
  display: inline-flex;
  flex-direction: column;
  min-width: 30rem;
  opacity: 0;
  padding: 0 2.4rem;
  will-change: transform;
  z-index: 10;

  @media(min-width: 768px) {
    animation: ${revealSidebarDesktop} 0.4s ease-in both;
    box-shadow: 0rem -1rem 2rem rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  @media(max-width: 767px) {
    animation: ${revealSidebarMobile} 0.4s ease-in both;
    min-width: initial;
  }
`

const SidebarContainer = styled.div`
  height: 100%;

  @media(max-width: 767px) {
    height: auto;
    overflow: visible;
  }
`

const DepotSelectCheckboxLabel = styled.label`
  align-items: center;
  color: ${accessory5};
  display: flex;
  font-size: 1.4rem;
  padding: 1rem 0;
  width: 100$;
`

const DepotSelectCheckbox = styled.input`
  margin-right: 1rem;
  width: 2rem;
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
