import React from 'react'

// FIXME: Firefox doesn't like rems so using em for short term. Need to think of a longer term solution/standardization to support cross-browser compatibility https://bugzilla.mozilla.org/show_bug.cgi?format=default&id=1231147
const IconCopyText = ({ color = 'white', size = '1.5em', title = 'Copy Text' }) => (
  <svg
    aria-labelledby='copy-text-icon'
    version='1.1'
    viewBox='0 0 19 22'
    width={size}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <title id='copy-text-icon'>{title}</title>
    <g
      transform='translate(-2 -1)'
      stroke='none'
      strokeWidth={1}
      fill='none'
      fillRule='evenodd'
    >
      <path d='M0 0L24 0 24 24 0 24z' />
      <path
        d='M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z'
        fill={color}
      />
    </g>
  </svg>
)

export default IconCopyText
