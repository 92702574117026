import React from 'react'
import { string, func } from 'prop-types'
import { Column } from 'src/microcomponents/flexbox-helpers'
import InputLabel from 'src/microcomponents/input-label'
import { ORDER_HISTORY } from 'helpers/promos/user-segmentation'

import { selectContainer } from '../../style.js'

// The idea here is we're targeting user segments based on how many orders they've placed. Either none (0) or some (1)
export default class UserTargetOptions extends React.PureComponent {
  static propTypes = {
    orderHistoryLimit: string,
    setOrderHistoryLimit: func
  }

  static defaultProps = {
    orderHistoryLimit: ORDER_HISTORY.allUsers
  }

  render () {
    const { setOrderHistoryLimit, orderHistoryLimit } = this.props
    return (
      <Column top={1} bottom={1}>
        <InputLabel content='User order history' />
        <div className={selectContainer}>
          <select onChange={setOrderHistoryLimit} value={orderHistoryLimit || 'null'}>
            {/* Empty strings are the only falsey value acceptable in HTML elements */}
            <option disabled value='null'>Select one</option>
            <option value={ORDER_HISTORY.newUsers}>Users who haven’t ordered</option>
            <option value={ORDER_HISTORY.existingUsers}>Users with 1+ orders</option>
          </select>
        </div>
      </Column>
    )
  }
}
