import React from 'react'
import { bool, number } from 'prop-types'
import LoadingSpinner from 'components/spinner'

const LoadingSpinnerContainer = ({ show, size = 2 }) => {
  if (!show) {
    return null
  } else {
    return <LoadingSpinner size={size} />
  }
}

LoadingSpinnerContainer.propTypes = {
  show: bool,
  size: number
}

export default LoadingSpinnerContainer
