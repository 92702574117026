import t from './actionTypes'

const defaultState = {
  loading: false
}

const depots = (state = defaultState, action) => {
  switch (action.type) {
    // clear state for a new depot request
    case t.REQUEST_DEPOT_INVENTORY:
      return {
        loading: true
      }

    case t.RECEIVE_DEPOT_INVENTORY:
      return {
        loading: false
      }

    default:
      return state
  }
}

export default depots
