// method to transform a catalog item to a product that will properly render a <ProductCard />
export default ({catalogItem, brandsMap, speciesMap, tag}) => {
  const {
    /* eslint-disable camelcase */
    brand_id,
    high_cbd,
    images,
    species_id
    /* eslint-enable camelcase */
  } = catalogItem

  const photoUrl = images && images.length
    ? images[0].downloadUrl
    : ''

  return {
    ...catalogItem,
    name: catalogItem.title,
    photoUrl,
    highCBD: high_cbd,
    // querying product directly here because the linter is mad about the snake_case
    price: catalogItem.suggested_retail_price || [],
    brand: brandsMap[brand_id] || {},
    species: speciesMap[species_id] || {},
    type: {},
    subtype: {},
    tag
  }
}
