import React from 'react'
import PropTypes from 'prop-types'
import Colors from 'microcomponents/colors'
import { Draggable } from 'react-beautiful-dnd'
import { Flex, Row, Column } from 'src/microcomponents/flexbox-helpers'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { midGray } from 'src/helpers/css-variables'

import LaunchIcon from 'microcomponents/icons/icon-launch-link'
import { bool, number, string } from 'prop-types'

const SortableListItem = (props) => {
  const {
    className,
    count,
    id,
    index,
    shouldLinkOutItems,
    species,
    subvalue,
    slug,
    stateCode,
    value,
    narrow = true
  } = props

  const primarySpecies = species && species.split(' ')[0].toLowerCase()
  const speciesColor = Colors[primarySpecies] && Colors[primarySpecies][1]

  if (narrow) {
    return (
      <Draggable draggableId={id} index={index}>
        {(provided, snapshot) => (
          <Li
            className={className}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Row justify='space-between' full>
              <Flex>
                <Value padding='0 20px 0 0' width='350px'>
                  {value}
                  {shouldLinkOutItems &&
                    <StyledLink target='_blank' to={`/catalog/products?catalogFormId=${id}&catalogFormType=product`}>
                      <LaunchIcon size='1rem' />
                    </StyledLink>}
                </Value>
                <Subvalue>{(!!subvalue && subvalue) || (!!slug && `/${slug}`)}</Subvalue>
              </Flex>
              <Flex>
                <Subvalue textAlign='right' color={speciesColor} padding='0 20px 0 0'>{species}</Subvalue>
                <Subvalue width='60px' textAlign='right' padding='0 20px 0 0'>{count}</Subvalue>
              </Flex>
              {Boolean(stateCode) && <StateCorner>{stateCode}</StateCorner>}
            </Row>
          </Li>
        )}
      </Draggable>
    )
  } else {
    return (
      <Draggable draggableId={id} index={index}>
        {(provided, snapshot) => (
          <Li
            className={className}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Row justify='space-between' right={1} full>
              <Column>
                <Value>
                  {value}
                  {shouldLinkOutItems &&
                    <StyledLink target='_blank' to={`/catalog/products?catalogFormId=${id}&catalogFormType=product`}>
                      <LaunchIcon size='1rem' />
                    </StyledLink>}
                </Value>
                <Subvalue>{(!!subvalue && subvalue) || (!!slug && `/${slug}`)}</Subvalue>
              </Column>
              <Row align='center'>
                <Column align='flex-end'>
                  <Flex>{count}</Flex>
                  <Subvalue textAlign='right' color={speciesColor}>{species}</Subvalue>
                </Column>
                {Boolean(stateCode) && <StateCorner>{stateCode}</StateCorner>}
              </Row>
            </Row>
          </Li>
        )}
      </Draggable>
    )
  }
}

SortableListItem.propTypes = {
  className: string,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: string,
  index: number,
  shouldLinkOutItems: bool,
  species: string,
  subvalue: string,
  slug: string,
  stateCode: string,
  value: string,
  narrow: bool
}

const Li = styled.li`
  position: relative;
  border: ${({ isDragging }) => isDragging ? '2px dashed pink' : ''}
`

const Subvalue = styled.span`
  color: ${({ color }) => color || ''};
  text-align: ${({ textAlign }) => textAlign || ''};
  padding: ${({ padding }) => padding || ''};
  width: ${({ width }) => width || ''};
  opacity: 0.7;
`

const Value = styled.span`
  padding: ${({ padding }) => padding || ''};
  width: ${({ width }) => width || ''};
  font-weight: 700;
`

const StyledLink = styled(Link)`
  margin-left: 1rem;
`

const StateCorner = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.7rem;
  font-size: 1rem;
  color: ${midGray};
`

export default SortableListItem
