const canceledOrderIds = [5, 6, 7, 8, 9, 10, 12, 13]

export const ORDER_STATUS_TEXTS = {
  CANCELED: 'Canceled',
  DELIVERED: 'Delivered'
}

export function orderIsCanceled (order) {
  return canceledOrderIds.includes(order.status)
}

export function orderIsDelivered (order) {
  return order.status === 4
}

export function orderIsPending (order) {
  return order.status < 4
}

export function getOrderStatusText (order) {
  if (orderIsCanceled(order)) return ORDER_STATUS_TEXTS.CANCELED
  if (orderIsDelivered(order)) return ORDER_STATUS_TEXTS.DELIVERED

  // the consumer of this function will calculate a time diff for the status text
  return ''
}

// GET /enums/orderStatusId
// export const orderStatusMap = {
//   1: {
//     name: 'Requested',
//     description: 'This is used when order entered the system but hasn\'nt been accepted by a driver'
//   },
//   2: {
//     name: 'Accepted',
//     description: 'Driver accepted the order and going to deliver'
//   },
//   3: {
//     name: 'InRoute',
//     description: 'Driver is in route to deliver the order'
//   },
//   4: {
//     name: 'Delivered',
//     description: 'Driver delivered and confirmed the delivery'
//   },
//   5: {
//     name: 'Canceled by patient',
//     description: 'Patient canceled the order'
//   },
//   6: {
//     name: 'Removed',
//     description: 'Order was canceled before driver accepted it'
//   },
//   7: {
//     name: 'Timeout',
//     description: 'No driver found within search interval'
//   },
//   8: {
//     name: 'Canceled by driver',
//     description: 'Driver canceled the order'
//   },
//   9: {
//     name: 'Declined',
//     description: 'Declined'
//   },
//   10: {
//     name: 'No response',
//     description: 'No response'
//   },
//   11: {
//     name: 'Reroute',
//     description: 'Reroute'
//   },
//   12: {
//     name: 'Reroute timeout',
//     description: 'Reroute timeout'
//   },
//   13: {
//     name: 'Refund',
//     description: 'Refund order'
//   }
// }
