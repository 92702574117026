import React, { PureComponent } from 'react'
import { bool, func, number, string } from 'prop-types'
import { Text, Label } from 'components/typography'
import Tooltip from 'components/tooltip'
import Button, { HYBRID } from 'components/button'
import { Flex, Column, Row } from 'src/microcomponents/flexbox-helpers'
import { OrderPriceSummary } from 'components/order-summary'
import LoadingSpinner from 'microcomponents/loading-spinner/'

import infoWhite from 'src/assets/info-white.svg'

import {
  formFooter,
  loading,
  tooltipImage,
  tooltipWrapper
} from './style.js'

export default class OrderFooter extends PureComponent {
  static propTypes = {
    cartQuote: number,
    cartQuoteTotalDisplay: number,
    catalogCartEtaValue: string,
    enableSendCart: bool,
    hasCartQuoteTotal: bool,
    handleSendCart: func,
    isEtaLoading: bool,
    isQuoteLoading: bool
  }

  render () {
    const {
      cartQuote,
      cartQuoteTotalDisplay,
      catalogCartEtaValue,
      enableSendCart,
      hasCartQuoteTotal,
      handleSendCart,
      isEtaLoading,
      isQuoteLoading
    } = this.props

    return (
      <div className={formFooter}>
        <Row justify='space-between' full>
          <Row>
            <Column right={3}>
              <Row>
                {isQuoteLoading &&
                  <div className={loading}>
                    <LoadingSpinner show />
                  </div>
                }
                {!isQuoteLoading && <Text componentStyle={{ fontSize: '1.6rem' }} color='white'>{cartQuoteTotalDisplay}</Text>}
                {hasCartQuoteTotal && !isQuoteLoading &&
                  <div className={tooltipWrapper}>
                    <Tooltip
                      position='top'
                      contents={<OrderPriceSummary {...cartQuote} />}
                    >
                      <img
                        className={tooltipImage}
                        src={infoWhite}
                      />
                    </Tooltip>
                  </div>
                }
              </Row>
              <Label>Cart Total</Label>
            </Column>

            <Column>
              {isEtaLoading &&
                <div className={loading}>
                  <LoadingSpinner show />
                </div>
              }
              {!isEtaLoading && <Text componentStyle={{ fontSize: '1.6rem' }} color='white'>{catalogCartEtaValue}</Text>}
              <Label>ETA</Label>
            </Column>
          </Row>

          <Row>
            <Flex>
              <Button
                type={HYBRID}
                disabled={!enableSendCart}
                onClick={handleSendCart}
              >
                Send Cart
              </Button>
            </Flex>
          </Row>
        </Row>
      </div>
    )
  }
}
