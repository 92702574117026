import React, { PureComponent } from 'react'
import { func, string } from 'prop-types'
import styled from '@emotion/styled'
import window from 'global/window'
import { Flex, Column } from 'src/microcomponents/flexbox-helpers'

import { dataPropTypeShape } from './types'
import { Title } from 'components/typography'
import McColors from 'src/helpers/css-variables'

import EditTerritoryForm from './edit-territory-form'

const HEADER = {
  default: 'Add Territory',
  edit: 'Edit Territory'
}

class EditTerritoryManagement extends PureComponent {
  static propTypes = {
    territoryId: string,
    territoryData: dataPropTypeShape,
    createTerritory: func,
    updateTerritory: func,
    deleteTerritory: func
  }

  handleFormSubmit = (values) => {
    const { createTerritory, updateTerritory } = this.props
    if (values.id) {
      updateTerritory(values)
    } else {
      createTerritory(values)
    }
  }

  handleFormDelete = (values) => {
    const { city, id, zipcode } = values
    const territoryLabel = city || zipcode
    const confirmMessage = `Are you sure you want to delete territory: ${territoryLabel}?`

    if (window.confirm(confirmMessage)) {
      this.props.deleteTerritory(id, territoryLabel)
    }
  }

  render () {
    const {
      territoryData,
      territoryId
    } = this.props
    const isEdit = Boolean(territoryId)

    return (
      <Container>
        <Column>
          <Flex full componentStyle={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Title color={McColors.white}>{ isEdit ? HEADER.edit : HEADER.default }</Title>
          </Flex>
          <EditTerritoryForm
            handleFormDelete={this.handleFormDelete}
            handleFormSubmit={this.handleFormSubmit}
            isEdit={isEdit}
            territoryData={territoryData}
          />
        </Column>
      </Container>
    )
  }
}

const Container = styled.div`
  height: 100%;
  overflow: auto;
  padding: 1rem;
`

export default EditTerritoryManagement
