import React, { PureComponent } from 'react'
import history from 'components/router/history'
import Accordion from 'components/accordion'
import styled from '@emotion/styled'
import { bool, func } from 'prop-types'

const iconSize = 0.75
const routes = [
  {
    name: 'Dashboard',
    path: '/dashboard'
  },
  {
    menu: {
      name: 'Catalog',
      path: '/catalog',
      submenu: [
        {
          name: 'Products',
          path: '/products'
        },
        {
          name: 'Groups',
          path: '/groups'
        },
        {
          name: 'Menus',
          path: '/menus'
        },
        {
          name: 'Brands',
          path: '/brands'
        },
        {
          name: 'Types',
          path: '/types'
        },
        {
          name: 'Subtypes',
          path: '/subtypes'
        },
        {
          name: 'Strains',
          path: '/strains'
        }
      ]
    }
  },
  {
    name: 'Users',
    path: '/users'
  }, {
    name: 'Deliveries',
    path: '/deliveries'
  }, {
    name: 'Promos',
    path: '/promos'
  },
  {
    name: 'Order Builder',
    path: '/order-builder'
  },
  {
    name: 'Inventory Update',
    path: '/inventory-update'
  },
  {
    name: 'Territory Management',
    path: '/territory-management'
  }
]

export default class MenuLinks extends PureComponent {
  static propTypes = {
    closeNavSlider: func,
    isAdmin: bool,
    isDispManager: bool
  }

  goToLink = (path) => {
    history.push(path)
    this.props.closeNavSlider()
  }

  render () {
    const { isAdmin, isDispManager } = this.props
    let routesToShow = []
    if (isAdmin) {
      routesToShow = routes
    } else if (isDispManager) {
      routesToShow = routes.filter(r => r.name === 'Dashboard' || r.name === 'Deliveries')
    }
    return routesToShow.map((r, i) => {
      if (r.menu) {
        const path = window.location.href.split('/').slice(3)
        const shouldOpen = r.menu.name.toLowerCase() === path[0]
        return (
          <Accordion
            buttonContent={<MenuLabel>{r.menu.name}</MenuLabel>}
            iconColor='#fff'
            openByDefault={shouldOpen}
            key={i}
          >
            <SubMenu>
              {r.menu.submenu.map((sub, index) => {
                const isActive = path[1] === sub.path.slice(1)
                return (
                  <SubMenuItem isActive={isActive} onClick={() => this.goToLink(r.menu.path + sub.path)} key={index}>
                    {sub.name}
                  </SubMenuItem>
                )
              })}
            </SubMenu>
          </Accordion>
        )
      } else {
        return (
          <MenuLabel onClick={() => this.goToLink(r.path)} key={i}>
            <IconOffset>{r.name}</IconOffset>
          </MenuLabel>
        )
      }
    })
  }
}

const SubMenu = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  margin-left: ${iconSize}rem;
  padding-bottom: 2rem;
`

const MenuLabel = styled.div`
  color: #fff;
  font-size: 1.8rem;
  font-weight: 700;
  padding-left: 1rem;
  cursor: pointer;
  line-height: 4.5rem;

  &:hover {
    text-decoration: underline;
  }
`
const SubMenuItem = styled.div`
  margin-left: ${iconSize}rem;
  cursor: pointer;
  opacity: ${({ isActive }) => isActive ? 1 : 0.5};
  line-height: 3.85rem;
  margin-left: ${iconSize}rem;


  .active {
    opacity: 1;
  }

  &:hover {
    text-decoration: underline;
  }
`

const IconOffset = styled.div`
  margin-left: ${iconSize}rem;
`
