import { css } from 'emotion'
import { mcBlue, mcRed, white } from 'helpers/css-variables'

export const formHeader = css`
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  color: ${white};
  background-color: ${mcBlue};
  padding: 2rem;
`

export const infoBlockLink = css`
  text-decoration: none;
  color: ${white};
`

export const errorText = css`
  color: ${mcRed};
  margin-top: 1rem;
`
