import React from 'react'

import { tagTypeColor, tagTypeStyle } from './helpers'

export default function RocketTag ({
  color = tagTypeColor,
  height = '16px',
  width = '16px',
  style = tagTypeStyle
}) {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title id='rocket-icon'>High potency</title>
      <g
        id='Page-4'
        fill='none'
        fillRule='evenodd'
        stroke='none'
        strokeWidth='1'
      >
        <g id='rocket' transform='translate(2 2)'>
          <circle id='Oval' cx='6.162' cy='7.215' r='1' fill={color} />
          <circle
            id='Oval'
            cx='8.612'
            cy='4.765'
            r='1.25'
            stroke='#F05B4B'
          />
          <path
            id='Path-9'
            stroke='#F05B4B'
            strokeLinejoin='round'
            d='M3.18 7.287l2.956 2.927c2.378-.717 4.245-1.982 5.602-3.795 1.357-1.813 1.913-3.945 1.669-6.395-2.753-.154-4.968.429-6.645 1.748-1.677 1.32-2.871 3.158-3.583 5.515z'
          />
          <path
            id='Path-10'
            stroke='#F05B4B'
            strokeLinejoin='round'
            d='M6.136 10.214l.626 3.06c1.044-.244 1.843-.789 2.396-1.633.52-.795.72-1.777.599-2.946'
          />
          <path
            id='Path-10'
            stroke='#F05B4B'
            strokeLinejoin='round'
            d='M.565 4.593l.627 3.06c1.044-.245 1.843-.789 2.396-1.634.52-.795.72-1.777.598-2.946'
            transform='scale(1 -1) rotate(89 7.85 0)'
          />
          <path
            id='Path-11'
            stroke='#F05B4B'
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M1.853 9.003c-.803.227-1.37.828-1.698 1.803a8.954 8.954 0 00-.12 2.468c.526.04 1.29.04 2.291 0 .894-.159 1.558-.718 1.992-1.678'
          />
        </g>
      </g>
    </svg>
  )
}
