import GoogleMaps from 'helpers/complacent'
import { formatSearchResults } from './format.js'
import { GMAPS_KEY } from 'helpers/environment'

const maps = Maps()

export function Maps () {
  return GoogleMaps({
    key: GMAPS_KEY,
    libraries: ['places']
  })
}

export function searchPlace (search, callback = () => {}) {
  const options = {
    input: search,
    componentRestrictions: {
      country: 'us'
    }
  }

  maps.autocomplete.place(options, (err, res) => {
    if (err) return callback()
    const formattedResults = formatSearchResults(res)
    callback(formattedResults)
  })
}
