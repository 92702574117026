import React from 'react'
import Drawer from '@eaze/react-fast-drawer'
import {
  array,
  bool,
  func,
  number,
  oneOfType,
  object,
  string
} from 'prop-types'
import withFullHeight from 'hocs/with-full-height'
import styled from '@emotion/styled'
import { css, keyframes } from 'emotion'

import Screen from 'components/screen/container'
import DeliveryOverview from './maps/delivery-overview'
import DepotSidebar from './depot-sidebar'
import DriverSidebar from './driver-sidebar'
import Breakdown from 'components/breakdown'
import DepotOverview from './depot-overview'

import MCColors from 'helpers/css-variables'
const { accessory5, darkDivider, primaryDark, secondaryDark } = MCColors

class Deliveries extends React.PureComponent {
  static propTypes = {
    changeDriverMode: func,
    deliveryCounts: oneOfType([number, object]),
    deliveryType: string,
    depots: array,
    driverCurrentVehicle: object,
    driverDetailsContent: string,
    fetchFocusedDriverInventory: func,
    focusedDriver: object,
    rerouteOrder: func,
    driverMarkers: oneOfType([array, string]),
    filteredDepots: oneOfType([array, object]),
    filteredDrivers: oneOfType([array, object]),
    isAdmin: bool,
    isDriverFocused: bool,
    isDeliveriesRoute: bool,
    isDeliveriesTab: bool,
    isFocusedDriverInventoryLoading: bool,
    isFocusedDriverOrdersLoading: bool,
    setFocusedDriver: func,
    focusedDriverId: oneOfType([number, string]),
    focusedDriverInventory: object,
    focusedDriverOrders: array,
    driverStatusTotals: oneOfType([number, object]),
    filterList: array,
    maps: oneOfType([array, object]),
    mapView: oneOfType([bool, object]),
    toggleMapView: func,
    saveMaps: func,
    setDriverDeliveryType: func,
    setModeFilter: func,
    mode: string,
    utilization: string,
    toggleDepotOverview: func,
    depotOverview: oneOfType([bool, string]),
    depotDriverOverview: oneOfType([array, string]),
    loading: bool,
    loadingDriver: oneOfType([bool, object]),
    setDepotFilter: func,
    setDepotFilterString: func,
    setDeliveryTypeFilter: func,
    setDriverDetailsContent: func,
    fetchDispensariesAndDepotDrivers: func,
    focusedDepotId: oneOfType([number, string]),
    userEmail: string
  }

  state = {
    expandDriverBubbleId: null,
    depotSpecificNav: '' // when a depot in the depotBlock is clicked we want to navigate to that area but not change the checked filterList
  }

  componentDidMount () {
    document.addEventListener('keyup', this.refreshKeyAction, false)
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps (nextProps) {
  /* eslint-enable camelcase */
    const filtersChange =
      this.props.filterList.length !== nextProps.filterList.length
    const driverFocusChanged =
      this.props.focusedDriver.id !== nextProps.focusedDriver.id
    if (filtersChange || driverFocusChanged) {
      this.setState({ depotSpecificNav: '' })
    }
  }

  componentWillUnmount () {
    document.removeEventListener('keyup', this.refreshKeyAction)
  }

  refreshKeyAction = e => {
    const { fetchDispensariesAndDepotDrivers, focusedDepotId } = this.props
    // we are typing in an input field we don't want to trigger this
    if (e.target.tagName === 'INPUT') return

    // keyCode for r
    if (e.keyCode === 82) {
      fetchDispensariesAndDepotDrivers(focusedDepotId)
    }
  }

  expandDriverBubble = id => {
    this.setState({ expandDriverBubbleId: id })
  }

  setDepotZoom = depotName => {
    this.setState({ depotSpecificNav: depotName })
  }

  render () {
    const { depotSpecificNav, expandDriverBubbleId } = this.state

    const {
      changeDriverMode,
      deliveryCounts,
      deliveryType,
      depots,
      driverCurrentVehicle,
      driverDetailsContent,
      fetchFocusedDriverInventory,
      focusedDriver,
      rerouteOrder,
      driverMarkers,
      filteredDepots,
      filteredDrivers,
      isAdmin,
      isDriverFocused,
      isDeliveriesRoute,
      isDeliveriesTab,
      isFocusedDriverInventoryLoading,
      isFocusedDriverOrdersLoading,
      setFocusedDriver,
      focusedDriverId,
      focusedDriverInventory,
      focusedDriverOrders,
      driverStatusTotals,
      filterList,
      maps,
      mapView,
      toggleMapView,
      saveMaps,
      setDriverDeliveryType,
      setModeFilter,
      mode,
      utilization,
      toggleDepotOverview,
      depotOverview,
      depotDriverOverview,
      loading,
      loadingDriver,
      setDepotFilter,
      setDepotFilterString,
      setDeliveryTypeFilter,
      setDriverDetailsContent,
      selectedDepot,
      userEmail
    } = this.props

    const showNav = !isDeliveriesTab

    const filteredDriverMarkers = depotSpecificNav
      ? driverMarkers.filter(d => d.depotName === depotSpecificNav)
      : driverMarkers

    const shouldRenderBreakdown = (focusedDriverId && !mapView) || loadingDriver

    const body = (
      <DeliveriesContainer>
        <InnerDeliveriesContainer>
          {mapView
            ? null
            : (
              <SidebarWrapper>
                {showNav && (
                  <DepotSidebar
                    depots={filteredDepots}
                    setDepotFilter={setDepotFilter}
                    setDepotFilterString={setDepotFilterString}
                    setFocusedDriver={setFocusedDriver}
                  />
                )}
                <DriverSidebar
                  deliveryCounts={deliveryCounts}
                  deliveryType={deliveryType}
                  drivers={filteredDrivers}
                  driverStatusTotals={driverStatusTotals}
                  expandDriverBubble={this.expandDriverBubble}
                  focusedDriverId={focusedDriverId}
                  loading={loading}
                  mode={mode}
                  setDeliveryTypeFilter={setDeliveryTypeFilter}
                  setDepotFilter={setDepotFilter}
                  setDepotZoom={this.setDepotZoom}
                  setFocusedDriver={setFocusedDriver}
                  setModeFilter={setModeFilter}
                  toggleDepotOverview={toggleDepotOverview}
                  utilization={utilization}
                />
              </SidebarWrapper>
              )}

          <Drawer
            escapeClose
            modalElementClass={DriverDrawerStyle}
            onRequestClose={toggleDepotOverview}
            open={depotOverview}
          >
            <DepotOverview
              depotDriverOverview={depotDriverOverview}
              toggleDepotOverview={toggleDepotOverview}
            />
          </Drawer>

          <MapContainer fullHeight={!!mapView}>
            <FullWidthMap>
              <ToggleUIButton onClick={toggleMapView}>
                {mapView ? 'Show' : 'Hide'} UI
              </ToggleUIButton>
            </FullWidthMap>

            <DeliveryOverviewContainer>
              <DeliveryOverview
                depots={depots}
                selectedDepot={selectedDepot}
                depotSpecificNav={depotSpecificNav}
                driver={focusedDriver}
                driverMarkers={filteredDriverMarkers}
                driverOrders={focusedDriverOrders}
                expandDriverBubbleId={expandDriverBubbleId}
                filterList={filterList}
                focusedDriverId={focusedDriverId}
                isDriverFocused={isDriverFocused}
                isOrdersLoading={isFocusedDriverOrdersLoading}
                maps={maps}
                mapView={mapView}
                saveMaps={saveMaps}
                setFocusedDriver={setFocusedDriver}
              />
            </DeliveryOverviewContainer>
            {shouldRenderBreakdown && (
              <Breakdown
                changeDriverMode={changeDriverMode}
                driverCurrentVehicle={driverCurrentVehicle}
                driverDetailsContent={driverDetailsContent}
                driverInventoryInfo={focusedDriverInventory}
                driverOrders={focusedDriverOrders}
                fetchFocusedDriverInventory={fetchFocusedDriverInventory}
                focusedDriver={focusedDriver}
                focusedDriverId={focusedDriverId}
                isAdmin={isAdmin}
                isDeliveriesRoute={isDeliveriesRoute}
                isDeliveriesTab={isDeliveriesTab}
                isInventoryLoading={isFocusedDriverInventoryLoading}
                isOrdersLoading={isFocusedDriverOrdersLoading}
                rerouteOrder={rerouteOrder}
                setDriverDeliveryType={setDriverDeliveryType}
                setDriverDetailsContent={setDriverDetailsContent}
                setFocusedDriver={setFocusedDriver}
                userEmail={userEmail}
              />
            )}
          </MapContainer>
        </InnerDeliveriesContainer>
      </DeliveriesContainer>
    )

    if (showNav) {
      return <Screen>{body}</Screen>
    } else {
      return body
    }
  }
}

export default withFullHeight(Deliveries)

const DeliveriesContainer = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
  width: 100%;

  @media(max-width: 767px) {
    height: auto;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  > div {
    display: flex;
    flex-direction: row;

    &:first-child {
      display: flex;
    }

    &:last-child {
      display: flex;
      height: 100%;
      overflow: hidden;
      width: 100%;

      @media(max-width: 767px) {
        flex-direction: column-reverse;
      }
    }
  }
`

const InnerDeliveriesContainer = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`

const SidebarWrapper = styled.div`
  display: flex;

  @media(max-width: 767px) {
    flex-direction: column;
  }
`

const DriverDrawerStyle = css`
  background-color: ${primaryDark};
  height: 100%;
  margin-top: 20rem;
  max-width: 100%;
  min-height: 40rem;
  overflow: auto;
  padding: 1rem;
  width: 75rem;
`

const MapContainer = styled.div`
  background-color: ${secondaryDark};
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;

  @media(max-width: 767px) {
    display: flex;
    flex-shrink: 0;
    height: ${({ fullHeight }) => fullHeight ? '100vh' : '40rem'};
  }
`

const FullWidthMap = styled.div`
  left: 1rem;
  margin: 1rem 1rem 0 0rem;
  position: absolute;
  z-index: 10;
`

const fallIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.5) translateY(-10rem);
  }
  75% {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0)
  }
`

const ToggleUIButton = styled.button`
  animation: ${fallIn} 0.6s linear forwards;
  background-color: ${primaryDark};
  border-radius: 2px;
  border: 1px solid ${darkDivider};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: ${accessory5};
  cursor: pointer;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
  opacity: 0;
  padding: 1rem;
  position: relative;
  right: 0;
  text-transform: uppercase;
  width: 8rem;
  will-change: transform;
`

const DeliveryOverviewContainer = styled.div`
  display: flex;
  height: 100%;
`
