import React from 'react'
import window from 'global/window'
import Button, { TRANSPARENT } from 'components/button'
import styled from '@emotion/styled'

import TablePager from 'src/microcomponents/table-pager'
import TableSearch from 'src/microcomponents/table-pager/search'
import Screen from 'src/components/screen/container'
import { MC_URL } from 'helpers/environment'
import { array, bool, func, number } from 'prop-types'

const Users = (props) => {
  const {
    decrement,
    increment,
    loading,
    page,
    search,
    setQuery,
    total,
    users
  } = props

  const setText = (event) => {
    const { value } = event.target

    if (value === '') {
      reset()
    }

    return setQuery(value)
  }

  const submit = (event) => {
    event.preventDefault()

    return search()
  }

  const toCustomer = (id) => {
    const userProfileUrl = `${MC_URL}/profile/${id}`

    window.location = userProfileUrl
  }

  const reset = () => {
    setQuery('')

    return search()
  }

  return (
    <Screen>
      <UsersListContainer>
        <UsersListTableControls>
          <TableSearch submit={submit} setText={setText} />
          <TablePager increment={increment} decrement={decrement} total={total} page={page} loading={loading} />
        </UsersListTableControls>
        <div>
          {!users.length && !loading
            ? (
              <UsersListNoMatches>
                <div>😳</div>
                <MarginBottomDiv>No matches</MarginBottomDiv>
                <Button onClick={reset} type={TRANSPARENT}>Try Again</Button>
              </UsersListNoMatches>
              )
            : (
              <table>
                <thead>
                  <tr>
                    <th />
                    <th>ID</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Status</th>
                    <th>Orders</th>
                    <th>Credits</th>
                    <th>Spent</th>
                  </tr>
                </thead>
                <tbody>
                  {!loading &&
                    users.map((user, i) => {
                      return (
                        <UsersListTableRow
                          isSuspended={user.isSuspended}
                          key={i}
                          onClick={() => toCustomer(user.id)}
                        >
                          <td>{i + 1}</td>
                          <td className='fs-hide'>{user.id}</td>
                          <td className='fs-hide'>{user.firstName} {user.lastName}</td>
                          <td className='fs-hide'>{user.email}</td>
                          <td className='fs-block'>{user.mobilePhoneDisplay}</td>
                          <td>{user.verificationStatusDescription}</td>
                          <td>{user.totalOrdersCount}</td>
                          <td>{user.extra && user.extra.inviteCredit}</td>
                          <td>{user.stat.chargeAmount || 0}</td>
                        </UsersListTableRow>
                      )
                    })
                  }
                </tbody>
              </table>
              )
          }
        </div>
      </UsersListContainer>
    </Screen>
  )
}

Users.propTypes = {
  decrement: func,
  increment: func,
  loading: bool,
  page: number,
  search: func,
  setQuery: func,
  total: number,
  users: array
}

export default Users

const UsersListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  width: 100%;
`

const UsersListTableControls = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
`

const UsersListNoMatches = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 3rem;
  justify-content: center;
  margin: 10rem auto 0;
  max-width: 20rem;
  text-align: center;
  width: 100%;
`

// !important here because global styles are fun
const UsersListTableRow = styled.tr`
  background-color: ${({ isSuspended }) => isSuspended && '#613f48 !important'};
`

const MarginBottomDiv = styled.div`
  margin-bottom: 1rem;
`
