// https://github.com/eaze/web-ui/tree/master/packages/color-picker
import React, { PureComponent } from 'react'
import { string, bool, func, array } from 'prop-types'

import styled from '@emotion/styled'
import { GithubPicker } from 'react-color'
import { Checkboard } from 'react-color/lib/components/common'
import Color from 'components/color'

// this contains a box with a color that will show ColorPickerWidget conditionally
export default class ColorPicker extends PureComponent {
  static propTypes = {
    color: string.isRequired,
    showColorPicker: bool.isRequired,
    onColorChange: func.isRequired
  }

  render () {
    const { color, showColorPicker, onColorChange } = this.props

    return (
      <Box bgColor={color}>
        <ColorPickerWidget show={showColorPicker} onColorChange={onColorChange} />
        {color === 'transparent' && <Checkboard />}
      </Box>
    )
  }
}

const Box = styled.div`
  position: relative;
  height: 2rem;
  width: 2rem;
  border-radius: 1px;
  border: 1px solid black;
  background-color: ${props => props.bgColor};
`

export class ColorPickerWidget extends PureComponent {
  static propTypes = {
    onColorChange: func.isRequired,
    show: bool.isRequired,
    colors: array
  }

  // default the colors array to the 1 value of @eaze/color with transparent first
  static defaultProps = {
    colors: ['transparent'].concat(Object.entries(Color).map(([_, value]) => value[1]))
  }

  handleColorChange = (color) => {
    // color has hex and rgb values, right now let's always use hex
    this.props.onColorChange(color.hex)
  }

  render () {
    const { colors, show } = this.props

    return show && (
      <Widget>
        <GithubPicker onChangeComplete={this.handleColorChange} width='150px' triangle='top-left' colors={colors} />
      </Widget>
    )
  }
}

const Widget = styled.div`
  position: relative;
  top: 2.5rem;
`
