/**
* convenience for returning a query parameter object for editing catalog forms
*/

import { QUERY_PARAM_STRINGS } from 'src/helpers/constants'
import qs from 'query-string'

const formatQuery = (formType, formId) => {
  const { CATALOG_FORM_TYPE, CATALOG_FORM_ID } = QUERY_PARAM_STRINGS

  const query = {
    [CATALOG_FORM_TYPE]: [formType],
    [CATALOG_FORM_ID]: formId
  }

  return qs.stringify(query)
}

export default formatQuery
