import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import Header from './header-box'

const PRODUCT_TYPES = {
  1: 'Flower',
  2: 'Preroll',
  3: 'Edible',
  4: 'Concentrate',
  5: 'Accessory',
  6: 'Vaporizer',
  7: 'Drop',
  8: 'Topical'
}

const containerStyle = {
  pageBreakAfter: 'always',
  fontFamily: 'arial'
}

const width100 = {
  width: '100%'
}

const noOverFlow = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: 300
}

const border = {
  borderBottom: '1px solid black'
}

const left = {
  textAlign: 'left'
}

const right = {
  textAlign: 'right'
}

const box = {
  height: 15,
  width: 15,
  borderRadius: 2,
  border: '1px solid black',
  marginRight: '1rem'
}

const padding = {
  padding: '8px 0'
}

const width60 = {
  maxWidth: 60,
  width: 60,
  minWidth: 60
}

const fontSize12 = {
  fontSize: 12
}

const MAX_ITEMS_PER_PAGE = 8

const getProductType = function (product) {
  return PRODUCT_TYPES[product.productType]
}

export default class PrintOrder extends PureComponent {
  renderTitle = (productType) => {
    return (<tr key={productType}><td style={{ ...padding, marginTop: 5, fontWeight: 'bold' }}>{productType.toUpperCase()}</td></tr>)
  }

  renderProductLineItem = (product) => {
    return (
      <tr style={{ ...width100, ...border }} key={product.id}>
        <td style={{ ...padding, ...width60, ...fontSize12 }}><div style={{ display: 'flex', ...width60, ...fontSize12 }}><div style={box} /> {product.quantity}X</div></td>

        <td style={{ ...left, ...padding, ...noOverFlow, ...fontSize12 }}>
          {product.name} <br />
          {product.brand.name}
        </td>
        <td style={{ ...right, ...padding, ...fontSize12 }}> ${product.price}<br />
          ID # {product.id}
        </td>
      </tr>
    )
  }

  renderPages = (order) => {
    const pages = []

    const originalProducts = order.products
    let currentProductType = getProductType(order.products[0])

    const paginate = (products) => {
      const page = []
      let nextProducts = []

      // all pages start with the title of the current product type
      page.push(this.renderTitle(currentProductType))

      // use a for loop so we can break out and possibly recurse paginate
      for (let i = 0; i < products.length; i++) {
        const product = products[i]
        const thisProductType = getProductType(product)
        let productAdded = false

        if (currentProductType !== thisProductType) {
          currentProductType = thisProductType
          // we have hit a new productType, so we must check if we can add 2 things - the new productType and at least one product below it
          if (page.length <= MAX_ITEMS_PER_PAGE - 2) {
            page.push(this.renderTitle(currentProductType))
            page.push(this.renderProductLineItem(product))
            productAdded = true
          }
        } else {
          // add product line item to page
          page.push(this.renderProductLineItem(product))
          productAdded = true
        }

        if (productAdded && page.length === MAX_ITEMS_PER_PAGE) {
          pages.push(page)
          currentProductType = thisProductType
          nextProducts = products.slice(i + 1) // we added a product, so nextProducts should start with the next product
          break
        }

        // no product was added, which means we've filled up the page or we hit a new productType and can't add 2 items
        if (!productAdded) {
          pages.push(page)
          nextProducts = products.slice(i)
          break
        }

        // we've run out of products, end this page and nextProducts will be empty
        if (products.length === i + 1) {
          pages.push(page)
          break
        }
      }

      // if we broke out of the loop because we had a full page, recurse until we have no more products
      if (nextProducts.length) {
        paginate(nextProducts)
      }
    }

    paginate(originalProducts)

    return pages
  }

  state = {
    pages: []
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillMount () {
  /* eslint-enable camelcase */
    const today = new Date(Date.now()).toLocaleString().split(',')[0]
    const pages = this.renderPages(this.props.order, today)
    this.setState({ pages, today })
  }

  render () {
    const { order } = this.props
    const { pages, today } = this.state
    const totalPageCount = pages.length

    return (
      <Fragment>
        {
          pages.map((page, i) => {
            return (
              <div key={i} style={containerStyle}>
                <Header pageNumber={i + 1} order={order} totalPages={totalPageCount} today={today} />

                <table style={{ ...width100, borderCollapse: 'collapse', marginTop: '10px' }}>
                  <tbody>
                    {page}
                  </tbody>
                </table>
              </div>
            )
          })
        }
      </Fragment>
    )
  }
}

PrintOrder.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    products: PropTypes.array.isRequired
  })
}
