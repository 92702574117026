import { func, number, oneOfType, string } from 'prop-types'
import React from 'react'
import { Row, Column } from 'src/microcomponents/flexbox-helpers'
import InputLabel from 'src/microcomponents/input-label'

const Values = ({ minOrderValue, creditMax, setMinOrderValue, setCreditMax }) => (
  <Row top={1} bottom={1}>
    <Column right={1} full>
      <InputLabel content='Minimum Order Value' />
      <input
        onBlur={setMinOrderValue}
        type='number'
        placeholder='MOV'
        defaultValue={minOrderValue}
      />
    </Column>
    <Column full>
      <InputLabel content='Max discount*' />
      <input
        onBlur={setCreditMax}
        type='number'
        placeholder='Amount'
        defaultValue={creditMax}
      />
    </Column>
  </Row>
)

Values.propTypes = {
  minOrderValue: oneOfType([number, string]),
  creditMax: oneOfType([number, string]),
  setMinOrderValue: func,
  setCreditMax: func
}

export default Values
