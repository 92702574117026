import t from './actionTypes'

export const setPermissions = function ({isAdmin, isDispManager}) {
  return (dispatch) => {
    dispatch({
      type: t.SET_PERMISSIONS,
      payload: {
        isAdmin,
        isDispManager
      }
    })
  }
}

export const clearPermissions = function () {
  return (dispatch) => {
    dispatch({
      type: t.CLEAR_PERMISSIONS
    })
  }
}
