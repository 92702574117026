import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { accessory2, accessory5, mcGreen, mcRed } from 'src/helpers/css-variables'
import { array, bool, func } from 'prop-types'

export class FabFan extends PureComponent {
  state = {
    open: false
  }

  static propTypes = {
    onClick: func,
    open: bool,
    options: array
  }

  render () {
    const {
      onClick,
      open,
      options
    } = this.props

    return (
      <Container>
        <Content>
          {open && options.length && options.map((option, i) => (
            <OptionsContainer
              key={i}
              onClick={option.onClick}
            >
              <div>{option.name}</div>
              <BigDotDiv backgroundColor={option.background} />
            </OptionsContainer>
          ))}
          <FabFanButton
            isOpen={open}
            onClick={onClick}
          >
            <FabFanButtonContent>×</FabFanButtonContent>
          </FabFanButton>
        </Content>
      </Container>
    )
  }
}

export default FabFan

const Container = styled.div`
  bottom: 2rem;
  position: fixed;
  right: 2rem;
  width: 0;
  z-index: 15;
`

const Content = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  position: relative;
`

const OptionsContainer = styled.div`
  align-items: center;
  color: ${accessory2};
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  letter-space: 0.03em;
  margin-bottom: 1.5rem;
  width: 17rem;
`

const BigDotDiv = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 50%;
  height: 5rem;
  width: 5rem;
`

const FabFanButton = styled.button`
  align-items: center;
  background-color: ${({ isOpen }) => isOpen ? mcRed : mcGreen};
  border-radius: 50%;
  border: 0;
  box-shadow: 0 0.3rem 1rem rgba(0,0,0,0.2);
  color: ${accessory5};
  cursor: pointer;
  display: flex;
  font-size: 3rem;
  height: 6rem;
  justify-content: center;
  outline: none;
  padding: 1rem;
  transform: rotate(${({ isOpen }) => isOpen ? '270' : '45'}deg);
  transition: background-color 0.3s linear;
  width: 6rem;
`

const FabFanButtonContent = styled.div`
  bottom: 0.25rem;
`
