// Palette: https://material-ui.com/customization/palette/

import COLORS from './colors'

const PALETTE = {
  primary: {
    light: COLORS.blue[30],
    main: COLORS.blue[50],
    dark: COLORS.blue[70],
    contrastText: 'white'
  },
  secondary: {
    light: COLORS.indigo[30],
    main: COLORS.indigo[50],
    dark: COLORS.indigo[70],
    contrastText: 'white'
  },
  // Custom keys
  colors: COLORS
}

export default PALETTE
