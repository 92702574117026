import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import QuantityPicker from 'src/components/quantity-picker'

const Container = styled.div`
  position: absolute;
  transform-origin: top right;
  width: 100%;
  top: 1rem;
  z-index: 6;
  transform: scale(${getScale});
  transition: all 0.2s linear;
`

const IndividualPicker = styled.div`
  width: 90%;
  border-radius: 5rem;
  padding: 0.5rem;
  background: white;
  display: flex;
  box-shadow: 0 0.2rem 3rem rgba(0,0,0,0.3);
  margin: 0 auto;
  justify-content: space-between;
`

export default class QuantityPickerComponent extends PureComponent {
  static propTypes = {
    focused: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    showIndividualPicker: PropTypes.bool,
    isInCart: PropTypes.bool.isRequired,
    product: PropTypes.object.isRequired,
    cartItem: PropTypes.object.isRequired,
    addItem: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
    productAdded: PropTypes.func.isRequired,
    productRemoved: PropTypes.func,
    onOverMaxQuantityClick: PropTypes.func
  }

  stopPropagation = event => event.stopPropagation()

  render () {
    const {
      focused,
      isMobile,
      showIndividualPicker,
      isInCart,
      product,
      cartItem,
      addItem,
      removeItem,
      productAdded,
      productRemoved,
      onOverMaxQuantityClick
    } = this.props

    return (
      <Container focused={focused} isMobile={isMobile} showIndividualPicker={showIndividualPicker} isInCart={isInCart}>
        <IndividualPicker onClick={this.stopPropagation} onTouchStart={this.stopPropagation}>
          <QuantityPicker
            product={product}
            cartItem={cartItem}
            addItemToCart={addItem}
            removeItemFromCart={removeItem}
            onOverMaxQuantityClick={onOverMaxQuantityClick}
            modifyCart
            hidePrice
            stripQty

            onAfterAdd={productAdded}
            onAfterRemove={productRemoved}
          />
        </IndividualPicker>
      </Container>
    )
  }
}

function getScale ({ focused, isMobile, showIndividualPicker, isInCart }) {
  return (focused || (isMobile && showIndividualPicker)) && isInCart ? 1 : 0
}
