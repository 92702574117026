import React from 'react'

import { tagTypeColor, tagTypeStyle } from './helpers'

export default function ExclusiveTag ({ color = tagTypeColor, height = '12px', width = '9px', style = tagTypeStyle }) {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox='0 0 9 12'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Exclusive</title>
      <desc>Created with Sketch.</desc>
      <g id='MC' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Exclusive' transform='translate(-8.000000, -6.000000)' fill={color} fillRule='nonzero'>
          <path d='M10.5186582,14.3279829 C10.7713474,15.0202715 11.2016019,15.6131722 11.8424307,15.9273296 C12.6141571,16.3077456 13.5258869,16.0415686 14.063136,15.6234537 C14.8599036,15.0031357 15.1740577,13.8344702 15.2013754,12.3699256 C15.2082049,11.8832672 15.1114545,11.4034632 15.2685316,11.0299015 C15.3540959,10.8086261 15.5505281,10.6497756 15.784154,10.612929 C16.1590141,10.5670795 16.5156402,10.7875244 16.6435249,11.1441405 C16.7304505,11.4179515 16.7832121,11.7015238 16.8006019,11.9883671 C16.8971227,12.8910017 16.8249364,13.803788 16.5877511,14.6798392 C16.2975001,15.7536862 15.7807393,16.5476476 15.0625102,17.1234124 C14.6743266,17.4341156 14.2280949,17.6636773 13.7501201,17.7985652 C13.1769779,17.9752615 12.5747065,18.0366012 11.9778812,17.9790629 C10.8396417,17.8454032 10.0428741,17.2719232 9.4316395,16.5716378 C8.8204049,15.8713524 8.38104446,14.942589 8.15225832,13.8801658 C7.91550451,12.7800437 7.96786353,11.379473 8.21941445,10.2884901 C8.57568341,8.73483898 9.29163604,7.37425189 10.4401197,6.55858507 C10.8677706,6.23776711 11.3773063,6.04525163 11.9095869,6.00338329 C13.0967706,5.94740616 13.8184145,6.59285679 14.1644393,7.4336562 C14.5355053,8.3350023 14.4546903,9.62133401 14.1530569,10.5980779 C13.877312,11.4650672 13.405108,12.2561169 12.7735106,12.9091339 C12.1544081,13.5669187 11.3783088,14.0552733 10.5186582,14.3279829 Z M12.3398414,9.41570372 C12.3899239,8.87878018 12.3876474,8.13736875 12.0143049,7.95230149 C11.83087,7.88337761 11.6269072,7.89583439 11.4531528,7.9865732 C11.0934691,8.16478612 10.8567153,8.58975539 10.6905324,8.99987358 C10.2875956,9.99603809 10.0884037,11.473149 10.1965364,12.7606231 C11.3313612,12.1540138 12.1839026,11.0618884 12.3352884,9.4179885 L12.3398414,9.41570372 Z' />
        </g>
      </g>
    </svg>
  )
}
