import React, { PureComponent } from 'react'
import { bool, func, object, string } from 'prop-types'
import Accordion from 'components/accordion'
import InputLabel from 'src/microcomponents/input-label'
import { MENU_SLUGS } from 'src/helpers/constants'
import SidebarMenuProducts from 'src/components/sidebar-menu-products'

import {
  accordionButtonText,
  accordionWrapper,
  container,
  filterInput,
  filterInputContainer,
  productGroups,
  selectContainer,
  setMenuSlugContainer
} from './style.js'

class SidebarMenu extends PureComponent {
  static propTypes = {
    groups: object.isRequired,
    isCaseBuilder: bool, // only passed in as true if case builder so we dont show menu select dropdown
    menu: object.isRequired,
    menuSlug: string,
    onAddProduct: func.isRequired,
    onMenuSlugChange: func.isRequired,
    onProductsFilterChange: func.isRequired,
    products: object.isRequired
  }

  handleMenuSlugChange = ({ target: { value } }) => {
    const { onMenuSlugChange } = this.props

    onMenuSlugChange(value)
  }

  handleFilterChange = ({ target: { value } }) => {
    const { onProductsFilterChange } = this.props

    onProductsFilterChange(value)
  }

  renderAccordionButtonContent = (group) => (
    <span className={accordionButtonText}>
      {group.name} {group.products.length}
    </span>
  )

  render () {
    const {
      isCaseBuilder,
      menu,
      groups,
      products,
      menuSlug,
      onAddProduct
    } = this.props

    return (
      <div className={container}>
        {!isCaseBuilder && <div className={setMenuSlugContainer}>
          <InputLabel content='Select Menu' />
          <div className={selectContainer}>
            <select
              onChange={this.handleMenuSlugChange}
              value={menuSlug}
            >
              {
                MENU_SLUGS.map(function (slug, index) {
                  return <option key={`menu-slug-${index}`} value={slug}>{slug}</option>
                })
              }
            </select>
          </div>
        </div>
        }
        <div className={filterInputContainer}>
          <input
            className={filterInput}
            placeholder='Search products'
            onChange={this.handleFilterChange}
          />
        </div>
        <div className={productGroups}>
          {menu.groups.map((id, index) => {
            const group = groups[id] || { products: [] }
            const hasProducts = Boolean(group.products.length)
            // we don't want to render buy it again for the order builder since it is
            // related to the logged in user, not the user who we're building a cart for
            const isBuyItAgain = group.slug === 'buy-it-again'
            const shouldRenderGroup = hasProducts && !isBuyItAgain

            // only render a group if it has products
            return shouldRenderGroup && (
              <div className={accordionWrapper} key={index}>
                <Accordion
                  key={`group-accordion-element-${id}`}
                  buttonContent={this.renderAccordionButtonContent(group)}
                  iconColor='#fff'
                >
                  <SidebarMenuProducts
                    productIds={group.products}
                    products={products}
                    onAddProduct={onAddProduct}
                  />
                </Accordion>
              </div>
            )
          })
          }
        </div>
      </div>
    )
  }
}

export default SidebarMenu
