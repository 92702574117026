import t from './actionTypes'

export function setDepotsMap (depotsMap) {
  return {
    type: t.SET_DEPOTS_MAP,
    payload: depotsMap
  }
}

export function clearDepotsMap () {
  return {
    type: t.CLEAR_DEPOTS_MAP
  }
}
