import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { LANE_TYPES } from 'helpers/hub'
import Print from 'microcomponents/print-button'
import rightArrowImg from 'src/assets/thick-arrow.svg'
import LoadingSpinner from 'src/microcomponents/loading-spinner'
import styled from '@emotion/styled'

export default class OrderCardAction extends PureComponent {
  static propTypes = {
    onClickFn: PropTypes.func,
    orderId: PropTypes.number,
    orderIsLoading: PropTypes.bool,
    orders: PropTypes.array,
    type: PropTypes.string.isRequired
  }

  static defaultProps = {
    orders: []
  }

  handleClick = () => {
    const { onClickFn, orderId, orders } = this.props
    // orderOrOrderId? this seems...bad...
    const ordersOrOrderId = orders.length ? orders : orderId
    onClickFn(ordersOrOrderId)
  }

  getSrc = (type) => {
    let src
    let flip = false
    if (type === LANE_TYPES.PACKING) {
      src = rightArrowImg
    } else if (type === LANE_TYPES.PACKED) {
      flip = true
      src = rightArrowImg
    } else if (type === LANE_TYPES.NOT_RECOVERED) {
      src = rightArrowImg
    }

    return { src, flip }
  }

  render () {
    const { type, onClickFn, orderIsLoading } = this.props
    const { src, flip } = this.getSrc(type)

    if (type === LANE_TYPES.AWAITING_DRIVER) {
      return <Print runner onClick={this.handleClick} />
    }

    if (!src || !onClickFn) return null

    if (orderIsLoading) {
      return (
        <OrderCardIsLoading>
          <LoadingSpinner show size={4} />
        </OrderCardIsLoading>
      )
    }

    return (
      <OrderCardActionButton onClick={this.handleClick}>
        <OrderCardArrow src={src} flip={flip} />
      </OrderCardActionButton>
    )
  }
}

const OrderCardActionButton = styled.button`
  background-color: #ffffffa3;
  border-radius: 0.5rem;
  cursor: pointer;
  height: 6rem;
  position: absolute;
  right: 3rem;
  width: 6rem;
`

const OrderCardIsLoading = styled.div`
  min-width: 4rem;
  position: relative;
  right: 4rem;
`

const OrderCardArrow = styled.img`
  transform: ${({ flip }) => flip ? 'rotate(180deg)' : ''};
`
