import React, { PureComponent } from 'react'
import { array, func, number } from 'prop-types'
import styled from '@emotion/styled'
import { white } from 'src/helpers/css-variables'

import CloneDepotProducts from './clone-depot-products.js'
import AddPeakHoursForm from 'src/components/settings/delivery-fees/add-peak-hours-form'
import { formatPeakHoursTime } from 'src/helpers/peak-hours'
import AddDeliveryScheduleForm from '../../components/settings/delivery-fees/add-delivery-schedule-form.js'

export default class Settings extends PureComponent {
  static propTypes = {
    activeDepots: array,
    activeDispensaries: array,
    deliveryFeesList: array,
    deliveryScheduleList: array,
    saveDeliveryFees: func,
    saveDeliverySchedule: func,
    selectedDepotId: number
  }

  getDepotTimeZone = depotId => {
    const { activeDepots } = this.props
    const depot = activeDepots.find(depot => depot.id === depotId)
    return depot && depot.timeZone
  }

  createPeakHoursBody = (peakHours, depotId) => {
    const peakHoursList = peakHours.peakHours
    const mappedPeakHoursBody = peakHoursList.map(peakHoursRow => {
      const depotTimeZone = this.getDepotTimeZone(depotId)
      const updatedRow = {
        depotId: depotId,
        startTime: formatPeakHoursTime(
          peakHoursRow.dayOfWeek,
          peakHoursRow.startTime,
          depotTimeZone
        ),
        endTime: formatPeakHoursTime(
          peakHoursRow.dayOfWeek,
          peakHoursRow.endTime,
          depotTimeZone
        ),
        deliveryFee: peakHoursRow.deliveryFee,
        minimumAmountBypassDeliveryFee: null,
        minimumOrderValue: null,
        remainingAmountBypassDeliveryFee: null
      }
      return updatedRow
    })
    return mappedPeakHoursBody
  }

  createDeliveryScheduleBody = (deliverySchedule, depotId) => {
    const deliveryScheduleHoursList = deliverySchedule.deliverySchedule
    const mappedDeliveryScheduleBody = deliveryScheduleHoursList.map(
      deliveryScheduleRow => {
        const depotTimeZone = this.getDepotTimeZone(depotId)
        const updatedRow = {
          depotId: depotId,
          timeFrom: formatPeakHoursTime(
            deliveryScheduleRow.dayOfWeek,
            deliveryScheduleRow.startTime,
            depotTimeZone
          ),
          timeTo: formatPeakHoursTime(
            deliveryScheduleRow.dayOfWeek,
            deliveryScheduleRow.endTime,
            depotTimeZone
          ),
          windowCapacity: deliveryScheduleRow.windowCapacity,
          dayOfWeek: deliveryScheduleRow.dayOfWeek
        }
        return updatedRow
      }
    )
    return mappedDeliveryScheduleBody
  }

  handleSubmitDeliveryHoursForm = formData => {
    const { saveDeliveryFees, selectedDepotId } = this.props
    saveDeliveryFees(
      this.createPeakHoursBody(formData, selectedDepotId),
      selectedDepotId
    )
  }

  handleSubmitDeliveryScheduleForm = formData => {
    const { saveDeliverySchedule, selectedDepotId } = this.props
    saveDeliverySchedule(
      this.createDeliveryScheduleBody(formData, selectedDepotId),
      selectedDepotId
    )
  }

  render () {
    const {
      deliveryFeesList,
      deliveryScheduleList,
      activeDepots,
      activeDispensaries,
      selectedDepotId
    } = this.props
    const activeDepotsLoaded = activeDepots && activeDepots.length > 0

    const depotTimeZone = this.getDepotTimeZone(selectedDepotId)
    const dispensaryId = activeDepots?.find(
      d => d.id === selectedDepotId
    )?.dispensaryId

    return (
      activeDepotsLoaded
        ? <Wrapper>
          <Header>Depot Settings</Header>
          <AddPeakHoursForm
            deliveryFees={deliveryFeesList}
            onSubmitForm={this.handleSubmitDeliveryHoursForm}
            depotTimeZone={depotTimeZone}
          />
          <AddDeliveryScheduleForm
            deliverySchedule={deliveryScheduleList}
            onSubmitForm={this.handleSubmitDeliveryScheduleForm}
            depotTimeZone={depotTimeZone}
          />
          <Header>Clone Depot Products</Header>
          <CloneDepotProducts
            activeDepots={activeDispensaries?.[dispensaryId]?.depots}
            selectedDepotId={selectedDepotId}
          />
        </Wrapper>
        : null
    )
  }
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 89.9rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`
const Header = styled.h1`
  font-weight: 600;
  font-size: 2.4rem;
  letter-spacing: 0.033rem;
  color: ${white};
  margin: 5rem 0 3.6rem;
`
