import React, { PureComponent } from 'react'
import { array, func, string } from 'prop-types'
import { Text } from 'components/typography'
import Autosuggest from 'react-autosuggest'
import debounce from 'debounce'

import ROUTES from 'src/components/router/routes'
import BackButton from 'src/components/back-button'
import history from 'components/router/history'
import Screen from 'src/components/screen/container'
import phoneSvg from 'src/assets/phone.svg'

import theme from './autosuggest-theme.js'
import {
  backIcon,
  backText,
  content,
  leftColumn,
  pageContainer,
  phoneImage,
  rightColumn
} from './style.js'

class CatalogBuilderSearchUser extends PureComponent {
  static propTypes = {
    list: array,
    search: func,
    setQuery: func,
    setUsers: func,
    query: string
  }

  componentWillUnmount () {
    this.props.setQuery('')
  }

  onChange = (event, { newValue }) => this.props.setQuery(newValue)

  renderSuggestion = suggestion => (
    <div>
      <Text><b>Name:</b> {suggestion.displayName}</Text>
      <Text><b>Email:</b> {suggestion.email}</Text>
      <Text className='fs-block'>
        <b>Phone:</b> {suggestion.mobilePhoneDisplay || 'None'}
      </Text>
    </div>
  )

  onSelect = (event, { suggestion }) => {
    history.push(`/order-builder/user/${suggestion.id}`)
  }

  debouncedSearch = debounce(() => {
    const { search, query, setUsers } = this.props

    // only run search if there's a query
    if (query.trim()) {
      search()
    } else {
      // if there's no query, clear search
      setUsers([])
    }
  }, 250)

  render () {
    const { list, query } = this.props

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Enter customer phone number or email to get started.',
      value: query,
      onChange: this.onChange,
      type: 'search',
      autoFocus: true
    }

    return (
      <Screen>
        <div className={pageContainer}>
          <div className={leftColumn}>
            <BackButton path={ROUTES.ORDER_BUILDER}>
              <span className={backIcon}>←</span>
              <span className={backText}>Go Back</span>
            </BackButton>
          </div>
          <div className={rightColumn}>
            <div className={content}>
              <img
                className={phoneImage}
                src={phoneSvg}
              />
              <Autosuggest
                suggestions={list}
                alwaysRenderSuggestions
                inputProps={inputProps}
                renderSuggestion={this.renderSuggestion}
                getSuggestionValue={({ email }) => email}
                onSuggestionsFetchRequested={this.debouncedSearch}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={this.onSelect}
                theme={theme}
              />
            </div>
          </div>
        </div>
      </Screen>
    )
  }
}

export default CatalogBuilderSearchUser
