import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Row, Column } from 'src/microcomponents/flexbox-helpers'
import InputLabel from 'src/microcomponents/input-label'
import DateInput from 'src/microcomponents/input-date'
import { formatAsPacificTime, formatDate } from 'src/helpers/date'
import { format } from 'date-fns-tz'
import { PROMOS_IANA_TIME_ZONE } from 'src/helpers/promos/definitions'
import { func, string } from 'prop-types'

const TZ = format(new Date(), 'z', { timeZone: PROMOS_IANA_TIME_ZONE }) // PST OR PDT

const DateFormSection = ({ promoValidAt, promoExpiresAt, setPromoValidAt, setPromoExpiresAt }) => {
  const [startDate, setStartDate] = useState(formatAsPacificTime(promoValidAt, 'date'))
  const [startTime, setStartTime] = useState(formatAsPacificTime(promoValidAt, 'time'))
  const [endDate, setEndDate] = useState(formatAsPacificTime(promoExpiresAt, 'date'))
  const [endTime, setEndTime] = useState(formatAsPacificTime(promoExpiresAt, 'time'))

  useEffect(() => {
    const startDateTime = formatDate(startDate, startTime)
    setPromoValidAt(startDateTime)
  }, [startDate, startTime])

  useEffect(() => {
    const endDateTime = formatDate(endDate, endTime)
    setPromoExpiresAt(endDateTime)
  }, [endDate, endTime])

  return (
    <>
      <Row top={1} bottom={1}>
        <Column right={1}>
          <InputLabel content='Start Date *' htmlFor='startDate' />
          <DateInput
            id='startDate'
            name='startDate'
            onChange={(e) => setStartDate(e.target.value)}
            required
            type='date'
            value={startDate}
          />
        </Column>
        <Column right={1}>
          <InputLabel content='Start Time *' htmlFor='startTime' />
          <TimeInput
            id='startTime'
            name='startTime'
            onChange={(e) => setStartTime(e.target.value)}
            required
            type='time'
            value={startTime}
          />
        </Column>
        <Column right={1} componentStyle={{ alignSelf: 'flex-end' }}>
          {TZ}
        </Column>
      </Row>

      <Row top={1} bottom={1}>
        <Column right={1}>
          <InputLabel content='End Date *' htmlFor='endDate' />
          <DateInput
            id='endDate'
            name='endDate'
            onChange={(e) => setEndDate(e.target.value)}
            required
            type='date'
            value={endDate}
          />
        </Column>
        <Column right={1}>
          <InputLabel content='End Time *' htmlFor='endTime' />
          <TimeInput
            id='endTime'
            name='endTime'
            onChange={(e) => setEndTime(e.target.value)}
            required
            type='time'
            value={endTime}
          />
        </Column>
        <Column right={1} componentStyle={{ alignSelf: 'flex-end' }}>
          {TZ}
        </Column>
      </Row>
    </>
  )
}

const TimeInput = styled.input`
  width: 16rem;
`

DateFormSection.propTypes = {
  promoValidAt: string,
  promoExpiresAt: string,
  setPromoValidAt: func,
  setPromoExpiresAt: func
}

export default DateFormSection
