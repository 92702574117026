import React, { PureComponent } from 'react'
import { func, string } from 'prop-types'
import { Column } from 'src/microcomponents/flexbox-helpers'

import ColorPicker from 'components/color-picker'
import McButton from 'microcomponents/mc-button'
import UploadFile from 'microcomponents/upload-file'
import uploadImageSvg from 'src/assets/upload-image.svg'
import IconImage from './icon'

import {
  descriptionClass,
  menuColorClass,
  menuInfoSection,
  topMenuInfo,
  uploadButton,
  uploadButtons,
  uploadButtonMobile
} from '../edit-page-styles.js'

export default class MenuMainTab extends PureComponent {
  state = {
    showColorPicker: false
  }

  static propTypes = {
    description: string.isRequired,
    desktopImage: string.isRequired,
    iconImage: string.isRequired,
    menuColor: string.isRequired,
    saveFormValue: func.isRequired,
    uploadPublicFile: func
  }

  handleColorChange = (colorHex) => {
    this.setState({ showColorPicker: false })
    this.props.saveFormValue(colorHex, 'color')
  }

  render () {
    const {
      description,
      desktopImage,
      iconImage,
      menuColor,
      saveFormValue,
      uploadPublicFile
    } = this.props

    const { showColorPicker } = this.state

    const backgroundImage = desktopImage ? `url(${desktopImage})` : '#ECE9E3'

    return (
      <div>
        <div className={topMenuInfo}>
          <Column componentStyle={{ padding: '1rem' }}>
            <IconImage color={menuColor} image={iconImage} />
            HEROS WILL GO HERE EVENTUALLY
          </Column>
        </div>

        <div className={menuInfoSection} style={{ background: backgroundImage, opacity: 0.8 }}>
          <div style={{ height: '10rem' }}>
            <div className={menuColorClass}>
              <McButton onClick={() => this.setState({ showColorPicker: true })}>
                <span>MENU COLOR</span>
                <ColorPicker color={menuColor} showColorPicker={showColorPicker} onColorChange={this.handleColorChange} />
              </McButton>
            </div>

            <div className={uploadButtons}>

              <UploadFile
                postUpload={({ downloadUrl }) => saveFormValue(downloadUrl, 'icon_image')}
                uploadFile={uploadPublicFile}
              >
                <div className={uploadButton}>
                  <img style={{ height: '3rem', width: '3rem' }} src={uploadImageSvg} />
                  ICON
                </div>
              </UploadFile>

              <UploadFile
                postUpload={({ downloadUrl }) => saveFormValue(downloadUrl, 'desktop_image')}
                uploadFile={uploadPublicFile}
              >
                <div className={uploadButtonMobile}>
                  <img style={{ height: '3rem', width: '3rem' }} src={uploadImageSvg} />
                  DESKTOP
                </div>
              </UploadFile>

              <UploadFile
                postUpload={({ downloadUrl }) => saveFormValue(downloadUrl, 'mobile_image')}
                uploadFile={uploadPublicFile}
              >
                <div className={uploadButtonMobile}>
                  <img style={{ height: '3rem', width: '3rem' }} src={uploadImageSvg} />
                  MOBILE
                </div>
              </UploadFile>
            </div>
          </div>

          <center>
            <IconImage color={menuColor} image={iconImage} />
          </center>

          <textarea
            className={descriptionClass}
            onChange={event => saveFormValue(event.target.value, 'description')}
            value={description}
            placeholder='Menu description'
          />
        </div>
      </div>
    )
  }
}
