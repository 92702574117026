import { css } from 'emotion'
import { borderContainerLight, colorTextDark, secondaryDark, white } from 'helpers/css-variables'

export const container = css`
  position: relative;
  max-width: 40rem;
  width: 100%;
  margin-top: 5rem;
`

export const input = css`
  background-color: ${white};
  color: ${colorTextDark};
  border: 1px solid ${borderContainerLight};
  background-repeat: no-repeat;
  background-image: url('assets/spyglass-dark.svg');
  background-size: 1.6rem;
  background-position: 96% center;

  &:focus {
    background-image: none;
  }
`

export const suggestionsContainer = css`
  width: 100%;
  list-style-type: none;
  border-top: none;
  color: ${colorTextDark};
  font-size: 1.6rem;
  margin-top: 0.5rem;
  position: absolute;

  ul {
    list-style: none;
    margin: 0;
    padding-left: 0;

    li {
      background-color: ${white};
      margin-bottom: 1rem;
      border-radius: 0.4rem;
      list-style: none;
      padding: 1rem;
      font-size: 1.4rem;
      cursor: pointer;
      border: 1px solid ${borderContainerLight};
      box-shadow: 0 -0.2rem 0.5rem rgba(0,0,0,0.1);
    }
  }
`

export const suggestion = css`
  width: 100%;
  border-bottom: 1px solid ${secondaryDark};
`

export const suggestionHighlighted = css`
  opacity: 0.5;
`
