import React from 'react'
import { string } from 'prop-types'
import { Field, getIn } from 'formik'

import { mcRed } from 'helpers/css-variables'
import styled from '@emotion/styled'

const ErrorMessage = ({ name }) => (
  <Field
    name={name}
  >
    {({ form }) => {
      const error = getIn(form.errors, name)
      const touch = getIn(form.touched, name)
      return touch && error ? <Message>{error}</Message> : null
    }}
  </Field>
)

const Message = styled.div`
  padding: 0.6rem 0 0;
  color: ${mcRed}
`

ErrorMessage.propTypes = {
  name: string
}

export default ErrorMessage
