import t from './actionTypes'

export function setDriverInventoryMap (driverInventoryMap) {
  return {
    type: t.SET_DRIVER_INVENTORY_MAP,
    payload: driverInventoryMap
  }
}

export function updateDriverInventoryMap (driverInventoryMap) {
  return {
    type: t.UPDATE_DRIVER_INVENTORY_MAP,
    payload: driverInventoryMap
  }
}

export function clearDriverInventoryMap () {
  return {
    type: t.CLEAR_DRIVER_INVENTORY_MAP
  }
}
