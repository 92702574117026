import { createSelector } from 'reselect'

export const getLoading = (state) => state.loading

export const areWeLoading = createSelector(
  [getLoading],
  loadingState => {
    return Object
      .keys(loadingState)
      .some(l => loadingState[l])
  }
)

export const getLoadingCatalog = createSelector(
  [getLoading],
  (loading) => loading.getLoadingCatalog
)

export const getLoadingProducts = createSelector(
  [getLoading],
  (loading) => loading.loadingProducts
)

export const getLoadingUsers = createSelector(
  [getLoading],
  (loading) => loading.users
)
