import styled from '@emotion/styled'
import { white } from 'helpers/css-variables'

const ContentBlock = styled.div`
  padding: 2rem;
  justify-content: space-around;
  background-color: ${({ color }) => color};
  flex: 1;
  margin-right: 2rem;
  border-radius: 0.5rem;
  height: 11rem;
`

const Title = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
  color: ${white};
  font-family: system-ui;
  font-weight: 800;
`

const Subtitle = styled(Title)`
  white-space: nowrap;
  font-size: 1rem;
`

const SpecialContentBlock = styled(ContentBlock)`
  margin-right: 2rem;
  display: flex;
  align-items: center;
  flex: 2;
  justify-content: space-around;
`

const TitleSmall = styled(Title)`
  font-size: 2rem;
`

const OperatorRow = styled.div`
  display: flex;
`

const Operator = styled.div`
  align-items: center;
  color: ${white};
  display: flex;
  justify-content: center;
  padding: 0 0.5rem;
`

export default {
  ContentBlock,
  Operator,
  OperatorRow,
  SpecialContentBlock,
  Subtitle,
  Title,
  TitleSmall
}
