import api from 'api'
import times from 'map-times'

import { pushNotification, SUCCESS } from 'src/redux/alerts/actions'
import {
  SET_STRAIN,
  SET_CATALOG_STRAINS,
  SHOW_STRAIN_FORM,
  HIDE_STRAIN_FORM
} from 'src/redux/catalog/strain/actionTypes'
import {
  hideActionBar,
  clearFormQueryParams
} from 'src/redux/catalog/actions.common'

export function createCatalogStrain () {
  return async (dispatch, getState) => {
    const state = getState()
    const { catalogStrain } = state
    const body = transformFromCatalogStrain(catalogStrain)

    const { err, data } = await api.createCatalogStrain(body)
    if (err) return

    dispatch(pushNotification(`Successfully created strain ${catalogStrain.name}`, SUCCESS))
    dispatch(hideStrainForm())
    dispatch(addCatalogStrain(data))
  }
}

export function updateCatalogStrain (routerLocation) {
  return async (dispatch, getState) => {
    const state = getState()
    const { catalogStrain } = state
    const { id } = catalogStrain
    const body = transformFromCatalogStrain(catalogStrain)

    const { err, data } = await api.updateCatalogStrain({ ...body, id })
    if (err) return

    dispatch(pushNotification(`Successfully updated strain ${catalogStrain.name}`, SUCCESS))
    dispatch(hideStrainForm(routerLocation))
    dispatch(replaceCatalogStrain(data))
  }
}

export function deleteCatalogStrain (routerLocation) {
  return async (dispatch, getState) => {
    const state = getState()
    const { catalogStrain } = state
    const { id } = catalogStrain

    const { err } = await api.deleteCatalogStrain({ id })
    if (err) return

    dispatch(pushNotification(`Successfully deleted ${catalogStrain.name}`, SUCCESS))
    dispatch(removeCatalogStrain(catalogStrain))
    dispatch(hideStrainForm(routerLocation))
  }
}

// parser to format keys from the response to match what the redux store wants
export function transformToCatalogStrain (strain) {
  const { effects = [], species = {} } = strain
  return {
    id: strain.id || null,
    name: strain.name || '',
    description: strain.highlights || '',
    species: species.id || '',
    thc: strain.thc_content || 0,
    cbd: strain.cbd_content || 0,
    highCbd: strain.high_cbd || false,
    effects: times(5, (index) => effects[index] || { name: '', amount: '' }),
    images: strain.images || []
  }
}

// parser to format keys from the redux store to match what the response wants
export function transformFromCatalogStrain (strain) {
  return {
    id: strain.id,
    name: strain.name,
    highlights: strain.description,
    species_id: strain.species,
    thc_content: strain.thc,
    cbd_content: strain.cbd,
    high_cbd: strain.highCbd,
    effects: strain.effects,
    images: strain.images
  }
}

export function setCatalogStrain (strain) {
  return {
    type: SET_STRAIN,
    strain
  }
}

function setCatalogStrains (strains) {
  return {
    type: SET_CATALOG_STRAINS,
    strains
  }
}

export function addCatalogStrain (strain) {
  // get strains, add strain, update full list
  return (dispatch, getState) => {
    const state = getState()
    const { catalog: { strains } } = state

    dispatch(setCatalogStrains([strain, ...strains]))
  }
}

export function replaceCatalogStrain (strain) {
  // get strains, search for strain, replace, update full list
  return (dispatch, getState) => {
    const state = getState()
    const { catalog: { strains } } = state
    const newList = strains.map((strainItem) => strainItem.id === strain.id ? strain : strainItem)

    dispatch(setCatalogStrains(newList))
  }
}

export function removeCatalogStrain (strain) {
  // get strains, search for strain, remove, update full list
  return (dispatch, getState) => {
    const state = getState()
    const { catalog: { strains } } = state
    const strainsWithItemRemoved = strains.filter((strainItem) => strainItem.id !== strain.id)
    dispatch(setCatalogStrains([...strainsWithItemRemoved]))
  }
}

export function showStrainForm (strain) {
  return dispatch => {
    dispatch({ type: SHOW_STRAIN_FORM })
    const strainObject = strain || { effects: [] }
    const transformedStrain = transformToCatalogStrain(strainObject)
    dispatch(setCatalogStrain(transformedStrain))
    dispatch(hideActionBar())
  }
}

export function hideStrainForm (routerLocation) {
  return dispatch => {
    // clear out form on hide
    if (routerLocation) {
      clearFormQueryParams(routerLocation)
    }
    dispatch({ type: HIDE_STRAIN_FORM })
    const transformedStrain = transformToCatalogStrain({ effects: [] })
    dispatch(setCatalogStrain(transformedStrain))
  }
}
