// https://github.com/eaze/web-ui/blob/master/packages/tooltip/src/index.js
/**
 * Component to display a tooltip.
 */

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ToolTip from 'react-tooltip-portal'
import document from 'global/document'
import styled from '@emotion/styled'
import { ClassNames } from '@emotion/core'

import InfoIcon from './info-icon'

export default class Tooltip extends PureComponent {
  static propTypes = {
    contents: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    position: PropTypes.string,
    tooltipIconClass: PropTypes.string,
    tooltipIconStyle: PropTypes.object,
    tooltipClass: PropTypes.string,
    tooltipStyle: PropTypes.object,
    containerClass: PropTypes.string,
    containerStyle: PropTypes.object,
    hoverEvents: PropTypes.bool,
    hideOnInnerClick: PropTypes.bool
  }

  static defaultProps = {
    position: 'right',
    tooltipIconClass: '',
    tooltipIconStyle: {},
    tooltipClass: '',
    tooltipStyle: {},
    containerClass: '',
    containerStyle: {},
    hoverEvents: true
  }

  state = {
    isTooltipActive: false
  }

  listenForOutsideClick = () => {
    if (this.state.isTooltipActive) {
      this.toggleTooltipActive()
    }
  }

  toggleTooltipActive = () => {
    this.setState({ isTooltipActive: !this.state.isTooltipActive }, () => {
      if (!this.props.hoverEvents) {
        // this makes the tooltip more like a context menu, where if you click outside it will close
        // so if the tooltip is open we listen for the next click and then we close the menu and remove the listener
        if (this.state.isTooltipActive) {
          document.addEventListener('click', this.listenForOutsideClick)
        } else {
          document.removeEventListener('click', this.listenForOutsideClick)
        }
      }
    })
  }

  render () {
    const {
      contents,
      position,
      containerClass,
      containerStyle,
      tooltipIconClass,
      tooltipIconStyle,
      tooltipClass,
      tooltipStyle,
      hoverEvents,
      hideOnInnerClick
    } = this.props

    return (
      <ClassNames>
        {({ css }) => (
          <Container className={css(containerClass)} style={containerStyle}>
            <div
              className={css(tooltipIconClass)}
              style={tooltipIconStyle}
              onClick={this.toggleTooltipActive}
              onMouseEnter={() => hoverEvents && this.setState({ isTooltipActive: true })}
              onMouseLeave={() => hoverEvents && this.setState({ isTooltipActive: false })}
              ref={c => { this.tipRef = c }}
            >
              <InfoIcon />
            </div>
            <ToolTip
              active={this.state.isTooltipActive}
              position={position}
              parent={this.tipRef}
              hoverEvents={false}
            >
              <Tip
                className={css(tooltipClass)}
                style={tooltipStyle}
                onClick={() => hideOnInnerClick && this.toggleTooltipActive()}
              >
                {contents}
              </Tip>
            </ToolTip>
          </Container>
        )}
      </ClassNames>
    )
  }
}

const Tip = styled.div`
  background-color: #F4F2ED;
  color: #373838;
  box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.2);
  line-height: 1.5em;
  max-width: 25rem;
  margin: -0.5rem;
  padding: 0.5rem;
`

const Container = styled.div`
  text-shadow: 0 -0.6rem 0.5rem rgba(0, 0, 0, 0.3);
  border: none;
  height: 2rem;
  width: 2rem;
`
