import {
  DealTag,
  ExclusiveTag,
  ExpressTag, // lightening bolt
  FireTag,
  PriceTag,
  RocketTag,
  SparkleTag,
  StopwatchTag
} from 'microcomponents/icons/tags'

import { EAZE_ENVIRONMENT } from './environment'

import window from 'global/window'

const iOS = /iPad|iPhone|iPod/
const android = /android/i

const detectMobile = (regex) => regex.test(window.navigator ? window.navigator.userAgent : '')

export const environment = EAZE_ENVIRONMENT
export const isProduction = environment === 'production'
export const isLikeProduction = environment === 'production' || environment === 'staging' // we want staging to be as close to production as possible
export const isLocal = environment === 'local'
export const isMobile = detectMobile(iOS) || detectMobile(android)

export const QUERY_PARAM_STRINGS = {
  CATALOG_FORM_TYPE: 'catalogFormType',
  CATALOG_FORM_ID: 'catalogFormId'
}

export const FORM_STRING_TYPES = {
  TYPE: 'type',
  SUBTYPE: 'subtype',
  STRAIN: 'strain',
  PRODUCT: 'product'
}

export const SPECIES_CATALOG_TYPE = 'species'

export const CREDIT_CARD_PAYMENT_METHOD = 1

export const AVAILABLE_STATES = ['CA', 'MI']
export const DEFAULT_MENU_SLUG = 'default'
export const FIRE_MENU_SLUG = 'fire'
export const MENU_SLUGS = [DEFAULT_MENU_SLUG, FIRE_MENU_SLUG]
export const PRODUCT_FILTER_TYPE = 'product'
export const GROUP_FILTER_TYPE = 'group'
// type IDs (legacy_id)
export const FLOWERS_TYPE_ID = 1
export const PREROLLS_TYPE_ID = 2
export const EDIBLES_TYPE_ID = 3
export const CONCENTRATES_TYPE_ID = 4
export const ACCESSORIES_TYPE_ID = 5

export const TAG_MAP = {
  deal: DealTag,
  default: DealTag,
  exclusive: ExclusiveTag,
  express: ExpressTag,
  fire: FireTag,
  price: PriceTag,
  rocket: RocketTag,
  sparkle: SparkleTag,
  stopwatch: StopwatchTag
}

export const CASH_PAYMENT_METHOD = 0
export const CREDIT_PAYMENT_METHOD = 1
export const PRESENT_CREDIT_CARD_PAYMENT_METHOD = 2
export const PRESENT_DEBIT_CARD_PAYMENT_METHOD = 3
export const DEBIT_CASH_BACK_PAYMENT_METHOD = 4
export const ACH_PAYMENT_METHOD = 5
export const STORE_CREDITS_PAYMENT_METHOD = 9
export const PAYMENT_METHOD_TEXT_MAPPING = {
  0: 'CASH',
  2: 'CREDIT',
  3: 'DEBIT',
  4: 'DEBIT',
  5: 'ACH',
  6: 'DEBIT ONLINE',
  9: 'EAZE CREDIT'
}

export const SPLIT_EXPERIMENTS = {
  DISPENSARY_CASE: 'dispensary_case',
  DEPOT_SCHEDULED_DELIVERY: 'depot_scheduled_delivery'
}

export const SPLIT_TREATMENTS = {
  DISPENSARY_CASE: {
    ON: 'on',
    OFF: 'off'
  }
}
