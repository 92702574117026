/**
 * Email input
 * The validation is based on https://github.com/sindresorhus/email-regex
 */

import React from 'react'
import { bool, func, string } from 'prop-types'

import emailRegex from 'email-regex'

import InputWithLabel from './input-with-label'

class Email extends React.Component {
  constructor () {
    super()

    this.onChange = this.onChange.bind(this)
    this.validators = [
      // check for null if required
      (value) => {
        if (!this.props.required) return null
        if (!value) return 'Email is required'
      },
      // check for valid email entered
      (value) => {
        if (!value) return null
        const match = emailRegex({ exact: true }).test(value)
        return match ? null : 'Please enter a valid email'
      }
    ]
  }

  onChange (e) {
    this.context.onChange(this.props.name, e.target.value, this.inputContainer.input.isValid())
  }

  componentDidMount () {
    this.context.register(this.props.name)
  }

  componentWillUnmount () {
    this.context.deregister(this.props.name)
  }

  render () {
    return (
      <InputWithLabel
        {...this.props}
        name={this.props.name}
        type='email'
        validators={this.validators}
        onChange={this.onChange}
        ref={(inputContainer) => { this.inputContainer = inputContainer }}
        eagerValidate
      />
    )
  }
}

Email.contextTypes = {
  onChange: func,
  register: func,
  deregister: func
}

Email.propTypes = {
  name: string,
  placeholderName: string,
  required: bool
}

export default Email
