const Colors = {
  primary: {
    1: '#00AAE7',
    2: '#5CC9F0',
    3: '#66CCF1',
    4: '#99DDF5',
    5: '#E3F6FD'
  },
  cbd: {
    1: '#9360A8',
    2: '#A980B9',
    3: '#BEA0CB',
    4: '#D4BFDC',
    5: '#E9DFEE'
  },
  hybrid: {
    1: '#42BC97',
    2: '#68C9AC',
    3: '#8ED7C1',
    4: '#B3E4D5',
    5: '#D9F2EA'
  },
  sativa: {
    0: '#FFE400',
    1: '#FACA18',
    2: '#FBD546',
    3: '#FCDF74',
    4: '#FDEAA3',
    5: '#FEF4D1',
    6: '#EBB22D'
  },
  indica: {
    1: '#126CAB',
    2: '#4189BC',
    3: '#71A7CD',
    4: '#A0C4DD',
    5: '#D0E2EE'
  },
  danger: {
    1: '#F05B4B',
    2: '#F37C6F',
    3: '#F69D93',
    4: '#F9BDB7',
    5: '#FCDEDB',
    6: '#E02020'
  },
  accessory: {
    1: '#5C515A',
    2: '#817D80',
    3: '#D2CDCC',
    4: '#E9E3E1',
    5: '#FFF8F5'
  },
  rebrand: {
    1: '#181AC0'
  },
  homepageRedesign: {
    0: '#000000',
    1: '#FFE400',
    2: '#FFFFFF',
    3: '#0091FF',
    4: '#BBB4B8',
    5: '#00A6F0',
    6: '#FF0000'
  },
  search: {
    1: '#AFB0B4',
    2: '#FFE404'
  }
}

module.exports = {
  accessory1: Colors.accessory['1'],
  accessory2: Colors.accessory['2'],
  accessory3: Colors.accessory['3'],
  accessory4: Colors.accessory['4'],
  accessory5: Colors.accessory['5'],
  blazeUpBlack: '#5E5F5F',
  borderContainerLight: '#D0D0D0',
  colorTextDark: '#373838',
  danger5: Colors.danger['5'],
  darkDivider: '#404242',
  defaultBorderRadius: '0.34rem',
  eazeBlue: Colors.primary['1'],
  fire: '#B53553',
  fontSizeLabel: '1.2rem',
  fontSizeParagraph: '1.6rem',
  indica1: Colors.indica['1'],
  labelFontSize: '1.2rem',
  letterSpacingLabel: '0.04rem',
  lightFire: '#F7EAED',
  lightGray: '#C3CDD6',
  marginFormElementsVertical: '0.5rem',
  mcBlue: Colors.primary['2'],
  mcGreen: Colors.hybrid['1'],
  mcLightBlue: Colors.indica['5'],
  mcRed: Colors.danger['1'],
  mcYellow: Colors.sativa['1'],
  midGray: '#948A91',
  paddingContainer: '1rem',
  primary2: Colors.primary['2'],
  primary5: Colors.primary['5'],
  primaryDark: '#313232',
  secondaryDark: '#373838',
  softBlack: '#333',
  tertiaryDark: '#494B4B',
  white: '#FFF'
}
