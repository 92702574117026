import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import Screen from 'src/components/screen'
import Form from 'microcomponents/input/form'
import EmailInput from 'microcomponents/input/email'
import PasswordInput from 'microcomponents/input/password'
import { secondaryDark } from 'src/helpers/css-variables'
import history from 'components/router/history'
import ROUTES from 'src/components/router/routes'
import { bool, func } from 'prop-types'

export default class Login extends PureComponent {
  static propTypes = {
    isLoggedIn: bool,
    requestLogin: func
  }

  componentDidMount () {
    if (this.props.isLoggedIn) {
      history.push(ROUTES.USERS)
    }
  }

  render () {
    return (
      <Screen>
        <LoginContainer>
          <LoginInputsContainer>
            <Form
              onSubmit={this.props.requestLogin}
              submitText='Login'
            >
              <EmailInput
                name='email'
                placeholder='Email'
                eagerValidate
                required
              />
              <PasswordInput
                name='password'
                placeholder='Password'
                type='password'
                eagerValidate
                required
              />
            </Form>
          </LoginInputsContainer>
        </LoginContainer>
      </Screen>
    )
  }
}

const LoginContainer = styled.div`
  width: 32.5rem;
  margin: 10rem auto 0;

  input[type=email],
  input[type=password] {
    background-color: white !important;
    font-size: 1.6rem;
  }
`

const LoginInputsContainer = styled.div`
  background: ${secondaryDark};
  padding: 3rem;
  border-radius: 0.4rem;
`
