import React, { PureComponent } from 'react'
import { array, bool, func, object, shape, string } from 'prop-types'
import { DragDropContext } from 'react-beautiful-dnd'
import { Flex, Column, Row } from 'src/microcomponents/flexbox-helpers'
import debounce from 'debounce'
import styled from '@emotion/styled'
import { css } from 'emotion'
import Button, { HYBRID } from 'components/button'
import ColorPicker from 'components/color-picker'
import LoadingSpinner from 'components/spinner'

import Switch from 'src/microcomponents/switch'
import Screen from 'src/components/screen/container'
import BackButton from 'src/components/back-button'
import ItemsSearch from 'src/microcomponents/items-search'
import List from 'src/components/draggable/list'
import UploadFile from 'microcomponents/upload-file'
import PhoneShell from 'assets/phone-shell.png'
import TabBar from 'microcomponents/tab-bar'
import GroupSettingsTab from './tab-settings'
import AutoAddView from './auto-add-view'
import uploadImageSvg from 'src/assets/upload-image.svg'
import McButton from 'microcomponents/mc-button'
import { mcGreen, secondaryDark } from 'helpers/css-variables'

import {
  centerContainer,
  content,
  descriptionClass,
  desktopImageRow,
  groupList,
  groupMenuColor,
  groupMenuColorFont,
  groupOverflowContainer,
  iconImage,
  infoContainer,
  item,
  leftBar,
  main,
  menuSlugInput,
  mobileImg,
  mobilePreviewButton,
  overflowContainer,
  phoneContent,
  phoneDescription,
  reveal,
  rightBarForm,
  switchFormContainer,
  topGroupInfo,
  topInfo,
  title
} from '../edit-page-styles.js'

const TABS = {
  GROUP: 'GROUP',
  SETTINGS: 'SETTINGS'
}

export default class EditGroup extends PureComponent {
  static propTypes = {
    activeDepotsByState: object,
    brandsMap: object,
    createOrUpdateGroup: func,
    fetchCatalog: func,
    setGroupItemsByFilter: func,
    loading: bool,
    groupItem: shape({
      description: string,
      name: string,
      slug: string,
      auto_add: bool,
      filters: object,
      /* eslint-disable camelcase */
      depots_enabled: array,
      desktop_image: string,
      desktop_inline_image: string,
      icon: string,
      mobile_image: string,
      mobile_inline_image: string,
      manual_sort: bool
      /* eslint-enable camelcase */
    }),
    items: object, // ! USE THIS AS COLUMNS
    resetGroupItem: func,
    searchCatalog: func,
    setCatalogStateFilter: func,
    sortedItems: func,
    speciesMap: object,
    stateId: string,
    statesAvailable: array,
    updateGroupObject: func,
    uploadPublicFile: func,

    subtypes: array,
    types: array,
    brands: array
  }

  state = {
    name: '',
    internal_name: '',
    slug: '',
    depotsEnabled: [],
    description: '',
    hasSetDepotsEnabled: false,
    currentTab: TABS.GROUP,
    tabs: [TABS.GROUP, TABS.SETTINGS],
    desktop_image: '',
    icon: '',
    theme: '',
    showColorPicker: false, // TODO: Refactor, adding this to local state makes it's way into redux layer and must be pruned, if we start with minimum set we don't need this unnecessary data
    mobile_image: '',
    desktop_inline_image: '',
    mobile_inline_image: '',
    manual_sort: false,
    state_id: '',
    openMobilePreview: true,
    auto_add: true,
    filters: {},
    saveAllowed: false,
  }

  componentWillUnmount () {
    this.props.resetGroupItem()
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps (nextProps) {
    const {
      activeDepotsByState,
      groupItem: {
        description,
        name,
        slug,
        /* eslint-disable camelcase */
        depots_enabled: depotsConfirmed,
        desktop_image,
        internal_name,
        auto_add,
        filters,
        icon,
        theme,
        mobile_image,
        desktop_inline_image,
        mobile_inline_image,
        manual_sort,
        state_id
        /* eslint-enable camelcase */
      }
    } = nextProps

    let hasSetDepotsEnabled = this.state.hasSetDepotsEnabled
    let depotsEnabled = [...this.state.depotsEnabled]

    const hasActiveDepots = Object.keys(activeDepotsByState).length !== 0

    if (!depotsEnabled.length && hasActiveDepots) {
      // massage the data into something more convenient
      const entryToDepotList = ([state, depots]) => depots.map((depot) => simplifyDepot(state, depot))
      const simplifyDepot = (state, depotObj) => ({ id: depotObj.id, name: depotObj.name, state, enabled: false })

      depotsEnabled = Object.entries(activeDepotsByState).map(entryToDepotList).flat()
    }

    if (depotsConfirmed && hasActiveDepots && !hasSetDepotsEnabled) {
      depotsConfirmed.forEach(function (id) {
        const depotObj = depotsEnabled.find((depotObj) => depotObj.id === id)
        if (depotObj) {
          depotObj.enabled = true
        }
      })

      hasSetDepotsEnabled = true
    }

    this.setState({
      depotsEnabled,
      description,
      desktop_image,
      desktop_inline_image,
      hasSetDepotsEnabled,
      icon,
      internal_name,
      manual_sort,
      mobile_image,
      mobile_inline_image,
      name,
      slug,
      auto_add,
      filters,
      state_id,
      theme
    })
  }

  saveFormValue = (value, key) => {
    this.setState({ [key]: value })
    this.updateGroupStore()

    if (key === 'state_id') {
      this.updateStateFilter()
    }
  }

  updateStateFilter = debounce(() => {
    this.props.setCatalogStateFilter(this.state.state_id)
    this.props.fetchCatalog()
  }, 200)

  handleColorChange = (colorHex) => {
    this.setState({ showColorPicker: false })
    this.saveFormValue(colorHex, 'theme')
  }

  setFilters = (filters) => {
    this.setState({ filters }, this.updateGroupStore)
  }

  setSaveAllowed = (value) => {
    this.setState({saveAllowed: value})
  }

  updateGroupStore = debounce(() => {
    const data = { ...this.state }
    delete data.openMobilePreview
    this.props.updateGroupObject(data)
  }, 75)

  toggleDepotEnabled = (depotId) => {
    return () => {
      const { depotsEnabled } = this.state
      const newDepotsEnabled = [...depotsEnabled]
      const depotToUpdate = newDepotsEnabled.find(depot => depot.id === depotId)
      depotToUpdate.enabled = !depotToUpdate.enabled
      this.setState({ depotsEnabled: newDepotsEnabled }, this.updateGroupStore)
    }
  }

  setAllRelevantDepotsEnabled = (enabled) => {
    const { depotsEnabled } = this.state
    const newDepotsEnabled = [...depotsEnabled].map((depot) => ({
      ...depot,
      enabled: this.isDepotRelevant(depot) ? enabled : depot.enabled
    }))
    this.setState({ depotsEnabled: newDepotsEnabled }, this.updateGroupStore)
  }

  isDepotRelevant = (depot) => {
    const { state_id: state } = this.state
    return depot.enabled || depot.state === state
  }

  openMobilePreview = () => {
    this.setState({ openMobilePreview: !this.state.openMobilePreview })
  }

  handleOnDragEnd = (result) => {
    const { items, sortedItems } = this.props
    const { draggableId, source, destination } = result

    // item didn't move anywhere
    if (!destination) return

    // item dropped in same position as starting position
    if (destination.droppableId === source.droppableId && destination.index === source.index) return

    // move item from old index to new index
    const start = source.droppableId
    const finish = destination.droppableId

    // the sidebar (i.e. `products`) is auto-sorted in the selector so any manual sorting is discarded anyway
    if (start === 'products' && finish === 'products') return

    let newState = {
      ...items
    }

    // reorder in 1 column
    if (start === finish) {
      const updatedItems = [...items[start]]
      const movedItem = updatedItems.find(item => item.id === draggableId)
      updatedItems.splice(source.index, 1)
      updatedItems.splice(destination.index, 0, movedItem)

      newState = {
        ...newState,
        [finish]: updatedItems
      }
    } else {
      // move between lists
      const updatedStartItems = [...items[start]]
      const movedStartItem = updatedStartItems.find(item => item.id === draggableId)
      updatedStartItems.splice(source.index, 1)

      const updatedFinishItems = [...items[finish]]
      updatedFinishItems.splice(destination.index, 0, movedStartItem)

      newState = {
        [start]: updatedStartItems,
        [finish]: updatedFinishItems
      }
    }

    sortedItems(newState.groupItems)
  }

  setGroupItemsByFilter = (filters) => {
    const { setGroupItemsByFilter } = this.props
    setGroupItemsByFilter(filters)
  }

  toggleAutoAdd = () => {
    const { auto_add: autoAdd } = this.state
    // auto add is on
    if (autoAdd) {
      // turn off auto add
      this.setState({
        auto_add: false,
        filters: {}
      })
    } else {
      // turn on auto add
      this.setState({
        auto_add: true,
        manual_sort: false,
        filters: {}
      })
    }
    this.updateGroupStore()
  }

  toggleAutoSort = () => {
    const { manual_sort: manualSort } = this.state
    const autoSort = !manualSort
    // auto sort is on
    if (autoSort) {
      // turn off auto sort
      this.setState({
        auto_add: false,
        manual_sort: true,
        filters: {}
      })
    } else {
      // turn on auto sort
      this.setState({
        manual_sort: false
      })
    }
    this.updateGroupStore()
  }

  render () {
    const {
      brandsMap,
      createOrUpdateGroup,
      fetchCatalog,
      items,
      loading,
      searchCatalog,
      speciesMap,
      stateId,
      statesAvailable,
      uploadPublicFile,
      subtypes,
      types,
      brands
    } = this.props

    // TODO: This could be refactored to not require this translation from snake case to camel case
    // This made it very confusing trying to apply the upload desktop image button pattern which should have been very straightforward
    const {
      auto_add: autoAdd,
      filters,
      currentTab,
      depotsEnabled,
      description,
      desktop_image: desktopImage,
      desktop_inline_image: desktopInlineImage,
      icon,
      internal_name: displayName,
      manual_sort: manualSort,
      mobile_image: mobileImage,
      mobile_inline_image: mobileInlineImage,
      name,
      showColorPicker,
      slug,
      tabs,
      theme,
      openMobilePreview
    } = this.state

    const isOnSettingsTab = currentTab === TABS.SETTINGS
    const noOverflowOnSettingsTab = isOnSettingsTab ? '' : 'auto'
    const showsMobilePreview = Boolean(mobileImage) && !isOnSettingsTab
    const saveGroup = autoAdd && (!this.state.saveAllowed) ? true : false

    const searchCatalogWithState = (query) => {
      searchCatalog(query, stateId)
    }

    return (
      <Screen>
        <Column className={main}>
          <Row full>

            <Column className={leftBar}>
              <BackButton path='/catalog/groups' />
            </Column>

            <DragDropContext onDragEnd={this.handleOnDragEnd}>
              <Row full className={reveal}>

                <Flex
                  full top={1}
                  componentStyle={{
                    height: '100vh',
                    overflow: noOverflowOnSettingsTab
                  }}
                >
                  <MobileImagePreviewClass show={showsMobilePreview} open={openMobilePreview}>
                    <div className={phoneContent}>
                      <MobileButton
                        onClick={() => this.openMobilePreview()}
                      >
                        Hide
                      </MobileButton>
                      <img src={mobileImage} className={mobileImg} />
                      <span className={phoneDescription}>{description}</span>
                    </div>
                  </MobileImagePreviewClass>

                  <Column
                    className={centerContainer}
                    componentStyle={{ margin: '0 auto' }}
                  >
                    <Row align='center' justify='space-between'>
                      <TabBar tabs={tabs} onClick={(tab) => this.saveFormValue(tab, 'currentTab')} currentTab={currentTab} />
                      <StyledButton onClick={createOrUpdateGroup} type={HYBRID} disabled={saveGroup}>Save Group</StyledButton>
                    </Row>

                    <Row align='center' justify='space-between' className={topInfo} top={1}>
                      <input
                        className={title}
                        onChange={event => this.saveFormValue(event.target.value, 'internal_name')}
                        value={displayName || ''}
                        placeholder='Group Internal Name'
                      />

                      <span className={menuSlugInput}>
                        <label htmlFor='slug'>www.eaze.com/groups/</label>
                        <input
                          id='slug'
                          className={descriptionClass}
                          onChange={event => this.saveFormValue(event.target.value, 'slug')}
                          value={slug || ''}
                          style={{ paddingLeft: 0 }}
                          placeholder='group-slug'
                        />
                      </span>
                    </Row>

                    <Column className={content} top={1} full>
                      {currentTab === TABS.GROUP &&
                        (<MainGroupContainer>
                          {!loading &&
                            <div className={topGroupInfo}>
                              <Column className={infoContainer}>

                                <HeaderImage desktopImage={desktopImage} />
                                <Column componentStyle={{ padding: '1rem', zIndex: 1 }}>
                                  <div className={desktopImageRow}>
                                    <UploadFile
                                      postUpload={({ downloadUrl }) => this.saveFormValue(downloadUrl, 'icon')}
                                      uploadFile={uploadPublicFile}
                                    >
                                      <UploadButton>
                                        <img style={{ height: '3rem', width: '3rem' }} src={uploadImageSvg} />
                                        Icon Image
                                      </UploadButton>
                                    </UploadFile>

                                    {icon ? <img className={iconImage} src={icon} /> : null}

                                    <div className={groupMenuColor}>
                                      <McButton onClick={() => this.setState({ showColorPicker: true })}>
                                        <span className={groupMenuColorFont}>Group Color</span>
                                        <ColorPicker color={theme} showColorPicker={showColorPicker} onColorChange={this.handleColorChange} />
                                      </McButton>
                                    </div>
                                    <MobileUploadContainer>
                                      <UploadFile
                                        postUpload={({ downloadUrl }) => this.saveFormValue(downloadUrl, 'mobile_image')}
                                        uploadFile={uploadPublicFile}
                                      >
                                        <UploadButton>
                                          <img style={{ height: '2rem', width: '3rem' }} src={uploadImageSvg} />
                                          Mobile Image
                                        </UploadButton>
                                      </UploadFile>
                                    </MobileUploadContainer>
                                    <DesktopImageButtonWrapper>
                                      <UploadFile
                                        postUpload={({ downloadUrl }) => this.saveFormValue(downloadUrl, 'desktop_image')}
                                        uploadFile={uploadPublicFile}
                                      >
                                        <UploadButton>
                                          <img style={{ height: '3rem', width: '3rem' }} src={uploadImageSvg} />
                                          Desktop Image
                                        </UploadButton>
                                      </UploadFile>
                                    </DesktopImageButtonWrapper>
                                  </div>

                                  <input
                                    className={title}
                                    onChange={event => this.saveFormValue(event.target.value, 'name')}
                                    value={name || ''}
                                    placeholder='Group name'
                                  />

                                  <textarea
                                    className={descriptionClass}
                                    maxLength='200'
                                    onChange={event => this.saveFormValue(event.target.value, 'description')}
                                    placeholder='Group description'
                                    value={description}
                                  />
                                </Column>
                                <Column top={1} componentStyle={{ padding: '1rem', zIndex: 1 }}>
                                  <Row>
                                    {showsMobilePreview && (
                                      <div className={mobilePreviewButton}>
                                        <Switch
                                          enabled={openMobilePreview}
                                          onClick={() => this.openMobilePreview()}
                                        />
                                        Show Mobile Preview
                                      </div>
                                    )}
                                    <div style = {{ display: 'inline-block', marginLeft: 'auto' }}>
                                      <span
                                        style={{ width: showsMobilePreview ? '50%' : '100%' }}
                                        onClick={this.toggleAutoAdd}
                                      >
                                        <SortToggleLabel>Auto Add</SortToggleLabel>
                                        <Switch enabled={autoAdd} />
                                      </span>
                                      <span
                                        className={switchFormContainer}
                                        style={{ width: showsMobilePreview ? '50%' : '100%', marginLeft: '25px' }}
                                        onClick={this.toggleAutoSort}
                                      >
                                        <SortToggleLabel>Auto Sort</SortToggleLabel>
                                        <Switch enabled={!manualSort} />
                                      </span>
                                    </div>
                                  </Row>
                                </Column>
                              </Column>
                            </div>}
                          {loading
                            ? <LoadingSpinner />
                            : <div className={groupOverflowContainer}>
                              <List
                                brandsMap={brandsMap}
                                className={GroupList}
                                emptyListText='Products that are associated with GROUP will show up here.'
                                itemClassName={item}
                                items={items.groupItems}
                                listName='groupItems'
                                narrow
                                speciesMap={speciesMap}
                              />
                            </div>}
                        </MainGroupContainer>)}
                        {isOnSettingsTab &&
                          <GroupSettingsTab
                            depotsEnabled={depotsEnabled.filter(this.isDepotRelevant)}
                            desktopInlineImage={desktopInlineImage}
                            mobileInlineImage={mobileInlineImage}
                            saveFormValue={this.saveFormValue}
                            setAllDepotsEnabled={this.setAllRelevantDepotsEnabled}
                            toggleDepotEnabled={this.toggleDepotEnabled}
                            uploadPublicFile={uploadPublicFile}
                            statesAvailable={statesAvailable}
                            stateId={stateId}
                          />}
                    </Column>
                  </Column>
                </Flex>
                <Sidebar hide={isOnSettingsTab || autoAdd}>
                  <Column className={overflowContainer}>
                    <ItemsSearch
                      formClassName={rightBarForm}
                      placeholder='Search Products'
                      resetAction={fetchCatalog}
                      searchAction={searchCatalogWithState}
                    />

                    <List
                      className={groupList}
                      itemClassName={item}
                      items={items.products}
                      listName='products'
                    />
                  </Column>
                </Sidebar>
                <AutoAddContainer className='AutoAddView' hide={isOnSettingsTab || !autoAdd}>
                  <TitleRow>
                      <Title>Group Rules</Title>
                      <CloseButton onClick={this.toggleAutoAdd}>
                        &#10005;
                      </CloseButton>
                    </TitleRow>
                    <AutoAddView
                      types={types}
                      subtypes={subtypes}
                      brands={brands}
                      filters={filters}
                      setFilters={this.setFilters}
                      statesAvailable={statesAvailable}
                      setGroupItemsByFilter={this.setGroupItemsByFilter}
                      setSaveAllowed={this.setSaveAllowed}
                    />
                </AutoAddContainer>
              </Row>
            </DragDropContext>
          </Row>
        </Column>
      </Screen>
    )
  }
}

const MobileImagePreviewClass = styled.div`
  background-image: url(${PhoneShell});
  background-repeat: no-repeat;
  background-size: cover;
  bottom: ${({ open }) => open ? '0' : '-300px'};
  display: ${({ show }) => !show ? 'none' : ''};
  height: 30rem;
  left: 5rem;
  position: fixed;
  width: 30rem;
  z-index: 10;
`
const Sidebar = styled.div`
  box-shadow: 0 0 3rem rgba(0,0,0,.5);
  flex-shrink: 0;
  height: 100vh;
  width: 35rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
  visibility: ${({ hide }) => hide ? 'hidden' : ''};
`
const AutoAddContainer = styled.div`
  box-shadow: 0 0 2.4rem rgba(0,0,0,.5);
  padding: 2.4rem 2.4rem;
  flex-shrink: 0;
  height: 100vh;
  width: 27%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: absolute;
  right: 0px;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: ${secondaryDark};
  z-index: 20;
  visibility: ${({ hide }) => hide ? 'hidden' : ''};
`

const MobileUploadContainer = styled.div`
  margin-left: auto;
`

const UploadButton = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 0.5rem;
  color: ${mcGreen};
  display: flex;
  height: 5rem;
  justify-content: space-evenly;
  right: 1rem;
  width: 15rem;
`

const MobileButton = styled(Button)`
  position: relative;
  bottom: 66px;
  width: 30%;
  margin: 0 auto;
  display: block;
  padding: 5px;
  background-color: ${secondaryDark};
`

const CloseButton = styled.button`
  margin-right: 3rem;
  background-color: transparent;
  color: white;
  border: 0;
  cursor: pointer;
  font-size: 20px;
`

const MainGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`

const SortToggleLabel = styled.span`
  margin-right: 1rem;
`

const DesktopImageButtonWrapper = styled.div`
  margin-left: 2rem;
`

const StyledButton = styled(Button)`
  font-family: inherit;
  width: 15rem;
`

const HeaderImage = styled.div`
  background-image: url(${({ desktopImage }) => desktopImage || ''});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  max-width: 100%;
  opacity: 0.25;
  position: absolute;
  width: 100%;
  z-index: 1;
`

// magic number: 30px here is twice the padding height (top and bottom)
const GroupList = css`
  padding: 0 15px;
  height: calc(100% - 30px);
`

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.6rem;
  margin-top: 3.6rem;
`

const Title = styled.h1`
  margin: 0;
  letter-spacing: 0.1rem;
`
