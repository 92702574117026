import t from './actionTypes'
import { requestPromos, receivePromos, requestTogglePromo, receiveTogglePromo } from '../loading/actions'
import { getApiPayload, getPromoValidity } from '../manage-promos/selectors.js'
import * as mp from '../manage-promos/actions'
import api from 'api'
import errorHandler from 'helpers/error-handler'

export function fetchPromos () {
  return async dispatch => {
    dispatch(requestPromos())
    // Backend defaults to 100, for now we want to show all promos until we can implement a search/conflict alerts for promo creation.
    // As of now there are ~4000 promo codes
    // As of 7/10/19, there are 5,230
    const { err, data } = await api.getPromos({ start: 0, limit: 10000 })
    dispatch(receivePromos())
    if (err) return

    dispatch(setPromos(data))
  }
}

export function setPromos (promos) {
  return {
    type: t.SET_PROMOS,
    promos
  }
}

export function toggleManageBar () {
  return (dispatch, getState) => {
    dispatch({ type: t.TOGGLE_MANAGE_BAR })
    const state = getState()

    // make sure we're not editing promos if we close the manageBar
    if (!state.promos.manageBar) {
      dispatch(setEditPromo(false))
    }
  }
}

export function showPromoPreview (value) {
  return {
    type: t.TOGGLE_PROMO_PREVIEW,
    value
  }
}

export function composePromo () {
  return dispatch => {
    dispatch(mp.resetManagePromoState())
    dispatch(setClonePromo(false))
    dispatch(showPromoPreview(false))
    dispatch(toggleManageBar())
  }
}

export function createOrUpdatePromo () {
  return (dispatch, getState) => {
    const state = getState()
    const { promos } = state

    if (!getPromoValidity(state).status) return

    const payload = getApiPayload(state)

    dispatch(requestPromos())

    if (promos.editPromo) {
      dispatch(updatePromo(payload))
      dispatch(setEditPromo(false))
    } else {
      dispatch(createPromo(payload))
    }
  }
}

export function createPromo (body) {
  return async (dispatch, getState) => {
    const { err } = await api.postPromo(body)
    dispatch(receivePromos())
    if (err) {
      errorHandler(err)
      return console.error(err)
    }

    dispatch(mp.resetManagePromoState())
    dispatch(fetchPromos())
    dispatch(toggleManageBar())
  }
}

function updatePromo (body) {
  return async (dispatch, getState) => {
    const { managePromos } = getState()
    const { err } = await api.updatePromo({
      ...body,
      id: managePromos.id
    })
    dispatch(receiveTogglePromo())

    if (err) {
      errorHandler(err)
      return console.error(err)
    }

    dispatch(fetchPromos())
  }
}

export function editPromo () {
  return (dispatch, getState) => {
    dispatch(setEditPromo(true))
    dispatch(showPromoPreview(false))
  }
}

function setEditPromo (edit) {
  return {
    type: t.SET_EDIT_PROMO,
    payload: edit
  }
}

export function togglePromo (row) {
  return async dispatch => {
    dispatch(requestTogglePromo())
    const { err } = await api.updatePromo({
      ...row,
      enabled: !row.enabled
    })
    dispatch(receiveTogglePromo())
    if (err) return
    dispatch(fetchPromos())
  }
}

function setClonePromo (value) {
  return {
    type: t.SET_PROMO_CLONEABLE,
    value
  }
}

export function selectRow (row) {
  return (dispatch, getState) => {
    dispatch(setClonePromo(true))
    dispatch(showPromoPreview(true))
    dispatch(toggleManageBar())

    dispatch(mp.setPromoToEdit(row))

    // catalog based promo
    if (row.rules.applies_to === 'id') {
      if (row.rules.operators.id_type === 'item') {
        dispatch(mp.getCatalogItem(row.rules.operators.id_value))
      } else if (row.rules.operators.id_type === 'brand') {
        dispatch(mp.getCatalogBrand(row.rules.operators.id_value))
      } else if (row.rules.operators.id_type === 'group') {
        dispatch(mp.getCatalogGroup(row.rules.operators.id_value))
      }
    }

    if (row.rules.promo_type === 'bogo') {
      // get BUY info
      if (row.rules.operators.buy.id_type === 'item') {
        dispatch(mp.getCatalogItem(row.rules.operators.buy.id_value, 'buy'))
      } else if (row.rules.operators.buy.id_type === 'brand') {
        dispatch(mp.getCatalogBrand(row.rules.operators.buy.id_value, 'buy'))
      } else if (row.rules.operators.buy.id_type === 'group') {
        dispatch(mp.getCatalogGroup(row.rules.operators.buy.id_value, 'buy'))
      }

      // get GET info
      if (row.rules.operators.get.id_type === 'item') {
        dispatch(mp.getCatalogItem(row.rules.operators.get.id_value, 'get'))
      } else if (row.rules.operators.get.id_type === 'brand') {
        dispatch(mp.getCatalogBrand(row.rules.operators.get.id_value, 'get'))
      } else if (row.rules.operators.get.id_type === 'group') {
        dispatch(mp.getCatalogGroup(row.rules.operators.get.id_value, 'get'))
      }
    }
  }
}

export function clonePromo () {
  return dispatch => {
    dispatch(setClonePromo(false))
    dispatch(showPromoPreview(false))
    dispatch(mp.setPromoCode(''))
  }
}

export function setSearchQuery (searchQuery) {
  return {
    type: t.SET_SEARCH_QUERY,
    value: searchQuery
  }
}
