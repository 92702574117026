import t from './actionTypes'
import api from 'api'
import errorHandler from 'helpers/error-handler'

export function fetchPlace (placeId, callback = function () {}) {
  return async (dispatch) => {
    const { err, data } = await api.getPlaces({ placeId })
    // only set exact on place object if there is no error
    if (!err) {
      data.exact = data.isStreetAddr
    }
    callback(err, data)
    if (err) {
      errorHandler(err.message || err)
      return console.error(err.message || err)
    }

    dispatch(setActivePlace(data))
    dispatch(updateHistory(data))
  }
}

export function setActivePlace (activePlace) {
  return {
    type: t.SET_ACTIVE_PLACE,
    payload: activePlace
  }
}

export function updateHistory (activePlace) {
  return (dispatch, getState) => {
    const state = getState()
    const { place: { history } } = state
    const newHistory = [ activePlace, ...history ]

    dispatch(setHistory(newHistory))
  }
}

export function setHistory (history) {
  return {
    type: t.SET_PLACE_HISTORY,
    payload: history
  }
}

export function clearPlaceStore () {
  return {
    type: t.CLEAR_PLACE_STORE
  }
}
