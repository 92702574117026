import { css } from 'emotion'
import { colorTextDark, white } from 'helpers/css-variables'

export const container = css`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const userOrderCard = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
  color: ${colorTextDark});
  background-color: ${white});
  padding: 3rem;
  border-radius: 8px;
  max-width: 30rem;
  height: 32.5rem;
`

export const userOrderLink = css`
  text-decoration: none;
`

export const phoneImage = css`
  flex: 1 1 auto;
`
