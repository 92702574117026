import React, { PureComponent } from 'react'
import { bool, func, number, string } from 'prop-types'

import styled from '@emotion/styled'

export default class LoadingNavigation extends PureComponent {
  static propTypes = {
    children: string,
    customClass: string,
    handleClick: func,
    isLoading: bool.isRequired,
    pollFrequency: number,
    shouldResetLoading: bool,
    widthLoadingTransition: string,
    widthMax: number,
    widthMin: number,
    widthStepDivisor: number
  }

  static defaultProps = {
    pollFrequency: 100,
    widthLoadingTransition: 'width 0.25s ease-in',
    widthMax: 100,
    widthMin: 0,
    widthStepDivisor: 2
  }

  state = {
    pollId: 0,
    width: 0
  }

  componentDidMount () {
    const { widthMax } = this.props
    this.startWidthLoading(widthMax)
  }

  componentWillUnmount () {
    this.stopWidthLoading()
  }

  componentDidUpdate (prevProps, prevState) {
    const { isLoading, shouldResetLoading, widthMax, widthMin } = this.props

    if (!prevProps.shouldResetLoading && shouldResetLoading) {
      this.stopWidthLoading(widthMin)
      this.startWidthLoading(widthMax)
    }

    if (prevProps.isLoading && !isLoading) {
      this.stopWidthLoading(widthMax)
    }

    if (!prevProps.isLoading && isLoading) {
      this.stopWidthLoading(widthMin)
      this.startWidthLoading(widthMax)
    }
  }

  startWidthLoading = (maximumWidth) => {
    const { pollFrequency } = this.props
    const pollId = setInterval(() => {
      const { width } = this.state
      const calculatedWidth = this.calculateWidth(width, maximumWidth)
      this.setWidthLoading(calculatedWidth)
    }, pollFrequency)

    this.setState({ pollId })
  }

  stopWidthLoading = (width) => {
    const { pollId } = this.state
    clearInterval(pollId)

    if (width || width === 0) {
      this.setWidthLoading(width)
    }
  }

  setWidthLoading = (width) => {
    this.setState({ width })
  }

  calculateWidth = (width, maximumWidth) => {
    const { widthStepDivisor } = this.props
    // step is remaining width divided by widthStepDivisor so we never quite get to the end
    const widthStep = (maximumWidth - width) / widthStepDivisor
    return width + widthStep
  }

  render () {
    const { width } = this.state
    const {
      children,
      customClass,
      handleClick,
      widthLoadingTransition,
      widthMin
    } = this.props
    const transition = width === widthMin ? 'none' : widthLoadingTransition

    return (
      <NavigationLink
        className={customClass}
        onClick={handleClick}
        transition={transition}
        width={width}
      >
        { children }
      </NavigationLink>
    )
  }
}

const NavigationLink = styled.a`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: ${({ width }) => `${width}%`};
    transition:${({ transition }) => transition};
  }
`
