import t from './actionTypes'

const defaultState = {
  login: false,
  users: false,
  dispensaries: false,
  driver: false,
  drivers: false,
  promos: false,
  togglePromo: false,
  catalog: false,
  species: false,
  group: false,
  brand: false,
  menus: false,
  user: false,
  menu: false,
  catalogBuilderEta: false,
  catalogBuilderQuote: false,
  adultUseTerritories: false,
  tags: false,
  tagTypes: false,
  inventory: false,
  deliveryFees: false
}

const loading = (state = defaultState, action) => {
  switch (action.type) {
    case t.REQUEST_LOGIN:
      return {
        ...state,
        login: true
      }

    case t.RECEIVE_LOGIN:
      return {
        ...state,
        login: false
      }

    case t.REQUEST_USERS:
      return {
        ...state,
        users: true
      }

    case t.RECEIVE_USERS:
      return {
        ...state,
        users: false
      }

    case t.REQUEST_DISPENSARIES:
      return {
        ...state,
        dispensaries: true
      }

    case t.RECEIVE_DISPENSARIES:
      return {
        ...state,
        dispensaries: false
      }

    case t.REQUEST_DRIVER:
      return {
        ...state,
        driver: true
      }

    case t.RECEIVE_DRIVER:
      return {
        ...state,
        driver: false
      }

    case t.REQUEST_DRIVERS:
      return {
        ...state,
        drivers: true
      }

    case t.RECEIVE_DRIVERS:
      return {
        ...state,
        drivers: false
      }

    case t.REQUEST_PROMOS:
      return {
        ...state,
        promos: true
      }

    case t.RECEIVE_PROMOS:
      return {
        ...state,
        promos: false
      }

    case t.REQUEST_TOGGLE_PROMO:
      return {
        ...state,
        togglePromo: true
      }

    case t.RECEIVE_TOGGLE_PROMO:
      return {
        ...state,
        togglePromo: false
      }

    case t.REQUEST_CATALOG_ITEM:
      return {
        ...state,
        catalog: true
      }

    case t.RECEIVE_CATALOG_ITEM:
      return {
        ...state,
        catalog: false
      }

    case t.REQUEST_CATALOG:
      return {
        ...state,
        catalog: true
      }

    case t.RECEIVE_CATALOG:
      return {
        ...state,
        catalog: false
      }

    case t.REQUEST_SPECIES:
      return {
        ...state,
        species: true
      }

    case t.RECEIVE_SPECIES:
      return {
        ...state,
        species: false
      }

    case t.REQUEST_PRODUCTS:
      return {
        ...state,
        products: true
      }

    case t.RECEIVE_PRODUCTS:
      return {
        ...state,
        products: false
      }

    case t.REQUEST_CATALOG_GROUP:
      return {
        ...state,
        group: true
      }

    case t.RECEIVE_CATALOG_GROUP:
      return {
        ...state,
        group: false
      }

    case t.REQUEST_CATALOG_BRAND:
      return {
        ...state,
        brand: true
      }

    case t.RECEIVE_CATALOG_BRAND:
      return {
        ...state,
        brand: false
      }

    case t.REQUEST_CATALOG_MENU:
      return {
        ...state,
        menus: true
      }

    case t.RECEIVE_CATALOG_MENU:
      return {
        ...state,
        menus: false
      }

    case t.REQUEST_USER:
      return {
        ...state,
        user: true
      }

    case t.RECEIVE_USER:
      return {
        ...state,
        user: false
      }

    case t.REQUEST_MENU:
      return {
        ...state,
        menu: true
      }

    case t.RECEIVE_MENU:
      return {
        ...state,
        menu: false
      }

    case t.REQUEST_ORDER_BUILDER_ETA:
      return {
        ...state,
        catalogBuilderEta: true
      }

    case t.RECEIVE_ORDER_BUILDER_ETA:
      return {
        ...state,
        catalogBuilderEta: false
      }

    case t.REQUEST_ORDER_BUILDER_QUOTE:
      return {
        ...state,
        catalogBuilderQuote: true
      }

    case t.RECEIVE_ORDER_BUILDER_QUOTE:
      return {
        ...state,
        catalogBuilderQuote: false
      }

    case t.REQUEST_ADULT_USE_TERRITORIES:
      return {
        ...state,
        adultUseTerritories: true
      }

    case t.RECEIVE_ADULT_USE_TERRITORIES:
      return {
        ...state,
        adultUseTerritories: false
      }

    case t.REQUEST_TAGS:
      return {
        ...state,
        tags: true
      }

    case t.RECEIVE_TAGS:
      return {
        ...state,
        tags: false
      }

    case t.REQUEST_TAG_TYPES:
      return {
        ...state,
        tagTypes: true
      }

    case t.RECEIVE_TAG_TYPES:
      return {
        ...state,
        tagTypes: false
      }

    case t.REQUEST_INVENTORY:
      return {
        ...state,
        inventory: true
      }

    case t.RECEIVE_INVENTORY:
      return {
        ...state,
        inventory: false
      }

    case t.REQUEST_DELIVERY_FEES:
      return {
        ...state,
        deliveryFees: true
      }

    case t.RECEIVE_DELIVERY_FEES:
      return {
        ...state,
        deliveryFees: false
      }

    default:
      return state
  }
}

export default loading
