import React from 'react'
import { bool, element } from 'prop-types'
import styled from '@emotion/styled'
import { secondaryDark } from 'src/helpers/css-variables'

export const RightBar = ({ isOpen = false, children }) => {
  return (
    <RightBarContainer isOpen={isOpen}>
      {children}
    </RightBarContainer>
  )
}

RightBar.propTypes = {
  children: element,
  isOpen: bool
}

const RightBarContainer = styled.div`
  background-color: ${secondaryDark};
  bottom: 0;
  box-shadow: -5px 0 1rem rgba(0,0,0,0.2);
  min-width: 50rem;
  position: fixed;
  right: 0;
  top: 0;
  transform: ${({ isOpen }) => `translateX(${isOpen ? 0 : 105}%)`};
  will-change: transform;
  z-index: 15;
  overflow-y: scroll;
`

export default RightBar
