import { createSelector } from 'reselect'
import { isValidInput } from 'src/helpers/form-validators'

export const getCatalogSubtype = (state) => state.catalogSubtype

export const cantCreateSubtype = createSelector(
  [getCatalogSubtype],
  (catalogSubtype) => {
    const keys = Object.keys(catalogSubtype)

    return keys.some(k => {
      // block of keys that are not required
      if (k === 'id') return false
      if (k === 'description') return false

      const value = catalogSubtype[k]

      return !isValidInput(value)
    })
  }
)
