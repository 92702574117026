import { createSelector } from 'reselect'
import { isValidInput, isValidImage, isValidEffect } from 'src/helpers/form-validators'

export const getCatalogStrain = (state) => state.catalogStrain

export const cantCreateStrain = createSelector(
  [getCatalogStrain],
  (catalogStrain) => {
    const keys = Object.keys(catalogStrain)

    return keys.some(k => {
      // block of keys that are not required
      if (k === 'id') return false
      if (k === 'description') return false
      if (k === 'highCbd') return false

      const value = catalogStrain[k]

      if (k === 'images') {
        return !isValidImage(value)
      }

      if (k === 'effects') {
        return value.some((effect, index) => !isValidEffect(effect, index))
      }

      return !isValidInput(value)
    })
  }
)
