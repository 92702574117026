/**
 * This is where we're setting up the parent most container for mc
 */

import React, { PureComponent } from 'react'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
// https://material-ui.com/customization/themes/#theme-provider
import ThemeProvider from '@material-ui/styles/ThemeProvider'
// We may want to implement CssBaseline eventually, need to
// investigate the impact on the app before we do though
// https://material-ui.com/components/css-baseline/#css-baseline
// import CssBaseline from '@material-ui/core/CssBaseline'
import MC3_THEME from './theme/muiTheme'

import McRouteContainer from './components/router/container'
import store from './redux/store'

import Firebase from 'helpers/firebase'

// we just import analytics so that load-segment will DL the segment lib
import './analytics'

const persistStoreConfig = {
  transforms: [],
  whitelist: ['soundAlerts', 'user', 'permissions'],
  keyPrefix: 'mc:'
}

export default class Mc extends PureComponent {
  state = {
    hydrated: false
  }

  componentDidMount () {
    persistStore(store, persistStoreConfig, () => this.setState({ hydrated: true }))
  }

  render () {
    const { hydrated } = this.state

    if (!hydrated) return null

    const firebase = new Firebase(store) // init firebase so we have a single instance for the whole app

    return (
      <ThemeProvider theme={MC3_THEME}>
        <Provider store={store}>
          <McRouteContainer {...this.props} store={store} firebase={firebase} />
        </Provider>
      </ThemeProvider>
    )
  }
}
