import React, { PureComponent } from 'react'
import { string } from 'prop-types'
import styled from '@emotion/styled'

export default class MenuIcon extends PureComponent {
  static propTypes = {
    image: string.isRequired,
    color: string.isRequired
  }

  containerStyle = {
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '5rem',
    height: '5rem'
  }

  render () {
    const { image, color } = this.props
    if (!image) return null

    return (
      <div style={{ ...this.containerStyle, backgroundColor: color }}>
        <Image src={image} />
      </div>
    )
  }
}

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`
