import React, { PureComponent } from 'react'
import { number, string } from 'prop-types'

import El from './order-summary-elements'

export default class SpecialInfoBox extends PureComponent {
  static propTypes = {
    color: string,
    cash: number,
    cashBack: number
  }

  render () {
    const { color, cash, cashBack } = this.props
    const total = cash - cashBack

    return (
      <El.SpecialContentBlock color={color}>
        <El.OperatorRow>
          <div>
            <El.TitleSmall>${cash.toFixed(2)}</El.TitleSmall>
            <El.Subtitle>CASH SALES</El.Subtitle>
          </div>
          <El.Operator>-</El.Operator>
        </El.OperatorRow>
        <El.OperatorRow>
          <div>
            <El.TitleSmall>${cashBack.toFixed(2)}</El.TitleSmall>
            <El.Subtitle>POB CHANGE</El.Subtitle>
          </div>
          <El.Operator>=</El.Operator>
        </El.OperatorRow>
        <El.OperatorRow>
          <div>
            <El.TitleSmall>${total.toFixed(2)}</El.TitleSmall>
            <El.Subtitle>CASH ON HAND</El.Subtitle>
          </div>
        </El.OperatorRow>
      </El.SpecialContentBlock>
    )
  }
}
