import { SET_CATALOG_TYPE } from 'src/redux/catalog/type/actionTypes'

const defaultState = {
  id: null,
  name: '',
  description: ''
}

const catalogType = (state = defaultState, action) => {
  switch (action.type) {
    case SET_CATALOG_TYPE:
      return {
        ...state,
        ...action.catalogType
      }

    default:
      return state
  }
}

export default catalogType
