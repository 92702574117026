import t from './actionTypes.js'

let api = {}

export function setUserBasic (userId, email, firstName, lastName, zipcode, mobilePhone) {
  return {
    type: t.SET_USER_BASIC,
    user: {
      userId,
      email,
      firstName,
      lastName,
      mobilePhone,
      zipcode
    }
  }
}

export function setUserToken (token) {
  return {
    type: t.SET_USER_TOKEN,
    token
  }
}

export function removeUser () {
  return {
    type: t.REMOVE_USER
  }
}

export function login (email, password, callback) {
  return api.login({
    email: email,
    password: password
  }, function (err, res) {
    if (err) {
      return callback(err)
    }

    return callback(null, res)
  })
}

export default function (client) {
  // allow api to be used outside, in api functions (login)
  api = client

  return {
    setUserBasic,
    setUserToken,
    removeUser,
    login
  }
}
