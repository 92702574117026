import React, { PureComponent } from 'react'
import { bool, func, number, shape, string } from 'prop-types'

import ProductBlock from 'src/components/product-block'
import plusSvg from 'src/assets/plus.svg'
import styled from '@emotion/styled'
import { accessory3, mcRed, softBlack, white } from 'src/helpers/css-variables'
import { generateImageUrl } from 'helpers/get-cloudflare-img-url.js'
export class HorizontalProductCard extends PureComponent {
  static propTypes = {
    invalid: bool,
    onAddProduct: func,
    product: shape({
      name: string,
      brand: shape({
        name: string
      }),
      photoUrl: string,
      price: number,
      isAvailable: bool
    }),
    showAddIcon: bool
  }

  static defaultProps = {
    product: {
      photoUrl: '',
      price: 0,
      isAvailable: false
    },
    showAddIcon: false
  }

  state = {
    quantity: 1
  }

  handleAddProduct = (id) => {
    const { onAddProduct } = this.props
    const quantity = this.state.quantity
    onAddProduct(id, quantity)
  }

  onQtyUpdate = (event) => {
    const qty = Number.parseInt(event.target.value, 10)
    this.setState({ quantity: qty })
  }

  onInputFocus = (event) => {
    event.target.select()
  }

  render () {
    const {
      invalid,
      product,
      showAddIcon
    } = this.props

    const {
      photoUrl,
      catalogItemId,
      isAvailable
    } = product

    if (invalid) {
      product.price = product.price || product.suggested_retail_price
      product.name = product.title
      return (
        <Container color={mcRed}>
          <StyledImage
            url={product.images.length && product.images[0].downloadUrl && generateImageUrl(product.images[0].downloadUrl)}
          />
          <Content>
            <ProductBlock product={product} />
          </Content>
        </Container>
      )
    }

    return (
      <Container isAvailable={isAvailable}>
        <StyledImage url={generateImageUrl(photoUrl)} />
        <Content>
          <ProductBlock product={product} />
        </Content>
        {showAddIcon && (
          <ButtonContainer>
            <QuantityInput
              type='number'
              min={1}
              value={this.state.quantity}
              onChange={this.onQtyUpdate}
              onClick={this.onInputFocus}
            />
            <ImageButton
              src={plusSvg}
              onClick={() => this.handleAddProduct(catalogItemId)}
            />
          </ButtonContainer>
        )}
      </Container>
    )
  }
}

export default HorizontalProductCard

const Container = styled.div`
  background: ${white};
  border-radius: 0.6rem;
  border: 1px solid ${accessory3};
  color: ${({ color }) => color || softBlack};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  opacity: ${({ isAvailable }) => isAvailable ? 1 : 0.5};
  padding: 1.5rem;
`

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: 2rem;
`

const ImageButton = styled.img`
  height: 2rem;
  margin-left: 1rem;
  width: 2rem;
`

const QuantityInput = styled.input`
  background-color: ${white};
  border: 1px solid ${accessory3};
  color: ${softBlack};
  width: 5.5rem;
`

const Content = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  margin: 0 1.2rem;
`

const StyledImage = styled.img`
  background-color: ${accessory3};
  height: 6rem;
  width: 6rem;
`
