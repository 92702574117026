/**
 * Setting up the users container
 */

import { connect } from 'react-redux'
import React from 'react'
import { func } from 'prop-types'
import { fetchUsers, nextCustomerPage, prevCustomerPage, search, setQuery } from 'src/redux/users/actions'
import { getLoadingUsers } from 'src/redux/loading/selectors'
import { getUsersList, getUsersCurrentPage, getUsersTotal } from 'src/redux/users/selectors'
import Users from './'

// Props passed to the component
const mapStateToProps = (state, props) => {
  return {
    loading: getLoadingUsers(state),
    page: getUsersCurrentPage(state),
    total: getUsersTotal(state),
    users: getUsersList(state)
  }
}

// Methods passed to the components
const mapDispatchToProps = (dispatch, props) => {
  return {
    decrement: () => dispatch(prevCustomerPage()),
    fetchUsers: () => dispatch(fetchUsers()),
    increment: () => dispatch(nextCustomerPage()),
    search: () => dispatch(search()),
    setQuery: (query) => dispatch(setQuery(query))
  }
}

class UsersContainer extends React.PureComponent {
  static propTypes = {
    fetchUsers: func
  }

  componentDidMount () {
    this.props.fetchUsers()
  }

  render () {
    return (
      <Users {...this.props} />
    )
  }
}

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersContainer)

export default reduxContainer
