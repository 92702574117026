/**
 * Form component that uses ./input components.
 *
 * This component sends down 2 methods through childContext - `onChange` and `register`.
 * These methods allow inputs to register to the form state and to send up changes
 * and validations to the top level form state, which allows the form to monitor
 * it's own validation state.
 */

import React from 'react'
import { array, bool, func, string } from 'prop-types'

import FormSubscriber from 'microcomponents/input/form-subscriber'
import Button, { HYBRID } from 'components/button'
import styled from '@emotion/styled'

class Form extends React.Component {
  constructor (props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.register = this.register.bind(this)
    this.deregister = this.deregister.bind(this)
    this.subscribe = this.subscribe.bind(this)
    this.unsubscribe = this.unsubscribe.bind(this)
    this.getInputStates = this.getInputStates.bind(this)
    this.setValuesForInputs = this.setValuesForInputs.bind(this)
    this.isFormValid = this.isFormValid.bind(this)
    this.subscriber = new FormSubscriber()

    this.state = {
      valid: false,
      inputs: {}
    }
  }

  isFormValid () {
    const keys = Object.keys(this.state.inputs)
    for (let i = 0; i < keys.length; i++) {
      const inputState = this.state.inputs[keys[i]]
      if (!inputState.valid) return false
    }

    return true
  }

  onSubmit (e) {
    e.preventDefault()

    if (this.isFormValid()) {
      this.props.onSubmit(this.state.inputs)
    }
  }

  register (name, value = '', valid = false) {
    this.setValuesForInputs(name, value, valid)
  }

  deregister (name) {
    const newInputs = this.state.inputs
    delete newInputs[name]
    this.setState({ inputs: newInputs })
  }

  subscribe (key, fn) {
    this.subscriber.subscribe(key, fn)
  }

  unsubscribe (key) {
    this.subscriber.unsubscribe(key)
  }

  getInputStates () {
    return this.state.inputs
  }

  onChange (name, value, valid) {
    this.setValuesForInputs(name, value, valid)
    this.subscriber.updateInputs()
  }

  setValuesForInputs (name, value, valid) {
    const inputsState = this.state.inputs
    inputsState[name] = { value, valid }

    this.setState({ inputs: inputsState })
  }

  getChildContext () {
    return {
      isFormValid: this.isFormValid,
      onChange: this.onChange,
      onSubmit: this.onSubmit,
      register: this.register,
      deregister: this.deregister,
      subscribe: this.subscribe,
      unsubscribe: this.unsubscribe,
      getInputStates: this.getInputStates
    }
  }

  render () {
    const { children, includeButton, submitText, loading, disabled } = this.props
    return (
      <form action='' onSubmit={this.onSubmit}>
        {children}
        {
          includeButton
            ? <StyledButton disabled={disabled} loading={loading} type={HYBRID}>{submitText}</StyledButton>
            : null
        }
      </form>
    )
  }
}

Form.propTypes = {
  children: array,
  // Whether to include a simple self-managed primary-styled button. If this is
  // set to false, include buttons (./button.js) in the children
  // that will use the form's isFormValid and onSubmit
  includeButton: bool.isRequired,
  // Whether the form has submitted and is waiting for a return
  loading: bool,
  disabled: bool,
  // Action run when form is valid
  onSubmit: func.isRequired,
  // Text on submit button e.g. 'Signup', 'Login'
  submitText: string
}

Form.defaultProps = {
  includeButton: true,
  loading: false,
  disabled: false,
  onSubmit: (e) => { e.preventDefault() }
}

Form.childContextTypes = {
  isFormValid: func,
  onChange: func,
  onSubmit: func,
  register: func,
  deregister: func,
  subscribe: func,
  unsubscribe: func,
  getInputStates: func
}

export default Form

const StyledButton = styled(Button)`
  font-family: inherit;
`
