import React, { PureComponent } from 'react'
import { element, func, node, oneOfType, string } from 'prop-types'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'

class BackButton extends PureComponent {
  static propTypes = {
    children: oneOfType([
      string,
      node,
      element
    ]),
    onClick: func,
    path: string
  }

  static defaultProps = {
    children: '← Go Back',
    onClick: () => {},
    path: '/'
  }

  render () {
    const { children, onClick, path } = this.props

    return (
      <StyledLink
        onClick={onClick}
        to={path}
      >
        {children}
      </StyledLink>
    )
  }
}

export default BackButton

const StyledLink = styled(Link)`
  text-decoration: none;
  color: rgba(255,255,255, 0.8);
  font-size: 1.4rem;
`
