
// takes an array of products and returns an ordered
// list of IDs and a products object keyed by ID
export const arrayToObject = (array, key = 'id') => {
  const productIds = []
  const productsObject = {}

  array.forEach((item) => {
    // preserve product order set in backend by default
    productIds.push(item[key])
    productsObject[item[key]] = item
  })

  return {
    productsObject,
    productIds
  }
}
