// strain types
export const HYBRID = 'HYBRID'
export const INDICA = 'INDICA'
export const SATIVA = 'SATIVA'
export const CBD = 'CBD'
export const ACCESSORY = 'ACCESSORY'

const SATIVA_TYPE = 'Sativa'
const SATIVA_HYBRID_TYPE = 'Sativa Hybrid'
const INDICA_TYPE = 'Indica'
const INDICA_HYBRID_TYPE = 'Indica Hybrid'
const HYBRID_TYPE = 'Hybrid'

export const typeMap = {
  [SATIVA_TYPE]: SATIVA,
  [INDICA_TYPE]: INDICA,
  [INDICA_HYBRID_TYPE]: INDICA,
  [SATIVA_HYBRID_TYPE]: SATIVA,
  [HYBRID_TYPE]: HYBRID,
  [CBD]: CBD
}

export function parseType (type, highCBD, isAccessory = false) {
  if (isAccessory) return ACCESSORY
  if (highCBD) return CBD
  // if we don't find a type, fallback to accessory
  return typeMap[type] || ACCESSORY
}
