import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import MCColors from 'helpers/css-variables'
import { func, number, oneOfType, object, string } from 'prop-types'
const { accessory1, accessory2, accessory5, mcGreen, mcRed, mcYellow } = MCColors

const modeColorMap = {
  DELIVERY: mcGreen,
  HOLD: mcRed,
  OFF: mcRed,
  ON: mcYellow
}

export default class DriverRow extends PureComponent {
  static propTypes = {
    depotDriver: object,
    expandDriverBubble: func,
    focusedDriverId: oneOfType([number, string]),
    setFocusedDriver: func
  }

  handleDriverRowClick = (depotDriver) => (event) => {
    const { focusedDriverId, setFocusedDriver } = this.props

    // logic to toggle driver off if that driver is currently on
    const isDriverFocused = focusedDriverId === depotDriver.id
    const driverId = isDriverFocused ? undefined : depotDriver.id

    setFocusedDriver(driverId)
  }

  render () {
    const {
      expandDriverBubble,
      depotDriver,
      focusedDriverId
    } = this.props
    const orderCount = depotDriver.pendingOrderIds && depotDriver.pendingOrderIds.length

    const mode = depotDriver.driverModeDescription
    const statusPillColor = modeColorMap[mode] || accessory2

    // we want to map DELIVERY to DEL, or if mode is HOLD and
    // the driver has an order count we want to render DEL
    const modeCopy = (mode === 'DELIVERY' || (mode === 'HOLD' && orderCount))
      ? 'DEL'
      : mode

    return (
      <DriverRowWrapper
        isSelected={focusedDriverId && depotDriver.id === focusedDriverId}
        onClick={this.handleDriverRowClick(depotDriver)}
        onMouseEnter={() => expandDriverBubble(depotDriver.id)}
        onMouseLeave={() => expandDriverBubble(null)}
      >
        <DriverRowContainer isInactive={!depotDriver.isActive}>
          <OrderCount>
            {orderCount}
          </OrderCount>
          <DriverName>
            {depotDriver.displayName}
          </DriverName>
          <DriverIdLink>
            {depotDriver.id}
          </DriverIdLink>
          <DriverStatusPill color={statusPillColor}>
            {modeCopy}
          </DriverStatusPill>
        </DriverRowContainer>
      </DriverRowWrapper>
    )
  }
}

const DriverRowWrapper = styled.div`
  cursor: pointer;
  background-color: ${({ isSelected }) => isSelected && accessory1};

  &:hover {
    background-color: ${accessory1};
  }

  &:last-of-type {
    margin-bottom: 1rem;
  }
`

const DriverRowContainer = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 10% 50% 25% 15%;
  opacity: ${({ isInactive }) => isInactive ? 0.3 : 1};
  padding: 0.6rem 2.4rem;
`

const OrderCount = styled.div`
  color: ${accessory5};
`

const DriverName = styled.div`
  color: ${accessory2};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const DriverIdLink = styled.div`
  color: ${accessory2};
  text-decoration: none;
`

const DriverStatusPill = styled.div`
  background-color: ${({ color }) => color};
  border-radius: 1rem;
  color: ${accessory5};
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.03rem;
  padding: 0.4rem;
  text-align: center;
  text-shadow: 0 0 1px rgba(85,85,85,.5);
  white-space: nowrap;
  width: 5rem;
`
