import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { array, bool, func, object, string } from 'prop-types'

import RightBar from 'src/components/right-bar'
import Button, { DANGER, PRIMARY } from 'components/button'
import { ERROR } from 'src/redux/alerts/actions'
import { getActiveDepotsGroupedByState } from 'src/redux/dispensaries/selectors'

import {
  drawerButton,
  drawerButtonContainer,
  drawerListItem,
  drawerTitle,
  myCheckbox,
  myCheckboxLabel
} from '../style.js'

const mapStateToProps = (state) => {
  return {
    depotsByState: getActiveDepotsGroupedByState(state)
  }
}

class DepotSelectDrawer extends PureComponent {
  static propTypes = {
    activeDispensaries: array,
    bulkCreateId: string,
    bulkCreateState: string,
    closeDepotDrawer: func,
    createInventoryItems: func,
    depotsByState: object,
    isBulkCreateActive: bool,
    pushNotification: func,
    title: string
  }

  state = {
    areAllDepotsSelected: true,
    areNoDepotsSelected: false,
    depotCount: 0,
    filteredDispensaries: [],
    selectedDepots: {},
    sortedDepots: []
  }

  sortDepots = (depots) => {
    function compare (a, b) {
      if (a.name.toUpperCase() < b.name.toUpperCase()) {
        return -1
      }
      if (a.name.toUpperCase() > b.name.toUpperCase()) {
        return 1
      }
      return 0
    }
    return depots.sort(compare)
  }

  handleRequestClose = () => {
    this.props.closeDepotDrawer()
  }

  triggerCreateInventoryItems = () => {
    if (this.state.areNoDepotsSelected) {
      return this.props.pushNotification('No depots are selected, please select at least one depot and try again', ERROR)
    }

    const filteredDispensaries = this.filterDispensaryObject()
    this.props.createInventoryItems(this.props.bulkCreateId, filteredDispensaries)

    // reset selected depots after creation
    const { selectedDepots, enabledDepots } = this.selectedDepots()
    this.setState({
      selectedDepots,
      areAllDepotsSelected: true,
      areNoDepotsSelected: false,
      depotCount: enabledDepots.length,
      filteredDispensaries: this.props.activeDispensaries,
      sortedDepots: this.sortDepots(enabledDepots)
    })

    this.handleRequestClose()
  }

  filterDispensaryObject = () => {
    const { filteredDispensaries, selectedDepots } = this.state
    let updatedDispensaries = filteredDispensaries.map((dispensary) => {
      const filteredDispensary = { ...dispensary }
      filteredDispensary.depots = dispensary.depots.filter((depot) => selectedDepots[depot.id])
      return filteredDispensary
    })

    updatedDispensaries = updatedDispensaries.filter(dispensary => dispensary.depots.length)

    this.setState({ filteredDispensaries: updatedDispensaries })
    return updatedDispensaries
  }

  handleToggleDepot = (event) => {
    event.stopPropagation()

    const { target: { id, checked } } = event
    const { selectedDepots, depotCount } = this.state
    const newSelectedDepots = { ...selectedDepots }

    newSelectedDepots[id] = checked

    const areAllDepotsSelected = depotCount === this.numberOfSelectedDepots(newSelectedDepots)
    const areNoDepotsSelected = depotCount === 0

    this.setState({
      selectedDepots: newSelectedDepots,
      areAllDepotsSelected,
      areNoDepotsSelected
    })
  }

  handleToggleAllDepots = (event) => {
    event.stopPropagation()
    const { selectedDepots } = this.state

    let selectAll

    if (this.state.areAllDepotsSelected) {
      Object.keys(selectedDepots).forEach((key) => {
        selectedDepots[key] = false
      })
      selectAll = false
    } else {
      Object.keys(selectedDepots).forEach((key) => {
        selectedDepots[key] = true
      })
      selectAll = true
    }

    this.setState({
      selectedDepots: selectedDepots,
      areAllDepotsSelected: selectAll,
      areNoDepotsSelected: !selectAll
    })
  }

  numberOfSelectedDepots = (depots) => {
    return Object.keys(depots).filter((key) => depots[key]).length
  }

  componentDidUpdate = (prevProps) => {
    const { bulkCreateState } = this.props

    // update component when product state changes
    if (prevProps.bulkCreateState !== bulkCreateState) {
      const { selectedDepots, enabledDepots } = this.selectedDepots()

      this.setState({
        selectedDepots,
        areAllDepotsSelected: true,
        areNoDepotsSelected: false,
        depotCount: enabledDepots.length,
        filteredDispensaries: this.props.activeDispensaries,
        sortedDepots: this.sortDepots(enabledDepots)
      })
    }
  }

  selectedDepots = () => {
    const { depotsByState, bulkCreateState } = this.props
    const initDepots = {}
    const enabledDepots = bulkCreateState === 'US'
      ? Object.keys(depotsByState).reduce((accum, current) => {
        return [...accum, ...depotsByState[current]]
      }, [])
      : depotsByState[bulkCreateState] || []

    enabledDepots.forEach((el) => { initDepots[el.id] = el })
    return {
      selectedDepots: initDepots,
      enabledDepots
    }
  }

  render () {
    const { title } = this.props

    const {
      areAllDepotsSelected,
      depotCount,
      selectedDepots,
      sortedDepots
    } = this.state

    return (
      <RightBar isOpen={this.props.isBulkCreateActive}>
        <div style={{ height: '100%', overflow: 'auto', padding: '1rem', width: '100%' }}>
          <div className={drawerTitle}>
            {`Which depots would you like to create ${title} at?`}
          </div>

          <div className={drawerListItem} style={{ marginBottom: '2rem' }}>
            {
              // dynamically update the select all text and functionality depending on how many depots are selected
              this.numberOfSelectedDepots(selectedDepots) === depotCount ||
                (this.numberOfSelectedDepots(selectedDepots) === depotCount && this.numberOfSelectedDepots(selectedDepots) !== 0)
                ? <div>
                  <label className={myCheckbox}>
                    <input
                      type='checkbox'
                      id='selectAllDepots'
                      checked={areAllDepotsSelected || false}
                      onChange={this.handleToggleAllDepots}
                    />
                    <span />
                  </label>
                  <label htmlFor='selectAllDepots' className={myCheckboxLabel}>Deselect All</label>
                </div>
                : <div>
                  <label className={myCheckbox}>
                    <input
                      type='checkbox'
                      id='selectAllDepots'
                      checked={areAllDepotsSelected || false}
                      onChange={this.handleToggleAllDepots}
                    />
                    <span />
                  </label>
                  <label htmlFor='selectAllDepots' className={myCheckboxLabel}>Select All</label>
                </div>
            }
          </div>
          {
            sortedDepots.map(depot => {
              const {
                name,
                id
              } = depot

              return (
                <div key={id} className={drawerListItem}>
                  <label className={myCheckbox}>
                    <input
                      type='checkbox'
                      id={id}
                      onChange={this.handleToggleDepot}
                      checked={selectedDepots[id] || false}
                    />
                    <span />
                    <label htmlFor={id} className={myCheckboxLabel}>{`${name} | Depot #${id}`}</label>
                  </label>
                </div>
              )
            })
          }
          <div className={drawerButtonContainer}>
            <Button type={PRIMARY} className={drawerButton} onClick={this.triggerCreateInventoryItems}>Create Product</Button>
            <Button type={DANGER} className={drawerButton} onClick={this.handleRequestClose}>Cancel</Button>
          </div>
        </div>
      </RightBar>
    )
  }
}

export default connect(mapStateToProps)(DepotSelectDrawer)
