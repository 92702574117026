import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { dateOnly, timeOnly, transformToUTCISOString, convertTo12HourFormat } from 'src/helpers/date'
import { utcToZonedTime } from 'date-fns-tz'
import { selectDay } from 'src/helpers/delivery-fee-ranges'

const headerStyle = {
  height: '100px',
  width: '100%',
  position: 'relative',
  marginBottom: '50px'
}

const orderRowStyle = {
  display: 'flex',
  alignItems: 'end',
  paddingBottom: '25px',
  justifyContent: 'space-evenly',
  borderBottom: '2px solid black',
  marginBottom: '35px'
}

const itemBoxStyle = {
  marginRight: '40px',
  position: 'relative',
  height: '75px',
  minWidth: '50px'
}

const numberBoxStyle = {
  position: 'relative',
  marginRight: '20px'
}

const largeTextStyle = {
  fontSize: '40px'
}

const mediumTextStyle = {
  fontSize: '25px'
}

const labelStyle = {
  fontSize: '15px'
}

const orderDriverBox = {
  position: 'absolute',
  left: 0
}

const orderHeaderBox = {
  position: 'absolute',
  right: 0
}

const containerStyle = {
  padding: '24px'
}

const addressBoxStyle = {
  maxWidth: '150px',
  fontSize: '25px'
}

const bottomLabelStyle = {
  position: 'absolute',
  bottom: 0
}

const nameStyle = {
  minWidth: '100px',
  fontSize: '25px'
}

export default class PrintOrderManifest extends PureComponent {
  render () {
    const { orders } = this.props
    const driverInfo = orders[0].acceptedBy

    return (
      <div style={containerStyle}>
        <div style={headerStyle}>
          <div style={orderDriverBox}>
            <div style={largeTextStyle}>{driverInfo.displayName}</div>
            <div style={labelStyle}>DRIVER - {driverInfo.mobilePhoneDisplay}</div>
          </div>
          <div style={orderHeaderBox}>
            <div style={largeTextStyle}>{orders.length}</div>
            <div style={labelStyle}>ORDERS</div>
          </div>
        </div>

        {
          orders.map((order, i) => {
            const orderQuantity = order.products.reduce((a, product) => a + product.quantity, 0)
            let deliveryWindowString = 'ASAP'
            if (order.deliveryWindowInfo) {
              const startISO = transformToUTCISOString(
                order.deliveryWindowInfo.timeFrom
              )
              const endISO = transformToUTCISOString(order.deliveryWindowInfo.timeTo)
              // clip the date off and use today's date to know if it's DST
              let start = `${dateOnly()}T${startISO.split('T')[1]}`
              let end = `${dateOnly()}T${endISO.split('T')[1]}`
              start = utcToZonedTime(start, order.depotTimeZone).toISOString()
              end = utcToZonedTime(end, order.depotTimeZone).toISOString()
              deliveryWindowString = `${convertTo12HourFormat(timeOnly(start))} - ${convertTo12HourFormat(timeOnly(end))}`
              deliveryWindowString = `${selectDay[
                order.deliveryWindowInfo.dayOfWeek
              ].label.substring(0, 10)} ${convertTo12HourFormat(timeOnly(start))}-${convertTo12HourFormat(timeOnly(end))}`
            }

            return (
              <div style={orderRowStyle} key={order.id}>
                <div style={numberBoxStyle}>
                  <div style={largeTextStyle}>{i + 1}.</div>
                </div>
                <div style={itemBoxStyle}>
                  <div style={largeTextStyle}>{order.id}</div>
                  <div style={bottomLabelStyle}>ORDER #</div>
                </div>
                <div style={itemBoxStyle}>
                  <div style={mediumTextStyle}>{orderQuantity}</div>
                  <div style={bottomLabelStyle}>ITEMS</div>
                </div>
                <div style={itemBoxStyle}>
                  <div style={nameStyle}>{order.createdBy.firstName} {order.createdBy.lastName.substr(0, 1)}</div>
                  <div style={bottomLabelStyle}>CUSTOMER</div>
                </div>
                <div style={addressBoxStyle}>
                  <div>{order.delivery.fullAddress}</div>
                </div>
                {order.deliveryWindowInfo && (
                  <div style={addressBoxStyle}>
                  <div>{deliveryWindowString}</div>
                </div>
                )}
              </div>
            )
          })
        }
      </div>
    )
  }
}

PrintOrderManifest.propTypes = {
  orders: PropTypes.array.isRequired
}
