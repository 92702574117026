import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { func, number } from 'prop-types'

import { fetchDeliveryFees, saveDeliveryFees } from 'src/redux/delivery-fees/actions'
import { getDeliveryFeesList } from 'src/redux/delivery-fees/selectors'
import { getDeliveryScheduleList } from 'src/redux/delivery-schedule/selectors'
import Settings from './'
import { getActiveDepots, getActiveDispensariesMap } from 'src/redux/dispensaries/selectors'
import { fetchAllDispensaries } from 'src/redux/dispensaries/actions'
import { fetchDeliverySchedule, saveDeliverySchedule } from 'src/redux/delivery-schedule/actions'

const mapStateToProps = (state, props) => {
  return {
    deliveryFeesList: getDeliveryFeesList(state),
    deliveryScheduleList: getDeliveryScheduleList(state),
    activeDepots: getActiveDepots(state),
    activeDispensaries: getActiveDispensariesMap(state)
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchDeliveryFees: (depotId) => dispatch(fetchDeliveryFees(depotId)),
    fetchDeliverySchedule: (depotId) => dispatch(fetchDeliverySchedule(depotId)),
    fetchAllDispensaries: () => dispatch(fetchAllDispensaries()),
    saveDeliveryFees: (peakHoursList, depotId) => dispatch(saveDeliveryFees(peakHoursList, depotId)),
    saveDeliverySchedule: (deliveryScheduleList, depotId) => dispatch(saveDeliverySchedule(deliveryScheduleList, depotId))
  }
}

class SettingsContainer extends PureComponent {
  static propTypes = {
    fetchDeliveryFees: func,
    selectedDepotId: number,
    fetchDeliverySchedule: func
  }

  componentDidMount () {
    const { fetchDeliveryFees, fetchDeliverySchedule, selectedDepotId } = this.props
    fetchDeliveryFees(selectedDepotId)
    fetchDeliverySchedule(selectedDepotId)
  }

  render () {
    return (
      <Settings {...this.props} />
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsContainer)
