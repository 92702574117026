import t from './actionTypes'

const defaultState = {
  slider: false
}

const navigation = (state = defaultState, action) => {
  switch (action.type) {
    case t.OPEN_NAVIGATION_SLIDER:
      return Object.assign({}, state, {
        slider: true
      })

    case t.CLOSE_NAVIGATION_SLIDER:
      return Object.assign({}, state, {
        slider: false
      })

    default:
      return state
  }
}

export default navigation
