import React from 'react'
import { string } from 'prop-types'
import { css, cx } from 'emotion'

const label = css`
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`

const InputLabel = (props) => {
  const { htmlFor, content } = props
  return <label className={cx(label)} htmlFor={htmlFor}>{content}</label>
}

InputLabel.propTypes = {
  htmlFor: string,
  content: string
}

export default InputLabel
