import React from 'react'

import MCColors from 'src/helpers/css-variables'
const { midGray } = MCColors

// FIXME: Firefox doesn't like rems so using em for short term. Need to think of a longer term solution/standardization to support cross-browser compatibility https://bugzilla.mozilla.org/show_bug.cgi?format=default&id=1231147
export default function ArrowIcon ({ height = '0.75em', width = '1.25em', color = midGray, transform = 'translate(-23, -412) translate(0, 274) translate(0, 133) translate(33, 11) scale(1) rotate(90) translate(-33, -11) translate(28.5, 2)', componentStyle = {} }) {
  return (
    <svg
      height={height}
      width={width}
      viewBox='0 0 20 12'
      version='1.1'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      style={componentStyle}
    >
      <g
        transform={transform}
        stroke={color}
        strokeWidth='1.5'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
      >
        <path d='M0 0l9 9m0 0l-9 8.485' />
      </g>
    </svg>
  )
}
