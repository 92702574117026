import React, { PureComponent } from 'react'
import { array, bool, func, object, shape, string } from 'prop-types'
import Details from './details'
import Buttons from './action-buttons.js'

import { previewContainer } from '../style.js'

class PreviewTagsDeals extends PureComponent {
  static propTypes = {
    brandsMap: object,
    depotsMap: object,
    formData: shape({
      description: string,
      expiresAt: string,
      id: string,
      items: array,
      markets: array,
      startAt: string,
      tagType: string,
      text: string
    }),
    isEdit: bool,
    onDeleteTag: func.isRequired,
    onCancelPreview: func.isRequired,
    onCloseForm: func.isRequired,
    productsMap: object,
    selectedState: string,
    selectedDepots: object,
    selectedProducts: object,
    speciesMap: object,
    tagsMap: object
  }

  render () {
    const {
      brandsMap,
      depotsMap,
      formData,
      isEdit,
      onCancelPreview,
      onCloseForm,
      onDeleteTag,
      speciesMap,
      selectedDepots,
      productsMap,
      selectedState,
      selectedProducts,
      tagsMap
    } = this.props

    return (
      <div className={previewContainer}>
        <Details
          brandsMap={brandsMap}
          speciesMap={speciesMap}
          depotsMap={depotsMap}
          tagData={formData}
          isEdit={isEdit}
          headerText={isEdit ? 'Tag Details' : 'Does this look right?'}
          onCancelPreview={onCancelPreview}
          onCloseForm={onCloseForm}
          onDeleteTag={onDeleteTag}
          selectedDepots={selectedDepots}
          productsMap={productsMap}
          selectedState={selectedState}
          selectedProducts={selectedProducts}
          tagsMap={tagsMap}
        />
        <Buttons
          onCloseForm={onCloseForm}
          onCancelPreview={onCancelPreview}
          onDeleteTag={onDeleteTag}
          formData={formData}
          isEdit={isEdit}
        />
      </div>
    )
  }
}

export default PreviewTagsDeals
