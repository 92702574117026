const indigo = {
  10: '#efefff',
  20: '#e0e1ff',
  30: '#c8c9ff',
  40: '#7679e3',
  50: '#5053af',
  60: '#3f418e',
  70: '#363869',
  80: '#2f3050',
  90: '#2d2d3c'
}

export default indigo
