import t from './actionTypes'

const defaultState = {
  vehicles: []
}

const driverVehicles = (state = defaultState, { type, payload }) => {
  switch (type) {
    case t.SET_DRIVER_VEHICLES:
      return {
        ...state,
        vehicles: payload
      }

    default:
      return state
  }
}

export default driverVehicles
