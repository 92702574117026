import t from './actionTypes'
import { orderIsCanceled } from 'src/helpers/orders'
// make a default state with id of 0, when we connect to a socket with a depotId
// this default data structure will be replaced, keyed by a different id
const defaultState = {
  selectedDepotId: 0
}

const hub = (state = defaultState, { type, payload, err, meta }) => {
  const newState = { ...state }
  const selectedDepotId = newState.selectedDepotId

  switch (type) {
    case t.SET_SELECTED_DEPOT_ID: {
      // whenever we select a depot id, we reset the socket and wipe all data
      return {
        selectedDepotId: payload,
        [payload]: {}
      }
    }

    case t.RECEIVE_PENDING_ORDERS: {
      const { orders: pendingOrders } = payload

      const orderIds = []
      if (pendingOrders && pendingOrders.length) {
        pendingOrders.forEach(order => {
          newState[selectedDepotId][order.id] = order
          orderIds.push(order.id)
        })
      }

      // pendingOrders will only be active orders, once an order is delivered, we want to remove it from this store
      // however, an order that is delivered will be absent from pendingOrders
      // so, let's loop through our store, if the id is in the current pendingOrders or it has a canceled status
      // we keep it, otherwise we remove it
      Object.values(newState[selectedDepotId]).forEach(order => {
        let orderShouldBeRemoved = true
        orderIds.forEach(orderId => {
          if (orderId === order.id) {
            orderShouldBeRemoved = false
          }
        })

        if (orderShouldBeRemoved && !orderIsCanceled(order)) {
          delete newState[selectedDepotId][order.id]
        }
      })

      return newState
    }

    case t.RECEIVE_CANCELED_ORDERS: {
      const canceledOrders = payload

      if (!canceledOrders || !canceledOrders.length) {
        return newState
      }

      canceledOrders.forEach(order => {
        newState[selectedDepotId][order.id] = order
      })

      return newState
    }

    case t.CLEAR_UNPACKED_CANCELED_ORDERS: {
      const canceledUnpackedOrders = payload

      if (canceledUnpackedOrders) {
        canceledUnpackedOrders.forEach(order => {
          delete newState[selectedDepotId][order.id]
        })
      }

      return newState
    }

    case t.ORDER_MODIFIED: {
      const { orderId, key, value } = payload

      newState[selectedDepotId][orderId][key] = value
      return newState
    }

    case t.MARK_ORDERS_PRINTED: {
      const orders = payload

      if (orders && orders.length) {
        orders.forEach(order => {
          newState[selectedDepotId][order.id].isPrinted = true
        })
      }

      return newState
    }

    case t.MARK_MANIFEST_PRINTED: {
      const driverId = payload
      Object.entries(newState[selectedDepotId]).forEach(([_, order]) => {
        if (order.acceptedBy && order.acceptedBy.id === driverId) {
          order.acceptedBy.hasPrintedManifest = true
        }
      })

      return newState
    }

    case t.MARK_ORDER_LOADING: {
      const id = payload
      newState[selectedDepotId][id].loading = true

      return newState
    }

    case t.ERROR_ORDER_ID: {
      if (!newState[selectedDepotId]) {
        newState[selectedDepotId] = {}
      }

      delete newState[selectedDepotId][payload]
      return newState
    }

    case t.CLEAR_STORE: {
      return defaultState
    }

    default:
      return state
  }
}

export default hub
