import React from 'react'

// will inherit size from parent container
// button dimensions defined in parent container
const MinusIcon = ({ width = '100%', height = '100%' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
  >
    <circle
      cx={12}
      cy={12}
      r={11}
      fill='#fff'
      stroke='#00aae7'
      strokeWidth={2}
    />
    <path
      d='M16.1 13c.5 0 .9-.6.9-1 0-.5-.4-1-.9-1H7.9c-.5 0-.9.5-.9 1s.4 1 .9 1h8.2z'
      fill='#00aae7'
    />
  </svg>
)

export default MinusIcon
