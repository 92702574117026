import React from 'react'
import InputGroupBogo from '../input-group-bogo.js'
import InputLabel from 'src/microcomponents/input-label'
import { Row, Column } from 'src/microcomponents/flexbox-helpers'
import { func, object } from 'prop-types'
import { selectContainer } from '../style.js'

const DetailsBogo = ({ promoOptions, catalog, managePromos, setBuyOneValue, setBuyOneType, setBuyOneAmount, setGetOneValue, setGetOneType, setGetOneAmount }) => {
  const buy = managePromos.rules.operators.buy
  const get = managePromos.rules.operators.get

  return (
    <div>
      <Row top={1} bottom={1}>
        <InputLabel content='Buy One' />
      </Row>
      <Row top={1} bottom={1}>
        <Column full>
          <InputLabel content='Type*' />
          <div className={selectContainer}>
            <select onChange={setBuyOneType} value={buy.id_type || 0}>
              <option value={0} disabled />
              {promoOptions.id_types.map((opt, ind) => <option value={opt} key={ind}>{opt}</option>)}
            </select>
          </div>
        </Column>
      </Row>
      <Row top={1} bottom={1}>
        <Column right={1} componentStyle={{ flex: 4, zIndex: 100000 }}>
          <InputLabel content='Value*' />
          <InputGroupBogo
            bogoIdType={buy.id_type}
            bogoIdValue={buy.id_value}
            catalog={catalog}
            setIdValue={setBuyOneValue}
            catalogName={managePromos.catalogNameBuy}
          />
        </Column>
        <Column full>
          <InputLabel content='Qty*' />
          <input
            onBlur={setBuyOneAmount}
            defaultValue={buy.quantity || ''}
            type='number'
            min={0}
            placeholder=''
          />
        </Column>
      </Row>

      <Row top={2} bottom={1}>
        <InputLabel content='Get One' />
      </Row>
      <Row top={1} bottom={1}>
        <Column full>
          <InputLabel content='Type*' />
          <div className={selectContainer}>
            <select onChange={setGetOneType} value={get.id_type || 0}>
              <option value={0} disabled />
              {promoOptions.id_types.map((opt, ind) => <option key={ind}>{opt}</option>)}
            </select>
          </div>
        </Column>
      </Row>
      <Row top={1} bottom={1}>
        <Column right={1} componentStyle={{ flex: 4 }}>
          <InputLabel content='Value*' />
          <InputGroupBogo
            bogoIdType={get.id_type}
            bogoIdValue={get.id_value}
            catalog={catalog}
            setIdValue={setGetOneValue}
            catalogName={managePromos.catalogNameGet}
          />
        </Column>
        <Column full>
          <InputLabel content='Qty*' />
          <input
            onBlur={setGetOneAmount}
            defaultValue={get.quantity || ''}
            type='number'
            min={0}
            placeholder=''
          />
        </Column>
      </Row>
    </div>
  )
}

DetailsBogo.propTypes = {
  promoOptions: object,
  catalog: object,
  managePromos: func,
  setBuyOneValue: func,
  setBuyOneType: func,
  setBuyOneAmount: func,
  setGetOneValue: func,
  setGetOneType: func,
  setGetOneAmount: func
}

export default DetailsBogo
