import React, { PureComponent } from 'react'
import styled from '@emotion/styled'

import PlusIcon from './plus-icon'
import { bool, func } from 'prop-types'

const Increment = styled.button`
  background-color: transparent;
  border: 0;
  outline: none;
  font-weight: 400;
  text-align: center;
  font-size: 3rem;
  height: 3rem;
  width: 3rem;
  color: transparent;
  cursor: ${({ isDisabled }) => isDisabled ? 'not-allowed' : 'pointer'};
  opacity: ${({ isDisabled }) => isDisabled ? 0.5 : 1};
  @media(max-width: 767px) {
    font-size: 2rem;
    height: 2rem;
    width: 2rem;
    padding: 0;
  }
`

export default class IncrementComponent extends PureComponent {
  static propTypes = {
    disabled: bool,
    onClick: func
  }

  render () {
    const { disabled } = this.props

    return (
      <Increment
        aria-label='Increment'
        onClick={this.props.onClick}
        // intentionally not named disabled because we don't want to actually block clicks
        isDisabled={disabled}
      >
        <PlusIcon />
      </Increment>
    )
  }
}
