import React, { PureComponent, Fragment } from 'react'
import styled from '@emotion/styled'
import { number } from 'prop-types'

import { defaultColor, defaultSize, getTextToIconRatio } from './display-helper.js'

const SoldOutText = styled.span`
  color: ${defaultColor};
  font-size: ${props => props.size}rem;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle;
`

export default class SoldOutComponent extends PureComponent {
  static propTypes = {
    size: number
  }

  static defaultProps = {
    size: defaultSize
  }

  render () {
    const { size } = this.props
    return (
      <Fragment>
        <div>
          <SoldOutSvg size={size} />
          <SoldOutText size={size}>OUT OF STOCK</SoldOutText>
        </div>
      </Fragment>
    )
  }
}

export class SoldOutSvg extends PureComponent {
  static propTypes = {
    size: number
  }

  static defaultProps = {
    color: defaultColor,
    size: defaultSize
  }

  render () {
    const { size } = this.props
    const iconSize = getTextToIconRatio(size)

    return (
      <svg
        style={{
          verticalAlign: 'middle',
          marginRight: '.5rem',
          width: `${iconSize}rem`,
          height: `${iconSize}rem`
        }}
        width={`${iconSize}rem`}
        height={`${iconSize}rem`}
        viewBox='0 0 14 14'
        version='1.1'
      >
        <g
          transform='translate(-1.0, -1.0) translate(1.0, 1.0)'
          stroke='none'
          strokeWidth={1}
          fill='none'
          fillRule='evenodd'
        >
          <circle stroke={defaultColor} cx={7} cy={7} r='6.5' />
          <g transform='translate(4.25, 4.25)' fill={defaultColor}>
            <rect
              transform='translate(2.828427, 2.828427) scale(-1, 1) rotate(45.0) translate(-2.828427, -2.828427) '
              x='-0.671572875'
              y='2.32842712'
              width={7}
              height={1}
              rx='0.5'
            />
            <rect
              transform='translate(2.828427, 2.828427) rotate(45.0) translate(-2.828427, -2.828427) '
              x='-0.671572875'
              y='2.32842712'
              width={7}
              height={1}
              rx='0.5'
            />
          </g>
        </g>
      </svg>
    )
  }
}
