import React, { PureComponent } from 'react'
import { number, string, oneOfType, func, object, bool } from 'prop-types'
import styled from '@emotion/styled'

import { Text, Subtext } from 'components/typography'
import Color from 'components/color'

import QuantityPicker from 'components/quantity-picker'

import NameContainer from './name-container.js'
import Container from './container.js'
import Price from './price'

export default class Product extends PureComponent {
  static propTypes = {
    addItem: func,
    cart: object,
    cartItem: object,
    name: string,
    price: number,
    product: object,
    quantity: oneOfType([number, string]),
    quantityPickerProps: object,
    removeItem: func,
    shouldRequestQuote: bool,
    showProductImage: bool,
    showProductLinks: bool,
    showQuantityPicker: bool
  }

  color = Color.accessory['2']
  subtextColor = Color.accessory['3']

  render () {
    const {
      removeItem,
      addItem,
      cart,
      cartItem,
      product,
      showQuantityPicker,
      showProductImage,
      showProductLinks,
      quantityPickerProps,
      shouldRequestQuote
    } = this.props
    const { brand: { name: brandName } = {}, id: productId, name, price, quantity } = product
    const priceDisplay = quantity * price
    const quantityDisplay = `Qty: ${quantity}`

    return (
      <Container>
        <ProductWrap>
          {showProductImage &&
            <ImageContainer>
              <img src={product.photoUrl} alt={name} />
            </ImageContainer>}
          <NameContainer>
            {showProductLinks ? <StyledLink href={{ query: { cart: cart.id, product_id: productId } }}>{name}</StyledLink> : <Text color={this.color}>{name}</Text>}
            {brandName && <Subtext color={this.subtextColor}>{brandName}</Subtext>}
          </NameContainer>
        </ProductWrap>

        <Price showQuantityPicker={showQuantityPicker}>
          {showQuantityPicker
            ? <QuantityPicker
                cart={cart}
                product={product}
                cartItem={cartItem}
                addItemToCart={addItem}
                removeItemFromCart={removeItem}
                shouldRequestQuote={shouldRequestQuote}
                {...quantityPickerProps}
              />
            : <QuantityPriceContainer>
                <PriceDisplay>${priceDisplay}</PriceDisplay>
                <QuantityDisplay>{quantityDisplay}</QuantityDisplay>
              </QuantityPriceContainer>}
        </Price>
      </Container>
    )
  }
}

const ProductWrap = styled.div`
  display: flex;
  padding-right: 1rem;
`

const ImageContainer = styled.div`
  width: 4rem;
  min-width: 4rem;
  margin-right: 1rem;
  img {
    width: 100%;
    height: auto;
  }
`

const QuantityPriceContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const PriceDisplay = styled.div`
  font-weight: 600;
  text-align: center;
  font-size: 1.4rem;
`

const QuantityDisplay = styled.div`
  text-align: center;
  font-weight: 200;
  font-size: 1rem;
`

const StyledLink = styled.a`
  color: ${Color.accessory['2']};
  font-size: 1.4rem;
  letter-spacing: 0.03em;
`
