import React from 'react'

import MCColors from 'src/helpers/css-variables'
const { midGray } = MCColors

export default function ProfileIcon ({ height = '0.75em', width = '0.56em', color = midGray }) {
  return (
    <svg
      height={height}
      width={width}
      viewBox='0 0 6 8'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Page-1' fill='none' fillRule='evenodd'>
        <g id='Depot-View---Deliveries-driver-selected' transform='translate(-591 -397)' fill={color} fillRule='nonzero'>
          <g id='Group-17' transform='translate(485 332)'>
            <g id='Group-19' transform='translate(34 24)'>
              <g id='Group-18'>
                <g id='Group-16' transform='translate(72 39)'>
                  <path d='M0.193548387,2 C0.0863225806,2 0,2.08702438 0,2.19512193 L0,9.80487807 C0,9.91297562 0.0863225806,10 0.193548387,10 L5.80645161,10 C5.91367742,10 6,9.91297562 6,9.80487807 L6,2.19512193 C6,2.08702438 5.91367742,2 5.80645161,2 L0.193548387,2 Z M2.18181818,3 L3.81818182,3 C3.9186,3 4,3.08735609 4,3.19512193 C4,3.30288777 3.9186,3.39024385 3.81818182,3.39024385 L2.18181818,3.39024385 C2.0814,3.39024385 2,3.30288777 2,3.19512193 C2,3.08735609 2.0814,3 2.18181818,3 Z M3,5 C3.55227778,5 4,5.44772197 4,6.00000056 C4,6.55227803 3.55227778,7 3,7 C2.44772222,7 2,6.55227803 2,6.00000056 C2,5.44772197 2.44772222,5 3,5 Z M3,7 C4.10458,7 5,7.8264 5,9 L1,9 C1,7.8264 1.89543,7 3,7 Z' id='Profile-Icon' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
