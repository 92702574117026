const red = {
  10: '#ffeef0',
  20: '#ffccd2',
  30: '#ffa8b3',
  40: '#fe6579',
  50: '#f73952',
  60: '#d71f37',
  70: '#b00d22',
  80: '#800a19',
  90: '#621822'
}

export default red
