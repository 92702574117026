import capitalize from './../capitalize'

/**
 * Returns the received string with every word capitalized; if input is not
 * a string, it will simply be returned as it was received
 * @param {*} words - Words to capitalize
 * @returns {string} words are returned capitalized; e.g. "hey you" -> "Hey You"
 */
function titleize (words) {
  const isString = typeof words === 'string'
  if (isString) {
    return words.split(' ').map(capitalize).join(' ')
  } else console.warn('titleize() received an unexpected input type', words)
  return words
}

export default titleize
