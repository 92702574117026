import React from 'react'
import { func } from 'prop-types'
import { Flex } from 'src/microcomponents/flexbox-helpers'
import styled from '@emotion/styled'

import InputLabel from 'src/microcomponents/input-label'
import { USER_SEGMENTS, getUserSegmentType } from 'helpers/promos/user-segmentation'
import OrderHistorySelect from './order-history-select'
import managePromosPropType from 'src/redux/manage-promos/prop-types'

import { supplierSpecificity } from '../../style.js'

const RADIO_NAME = 'userSegment'
const noop = () => {}

export default class UserSegmentation extends React.PureComponent {
  static propTypes = {
    // orderHistoryLimit: string,
    setOrderHistoryLimit: func,
    setPromoUserIds: func,
    managePromos: managePromosPropType.isRequired
  }

  static defaultProps = {
    orderHistoryLimit: null,
    setOrderHistoryLimit: () => {},
    managePromos: {}
  }

  state = {
    userSegment: USER_SEGMENTS.allUsers
  }

  isChecked = (userSegment) => {
    return this.state.userSegment === userSegment
  }

  getUserIdsAsString = () => {
    const { managePromos } = this.props
    // eslint-disable-next-line camelcase
    const { user_cohort = {} } = managePromos.rules
    const userIds = user_cohort.user_ids
    if (!userIds || !userIds.join) return ''

    return userIds.join(', ')
  }

  handleUserSegmentClick = (userSegment, cb = noop) => {
    const { setPromoUserIds, setOrderHistoryLimit } = this.props
    setOrderHistoryLimit({ target: { value: null } })
    setPromoUserIds(null)
    this.setState({ userSegment }, cb())
  }

  handleUserIdsChange = ({ target: { value } }) => {
    const { setPromoUserIds } = this.props
    const userIdList = value.split(',')

    // Did is parse into an array, ok, let's filter empty strings, and make sure they're returned to integers once filtered.
    const trimmedList = userIdList
      .filter((value) => value && value.length)
      // Needs to be an integer for the api payload. Also, remove spaces in case it's a padded CSV list.
      .map((value) => parseInt(value.trim()))

    // If we didn't get at least one result, we need to set null so it becomes an 'All users' promo.
    trimmedList.length
      ? setPromoUserIds(trimmedList)
      : setPromoUserIds(null)
  }

  componentDidMount () {
    const { managePromos } = this.props
    const userSegment = getUserSegmentType(managePromos)
    this.setState({ userSegment })
  }

  render () {
    const { setOrderHistoryLimit, managePromos } = this.props

    return (
      <React.Fragment>
        <div className={supplierSpecificity}>
          <InputLabel content='User segmentation:' />
          <Flex style={{ justifyContent: 'space-around' }}>
            <label
              htmlFor='none'
              onClick={() => this.handleUserSegmentClick(USER_SEGMENTS.allUsers)}
            >
              <input
                type='radio'
                id='none'
                name={RADIO_NAME}
                checked={this.isChecked(USER_SEGMENTS.allUsers)}
              />
              <span>All Users</span>
            </label>
            <label htmlFor='orderHistory' onClick={() => this.handleUserSegmentClick(USER_SEGMENTS.orderHistory)}>
              <input
                type='radio'
                id='orderHistory'
                name={RADIO_NAME}
                checked={this.isChecked(USER_SEGMENTS.orderHistory)}
              />
              <span>Order History</span>
            </label>
            <label htmlFor='custom' onClick={() => this.handleUserSegmentClick(USER_SEGMENTS.custom)}>
              <input
                type='radio'
                id='custom'
                name={RADIO_NAME}
                checked={this.isChecked(USER_SEGMENTS.custom)}
              />
              <span>Custom</span>
            </label>
          </Flex>
        </div>

        {this.state.userSegment === USER_SEGMENTS.orderHistory &&
          <OrderHistorySelect
            orderHistoryLimit={managePromos.rules.order_history}
            setOrderHistoryLimit={setOrderHistoryLimit}
          />
        }
        {this.state.userSegment === USER_SEGMENTS.custom &&
          <div>
            <InputLabel content='User ids' />
            <StyledTextArea onBlur={this.handleUserIdsChange} defaultValue={this.getUserIdsAsString()} />
          </div>
        }
      </React.Fragment>
    )
  }
}

const StyledTextArea = styled.textarea`
  height: 15rem;
`
