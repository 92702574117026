import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import Colors from 'microcomponents/colors'
import { bool, number, object, string } from 'prop-types'
import { HYBRID, INDICA, SATIVA, CBD, ACCESSORY } from './helpers'
import Tag from './tag'

const fonts = '\'Proxima\', \'Open Sans\', \'Helvetica\''

const Content = styled.div`
  background-color: ${getBackground};
  color: ${({ isInCart }) => isInCart ? 'white' : Colors.accessory[1]};
  font-family: ${fonts};
  padding: 1rem;
  transition: color 0.2s linear;
  font-size: 1.4rem;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Price = styled.div`
  font-weight: bold;
  color: ${({ isInCart }) => isInCart ? 'white' : Colors.accessory[1]};
`

const Name = styled.div`
  color: ${getNameColor};
  font-weight: bold;
  line-height: 1em;
  margin: 0.5rem 0;
`

const TagWrapper = styled.div`
  margin-top: auto;
`

const Brand = styled.div`
  font-weight: 200;
  line-height: 1em;
  color: ${({ isInCart }) => isInCart ? 'white' : Colors.accessory[2]};
  margin-bottom: ${({ tag }) => tag ? '0.5rem' : '0'}
`

export default class ContentComponent extends PureComponent {
  static propTypes = {
    brandName: string,
    isAvailable: bool,
    isInCart: bool,
    name: string,
    parsedSpecies: string,
    price: number,
    tag: object,
    tagType: string
  }

  render () {
    const {
      brandName,
      isAvailable,
      isInCart,
      name,
      parsedSpecies,
      price,
      tag,
      tagType
    } = this.props

    return (
      <Content parsedSpecies={parsedSpecies} isInCart={isInCart}>
        <Price isInCart={isInCart}>{'$' + price}</Price>
        <Name isInCart={isInCart} parsedSpecies={parsedSpecies}>{name}</Name>
        <Brand isInCart={isInCart} tag={tag}>{brandName}</Brand>
        {tag && isAvailable &&
          <TagWrapper>
            <Tag text={tag.text} type={tagType || tag.tagType} color={isInCart ? 'white' : null} />
          </TagWrapper>
        }
      </Content>
    )
  }
}

export const strainMap = {
  [HYBRID]: Colors.hybrid[1],
  [INDICA]: Colors.indica[1],
  [SATIVA]: Colors.sativa[1],
  [CBD]: Colors.cbd[1],
  [ACCESSORY]: Colors.accessory[1]
}

function getBackground ({ isInCart, parsedSpecies = HYBRID }) {
  return isInCart ? [strainMap[parsedSpecies]] : 'white'
}

function getNameColor ({ isInCart, parsedSpecies = HYBRID }) {
  return isInCart ? 'white' : [strainMap[parsedSpecies]]
}
