export default {
  GET_DEPOT_INVENTORY: 'GET_DEPOT_INVENTORY',
  SET_DEPOT_INVENTORY: 'SET_DEPOT_INVENTORY',

  REQUEST_DEPOT_INVENTORY: 'REQUEST_DEPOT_INVENTORY',
  RECEIVE_DEPOT_INVENTORY: 'RECEIVE_DEPOT_INVENTORY',

  REQUEST_DEPOT_BATCH_QUANTITY: 'REQUEST_DEPOT_BATCH_QUANTITY',
  RECEIVE_DEPOT_BATCH_QUANTITY: 'RECEIVE_DEPOT_BATCH_QUANTITY'
}
