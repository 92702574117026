import t from './actionTypes'

const defaultState = {
  name: '',
  id: '',
  items: [],
  description: '',
  slug: '',
  color: '',
  desktop_image: '',
  mobile_image: '',
  icon_image: '',
  depots_enabled: null,
  state_id: ''
}

const menuItem = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_MENU_ITEMS:
      return {
        ...state,
        items: action.items
      }

    case t.UPDATE_MENU_OBJECT:
      return {
        ...state,
        ...action.obj
      }

    case t.RESET_MENU_ITEM:
      return defaultState

    default:
      return state
  }
}

export default menuItem
