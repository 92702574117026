import { css } from 'emotion'
import { danger5, mcRed, primaryDark, white } from 'helpers/css-variables'

export const container = css`
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    background: center/60% no-repeat url('assets/place/pin.svg');
    bottom: 0;
    top: 0;
    left: 0.7rem;
    padding: 2rem 1rem;

    @media (max-width: 767px) {
      background-size: 50%;
    }
  }
`

export const input = css`
  background-color: transparent;
  font-size: 1.6rem;
  border: 1px solid ${mcRed};
  background-color: ${danger5};
  color: ${mcRed};
  padding-left: 3rem;

  &::placeholder {
    font-size: 1.6rem;
    color: ${mcRed};
  }

  &:focus {
    background-color: ${white};
    font-size: 1.6rem;
    border: 1px solid ${primaryDark};
    color: ${primaryDark};

    &::placeholder {
      font-size: 1.6rem;
      color: ${primaryDark};
    }
  }
`

export const suggestionsContainer = css`
  position: absolute;
  width: 100%;
  background-color: ${white};
  color: ${primaryDark};
`
