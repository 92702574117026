import React, { PureComponent } from 'react'
import { array, func, string } from 'prop-types'

import { active, button, tabContainer } from './style.js'
export default class TabBar extends PureComponent {
  handleClick = (tab) => {
    this.props.onClick(tab)
  }

  render () {
    const { tabs, currentTab } = this.props
    return (
      <div className={tabContainer}>
        {
          tabs.map(tab => {
            const buttonCss = [button, (currentTab === tab) && active]

            return (
              <button key={tab} css={buttonCss} onClick={() => this.handleClick(tab)}>
                {tab}
              </button>
            )
          })
        }
      </div>
    )
  }
}

TabBar.propTypes = {
  currentTab: string,
  onClick: func.isRequired,
  tabs: array.isRequired
}
