export default {
  products: {
    name: 'products',
    label: 'Products',
    searchable: true,
    indexQueryParams: {
      limit: 100,
      start: 0,
      stateIds: null
    }
  },
  brands: {
    name: 'brands',
    label: 'Brands',
    searchable: true,
    indexQueryParams: {}
  },
  groups: {
    name: 'groups',
    label: 'Groups',
    searchable: true,
    indexQueryParams: {
      stateIds: null
    }
  },
  menus: {
    name: 'menus',
    label: 'Menus',
    searchable: false,
    indexQueryParams: {
      stateIds: null
    }
  },
  strains: {
    name: 'strains',
    label: 'Strains',
    searchable: true,
    indexQueryParams: {}
  },
  subtypes: {
    name: 'subtypes',
    label: 'Subtypes',
    searchable: true,
    indexQueryParams: {}
  },
  types: {
    name: 'types',
    label: 'Types',
    searchable: true,
    indexQueryParams: {}
  }
}
