/**
 * This component displays a list of products with prices and quantities.
 * Used in checkout and in order status.
 */
import React, { PureComponent } from 'react'
import { arrayOf, bool, func, number, object, oneOfType, shape, string } from 'prop-types'

import Product from './product'

export default class ProductsList extends PureComponent {
  static propTypes = {
    addItem: func,
    cart: object,
    products: arrayOf(shape({
      id: number.isRequired,
      name: string,
      price: number,
      quantity: oneOfType([number, string])
    })),
    quantityPickerProps: object,
    removeItem: func,
    shouldRequestQuote: bool,
    showProductImage: bool,
    showProductLinks: bool,
    showQuantityPicker: bool
  }

  static defaultProps = {
    showQuantityPicker: false,
    cart: {},
    quantityPickerProps: {}
  }

  render () {
    const { products, removeItem, addItem, cart, showQuantityPicker, quantityPickerProps, showProductImage, showProductLinks, shouldRequestQuote } = this.props
    const productsList = products.map((product, index) => {
      let cartItem = {}

      if (!product) return false

      if (cart && cart.products) {
        cartItem = cart.products.find((cartItem) => product.id === cartItem.id)
      }

      return (
        <Product
          addItem={addItem}
          cart={cart}
          cartItem={cartItem}
          index={index}
          key={index}
          product={product}
          quantityPickerProps={quantityPickerProps}
          removeItem={removeItem}
          showProductImage={showProductImage}
          showProductLinks={showProductLinks}
          showQuantityPicker={showQuantityPicker}
          shouldRequestQuote={shouldRequestQuote}
        />
      )
    })

    return <div style={{ width: '100%' }}>{productsList}</div>
  }
}
