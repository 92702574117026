import { arrayToObject } from 'src/redux/products/helpers'
// takes a menu object and formats it for our reducers
export const formatMenu = (menu) => {
  const { groups } = menu
  const menuMap = { ...menu }
  let groupsMap = {}
  let productsMap = {}
  const groupIds = []

  groups.forEach((group) => {
    const { products } = group
    const { productsObject, productIds } = arrayToObject(products, 'catalogItemId')
    productsMap = {
      ...productsMap,
      ...productsObject
    }

    groupIds.push(group.id)
    const groupObject = { ...group }

    groupObject.products = productIds
    groupsMap[group.id] = groupObject
  })

  menuMap.groups = groupIds

  return {
    menuMap,
    groupsMap,
    productsMap
  }
}
