import React, { PureComponent } from 'react'
import { array, func, string } from 'prop-types'
import Autosuggest from 'react-autosuggest'
import debounce from 'debounce'
import styled from '@emotion/styled'
import { css } from 'emotion'

import MCColors from 'helpers/css-variables'
const { blazeUpBlack, darkDivider, mcBlue, primaryDark, white } = MCColors

export class SearchCatalog extends PureComponent {
  static propTypes = {
    list: array,
    searchCatalogItems: func,
    setResult: func.isRequired,
    stateId: string,
    value: string
  }

  state = {
    value: this.props.value || ''
  }

  updateInputValue = (event, { newValue }) => {
    this.setState({ value: newValue })
  }

  retrieveSuggestions = debounce(() => {
    const { value } = this.state
    const { stateId } = this.props

    this.props.searchCatalogItems(value, stateId)
  }, 200)

  onSelect = (e, { suggestion }) => {
    this.props.setResult(suggestion.id)

    this.setState({ value: suggestion.title })
  }

  renderSuggestion = (suggestion) => <div>{suggestion.title} <Brand>{suggestion.brand.name}</Brand></div>

  render () {
    const { list } = this.props
    const { value } = this.state

    return (
      <Autosuggest
        suggestions={list}
        getSuggestionValue={c => c.title}
        onSuggestionSelected={this.onSelect}
        onSuggestionsFetchRequested={this.retrieveSuggestions}
        onSuggestionsUpdateRequested={this.retrieveSuggestions}
        inputProps={{ value, onChange: this.updateInputValue, placeholder: 'Type to search catalog...' }}
        renderSuggestion={this.renderSuggestion}
        theme={localTheme}
      />
    )
  }
}

export default SearchCatalog

const localTheme = {
  container: css`
    background-color: ${primaryDark};
    position: relative;
    width: 100%;

    @media(max-width: 767px) {
      margin-top: 0;
    }

    &::before {
      content: '';
      position: absolute;
      background-size: 2rem;
      top: 0;
      left: 0.2rem;
      bottom: 0;
      padding: 2rem;
      box-sizing: border-box;

      @media (max-width: 767px) {
        background-size: 1.4rem;
        left: 0.167rem;
        top: 0;
        padding: 1.6rem;
      }

      @media (max-width: 374px) {
        background-size: 1.2rem;
      }
    }
  `,
  suggestionsList: css`
    background: ${primaryDark};
    border: 1px solid ${darkDivider};
    color: ${white};
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1001;
  `,
  suggestion: css`
    padding: 0.5rem 1.5rem;
    position: relative;
  `,
  suggestionHighlighted: css`
    background-color: ${mcBlue};
  `,
  input: css`
    border: none;
    color: ${white};
    font-size: 1.6rem;
    outline: none;
    padding: 1rem;
    width: 100%;

    &::-webkit-input-placeholder {
      color: ${white};
      opacity: 0.4;
    }

    &[aria-expanded="true"] {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  `
}

const Brand = styled.span`
  color: ${blazeUpBlack};
`
