import { connect } from 'react-redux'
import React, { PureComponent } from 'react'
import { func, number, object } from 'prop-types'
import { unsetSocket } from 'helpers/socket-io'
import {
  setSelectedDepotId,
  markOrderPacked,
  markOrderUnpacked
} from 'src/redux/hub/actions'
import { getUserId } from 'src/redux/hub/selectors'

import HubAndSpoke from './component'

const mapStateToProps = (state, props) => {
  return {
    userId: getUserId(state)
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    markOrderPacked: (orderId, userId) => dispatch(markOrderPacked(orderId, userId)),
    markOrderUnpacked: (orderId, userId) => dispatch(markOrderUnpacked(orderId, userId)),
    setSelectedDepotId: (id) => dispatch(setSelectedDepotId(id))
  }
}

class HubAndSpokeContainer extends PureComponent {
  static propTypes = {
    match: object,
    markOrderPacked: func,
    markOrderUnpacked: func,
    selectedDepotId: number,
    setSelectedDepotId: func,
    userId: number
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillMount () {
  /* eslint-enable camelcase */
    const { selectedDepotId, setSelectedDepotId } = this.props
    setSelectedDepotId(selectedDepotId)
  }

  componentWillUnmount () {
    unsetSocket()
  }

  render () {
    const {
      markOrderPacked,
      markOrderUnpacked,
      match,
      selectedDepotId,
      userId
    } = this.props

    return (
      <HubAndSpoke
        markOrderPacked={markOrderPacked}
        markOrderUnpacked={markOrderUnpacked}
        match={match}
        selectedDepotId={selectedDepotId}
        userId={userId}
      />
    )
  }
}

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HubAndSpokeContainer)

export default reduxContainer
