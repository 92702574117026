import React, { PureComponent } from 'react'
import { array, bool, func, object } from 'prop-types'
import debounce from 'debounce'
import window from 'global/window'
import { Flex, Column } from 'src/microcomponents/flexbox-helpers'

import Button, { HYBRID, DANGER } from 'components/button'
import InputLabel from 'src/microcomponents/input-label'
import { isValidInput } from 'src/helpers/form-validators'

import { transformToCatalogSubtype } from 'src/redux/catalog/subtype/actions'

import {
  inputError,
  selectContainer
} from '../../style.js'

export class EditSubtype extends PureComponent {
  state = {
    formSubmitAttempted: false,
    // using transformToCatalogSubtype in order to make sure the local
    // catalogSubtype has the same shape as the redux store's catalogSubtype
    catalogSubtype: transformToCatalogSubtype({}),
    isEdit: false
  }

  static propTypes = {
    cantCreateSubtype: bool,
    catalogSubtype: object,
    createCatalogSubtype: func,
    deleteCatalogSubtype: func,
    isSubtypeFormOpen: bool,
    setCatalogSubtype: func,
    types: array,
    updateCatalogSubtype: func
  }

  // destructuring isSubtypeFormOpen nextProps to compare to this.props.isSubtypeFormOpen
  // to determine whether the form was opened from a closed state
  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps ({ isSubtypeFormOpen, catalogSubtype }) {
    /* eslint-enable camelcase */
    // if we are going to open the form, ensure we reset formSubmitAttempted
    if (this.props.isSubtypeFormOpen === false && isSubtypeFormOpen === true) {
      const { id } = catalogSubtype
      const isEdit = Boolean(id)

      this.setState({
        formSubmitAttempted: false,
        catalogSubtype,
        isEdit
      })
    }
  }

  deleteSubtype = (event) => {
    event.preventDefault()
    const { catalogSubtype: { name } } = this.state
    const confirmMessage = `Are you sure you want to delete the ${name} subtype from the catalog?`
    if (window.confirm(confirmMessage)) {
      this.props.deleteCatalogSubtype()
    }
  }

  submitForm = (event) => {
    event.preventDefault()
    const { cantCreateSubtype } = this.props
    const { createCatalogSubtype, updateCatalogSubtype, catalogSubtype: { id } } = this.props
    this.setState({ formSubmitAttempted: true })

    if (!cantCreateSubtype) {
      if (id) {
        updateCatalogSubtype()
      } else {
        createCatalogSubtype()
      }
    }
  }

  // debounce this callback so we don't spam redux updates on change of form fields
  updateStore = debounce(() => {
    const { setCatalogSubtype } = this.props
    const { catalogSubtype } = this.state
    setCatalogSubtype(catalogSubtype)
  }, 200)

  updateSubtypeState = (catalogSubtype) => {
    this.setState({ catalogSubtype }, this.updateStore)
  }

  setFormValue = ({ target: { value } }, formKey) => {
    const { catalogSubtype } = this.state
    const newCatalogSubtype = {
      ...catalogSubtype,
      [formKey]: value
    }
    this.updateSubtypeState(newCatalogSubtype)
  }

  render () {
    const { isEdit, formSubmitAttempted, catalogSubtype } = this.state
    const { types } = this.props
    const { name, description, parentType, slug } = catalogSubtype

    return (
      <div style={{ height: '100%', overflow: 'auto', padding: '1rem' }}>
        <Column>
          <h1>Add a Subtype to Catalog</h1>
          <form onSubmit={this.submitForm}>
            <Column>
              <InputLabel content='* Name' />
              <input
                className={formSubmitAttempted && !isValidInput(name) ? inputError : ''}
                onChange={(event) => this.setFormValue(event, 'name')}
                value={name}
                placeholder=''
              />
            </Column>

            <Column top={1}>
              <InputLabel content='* Parent Type' />
              <div className={selectContainer}>
                <select
                  className={formSubmitAttempted && !isValidInput(parentType) ? inputError : ''}
                  onChange={(event) => this.setFormValue(event, 'parentType')}
                  value={parentType}
                >
                  <option value=''>None</option>
                  {types.map(t => <option key={t.id} value={t.id}>{t.name}</option>)}
                </select>
              </div>
            </Column>

            <Column top={1}>
              <InputLabel content='Description' />
              <textarea
                onChange={(event) => this.setFormValue(event, 'description')}
                value={description}
                cols='2'
                rows='5'
              />
            </Column>

            <Column top={1}>
              <InputLabel content='Slug' />
              <input
                onChange={(event) => this.setFormValue(event, 'slug')}
                value={slug}
                placeholder='enter a unique slug ex: universal-cartridge'
              />
            </Column>

            <Flex top={3}>
              <Button type={HYBRID}>Submit</Button>
            </Flex>

            {
              isEdit && (
                <Flex top={1}>
                  <Button
                    type={DANGER}
                    onClick={this.deleteSubtype}
                    disabled
                  >
                  Delete
                  </Button>
                </Flex>
              )
            }

          </form>
        </Column>
      </div>
    )
  }
}

export default EditSubtype
