import React from 'react'

// FIXME: Firefox doesn't like rems so using em for short term. Need to think of a longer term solution/standardization to support cross-browser compatibility https://bugzilla.mozilla.org/show_bug.cgi?format=default&id=1231147
export default function TagNavIcon ({ color = 'white', size = '2.5em', componentStyle }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 26 22'
      version='1.1'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g
        transform='translate(-176.000000, -90.000000) translate(36.000000, 72.000000) translate(121.000000, 18.000000) translate(19.000000, 0.000000)'
        fill='#FFF'
        fillRule='nonzero'
        stroke='none'
        strokeWidth={1}
      >
        <path
          d='M20.774 1.01A2.556 2.556 0 0 0 18.94.244L13.058.33a4.442 4.442 0 0 0-3.09 1.308L.753 10.85a2.578 2.578 0 0 0 0 3.642l6.511 6.51a2.558 2.558 0 0 0 1.821.755c.688 0 1.334-.268 1.821-.754l8.41-8.41c1.055.483 2.115.72 3.073.72 1.437 0 2.644-.531 3.255-1.559.102-.169.186-.35.25-.539a.58.58 0 0 0-1.097-.372 1.63 1.63 0 0 1-.147.318c-.655 1.101-2.522 1.299-4.455.546A4.437 4.437 0 0 0 21.427 8.7l.041-2.742c.165.083.33.168.49.263 1.363.814 2.405 1.987 2.785 3.14a.58.58 0 1 0 1.1-.364c-.473-1.43-1.673-2.804-3.293-3.77a9.176 9.176 0 0 0-1.063-.544l.027-1.826a2.556 2.556 0 0 0-.74-1.846zm-1.472 9.962l-.206.205-.07-.04a7.74 7.74 0 0 1-1.293-.964.579.579 0 1 0-.788.848c.395.366.837.7 1.31.997l-8.166 8.166a1.408 1.408 0 0 1-1.002.416c-.38 0-.735-.148-1.003-.416l-6.511-6.51a1.42 1.42 0 0 1 0-2.005l9.213-9.213a3.29 3.29 0 0 1 2.289-.968L18.94 1.4c.382 0 .74.15 1.01.423.268.272.412.633.407 1.016l-.088 5.843a3.29 3.29 0 0 1-.967 2.29z'
          fill={color} />
        <path
          d='M14.904 3.637a.579.579 0 1 0 .46 1.063 1.29 1.29 0 0 1 1.422 2.093 1.277 1.277 0 0 1-.691.355 1.789 1.789 0 0 1 .237-.953.58.58 0 0 0-.995-.593 2.882 2.882 0 0 0-.383 1.18 1.281 1.281 0 0 1-.333-1.197.579.579 0 1 0-1.126-.265 2.432 2.432 0 0 0 1.599 2.876c.125.434.32.871.586 1.305a.578.578 0 1 0 .986-.607 4.51 4.51 0 0 1-.317-.614c.46-.09.9-.313 1.255-.669a2.448 2.448 0 0 0 0-3.458 2.436 2.436 0 0 0-2.7-.516z'
          fill={color} />
      </g>
    </svg>
  )
}
