import React from 'react'

import MCColors from 'src/helpers/css-variables'
const { midGray } = MCColors

export default function DepotIcon ({ height = '0.75em', width = '0.75em', color = midGray }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 47 47'
    >
      <g
        transform='translate(6.000000, 4.000000)'
        stroke={color}
        strokeWidth='4'
        fill='none'
        fillRule='evenodd'
      >
        <path d='M18.007.8L.75 13.954V39.25h6.76v-5.533h20.98v5.533h6.76V13.954L18.007.8zM.607 39.25H.605h.002zm7.05 0h-.001zm-.147.093zm20.837-.093h-.002.002zm7.05 0h-.001zm-.147.093z' />
        <path d='M17.5 8.75a2.765 2.765 0 0 0-2.75 2.757 2.753 2.753 0 0 0 2.75 2.743 2.753 2.753 0 0 0 2.75-2.743A2.765 2.765 0 0 0 17.5 8.75z' />
        <path d='M7.51 26.181v3.69h20.98v-3.69H7.51z' />
        <path d='M7.51 29.685v3.72h20.98v-3.72H7.51z' />
      </g>
    </svg>
  )
}
