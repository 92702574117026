import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Drawer from '@eaze/react-fast-drawer'
import { Text, SectionTitle } from 'components/typography'
import Button, { PRIMARY } from 'components/button'

import ROUTES from 'src/components/router/routes'
import { formatConciergeUrl } from 'src/redux/catalog-builder/format'

import {
  button,
  buttonRow,
  container,
  content,
  dataItem,
  orderData,
  title
} from './order-sent-drawer.styles.js'

class OrderSentDrawer extends PureComponent {
  static propTypes = {
    conciergeSlug: PropTypes.string,
    displayName: PropTypes.string,
    mobilePhoneDisplay: PropTypes.string,
    showOrderDrawer: PropTypes.bool,
    setShowOrderDrawer: PropTypes.func.isRequired
  }

  handleCloseOrderDrawer = () => {
    const { setShowOrderDrawer } = this.props
    setShowOrderDrawer(false)
  }

  render () {
    const {
      conciergeSlug,
      displayName,
      mobilePhoneDisplay,
      showOrderDrawer
    } = this.props
    const conciergeLink = formatConciergeUrl(conciergeSlug)
    const textStyles = { marginTop: '1rem' }

    return (
      <Drawer
        open={showOrderDrawer}
        onRequestClose={this.handleCloseOrderDrawer}
        modalElementClass={container}
      >
        <div className={content}>
          <SectionTitle
            className={title}
          >
            Order has been sent via SMS
          </SectionTitle>

          <div className={orderData}>
            <Text
              componentStyle={textStyles}
              className={dataItem}
            >
              <b>name:</b> {displayName}
            </Text>
            <Text
              componentStyle={textStyles}
              className={dataItem}
            >
              <b>phone number:</b> {mobilePhoneDisplay}
            </Text>
            <Text
              componentStyle={textStyles}
              className={dataItem}
            >
              <b>link:</b> {conciergeLink}
            </Text>
          </div>

          <div className={buttonRow}>
            <div className={button}>
              <Button
                inverted
                type={PRIMARY}
                onClick={this.handleCloseOrderDrawer}
              >
                Edit This Cart
              </Button>
            </div>
            <Link
              className={button}
              to={ROUTES.ORDER_BUILDER}
            >
              <Button type={PRIMARY}>Start A New Order</Button>
            </Link>
          </div>
        </div>
      </Drawer>
    )
  }
}

export default OrderSentDrawer
