import React from 'react'
import { bool, string } from 'prop-types'
import { css, cx } from 'emotion'
import { fontSizeLabel, letterSpacingLabel, eazeBlue } from 'helpers/css-variables'

const label = css`
  font-size: ${fontSizeLabel};
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: ${letterSpacingLabel};
`

const activeCss = css`
  color: ${eazeBlue};
  cursor: pointer;
  font-weight: 400;
`

const Label = (props) => {
  const {
    content,
    active,
    className,
    ...otherProps
  } = props

  const labelStyle = cx({
    [label]: true,
    [activeCss]: active,
    [className]: className
  })

  return (
    <label className={labelStyle} {...otherProps}>
      {content}
    </label>
  )
}

Label.propTypes = {
  active: bool,
  className: string,
  content: string
}

Label.defaultProps = {
  content: ''
}

export default Label
