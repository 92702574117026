import t from './actionTypes.js'
import { idToInt } from 'helpers/sanitizers'

export function clearDriversStore () {
  return {
    type: t.CLEAR_DRIVERS
  }
}

export function setFocusedDriversDriverId (driverId) {
  return {
    type: t.SET_FOCUSED_DRIVERS_DRIVER_ID,
    payload: idToInt(driverId)
  }
}

export function setFocusedDriverDepotHasMETRCRules (hasRules) {
  return {
    type: t.SET_FOCUSED_DRIVER_DEPOT_HAS_METRC_RULES,
    payload: hasRules
  }
}

export function setDriverInventoryLoadingMap (payload) {
  return {
    type: t.SET_DRIVERS_DRIVER_INVENTORY_LOADING_MAP,
    payload
  }
}

export function setDriverOrdersLoadingMap (payload) {
  return {
    type: t.SET_DRIVERS_DRIVER_ORDERS_LOADING_MAP,
    payload
  }
}

export function setDriverDetailsContent (payload) {
  return {
    type: t.SET_DRIVERS_DRIVER_DETAILS_CONTENT,
    payload
  }
}

export function toggleDriverMETRCInfoDrawer () {
  return {
    type: t.TOGGLE_DRIVER_METRC_INFO_DRAWER
  }
}
