import React from 'react'

const PlusIcon = ({ width = '100%', height = '100%', fillColor = '#D7D7D7' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
  >
    <g stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
      <circle stroke={fillColor} strokeWidth={2} cx={12} cy={12} r={11} />
      <path
        d='M12.974 13h4.131c.474 0 .895-.532.895-1 0-.468-.421-.924-.895-.924h-4.131V6.912c0-.493-.448-.912-.974-.912s-.974.419-.974.912v4.164H6.895c-.474 0-.895.456-.895.924s.421 1 .895 1h4.131v4.088c0 .493.448.912.974.912s.974-.419.974-.912V13z'
        fill={fillColor}
      />
    </g>
  </svg>
)

export default PlusIcon
