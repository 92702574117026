import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
// import PropTypes from 'prop-types'
import Spinner from 'components/spinner'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export default class SpinnerComponent extends PureComponent {
  // static defaultProps = {
  // }

  // static propTypes = {
  // }

  render () {
    return (
      <Container><Spinner /></Container>
    )
  }
}
