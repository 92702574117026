const purple = {
  10: '#fbeeff',
  20: '#f6d7ff',
  30: '#e4aff3',
  40: '#d17ee9',
  50: '#a54fbe',
  60: '#973eb1',
  70: '#7e2f95',
  80: '#632775',
  90: '#502d5b'
}

export default purple
