import { connect } from 'react-redux'
import React, { Fragment, PureComponent } from 'react'
import { array, bool, func, number } from 'prop-types'
import styled from '@emotion/styled'
import Colors from 'microcomponents/colors'
import qs from 'query-string'
import api from 'api'
import Button, { HYBRID } from 'components/button'

import history from 'components/router/history'
import McCard from 'src/components/mc-card'
import List from 'src/pages/catalog/list/list-component'
import { casesAddedFromCsv, cloneCase, getCasesByDepot, deleteCase, exportCSV, createCase, getCaseByCaseId, getArchivedCasesByDepot } from 'src/redux/cases/actions'
import { getCases, getArchivedCases } from 'src/redux/cases/selectors'
import { readDate } from 'src/helpers/date'
import archivedImg from 'src/assets/archived.svg'
import InputFile from 'microcomponents/input-file'
import LoadingSpinner from 'src/microcomponents/loading-spinner'
import types from 'microcomponents/input-file/types'
import { getActiveDepotsSlimmed } from 'src/redux/dispensaries/selectors'
import { idToInt } from 'helpers/sanitizers'

import { brandGrid } from 'src/pages/catalog/style.js'

const mapStateToProps = (state, props) => {
  const params = qs.parse(props.location.search)
  const { match } = props
  const selectedDepotId = idToInt(match.params.id || state.depots.id)

  return {
    archived: Boolean(params.archived),
    archivedCases: getArchivedCases(state),
    cases: getCases(state),
    depotId: selectedDepotId,
    depots: getActiveDepotsSlimmed(state)
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    casesAddedFromCsv: (err, cases) => dispatch(casesAddedFromCsv(err, cases)),
    cloneCase: (caseId) => dispatch(cloneCase(caseId)),
    createCase: (caseTemplate) => dispatch(createCase(caseTemplate)),
    deleteCase: (id) => dispatch(deleteCase(id)),
    exportCSV: (id, depotName) => dispatch(exportCSV(id, depotName)),
    getArchivedCasesByDepot: (id) => dispatch(getArchivedCasesByDepot(id)),
    getCaseByCaseId: (id) => dispatch(getCaseByCaseId(id)),
    getCasesByDepot: (id) => dispatch(getCasesByDepot(id))
  }
}

class Cases extends PureComponent {
  static propTypes = {
    archived: bool,
    archivedCases: array,
    cases: array,
    casesAddedFromCsv: func,
    cloneCase: func,
    deleteCase: func,
    depotId: number,
    depots: array,
    exportCSV: func,
    getCasesByDepot: func,
    getArchivedCasesByDepot: func
  }

  state = {
    csvLoading: false
  }

  componentDidMount () {
    const { archived, depotId, getCasesByDepot, getArchivedCasesByDepot } = this.props
    archived ? getArchivedCasesByDepot(depotId) : getCasesByDepot(depotId)
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps (nextProps) {
  /* eslint-enable camelcase */
    const { getArchivedCasesByDepot, depotId, archived } = this.props
    if (nextProps.archived && archived !== nextProps.archived) {
      getArchivedCasesByDepot(depotId)
    }
  }

  handleCsvUpload = async (result) => {
    const { depotId, casesAddedFromCsv } = this.props

    const formdata = new window.FormData()
    const file = result.file
    formdata.set('csv', file)

    const { err, data } = await api.uploadCaseCsv(formdata)
    if (err) return this.setState({ csvLoading: false })

    const cases = data.filter(caseTemplate => {
      // this doesn't affect the filter operation, we just want to have this side effect of logging for operations sake
      caseTemplate.errors.forEach(({ catalogItemId, status, productIdOptions }) => {
        if (!productIdOptions) productIdOptions = []
        console.warn(caseTemplate.depot_id, catalogItemId, status, ...productIdOptions)
      })

      return caseTemplate.depot_id === depotId
    })

    casesAddedFromCsv(cases)
    this.setState({ csvLoading: false })
  }

  addNewCaseTemplate = () => {
    // use 1 as a default id for new cases
    history.push(`/dashboard/${this.props.depotId}/cases/1`)
  }

  render () {
    const { depots, archived, archivedCases, cases, deleteCase, cloneCase, depotId, exportCSV } = this.props
    const templates = archived ? archivedCases : cases
    const loading = this.state.csvLoading
    const depot = depots.filter(depot => depot.id === depotId)
    const depotName = depot.length ? depot[0].name : ''

    return (
      <Fragment>
        <UploadBtn type={HYBRID}>
          {loading
            ? <LoadingSpinner show size={1} />
            : <InputFile
              name={'upload csv'}
              filetype={types.CSV}
              onFileRead={this.handleCsvUpload}
              onRequestUpload={() => this.setState({ csvLoading: true })}>
              UPLOAD CSV
            </InputFile>
          }
        </UploadBtn>
        <List listClass={brandGrid} items={templates}>
          {
            !archived &&
              <CaseBox onClick={this.addNewCaseTemplate}>
                ADD NEW CASE TEMPLATE
              </CaseBox>
          }
          {
            templates.map((template, i) =>
              <McCard
                type='case'
                key={i}
                name={template.name}
                id={i}
                description={template.description}
                updatedAt={readDate(template.updated_at)}
                count={template.unique_item_count}
                slug={`${template.item_count} total items`}
                color={template.color}
                url={`/dashboard/${depotId}/cases/${template.id}`}
                deleteCase={() => { deleteCase(template.id) }}
                cloneCase={() => { cloneCase(template.id) }}
                exportCSV={() => { exportCSV(template.id, depotName) }}
              />
            )
          }
          {
            !archived &&
            <McCard
              name='Archived Templates'
              image={archivedImg}
              description={depotName}
              url={`/dashboard/${depotId}/cases?archived=true`}
            />
          }
        </List>
      </Fragment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cases)

const CaseBox = styled.button`
  border-style: dashed;
  border-color: ${Colors.primary[1]};
  color: ${Colors.primary[1]};
  font-size: 1.4rem;
  width: 100%;
  cursor: pointer;
  border-radius: 0.4rem;
  transition: all 0.2s linear;
  box-shadow: 0 -0.2rem 0.5rem rgba(0,0,0,0.1);
  transform: translateZ(0.1rem);
  background: transparent;
  text-transform: uppercase;
  letter-spacing: 0.03rem;
  border: 0.4rem dashed;
  font-weight: 500;

  &:hover {
    transform: translateY(-0.5rem);
  }
`

const UploadBtn = styled(Button)`
  font-family: inherit;
  margin: 2rem auto;
  margin-bottom: 2rem;
  width: 10rem;
  display: block;
`
