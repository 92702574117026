import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { number, oneOfType, string } from 'prop-types'

import {
  DealTag,
  ExclusiveTag,
  ExpressTag,
  FireTag,
  PriceTag,
  RocketTag,
  SparkleTag,
  StopwatchTag
} from 'microcomponents/icons/tags'
import { defaultColor, defaultSize, getColorFromProps } from './display-helper.js'

const TagText = styled.span`
  color: ${(props) => getColorFromProps(props)};
  font-size: ${props => props.size}rem;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle;
`

export default class TagComponent extends PureComponent {
  static propTypes = {
    height: string,
    iconStyle: string,
    size: oneOfType([number, string]),
    text: string,
    type: string,
    width: string
  }

  static defaultProps = {
    color: defaultColor,
    text: '',
    size: defaultSize
  }

  render () {
    const {
      height,
      iconStyle,
      size,
      text,
      type,
      width
    } = this.props

    const color = getColorFromProps(this.props)
    return (
      <div>
        <TagIcon
          color={color}
          type={type}
          style={iconStyle}
          width={width}
          height={height}
        />
        <TagText color={color} size={size}>{text}</TagText>
      </div>
    )
  }
}

export class TagIcon extends PureComponent {
  static propTypes = {
    height: string,
    color: string,
    style: string,
    type: string,
    width: string
  }

  static defaultProps = {
    type: 'default'
  }

  render () {
    const {
      height,
      color,
      style,
      type,
      width
    } = this.props

    // map of backend tag name to front end component
    const tagMap = {
      deal: DealTag,
      default: DealTag,
      exclusive: ExclusiveTag,
      express: ExpressTag,
      fire: FireTag,
      price: PriceTag,
      rocket: RocketTag,
      sparkle: SparkleTag,
      stopwatch: StopwatchTag
    }

    // the fallback accounts for types returned from the backend that don't exist in the map
    const TagSvg = tagMap[type] || tagMap.default

    return (
      <TagSvg color={color} style={style} width={width} height={height} />
    )
  }
}
