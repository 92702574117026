import t from './actionTypes'

export function setMenus (menus) {
  return {
    type: t.SET_MENUS,
    menus
  }
}

export function clearMenus () {
  return {
    type: t.CLEAR_MENUS
  }
}

export function setMenu (menu, slug) {
  return (dispatch, getState) => {
    const state = getState()
    // shallow copy of menus in state
    const menusCopy = {...state.menus}
    menusCopy[slug] = menu

    dispatch(setMenus(menusCopy))
  }
}
