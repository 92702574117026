import React, { PureComponent } from 'react'
import DriverRow from './driver-row'
import styled from '@emotion/styled'
import MCColors from 'helpers/css-variables'
import { array, func, number, oneOfType, string } from 'prop-types'
const { accessory3 } = MCColors

export default class DriverList extends PureComponent {
  static propTypes = {
    driverList: array,
    expandDriverBubble: func,
    focusedDriverId: oneOfType([number, string]),
    listTitle: string,
    setFocusedDriver: func
  }

  render () {
    const {
      driverList,
      expandDriverBubble,
      focusedDriverId,
      listTitle,
      setFocusedDriver
    } = this.props

    return (
      <>
        <DriverListTitle>
          {listTitle} ({driverList.length})
        </DriverListTitle>
        {driverList.map((depotDriver) => {
          return (
            <DriverRow
              key={depotDriver.id}
              depotDriver={depotDriver}
              expandDriverBubble={expandDriverBubble}
              setFocusedDriver={setFocusedDriver}
              focusedDriverId={focusedDriverId}
            />
          )
        })}
      </>
    )
  }
}

const DriverListTitle = styled.div`
  color: ${accessory3};
  font-size: 1.6rem;
  font-weight: bold;
  margin: 1.3rem 0;
  padding: 0 2.4rem;
`
