import React, { PureComponent } from 'react'
import { array, func, string } from 'prop-types'
import List from './list-component'
import McCard from 'src/components/mc-card'
import { FORM_STRING_TYPES } from 'src/helpers/constants'
import formatQuery from 'src/helpers/query-parameter'
import { grid } from '../style.js'
import Create from './create'

const { TYPE } = FORM_STRING_TYPES

export default class TypeList extends PureComponent {
  static propTypes = {
    create: func,
    pathname: string,
    typesSorted: array
  }

  render () {
    const { create, pathname, typesSorted } = this.props
    return (
      <List listClass={grid}>
        <Create
          action={create}
          title='add new type'
        />
        {
          typesSorted.map((type) => {
            const { name = '', id } = type
            const search = formatQuery(TYPE, id)
            return (
              <McCard
                key={type.id}
                description={name}
                url={`${pathname}?${search}`}
              />
            )
          })
        }
      </List>
    )
  }
}
