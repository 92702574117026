import io from 'socket.io-client'
import t from 'src/redux/hub/actionTypes'
import * as hubActions from 'src/redux/hub/actions'
import store from 'src/redux/store'
import { SOCKET_AUTH_KEY, SOCKET_URL } from 'helpers/environment'
import captureException from 'helpers/error-handler'

// socket is a singleton that gets instantiated when we go to hub-and-spoke and unset when we leave that page
// depotId is used to connect to the namespaced socket and must be sent with all requests as well
let socket = null
let DEPOT_ID = null

export const dispatchHubAction = function ({ msgType, data }) {
  let actionToDispatch
  switch (msgType) {
    case t.RECEIVE_PENDING_ORDERS:
      actionToDispatch = hubActions.receivePendingOrders
      break
    case t.RECEIVE_CANCELED_ORDERS:
      actionToDispatch = hubActions.receiveCanceledOrders
      break
    case t.NEW_CONNECTION:
      actionToDispatch = hubActions.newConnection
      break
    case t.ORDER_MODIFIED:
      actionToDispatch = hubActions.orderModified
      break
    case t.MARK_MANIFEST_PRINTED:
      actionToDispatch = hubActions.markDriverManifestPrinted
      break
    case t.CLEAR_STORE:
      actionToDispatch = hubActions.clearStore
      break
    case t.ORDER_CANCELED:
      actionToDispatch = hubActions.orderCanceled
      break
    case t.ERROR_ORDER_ID:
      actionToDispatch = hubActions.errorOrderId
      break
    case t.ERROR:
      actionToDispatch = hubActions.showError
      break
  }

  if (!actionToDispatch) return console.warn('no msgType matched, no action to dispatch', msgType)

  store.dispatch(actionToDispatch(data))
}

export const createSocket = function (depotId) {
  DEPOT_ID = depotId
  socket = io(`${SOCKET_URL}/${DEPOT_ID}?token=${SOCKET_AUTH_KEY}`)

  socket.on('connect_error', (data) => {
    // console.warn('socket.io error connecting to hub-io', data)
    captureException(new Error(data))
  })

  socket.on('connect_timeout', (data) => {
    // console.warn('socket.io timeout connecting to hub-io', data)
    captureException(new Error(data))
  })

  socket.on('reconnect_error', (data) => {
    // console.warn('socket.io error REconnecting to hub-io', data)
    captureException(new Error(data))
  })

  socket.on('reconnect_failed', (data) => {
    // console.warn('socket.io failed to reconnect to hub-io', data)
    captureException(new Error(data))
  })

  socket.on('error', (data) => {
    // console.warn('Websocket error', data)
    captureException(new Error(data))
    if (data === 'Invalid namespace') {
      // when we get an Invalid namespace error it is because the dynamic route has not been
      // created yet on the server, so the client doesn't have one to connect to. If we wait
      // one second before trying a re-connect we should hopefully not spam the backend with
      // reconnect attempts
      setTimeout(function () {
        socket.connect()
      }, 2000)
    }
  })

  socket.on('fromHub', (data) => {
    // logging all socket data even in production to identify issues faster
    console.info('data received from hub!', data)
    dispatchHubAction(data)
  })
}

export const unsetSocket = function () {
  if (socket) socket.disconnect()
  socket = null
  DEPOT_ID = null
}

export const sendMessageToHub = function (data) {
  if (!data.msgType) return console.warn('no msgType, all messages must have a msgType!')
  console.info('sending to hub', data)

  data.depotId = DEPOT_ID // attach depotId to all messages sent to hub
  socket.emit('msg', data)
}
