import React from 'react'
import styled from '@emotion/styled'
import { number, object } from 'prop-types'

const Marker = ({ number, children }) => {
  return (
    <CustomerMarker>
      <CustomerNumberInQueue>{number}</CustomerNumberInQueue>
      <div>{children}</div>
    </CustomerMarker>
  )
}

Marker.propTypes = {
  number: number,
  children: object
}

export default Marker

const CustomerMarker = styled.div`
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 50%;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  height: 2.4rem;
  padding: 0.5rem;
  position: relative;
  text-align: center;
  width: 2.4rem;
  z-index: 3;
`

const CustomerNumberInQueue = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`
