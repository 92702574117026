/**
 * Component to add float labels on top of Input,
 * consumed by higher level inputs components like ./email, ./password, etc.
 */

import React from 'react'
import { func, string } from 'prop-types'
import { css, cx } from 'emotion'

import Input from './input'

const label = css`
  position: absolute;
  z-index: 2;
  top: 1.8rem;
  left: 1.1rem;
  color: var(--colorTextDark);
  font-weight: 200;
  transition: all 0.2s ease-out;
`

const float = css`
  font-size: 1rem;
  top: 1.25rem;
  left: 1.1rem;
`

const container = css`
  position: relative;
`

class InputWithLabel extends React.Component {
  constructor () {
    super()

    this.onChange = this.onChange.bind(this)

    this.state = {
      float: false
    }
  }

  onChange (e) {
    if (e.target.value && e.target.value.length > 0) {
      this.setState({ float: true })
    } else {
      this.setState({ float: false })
    }

    this.props.onChange(e)
  }

  render () {
    const labelStyles = cx({
      [label]: true,
      [float]: this.state.float
    })

    return (
      <div className={cx(container)}>
        <label htmlFor={this.props.name} className={labelStyles}>
          {this.props.placeholder}
        </label>
        <Input {...this.props} onChange={this.onChange} ref={(input) => { this.input = input }} />
      </div>
    )
  }
}

InputWithLabel.propTypes = {
  // ** Required propTypes ** //
  // Other props not listed here will be checked by Input
  // Name of input. Required for submitting values correctly
  name: string.isRequired,
  onChange: func,
  // Placeholder to use in label
  placeholder: string.isRequired
}

export default InputWithLabel
