import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import Button, { PRIMARY } from 'components/button'

import Screen from 'src/components/screen/container'
import ROUTES from 'src/components/router/routes'
import phoneSvg from 'src/assets/phone.svg'

import { container, phoneImage, userOrderCard, userOrderLink } from './style.js'

class CatalogBuilder extends PureComponent {
  render () {
    return (
      <Screen>
        <div className={container}>
          <div className={userOrderCard}>
            <img
              className={phoneImage}
              src={phoneSvg}
            />
            <Link
              className={userOrderLink}
              to={ROUTES.ORDER_BUILDER_SEARCH_USER}
            >
              <Button type={PRIMARY}>Start Customer Order</Button>
            </Link>
          </div>
        </div>
      </Screen>
    )
  }
}

export default CatalogBuilder
