import t from './actionTypes'

const defaultState = {
  isAdmin: false,
  isDispManager: false
}

const permissions = (state = defaultState, { type, payload }) => {
  switch (type) {
    case t.CLEAR_PERMISSIONS:
      return defaultState

    case t.SET_PERMISSIONS:
      return {
        ...state,
        isAdmin: payload.isAdmin,
        isDispManager: payload.isDispManager
      }

    default:
      return state
  }
}

export default permissions
