import { SET_ITEM } from './actionTypes'
import { DEFAULT_STATUS } from './constants'

const defaultState = {
  id: null,
  name: '',
  slug: '',
  price: 0,
  brand: '',
  type: '',
  subtype: '',
  strain: '',
  species: '',
  description: '',
  dietary: '',
  ingredients: '',
  cogs: 0,
  status: DEFAULT_STATUS,
  excise_tax_exempt: false,
  weight: 0,

  thc: 0,
  cbd: 0,
  highCbd: false,
  enabled: false,

  effects: [
    { name: '', amount: '' },
    { name: '', amount: '' },
    { name: '', amount: '' },
    { name: '', amount: '' },
    { name: '', amount: '' }
  ],
  images: []
}

const catalogItem = (state = defaultState, action) => {
  switch (action.type) {
    case SET_ITEM:
      return {
        ...state,
        ...action.item
      }

    default:
      return state
  }
}

export default catalogItem
