import { createStore } from 'redux'
import mcReducers from './index'
import { getEnhancers } from './enhancers'
import { isLikeProduction } from 'helpers/constants'

const store = createStore(mcReducers, {}, getEnhancers())

if (!isLikeProduction && module.hot) {
  module.hot.accept('./index.js', () => {
    console.info('replacing reducers')
    store.replaceReducer(require('./index.js').default)
  })
}

export default store
