/**
 * Simple utility to display prices with dollar signs and cents.
 * @param  {Number} price - base price, expected in dollars.
 * @return {String}         Return string will include '$' and denote amount in cents.
 */
export function numberToPrice (price) {
  return `$${fixNum(price).toFixed(2)}`
}

function fixNum (number) {
  return typeof number === 'number' ? number : parseFloat(number)
}

export function getPrice (price, prices) {
  return price || (prices[0] && prices[0].price)
}

export function displayPriceFormatter (amount) {
  return amount >= 0
    ? `${numberToPrice(amount)}`
    : `-${numberToPrice(amount * -1)}`
}
