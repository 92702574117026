// https://github.com/eaze/web-ui/tree/master/packages/typography
import SectionTitle from './section-title'
import Subtext from './subtext'
import Title from './title'
import Label from './label'
import Text from './text'

export {
  SectionTitle,
  Subtext,
  Title,
  Label,
  Text
}
