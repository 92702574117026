export default {
  LOGIN: '/login',
  ERROR: '/error',
  INVENTORY: '/inventory',
  INVENTORY_UPDATE: '/inventory-update',
  USERS: '/users',
  DELIVERIES: '/deliveries',
  PROMOS: '/promos',
  PROMOS_TAGS: '/promos/tags',
  DEPOT_INVENTORY: '/depots/:id/inventory',
  MENUS: '/menus',

  CATALOG: '/catalog/:view',
  CATALOG_PRODUCTS: '/catalog/products',
  EDIT_CATALOG_GROUP: '/catalog/groups/edit/:id',
  EDIT_CATALOG_BRAND: '/catalog/brands/edit/:id',
  EDIT_CATALOG_MENU: '/catalog/menus/edit/:id',
  ADD_CATALOG_GROUP: '/catalog/groups/create',
  ADD_CATALOG_BRAND: '/catalog/brands/create',
  ADD_CATALOG_MENU: '/catalog/menus/create',

  ORDER_BUILDER: '/order-builder',
  ORDER_BUILDER_SEARCH_USER: '/order-builder/search/user',
  ORDER_BUILDER_USER: '/order-builder/user/:id',

  ADULT_USE_TERRITORIES: '/territory-management',

  DASHBOARD: '/dashboard',
  DEPOT_DASHBOARD: '/dashboard/:id/:tab',
  DEPOT_DASHBOARD_TASKS: '/dashboard/:id/:tab/:task',
  CASE_BUILDER_ID: '/dashboard/:id/cases/:caseId'
}
