import React from 'react'
import { func, array, bool } from 'prop-types'
import { Column } from 'src/microcomponents/flexbox-helpers'
import styled from '@emotion/styled'
import InputLabel from 'src/microcomponents/input-label'
import DepotOption from './depot-option.js'
import Accordion from 'components/accordion'
import { selectContainer } from '../../style.js'

const DepotSelect = ({ onDepotSelect, depots = [], selectedDepotIds = [], allDepotsSelected }) => {
  // Unfortunately selectedDepotIds will sometimes come in as null, preventing default.
  // So we'll derive a value for that...
  const selectedCount = (selectedDepotIds && selectedDepotIds.length) || 0

  const setDepots = (ids = []) => {
    let newSelectedDepotIds = []
    if (ids.length === depots.length || ids.length === 0) {
      newSelectedDepotIds = ids
    } else {
      newSelectedDepotIds = [
        ...selectedDepotIds.filter(id => !ids.includes(id)),
        ...ids.filter(id => !selectedDepotIds.includes(id))
      ]
    }
    onDepotSelect(newSelectedDepotIds)
  }

  const handleToggleAllDepots = event => {
    event.stopPropagation()
    if (selectedCount === depots.length) {
      setDepots()
    } else {
      setDepots(depots.map((depot) => depot.id))
    }
  }

  const getAccordionReadout = () => {
    const depotCountText = (count) => `${typeof count === 'number' ? '(' + count + ')' : count} selected`
    const text = depotCountText(selectedCount)
    const allDepotsSelected = selectedCount !== 0 && selectedCount === depots.length

    return (
      <div>
        <DepotSelectAllCheckbox
          type='checkbox'
          checked={allDepotsSelected}
          onClick={handleToggleAllDepots}
        />{text}
      </div>
    )
  }

  return (
    <Column top={1} bottom={1}>
      <InputLabel content='Depots' />
      <Accordion
        className={selectContainer}
        buttonContent={getAccordionReadout()}
        fontColor='white'
        caretColor='white'
        iconColor='#fff'
      >
        {depots.map((depot, i) => (
          <DepotOption
            key={depot.id}
            onClick={() => setDepots([depot.id])}
            selected={selectedDepotIds ? selectedDepotIds.indexOf(depot.id) > -1 : false}
            name={depot.name}
          />
        ))}
      </Accordion>
    </Column>
  )
}

const DepotSelectAllCheckbox = styled.input`
  margin-right: 1rem;
  width: 2rem;
`
DepotSelect.propTypes = {
  onDepotSelect: func,
  depots: array,
  selectedDepotIds: array,
  allDepotsSelected: bool
}

export default DepotSelect
