import t from './action-types'
import { setPromoDispensary, setPromoDepots, setPromoStateId, setPromoType, setPromoIdValue, setPromoAppliesTo, setSelectedPromoDefinition, setPromoIdType } from './actions'

export function setPromoDepotsAndClearDispensary (depotIds) {
  return (dispatch, getState) => {
    dispatch(setPromoDepots(depotIds))
    dispatch(setPromoDispensary(null))
  }
}

export function setPromoDispensaryAndClearDepots (dispensaryId) {
  return (dispatch, getState) => {
    dispatch(setPromoDispensary(dispensaryId))
    dispatch(setPromoDepots(null))
  }
}

export function setPromoStateIdAndClearRelated (stateId) {
  return (dispatch, getState) => {
    const state = getState()
    dispatch(resetPromoTypeAndRelated())
    if (state.managePromos['rules.user_cohort.depot_ids']) {
      dispatch(setPromoDepots(null))
    } else {
      dispatch(setPromoDispensary(null))
    }
    dispatch(setPromoStateId(stateId))
  }
}

export function resetPromoTypeAndRelated () {
  return (dispatch, getState) => {
    dispatch(setSelectedPromoDefinition(0))
    dispatch(setPromoType(null))
    dispatch(setPromoAppliesTo(null))
    dispatch(setPromoIdType(null))
    dispatch(setPromoIdValue(null))
    dispatch({ type: t.SET_CATALOG_NAME, name: '' })
  }
}
