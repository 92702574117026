import api from 'api'

import history from 'components/router/history'
import { requestCatalogGroup, receiveCatalogGroup } from '../loading/actions'
import { pushNotification, SUCCESS } from '../alerts/actions'
import { setCatalogGroups, setCatalogStateFilter, fetchCatalogEntity } from '../catalog/actions'
import { dedupeArrayByObjectKey } from 'src/utils/helpers'
import ROUTES from 'src/pages/catalog/routes'
import t from './actionTypes'

export function fetchGroupAndApplyStateFilter (id) {
  return async dispatch => {
    const groupItem = await dispatch(fetchGroupByID(id))
    dispatch(setCatalogStateFilter(groupItem.state_id))
    dispatch(fetchCatalogEntity(ROUTES.products.name))
  }
}

export function fetchGroupByID (id, storeResult = true) {
  return async dispatch => {
    dispatch(requestCatalogGroup())

    const { err, data } = await api.getCatalogGroup({ id })
    dispatch(receiveCatalogGroup())

    if (err) return
    if (storeResult) {
      dispatch(updateGroupObject(data))
    }

    return data
  }
}

export function sortedItems (groupItems) {
  return dispatch => {
    const deDupedGroupItems = dedupeArrayByObjectKey(groupItems, 'id')

    dispatch(setGroupItems(deDupedGroupItems))
  }
}

export function setGroupItemsByFilter (filters = {}) {
  return async dispatch => {
    const { err, data } = await api.getCatalogItemsByFilter(filters)
    if (err) return console.error(err)
    const items = dedupeArrayByObjectKey(data, 'id')
    dispatch(setGroupItems(items))
  }
}

function pruneItemForPayload (item) {
  // The current pattern passes in all of group item as state then prunes it here in redux before passing into API
  // passing around this many props seems like it can be optimized with model transformation pattern
  delete item.pending
  delete item.showColorPicker
  delete item.currentTab
  delete item.tabs
  delete item.saveAllowed
  // only update this if item.depotsEnabled exists to filter through
  if (item.depotsEnabled) {
    item.depots_enabled = item.depotsEnabled.filter(({ enabled }) => enabled).map(({ id }) => id)
  }
  delete item.depotsEnabled
  delete item.hasSetDepotsEnabled

  return item
}

export function createOrUpdateGroup () {
  return async (dispatch, getState) => {
    const state = getState()

    // clone item so we don't mutate UI
    const item = {
      ...state.groupItem
    }

    const prunedItem = pruneItemForPayload(item)

    dispatch(requestCatalogGroup())
    const groupItem = await dispatch(fetchGroupByID(state.groupItem.id, false))
    if (state.groupItem.updated_at !== groupItem.updated_at) {
      return window.alert('Aw, shucks! It is with our deepest sympathies that we must inform you that your edits have been lost (someone else edited this group while you were making your changes, try again after reloading!)')
    }

    prunedItem.items = prunedItem.items.map(({ id }) => ({ id }))

    if (prunedItem.id) {
      // Update Item!
      const { err, data } = await api.updateCatalogGroup(prunedItem)
      dispatch(receiveCatalogGroup())
      if (err) return

      dispatch(updateGroupObject(data))
      // reset groups to empty array, we're refetching them once the next component mounts
      dispatch(setCatalogGroups([]))

      dispatch(pushNotification(`Successfully edited ${prunedItem.name} group`, SUCCESS))

      history.push('/catalog/groups')
    } else {
      //  we don't need this, so let's not send it.
      delete prunedItem.id

      // Create Item!
      const { err } = await api.createCatalogGroup(prunedItem)
      dispatch(receiveCatalogGroup())
      if (err) return

      dispatch(pushNotification(`Successfully created ${prunedItem.name} group`, SUCCESS))

      // reset groups to empty array, we're refetching them once the next component mounts
      dispatch(setCatalogGroups([]))

      history.push('/catalog/groups')
    }
  }
}

export function setGroupItems (items) {
  return {
    type: t.SET_GROUP_ITEMS,
    items
  }
}

export function resetGroupItem () {
  return {
    type: t.RESET_GROUP_ITEM
  }
}

export function updateGroupObject (obj) {
  return {
    type: t.UPDATE_GROUP_OBJECT,
    obj
  }
}
