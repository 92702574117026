import React from 'react'
import ProductCard from 'src/components/product-card'
import { formatDateTimeWithZone } from 'src/helpers/date'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import catalogToProduct from 'src/helpers/catalog-to-product'
import { PROMOS_IANA_TIME_ZONE } from 'src/helpers/promos/definitions'
import {
  dateTimeValue,
  detailsDepotName,
  detailsDivider,
  detailsLabel,
  detailsRow,
  detailsRowDispensary,
  detailsValue,
  previewContent,
  productCardGrid,
  title
} from '../style.js'

const ProductPreview = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  justify-content: ${({ count }) => count > 1 ? 'start' : 'space-around'};
`
export default class TagDetails extends React.PureComponent {
  static propTypes = {
    brandsMap: PropTypes.object,
    tagData: PropTypes.shape({
      description: PropTypes.string,
      expiresAt: PropTypes.string,
      id: PropTypes.string,
      items: PropTypes.array,
      markets: PropTypes.array,
      startAt: PropTypes.string,
      tagType: PropTypes.string,
      text: PropTypes.string,
      state_id: PropTypes.string
    }),
    isEdit: PropTypes.bool,
    productsMap: PropTypes.object,
    selectedState: PropTypes.string,
    selectedDepots: PropTypes.object,
    selectedProducts: PropTypes.object,
    speciesMap: PropTypes.object,
    depotsMap: PropTypes.object,
    headerText: PropTypes.string
  }

  static defaultProps = {
    tagData: {}
  }

  render () {
    const {
      isEdit,
      brandsMap,
      depotsMap,
      headerText,
      productsMap,
      selectedState,
      selectedDepots,
      selectedProducts,
      speciesMap,
      tagData
    } = this.props

    const transformedProducts = Object.keys(selectedProducts).map((id) => {
      return catalogToProduct({
        catalogItem: productsMap[id] || {},
        brandsMap,
        speciesMap,
        tag: { text: tagData.text }
      })
    })

    return (
      <>
        <h1 className={title}>{headerText}</h1>
        <div className={previewContent}>
          <ProductPreview count={transformedProducts.length}>
            {
              transformedProducts.map((product, index) => {
                // due to a check in the productcard component (from web-ui),
                // if isAvailable is undefined, it will grey out the card and
                // show an out of stock message
                // we avoid that by defaulting to true if the flag is undefined,
                // it feels like this should always be set by the backend though
                if (typeof product.isAvailable === 'undefined') {
                  product.isAvailable = true
                }
                return (
                  <div
                    key={`product_preview_${index}`}
                    className={productCardGrid}
                  >
                    <ProductCard
                      product={product}
                      tagData={tagData}
                      cart={{}}
                      cartItem={{}}
                    />
                  </div>
                )
              })
            }
          </ProductPreview>
          <div>
            <div className={detailsRow}>
              <div className={detailsLabel}>Description:</div>
              <div className={detailsDivider} />
              <div className={detailsValue}>{tagData.description}</div>
            </div>
            <div className={detailsRow}>
              <div className={detailsLabel}>Start:</div>
              <div className={detailsDivider} />
              <div className={dateTimeValue}>{formatDateTimeWithZone(tagData.startAt, PROMOS_IANA_TIME_ZONE)}</div>
            </div>
            <div className={detailsRow}>
              <div className={detailsLabel}>End:</div>
              <div className={detailsDivider} />
              <div className={dateTimeValue}>{formatDateTimeWithZone(tagData.expiresAt, PROMOS_IANA_TIME_ZONE)}</div>
            </div>
            {
              (tagData.state_id || (!isEdit && selectedState)) && (
                <div className={detailsRow}>
                  <div className={detailsLabel}>State:</div>
                  <div className={detailsDivider} />
                  <div className={detailsValue}>{tagData.state_id || selectedState}</div>
                </div>
              )
            }
            <div className={detailsRowDispensary}>
              <div className={detailsLabel}>Dispensary:</div>
              <div className={detailsDivider} />
              <div className={detailsValue}>
                {Object.keys(selectedDepots).map((id, index) => {
                  const depot = depotsMap[id] || {}

                  return (
                    <span
                      key={index}
                      className={detailsDepotName}
                    >
                      {depot.name}
                    </span>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
