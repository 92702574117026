import React, { useState, useEffect } from 'react'
import { bool, func, object } from 'prop-types'
import styled from '@emotion/styled'
import { css, keyframes } from 'emotion'

const Overlay = ({ isOpen, onClick, overlayStyle }) => {
  const [shouldFadeOut, setShouldFadeOut] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setShouldFadeOut(false)
    } else {
      setShouldFadeOut(true)
    }
  }, [isOpen])

  return (
    <OverlayContainer className={shouldFadeOut ? FadeOutCSS : ''}
      isOpen={isOpen}
      onClick={onClick}
      style={{ ...overlayStyle }}
    />
  )
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  50% { 
    opacity: 0.3;
  }
  100% {
    opacity: 0.6;
  }
`

const fadeOut = keyframes`
  0% {
    opacity: 0.6;
  }
  50% { 
    opacity: 0.3;
  }
  100% {
    opacity: 0;
  }
`

const OverlayContainer = styled.div`
  background-color: black;
  bottom: 0;
  left: 0;  
  right: 0;
  top: 0;
  opacity: 0;
  animation: ${({ isOpen }) => isOpen ? `${fadeIn} 0.2s linear forwards` : 'none'};
  pointer-events: ${({ isOpen }) => isOpen ? 'auto' : 'none'};
  position: fixed;
  z-index: 10;
`

const FadeOutCSS = css`
  animation: ${fadeOut} 0.2s linear forwards;
`

Overlay.propTypes = {
  isOpen: bool,
  onClick: func,
  overlayStyle: object
}

export default Overlay
