import React, { PureComponent } from 'react'
import { node } from 'prop-types'
import styled from '@emotion/styled'

const Container = styled.div`
  line-height: 2rem;
  padding: 1rem 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: 'Proxima', 'Open Sans', Helvetica;
  font-size: 1.6rem;
`

export default class ContainerComponent extends PureComponent {
  static propTypes = {
    children: node
  }

  render () {
    return (
      <Container>{this.props.children}</Container>
    )
  }
}
