// this component is mostly copied from <CatalogSearch /> from 'src/components/catalog-search/container' but pared down to just search catalog groups

import React, { useState } from 'react'
import { array, func, string } from 'prop-types'
import styled from '@emotion/styled'
import { css } from 'emotion'
import debounce from 'debounce'
import Autosuggest from 'react-autosuggest'

import { blazeUpBlack, darkDivider, mcBlue, primaryDark, white } from 'helpers/css-variables'

const CatalogGroupSearch = (props) => {
  const [value, setValue] = useState('')
  const { list, searchCatalogGroups, setResult, stateId } = props

  const onChange = (event, { newValue }) => {
    setValue(newValue)
  }

  const getSuggestionValue = (suggestion) => suggestion.name

  const retrieveSuggestions = debounce(() => {
    const v = value.trim()

    if (v.length < 2) return

    searchCatalogGroups(v, stateId)
  }, 200)

  const onSelect = (e, { suggestion }) => {
    setResult(suggestion.id)
    setValue(suggestion.name)
  }

  const renderSuggestion = (suggestion) => <div>{suggestion.name} <GroupSlug>/{suggestion.slug}</GroupSlug></div>

  return (
    <Autosuggest
      suggestions={list}
      getSuggestionValue={getSuggestionValue}
      onSuggestionsFetchRequested={retrieveSuggestions}
      onSuggestionSelected={onSelect}
      renderSuggestion={renderSuggestion}
      inputProps={{
        value,
        onChange,
        type: 'search',
        placeholder: 'Search for catalog groups...'
      }}
      theme={localTheme}
    />
  )
}

const localTheme = {
  container: css`
    background-color: ${primaryDark};
    position: relative;
    width: 100%;

    @media(max-width: 767px) {
      margin-top: 0;
    }

    &::before {
      content: '';
      position: absolute;
      background-size: 2rem;
      top: 0;
      left: 0.2rem;
      bottom: 0;
      padding: 2rem;
      box-sizing: border-box;

      @media (max-width: 767px) {
        background-size: 1.4rem;
        left: 0.167rem;
        top: 0;
        padding: 1.6rem;
      }

      @media (max-width: 374px) {
        background-size: 1.2rem;
      }
    }
  `,
  suggestionsList: css`
    background: ${primaryDark};
    border: 1px solid ${darkDivider};
    color: ${white};
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1001;
  `,
  suggestion: css`
    padding: 0.5rem 1.5rem;
    position: relative;
  `,
  suggestionHighlighted: css`
    background-color: ${mcBlue};
  `,
  input: css`
    border: none;
    color: ${white};
    font-size: 1.6rem;
    outline: none;
    padding: 1rem;
    width: 100%;

    &::-webkit-input-placeholder {
      color: ${white};
      opacity: 0.4;
    }

    &[aria-expanded="true"] {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  `
}

const GroupSlug = styled.span`
  color: ${blazeUpBlack};
`

CatalogGroupSearch.propTypes = {
  list: array,
  searchCatalogGroups: func,
  setResult: func.isRequired,
  stateId: string
}

export default CatalogGroupSearch
