import React, { PureComponent } from 'react'
import Switch from 'rc-switch'
import styled from '@emotion/styled'
import { array, bool, func, oneOfType, string } from 'prop-types'

// rc-switch styles are applied globally in src/index.js
// import rcSwitchStyles from './style.js'

export default class EazeSwitch extends PureComponent {
  static defaultProps = {
    onClick: () => {},
    onChange: () => {},
    checkedChildren: '✔',
    unCheckedChildren: '𝗫',
    className: ''
  }

  static propTypes = {
    customLabel: string,
    checkedChildren: string,
    enabled: bool,
    unCheckedChildren: oneOfType([array, string]),
    className: string,
    onChange: func,
    onClick: func
  }

  render () {
    const { customLabel, checkedChildren, enabled, unCheckedChildren, className, onClick } = this.props

    return (
      <label>
        <Switch
          checked={enabled}
          checkedChildren={checkedChildren}
          unCheckedChildren={unCheckedChildren}
          onClick={() => onClick()}
          onChange={(event) => {
            this.props.onChange(event)
          }}
          className={className}
        />
        {customLabel && <HybridLabel>{customLabel}</HybridLabel>}
      </label>
    )
  }
}

const HybridLabel = styled.div`
  display: inline-block;
  margin-left: 1rem;
`
