import React, { PureComponent } from 'react'
import { func, number, string } from 'prop-types'
import window from 'global/window'

import { DELIVERY_TYPES } from 'helpers/drivers'
import ArrowIcon from 'src/microcomponents/icon-arrow'

import styled from '@emotion/styled'

import MCColors from 'src/helpers/css-variables'
const { tertiaryDark, white } = MCColors

export default class DeliveryTypeSelect extends PureComponent {
  static propTypes = {
    deliveryType: string,
    driverId: number,
    setDriverDeliveryType: func
  }

  static defaultProps = {
    setDriverDeliveryType: function () {
      console.warn('setDriverDeliveryType() not implimented in <DeliveryTypeSelect />')
    }
  }

  handleDriverDeliveryTypeChange = (evt) => {
    const { target: { value: newDeliveryType } } = evt
    const { driverId, setDriverDeliveryType } = this.props
    const confirmed = window.confirm(`Are you sure you want to change the driver to ${newDeliveryType.toUpperCase()}?`)

    if (confirmed) {
      setDriverDeliveryType(driverId, newDeliveryType)
    }
  }

  render () {
    const { deliveryType } = this.props

    return (
      <Container>
        <TypeSelect
          onChange={this.handleDriverDeliveryTypeChange}
          value={deliveryType}
        >
          {
            Object.values(DELIVERY_TYPES).map(option => {
              return (
                <option value={option} key={option}>
                  {option}
                </option>
              )
            })
          }
        </TypeSelect>
        <ArrowIconWrapper>
          <ArrowIcon height='14px' width='14px' />
        </ArrowIconWrapper>
      </Container>
    )
  }
}

const Container = styled.div`
  background-color: ${tertiaryDark};
  border-radius: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 12rem;
`

const TypeSelect = styled.select`
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  color: ${white};
  padding: 0.9rem;
  padding-right: 4rem;
  padding-left: 1.6rem;
  cursor: pointer;
  width: 100%;
  text-overflow: ellipsis;
`

const ArrowIconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  right: 0;
  top: 0;
  padding-right: 0.9rem;
  pointer-events: none;
`
