import React, { PureComponent } from 'react'
import { array, func } from 'prop-types'
import List from './list-component'
import McCard from 'src/components/mc-card'
import Create from './create'
import { brandGrid } from '../style.js'

export default class BrandList extends PureComponent {
  static propTypes = {
    brands: array,
    create: func
  }

  render () {
    const { brands, create } = this.props

    return (
      <List listClass={brandGrid} items={brands}>
        <Create
          action={create}
          title='add new brand'
        />
        {
          brands.slice(0, 100).map(b => (
            <McCard
              key={b.id}
              name={b.name}
              headerImage={b.header_image}
              id={b.id}
              description={b.about}
              count={b.item_count}
              slug={b.slug}
              color={'red'}
              url={`/catalog/brands/edit/${b.id}`}
            />
          ))
        }
      </List>
    )
  }
}
