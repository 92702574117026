import { func, string } from 'prop-types'
import React from 'react'

import { jumboCreate } from '../style.js'

const Create = ({ action, title }) => (
  <button className={jumboCreate} onClick={() => action()}>{title}</button>
)

Create.propTypes = {
  action: func,
  title: string
}

export default Create
