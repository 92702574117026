import { createSelector } from 'reselect'
import sortOn from 'sort-on'
import doubleSort from 'double-sort'
import fuzzysearch from 'fuzzysearch'
import isEmptyObject from 'is-empty-object'

import { getDriverMap } from 'src/redux/driver-map/selectors'
import { getDriverInventoryMap } from 'src/redux/driver-inventory-map/selectors'
import { getDriverOrdersMap } from 'src/redux/driver-orders-map/selectors'

import {
  getDefaultDriverObjectWithLocation,
  DELIVERY_TYPES
} from 'helpers/drivers'

const getDeliveriesStore = state => state.deliveries
const getDriverList = createSelector(
  [getDriverMap],
  driverMap => Object.values(driverMap)
)
export const getDeliveriesFilter = state => state.deliveries.filter
const getMode = state => state.deliveries.mode
export const getDeliveryType = state => state.deliveries.deliveryType

export const getDriverDetailsContent = createSelector(
  [getDeliveriesStore],
  deliveriesStore => deliveriesStore.driverDetailsContent
)

export const getFocusedDriverId = createSelector(
  [getDeliveriesStore],
  deliveriesStore => deliveriesStore.focusedDriverId
)

export const getFocusedDriver = createSelector(
  [getFocusedDriverId, getDriverMap],
  (focusedDriverId, driverMap) =>
    driverMap[focusedDriverId] || getDefaultDriverObjectWithLocation()
)

export const getIsDriverFocused = createSelector(
  [getFocusedDriverId],
  driverId => {
    return Boolean(driverId || driverId === 0)
  }
)

export const getDepotFilterString = createSelector(
  [getDeliveriesStore],
  deliveriesStore => {
    return deliveriesStore.depotFilterString
  }
)

const getDeliveryDrivers = createSelector(
  [getDriverList, getDeliveriesFilter, getMode, getDeliveryType],
  (drivers, filters, mode, deliveryType) => {
    drivers = doubleSort(drivers, 'pendingOrders', 'driverMode').reverse()

    drivers = filterOutDrivers(drivers, mode, deliveryType)

    drivers = drivers.filter(d => {
      return filters.some(filterObj => {
        return d.depotName === filterObj.value
      })
    })

    return drivers
  }
)

function filterOutDrivers (drivers, mode, deliveryType) {
  if (mode) {
    drivers = drivers.filter(
      d => d.driverModeDescription === mode.toUpperCase()
    )
  }

  if (deliveryType) {
    drivers = drivers.filter(d => d.deliveryType === deliveryType)
  }

  return drivers
}

export const getDriverMapMarkers = createSelector(
  [getDriverList, getDeliveriesFilter, getMode, getDeliveryType],
  (drivers, filter, mode, deliveryType) => {
    let newDrivers = []

    if (filter.length) {
      filter.forEach(({ key, value }) => {
        newDrivers = newDrivers.concat(drivers.filter(d => d[key] === value))
      })
    } else {
      newDrivers = [...drivers]
    }

    const filteredDrivers = filterOutDrivers(newDrivers, mode, deliveryType)
    const driversWithLocation = filteredDrivers.filter(
      d => !isEmptyObject(d.location)
    )

    return driversWithLocation
  }
)

export const getDriverDictionary = createSelector(
  [getDriverList, getMode, getDeliveryType],
  (drivers, mode, deliveryType) => {
    let driverDictionary = {}

    drivers = filterOutDrivers(drivers, mode, deliveryType)

    drivers.forEach(driver => {
      if (driverDictionary[driver.depotName]) {
        driverDictionary[driver.depotName].push(driver)
      } else {
        driverDictionary[driver.depotName] = [driver]
      }
    })

    Object.entries(driverDictionary).map(([key, value]) => {
      driverDictionary[key] = sortOn(value, ['-pendingOrders', 'driverMode'])
    })

    // hack to sort object keys by converting to array, sorting, and creating a new object upon the sorted array.
    return Object.keys(driverDictionary)
      .sort()
      .reduce((acc, k) => {
        acc[k] = driverDictionary[k]

        return acc
      }, {})
  }
)

export const getFilteredDrivers = createSelector(
  [getDriverDictionary, getDeliveriesFilter],
  (drivers, filter) => {
    const filteredDrivers = {}
    filter.forEach(({ value }) => {
      // falling back to empty array because when we fetch from depot dashboard
      // we do not get drivers for that depot
      const driversList = drivers[value] || []

      // using a map so we can leverage sortOn and enforce a custom sort order
      const sortMap = {
        DELIVERY: 0,
        HOLD: 1,
        ON: 2
      }
      const sortedDriversList = sortOn(
        driversList,
        driver => sortMap[driver.driverModeDescription]
      )

      const driversByType = {
        [DELIVERY_TYPES.DYNAMIC]: [],
        [DELIVERY_TYPES.BATCH]: []
      }

      sortedDriversList.forEach(function (driver) {
        // assume only dynamic or batch
        if (driver.deliveryType === DELIVERY_TYPES.DYNAMIC) {
          driversByType[DELIVERY_TYPES.DYNAMIC].push(driver)
        }

        if (driver.deliveryType === DELIVERY_TYPES.BATCH) {
          driversByType[DELIVERY_TYPES.BATCH].push(driver)
        }
      })

      filteredDrivers[value] = driversByType
    })

    return filteredDrivers
  }
)

export const getFilteredDepots = createSelector(
  [getDriverDictionary, getDepotFilterString],
  (driverDictionary, depotFilterString) => {
    const filteredDriverDictionary = {}

    Object.keys(driverDictionary).forEach(function (depotName) {
      if (
        fuzzysearch(depotFilterString.toLowerCase(), depotName.toLowerCase())
      ) {
        filteredDriverDictionary[depotName] = driverDictionary[depotName]
      }
    })

    return filteredDriverDictionary
  }
)

export const getDriverStatusTotals = createSelector(
  [getDeliveryDrivers],
  drivers => {
    let delivering = []
    let off = []
    let hold = []
    let idle = []
    let total = []

    drivers.forEach(d => {
      if (d.driverModeDescription === 'DELIVERY') {
        delivering.push(d.id)
      } else if (d.driverModeDescription === 'OFF') {
        off.push(d.id)
      } else if (d.driverModeDescription === 'HOLD') {
        hold.push(d.id)
      } else if (d.driverModeDescription === 'ON') {
        idle.push(d.id)
      }

      total.push(d.id)
    })

    return {
      delivering: delivering.length,
      off: off.length,
      hold: hold.length,
      idle: idle.length,
      total: total.length
    }
  }
)

export const getDriverUtilization = createSelector(
  [getDriverStatusTotals],
  drivers => {
    return `${Math.floor((drivers.delivering / drivers.total) * 100)}%`
  }
)

export const getDepotDriversOverview = createSelector(
  [getDriverList],
  drivers => {
    let collection = {}

    drivers.forEach(driver => {
      if (!collection[driver.depotName]) {
        collection[driver.depotName] = []
      }

      collection[driver.depotName].push(driver)
    })

    return collection
  }
)

export const getDriverUtilizationOverview = createSelector(
  [getDepotDriversOverview],
  driverMap => {
    const keys = Object.keys(driverMap)
    let depotTotals = {}
    let collection = []

    if (keys.length) {
      keys.forEach(k => {
        depotTotals[k] = {
          name: k,
          idle: 0,
          delivering: 0,
          hold: 0,
          off: 0,
          total: 0
        }

        let drivers = driverMap[k]

        drivers.forEach(d => {
          if (d.driverModeDescription === 'DELIVERY') {
            depotTotals[k].delivering = depotTotals[k].delivering + 1
          } else if (d.driverModeDescription === 'OFF') {
            depotTotals[k].off = depotTotals[k].off + 1
          } else if (d.driverModeDescription === 'HOLD') {
            depotTotals[k].hold = depotTotals[k].hold + 1
          } else if (d.driverModeDescription === 'ON') {
            depotTotals[k].idle = depotTotals[k].idle + 1
          }

          depotTotals[k].total = depotTotals[k].total + 1
        })

        let u = {}
        u['utilization'] = Math.floor(
          (depotTotals[k].delivering / depotTotals[k].total) * 100
        )
        collection.push(Object.assign({}, depotTotals[k], u))
      })
    }
    return sortOn(collection, 'utilization')
  }
)

export const getDeliveryTypeCounts = createSelector(
  [getDeliveryDrivers],
  drivers => {
    const counts = { dynamic: 0, batch: 0 }

    drivers.forEach(d => {
      if (d.deliveryType) {
        counts[d.deliveryType.toLowerCase()]++
      }
    })

    return counts
  }
)

const EMPTY_DRIVER_INVENTORY = { inventory: [], inventoryChecksum: {} }
export const getFocusedDriverInventory = createSelector(
  [getFocusedDriverId, getDriverInventoryMap],
  (focusedDriverId, driverInventoryMap) => {
    return driverInventoryMap[focusedDriverId] || EMPTY_DRIVER_INVENTORY
  }
)

const EMPTY_DRIVER_ORDERS = []
export const getFocusedDriverOrders = createSelector(
  [getFocusedDriverId, getDriverOrdersMap],
  (focusedDriverId, driverOrdersMap) => {
    return driverOrdersMap[focusedDriverId] || EMPTY_DRIVER_ORDERS
  }
)

export const getDeliveriesMapView = createSelector(
  [getDeliveriesStore],
  deliveriesStore => {
    return deliveriesStore.mapView
  }
)

export const getDeliveriesMaps = createSelector(
  [getDeliveriesStore],
  deliveriesStore => {
    return deliveriesStore.maps
  }
)

export const getDeliveriesMode = createSelector(
  [getDeliveriesStore],
  deliveriesStore => {
    return deliveriesStore.mode
  }
)

export const getDeliveriesDepotOverview = createSelector(
  [getDeliveriesStore],
  deliveriesStore => {
    return deliveriesStore.depotOverview
  }
)

export const getDriverInventoryLoadingMap = createSelector(
  [getDeliveriesStore],
  deliveriesStore => {
    return deliveriesStore.driverInventoryLoadingMap
  }
)

export const getDriverOrdersLoadingMap = createSelector(
  [getDeliveriesStore],
  deliveriesStore => {
    return deliveriesStore.driverOrdersLoadingMap
  }
)

export const getIsFocusedDriverInventoryLoading = createSelector(
  [getFocusedDriverId, getDriverInventoryLoadingMap],
  (driverId, driverInventoryMap) => {
    return driverInventoryMap[driverId]
  }
)

export const getIsFocusedDriverOrdersLoading = createSelector(
  [getFocusedDriverId, getDriverOrdersLoadingMap],
  (driverId, driverOrdersMap) => {
    return driverOrdersMap[driverId]
  }
)
