import React from 'react'

// will inherit size from parent container
// button dimensions defined in parent container
const TrashIcon = ({ width = '100%', height = '100%'}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 16'
  >
    <g fill='#EF5A4B'>
      <path d='M.435 2.894h1.67l.4 11.744c.017.732.643 1.31 1.39 1.31h8.21c.747 0 1.373-.578 1.39-1.31l.4-11.744h1.67a.345.345 0 00.348-.34.345.345 0 00-.348-.341h-4.73V1.055c0-.561-.47-1.021-1.044-1.021H6.21c-.574 0-1.044.46-1.044 1.021v1.158H.435a.345.345 0 00-.348.34c0 .187.156.34.348.34zM5.86 1.055c0-.187.156-.34.348-.34h3.6c.191 0 .348.153.348.34v1.158H5.86V1.055zm7.34 1.839l-.4 11.727a.687.687 0 01-.696.664H3.896a.687.687 0 01-.696-.664L2.8 2.894h10.4z' />
      <path d='M8 13.532a.345.345 0 00.348-.34v-8.34A.345.345 0 008 4.51a.345.345 0 00-.348.34v8.34c0 .188.157.34.348.34zm2.609 0a.345.345 0 00.348-.34v-8.34a.345.345 0 00-.348-.341.345.345 0 00-.348.34v8.34c0 .188.156.34.348.34zm-5.218 0a.345.345 0 00.348-.34v-8.34a.345.345 0 00-.348-.341.345.345 0 00-.348.34v8.34c0 .188.157.34.348.34z' />
    </g>
  </svg>
)

export default TrashIcon
