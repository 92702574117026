/**
 * Utility to send errors to Sentry instead of throwing them.
 */

import * as Sentry from '@sentry/browser'
import { EAZE_ENVIRONMENT, SENTRY, VERSION } from 'helpers/environment'

// const ignoreErrors = [ ] // array of strings/regular expressions to ignore

// allow errors from these domains _only_
const whitelistUrls = [
  'eaze.com',
  'eazeup.com',
  'eaze.dev', // dev clusters
  'event-proxy.eaze.com',
  'localhost:9964', // local dev env
  'mc.eaze.com',
  'www.eaze.com'
]

// init Sentry SDK with our dsn and tag errors sent from this instance of the app with its version
// we also include the environment (dev, staging, prod)
// note that the SDK provides a `beforeSend` callback we could use to filter errors, we should do this in nextgen
// in mc-next VERSION is a global variable

if (SENTRY && SENTRY !== 'test') {
  Sentry.init({
    dsn: SENTRY,
    release: `mc-next@${VERSION}`,
    // ignoreErrors,
    environment: EAZE_ENVIRONMENT,
    whitelistUrls
  })
} else {
  console.warn('Sentry not initialized due to missing DSN value')
}

export default (e) => {
  Sentry.captureException(e)
}
