import { css } from 'emotion'
import { mcRed, secondaryDark, tertiaryDark } from 'helpers/css-variables'

export const productsWrapper = css`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const tagContainer = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const tagForm = css`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const tagFormContent = css`
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

export const title = css`
  margin: 0.5rem 0;
`

export const errorText = css`
  color: ${mcRed};
`

export const inputError = css`
  box-shadow: 0 0 0 0.1rem ${mcRed};
`

export const tagTypeContainer = css`
  height: 4rem;
  width: 8rem;
  background-color: ${tertiaryDark};
  padding: 1rem;
  border-radius: 0.4rem;
  cursor: pointer;
  text-align: center;
`

export const conflictButton = css`
  margin-left: auto;
  background: none;
  cursor: pointer;
  border-radius: 3px;
`

export const groupsContent = css`
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

export const productsContainer = css`
  display: flex;
  flex-direction: column;
`

export const productsHeader = css`
  padding-bottom: 1rem;
`

export const productLabel = css`
  display: flex;
  padding: 1rem 2rem;
  cursor: pointer;

  &:nth-of-type(odd) {
    background-color: ${tertiaryDark};
  }
`

export const productTitle = css`
  margin-right: 1rem;
  width: 40%;
`

export const brandName = css`
  margin: 0 1rem;
  width: 20%;
`

export const productId = css`
  margin: 0 1rem;
  width: 10%;
`

export const checkboxBase = css`
  width: auto;
`

export const productCheckbox = css`
  ${checkboxBase};
`

export const depotCheckbox = css`
  ${checkboxBase};
`

export const depotSelectAllCheckbox = css`
  ${checkboxBase};
`

export const renderedDate = css`
  margin-top: 1rem;
`

export const filterTypeForm = css`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
`

export const filterTypeGroup = css`
  display: flex;
`

export const filterTypeLabel = css`
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-right: 1rem;
  margin-top: 1rem;
`

export const filterTypeRadio = css`
  width: 2rem;
  margin: 0;
  margin-right: 0.5rem;
`

export const previewContainer = css`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const previewContent = css`
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`

export const previewCardGrid = css`
  display: flex;
  flex-direction: column;
`

export const productCardGrid = css`
  width: 20rem;
  margin-right: 0.5rem;
  flex-shrink: 0;
  flex-grow: 0;
`

export const detailsRow = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem auto;
`

export const detailsRowDispensary = css`
  ${detailsRow};
  align-items: baseline;
`

export const detailsLabel = css`
  margin-right: 1rem;
`

export const detailsDivider = css`
  width: 100%;
  height: 0.1rem;
  border: 1px dashed;
`

export const detailsValue = css`
  margin-left: 1rem;
`

export const dateTimeValue = css`
  ${detailsValue};
  white-space: nowrap;
`

export const detailsDepotName = css`
  border: 1px solid #555;
  border-radius: 3px;
  padding: 0.1rem .3rem;
  display: inline-block;
  margin: 0.2rem;
  :not(:last-child) {
    margin-right: 1rem;
  }
`

export const tagIconSelectContainer = css`
  padding: 1rem;
  margin-top: 1rem;
  display: flex;
  flex-flow: row wrap;
  width: 20rem;
  position: relative;
  background-color: ${tertiaryDark};
  box-shadow: 0.1rem 0.1rem 0.1rem 0.1rem ${secondaryDark};
  border-radius: .4rem;
`

export const tagIcon = css`
  display: flex;
  padding: .5rem;
`
