import { createSelector } from 'reselect'
import { SHARED_REQUIRED, SHARED_OPTIONAL, BOGO_REQUIRED, APPLIES_TO_ID_REQUIRED } from 'helpers/promos/validation-keys'

// We reverse this list so the user gets validation messages in the correct order.
const SHARED_REQUIRED_R = [...SHARED_REQUIRED].reverse()
const BOGO_REQUIRED_R = [...BOGO_REQUIRED].reverse()

export const getManagePromos = (state) => state.managePromos

export const getPromoStateId = createSelector(
  [getManagePromos], (managePromos) => {
    return managePromos['rules.state_id']
  }
)

export const getApiPayload = createSelector(
  [getManagePromos],
  (managePromos) => {
    const payload = {}

    let FULL_PAYLOAD_KEYS = []
    FULL_PAYLOAD_KEYS = FULL_PAYLOAD_KEYS.concat(SHARED_REQUIRED)
    FULL_PAYLOAD_KEYS = FULL_PAYLOAD_KEYS.concat(SHARED_OPTIONAL)

    if (managePromos['rules.applies_to'] === 'id') {
      FULL_PAYLOAD_KEYS = FULL_PAYLOAD_KEYS.concat(APPLIES_TO_ID_REQUIRED)
    }

    if (managePromos['rules.promo_type'] === 'bogo') {
      FULL_PAYLOAD_KEYS = FULL_PAYLOAD_KEYS.concat(BOGO_REQUIRED)
    }

    FULL_PAYLOAD_KEYS.forEach((obj, index) => {
      if (managePromos[obj.key] !== null) {
        payload[obj.key] = managePromos[obj.key]
      }
    })

    console.info({ apiPayload: managePromos.unFlat(payload) })
    return managePromos.unFlat(payload)
  }
)

/* TODO
export const reconstitute = () => {
  do all the things needed to fill in all display values from a basic promo model, catalogName, etc.
}
*/

export const getPromoValidity = createSelector(
  [getManagePromos],
  (managePromos) => {
    let isValid = true
    let message = null

    SHARED_REQUIRED_R.forEach((obj, index) => {
      if (!managePromos[obj.key] && managePromos[obj.key] !== 0) {
        isValid = false
        message = `${obj.label} is missing`
      }
    })

    if (managePromos['rules.applies_to'] === 'id') {
      APPLIES_TO_ID_REQUIRED.forEach((obj, index) => {
        if (!managePromos[obj.key] && managePromos[obj.key] !== 0) {
          isValid = false
          message = `${obj.label} is missing`
        }
      })
    }

    if (managePromos['rules.promo_type'] === 'bogo') {
      BOGO_REQUIRED_R.forEach((obj, index) => {
        if (!managePromos[obj.key] && managePromos[obj.key] !== 0) {
          isValid = false
          message = `${obj.label} is missing`
        }
      })
    }

    return { status: isValid, message }
  }
)
