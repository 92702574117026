import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Drawer from '@eaze/react-fast-drawer'
import { Text, SectionTitle } from 'components/typography'
import Button, { PRIMARY, DANGER } from 'components/button'
import Colors from 'microcomponents/colors'
import styled from '@emotion/styled'
import { css } from 'emotion'

const { accessory } = Colors

class ConfirmDrawer extends PureComponent {
  static propTypes = {
    confirmMessages: PropTypes.array.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    showDrawer: PropTypes.bool.isRequired
  }

  render () {
    const {
      showDrawer,
      handleCancel,
      handleConfirm,
      confirmMessages
    } = this.props

    return (
      <Drawer
        open={showDrawer}
        containerElementClass={css`z-index: 15`}
        modalElementClass={DrawerModalElementClass}
        onRequestClose={handleCancel}
      >
        <Content>
          <StyledTitle color={accessory[5]}>
            Are you sure you want to save this info?
          </StyledTitle>

          <Emoji>⚠️</Emoji>

          <ConfirmContent>
            {confirmMessages.map((message, key) => (
              <StyledText
                color={accessory[5]}
                key={key}
              >
                {message}
              </StyledText>
            ))}
          </ConfirmContent>
          <ButtonRow>
            <Button
              type={DANGER}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <RightButton
              type={PRIMARY}
              onClick={handleConfirm}
            >
              Save Anyway
            </RightButton>
          </ButtonRow>
        </Content>
      </Drawer>
    )
  }
}

export default ConfirmDrawer

const StyledTitle = styled(SectionTitle)`
  padding: 0 2rem;
  text-align: center;
`

const DrawerModalElementClass = css`
  background-color: ${Colors.accessory[1]};
  max-width: 45rem;
`

const Content = styled.div`
  padding: 2rem;
`

const ConfirmContent = styled.div`
  margin-bottom: 2rem;
`

const ButtonRow = styled.div`
  display: flex;
`
const RightButton = styled(Button)`
  font-family: inherit;
  margin-left: 1rem;
`

const StyledText = styled(Text)`
  align-items: center;
  display: flex;
  line-height: 3rem;
`

// emojis get wonky I think with rems that's why we're using px
const Emoji = styled.div`
  font-size: 85px;
  margin-top: 1rem;
  text-align: center;
`
