import React, { PureComponent } from 'react'
import { func, number, object } from 'prop-types'

import { SCREEN_STATES } from 'helpers/hub'
import RunnerPage from './pages/runner'
import PackerPage from './pages/packer'
import CanceledPage from './pages/canceled'
import SoundNotifier from './sound-notification'

export default class HubAndSpoke extends PureComponent {
  static propTypes = {
    markOrderPacked: func,
    markOrderUnpacked: func,
    match: object,
    selectedDepotId: number,
    userId: number
  }

  state = {
    screenState: SCREEN_STATES.PACKER // default to packing
  }

  render () {
    const {
      markOrderPacked,
      markOrderUnpacked,
      match,
      selectedDepotId,
      userId
    } = this.props

    const {
      params: { task }
    } = match
    const screenState = task && task.toUpperCase()
    let screen

    switch (screenState) {
      case SCREEN_STATES.PACKER:
        screen = (
          <PackerPage
            markOrderPacked={markOrderPacked}
            markOrderUnpacked={markOrderUnpacked}
            selectedDepotId={selectedDepotId}
            userId={userId}
          />
        )
        break
      case SCREEN_STATES.RUNNER:
        screen = <RunnerPage />
        break
      case SCREEN_STATES.CANCELED:
        screen = <CanceledPage userId={userId} />
        break
      default:
        screen = (
          <PackerPage
            markOrderPacked={markOrderPacked}
            markOrderUnpacked={markOrderUnpacked}
            selectedDepotId={selectedDepotId}
            userId={userId}
          />
        )
    }

    return (
      <div style={{ height: '100%', display: 'flex' }}>
        {screen}
        <SoundNotifier />
      </div>
    )
  }
}
