import t from './actionTypes'
import api from 'api'

import { pushNotification, SUCCESS } from 'src/redux/alerts/actions'
import { selectDay } from 'src/helpers/delivery-fee-ranges'
import {
  requestDeliverySchedule,
  receiveDeliverySchedule
} from 'src/redux/loading/actions'

export function fetchDeliverySchedule (depotId) {
  return async function (dispatch) {
    dispatch(requestDeliverySchedule())
    const { err, data } = await api.getDeliverySchedule({ depotId })
    dispatch(receiveDeliverySchedule())
    if (err) return

    dispatch(setDeliverySchedule(data))
  }
}

export function saveDeliverySchedule (deliveryScheduleList, depotId) {
  return async function (dispatch) {
    // Group the array by dayOfWeek
    const groupedByDay = deliveryScheduleList.reduce((acc, schedule) => {
      const { dayOfWeek } = schedule
      if (!acc[dayOfWeek]) {
        acc[dayOfWeek] = []
      }
      acc[dayOfWeek].push(schedule)
      return acc
    }, {})

    // Check for duplicates or overlapping timeFrom and timeTo properties
    for (const [day, schedules] of Object.entries(groupedByDay)) {
      schedules.sort((a, b) => a.timeFrom.localeCompare(b.timeFrom))
      for (let i = 0; i < schedules.length - 1; i++) {
        const current = schedules[i]
        const next = schedules[i + 1]
        if (current.timeTo > next.timeFrom) {
          dispatch(pushNotification(`Row ${i + 1} for Day of Week ${selectDay[day].label} has overlapping time ranges: ${current.timeFrom}-${current.timeTo} overlaps with ${next.timeFrom}-${next.timeTo}`, 'error'))
          return
        }
      }
    }
    try {
      const payload = deliveryScheduleList.map(schedule => ({
        ...schedule
      }))
      const response = await api.saveDeliverySchedule({ depotId, array: payload, deliverySchedule: payload })
      if (response.err) {
        console.error('API Error:', response.err)
        dispatch(pushNotification('Failed to save delivery schedule', 'error'))
        return
      }

      dispatch(pushNotification('Delivery Schedule successfully set', SUCCESS))
      dispatch(setDeliverySchedule(response.data))
    } catch (error) {
      console.error('Error saving delivery schedule:', error)
      dispatch(pushNotification('Error saving delivery schedule', 'error'))
    }
  }
}

export const setDeliverySchedule = (deliverySchedule) => ({
  type: t.SET_DELIVERY_SCHEDULE,
  payload: deliverySchedule
})
