import React, { useState } from 'react'
import styled from '@emotion/styled'
import Screen from 'src/components/screen'
import Form from 'microcomponents/input/form'
import Button from 'components/button'
import LinearProgress from '@material-ui/core/LinearProgress'
import MCColors from 'helpers/css-variables'
import debounce from 'debounce'
import window from 'global/window'

import { func, string, bool, array } from 'prop-types'

const InventoryUpdateComponent = (props) => {
  const {
    catalogSearch,
    itemsToEdit,
    searchResults,
    setItemToEdit,
    setAllItemsToEdit,
    unsetAllItemsToEdit,
    updateInventoryItems,
    batchProgress,
    catalogError,
    inventoryError,
    showingRecentCatalogEdits
  } = props

  /*
    STATE
  */

  const [options, setOptions] = useState(
    {
      Name: false,
      Image: false,
      Price: false,
      'CBD:THC': false
    }
  )

  /*
    HANDLERS
  */
  const debouncedCatalogSearch = debounce((query) => { catalogSearch(query) }, 500)
  const handleSearch = ({ target: { value } }) => debouncedCatalogSearch(value)

  /*
    RENDER
  */

  const getProgressBarValue = (batchProgress) => {
    if (batchProgress && batchProgress.length) {
      // Just show a full progress bar if no updates needed, and avoid dividing by 0 and yielding NaN value
      if (batchProgress[1] === 0) {
        return 100
      }
      return ((batchProgress[0] / batchProgress[1]) * 100)
    } else {
      return null
    }
  }

  const progressBarValue = getProgressBarValue(batchProgress)
  const progressComplete = !!progressBarValue && (batchProgress[0] === batchProgress[1])
  // If we set the total count, but it's 0...no update is needed
  const noUpdateNeeded = !!batchProgress && batchProgress[1] === 0

  const searchResultsText = (searchResults, showingRecentCatalogEdits) => {
    if (showingRecentCatalogEdits) {
      return `Showing last ${searchResults.length} edited items`
    } else {
      return `${searchResults.length || 0} result(s)`
    }
  }

  const getProgressMessage = (progressBarValue, batchProgress, noUpdateNeeded) => {
    if (noUpdateNeeded) {
      return 'No updates necesarry. Everything up to date.'
    } else if (progressComplete) {
      return `${batchProgress[1]} job(s) complete!`
    } else {
      return `Processing job ${batchProgress[0]}/${batchProgress[1]}.`
    }
  }

  return (
    <Screen>
      <Container>
        <div>
          <h3>Inventory Update</h3>
          <p><small>To avoid service degradation, please use this tool as early in the day as possible. Avoid peak hours.</small></p>

          <input type='text' onChange={handleSearch} placeholder='Enter a catalog query...' />
          <ErrorMessage>{catalogError}</ErrorMessage>

          <ResultsHead>
            {searchResultsText(searchResults, showingRecentCatalogEdits)}
            <button
              onClick={() => {
                setAllItemsToEdit(searchResults.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}))
              }}
            >
              Add all
            </button>
          </ResultsHead>
          <ResultList>
            {searchResults.map((v) => (
              <SearchResult key={v.id} added={!!itemsToEdit.find(i => i.id === v.id)} onClick={() => setItemToEdit(v)}>
                {v.title} - {v.brand && v.brand.name}, {v.type.name}, {v.subtype.name}
              </SearchResult>
            ))}
          </ResultList>

          <hr />

          <h3>Items queued for edit</h3>
          <ResultsHead>
            {itemsToEdit.length ? itemsToEdit.length + ' item(s)' : 'none'}
            <button onClick={() => { unsetAllItemsToEdit() }}>
              Remove all
            </button>
          </ResultsHead>
          <ResultList>
            {itemsToEdit.map((v) => (
              <SearchResult key={v.id} className='remove' onClick={() => setItemToEdit(v)}>
                {v.title} - {v.brand && v.brand.name}, {v.type.name}, {v.subtype.name}
              </SearchResult>
            ))}
          </ResultList>
        </div>

        <hr />

        <h3>Update properties</h3>

        <Form
          onSubmit={() => {
            updateInventoryItems(itemsToEdit, options)
          }}
          submitText='Update'
          disabled={!!batchProgress}
        >
          {Object.entries(options).map(([key, value]) => (
            <React.Fragment key={key}>
              <FlexLabel
                htmlFor={key}
                onClick={() => setOptions({ ...options, [key]: !value })}
              >
                <input
                  type='checkbox'
                  id={key}
                  name={key}
                  readOnly
                  checked={options[key]}
                />
                <span>{`Update ${key}?`}</span>
              </FlexLabel>
            </React.Fragment>
          ))}
        </Form>

        {!!progressBarValue && (
          <ProgressBar>
            {getProgressMessage(progressBarValue, batchProgress, noUpdateNeeded)}
            <LinearProgress variant='determinate' value={progressBarValue} />
          </ProgressBar>
        )}

        <ErrorMessage>{inventoryError}</ErrorMessage>
        {(progressComplete || noUpdateNeeded) && (
          <Button onClick={() => { window.location && window.location.reload() }}>Start a new job</Button>
        )}
      </Container>
    </Screen>
  )
}

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto 10rem;
`

const ResultsHead = styled.div`
  display: flex;
  justify-content: space-between;
`

const FlexLabel = styled.label`
  display: flex;
  margin-bottom: 1rem;

  & input {
    /* lol this is dumb */
    width: auto;
    margin-right: 1rem;
  }
`
const ResultList = styled.ul`
  padding: 0;
`
const SearchResult = styled.li`
  cursor: pointer;
  list-style: none;

  &:before {
    content: ${({ added }) => added ? '"✅"' : '"➕"'};
  }

  &:hover {
    font-size: 1.65rem;
    color: white;
  }
  &.remove {
    &:before {
      content: '➖'
    }

    &:hover {
      font-size: 1.65rem;
      color: white;

      &:before {
        content: '❌'
      }
    }
  }
`
const ProgressBar = styled.div`
  margin: 1rem 0;
`
const ErrorMessage = styled.div`
  color: ${MCColors.mcRed};
  margin: 2rem 0;
`
InventoryUpdateComponent.propTypes = {
  catalogSearch: func,
  itemsToEdit: array,
  searchResults: array,
  setItemToEdit: func,
  setAllItemsToEdit: func,
  unsetAllItemsToEdit: func,
  updateInventoryItems: func,
  batchProgress: array,
  catalogError: string,
  inventoryError: string,
  showingRecentCatalogEdits: bool
}

InventoryUpdateComponent.displayName = 'InventoryUpdateComponent'
export default InventoryUpdateComponent
