import tokenStore from '@eaze/eaze-api-client/es/token-store.js'
import {
  removeUser as srRemoveUser,
  setUserBasic as srSetUserBasic,
  setUserToken as srSetUserToken
} from 'src/redux/user-shared-redux/actions'

export function setUserBasic (id, email) {
  return dispatch => dispatch(srSetUserBasic(id, email))
}

export function setUserToken (token) {
  if (token) {
    tokenStore.set(token)
  }

  return dispatch => {
    dispatch(srSetUserToken(token))
  }
}

export function removeUser () {
  return dispatch => dispatch(srRemoveUser())
}
