import React, { PureComponent } from 'react'
import { array, func } from 'prop-types'
import List from './list-component'
import McCard from 'src/components/mc-card'
import Create from './create'
import { brandGrid } from '../style.js'

export default class MenuList extends PureComponent {
  static propTypes = {
    create: func,
    menus: array
  }

  render () {
    const { create, menus } = this.props

    return (
      <List listClass={brandGrid} items={menus}>
        <Create
          action={create}
          title='add new menu'
        />
        {
          // eslint-disable-next-line camelcase
          menus.map(({ description, groups, id, name, slug, state_id }) => (
            <McCard
              color='yellow'
              count={groups.length}
              description={description}
              key={id}
              name={name}
              slug={slug}
              // eslint-disable-next-line camelcase
              stateId={state_id}
              url={`/catalog/menus/edit/${id}`}
            />
          ))
        }
      </List>
    )
  }
}
