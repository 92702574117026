import React from 'react'

import { tagTypeColor, tagTypeStyle } from './helpers'

export default function DealTag ({ color = tagTypeColor, height = '16px', width = '16px', style = tagTypeStyle }) {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Deal</title>
      <desc>Created with Sketch.</desc>
      <g id='MC' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Deal' transform='translate(-4.000000, -4.000000)' fill={color} fillRule='nonzero'>
          <g transform='translate(4.000000, 4.000000)' id='Shape'>
            <g>
              <path d='M8.72307692,1.25384615 L14.1230769,1.25384615 C14.4346154,1.25384615 14.6423077,1.46153846 14.6423077,1.77307692 L14.6423077,7.17307692 C14.6423077,7.38076923 14.5384615,7.48461538 14.5384615,7.58846154 L7.47692308,14.65 C7.37307692,14.7538462 7.26923077,14.7538462 7.06153846,14.7538462 C6.95769231,14.7538462 6.75,14.7538462 6.75,14.65 L1.24615385,9.14615385 C1.14230769,9.04230769 1.14230769,8.93846154 1.14230769,8.73076923 C1.14230769,8.62692308 1.24615385,8.41923077 1.24615385,8.31538462 L8.30769231,1.25384615 C8.51538462,1.25384615 8.61923077,1.25384615 8.72307692,1.25384615 Z M7.58076923,0.630769231 L0.623076923,7.69230769 C0.311538462,8.00384615 0.207692308,8.41923077 0.207692308,8.83461538 C0.207692308,9.25 0.415384615,9.66538462 0.623076923,9.97692308 L6.02307692,15.3769231 C6.33461538,15.6884615 6.64615385,15.7923077 7.16538462,15.7923077 C7.58076923,15.7923077 7.99615385,15.5846154 8.30769231,15.3769231 L15.3692308,8.31538462 C15.6807692,8.00384615 15.7846154,7.58846154 15.7846154,7.17307692 L15.7846154,1.77307692 C15.7846154,0.942307692 15.0576923,0.215384615 14.2269231,0.215384615 L8.72307692,0.215384615 C8.30769231,0.215384615 7.89230769,0.423076923 7.58076923,0.630769231 Z' />
              <path d='M13.7076923,4.26538462 C13.7076923,3.12307692 12.7730769,2.18846154 11.6307692,2.18846154 C10.4884615,2.18846154 9.55384615,3.12307692 9.55384615,4.26538462 C9.55384615,5.40769231 10.4884615,6.34230769 11.6307692,6.34230769 C12.7730769,6.34230769 13.7076923,5.40769231 13.7076923,4.26538462 Z M12.6692308,4.26538462 C12.6692308,4.88846154 12.2538462,5.30384615 11.6307692,5.30384615 C11.0076923,5.30384615 10.5923077,4.88846154 10.5923077,4.26538462 C10.5923077,3.64230769 11.0076923,3.22692308 11.6307692,3.22692308 C12.2538462,3.22692308 12.6692308,3.74615385 12.6692308,4.26538462 Z' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
