export { default as ArchiveIcon } from './archive'
export { default as ArrowIcon } from './../icon-arrow'
export { default as CarIcon } from './car'
export { default as CopyIcon } from './copy'
export { default as DeleteIcon } from './delete'
export { default as DownloadIcon } from './download'
export { default as DriversLicenseIcon } from './drivers-license'
export { default as OccupationalLicenseIcon } from './occupational-license'
export { default as PromoCodeNavIcon } from './promo-code-nav'
export { default as TagNavIcon } from './tag-nav'
export { default as RestockIcon } from './restock'
export { default as VerticalMenuIcon } from './vertical-menu'
