import t from './actionTypes'

export function toggleCanceledOrderSounds () {
  return {
    type: t.TOGGLE_CANCELED_ORDER_SOUNDS
  }
}

export function toggleIncomingOrderSounds () {
  return {
    type: t.TOGGLE_INCOMING_ORDER_SOUNDS
  }
}

export function toggleManifestToPrintSounds () {
  return {
    type: t.TOGGLE_MANIFESTS_TO_PRINT_SOUNDS
  }
}
