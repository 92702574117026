/**
 * Setting up the catalog container
 */

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import React, { PureComponent } from 'react'

import {
  createOrUpdateMenu,
  fetchMenuById,
  resetMenuItem,
  sortedItems,
  updateMenuObject
} from 'src/redux/menu-item/actions'
import { getDeDupedGroups, getMenuGroups } from 'src/redux/menu-item/selectors'

import ROUTES from 'src/pages/catalog/routes'
import { fetchAllDispensaries } from 'src/redux/dispensaries/actions'
import { getActiveDepotsSlimmed } from 'src/redux/dispensaries/selectors'
import { fetchLocationStates, mapToCatalogFetch, searchCatalogEntity } from 'src/redux/catalog/actions'
import { uploadPublicFile } from 'src/redux/files/api'

import EditMenu from './'

// Props passed to the component
const mapStateToProps = (state, props) => {
  const id = props.match.params.id || false // false if we're coming into /create

  return {
    activeDepots: getActiveDepotsSlimmed(state),
    items: {
      menuItems: getMenuGroups(state),
      groups: getDeDupedGroups(state)
    },
    menuId: id,
    menuItem: state.menuItem,
    statesAvailable: state.catalog.statesAvailable,
    stateId: state.menuItem.state_id
  }
}

// Methods passed to the components
const mapDispatchToProps = dispatch => {
  return {
    createOrUpdateMenu: () => dispatch(createOrUpdateMenu()),
    fetchAllDispensaries: () => dispatch(fetchAllDispensaries()),
    fetchGroups: () => dispatch(mapToCatalogFetch(ROUTES.groups.name)),
    fetchLocationStates: () => dispatch(fetchLocationStates()),
    fetchMenuById: (id) => dispatch(fetchMenuById(id)),
    resetMenuItem: () => dispatch(resetMenuItem()),
    searchCatalogEntity: (value, state) => dispatch(searchCatalogEntity(value, ROUTES.groups.name, state)),
    sortedItems: items => dispatch(sortedItems(items)),
    updateMenuObject: v => dispatch(updateMenuObject(v)),
    uploadPublicFile: (formData) => dispatch(uploadPublicFile(formData))
  }
}

class EditMenuContainer extends PureComponent {
  /* eslint-disable camelcase */
  UNSAFE_componentWillMount () {
  /* eslint-enable camelcase */
    const {
      fetchAllDispensaries,
      fetchGroups,
      fetchLocationStates,
      fetchMenuById,
      menuId
    } = this.props

    if (menuId) {
      fetchMenuById(menuId)
    }

    fetchGroups()
    fetchAllDispensaries()
    fetchLocationStates()
  }

  render () {
    const {
      activeDepots,
      createOrUpdateMenu,
      fetchGroups,
      items,
      menuItem,
      resetMenuItem,
      searchCatalogEntity,
      sortedItems,
      stateId,
      statesAvailable,
      updateMenuObject,
      uploadPublicFile
    } = this.props

    return (
      <EditMenu
        activeDepots={activeDepots}
        createOrUpdateMenu={createOrUpdateMenu}
        fetchGroups={fetchGroups}
        items={items}
        menuItem={menuItem}
        resetMenuItem={resetMenuItem}
        searchCatalogGroups={searchCatalogEntity}
        sortedItems={sortedItems}
        stateId={stateId}
        statesAvailable={statesAvailable}
        updateMenuObject={updateMenuObject}
        uploadPublicFile={uploadPublicFile}
      />
    )
  }
}

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditMenuContainer)

export default withRouter(reduxContainer)
