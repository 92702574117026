import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Button, { DANGER, PRIMARY, HYBRID } from 'components/button'
import { Flex } from 'src/microcomponents/flexbox-helpers'

export default class PreviewTagsDeals extends PureComponent {
  static propTypes = {
    onCloseForm: PropTypes.func,
    onCancelPreview: PropTypes.func,
    onDeleteTag: PropTypes.func,
    formData: PropTypes.object,
    isEdit: PropTypes.bool
  }

  render () {
    const {
      onCloseForm,
      onCancelPreview,
      onDeleteTag,
      formData,
      isEdit
    } = this.props

    return (
      <div>
        <Flex top={1}>
          <Button type={HYBRID} onClick={onCloseForm}>Looks Good</Button>
        </Flex>
        <Flex top={1}>
          <Button type={PRIMARY} onClick={onCancelPreview}>Edit</Button>
        </Flex>
        {!formData.active && isEdit &&
          <Flex top={1}>
            <Button type={DANGER} onClick={onDeleteTag}>Delete</Button>
          </Flex>}
      </div>
    )
  }
}
