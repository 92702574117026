import React from 'react'

// FIXME: Firefox doesn't like rems so using em for short term. Need to think of a longer term solution/standardization to support cross-browser compatibility https://bugzilla.mozilla.org/show_bug.cgi?format=default&id=1231147
export default function PromoCodeNavIcon ({ color = 'white', size = '2.5em', componentStyle }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 26 17'
      version='1.1'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g
        transform='translate(-73.000000, -93.000000) translate(36.000000, 72.000000) translate(29.881020, 21.000000) translate(8.000000, 0.000000)'
        fill={color}
        fillRule='nonzero'
        stroke='none'
        strokeWidth={1}
      >
        <path
          d='M13.136 7.727a2.321 2.321 0 0 1-2.318-2.318 2.321 2.321 0 0 1 2.318-2.318 2.321 2.321 0 0 1 2.319 2.318 2.321 2.321 0 0 1-2.319 2.318zm0-3.09a.774.774 0 0 0-.772.772c0 .427.347.773.772.773a.774.774 0 0 0 .773-.773.774.774 0 0 0-.773-.773zm4.637 9.272a2.321 2.321 0 0 1-2.318-2.318 2.321 2.321 0 0 1 2.318-2.318 2.321 2.321 0 0 1 2.318 2.318 2.321 2.321 0 0 1-2.318 2.318zm0-3.09a.775.775 0 0 0-.773.772c0 .425.348.773.773.773a.775.775 0 0 0 .772-.773.775.775 0 0 0-.772-.773zm-6.182 3.09a.772.772 0 0 1-.593-1.267l7.727-9.273a.777.777 0 0 1 1.088-.099c.327.274.37.76.097 1.09l-7.727 9.272a.772.772 0 0 1-.592.277z'
          fill={color}
        />
        <rect
          x='6.18181818'
          y='3.09090909'
          width='1.54545455'
          height='1.54545455'
        />
        <rect
          x='6.18181818'
          y='12.3636364'
          width='1.54545455'
          height='1.54545455'
        />
        <rect
          x='6.18181818'
          y='9.27272727'
          width='1.54545455'
          height='1.54545455'
        />
        <rect
          x='6.18181818'
          y='6.18181818'
          width='1.54545455'
          height='1.54545455'
        />
        <path
          d='M23.182 17H1.545A1.529 1.529 0 0 1 0 15.455V11.59c0-.427.346-.773.773-.773A2.321 2.321 0 0 0 3.09 8.5 2.321 2.321 0 0 0 .773 6.182.773.773 0 0 1 0 5.409V1.545C0 .694.694 0 1.545 0h21.637c.867 0 1.545.678 1.545 1.545v13.91c0 .867-.678 1.545-1.545 1.545zM1.545 12.286v3.169h21.637V1.545H1.545v3.169A3.87 3.87 0 0 1 4.636 8.5a3.87 3.87 0 0 1-3.09 3.786z'
          fill={color}
        />
      </g>
    </svg>
  )
}
