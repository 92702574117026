import { css } from 'emotion'
import { mcYellow, midGray } from 'helpers/css-variables'

export const cardContainer = css`
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
  transition: transform 0.25s linear;
  text-decoration: none;

  .button-content {
    visibility: hidden;
  }

  &:hover {
    transform: translateY(-0.5rem);
    .button-content {
      visibility: visible;
    }
  }
`

export const filler = css`
  width: 100%;
  height: 0.5rem;
  border-radius: 0.4rem;
  background-color: white;
  border: 0;
  z-index: 1;
`

export const fillerFar = css`
  ${filler};
  transform: scale(0.95) translateY(0.2rem) translateZ(-0.2rem);
`

export const fillerClose = css`
  ${filler};
  transform: scale(0.975) translateY(0.1rem) translateZ(-0.1rem);
`

export const contentCard = css`
  background-color: white;
  border-radius: 0.4rem;
  height: 100%;
  box-shadow: 0 -0.2rem 0.5rem rgba(0,0,0,0.1);
  transform: translateZ(0.1rem);
  position: relative;
  overflow: hidden;
  z-index: 2;
`

export const content = css`
  padding: 2rem 1rem;
  position: relative;
  color: black;
  letter-spacing: 0.03em;
  width: 80%;
`

export const name = css`
  font-weight: bold;
  text-transform: uppercase;
  color: ${mcYellow};
`

export const abovetheblur = css`
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 3;
`

export const about = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const productImage = css`
  width: 5rem;
  height: 5rem;
`

export const itemTotalContainer = css`
  max-width: 4rem;
  height: 100%;
`

export const box = css`
  padding: 1rem;
  text-align: center;
  color: white;
  border-radius: 0.4rem;
  letter-spacing: 0.03em;
`

export const slug = css`
  margin-top: 0.5rem;
  font-size: 1.2rem;
  text-align: left;
  opacity: 0.7;
`

export const stateCorner = css`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  font-size: 1.2rem;
  color: ${midGray};
`
