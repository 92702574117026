import history from 'components/router/history'
import ROUTES from 'src/components/router/routes'
import { closeNavSlider } from 'src/redux/navigation/actions'
import { receiveLogin, requestLogin as requestLoginLoading } from '../loading/actions'
import { setUserBasic, setUserToken, removeUser } from '../action-wrappers/user'
import { setPermissions, clearPermissions } from '../permissions/actions'
import { pushNotification } from 'src/redux/alerts/actions'
import gatewayClient from 'src/gateway-client'

export function requestLogin (formData) {
  return async dispatch => {
    dispatch(requestLoginLoading())

    const [err, response] = await gatewayClient().login({ email: formData.email.value, password: formData.password.value }).then(
      (data) => [null, data],
      (error) => [error, null]
    )

    dispatch(receiveLogin())

    if (err) {
      dispatch(pushNotification(err.response.data.message || err))
      return
    }
    const { user, xAuthToken } = response.data
    const { email, id, isAdmin, isDispManager } = user

    if (isAdmin || isDispManager) { // this should be handled in gateway middleware
      dispatch(setPermissions({ isAdmin, isDispManager }))
      dispatch(setUserToken(xAuthToken))
      dispatch(setUserBasic(id, email))
      const route = isDispManager ? ROUTES.DASHBOARD : ROUTES.USERS
      // for some reason we need to nextTick
      setTimeout(() => history.push(route))
    } else {
      dispatch(pushNotification('Unauthorized user'))
    }
  }
}

export function logout () {
  return dispatch => {
    dispatch(removeUser())
    dispatch(clearPermissions())
    dispatch(closeNavSlider())

    history.push(ROUTES.LOGIN)
  }
}
