import { css } from 'emotion'
import { blazeUpBlack, mcBlue, mcRed, tertiaryDark, white } from 'helpers/css-variables'

export const listContainer = css`
  max-width: 120rem;
  margin: auto;
`

export const grid = css`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  grid-gap: 2rem;
  justify-items: start;
`

export const brandGrid = css`
  ${grid};
  grid-gap: 2rem;
  padding-bottom: 4rem;
`

// a[disabled],
// a[disabled]:hover {
//   opacity: 0.6;
//   cursor: not-allowed;
//   border-bottom: 0.6rem solid transparent;
// }

export const selectContainer = css`
  width: 100%;
  flex: 1;
  display: flex;
  position: relative;

  & > select {
    width: 100%;
  }

  &:after {
    content: '▸';
    transform: rotate(90deg);
    color: white;
    position: absolute;
    bottom: 0;
    top: 0;
    background-color: ${tertiaryDark};
    z-index: 1000;
    right: 2rem;
  }

  select {
    -webkit-appearance: none;
    width: 100%;
    height: 4rem;
    padding-left: 1rem;
    font-size: 1.6rem;
    color: white;
    border: 0;
    background-color: ${tertiaryDark};
    cursor: pointer;
  }
`

export const noResults = css`
  font-size: 1.6rem;
  text-align: center;
`

export const imageFormThumbnail = css`
  height: 10rem;
  width: 10rem;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-right: 1.5rem;
`

export const imageFormThumbnailLoadingContainer = css`
  ${imageFormThumbnail};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const imageFormThumbnailSpinner = css`
  height: 2rem;
  width: 2rem;
`

export const imageFormThumbnailDeleteButton = css`
  position: absolute;
  cursor: pointer;
  border: none;
  padding: 0;
  top: -1rem;
  right: -1rem;
  height: 2.5rem;
  width: 2.5rem;
  font-size: 2rem;
  border-radius: 100%;
  background-color: ${blazeUpBlack};
  color: ${white};
  text-align: center;
`

export const inputError = css`
  box-shadow: 0 0 0 0.1rem ${mcRed};
`

export const jumboCreate = css`
  width: 100%;
  cursor: pointer;
  border-radius: 0.4rem;
  transition: all 0.2s linear;
  box-shadow: 0 -0.2rem 0.5rem rgba(0,0,0,0.1);
  transform: translateZ(0.1rem);
  background: transparent;
  color: white;
  text-transform: uppercase;
  font-size: 1.8rem;
  letter-spacing: 0.03rem;
  border: 0.4rem dashed ${mcBlue};
  color: ${mcBlue};
  font-weight: 700;

  &:hover {
    transform: translateY(-0.5rem);
  }
`

export const switchFormContainer = css`
  margin-left: auto;
  display: flex;
  height: 100%;
  align-items: center;
`

export const switchFormLabel = css`
  margin-right: 1rem;
`

export const drawerTitle = css`
  margin:  1rem auto 2rem;
  width: 80%;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
`

export const drawerButtonContainer = css`
  text-align: center;
  margin: 2rem auto 1rem auto;
`

export const drawerButton = css`
  margin: .5rem;
`

export const drawerListItem = css`
  margin: 1rem 1rem;
`

export const myCheckbox = css`
  input {
    display: none;
    position: relative;
  }

  span {
    width: 20px;
    height: 20px;
    margin-bottom: -5px;
    display: inline-block;
    background: url('../../assets/checkMarkOff.svg');
    background-repeat: no-repeat;
    cursor: pointer;
  }

  input:checked + span {
    background: url('../../assets/checkMarkOn.svg');
    background-repeat: no-repeat;
  }
`

export const myCheckboxLabel = css`
  margin-left: .5rem;
`
