import { css } from 'emotion'
import { colorTextDark, white } from 'helpers/css-variables'

export const pageContainer = css`
  display: flex;
  padding-top: 2rem;
  height: 100%;
`

export const leftColumn = css`
  flex-shrink: 0;
  padding-left: 1rem;
`

export const rightColumn = css`
  margin: 0 auto;
  flex: 0 1 100%;
  max-width: 65rem;
  padding: 0 2rem;
  padding-bottom: 5rem;
`

export const content = css`
  height: 100%;
  padding: 1rem;
  border-radius: 8px;
  background-color: ${white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colorTextDark};
`

export const backIcon = css`
  font-size: 2rem;

  @media (min-width: 1200px) {
    font-size: inherit;
  }
`

export const backText = css`
  margin-left: 0.5rem;
  display: none;

  @media (min-width: 1200px) {
    display: initial;
  }
`
