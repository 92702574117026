import t from './actionTypes'

const defaultState = {
  name: '',
  id: '',
  items: [],
  description: '',
  desktop_image: '',
  mobile_image: '',
  icon: '',
  theme: '',
  desktop_inline_image: '',
  mobile_inline_image: '',
  auto_add: true,
  manual_sort: false,
  filters: {},
  slug: '',
  state_id: ''
}

const groupItem = (state = defaultState, action) => {
  switch (action.type) {
    case t.SET_GROUP_ITEMS:
      return {
        ...state,
        items: action.items
      }

    case t.UPDATE_GROUP_OBJECT:
      return {
        ...state,
        ...action.obj
      }

    case t.RESET_GROUP_ITEM:
      return defaultState

    default:
      return state
  }
}

export default groupItem
