export default {
  CLEAR_ORDER_BUILDER_STORE: 'CLEAR_ORDER_BUILDER_STORE',

  SET_ORDER_BUILDER_USER: 'SET_ORDER_BUILDER_USER',
  CLEAR_ORDER_BUILDER_USER: 'CLEAR_ORDER_BUILDER_USER',

  SET_ORDER_BUILDER_DEPOT_ID: 'SET_ORDER_BUILDER_DEPOT_ID',
  CLEAR_ORDER_BUILDER_DEPOT_ID: 'CLEAR_ORDER_BUILDER_DEPOT_ID',

  SET_ORDER_BUILDER_CART_ID: 'SET_ORDER_BUILDER_CART_ID',
  CLEAR_ORDER_BUILDER_CART_ID: 'CLEAR_ORDER_BUILDER_CART_ID',

  SET_ORDER_BUILDER_CART_QUOTE: 'SET_ORDER_BUILDER_CART_QUOTE',
  CLEAR_ORDER_BUILDER_CART_QUOTE: 'CLEAR_ORDER_BUILDER_CART_QUOTE',

  SET_ORDER_BUILDER_MENU_SLUG: 'SET_ORDER_BUILDER_MENU_SLUG',
  CLEAR_ORDER_BUILDER_MENU_SLUG: 'CLEAR_ORDER_BUILDER_MENU_SLUG',

  SET_ORDER_BUILDER_CATALOG_BUCKET: 'SET_ORDER_BUILDER_CATALOG_BUCKET',
  CLEAR_ORDER_BUILDER_CATALOG_CART: 'CLEAR_ORDER_BUILDER_CATALOG_CART',

  SET_ORDER_BUILDER_CONCIERGE_SLUG: 'SET_ORDER_BUILDER_CONCIERGE_SLUG',
  CLEAR_ORDER_BUILDER_CONCIERGE_SLUG: 'CLEAR_ORDER_BUILDER_CONCIERGE_SLUG',

  SET_ORDER_BUILDER_CATALOG_BUCKET_ID: 'SET_ORDER_BUILDER_CATALOG_BUCKET_ID',
  CLEAR_ORDER_BUILDER_CATALOG_BUCKET_ID: 'CLEAR_ORDER_BUILDER_CATALOG_BUCKET_ID',

  SET_ORDER_BUILDER_PRODUCTS_FILTER: 'SET_ORDER_BUILDER_PRODUCTS_FILTER',
  CLEAR_ORDER_BUILDER_PRODUCTS_FILTER: 'CLEAR_ORDER_BUILDER_PRODUCTS_FILTER',

  SET_ORDER_BUILDER_CATALOG_CART_ETA: 'SET_ORDER_BUILDER_CATALOG_CART_ETA',
  CLEAR_ORDER_BUILDER_CATALOG_CART_ETA: 'CLEAR_ORDER_BUILDER_CATALOG_CART_ETA',

  SET_ORDER_BUILDER_SHOW_ORDER_DRAWER: 'SET_ORDER_BUILDER_SHOW_ORDER_DRAWER',
  SET_OUT_OF_DEPOT_CATALOG_ITEMS: 'SET_OUT_OF_DEPOT_CATALOG_ITEMS',
  REMOVE_OUT_OF_DEPOT_CATALOG_ITEM: 'REMOVE_OUT_OF_DEPOT_CATALOG_ITEM'
}
