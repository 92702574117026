import React from 'react'
import { Column } from 'src/microcomponents/flexbox-helpers'
import InputLabel from 'src/microcomponents/input-label'
import debounce from 'debounce'
import { array, func, string } from 'prop-types'
import styled from '@emotion/styled'

export default class PromoInput extends React.Component {
  static propTypes = {
    code: string,
    setPromoCode: func,
    promoCodes: array,
    editPromoId: string
  }

  static defaultProps = {
    promoCodes: [],
    editPromoId: null
  }

  state = {
    isExistingCode: false
  }

  doesPromoCodeExist = debounce((code, id, promoCodes) => {
    // Takes the current input value (promo code) and an optional id for cases where we're editing an existing promo
    // Edge case: we don't wan't to mark as invalid if they delete and re-enter the same promocode when editing
    const result = promoCodes.filter((promo) => {
      return (promo.code === code.toLowerCase()) && ((!id) || id !== promo.id)
    })

    this.setState({ isExistingCode: !!result.length })
  }, 250)

  render () {
    const { code, setPromoCode, promoCodes, editPromoId } = this.props
    return (
      <Column>
        <InputLabel content='promo code*' />
        <InputWrapper>
          <input
            onBlur={setPromoCode}
            style={{ textTransform: 'uppercase' }}
            placeholder='PROMO420'
            defaultValue={code}
            onChange={(e) => this.doesPromoCodeExist(e.target.value, editPromoId, promoCodes)}
          />
          <Indicator>{this.state.isExistingCode ? ' ❌' : ' ✅'}</Indicator>
        </InputWrapper>
      </Column>
    )
  }
}

const Indicator = styled.span`
  font-size: 2rem;
  padding-left: 1rem;
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`
