import React, { PureComponent } from 'react'
import { object, string } from 'prop-types'
import El from './order-item-elements'

export default class OrderInfoBlock extends PureComponent {
  static propTypes = {
    subtitle: string.isRequired,
    title: string.isRequired,
    titleStyle: object
  }

  render () {
    const { subtitle, title, titleStyle } = this.props
    return (
      <El.ContentBlock>
        <El.Title style={titleStyle}>{title}</El.Title>
        <El.Subtitle>{subtitle}</El.Subtitle>
      </El.ContentBlock>
    )
  }
}
