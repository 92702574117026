import Select from 'react-select'
import styled from '@emotion/styled'
import React, { useEffect, useState, useRef } from 'react'
import { array, func, object } from 'prop-types'
import { tertiaryDark } from 'helpers/css-variables'
import { ArrowIcon } from 'src/microcomponents/icons'
import Button, { PRIMARY, HYBRID } from 'components/button'

function AutoAddView (props) {
  const defaultFilterOptions = [
    // add another key for the value type i.e categorical, numeric, bool etc etc
    { name: 'Type', value: 'type_id', isDisabled: false },
    { name: 'Subtype', value: 'subtype_id', isDisabled: false },
    { name: 'State', value: 'state_id', isDisabled: false },
    { name: 'Brand', value: 'brand_id', isDisabled: false }
  ]
  const [filtersList, setFiltersList] = useState([{}])
  const [filterValueOptions, setFilterValues] = useState([])
  const [filterSelected, setFilterSelected] = useState([])
  const [filterSelectedValues, setFilterSelectedValues] = useState([])
  const [filterOptions, setFilterOptions] = useState(defaultFilterOptions)
  const [prefillFilters, setPrefillFilters] = useState(true)
  const [isApplyAllowed, setIsApplyAllowed] = useState(false)
  const isFirstRender = useRef(true)

  useEffect(function () {
    const {
      types,
      statesAvailable,
      subtypes,
      brands,
      filters: propsFilters
    } = props

    const mapper = {
      type_id: types,
      state_id: statesAvailable,
      subtype_id: subtypes,
      brand_id: brands
    }

    const isEmpty = arr => !arr.length
    // waits for filter data to be available
    if (!propsFilters ||
        isEmpty(types) || isEmpty(statesAvailable) || isEmpty(subtypes) || isEmpty(brands)
    ) {
      return
    } else if (!Object.keys(propsFilters).length) {
      // filters are empty, reset everything
      setFilterValues([])
      setFiltersList([{}])
      setFilterSelected([])
      setFilterSelectedValues([])
      setFilterOptions(defaultFilterOptions)
      return
    }

    // prefills filters only once if available and non-empty on props
    if (!prefillFilters) return

    // initializes group rules if filters are present
    const list = []
    const selected = []
    const selectedValues = []
    const filterValueOptions = []
    const options = [...defaultFilterOptions]

    filterOptions.forEach((filterOption, index) => {
      const filter = filterOption.value

      // a filter is found
      if (propsFilters[filter] && propsFilters[filter].length) {
        options[index] = { ...filterOptions[index], isDisabled: true }
        selected.push({ ...filterOptions[index], isDisabled: true })
        filterValueOptions.push(mapper[filter])

        // update filtersList
        const filterValue = propsFilters[filterOption.value]
        const indexOfFilter = filterOptions.map(el => el.value).indexOf(filter)
        list[indexOfFilter] = { [filter]: filterValue }
        const filteredList = list.filter(el => el && Object.keys(el).length)
        setFiltersList(filteredList)

        // sets selected values
        const values = mapper[filter]
        const filterValues = []
        // searches for data assocciated to value on filter
        values.forEach(el => {
          propsFilters[filter].forEach(e => {
            const id = filter === 'state_id' ? el.state : el.id
            if (id === e) {
              filterValues.push(el)
            }
          })
        })
        selectedValues.push(filterValues)
        setFilterValues(filterValueOptions)
        setFilterSelected(selected)
        setFilterSelectedValues(selectedValues)
      }
    })
    setFilterOptions(options)
    setPrefillFilters(false)
  }, [
    props.filters,
    props.types,
    props.statesAvailable,
    props.subtypes,
    props.brands,
  ])

  const toggleFilter = (filterToggle) => {
    // handles whether a filter is disabled or not
    const filtersList = [...filterOptions]
    const filtersIdx = filterOptions.findIndex(filter => filter.value === filterToggle)
    if (filtersIdx >= 0) {
      filtersList[filtersIdx].isDisabled = (!filtersList[filtersIdx].isDisabled)
    }
    setFilterOptions(filtersList)
  }

  const handleFilterChange = (e, index) => {
    // handle filter input change
    const { name, value } = e
    const list = [...filtersList]
    toggleFilter(value)
    if (Object.keys(list[index])[0] === null) {
      // brand new filter selection
      list[index][value] = []
      filterSelected[index] = name
    } else {
      // replace the selection if you are updating a filter selection
      const newFilterObj = {}
      newFilterObj[value] = []
      filterSelected[index] = e
      // clear selected values
      filterValueOptions[index] = []
      filterSelectedValues[index] = []
      list.forEach((filter, idx) => {
        if (idx === index) {
          toggleFilter(Object.keys(filter)[0])
          handleFilterValueChange([], index)
          return list.splice(index, 1, newFilterObj)
        }
      })
    }
    setFiltersList(list)
    handleFilterValues(value, index)
  }

  const handleRemoveClick = index => {
    // handle click event of the Remove button
    const list = [...filtersList]
    toggleFilter(Object.keys(list[index])[0])
    // clear selected values from filters and values
    filterValueOptions.splice(index, 1)
    filterSelectedValues.splice(index, 1)
    filterSelected.splice(index, 1)
    // remove
    list.splice(index, 1)
    setFiltersList(list)
  }

  const handleAddClick =  () => {
    // handle click event of the Add button
    setFiltersList([...filtersList, {}])
  }

  const handleFilterValues = (filterName, i) => {
    // determine what options the secondary dropdown has
    const filterList = [...filterValueOptions]
    switch (filterName) {
      case 'type_id':
        filterList[i] = props.types
        setFilterValues(filterList)
        return
      case 'state_id':
        filterList[i] = props.statesAvailable
        setFilterValues(filterList)
        return
      case 'subtype_id':
        filterList[i] = props.subtypes
        setFilterValues(filterList)
        return
      case 'brand_id':
        filterList[i] = props.brands
        setFilterValues(filterList)
    }
  }

  const handleFilterValueChange = (selected, index) => {
    const list = [...filtersList]
    const selectedList = [...filterSelectedValues]
    selectedList[index] = selected
    if (Object.keys(list[index])[0] === 'state_id') {
      // state does not use id as a key instead it uses state
      list[index][Object.keys(list[index])[0]] = selected.map(selection => selection.state)
    } else {
      list[index][Object.keys(list[index])[0]] = selected.map(selection => selection.id)
    }
    setFiltersList(list)
    setFilterSelectedValues(selectedList)
  }

  const applyFilters = () => {
    const filters = {}
    // unpack the list of objects to an object
    filtersList.forEach((filter) => {
      if (Object.keys(filter)[0] != null) {
        const key = Object.keys(filter)[0]
        filters[key] = filter[key]
      }
    })
    props.setFilters(filters)
    props.setGroupItemsByFilter(filters)
  }

  const applyAllowed = () => {
    // determines whether the apply filters button can be clicked
    let tempApplyAllowed = true
    filtersList.forEach((filter) => {
      if (Object.keys(filter).length === 0 || filter[Object.keys(filter)].length === 0) {
        tempApplyAllowed = false
      }
    })
    setIsApplyAllowed(tempApplyAllowed)
    props.setSaveAllowed(tempApplyAllowed)
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    applyAllowed()
  }, [filtersList,
      props.filters])

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted black',
      height: '35px',
      paddingTop: '10px',
      paddingBottom: '10px',
      color: state.isDisabled
        ? '#9e9e9e'
        : state.isSelected
          ? 'black'
          : 'white',
      cursor: state.isDisabled ? 'not-allowed' : 'default',
      backgroundColor: state.isFocused
        ? '#bdbdbd'
        : '#616161'
    }),
    menuList: (provided) => ({
      ...provided,
      paddingBottom: 0,
      paddingTop: 0
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: '#616161',
      border: 'black',
      width: '100%'
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, opacity, transition, color: 'white' }
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: '#bdbdbd'
      }
    },
    multiValueRemove: (styles) => {
      return {
        ...styles,
        color: 'white'
      }
    },
    clearIndicator: (styles) => {
      return {
        ...styles,
        color: 'white',
        ':hover': {
          color: '#e33030'
        }
      }
    }
  }

  return ( <>
  <div>
    {filtersList.map((x, i) => (
    <div key={`${i}-${Object.keys(filtersList[i])[0]}`}>
      <div>
        <span>
          {filtersList.length !== i && filtersList.length !== 1 &&
            <RemoveFilterButton
              onClick={() => handleRemoveClick(i)}>&#10005;
            </RemoveFilterButton>}
        </span>
        <Selector className='FilterSelector'>
          <Select
            name='Filter'
            onChange={(e) => handleFilterChange(e, i)}
            options={filterOptions}
            value={filterSelected[i]}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => option.name}
            isOptionDisabled={(option) => option.isDisabled}
            styles={customStyles} />
          <SelectorArrowIconWrapper>
            <ArrowIcon height='1.4rem' width='1.4rem' color='white' />
          </SelectorArrowIconWrapper>
        </Selector>
        {filterSelected[i] != null &&
          <SelectorMulti className='ValueSelector'>
            <Select name='Values'
              onChange={selected => handleFilterValueChange(selected, i)}
              isMulti
              isClearable
              isSearchable
              options={filterValueOptions[i]}
              value={filterSelectedValues[i]}
              getOptionValue={(option) => (option.id || option.name)}
              getOptionLabel={(option) => option.name}
              styles={customStyles}
              maxMenuHeight={325} />
            <SelectorArrowIconWrapper>
              <ArrowIcon height='1.4rem' width='1.4rem' color='white' />
            </SelectorArrowIconWrapper>
          </SelectorMulti>}
      </div>
    </div>
    ))}
  </div>
  <ButtonSection>
    <div style={{ height: '100%' }}>
      <StyledEazeButton
        onClick={handleAddClick}
        type={PRIMARY}
        disabled={filtersList.length === filterOptions.length}
      > Add Rule
      </StyledEazeButton>
      <br />
      <StyledEazeButton
        className='button submit ' type={HYBRID}
        disabled={!isApplyAllowed}
        onClick={() => applyFilters()}
      > {filtersList.length > 1 ? 'Apply Rules' : 'Apply Rule'}
      </StyledEazeButton>
    </div>
    </ButtonSection></>)
}

const ButtonSection = styled.div`
  margin-top: 25%;
`

const Selector = styled.div`
  width: 45%;
  margin-top: 0.4rem;
  position: relative;
  background-color: ${tertiaryDark};

  select {
    width: 100%;
    -webkit-appearance: none;
    height: 4rem;
    padding-left: 1.2rem;
    font-size: 1.6rem;
    color: white;
    border: 0;
    border-radius: 0.2rem;
    cursor: pointer;
    background-color: ${tertiaryDark};

    :disabled {
      cursor: not-allowed;
      opacity: 0.2;
    }
  }
`

const SelectorMulti = styled.div`
  width: 90%;
  margin-top: 0.4rem;
  margin-bottom: 3.6rem;
  position: relative;
  background-color: ${tertiaryDark};

  select {
    width: 100%;
    -webkit-appearance: none;
    height: 4.8rem;
    padding-left: 1.2rem;
    font-size: 1.6rem;
    color: white;
    border: 0;
    border-radius: 0.2rem;
    cursor: pointer;
    background-color: ${tertiaryDark};

    :disabled {
      cursor: not-allowed;
      opacity: 0.2;
    }
  }
`

const RemoveFilterButton = styled.button`
  background-color: transparent;
  color: white;
  border: 0;
  cursor: pointer;
`

const SelectorArrowIconWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  right: 0;
  top: 0;
  padding-right: 1.2rem;
  pointer-events: none;
`
const StyledEazeButton = styled(Button)`
  font-family: inherit;
  width: 90%;
  margin-top: .5rem;
`

AutoAddView.propTypes = {
  statesAvailable: array,
  subtypes: array,
  types: array,
  brands: array,
  setFilters: func,
  filters: object,
  setGroupItemsByFilter: func
}

export default AutoAddView
