import t from './actionTypes'

export default function setLocalFirebaseValues (data) {
  const {
    pages: {
      partnerDeliveriesAccess
    },
    pollingIntervals: {
      deliveries,
      orderBuilder
    }
  } = data

  return dispatch => {
    dispatch(setPartnerDeliveryAccess(partnerDeliveriesAccess))
    dispatch(setDeliveriesPollInterval(deliveries))
    dispatch(setOrderBuilderPollInterval(orderBuilder))
  }
}

function setPartnerDeliveryAccess (partnerDeliveriesAccess) {
  return {
    type: t.SET_PARTNER_DELIVERIES_ACCESS,
    payload: partnerDeliveriesAccess
  }
}

function setDeliveriesPollInterval (interval) {
  return {
    type: t.SET_DELIVERIES_POLL_INTERVAL,
    payload: interval
  }
}

function setOrderBuilderPollInterval (interval) {
  return {
    type: t.SET_ORDER_BUILDER_POLL_INTERVAL,
    payload: interval
  }
}

export function resetFirebase () {
  return {
    type: t.RESET_FIREBASE_VALUES
  }
}
