import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { func, object } from 'prop-types'

import InfoBlock from 'src/components/info-block'
import PlaceSearch from 'src/components/place-search'
import { searchPlace } from 'src/components/place-search/fetchers'
import { MC_URL } from 'helpers/environment'
import { numberToPrice } from 'helpers/price-helpers'

import autosuggestTheme from './autosuggest-theme.js'
import autosuggestErrorTheme from './autosuggest-error-theme.js'
import {
  errorText,
  formHeader,
  infoBlockLink
} from './style.js'

class UserInfoHeader extends PureComponent {
  static propTypes = {
    fetchPlace: func,
    onPlaceFetched: func,
    place: object,
    user: object
  }

  render () {
    const {
      user,
      fetchPlace,
      onPlaceFetched,
      place: { active }
    } = this.props
    const {
      basic: { displayName, mobilePhoneDisplay, userId, zipCode },
      stat: { totalOrdersCount, chargeAmount },
      extra: { totalCredit }
    } = user
    const autosuggestThemeStyle = active.exact
      ? autosuggestTheme
      : autosuggestErrorTheme

    const totalSpent = numberToPrice(chargeAmount || 0)
    const credit = numberToPrice(totalCredit || 0)
    const averageOrder = totalOrdersCount && chargeAmount
      ? chargeAmount / totalOrdersCount
      : 0
    const averageOrderPrice = numberToPrice(averageOrder)

    return (
      <div className={formHeader}>
        <div>
          <Link
            to={`${MC_URL}/profile/${userId}`}
            className={infoBlockLink}
            target='_blank'
          >
            <InfoBlock
              title={displayName}
              subTitle={<span className='fs-block'>{mobilePhoneDisplay}</span>}
            />
          </Link>
          <InfoBlock
            title={totalOrdersCount}
            subTitle='ORDERS'
          />
          <InfoBlock
            title={averageOrderPrice}
            subTitle='AVERAGE ORDER'
          />
          <InfoBlock
            title={totalSpent}
            subTitle='TOTAL SPENT'
          />
          <InfoBlock
            title={credit}
            subTitle='CREDIT'
          />
        </div>
        <PlaceSearch
          placeholder='Enter delivery address'
          theme={autosuggestThemeStyle}
          fetchPlace={fetchPlace}
          onPlaceFetched={onPlaceFetched}
          activePlace={active}
          initialSearch={zipCode}
          searchPlace={searchPlace}
          inputPropsOptions={{ autoFocus: true }}
        />
        {!active.exact && <div className={errorText}>This address isn't deliverable</div>}
      </div>
    )
  }
}

export default UserInfoHeader
