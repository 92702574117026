import React, { PureComponent } from 'react'
import { array, func, string } from 'prop-types'
import { Column } from 'src/microcomponents/flexbox-helpers'

import Button, { PRIMARY } from 'components/button'
import Switch from 'microcomponents/switch'

import {
  content,
  depotsListHeader,
  depotsListButtonWrapper,
  disableAllDepotsButton,
  enableAllDepotsButton,
  itemToggle,
  switchText,
  switchWrapper,
  toggleContainer
} from '../edit-page-styles.js'
export default class MenuSettingsTab extends PureComponent {
  static propTypes = {
    depotsEnabled: array.isRequired,
    setDepotEnabled: func.isRequired,
    slug: string.isRequired,
    setAllDepotsEnabled: func,
    statesAvailable: array,
    stateId: string,
    saveFormValue: func
  }

  enableAllDepots = () => {
    const { setAllDepotsEnabled } = this.props
    setAllDepotsEnabled(true)
  }

  disableAllDepots = () => {
    const { setAllDepotsEnabled } = this.props
    setAllDepotsEnabled(false)
  }

  render () {
    const {
      depotsEnabled,
      setDepotEnabled,
      slug,
      statesAvailable,
      stateId,
      saveFormValue
    } = this.props

    return (
      <Column className={content} top={1} full>
        <div style={{ margin: '1rem 0  0 1.5rem ' }}>
          <select
            onChange={(event) => saveFormValue(event.target.value, 'state_id')}
            value={stateId}
          >
            <option selected disabled value=''>Add a state</option>
            {statesAvailable.map((s, index) => <option key={index} value={s.state}>{s.name}</option>)}
          </select>
        </div>
        <div className={toggleContainer}>
          <div className={depotsListHeader}>
            <h3>Depots</h3>
            <div className={depotsListButtonWrapper}>
              <Button
                className={enableAllDepotsButton}
                onClick={this.enableAllDepots}
                type={PRIMARY}
              >
                Enable All
              </Button>
              <Button
                className={disableAllDepotsButton}
                onClick={this.disableAllDepots}
                type={PRIMARY}
                inverted
              >
                Disable All
              </Button>
            </div>
          </div>
          {
            slug === 'default' && <div>All depots are enabled on the 'default' menu</div>
          }
          {
            slug !== 'default' && depotsEnabled.map((depotObj, index) => {
              const depotText = `Depot #${depotObj.id} - ${depotObj.name}`
              const onClick = setDepotEnabled(index) // creates a curried fn to call later w index
              return (
                <div className={itemToggle} key={index}>
                  <div className={switchText}>{depotText}</div>
                  <div className={switchWrapper} onClick={onClick}><Switch enabled={depotObj.enabled} /></div>
                </div>
              )
            })
          }
        </div>
      </Column>
    )
  }
}
