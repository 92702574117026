import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const Text = styled.p`
  color: ${p => p.color || '#373838'};
  font-size: 1.4rem;
  font-family: 'Proxima', 'Open sans', Helvetica;
  letter-spacing: 0.03em;
  margin: 0;
`

export default class TextComponent extends PureComponent {
  static defaultProps = {
    componentStyle: {},
    className: '',
    color: ''
  }

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    componentStyle: PropTypes.object,
    className: PropTypes.string,
    color: PropTypes.string
  }

  render () {
    const { children, componentStyle, className, color } = this.props

    return (
      <Text
        className={className}
        style={{ ...componentStyle }}
        color={color}
      >
        {children}
      </Text>
    )
  }
}
