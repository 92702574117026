import t from './actionTypes'

const defaultState = {
  areCanceledOrdersMuted: false,
  areIncomingOrdersMuted: false,
  areManifestsToPrintMuted: false
}

const soundAlerts = (state = defaultState, action) => {
  switch (action.type) {
    case t.TOGGLE_CANCELED_ORDER_SOUNDS:
      return {
        ...state,
        areCanceledOrdersMuted: !state.areCanceledOrdersMuted
      }

    case t.TOGGLE_INCOMING_ORDER_SOUNDS:
      return {
        ...state,
        areIncomingOrdersMuted: !state.areIncomingOrdersMuted
      }

    case t.TOGGLE_MANIFESTS_TO_PRINT_SOUNDS:
      return {
        ...state,
        areManifestsToPrintMuted: !state.areManifestsToPrintMuted
      }

    default:
      return state
  }
}

export default soundAlerts
