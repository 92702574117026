// These are reducer keys mapped to form labels for easy feedback generation

/*
  !!ACHTUNG!!
  We're also using this to whitelist properties to merge onto the API payload for promo creation
  Be aware of merging/deep merging behavior, talk to me if you need a primer. -Chris

  NOTE: Order matters on these! It determines the sequential display of validation warnings on the forms.
*/

export const SHARED_REQUIRED = [
  {
    key: 'code',
    label: 'PROMO CODE'
  },
  {
    key: 'description',
    label: 'DESCRIPTION'
  },
  {
    key: 'valid_at',
    label: 'START DATE'
  },
  {
    key: 'expires_at',
    label: 'END DATE'
  },
  {
    key: 'rules.global_limit',
    label: 'GLOBAL USES'
  },
  {
    key: 'rules.user_limit',
    label: 'PER USER USE'
  },
  {
    key: 'rules.promo_type',
    label: 'PROMOTION TYPE'
  },
  {
    key: 'rules.operators.credit_type',
    label: 'CREDIT TYPE'
  },
  {
    key: 'rules.operators.credit_amount',
    label: 'CREDIT AMOUNT'
  },
  {
    key: 'rules.operators.credit_max',
    label: 'MAX DISCOUNT'
  },
  {
    key: 'meta.marketing_channel',
    label: 'MARKETING CHANNEL'
  },
  {
    key: 'rules.state_id',
    label: 'STATE'
  },
  // These matter, but the user should never see validation for them, and we need to fix something if they do ;)
  // They will either be set implicitly from user events, or will exist at initialization
  {
    key: 'rules.applies_to',
    label: 'If you see this, contact an engineer! ...rules.applies_to'
  }
]

export const APPLIES_TO_ID_REQUIRED = [
  {
    key: 'rules.operators.id_type',
    label: 'CATALOG DISCOUNT'
  },
  {
    key: 'rules.operators.id_value',
    label: 'CATALOG (ITEM|GROUP|BRAND)'
  }
]

export const SHARED_OPTIONAL = [
  {
    key: 'rules.user_cohort.user_ids',
    label: ''
  },
  {
    key: 'rules.user_cohort.dispensary_id',
    label: ''
  },
  {
    key: 'rules.user_cohort.depot_ids',
    label: ''
  },
  {
    key: 'rules.global_limit',
    label: ''
  },
  {
    key: 'rules.user_limit',
    label: ''
  },
  {
    key: 'rules.order_history',
    label: ''
  },
  {
    key: 'enabled',
    label: ''
  },
  {
    key: 'rules.min_order_amount',
    label: 'MINIMUM ORDER VALUE'
  }
]

export const BOGO_REQUIRED = [
  {
    key: 'rules.operators.buy.id_type',
    label: 'BUY ONE TYPE'
  },
  {
    key: 'rules.operators.buy.id_value',
    label: 'BUY ONE VALUE'
  },
  {
    key: 'rules.operators.buy.quantity',
    label: 'BUY ONE QUANTITY'
  },
  {
    key: 'rules.operators.get.id_type',
    label: 'GET ONE TYPE'
  },
  {
    key: 'rules.operators.get.id_value',
    label: 'GET ONE VALUE'
  },
  {
    key: 'rules.operators.get.quantity',
    label: 'GET ONE QUANTITY'
  }
]
