import React, { PureComponent } from 'react'
import styled from '@emotion/styled'
import { node } from 'prop-types'

const ImageContainer = styled.div`
  position: relative;
`

export default class ImageContainerComponent extends PureComponent {
  static propTypes = {
    children: node
  }

  render () {
    return (
      <ImageContainer>{this.props.children}</ImageContainer>
    )
  }
}
