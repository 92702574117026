import t from './actionTypes'
import caseActions from 'src/redux/cases/actionTypes'

import { DEFAULT_MENU_SLUG } from 'src/helpers/constants'

const USER_DEFAULT = {
  basic: {},
  stat: {},
  extra: {}
}

const defaultState = {
  user: USER_DEFAULT,
  catalogBucketId: null,
  catalogErrorIdMap: {},
  cartId: null,
  depotId: null,
  cartQuote: {},
  catalogCartEta: {},
  catalogBucket: {},
  outOfDepotItems: [], // happens when a depot uploads csv with catalogItemIds outside of their inventory
  menuSlug: DEFAULT_MENU_SLUG,
  conciergeSlug: '',
  productsFilter: '',
  showOrderDrawer: false
}

const catalogBuilder = (state = defaultState, { type, payload }) => {
  switch (type) {
    case t.CLEAR_ORDER_BUILDER_STORE:
      return defaultState

    case t.SET_ORDER_BUILDER_USER:
      return {
        ...state,
        user: payload
      }

    case t.CLEAR_ORDER_BUILDER_USER:
      return {
        ...state,
        user: USER_DEFAULT
      }

    case t.SET_ORDER_BUILDER_MENU_SLUG:
      return {
        ...state,
        menuSlug: payload
      }

    case t.CLEAR_ORDER_BUILDER_MENU_SLUG:
      return {
        ...state,
        menuSlug: DEFAULT_MENU_SLUG
      }

    case t.SET_ORDER_BUILDER_DEPOT_ID:
      return {
        ...state,
        depotId: payload
      }

    case t.CLEAR_ORDER_BUILDER_DEPOT_ID:
      return {
        ...state,
        depotId: null
      }

    case t.SET_ORDER_BUILDER_CATALOG_CART_ETA:
      return {
        ...state,
        catalogCartEta: payload
      }

    case t.CLEAR_ORDER_BUILDER_CATALOG_CART_ETA:
      return {
        ...state,
        catalogCartEta: {}
      }

    case t.SET_ORDER_BUILDER_CART_ID:
      return {
        ...state,
        cartId: payload
      }

    case t.CLEAR_ORDER_BUILDER_CART_ID:
      return {
        ...state,
        cartId: null
      }

    case t.SET_ORDER_BUILDER_CATALOG_BUCKET_ID:
      return {
        ...state,
        catalogBucketId: payload,
        outOfDepotItems: [],
        catalogErrorIdMap: {}
      }

    case t.CLEAR_ORDER_BUILDER_CATALOG_BUCKET_ID:
      return {
        ...state,
        catalogBucketId: null
      }

    case t.SET_ORDER_BUILDER_CATALOG_BUCKET:
      return {
        ...state,
        catalogBucket: payload
      }

    case t.CLEAR_ORDER_BUILDER_CATALOG_CART:
      return {
        ...state,
        catalogBucket: {}
      }

    case t.SET_ORDER_BUILDER_CONCIERGE_SLUG:
      return {
        ...state,
        conciergeSlug: payload
      }

    case t.CLEAR_ORDER_BUILDER_CONCIERGE_SLUG:
      return {
        ...state,
        conciergeSlug: ''
      }

    case t.SET_ORDER_BUILDER_CART_QUOTE:
      return {
        ...state,
        cartQuote: payload
      }

    case t.CLEAR_ORDER_BUILDER_CART_QUOTE:
      return {
        ...state,
        cartQuote: {}
      }

    case t.CLEAR_ORDER_BUILDER_CART:
      return {
        ...state,
        cartQuote: {}
      }

    case t.SET_ORDER_BUILDER_PRODUCTS_FILTER:
      return {
        ...state,
        productsFilter: payload
      }

    case t.CLEAR_ORDER_BUILDER_PRODUCTS_FILTER:
      return {
        ...state,
        productsFilter: ''
      }

    case t.SET_ORDER_BUILDER_SHOW_ORDER_DRAWER:
      return {
        ...state,
        showOrderDrawer: payload
      }

    case caseActions.SET_CATALOG_CASE_INFO:
      return {
        ...state,
        color: payload.color,
        depotId: payload.depotId,
        description: payload.description,
        name: payload.name
      }

    case t.SET_OUT_OF_DEPOT_CATALOG_ITEMS:
      return {
        ...state,
        outOfDepotItems: payload.catalogItems,
        catalogErrorIdMap: payload.errorIdMap,
        catalogBucket: payload.catalogBucket
      }

    case t.REMOVE_OUT_OF_DEPOT_CATALOG_ITEM:
      return {
        ...state,
        catalogBucket: payload.catalogBucket,
        outOfDepotItems: payload.outOfDepotItems
      }

    default:
      return state
  }
}

export default catalogBuilder
