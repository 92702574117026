import React from 'react'
import { bool, func, object, string } from 'prop-types'

export default function PrintIcon ({ onClick, color = 'white', size = '3em', runner = false, right = '', componentStyle }) {
  const defaultStyle = {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    marginRight: right
  }

  // merge incoming styles into the defaultStyle object to overwrite default values
  const buttonStyle = {...defaultStyle, ...componentStyle}

  if (runner) {
    buttonStyle.position = 'absolute'
    buttonStyle.right = '2rem'
  }

  return (
    <button style={buttonStyle} onClick={onClick}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 34 34'
        version='1.1'
        xmlnsXlink='http://www.w3.org/1999/xlink'
      >
        <path
          d='M30.786 6.912v-5.14c0-.704-.571-1.276-1.275-1.276H4.49c-.704 0-1.275.572-1.275 1.275v5.141c-1.686.2-3.002 1.62-3.002 3.36v12.607c0 1.875 1.525 3.4 3.4 3.4h3.684v5.95c0 .703.572 1.275 1.275 1.275h17.283c.703 0 1.275-.572 1.275-1.275v-5.95h3.258c1.876 0 3.4-1.525 3.4-3.4V10.27c0-1.739-1.315-3.16-3-3.359zM5.764 3.046h22.472v3.825H5.764V3.046zm18.815 27.908H9.846v-9.067H24.58v9.067zm6.659-8.075c0 .469-.381.85-.85.85h-3.26v-1.841h.568a1.275 1.275 0 0 0 0-2.55H6.304a1.275 1.275 0 0 0 0 2.55h.992v1.84H3.612a.85.85 0 0 1-.85-.85V10.272c0-.469.381-.85.85-.85h26.775c.47 0 .85.381.85.85V22.88zM26.267 11.44a2.708 2.708 0 0 0-2.705 2.705 2.708 2.708 0 0 0 2.705 2.705 2.708 2.708 0 0 0 2.704-2.705 2.708 2.708 0 0 0-2.704-2.705zm-6.665 0a2.707 2.707 0 0 0-2.703 2.705 2.707 2.707 0 0 0 2.703 2.705 2.708 2.708 0 0 0 2.705-2.705 2.709 2.709 0 0 0-2.705-2.705z'
          fill={color}
          fillRule='nonzero'
        />
      </svg>
    </button>
  )
}

PrintIcon.propTypes = {
  onClick: func,
  color: string,
  size: string,
  runner: bool,
  right: string,
  componentStyle: object
}
