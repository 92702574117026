import React, { PureComponent } from 'react'
import { array } from 'prop-types'
import styled from '@emotion/styled'
import Colors from 'microcomponents/colors'

import { orderIsCanceled, orderIsDelivered } from 'src/helpers/orders'
import OrderInfoBox from './order-summary-box'
import OrderBreakdownByPaymentBox from './order-summary-box-special'
import CashBreakdownBox from './cash-breakdown-box'
import {
  CASH_PAYMENT_METHOD,
  CREDIT_PAYMENT_METHOD,
  PRESENT_CREDIT_CARD_PAYMENT_METHOD,
  PRESENT_DEBIT_CARD_PAYMENT_METHOD,
  DEBIT_CASH_BACK_PAYMENT_METHOD,
  ACH_PAYMENT_METHOD,
  STORE_CREDITS_PAYMENT_METHOD
} from 'helpers/constants'

const onlinePaymentMethods = [CREDIT_PAYMENT_METHOD, ACH_PAYMENT_METHOD, STORE_CREDITS_PAYMENT_METHOD]
const inPersonCardPayment = [PRESENT_CREDIT_CARD_PAYMENT_METHOD, PRESENT_DEBIT_CARD_PAYMENT_METHOD, DEBIT_CASH_BACK_PAYMENT_METHOD]

export default class OrderSummary extends PureComponent {
  static propTypes = {
    orders: array.isRequired
  }

  static defaultProps = {
    orders: []
  }

  render () {
    const { orders } = this.props
    const amountOfOrders = orders.length
    const canceledOrders = orders.filter(orderIsCanceled).length
    let cashBack = 0
    let totalOnlineTips = 0
    const orderTotals = orders.reduce((acc, order) => {
      if (!orderIsDelivered(order)) return acc

      if (order.paymentMethod === CASH_PAYMENT_METHOD) {
        acc.cash.total += order.chargeAmount || 0
      } else if (onlinePaymentMethods.includes(order.paymentMethod)) {
        acc.cc.total += order.chargeAmount || 0
      } else if (inPersonCardPayment.includes(order.paymentMethod)) {
        acc.ccInPerson.total += order.chargeAmount || 0
      }
      if (order.paymentMethod === DEBIT_CASH_BACK_PAYMENT_METHOD) {
        cashBack += order.priceInfo.cashBack
      }
      totalOnlineTips += order.priceInfo.driverTip || 0
      acc.total.total += order.chargeAmount || 0
      return acc
    }, {
      cc: { total: 0, subtitle: 'ONLINE PAYMENTS' },
      cash: { total: 0, subtitle: 'CASH SALES' },
      ccInPerson: { total: 0, subtitle: 'CARD IN PERSON' },
      total: { total: 0, subtitle: 'TOTAL SALES' }
    })

    return (
      <>
        <TopContainer>
          <OrderInfoBox
            color={Colors.cbd[1]}
            title={amountOfOrders}
            subtitle='ORDERS TODAY'
          />
          <OrderInfoBox
            color={Colors.danger[1]}
            title={canceledOrders}
            subtitle='CANCELED TODAY'
          />
          <CashBreakdownBox
            color={Colors.sativa[1]}
            cash={orderTotals.cash.total}
            cashBack={cashBack}
          />
          <OrderBreakdownByPaymentBox
            color={Colors.hybrid[1]}
            orderTotals={orderTotals}
          />
          <OrderInfoBox
            color={Colors.indica[1]}
            title={`$${totalOnlineTips}`}
            subtitle='ONLINE TIPS'
          />
        </TopContainer>
      </>
    )
  }
}

const TopContainer = styled.div`
  margin-top: 1rem;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  overflow-x: auto;
`
