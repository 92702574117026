import { createSelector } from 'reselect'

export const getPermissionsStore = (state) => state.permissions

export const getIsAdmin = createSelector(
  [getPermissionsStore],
  function (permissionsStore) {
    return permissionsStore.isAdmin
  }
)

export const getIsDispManager = createSelector(
  [getPermissionsStore],
  function (permissionsStore) {
    return permissionsStore.isDispManager
  }
)
