import { createSelector } from 'reselect'

import { getDefaultDriverObjectWithLocation } from 'helpers/drivers'

import { getDriverMap } from 'src/redux/driver-map/selectors'
import { getDriverInventoryMap } from 'src/redux/driver-inventory-map/selectors'
import { getDriverOrdersMap } from 'src/redux/driver-orders-map/selectors'

const getDriversStore = state => state.drivers

export const getFocusedDriverDepotHasMETRCRules = createSelector(
  [getDriversStore],
  driversStore => driversStore.focusedDriverDepotHasMETRCRules
)

export const getDriverMETRCInfoDrawerOpen = createSelector(
  [getDriversStore],
  driversStore => driversStore.driverMETRCInfoDrawerOpen
)

export const getDriverDetailsContent = createSelector(
  [getDriversStore],
  driversStore => driversStore.driverDetailsContent
)

export const getFocusedDriverId = createSelector(
  [getDriversStore],
  function (driversStore) {
    return driversStore.focusedDriverId
  }
)

export const getFocusedDriverOnfleetId = createSelector(
  [getFocusedDriverId, getDriverMap],
  (driverId, drivers) => {
    const driver = drivers[driverId] || getDefaultDriverObjectWithLocation()
    return driver?.onfleetWorkerId
  }
)

export const getIsDriverFocused = createSelector(
  [getFocusedDriverId],
  driverId => {
    return Boolean(driverId || driverId === 0)
  }
)

export const getFocusedDriver = createSelector(
  [getFocusedDriverId, getDriverMap],
  (driverId, drivers) => {
    return drivers[driverId] || getDefaultDriverObjectWithLocation()
  }
)

const EMPTY_DRIVER_INVENTORY = { inventory: [], inventoryChecksum: {} }
export const getFocusedDriverInventory = createSelector(
  [getFocusedDriverId, getDriverInventoryMap],
  (focusedDriverId, driverInventoryMap) => {
    return driverInventoryMap[focusedDriverId] || EMPTY_DRIVER_INVENTORY
  }
)

const EMPTY_DRIVER_ORDERS = []
export const getFocusedDriverOrders = createSelector(
  [getFocusedDriverId, getDriverOrdersMap],
  (focusedDriverId, driverOrdersMap) => {
    return driverOrdersMap[focusedDriverId] || EMPTY_DRIVER_ORDERS
  }
)

export const getDriverInventoryLoadingMap = createSelector(
  [getDriversStore],
  driversStore => {
    return driversStore.driverInventoryLoadingMap
  }
)

export const getDriverOrdersLoadingMap = createSelector(
  [getDriversStore],
  driversStore => {
    return driversStore.driverOrdersLoadingMap
  }
)

export const getIsFocusedDriverInventoryLoading = createSelector(
  [getFocusedDriverId, getDriverInventoryLoadingMap],
  (driverId, driverInventoryMap) => {
    return driverInventoryMap[driverId]
  }
)

export const getIsFocusedDriverOrdersLoading = createSelector(
  [getFocusedDriverId, getDriverOrdersLoadingMap],
  (driverId, driverOrdersMap) => {
    return driverOrdersMap[driverId]
  }
)

export const getFocusedDriverDepotId = createSelector(
  [getFocusedDriver],
  focusedDriver => {
    return focusedDriver.depotId
  }
)
