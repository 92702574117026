import { createSelector } from 'reselect'
import { orderIsCanceled } from 'src/helpers/orders'
import { getDepotsMap } from 'src/redux/depots-map/selectors'

// outForDelivery = false && notReAssignable = true -> driver is sitting at a depot waiting
const getHubState = (state) => state.hub
const getOrders = (state) => Object.values({ ...state.hub[state.hub.selectedDepotId] })
const getCanceledOrders = (state) =>
  Object.values({ ...state.hub[state.hub.selectedDepotId] }).filter(orderIsCanceled)

export const getUserId = (state) => state.user.userId

export const getOrdersIncoming = createSelector(
  [getOrders],
  function (orders) {
    const ordersIncoming = []
    orders.forEach((order) => {
      if (orderIsCanceled(order) || order.outForDelivery) return

      if (!order.isPrinted && !order.packedBy) {
        ordersIncoming.push(order)
      }
    })

    return ordersIncoming
  }
)

export const getOrdersPacking = createSelector(
  [getOrders],
  function (orders) {
    const ordersPacking = []
    orders.forEach((order) => {
      if (orderIsCanceled(order) || order.outForDelivery) return

      if (order.isPrinted && !order.packedBy) {
        ordersPacking.push(order)
      }
    })
    return ordersPacking
  }
)

export const getOrdersPacked = createSelector(
  [getOrders],
  function (orders) {
    const ordersPacked = []
    orders.forEach((order) => {
      if (orderIsCanceled(order) || order.outForDelivery) return

      if (order.packedBy && !order.notReAssignable) {
        ordersPacked.push(order)
      }
    })
    return ordersPacked
  }
)

export const getOrdersAwaiting = createSelector(
  [getOrders],
  function (orders) {
    const ordersAwaiting = []
    orders.forEach((order) => {
      if (orderIsCanceled(order) || order.outForDelivery) return

      if (order.packedBy && order.notReAssignable) {
        ordersAwaiting.push(order)
      }
    })
    return ordersAwaiting
  }
)

export const getAllDriverOrders = createSelector(
  [getOrders],
  function (orders) {
    const drivers = {}

    orders.forEach((order) => {
      const driverId = order.acceptedBy && order.acceptedBy.id

      if (!driverId || orderIsCanceled(order) || !order.notReAssignable) return

      if (!drivers[driverId]) {
        // initialize driverQueue if we don't have one already
        drivers[driverId] = []
      }
      drivers[driverId].push(order)
    })

    return drivers
  }
)

export const getCanceledOrdersNotRecovered = createSelector(
  [getCanceledOrders],
  function (canceledOrders) {
    return canceledOrders.filter((order) => order.packedBy)
  }
)

export const getRecoveredOrders = createSelector(
  [getCanceledOrders],
  function (canceledOrders) {
    return canceledOrders.filter((order) => !order.packedBy)
  }
)

export const getOrdersNeedManifestPrinted = createSelector(
  [getAllDriverOrders],
  function (driverOrders) {
    return Object.values(driverOrders).filter((driverOrders) => {
      if (!driverOrders.length) return false

      const firstOrder = driverOrders[0]
      return !firstOrder.acceptedBy.hasPrintedManifest && !firstOrder.outForDelivery
    })
  }
)

export const getHubSelectedDepotId = createSelector(
  [getHubState],
  function (hub) {
    return hub.selectedDepotId
  }
)

export const getHubSelectedDepot = createSelector(
  [getDepotsMap, getHubSelectedDepotId],
  function (depotsMap, hubSelectedDepotId) {
    return depotsMap && depotsMap[hubSelectedDepotId] ? depotsMap[hubSelectedDepotId] : {}
  }
)
export const getIsSelectedDepotExternalFulfillment = createSelector(
  [getHubSelectedDepot],
  function (selectedDepot) {
    return selectedDepot.usesExternalFulfillment
  }
)

export const getIsHubSelectedDepotMetrcRequired = createSelector(
  [getHubSelectedDepot],
  function (selectedDepot) {
    return selectedDepot.metrcRequired
  }
)
