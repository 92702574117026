import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import sortOn from 'sort-on'
import warning from 'warning'

import TableHead from './table-head'
import TableRow from './table-row'

export default class Table extends PureComponent {
  static propTypes = {
    config: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.array,
    height: PropTypes.string,
    noDataMsg: PropTypes.string,
    rowClick: PropTypes.func,
    rowStyle: PropTypes.func,
    sortAscending: PropTypes.bool,
    sortBy: PropTypes.string,
    style: PropTypes.object
  }

  static defaultProps = {
    config: [],
    data: [],
    height: '100%',
    noDataMsg: '',
    rowClick: () => warning(false, 'rowClick not configured for <Table />'),
    rowStyle: () => warning(false, 'rowStyle not configured for <Table />')
  }

  constructor (props) {
    super(props)

    this.state = {
      sortBy: props.sortBy || '',
      ascending: props.sortAscending || false,
      noDataMsg: props.noDataMsg || 'Loading...'
    }
    this.handleSort = this.handleSort.bind(this)
  }

  handleSort (sortKey, disableSort = false) {
    if (disableSort) return
    const ascending = this.state.sortBy === sortKey
      ? !this.state.ascending
      : true

    this.setState({ sortBy: sortKey, ascending })
  }

  render () {
    const {
      data,
      config,
      height,
      noDataMsg,
      rowStyle,
      rowClick,
      style
    } = this.props
    const { ascending, sortBy } = this.state

    if (!data || !data.length) {
      return (
        <StyledTable style={style}>
          <tbody>
            <tr><td>{noDataMsg}</td></tr>
          </tbody>
        </StyledTable>
      )
    }

    // sortOn detects '-' at beginning of string to flip sort order.
    const sortedData = sortBy
      ? sortOn(data, (ascending ? sortBy : '-' + sortBy))
      : data

    return (
      <TableContainer style={{ height }}>
        <StyledTable style={style}>
          <TableHead
            config={config}
            data={data}
            onClick={this.handleSort}
            sortBy={sortBy}
            sortAscending={ascending}
          />
          <StyledTbody>
            {
              sortedData.map((item, index) => (
                <TableRow
                  data={item}
                  config={config}
                  key={index}
                  rowStyle={rowStyle}
                  rowClick={rowClick}
                />
              ))
            }
          </StyledTbody>
        </StyledTable>
      </TableContainer>
    )
  }
}

const TableContainer = styled.div`
  overflow: auto;
`

const StyledTable = styled.table`
  position: relative;
  padding-left: 0;
  width: 100%;
`

const StyledTbody = styled.tbody`
  padding-top: 6rem;
`
