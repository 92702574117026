import t from './actionTypes'

export const defaultState = {
  deliveryScheduleList: []
}

const deliverySchedule = (state = defaultState, { type, payload }) => {
  switch (type) {
    case t.SET_DELIVERY_SCHEDULE:
      return {
        ...state,
        deliveryScheduleList: payload
      }

    default:
      return state
  }
}

export default deliverySchedule
