import React from 'react'

export default function IconDepotMarker ({ color = 'black', size = '36px', componentStyle }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 64 85'
      version='1.1'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <filter
          x='-48.4%'
          y='-115.4%'
          width='196.8%'
          height='330.8%'
          filterUnits='objectBoundingBox'
          id='a'
        >
          <feGaussianBlur
            stdDeviation='6.00000024'
            in='SourceGraphic'
          />
        </filter>
      </defs>
      <g
        transform='translate(-955.000000, -166.000000) translate(965.000000, 166.000000)'
        stroke='none'
        strokeWidth={1}
        fill={color}
        fillRule='evenodd'
      >
        <ellipse
          fillOpacity='0.4'
          fill='#373838'
          filter='url(#a)'
          cx='19.6'
          cy='63.8'
          rx='18.6'
          ry='7.8'
        />
        <circle
          fill='#FFF'
          cx='27.3'
          cy='26.1'
          r='21.3'
        />
        <g
          transform='translate(0.600000, 0.000000)'
          fill={color}
          fillRule='nonzero'
        >
          <path
            d='M26.235 69.844c-4.5-1.622-20.446-22.673-24.805-35.18A26.005 26.005 0 0 1 0 26.13C0 11.722 11.769 0 26.235 0s26.234 11.722 26.234 26.13c0 2.922-.482 5.793-1.43 8.532-4.36 12.51-20.304 33.56-24.804 35.182zm0-63.674c-10.999 0-19.946 8.912-19.946 19.866 0 10.954 8.947 19.864 19.946 19.864 10.997 0 19.944-8.91 19.944-19.865 0-10.954-8.947-19.865-19.944-19.865z'
          />
          <g transform='translate(13.200000, 14.400000)'>
            <path
              d='M26.88 10.56L14.34.48c-.42-.36-1.05-.33-1.47 0L.72 10.59c-.51.42-.57 1.14-.15 1.65.24.27.57.42.9.42.27 0 .54-.09.75-.27l11.43-9.51 11.76 9.48c.51.39 1.23.33 1.65-.18.39-.48.3-1.23-.18-1.62z'
            />
            <path
              d='M22.02 12.15h-.21c-.39 0-.72.27-.81.63l-.21.81-.33-.45-.96-2.52c-.15-.39-.54-.66-.96-.66H8.82c-.42 0-.81.27-.96.66l-.96 2.52-.36.51-.21-.9a.837.837 0 0 0-.81-.63h-.21c-.45 0-.84.39-.84.84 0 .42.3.78.72.84l.6.09.21.54-.69.96c-.3.3-.45.72-.45 1.14v3.96c0 .45.33.84.75.93V23.37c0 .24.21.45.45.45h1.86c.24 0 .45-.21.45-.45v-1.86-.06H18.99V23.37c0 .24.21.45.45.45h1.86c.24 0 .45-.21.45-.45v-1.86-.06a.94.94 0 0 0 .81-.93v-3.99c0-.42-.18-.84-.45-1.14l-.72-.99.18-.45.6-.09c.42-.06.72-.42.72-.84-.03-.48-.42-.87-.87-.87zM9.18 18.66H6.72a.57.57 0 0 1-.57-.57v-.9c0-.3.24-.57.57-.57H7.8c.12 0 .21.03.3.09l1.38.9c.15.09.24.27.24.48.03.3-.21.57-.54.57zm-.57-4.56c-.24 0-.42-.24-.33-.48l.81-2.34c.06-.15.18-.24.33-.24h8.67c.15 0 .3.09.33.27l.69 2.34c.06.24-.09.45-.33.45H8.61zm8.94 3.99c0-.18.09-.36.24-.48l1.38-.9c.09-.06.21-.09.3-.09h1.08c.3 0 .57.24.57.57v.9c0 .3-.24.57-.57.57h-2.46c-.27 0-.54-.27-.54-.57z'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
