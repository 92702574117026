import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import warning from 'warning'
import dotProp from 'es5-dot-prop'

import TableCell from '../table-cell'

export default class TableRow extends PureComponent {
  static propTypes = {
    config: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.object,
    rowClick: PropTypes.func,
    rowStyle: PropTypes.func
  }

  static defaultProps = {
    config: [],
    data: [],
    rowClick: () => warning(false, 'rowClick not configured for <TableRow />'),
    rowStyle: () => warning(false, 'rowStyle not configured for <TableRow />')
  }

  render () {
    const {
      config,
      data,
      rowStyle,
      rowClick
    } = this.props

    return (
      <tr
        className={rowStyle(data)}
        onClick={() => rowClick(data)}
      >
        {
          config.map((configItem, i) => (
            <TableCell
              value={dotProp.get(data, configItem.key)}
              formatter={configItem.formatter}
              key={i}
              row={data}
            />
          ))
        }
      </tr>
    )
  }
}
