import React from 'react'
import { string } from 'prop-types'

function VerticalMenuIcon ({ color, height, width }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>Menu / More</title>
      <g
        id='Icons-/-Navigation-24px-/-White-/-More'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='More'>
          <rect id='Bounds' x='0' y='0' width='24' height='24' />
          <path d='M12,8 C13.1,8 14,7.1 14,6 C14,4.9 13.1,4 12,4 C10.9,4 10,4.9 10,6 C10,7.1 10.9,8 12,8 L12,8 Z M12,10 C10.9,10 10,10.9 10,12 C10,13.1 10.9,14 12,14 C13.1,14 14,13.1 14,12 C14,10.9 13.1,10 12,10 L12,10 Z M12,16 C10.9,16 10,16.9 10,18 C10,19.1 10.9,20 12,20 C13.1,20 14,19.1 14,18 C14,16.9 13.1,16 12,16 L12,16 Z' id='Shape' fill={color} />
        </g>
      </g>
    </svg>
  )
}

VerticalMenuIcon.propTypes = {
  color: string,
  height: string,
  width: string
}

VerticalMenuIcon.defaultProps = {
  color: '#FFFFFF',
  height: '2.4rem',
  width: '2.4rem'
}

export default VerticalMenuIcon
