import React, { PureComponent } from 'react'
import { number, oneOfType, string } from 'prop-types'
import styled from '@emotion/styled'

export default class InfoBlock extends PureComponent {
  static propTypes = {
    color: string,
    subTitle: oneOfType([
      string,
      number
    ]),
    title: oneOfType([
      string,
      number
    ])
  }

  static defaultProps = {
    title: '--',
    subTitle: '--'
  }

  render () {
    const { color, subTitle, title } = this.props
    return (
      <Container>
        <Title color={color}>{title}</Title>
        <Subtitle color={color}>{subTitle}</Subtitle>
      </Container>
    )
  }
}

const Container = styled.div`
  ${({ color }) => color && `color: ${color}`};
  display: inline-block;
  margin-bottom: 1rem;
  margin-right: 3rem;
`

const Title = styled.div`
  font-size: 2.4rem;
`

const Subtitle = styled.div`
  font-size: 1.4rem;
  margin-top: 0.2rem;
`
