export default {
  SET_PROMOS: 'SET_PROMOS',
  TOGGLE_MANAGE_BAR: 'TOGGLE_MANAGE_BAR',
  TOGGLE_PROMO_PREVIEW: 'TOGGLE_PROMO_PREVIEW',
  SET_PROMO_CLONEABLE: 'SET_PROMO_CLONEABLE',
  SET_EDIT_PROMO: 'SET_EDIT_PROMO',
  SET_SEARCH_QUERY: 'SET_SEARCH_QUERY'
  // SET_PAGE: 'SET_PAGE',
  // SET_TOTAL: 'SET_TOTAL',
  // SET_QUERY: 'SET_QUERY'
}
