import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { css } from 'emotion'
import { object } from 'prop-types'

import ROUTES from 'src/components/router/routes'
import { PromoCodeNavIcon, TagNavIcon } from 'microcomponents/icons'

import NavButtonGroup from 'src/components/navigation-button-group'
import NavButton from 'src/components/navigation-button'

import { secondaryDark } from 'src/helpers/css-variables'

class PromoNav extends PureComponent {
  static propTypes = {
    match: object
  }

  render () {
    const { match: { path } } = this.props

    return (
      <NavButtonGroup>
        <NavButton
          icon={PromoCodeNavIcon}
          isActive={path === ROUTES.PROMOS}
          label='Codes'
          LinkStyle={LinkStyle}
          route={ROUTES.PROMOS}
        />
        <NavButton
          icon={TagNavIcon}
          isActive={path === ROUTES.PROMOS_TAGS}
          label='Tags'
          LinkStyle={LinkStyle}
          route={ROUTES.PROMOS_TAGS}
        />
      </NavButtonGroup>
    )
  }
}

export default withRouter(PromoNav)

const LinkStyle = css`
  background-color: ${secondaryDark};
`
