import { css } from 'emotion'
import { accessory5, blazeUpBlack, primaryDark, white } from 'helpers/css-variables'

export const topBorderRadius = css`
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
`

export const backIcon = css`
  font-size: 2rem;

  @media (min-width: 1200px) {
    font-size: inherit;
  }
`

export const backText = css`
  margin-left: 0.5rem;
  display: none;

  @media (min-width: 1200px) {
    display: initial;
  }
`

export const container = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`

export const content = css`
  display: flex;
  padding-top: 2rem;
  flex-basis: 100%;
  justify-content: space-between;
  overflow: auto;
`

export const leftColumn = css`
  flex-shrink: 0;
  padding-left: 1rem;
`

export const centerColumn = css`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 5rem;
  flex-basis: 90%;
  max-width: 90rem;

  @media (min-width: 1200px) {
    flex-basis: 70%;
  }
`

export const rightColumn = css`
  flex: 1 1 50%;

  @media (min-width: 1200px) {
    flex-basis: 30%;
  }
`

export const form = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: max-content;
  background: ${accessory5};
  color: ${blazeUpBlack};
  -webkit-overflow-scrolling: touch;
`

export const formFooter = css`
  background-color: ${primaryDark};
  padding: 1rem 0;
  color: ${white};
`

export const ToolTipPortal = css`
  font-size: 1.6rem;
  background-color: ${white};
`

export const tooltipWrapper = css`
  cursor: pointer;
  display: flex;
  margin-left: 0.5rem;
  align-items: center;
`

export const tooltipImage = css`
  margin-top: 0.1rem;
`

export const loading = css`
  height: 1.6rem;
  width: 1.6rem;
  margin: 0.3rem 0;
`

export const backButton = css`
  cursor: pointer;
`
