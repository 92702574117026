import React, { PureComponent, Fragment } from 'react'
import { array, bool, func, node, object, oneOfType, shape, string } from 'prop-types'
import { ErrorMessage, withFormik } from 'formik'
import * as Yup from 'yup'
import sortOn from 'sort-on'
import styled from '@emotion/styled'
import { css } from 'emotion'
import { addDays } from 'date-fns'
import { format } from 'date-fns-tz'

import Accordion from 'components/accordion'
import { dateOnly, formatAsPacificTime, formatDate } from 'src/helpers/date'
import { Flex, Row, Column } from 'src/microcomponents/flexbox-helpers'
import InputLabel from 'src/microcomponents/input-label'
import DateInput from 'src/microcomponents/input-date'
import { ArrowIcon } from 'src/microcomponents/icons'
import Button, { DANGER, PRIMARY } from 'components/button'

import { TAG_MAP } from 'src/helpers/constants'
import { PROMOS_IANA_TIME_ZONE } from 'src/helpers/promos/definitions'

import {
  depotCheckbox,
  depotSelectAllCheckbox,
  errorText,
  inputError,
  productLabel,
  tagForm,
  tagFormButtons,
  tagFormContent,
  tagIcon,
  tagIconSelectContainer,
  tagTypeContainer,
  title
} from './style.js'
import { selectContainer } from '../../style.js'

const TZ = format(new Date(), 'z', { timeZone: PROMOS_IANA_TIME_ZONE }) // PST OR PDT

const todayStartAt = `${dateOnly()}T12:00Z` // 5am Pacific
const todayExpiresAt = `${dateOnly(addDays(new Date(), 1).toISOString())}T06:00Z` // 11pm pacific,

const DEFAULT_FORM_DATA = {
  active: false,
  areaIds: [],
  description: '',
  expireDate: todayExpiresAt,
  expiresAt: todayExpiresAt,
  expireTime: todayExpiresAt,
  id: null,
  items: [],
  startAt: todayStartAt,
  startDate: todayStartAt,
  startTime: todayStartAt,
  tagType: '',
  text: ''
}
class TagForm extends PureComponent {
  static propTypes = {
    depots: array,
    dirty: bool,
    errors: shape({
      areaIds: node,
      expiresAt: string,
      startAt: string,
      text: string
    }),
    formData: shape({
      description: string,
      expireDate: string,
      expiresAt: string,
      expireTime: string,
      id: string,
      items: array,
      state_id: string,
      markets: array,
      startAt: string,
      startDate: string,
      startTime: string,
      tagType: string,
      text: string
    }),
    handleBlur: func,
    handleChange: func,
    handleSubmit: func,
    isEdit: bool,
    onCancelTagForm: func,
    onSelectDepots: func,
    onSetCloseFormMessage: func,
    // This is used in the HOC
    // eslint-disable-next-line react/no-unused-prop-types
    onSubmitForm: func,
    resetForm: func,
    handleStateChange: func,
    selectedDepots: object,
    setFieldValue: func,
    tagTypes: array,
    touched: shape({
      areaIds: array,
      expiresAt: oneOfType([bool, string]),
      startAt: oneOfType([bool, string]),
      text: oneOfType([bool, string])
    }),
    availableStates: array,
    selectedState: string,
    values: object
  }

  static defaultProps = {
    tagTypes: [],
    selectedDepots: {},
    selectedState: 'CA',
    formData: DEFAULT_FORM_DATA
  }

  state = {
    addHeaderText: 'Add a Tag',
    editHeaderText: 'Edit a Tag',
    selectedTagIcon: TAG_MAP.default,
    selectedDepots: {},
    selectAllDepots: false,
    tagIconSelectMenuOpen: false
  }

  componentDidMount () {
    const { formData, selectedDepots, setFieldValue } = this.props
    const filteredDepots = this.filterDepots()
    const allDepotsSelected = filteredDepots.length === Object.keys(selectedDepots).length

    this.setState({
      selectAllDepots: allDepotsSelected,
      selectedDepots
    })

    if (!formData || !formData.tagType) {
      setFieldValue('tagType', 'default')
    }
  }

  componentDidUpdate (prevProps) {
    const { dirty, onSetCloseFormMessage } = this.props

    if (!prevProps.dirty && dirty) {
      onSetCloseFormMessage('Closing this form will lose any edits you have made. Are you sure?')
    }

    if (prevProps.dirty && !dirty) {
      onSetCloseFormMessage('')
    }
  }

  handleCancelTagForm = (event) => {
    event.preventDefault()
    const { onCancelTagForm, resetForm } = this.props

    resetForm()
    onCancelTagForm()
  }

  handleToggleTagMenu = (event) => {
    event.preventDefault()
    const status = this.state.tagIconSelectMenuOpen
    this.setState({ tagIconSelectMenuOpen: !status })
  }

  handleSelectTag = (tagType) => (event) => {
    event.preventDefault()
    this.setState({ selectedTagIcon: tagType })
    this.props.setFieldValue('tagType', tagType)
    this.setState({ tagIconSelectMenuOpen: false })
  }

  handleOnChange = (event) => {
    const { id, value } = event.target
    const { setFieldValue } = this.props
    setFieldValue(id, value)
  }

  handleSelectDepot = ({ target: { value, checked } }) => {
    const { selectedDepots } = this.state
    const newSelectedDepots = { ...selectedDepots }

    if (checked) {
      newSelectedDepots[value] = true
    } else {
      delete newSelectedDepots[value]
    }

    const filteredDepots = this.filterDepots()
    // if user has selected all depots by hand, flip the select all depots boolean
    const allDepotsSelected = filteredDepots.length === Object.keys(newSelectedDepots).length
    const newState = {
      selectedDepots: newSelectedDepots,
      selectAllDepots: allDepotsSelected
    }

    this.setState(newState, this.afterSelectAllDepots)
  }

  handleSelectAllDepots = (event) => {
    event.stopPropagation()
    const { target: { checked } } = event
    const selectedDepots = {}
    const filteredDepots = this.filterDepots()

    if (checked) {
      filteredDepots.forEach(function ({ id }) {
        selectedDepots[id] = true
      })
    }

    const newState = {
      selectAllDepots: checked,
      selectedDepots
    }

    this.setState(newState, this.afterSelectAllDepots)
  }

  afterSelectAllDepots = () => {
    const { onSelectDepots, setFieldValue } = this.props
    const { selectedDepots } = this.state

    onSelectDepots(selectedDepots)
    setFieldValue('areaIds', Object.keys(selectedDepots))
  }

  renderAccordionButtonContent = () => {
    const { selectAllDepots } = this.state
    return (
      <div>
        <input
          type='checkbox'
          checked={selectAllDepots}
          className={depotSelectAllCheckbox}
          onClick={this.handleSelectAllDepots}
        />
        Depots
      </div>
    )
  }

  handleStateChange = ({ target: { value: selectedState } }) => {
    this.props.handleStateChange(selectedState)
    this.setState({
      selectedDepots: {},
      selectAllDepots: false
    }, this.afterSelectAllDepots)
  }

  filterDepots = () => {
    const { depots, selectedState } = this.props
    return depots.filter(depot => {
      const stateAbbreviation = depot.depotAddress && depot.depotAddress.state
      return stateAbbreviation === selectedState
    })
  }

  render () {
    const {
      selectedDepots,
      addHeaderText,
      editHeaderText,
      tagIconSelectMenuOpen,
      selectedTagIcon
    } = this.state

    const {
      errors,
      formData,
      handleBlur,
      handleChange,
      handleSubmit,
      isEdit,
      tagTypes,
      touched,
      values,
      selectedState,
      availableStates
    } = this.props

    const caretDirection = (
      `translate(-23, -412) translate(0, 274) translate(0, 133) translate(33, 11) scale(-1, ${tagIconSelectMenuOpen ? '-1' : '1'}) rotate(90) translate(-33, -11) translate(28.5, 2)`
    )

    const SelectedTagIconComponent = TAG_MAP[selectedTagIcon] || TAG_MAP[formData && formData.tagType] || TAG_MAP.default
    const filteredDepots = this.filterDepots()

    return (
      <>
        <h1 className={title}>{isEdit ? editHeaderText : addHeaderText}</h1>
        <form
          onSubmit={handleSubmit}
          className={tagForm}
        >
          <div className={tagFormContent}>
            <Column top={1}>
              <Row>
                <Column right={1}>
                  <div
                    className={tagTypeContainer}
                    onClick={this.handleToggleTagMenu}
                  >
                    <SelectedTagIconComponent style={{ marginRight: '1rem', height: '2rem' }} />
                    <ArrowIcon
                      color='#FFF'
                      componentStyle={{ verticalAlign: 'text-top' }}
                      transform={caretDirection}
                    />
                    <input type='hidden' name='tagType' value={values.tagType || ''} />
                    {tagIconSelectMenuOpen &&
                      <div className={tagIconSelectContainer}>
                        {tagTypes.map((tag) => {
                          if (TAG_MAP[tag.tagType]) { // don't show tags that we lack assets for
                            const TagIcon = TAG_MAP[tag.tagType.toLowerCase()]
                            return (
                              <span key={tag.tagType} className={tagIcon} onClick={this.handleSelectTag(tag.tagType)}>
                                <TagIcon />
                              </span>
                            )
                          } else return null
                        })}
                      </div>}
                  </div>
                </Column>
                <Column full>
                  <input
                    className={errors.text && touched.text ? inputError : ''}
                    name='text'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder='text'
                    type='text'
                    value={values.text || ''}
                  />
                  <ErrorMessage
                    name='text'
                    render={msg => <div className={errorText}>{msg}</div>}
                  />
                </Column>
              </Row>
            </Column>
            <Column top={1}>
              <textarea
                type='text'
                name='description'
                placeholder='Description'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                cols='2'
                rows='5'
              />
            </Column>
            <Column top={1}>
              <Row>
                <Column right={1} bottom={1}>
                  <InputLabel content='Start Date *' htmlFor='startDate' />
                  <DateInput
                    className={errors.startDate && touched.startDate ? inputError : ''}
                    id='startDate'
                    name='startDate'
                    onBlur={handleBlur}
                    onChange={this.handleOnChange}
                    required
                    type='date'
                    value={values.startDate}
                  />
                  <ErrorMessage
                    name='startDate'
                    render={msg => <div className={errorText}>{msg}</div>}
                  />

                </Column>

                <Column right={1}>
                  <InputLabel content='Start Time *' htmlFor='startTime' />
                  <TimeInput
                    id='startTime'
                    name='startTime'
                    onBlur={handleBlur}
                    onChange={this.handleOnChange}
                    required
                    type='time'
                    value={values.startTime}
                  />
                  <ErrorMessage
                    name='startTime'
                    render={msg => <div className={errorText}>{msg}</div>}
                  />
                </Column>
                <Column right={1} componentStyle={{ alignSelf: 'center' }}>
                  {TZ}
                </Column>
              </Row>

              <Row>
                <Column right={1}>
                  <InputLabel content='Expire Date *' htmlFor='expireDate' />
                  <DateInput
                    id='expireDate'
                    name='expireDate'
                    onBlur={handleBlur}
                    onChange={this.handleOnChange}
                    required
                    type='date'
                    value={values.expireDate}
                  />
                  <ErrorMessage
                    name='expireDate'
                    render={msg => <div className={errorText}>{msg}</div>}
                  />
                </Column>
                <Column right={1}>
                  <InputLabel content='Expire Time *' htmlFor='expireTime' />
                  <TimeInput
                    id='expireTime'
                    name='expireTime'
                    onBlur={handleBlur}
                    onChange={this.handleOnChange}
                    required
                    type='time'
                    value={values.expireTime}
                  />
                  <ErrorMessage
                    name='expireTime'
                    render={msg => <div className={errorText}>{msg}</div>}
                  />
                </Column>
                <Column right={1} componentStyle={{ alignSelf: 'center' }}>
                  {TZ}
                </Column>
              </Row>
            </Column>
            <Column full top={1} >
              <InputLabel content='State'/>
              <div className={selectContainer}>
                <select value={selectedState} onChange={this.handleStateChange} disabled={formData && formData.state_id}>
                  {
                    availableStates.map((e, index) => (
                      <option value={e} key={index}>{e}</option>
                    ))
                  }
                </select>
              </div>
            </Column>
            <Column full top={1}>
              <InputLabel content='Target Market' />
              <AccordionWrap>
                <Accordion
                  buttonClass={AccordionButtonStyle}
                  buttonContent={this.renderAccordionButtonContent()}
                  iconColor='#fff'
                >
                  {sortOn(filteredDepots, 'name').map((depot) => {
                    return (
                      <label
                        className={productLabel}
                        htmlFor={depot.id}
                        key={depot.id}
                      >
                        <input
                          className={depotCheckbox}
                          onChange={this.handleSelectDepot}
                          type='checkbox'
                          // being explicit with false boolean so react
                          // doesn't assume this is an uncontrolled component
                          checked={selectedDepots[depot.id] || false}
                          id={depot.id}
                          value={depot.id}
                        />
                        {depot.name}
                      </label>
                    )
                  })}
                </Accordion>
              </AccordionWrap>

              <ErrorMessage
                name='areaIds'
                render={msg => <div className={errorText}>{msg}</div>}
              />
            </Column>
          </div>
          <div className={tagFormButtons}>
            <Flex top={3}>
              <Button type={PRIMARY}>Select Products</Button>
            </Flex>
            <Flex top={1}>
              <Button
                type={DANGER}
                onClick={this.handleCancelTagForm}
              >
                Cancel
              </Button>
            </Flex>
          </div>
        </form>
      </>
    )
  }
}

const AccordionWrap = styled.div`
  display: flex;
  padding: 1rem;
`

const AccordionButtonStyle = css`
  align-items: center;
  background-color: transparent;
  border-bottom: 2px solid var(--tertiaryDark);
  border: none;
  color: var(--white);
  cursor: pointer;
  font-size: 1.4rem;

  svg > g {
    stroke: var(--white);
  }
`

const TimeInput = styled.input`
  width: 16rem;
`

const formikEnhancer = withFormik({
  // sends initial form values from formData prop
  mapPropsToValues: ({ formData = DEFAULT_FORM_DATA }) => {
    const { startAt, expiresAt } = formData
    const copy = {
      ...formData
    }
    copy.startDate = formatAsPacificTime(startAt, 'date')
    copy.startTime = formatAsPacificTime(startAt, 'time')
    copy.expireDate = formatAsPacificTime(expiresAt, 'date')
    copy.expireTime = formatAsPacificTime(expiresAt, 'time')
    return copy
  },
  handleSubmit: (formData, { props }) => {
    const copy = { ...formData }
    copy.startAt = formatDate(copy.startDate, copy.startTime)
    copy.expiresAt = formatDate(copy.expireDate, copy.expireTime)

    delete copy.startDate
    delete copy.startTime
    delete copy.expireDate
    delete copy.expireTime

    props.onSubmitForm(copy)
  },
  validationSchema: Yup.object().shape({
    tagType: Yup.string()
      .required('A tagType is required'),
    text: Yup.string()
      /* eslint-disable no-template-curly-in-string */
      .max(15, 'Text max is ${max} characters')
      /* eslint-disable no-template-curly-in-string */
      .required('Text is required'),
    startDate: Yup.string()
      .required('Invalid start date'),
    startTime: Yup.string()
      .required('Missing start time'),
    expireDate: Yup.string()
      .required('Invalid end date'),
    expireTime: Yup.string()
      .required('Missing end time'),
    areaIds: Yup.array()
      .min(1, 'You must choose at least one depot')
  })
})

export default formikEnhancer(TagForm)
