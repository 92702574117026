import t from './actionTypes'

export function setDriverOrdersMap (driverOrdersMap) {
  return {
    type: t.SET_DRIVER_ORDERS_MAP,
    payload: driverOrdersMap
  }
}

export function updateDriverOrdersMap (driverOrdersMap) {
  return {
    type: t.UPDATE_DRIVER_ORDERS_MAP,
    payload: driverOrdersMap
  }
}

export function clearDriverOrdersMap () {
  return {
    type: t.CLEAR_DRIVER_ORDERS_MAP
  }
}
