import { string } from 'prop-types'
import React from 'react'

export default function CopyIcon ({ size = '36px', title = 'Copy' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 36 36'
      version='1.1'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      aria-labelledby='copy-icon-title'
    >
      <title id='copy-icon-title'>{title}</title>
      <defs>
        <circle id='a' cx={18} cy={18} r={18} />
      </defs>
      <g
        transform='translate(-811.000000, -394.000000) translate(508.000000, 325.000000) translate(224.000000, 69.000000) translate(79.000000, 0.000000)'
        stroke='none'
        strokeWidth={1}
        fill='none'
        fillRule='evenodd'
      >
        <use fill='#3AB78C' fillRule='evenodd' xlinkHref='#a' />
        <circle
          stroke='#3AB78C'
          strokeWidth={5}
          cx={18}
          cy={18}
          r='15.5'
        />
        <path
          d='M25 10.778h-1.333a.444.444 0 0 0-.445-.445H10.778a.444.444 0 0 0-.445.445v12.444c0 .246.2.445.445.445V25A1.778 1.778 0 0 1 9 23.222V10.778C9 9.796 9.796 9 10.778 9h12.444C24.204 9 25 9.796 25 10.778z'
          fill='#FFF'
          fillRule='nonzero'
        />
        <rect
          stroke='#FFF'
          strokeWidth='1.5'
          x='12.75'
          y='12.75'
          width='14.5'
          height='14.5'
          rx={2}
        />
      </g>
    </svg>
  )
}

CopyIcon.propTypes = {
  size: string,
  title: string
}
