import React from 'react'
import { array, bool } from 'prop-types'
import errorHandler from 'helpers/error-handler'
import canceledOrderSound from 'assets/sounds/CanceledOrder_Sound.mp3'
import incomingOrderSound from 'assets/sounds/OrderCreated_Sound.mp3'
import manifestToPrintSound from 'assets/sounds/ManifestPrinted_Sound.mp3'

export default class SoundNotification extends React.PureComponent {
  static defaultProps = {
    canceledOrdersNotRecovered: [],
    manifestNeedsPrintedOrders: [],
    ordersIncoming: []
  }

  static propTypes = {
    areCanceledOrdersMuted: bool,
    areIncomingOrdersMuted: bool,
    areManifestsToPrintMuted: bool,
    canceledOrdersNotRecovered: array,
    manifestNeedsPrintedOrders: array,
    ordersIncoming: array
  }

  componentDidUpdate (prevProps) {
    const {
      canceledOrdersNotRecovered: prevCanceledOrdersNotRecovered,
      manifestNeedsPrintedOrders: prevManifestNeedsPrintedOrders,
      ordersIncoming: prevOrdersIncoming
    } = prevProps
    const {
      canceledOrdersNotRecovered: newCanceledOrdersNotRecovered,
      areCanceledOrdersMuted,
      areIncomingOrdersMuted,
      areManifestsToPrintMuted,
      manifestNeedsPrintedOrders: newManifestNeedsPrintedOrders,
      ordersIncoming: newOrdersIncoming
    } = this.props

    if (!areCanceledOrdersMuted &&
      ((newCanceledOrdersNotRecovered.length && !prevCanceledOrdersNotRecovered.length) ||
      (prevCanceledOrdersNotRecovered.length && newCanceledOrdersNotRecovered.length > prevCanceledOrdersNotRecovered.length))
    ) {
      this.playSound(canceledOrderSound)
    }

    if (!areManifestsToPrintMuted &&
      ((newManifestNeedsPrintedOrders.length && !prevManifestNeedsPrintedOrders.length) || newManifestNeedsPrintedOrders.length > prevManifestNeedsPrintedOrders.length)) {
      this.playSound(manifestToPrintSound)
    }

    if (!areIncomingOrdersMuted &&
      ((newOrdersIncoming.length && !prevOrdersIncoming.length) ||
      (prevOrdersIncoming.length && newOrdersIncoming.length && newOrdersIncoming[newOrdersIncoming.length - 1].id > prevOrdersIncoming[prevOrdersIncoming.length - 1].id))
    ) {
      this.playSound(incomingOrderSound)
    }
  }

  playSound (sound) {
    const audio = new Audio(sound)
    const audioPromise = audio.play()
    if (audioPromise !== undefined) {
      audioPromise.catch(error => {
        if (error.name === 'NotAllowedError') {
          console.info('Notification sound failed to play due to browser permissions.')
          errorHandler({ msg: 'Notification sound failed to play due to browser permissions.', error })
        } else {
          errorHandler(error)
        }
      })
    }
  }

  render () {
    return null
  }
}
