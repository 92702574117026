import React from 'react'

export default function Minus () {
  return (
    <svg width='10' height='10' viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'>
      <g id='Page-1' fill='none' fillRule='evenodd'>
        <g id='Group-14' transform='translate(-1 -1)'>
          <g id='Group-12'>
            <rect id='Rectangle' fillOpacity='0' fill='#D8D8D8' fillRule='nonzero' width='12' height='12' />
            <rect id='Combined-Shape' fill='#FFF' x='1' y='4.5' width='10' height='3' rx='1.5' />
          </g>
        </g>
      </g>
    </svg>
  )
}
